import { z } from 'zod';
import {
  commaDelimitedCapitalLettersValidator,
  commaDelimitedIntegersValidator,
  scholarshipSortValidator,
  sortValidator,
} from '../common';

export const GetScholarshipSchema = z.object({
  latitude: z.number().optional(),
  longitude: z.number().optional(),
});
export type GetScholarship = z.infer<typeof GetScholarshipSchema>;

export const GetScholarshipsSchema = z.object({
  sort: scholarshipSortValidator.optional(),
  sortDirection: sortValidator.optional(),
  keywords: z.string().min(3).optional(),
  trueSearchFields: z.string().optional(),
  falseSearchFields: z.string().optional(),
  areasOfStudy: commaDelimitedIntegersValidator.optional(),
  militaryService: z.boolean().optional(),
  moneyLowerLimit: z.number().int().optional(),
  moneyUpperLimit: z.number().int().optional(),
  stateResidence: commaDelimitedCapitalLettersValidator.optional(),
  expired: z.boolean().optional(),
  limit: z.number().int().optional(),
  skip: z.number().int().optional(),
});
export type GetScholarships = z.infer<typeof GetScholarshipsSchema>;
