import { z } from 'zod';
import { IpedsDocumentSchema } from '../ipeds/model';

const ProductGroupAssessmentsSchema = z.object({
  assessmentId: z.string(),
  examName: z.string(),
  examId: z.string(),
  examNumber: z.string(),
  cipCodes: z.array(z.string()),
  socCodes: z.array(z.string()),
});
export type ProductGroupAssessments = z.infer<typeof ProductGroupAssessmentsSchema>;

export const ProductGroupResultSchema = z.object({
  legacyId: z.string(),
  groupId: z.string(),
  clusters: z.array(
    z.object({
      clusterId: z.string(),
      name: z.string(),
    }),
  ),
  assessments: z.array(ProductGroupAssessmentsSchema),
});
export type ProductGroupResult = z.infer<typeof ProductGroupResultSchema>;

export const ProductGroupsLegacySchema = z.object({
  total: z.number().int(),
  page: z.number().int(),
  pageSize: z.number().int(),
  results: z.array(ProductGroupResultSchema),
});
export type ProductGroupsLegacy = z.infer<typeof ProductGroupsLegacySchema>;

export const CertificateCipCrosswalkSchema = z.object({
  productId: z.number().int(),
  productNumber: z.number().int(),
  productName: z.string(),
  cipCode: z.string(),
  cipTitle: z.string(),
});
export type CertificateCipCrosswalk = z.infer<typeof CertificateCipCrosswalkSchema>;

export const ExamDetailSchema = z.object({
  cipCode: z.string(),
  cipTitle: z.string(),
  schools: z.array(IpedsDocumentSchema),
});
export type ExamDetail = z.infer<typeof ExamDetailSchema>;
