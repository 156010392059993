import { z } from 'zod';
import { SocCodeInfoSchema } from '../cip-soc-crosswalk/responses';
import { CertificateProductSchema, awardTypeValidator, scholarshipStatusValidator } from '../common';
import { MajorSchema } from '../majors/responses';
import { DeadlineType } from '../../enums';
import { IpedsDocumentSchema } from './model';

export const SchoolSchema = IpedsDocumentSchema.omit({
  _id: true,
  createdBy: true,
  createdDate: true,
  modifiedBy: true,
  modifiedDate: true,
});
export type School = z.infer<typeof SchoolSchema>;

export const IpedsResultSchema = z.object({
  schools: z.array(SchoolSchema),
  totalCount: z.number().int(),
  limit: z.number().int(),
  skip: z.number().int(),
});
export type IpedsResult = z.infer<typeof IpedsResultSchema>;

export const DeadlineTypeOptionSchema = z.object({
  deadlineType: z.nativeEnum(DeadlineType),
  deadline: z.string(),
});
export type DeadlineTypeOption = z.infer<typeof DeadlineTypeOptionSchema>;

export const IpedsCardInfoSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  collegeUrl: z.string(),
  pictureUrl: z.string().optional(),
  baseUrl: z.string(),
  cost: z.string().or(z.number()),
  schoolLength: z.string(),
  totalStudentsEnrolled: z.number(),
  deadlineTypeOptions: z.array(DeadlineTypeOptionSchema),
  collegeScorecard: z
    .object({
      'latest.cost.attendance.program_year': z.number().nullable(),
      'latest.cost.attendance.academic_year': z.number().nullable(),
    })
    .optional(),
  memberId: z.number().int().optional(),
});
export type IpedsCardInfo = z.infer<typeof IpedsCardInfoSchema>;

export const IpedsDropDownListSchema = z.object({
  id: z.number(),
  name: z.string(),
  city: z.string(),
  address: z.string(),
  zip: z.string(),
});

export const IpedsMajorDetailSchema = z.object({
  cipTitle: z.string(),
  isFavoriteSoc: z.boolean(),
  isBestFitSoc: z.boolean(),
  hasCertification: z.boolean(),
  relatedCareers: z.array(SocCodeInfoSchema),
  schools: z.array(SchoolSchema),
  userCertifications: z.array(CertificateProductSchema),
});

export const FavoriteSchoolIdsSchema = z.array(
  z.object({
    id: z.number().int(),
  }),
);

// Original is in ipeds responses - copied to avoid cyclical imports
const ScholarshipCardSchema = z.object({
  id: z.number().int(),
  awardType: awardTypeValidator,
  programName: z.string(),
  donorName: z.string(),
  description: z.string(),
  applicationDate: z.object({
    deadlineMonth: z.number().int().nullable(),
    deadlineDay: z.number().int().nullable(),
    deadlineYear: z.number().int().nullable(),
  }),
  moneyRange: z.object({
    lowerLimit: z.number().nullable(),
    upperLimit: z.number().nullable(),
  }),
  areasOfStudy: z.array(
    z.object({
      areaOfStudy: z.string(),
      areaOfStudyDescription: z.string(),
      cipCodes: z.array(
        z.object({
          cipCode: z.string(),
          cipCodeDescription: z.string(),
          isFavorite: z.boolean(),
        }),
      ),
    }),
  ),
  isFavorite: z.boolean().optional(),
  applicationStatus: scholarshipStatusValidator.optional(),
  webAddress: z.object({
    programUrl: z.string(),
    sponsorUrl: z.string(),
  }),
});

export const SchoolWithScholarshipsSchema = SchoolSchema.extend({
  scholarships: z.array(ScholarshipCardSchema).optional(),
});
export type SchoolWithScholarships = z.infer<typeof SchoolWithScholarshipsSchema>;

export const SchoolWithScoreSchema = SchoolSchema.extend({
  score: z.number(),
});
export type SchoolWithScore = z.infer<typeof SchoolWithScoreSchema>;

export const EnrolledSchoolSchema = z.object({
  id: z.number().int(),
  areasOfStudy: z.array(z.number()),
  fitMajors: z.array(MajorSchema).optional(),
  favoritedCareerMajors: z.array(MajorSchema).optional(),
  favoriteMajors: z.array(MajorSchema).optional(),
  cardInfo: IpedsCardInfoSchema,
  scholarships: z.array(ScholarshipCardSchema).optional(),
});
export type EnrolledSchool = z.infer<typeof EnrolledSchoolSchema>;

export const CollegeSearchDropdownSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  city: z.string(),
  address: z.string(),
  zip: z.string(),
});
export type CollegeSearchDropdown = z.infer<typeof CollegeSearchDropdownSchema>;
