import { z } from 'zod';
import { EnrolledSchoolSchema, SchoolSchema } from '../ipeds/responses';
import { ScholarshipCardSchema } from '../scholarships/responses';
import { UserPlanningSchema } from '../userPlanning/responses';
import {
  FavoriteCollegeSchema,
  FavoriteMajorSchema,
  FilterCollegeSchema,
  FilterMajorSchema,
  PersonalInfoSchema,
  TenantSharingSchema,
} from './model';
import { dateOrStringValidator } from '../common';

export const EnrolledCollegeSchema = z.object({
  id: z.number().int().nullable(),
  name: z.string().optional(),
  pictureUrl: z.string().optional(),
  website: z.string().optional(),
  areasOfStudy: z.array(z.number()).optional(),
  fitMajors: z.array(z.string()).optional(),
  favoritedCareerMajors: z.array(z.string()).optional(),
});

export const TenantAdminAssignmentSchema = z.object({
  ipedsId: z.number().int(),
  school: SchoolSchema,
});
export type TenantAdminAssignment = z.infer<typeof TenantAdminAssignmentSchema>;

export const SchoolFavoriteSchema = FavoriteCollegeSchema.extend({
  school: SchoolSchema,
});
export type SchoolFavorite = z.infer<typeof SchoolFavoriteSchema>;

export const UserPreferencesFavoritesSchema = z.object({
  colleges: z.array(SchoolFavoriteSchema).optional(),
  majors: FavoriteMajorSchema.optional(),
  scholarships: z.array(ScholarshipCardSchema).optional(),
});
export type UserPreferencesFavorites = z.infer<typeof UserPreferencesFavoritesSchema>;

export const UserPreferencesSchema = z.object({
  userId: z.number().int().or(z.string()),
  isCollegeOriginatingTenant: z.boolean().optional(),
  isNotToBeAskedToShare: z.boolean().optional(),
  collegeOriginatingTenantId: z.number().int().or(z.string()).optional(),
  enrolledSchoolId: z.number().int().optional(),
  enrollmentDate: dateOrStringValidator.optional(),
  favorites: UserPreferencesFavoritesSchema.optional(),
  filters: z
    .object({
      colleges: z.array(FilterCollegeSchema).optional(),
      majors: z.array(FilterMajorSchema).optional(),
    })
    .optional(),
  tenantSharing: z.array(TenantSharingSchema).optional(),
  personalInfo: PersonalInfoSchema.optional(),
  modifiedDate: dateOrStringValidator.optional(),
});
export type UserPreferences = z.infer<typeof UserPreferencesSchema>;

export const UserProfileSchema = z.object({
  userPreferences: UserPreferencesSchema,
  userPlanning: UserPlanningSchema,
  coordinates: z
    .object({
      longitude: z.number(),
      latitude: z.number(),
    })
    .optional(),
  enrolledSchool: EnrolledSchoolSchema.optional(),
  notifyTenantAvailable: z.boolean().optional(),
  tenantAdminAssignments: TenantAdminAssignmentSchema.array(),
});
export type UserProfile = z.infer<typeof UserProfileSchema>;
