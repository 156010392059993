/* eslint-disable @typescript-eslint/no-unused-vars */
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';

import type {
  AccessExtended,
  AccessRoleEnum,
  MeWithAccessRecordsApiResponse,
  PreferencesRecord,
  ProfileRecord,
  UserDocument,
  UserDocumentWithAccess,
} from '@youscience/user-service-common';

import { authService } from '@services/auth.api.service';

import { BRIGHTPATH_HOME_URL, AUTHENTICATION_BASE_URL } from '@constants/externalRoutes';
import { conditionalRedirect } from '@utils/navigation';

import { CurrentAccess, ImpersonatedBy, UserRoles } from '@interfaces/user';

// TODO - user-common-service needs to be updated to include the fields gradeLevel and plan
export interface ProfileGradeAndPlan {
  gradeLevel?: number;
  plan?: { now?: string; future?: string[] };
}
export type ProfileWithExtendedData = ProfileRecord & ProfileGradeAndPlan;

export type AuthSession<Y = AccessRoleEnum, T = boolean> = {
  currentAccess?: CurrentAccess;
  impersonatedBy?: ImpersonatedBy;
  isAuthenticated: T;
  isImpersonated: boolean;
  userData?: UserDocumentWithAccess;
  userType?: Y;
};

export interface AuthSessionContext {
  authSession: AuthSession;
  initializedUser: boolean;
  getMe: () => Promise<MeWithAccessRecordsApiResponse | undefined>;
  resetAuthSession: () => void;
  setPreferences: (newPreference: string) => void;
  signIn: (param?: string, search?: string) => unknown;
  signOut: () => void;
}

const initialAuthSession: AuthSession = {
  currentAccess: undefined,
  impersonatedBy: undefined,
  isImpersonated: false,
  isAuthenticated: false,
  userData: undefined,
  userType: undefined,
};

const initialState: AuthSessionContext = {
  authSession: { ...initialAuthSession },
  initializedUser: false,
  getMe: async () => undefined,
  resetAuthSession: () => undefined,
  setPreferences: () => undefined,
  signIn: () => undefined,
  signOut: () => undefined,
};

const getRole = (currentRole?: AccessRoleEnum): AccessRoleEnum => {
  const role = currentRole ?? UserRoles.Learner;
  if (role === UserRoles.Alumni) {
    return UserRoles.Learner;
  }
  return role;
};

export { getRole };

export const useAuthStore = createWithEqualityFn<AuthSessionContext>()(
  devtools((set, get) => ({
    ...initialState,

    signIn: (pathname?: string, search?: string) => {
      conditionalRedirect(
        `${AUTHENTICATION_BASE_URL}?redirectUrl=${window.location.protocol}//${window.location.host}${pathname}${search}`,
        'replace',
      );
    },

    signOut: () => {
      conditionalRedirect(
        `${AUTHENTICATION_BASE_URL}/logout?logoutRedirectUrl=${window.location.protocol}//${window.location.host}`,
        'replace',
      );
    },

    resetAuthSession: () => {
      set((state) => ({ ...state, authSession: initialAuthSession, initializedUser: false }));
      conditionalRedirect(`${window.location.protocol}//${window.location.host}/`, 'assign');
    },

    setPreferences: (newPreference: string) => {
      const { authSession } = get();

      let currentAccess: CurrentAccess;
      let isAuthenticated: boolean;
      let newAuthSession: AuthSession;
      let newPreferredAccess: AccessExtended | undefined;
      let userType: AccessRoleEnum;

      if (authSession.userData?.access.length) {
        newPreferredAccess = authSession.userData.access.find(
          (access: AccessExtended) => access._id.toString() === newPreference,
        );
      }

      if (newPreferredAccess) {
        const role = getRole(newPreferredAccess?.tenant?.permission?.role);

        currentAccess = {
          accessDocumentId: newPreferredAccess._id.toString(),
          constraintTags: newPreferredAccess?.user.grants ?? [],
          role,
          tenantId: newPreferredAccess?.tenant.tenantId ?? '',
          tenantName: newPreferredAccess?.tenant.name ?? '',
          userId: newPreferredAccess?.user.userId ?? '',
          commonAppEnabled: newPreferredAccess?.tenant.productAvailability?.commonApp ?? false,
        };

        isAuthenticated = true;

        newAuthSession = {
          ...authSession,
          currentAccess,
          isAuthenticated,
          userType: role,
          userData: {
            ...authSession.userData,
            fullName: newPreferredAccess.user.fullName,
            userId: newPreferredAccess.user.userId,
          } as UserDocument,
        } as AuthSession;

        set(() => ({ authSession: { ...newAuthSession } }));
      }
    },

    getMe: async () => {
      set((state) => ({ ...state }));

      try {
        const userData = (await authService.getUser()) as MeWithAccessRecordsApiResponse;
        const receivedPreference = (await authService.getUserPreferences()) as PreferencesRecord;

        if (userData) {
          let currentAccess: CurrentAccess;

          currentAccess = {
            accessDocumentId: '',
            role: UserRoles.Learner,
            tenantId: '',
            constraintTags: [],
            tenantName: '',
            userId: '',
            commonAppEnabled: false,
          };

          if (userData?.access?.length > 0) {
            const role = getRole(userData.access?.[0].tenant.permission?.role);

            currentAccess = {
              accessDocumentId: userData.access?.[0]._id.toString(),
              constraintTags: userData.access?.[0].user.grants,
              role,
              tenantId: userData.access?.[0].tenant.tenantId ?? '',
              tenantName: userData.access?.[0].tenant.name ?? '',
              userId: userData.access?.[0].user.userId ?? '',
              commonAppEnabled: userData.access?.[0].tenant.productAvailability?.commonApp ?? false,
            };
          } else {
            conditionalRedirect(BRIGHTPATH_HOME_URL, 'replace');
          }

          set(() => ({
            authSession: {
              currentAccess,
              impersonatedBy: userData.impersonatedBy ?? undefined,
              isAuthenticated: true,
              isImpersonated: !!userData.impersonatedBy,
              userData,
              userType: currentAccess.role,
            } as AuthSession,
          }));

          if (
            receivedPreference?.currentAccess &&
            receivedPreference.currentAccess.toString() !== userData.access[0]._id.toString()
          ) {
            get().setPreferences(receivedPreference.currentAccess.toString());
          }

          return userData;
        }
      } catch {
        set(() => ({
          authSession: {
            ...initialAuthSession,
            isAuthenticated: false,
          },
        }));
      } finally {
        set({ initializedUser: true });
      }
      return undefined;
    },
  })),
  shallow,
);

export const signOutCallback = (): (() => void) => {
  const closure = useAuthStore.getState().signOut;

  return closure;
};

export const getMeCallback: () => Promise<MeWithAccessRecordsApiResponse | undefined> = () => {
  const closure = useAuthStore.getState().getMe;

  return closure();
};

export const setPreferences: (preferredAccess: string) => void = (preferredAccess: string) => {
  const closure = useAuthStore.getState().setPreferences;

  return closure(preferredAccess);
};

export const getAuthSession: () => AuthSession | undefined = (): AuthSession | undefined => {
  const { authSession } = useAuthStore.getState();

  return authSession;
};

export const getCurrentAccess: () => CurrentAccess | undefined = () => {
  const closure = useAuthStore.getState().authSession.currentAccess;

  return closure;
};

export const resetAuthSession: () => void = () => {
  const closure = useAuthStore.getState().resetAuthSession;

  return closure();
};
