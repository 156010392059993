import { z } from 'zod';
import {
  admissionStatusValidator,
  decisionValidator,
  gradeValidator,
  positiveIntegerValidator,
  scholarshipStatusValidator,
  seasonValidator,
  tagsValidator,
} from '../common';
import { ApplicationSchema, CustomCheckListSchema, DefaultCheckListSchema } from './model';

export const UpdateApplyingCollegeSchema = z.object({
  id: positiveIntegerValidator.optional(),
  priority: z.number().int().min(1).max(3),
  admissionStatus: admissionStatusValidator.optional(),
  application: ApplicationSchema.optional(),
  decision: decisionValidator.optional(),
  defaultChecklist: DefaultCheckListSchema.optional(),
  customChecklist: z.array(CustomCheckListSchema).optional(),
  memberId: z.number().int().optional(),
});
export type UpdateApplyingCollege = z.infer<typeof UpdateApplyingCollegeSchema>;

export const AddUserPlanTaskSchema = z.object({
  taskId: z.number().int().optional(),
  status: z.enum(['Active', 'Completed', 'Deleted']),
  dueDate: z.string().optional(),
  linkUrls: z.array(z.string()).optional(),
  grades: z.array(gradeValidator),
  season: seasonValidator,
  tags: z.array(tagsValidator).optional(),
  title: z.string(),
  description: z.string(),
  isDeleted: z.boolean(),
});
export type AddUserPlanTask = z.infer<typeof AddUserPlanTaskSchema>;

export const SetUserPlanBodySchema = z.object({
  beginCollege: z
    .object({
      year: z.number().int().optional(),
      season: seasonValidator.optional(),
      other: z.string().optional(),
    })
    .optional(),
  overallGPA: z.number().min(0).max(5).optional(),
  currentGrade: z.number().int().optional(),
});
export type SetUserPlanBody = z.infer<typeof SetUserPlanBodySchema>;

export const UpdateUserPlanTaskSchema = z.object({
  status: z.enum(['Active', 'Completed']).optional(),
  dueDate: z.string().optional(),
  linkUrls: z.array(z.string()).optional(),
  grades: z.array(gradeValidator).optional(),
  season: seasonValidator.optional(),
  tags: z.array(tagsValidator).optional(),
  title: z.string().optional(),
  description: z.string().optional(),
  isDeleted: z.boolean().optional(),
});
export type UpdateUserPlanTask = z.infer<typeof UpdateUserPlanTaskSchema>;

export const UpdateApplyingScholarshipSchema = z.object({
  status: scholarshipStatusValidator,
});
export type UpdateApplyingScholarship = z.infer<typeof UpdateApplyingScholarshipSchema>;

export const AddApplyingScholarshipSchema = UpdateApplyingScholarshipSchema.extend({
  id: positiveIntegerValidator,
});
