import { createWithEqualityFn } from 'zustand/traditional';
import { TenantSharingType } from '@common-types';

import useNotificationStore from '@store/NotificationStore';

import { OptInConnectionData } from '@interfaces/optIn';
import { TenantSharingSchool } from '@interfaces/selectSchool';
import { updateSchoolTenantSharing, updateTenantSharing } from '@api/userPreferences';
import useUserPreferencesStore from '@store/UserPreferencesStore';

export interface OptInStore {
  optInConnectionData: Partial<OptInConnectionData>;
  setOptInConnectionData: (data: Partial<OptInConnectionData>) => void;
  resetOptInConnectionData: () => void;
  saveUserConnection: (schoolId: number, isShare: boolean) => void;

  allSchoolsTenantSharing: Partial<TenantSharingSchool>[];
  setAllSchoolsTenantSharing: (data: Partial<TenantSharingSchool>[]) => void;
  updateAllSchoolsTenantSharing: (isNotToBeAskedToShare: boolean) => void;
}

const initialState = {
  optInConnectionData: {},
} as OptInStore;

export const useOptInStore = createWithEqualityFn<OptInStore>()(
  (set, get) => ({
    ...initialState,

    setOptInConnectionData: (data: Partial<OptInConnectionData>) => {
      set({
        optInConnectionData: { ...get().optInConnectionData, ...data },
      });
    },

    resetOptInConnectionData: () => {
      set({
        optInConnectionData: { ...initialState.optInConnectionData },
      });
    },

    saveUserConnection: (schoolId: number, isShare: boolean) => {
      const data: OptInConnectionData = {
        question: 'Would you like us to share your contact information with school?',
        share: isShare,
        shareType: TenantSharingType.ENROLLMENT,
      };

      void updateSchoolTenantSharing(schoolId, data);
    },

    setAllSchoolsTenantSharing: (data: Partial<TenantSharingSchool>[]) => {
      set({
        allSchoolsTenantSharing: data,
      });
    },

    updateAllSchoolsTenantSharing: async (isNotToBeAskedToShare: boolean) => {
      const { allSchoolsTenantSharing } = get();

      const preparedTenantSharingData = allSchoolsTenantSharing.map(({ collegeId, currentlySharing }) => ({
        collegeId,
        currentlySharing,
      }));

      const sharedCollegesCount = preparedTenantSharingData.filter((school) => school.currentlySharing).length;

      const data = {
        optInText: 'I would like to opt in',
        optOutText: 'I would like to opt out',
        tenantSharing: preparedTenantSharingData,
      };

      await useUserPreferencesStore.getState().saveUserSharingPreferences({ isNotToBeAskedToShare });
      await updateTenantSharing(data);

      const title =
        isNotToBeAskedToShare || sharedCollegesCount === 0
          ? 'Your data is not shared with any colleges.'
          : `Your data is shared with ${sharedCollegesCount} favorite colleges.`;

      useNotificationStore.getState().notify({
        title,
        message: 'You can change permission all the time in the profile menu.',
        severity: 'success',
      });

      set({
        allSchoolsTenantSharing,
      });
    },
  }),
  Object.is,
);
