import { z } from 'zod';
import { mongoIdValidator } from '../common';

const QuestionSchema = z.object({
  questionId: z.number().int(),
  questionOrder: z.number().int(),
  active: z.boolean(),
  question: z.string(),
  questionType: z.number().int().min(1).max(3),
  multipleChoices: z.object({}).catchall(z.unknown()).optional(), // { [key: string]: any }
  answerLength: z.number().int().optional(),
});
export type Question = z.infer<typeof QuestionSchema>;

export const SurveyDocumentSchema = z.object({
  _id: mongoIdValidator.optional(),
  surveyId: z.number().int(),
  name: z.string(),
  questions: z.array(QuestionSchema),
});
export type SurveyDocument = z.infer<typeof SurveyDocumentSchema>;
