import { useState } from 'react';

import { CoreTypography } from '@youscience/khaleesi';
import { BrightpathHeader } from '@youscience/brightpath-header';

import useAuthStore, { ProfileWithExtendedData } from '@store/AuthStore';
import useHomePageStore from '@store/HomePageStore';
import useSchoolSelectionStore from '@store/SchoolSelectionStore';
import useUserPreferencesStore from '@store/UserPreferencesStore';
import useUserStore from '@store/UserStore';

import { MIN_OPT_IN_USER_AGE, MIN_OPT_IN_USER_GRADE } from '@constants/optIn';

import { AllSchoolConnectionModalProps, withAllSchoolConnectionModal } from '@HOCs/withAllSchoolConnectionModal';

import { isAlreadyGraduated } from '@utils/studentProgress';

import { VerificationMessage } from '@components/Layout/components/Header/components/LPUserMenu/components/VerificationMessage';

import DataSharingIcon from '@images/data-sharing.svg?react';
import { LPUserMenu } from './components/LPUserMenu';

import { StyledManageSharing } from './Header.styles';

import { handleTenantSelectCallback } from './constants';
import useEcpStore from '@store/EcpStore';

type HeaderProps = AllSchoolConnectionModalProps;

const Header = (props: HeaderProps) => {
  const { onOpenAllSchoolConnection } = props;

  const authSession = useAuthStore((state) => state.authSession);
  const schoolFavorites = useUserPreferencesStore((state) => state.schoolFavorites);

  const landingPageSchoolData = useHomePageStore((state) => state.landingPageSchoolData);
  const { isCollegeOriginatingTenant } = useSchoolSelectionStore((state) => state.schoolSelectionData);
  const { schoolExplorationEnabled } = useEcpStore((state) => state.ecpSettings);

  const { userData } = useAuthStore((state) => state.authSession);
  const { gradeLevel } = (userData?.profile ?? {}) as ProfileWithExtendedData;

  const alreadyGraduated = isAlreadyGraduated();
  const age = useUserStore.getState().getUserAge();

  const showManageSharing =
    age >= MIN_OPT_IN_USER_AGE && ((gradeLevel || 0) >= MIN_OPT_IN_USER_GRADE || alreadyGraduated);

  const enrolledSchoolId = landingPageSchoolData?.id;

  const [isOpenVerificationMessage, setIsOpenVerificationMessage] = useState(false);

  const handleOpenVerificationMessage = () => {
    setIsOpenVerificationMessage(true);
  };

  const handleCloseVerificationMessage = () => {
    setIsOpenVerificationMessage(false);
  };

  const userMenu = () => {
    if (enrolledSchoolId && (!isCollegeOriginatingTenant || schoolExplorationEnabled)) {
      return <LPUserMenu onHandleShowMessage={handleOpenVerificationMessage} />;
    }

    return schoolFavorites.length > 0 && showManageSharing ? (
      <StyledManageSharing onClick={onOpenAllSchoolConnection}>
        <DataSharingIcon />

        <CoreTypography>Manage sharing</CoreTypography>
      </StyledManageSharing>
    ) : null;
  };

  return authSession.userData ? (
    <>
      <BrightpathHeader
        currentProductPage="colleges-programs"
        accessDocumentId={authSession?.currentAccess?.accessDocumentId}
        productSpecificNode={userMenu()}
        handleTenantSelectCallback={handleTenantSelectCallback}
        impersonating={authSession?.isImpersonated || false}
        userDocument={authSession.userData}
      />
      {isOpenVerificationMessage && (
        <VerificationMessage
          isOpenVerificationMessage={isOpenVerificationMessage}
          onCloseVerificationMessage={handleCloseVerificationMessage}
        />
      )}
    </>
  ) : null;
};

export const HeaderWithModal = withAllSchoolConnectionModal(Header);
