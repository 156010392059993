import { z } from 'zod';
import { FilterCollege } from '../userPreferences/model';

export const SetCipCodesSchema = z.object({
  cipCode: z.string(),
  awLevel: z.array(z.number().int()).min(1).nullable(),
});
export type SetCipCodes = z.infer<typeof SetCipCodesSchema>;

const PictureSchema = z.object({
  name: z.string(),
  pictureWidth: z.number().optional(),
  pictureHeight: z.number().optional(),
  pictureSource: z.string().optional(),
  pictureUrl: z.string().optional(),
});

export const SetSchoolInfoSchema = z.object({
  areaOfStudyToRemove: z.number().int().optional(),
  completions: z.array(SetCipCodesSchema).optional(),
  picture: PictureSchema.optional(),
});
export type SetSchoolInfo = z.infer<typeof SetSchoolInfoSchema>;

const GetSchoolListSchema = z.object({
  awardLevels: z.string().optional(),
  carnegieSizeSettings: z.string().optional(),
  cipCodes: z.string().optional(),
  cost: z.number().int().min(0).optional(),
  exactCipCodes: z.string().optional(),
  hasScholarship: z.boolean().optional(),
  institutionTypes: z.string().optional(),
  isCommonApp: z.boolean().optional(),
  keywords: z.string().optional(),
  latitude: z.number().optional(),
  limit: z.number().int().optional(),
  locales: z.string().optional(),
  longitude: z.number().optional(),
  skip: z.number().int().optional(),
  states: z.string().optional(),
  totalStudentsEnrolled: z.string().optional(),
});
export type GetSchoolList = z.infer<typeof GetSchoolListSchema>;

export const GetSchoolsNearZipSchema = z.object({
  carnegieSizeSettings: z.string().optional(),
  classesOffered: z.number().int().optional(),
  institutionTypes: z.string().optional(),
  isVocationalSchool: z.boolean().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  limit: z.number().int().optional(),
});
export type GetSchoolsNearZip = z.infer<typeof GetSchoolsNearZipSchema>;

export type CollegeSearchFilters = Omit<FilterCollege, 'createdDate'>;

export const CollegeSearchQuerySchema = z.object({
  state: z.string(),
  city: z.string().optional(),
  name: z.string().optional(),
});
export type CollegeSearchQuery = z.infer<typeof CollegeSearchQuerySchema>;
