import { z } from 'zod';
import { dateOrStringValidator, mongoIdValidator, seasonValidator, tenantShareTypeValidator } from '../common';

export const SurveyResultSchema = z.object({
  questionId: z.number().int(),
  answer: z.string(),
});
export type SurveyResult = z.infer<typeof SurveyResultSchema>;

const FavoriteSurveySchema = z.object({
  surveyId: z.number().int(),
  questions: z.array(SurveyResultSchema),
});
export type FavoriteSurvey = z.infer<typeof FavoriteSurveySchema>;

export const FavoriteCollegeSchema = z.object({
  id: z.number().int(),
  isActive: z.boolean(),
  createdDate: dateOrStringValidator,
  modifiedDate: dateOrStringValidator,
  surveys: z.array(FavoriteSurveySchema).optional(),
});
export type FavoriteCollege = z.infer<typeof FavoriteCollegeSchema>;

export const FavoriteMajorSchema = z.object({
  cipCodes: z.array(z.string()),
  cipCodeInformation: z.array(
    z.object({
      cipCode: z.string(),
      surveys: z.array(FavoriteSurveySchema).optional(),
    }),
  ),
});
export type FavoriteMajor = z.infer<typeof FavoriteMajorSchema>;

const FavoritesSchema = z.object({
  colleges: z.array(FavoriteCollegeSchema).optional(),
  majors: FavoriteMajorSchema.optional(),
  scholarships: z.array(z.number().int()).optional(),
});

const FilterSharedSchema = z.object({
  id: mongoIdValidator.optional(),
  createdDate: dateOrStringValidator,
  awardLevels: z.array(z.string()).optional(),
  cipCodes: z.array(z.string()).optional(),
});

export const FilterCollegeSchema = FilterSharedSchema.extend({
  carnegieSizeSettings: z.array(z.string()).optional(),
  cost: z.number().optional(),
  hasScholarship: z.boolean().optional(),
  institutionTypes: z.array(z.string()).optional(),
  keywords: z.string().optional(),
  locales: z.array(z.string()).optional(),
  specialty: z.array(z.string()).optional(),
  states: z.array(z.string()).optional(),
  totalStudentsEnrolled: z.array(z.string()).optional(),
});
export type FilterCollege = z.infer<typeof FilterCollegeSchema>;

export const FilterMajorSchema = FilterSharedSchema.extend({
  careerFavorite: z.boolean().optional(),
  careerFit: z.boolean().optional(),
  certificationsEarned: z.boolean().optional(),
});
export type FilterMajor = z.infer<typeof FilterMajorSchema>;

const FiltersSchema = z.object({
  colleges: z.array(FilterCollegeSchema).optional(),
  majors: z.array(FilterMajorSchema).optional(),
});

const TenantSharingHistorySchema = z.object({
  optInDate: dateOrStringValidator,
  optInVersion: z.string(),
  optOutDate: dateOrStringValidator.optional(),
  optOutVersion: z.string().optional(),
});
export type TenantSharingHistory = z.infer<typeof TenantSharingHistorySchema>;

export const TenantSharingSchema = z.object({
  collegeId: z.number().int(),
  currentlySharing: z.boolean(),
  type: tenantShareTypeValidator,
  history: z.array(TenantSharingHistorySchema),
});
export type TenantSharing = z.infer<typeof TenantSharingSchema>;

const UserAddressSchema = z.object({
  isNotInUS: z.boolean().optional(),
  address: z.string(),
  apartment: z.string().optional(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
});
export type UserAddress = z.infer<typeof UserAddressSchema>;

const PlanEnrollmentSchema = z.object({
  season: seasonValidator,
  year: z.number().int(),
});

const ContactMethodSchema = z.object({
  email: z.boolean(),
  mail: z.boolean(),
  text: z.boolean(),
  phone: z.boolean(),
});
export type ContactMethod = z.infer<typeof ContactMethodSchema>;

export const PersonalInfoSchema = z.object({
  email: z.string().optional(),
  phone: z.string().optional(),
  address: UserAddressSchema.optional(),
  planEnrollment: PlanEnrollmentSchema.optional(),
  preferredMethodOfContact: ContactMethodSchema.optional(),
});
export type PersonalInfo = z.infer<typeof PersonalInfoSchema>;

export const UserPreferencesDocumentSchema = z.object({
  _id: mongoIdValidator.optional(),
  userId: z.number().int().or(z.string()),
  legacyId: z.number().int().optional(),
  isCollegeOriginatingTenant: z.boolean().optional(),
  isNotToBeAskedToShare: z.boolean().optional(),
  collegeOriginatingTenantId: z.number().int().or(z.string()).optional(),
  enrolledSchoolId: z.number().int().optional(),
  enrollmentDate: dateOrStringValidator.optional(),
  notifyWhenTenantAssigned: z.boolean().optional(),
  favorites: FavoritesSchema.optional(),
  filters: FiltersSchema.optional(),
  tenantSharing: z.array(TenantSharingSchema).optional(),
  personalInfo: PersonalInfoSchema.optional(),
  modifiedDate: dateOrStringValidator.optional(),
});
export type UserPreferencesDocument = z.infer<typeof UserPreferencesDocumentSchema>;
