import type { IpedsResult, CollegeSearchFilters } from '@common-types';
import { baseService } from '@services/base.service';
import { trackEvent } from '@utils/googleAnalytics';
import { DESKTOP_PER_PAGE_OPTIONS } from '@constants';
import { SchoolSearchState } from '@store/SchoolSearchFiltersStore';
import useUserInfoStore from '@store/UserInfoStore';
import { FormattedParams } from './types';

export const getSchools = async ({
  filters,
  filterByMajor = false,
}: {
  filters: SchoolSearchState;
  filterByMajor?: boolean;
}): Promise<IpedsResult> => {
  const { cost, keywords, hasScholarship, skip, limit } = filters;
  const userLocation = useUserInfoStore.getState().userLocation;

  const params: FormattedParams = {
    limit: limit || DESKTOP_PER_PAGE_OPTIONS[0],
    ...userLocation,
  };

  if (hasScholarship) {
    params.hasScholarship = hasScholarship;
  }

  if (cost) {
    params.cost = cost;
  }

  if (keywords) {
    params.keywords = encodeURIComponent(keywords);
    trackEvent({
      eventType: 'Keyword Search',
      props: { keywords },
    });
  }

  if (skip) {
    params.skip = skip;
  }

  const arrayFields: (keyof CollegeSearchFilters)[] = [
    'states',
    'carnegieSizeSettings',
    'institutionTypes',
    'cipCodes',
    'awardLevels',
    'totalStudentsEnrolled',
    'locales',
    'specialty',
  ];

  arrayFields.forEach((filter) => {
    const filterValue = filters[filter] as string[];

    if (filter === 'cipCodes' && filterByMajor) {
      params.exactCipCodes = filterValue.join(',');
    } else {
      if (filterValue?.length) {
        params[filter] = filterValue.join(',');
      }
    }
  });

  return baseService.getAsync<IpedsResult>('/', { params });
};
