import { z } from 'zod';
import { AuditFieldSchema, mongoIdValidator } from '../common';

export const MajorDocumentSchema = z
  .object({
    _id: mongoIdValidator.optional(),
    year: z.number().int().positive(),
    cipCode: z.string(),
    cipTitle: z.string().optional(),
    collegesCount: z.number().int().min(0),
    socCodes: z.array(z.string()).optional(),
  })
  .merge(AuditFieldSchema);
export type MajorDocument = z.infer<typeof MajorDocumentSchema>;
