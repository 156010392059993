import { Suspense, lazy, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { RouterProvider } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { educationConnectionsTheme as theme } from '@youscience/khaleesi';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { AppRoutes } from '@routes/AppRoutes';
import useAppInitStore from '@store/AppInitStore';

import { usePdfWorker } from './hooks/usePdfWorker';
import { QueryClientProvider } from './providers/QueryClientProvider';

import 'core-js/proposals/promise-with-resolvers';

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_TRACKING_ID,
};

TagManager.initialize(tagManagerArgs);

const Notification = lazy(() => import('@components/Notification'));

function App() {
  const { initialize } = useAppInitStore();

  // Configure PDF.js worker
  usePdfWorker();

  useEffect(() => {
    void initialize();
  }, [initialize]);

  return (
    <QueryClientProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={AppRoutes()} />
        <Suspense>
          <Notification />
        </Suspense>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
