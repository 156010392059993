import { z } from 'zod';
import { mongoIdValidator } from '../common';

export const SocCrosswalkDocumentSchema = z.object({
  _id: mongoIdValidator,
  socCode2010: z.string(),
  socTitle2010: z.string(),
  socCode2019: z.string(),
  socTitle2019: z.string(),
});
export type SocCrosswalkDocument = z.infer<typeof SocCrosswalkDocumentSchema>;
