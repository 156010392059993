import { z } from 'zod';
import { commaDelimitedDecimalValidator } from '../common';

export enum CertificateQuerySort {
  PRODUCT_ID = 'productId',
  PRODUCT_NUMBER = 'productNumber',
  CIPCODE = 'cipCode',
}

export const CertificateQuerySchema = z.object({
  cipCodes: commaDelimitedDecimalValidator.optional(),
  productId: z.string().optional(),
  productNumber: z.string().optional(),
  skip: z.number().int().optional(),
  limit: z.number().int().optional(),
  sort: z.nativeEnum(CertificateQuerySort).optional(),
});
export type CertificateQuery = z.infer<typeof CertificateQuerySchema>;
