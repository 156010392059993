import { Season } from '@common-types';

import { ErrorFieldsStatus, ContactFormHelperTexts } from '@interfaces/optIn';
import { ATTENDING_YEAR } from '@constants';

export const MIN_OPT_IN_USER_AGE = 13;
export const MIN_OPT_IN_USER_GRADE = 9;

export const MAX_ADDRESS_LENGTH = 75;

export const PLAN_ENROLLMENT_DATA = [
  `${Season.SPRING} ${ATTENDING_YEAR}`,
  `${Season.FALL} ${ATTENDING_YEAR}`,
  `${Season.SPRING} ${ATTENDING_YEAR + 1}`,
  `${Season.FALL} ${ATTENDING_YEAR + 1}`,
  `${Season.SPRING} ${ATTENDING_YEAR + 2}`,
  `${Season.FALL} ${ATTENDING_YEAR + 2}`,
  `${Season.SPRING} ${ATTENDING_YEAR + 3}`,
  `${Season.FALL} ${ATTENDING_YEAR + 3}`,
  `${Season.SPRING} ${ATTENDING_YEAR + 4}`,
  `${Season.FALL} ${ATTENDING_YEAR + 4}`,
];

export const PLAN_ENROLLMENT_OPTIONS = PLAN_ENROLLMENT_DATA.map((item) => ({ label: item, value: item }));

export const INITIAL_CONTACT_FORM_HELPER_TEXTS: ContactFormHelperTexts = {
  email: '',
  phone: '',
  contactMethod: '',
};

export const HELPER_TEXT = {
  emailValidation: 'Enter email in valid format.',
  emailRequired: 'Please fill out your email.',
  phoneRequired: 'Phone should have 10 digits.',
  contactMethodRequired: 'Please fill out one way to contact you.',
};

export const ADDRESS_HELPER_TEXT_LABELS: { [key: string]: string } = {
  address: 'street',
  apartment: 'apartment',
  city: 'city',
  state: 'state',
  zip: 'zip',
};

export const INITIAL_ERROR_FIELDS_STATUS: ErrorFieldsStatus = {
  email: false,
  phone: false,
  address: {
    address: false,
    apartment: false,
    city: false,
    state: false,
    zip: false,
  },
  preferredMethodOfContact: false,
};
