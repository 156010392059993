/**
 * {
"0": "US Service schools",
"1": "New England CT ME MA NH RI VT",
"2": "Mid East DE DC MD NJ NY PA",
"3": "Great Lakes IL IN MI OH WI",
"4": "Plains IA KS MN MO NE ND SD",
"5": "Southeast AL AR FL GA KY LA MS NC SC TN VA WV",
"6": "Southwest AZ NM OK TX",
"7": "Rocky Mountains CO ID MT UT WY",
"8": "Far West AK CA HI NV OR WA",
"9": "Outlying areas AS FM GU MH MP PR PW VI"
}
 */
export const locations = [
  {
    label: 'New England',
    value: 'New England',
    states: [
      {
        label: 'CT',
        value: 'CT',
        description: 'Connecticut',
      },
      {
        label: 'ME',
        value: 'ME',
        description: 'Maine',
      },
      {
        label: 'MA',
        value: 'MA',
        description: 'Massachusetts',
      },
      {
        label: 'NH',
        value: 'NH',
        description: 'New Hampshire',
      },
      {
        label: 'RI',
        value: 'RI',
        description: 'Rhode Island',
      },
      {
        label: 'VT',
        value: 'VT',
        description: 'Vermont',
      },
    ],
  },
  {
    label: 'Mid East',
    value: 'Mid East',
    states: [
      {
        label: 'DE',
        value: 'DE',
        description: 'Delaware',
      },
      {
        label: 'DC',
        value: 'DC',
        description: 'District of Columbia',
      },
      {
        label: 'MD',
        value: 'MD',
        description: 'Maryland',
      },
      {
        label: 'NJ',
        value: 'NJ',
        description: 'New Jersey',
      },
      {
        label: 'NY',
        value: 'NY',
        description: 'New York',
      },
      {
        label: 'PA',
        value: 'PA',
        description: 'Pennsylvania',
      },
    ],
  },
  {
    label: 'Great Lakes',
    value: 'Great Lakes',
    states: [
      {
        label: 'IL',
        value: 'IL',
        description: 'Illinois',
      },
      {
        label: 'IN',
        value: 'IN',
        description: 'Indiana',
      },
      {
        label: 'MI',
        value: 'MI',
        description: 'Michigan',
      },
      {
        label: 'OH',
        value: 'OH',
        description: 'Ohio',
      },
      {
        label: 'WI',
        value: 'WI',
        description: 'Wisconsin',
      },
    ],
  },
  {
    label: 'Plains',
    value: 'Plains',
    states: [
      {
        label: 'IA',
        value: 'IA',
        description: 'Iowa',
      },
      {
        label: 'KS',
        value: 'KS',
        description: 'Kansas',
      },
      {
        label: 'MN',
        value: 'MN',
        description: 'Minnesota',
      },
      {
        label: 'MO',
        value: 'MO',
        description: 'Missouri',
      },
      {
        label: 'NE',
        value: 'NE',
        description: 'Nebraska',
      },
      {
        label: 'ND',
        value: 'ND',
        description: 'North Dakota',
      },
      {
        label: 'SD',
        value: 'SD',
        description: 'South Dakota',
      },
    ],
  },
  {
    label: 'Southeast',
    value: 'Southeast',
    states: [
      {
        label: 'AL',
        value: 'AL',
        description: 'Alabama',
      },
      {
        label: 'AR',
        value: 'AR',
        description: 'Arkansas',
      },
      {
        label: 'FL',
        value: 'FL',
        description: 'Florida',
      },
      {
        label: 'GA',
        value: 'GA',
        description: 'Georgia',
      },
      {
        label: 'KY',
        value: 'KY',
        description: 'Kentucky',
      },
      {
        label: 'LA',
        value: 'LA',
        description: 'Louisiana',
      },
      {
        label: 'MS',
        value: 'MS',
        description: 'Mississippi',
      },
      {
        label: 'NC',
        value: 'NC',
        description: 'North Carolina',
      },
      {
        label: 'SC',
        value: 'SC',
        description: 'South Carolina',
      },
      {
        label: 'TN',
        value: 'TN',
        description: 'Tennessee',
      },
      {
        label: 'VA',
        value: 'VA',
        description: 'Virginia',
      },
      {
        label: 'WV',
        value: 'WV',
        description: 'West Virginia',
      },
    ],
  },
  {
    label: 'Southwest',
    value: 'Southwest',
    states: [
      {
        label: 'AZ',
        value: 'AZ',
        description: 'Arizona',
      },
      {
        label: 'NM',
        value: 'NM',
        description: 'New Mexico',
      },
      {
        label: 'OK',
        value: 'OK',
        description: 'Oklahoma',
      },
      {
        label: 'TX',
        value: 'TX',
        description: 'Texas',
      },
    ],
  },
  {
    label: 'Rocky Mountains',
    value: 'Rocky Mountains',
    states: [
      {
        label: 'CO',
        value: 'CO',
        description: 'Colorado',
      },
      {
        label: 'ID',
        value: 'ID',
        description: 'Idaho',
      },
      {
        label: 'MT',
        value: 'MT',
        description: 'Montana',
      },
      {
        label: 'UT',
        value: 'UT',
        description: 'Utah',
      },
      {
        label: 'WY',
        value: 'WY',
        description: 'Wyoming',
      },
    ],
  },
  {
    label: 'Far West',
    value: 'Far West',
    states: [
      {
        label: 'AK',
        value: 'AK',
        description: 'Alaska',
      },
      {
        label: 'CA',
        value: 'CA',
        description: 'California',
      },
      {
        label: 'HI',
        value: 'HI',
        description: 'Hawaii',
      },
      {
        label: 'NV',
        value: 'NV',
        description: 'Nevada',
      },
      {
        label: 'OR',
        value: 'OR',
        description: 'Oregon',
      },
      {
        label: 'WA',
        value: 'WA',
        description: 'Washington',
      },
    ],
  },
  {
    label: 'Outlying areas',
    value: 'Outlying areas',
    states: [
      {
        label: 'AS',
        value: 'AS',
        description: 'American Samoa',
      },
      {
        label: 'FM',
        value: 'FM',
        description: 'Federated States of Micronesia',
      },
      {
        label: 'GU',
        value: 'GU',
        description: 'Guam',
      },
      {
        label: 'MH',
        value: 'MH',
        description: 'Marshall Islands',
      },
      {
        label: 'MP',
        value: 'MP',
        description: 'Northern Marianas',
      },
      {
        label: 'PR',
        value: 'PR',
        description: 'Puerto Rico',
      },
      {
        label: 'PW',
        value: 'PW',
        description: 'Palau',
      },
      {
        label: 'VI',
        value: 'VI',
        description: 'Virgin Islands',
      },
    ],
  },
];
