import { MajorSearchFilters, SearchFilters } from '../searchFilters';
import { classifications } from './classifications';

const getOptions = (list: unknown[], labelKey: string, valueKey: string) => {
  return (list as Record<string, string>[]).map((listItem) => ({
    label: listItem[labelKey],
    value: listItem[valueKey]?.toString(),
  }));
};

export const institutionType = [
  {
    label: 'Public',
    value: 'Public',
    institutionCodes: [1],
  },
  {
    label: 'Private (non-profit)',
    value: 'Private (non-profit)',
    institutionCodes: [2],
  },
  {
    label: 'Private (for-profit)',
    value: 'Private (for-profit)',
    institutionCodes: [3],
  },
];

export const carnegieSizeSetting = [
  {
    label: 'Vocational/Technical',
    value: 'Vocational/Technical',
    carnegieSizeCodes: [-2],
  },
  {
    label: '2-years',
    value: '2-year',
    carnegieSizeCodes: [1, 2, 3, 4, 5],
  },
  {
    label: '4-years',
    value: '4-year',
    carnegieSizeCodes: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
  },
  {
    label: 'Graduate',
    value: 'Graduate',
    carnegieSizeCodes: [18],
  },
];

export const locales = [
  {
    label: 'City',
    value: 'City',
    localeCodes: [11, 12, 13],
  },
  {
    label: 'Suburb',
    value: 'Suburb',
    localeCodes: [21, 22, 23],
  },
  {
    label: 'Town',
    value: 'Town',
    localeCodes: [31, 32, 33],
  },
  {
    label: 'Rural',
    value: 'Rural',
    localeCodes: [41, 42, 43],
  },
];

export const enrollmentSize = [
  {
    label: 'Small (<5,000 Students)',
    value: 'small',
    maxValue: 500,
  },
  {
    label: 'Medium (5,000-15,000 Students)',
    value: 'medium',
    minValue: 5000,
    maxValue: 15000,
  },
  {
    label: 'Large (>15,000 Students)',
    value: 'large',
    minValue: 15000,
  },
];

export const degree = [
  {
    label: 'Certificate program',
    value: 'Certificate',
    awardLevels: [2, 4, 6, 8, 20, 21],
  },
  {
    label: 'Associate degree',
    value: 'Associate',
    awardLevels: [3],
  },
  {
    label: 'Bachelor degree',
    value: 'Bachelor',
    awardLevels: [5],
  },
  {
    label: 'Master degree',
    value: 'Master',
    awardLevels: [7],
  },
  {
    label: 'Doctorate degree',
    value: 'Doctorate',
    awardLevels: [17, 18, 19],
  },
];

export const degreeExtended = [
  {
    label:
      'Postsecondary award, certificate, or diploma of - at least 900 but less than 1,800 clock hours, or - at least 30 but less than 60 semester or trimester credit hours, or - at least 45 but less than 90 quarter credit hours',
    category: 'Certificate',
    awardLevel: 2,
  },
  {
    label:
      'Postsecondary award, certificate, or diploma of - 1,800 or more clock hours, or - 60 or more semester or trimester credit hours, or - 90 or more quarter credit hours',
    category: 'Certificate',
    awardLevel: 4,
  },
  {
    label: 'Postbaccalaureate certificate',
    category: 'Certificate',
    awardLevel: 6,
  },
  {
    label: "Post-master's certificate",
    category: 'Certificate',
    awardLevel: 8,
  },
  {
    label: 'Postsecondary award, certificate, or diploma of less than 12 weeks',
    category: 'Certificate',
    awardLevel: 20,
  },
  {
    label: 'Postsecondary award, certificate, or diploma 12 weeks but less than 1 year',
    category: 'Certificate',
    awardLevel: 21,
  },
  {
    label: 'Associate degree',
    category: 'Associate',
    awardLevel: 3,
  },
  {
    label: 'Bachelor degree',
    category: 'Bachelor',
    awardLevel: 5,
  },
  {
    label: 'Master degree',
    category: 'Master',
    awardLevel: 7,
  },
  {
    label: 'Doctor’s degree-research/scholarship',
    category: 'Doctorate',
    awardLevel: 17,
  },
  {
    label: 'Doctor’s degree-professional practice',
    category: 'Doctorate',
    awardLevel: 18,
  },
  {
    label: 'Doctor’s degree-Other',
    category: 'Doctorate',
    awardLevel: 19,
  },
];

export const specialty = [
  {
    label: 'Historically Black College',
    value: 'isHistoricallyBlack',
  },
  {
    label: 'Tribal College',
    value: 'isTribal',
  },
  {
    label: 'Men Only College',
    value: 'isMenOnly',
  },
  {
    label: 'Women Only College',
    value: 'isWomenOnly',
  },
];

export const searchFilters = {
  personalRecommendation: {
    title: 'personal recommendation',
    fieldNames: {
      isBestFitSoc: 'careerFit' as keyof MajorSearchFilters,
      isFavoriteSoc: 'careerFavorite' as keyof MajorSearchFilters,
      hasCertification: 'certificationsEarned' as keyof MajorSearchFilters,
    },
    fieldLabels: {
      isBestFitSoc: 'Based on career fit',
      isFavoriteSoc: 'Based on career you favorited',
      hasCertification: 'Based on your certifications',
    },
  },
  areasOfStudy: {
    title: 'areas of study',
    fieldName: 'cipCodes' as keyof SearchFilters,
    filters: getOptions(classifications, 'cipTitle', 'cipCode'),
  },
  degree: {
    title: 'degree',
    fieldName: 'awardLevels' as keyof SearchFilters,
    filters: degree,
  },
};
