import { createWithEqualityFn } from 'zustand/traditional';
import { EcpSettings } from '@constants/ecp/types';

interface EcpStore {
  ecpSettings: EcpSettings | Record<string, never>;
  setEcpSettings: (ecpSettings: EcpSettings) => void;
}

const initialState = {
  ecpSettings: {},
};

export const useEcpStore = createWithEqualityFn<EcpStore>()(
  (set) => ({
    ...initialState,

    setEcpSettings: async (ecpSettings: EcpSettings | Record<string, never>) => {
      set({ ecpSettings });
    },
  }),
  Object.is,
);
