import { z } from 'zod';
import {
  AuditFieldSchema,
  admissionStatusValidator,
  applicationStatusValidator,
  applicationTypeValidator,
  deadlineTypeValidator,
  decisionValidator,
  gradeValidator,
  mongoIdValidator,
  scholarshipStatusValidator,
  seasonValidator,
  tagsValidator,
} from '../common';

export const ApplicationSchema = z.object({
  deadline: z.string().optional(),
  deadlineType: deadlineTypeValidator.optional(),
  type: applicationTypeValidator,
  status: applicationStatusValidator.optional(),
});
export type Application = z.infer<typeof ApplicationSchema>;

export const DefaultCheckListSchema = z.object({
  sentTranscription: z.boolean().optional(),
  sentRecommendation: z.boolean().optional(),
  sentTestScores: z.boolean().optional(),
  submitFafsa: z.boolean().optional(),
  receiveFinancialAid: z.boolean().optional(),
  submitEssay: z.boolean().optional(),
});
export type DefaultCheckList = z.infer<typeof DefaultCheckListSchema>;

export const CustomCheckListSchema = z.object({
  label: z.string(),
  value: z.boolean(),
});
export type CustomCheckList = z.infer<typeof CustomCheckListSchema>;

export const BeginCollegeSchema = z.object({
  year: z.number().int().optional(),
  season: seasonValidator.optional(),
  other: z.string().optional(),
});

export const ApplyingCollegeSchema = z
  .object({
    id: z.number().int().optional(),
    priority: z.number().int(),
    admissionStatus: admissionStatusValidator.optional(),
    application: ApplicationSchema.optional(),
    decision: decisionValidator.optional(),
    defaultChecklist: DefaultCheckListSchema.optional(),
    customChecklist: z.array(CustomCheckListSchema).optional(),
    memberId: z.number().int().optional(),
  })
  .merge(AuditFieldSchema);
export type ApplyingCollege = z.infer<typeof ApplyingCollegeSchema>;

const ApplyingScholarshipSchema = z
  .object({
    id: z.number().int(),
    status: scholarshipStatusValidator,
  })
  .merge(AuditFieldSchema);
export type ApplyingScholarship = z.infer<typeof ApplyingScholarshipSchema>;

export const TaskSchema = z
  .object({
    _id: mongoIdValidator.optional(),
    taskId: z.number().int().optional(),
    status: z.enum(['Active', 'Completed']),
    dueDate: z.string().optional(),
    linkUrls: z.array(z.string()).optional(),
    grades: z.array(gradeValidator),
    season: seasonValidator,
    tags: z.array(tagsValidator),
    title: z.string(),
    description: z.string(),
    isDeleted: z.boolean(),
  })
  .merge(AuditFieldSchema);
export type Task = z.infer<typeof TaskSchema>;

export const UserPlanningDocumentSchema = z
  .object({
    _id: mongoIdValidator.optional(),
    userId: z.string().or(z.number().int()),
    legacyId: z.number().int().optional(),
    currentGrade: z.number().int().optional(),
    overallGPA: z.number().min(0).max(5).optional(),
    beginCollege: BeginCollegeSchema.optional(),
    applyingColleges: ApplyingCollegeSchema.array(),
    applyingScholarships: ApplyingScholarshipSchema.array(),
    tasks: TaskSchema.array(),
  })
  .merge(AuditFieldSchema);
export type UserPlanningDocument = z.infer<typeof UserPlanningDocumentSchema>;
