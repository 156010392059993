import { z } from 'zod';
import { mongoIdValidator } from '../common';

export const CipSocCrosswalkDocumentSchema = z.object({
  _id: mongoIdValidator.optional(),
  cipCode: z.string(),
  cipTitle: z.string(),
  socCode: z.string(),
  socTitle: z.string(),
});
export type CipSocCrosswalkDocument = z.infer<typeof CipSocCrosswalkDocumentSchema>;
