import { z } from 'zod';

export const EmployerCardSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string(),
  logo: z.string(),
  logoThumb: z.string(),
  numberOfEmployees: z.string(),
  website: z.string(),
});
export type EmployerCard = z.infer<typeof EmployerCardSchema>;
