import { ECP_API_URL } from '@constants/externalRoutes';

import { Api } from './api.service';
import { EcpSettings } from '@constants/ecp/types';

export class EcpService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: ECP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public getEcpSettings(tenantId: string): Promise<void | EcpSettings> {
    return this.get<EcpSettings>(`/tenants/${tenantId}/ecp-settings`).then(this.success).catch(this.error);
  }
}

export const ecpService = new EcpService();
