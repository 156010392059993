import { z } from 'zod';

const SocCodeImageSchema = z.object({
  height: z.number(),
  width: z.number(),
  url: z.string(),
});
export type SocCodeImage = z.infer<typeof SocCodeImageSchema>;

export const SocCodeInfoSchema = z.object({
  socCode: z.string(),
  title: z.string(),
  educationInvestment: z.string(),
  projectOpenings: z.string(),
  averageSalary: z.string(),
  images: z.array(SocCodeImageSchema),
  ranked_overall_fit: z.number(),
});
export type SocCodeInfo = z.infer<typeof SocCodeInfoSchema>;
