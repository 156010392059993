import { MajorDescription } from '..';
import { cipCodeDescriptions } from './cipCodeDescriptions';
import { classifications } from './classifications';

export const areasImagesMap = new Map([
  ['1', 'majors/01agriculture.jpg'],
  ['3', 'majors/03naturalresources.jpg'],
  ['4', 'majors/04architecture.jpg'],
  ['5', 'majors/05culturalstudies.jpg'],
  ['9', 'majors/09communicationjournalism.jpg'],
  ['10', 'majors/10communicationtech.jpg'],
  ['11', 'majors/11computersciences.jpg'],
  ['12', 'majors/12personalservices.jpg'],
  ['13', 'majors/13education.jpg'],
  ['14', 'majors/14engineering.jpg'],
  ['15', 'majors/15engineeringtech.jpg'],
  ['16', 'majors/16foreignlanguages.jpg'],
  ['19', 'majors/19familysciences.jpg'],
  ['22', 'majors/22legalstudies.jpg'],
  ['23', 'majors/23englishlanguage.jpg'],
  ['24', 'majors/24liberalarts.jpg'],
  ['25', 'majors/25libraryscience.jpg'],
  ['26', 'majors/26biomedical.jpg'],
  ['27', 'majors/27math.jpg'],
  ['28', 'majors/28rotc.jpg'],
  ['29', 'majors/29militarytech.jpg'],
  ['30', 'majors/30multidisciplinary.jpg'],
  ['31', 'majors/31fitnessstudies.jpg'],
  ['32', 'majors/32basicskills.jpg'],
  ['33', 'majors/33citizenship.jpg'],
  ['34', 'majors/34healthskills.jpg'],
  ['35', 'majors/35interpersonalskills.jpg'],
  ['36', 'majors/36leisureactivities.jpg'],
  ['37', 'majors/37personalawareness.jpg'],
  ['38', 'majors/38philosophy.jpg'],
  ['39', 'majors/39theologyvocations.jpg'],
  ['40', 'majors/40physicalsciences.jpg'],
  ['41', 'majors/41sciencetech.jpg'],
  ['42', 'majors/42psychology.jpg'],
  ['43', 'majors/43security.jpg'],
  ['44', 'majors/44publicadmin.jpg'],
  ['45', 'majors/45socialsciences.jpg'],
  ['46', 'majors/46constructiontrades.jpg'],
  ['47', 'majors/47mechanic.jpg'],
  ['48', 'majors/48precisionproduction.jpg'],
  ['49', 'majors/49transportation.jpg'],
  ['50', 'majors/50visualarts.jpg'],
  ['51', 'majors/51healthprofessions.jpg'],
  ['52', 'majors/52business.jpg'],
  ['53', 'majors/53highschooldiploma.jpg'],
  ['54', 'majors/54history.jpg'],
  ['60', 'majors/60residencyprogram.jpg'],
  ['61', 'majors/60residencyprogram.jpg'],
]);

export const areasDescriptionMap = new Map(classifications.map((area) => [area.cipCode, area]));

export const majorsDescriptionMap = new Map(
  cipCodeDescriptions.map((description: MajorDescription) => [
    description.cipCode,
    { ...description, cipTitle: description.cipTitle.replace(/\.$/, '') },
  ]),
);
