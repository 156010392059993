import { UserPreferences } from '@common-types';

import { baseService } from '@services/base.service';

import useUserPreferencesStore from '@store/UserPreferencesStore';

import { OptInConnectionData } from '@interfaces/optIn';

export async function deleteSchoolFavorite(schoolId: number) {
  const response = await baseService.deleteAsync<UserPreferences>(`/user/favorites/colleges/${schoolId}`);

  useUserPreferencesStore.getState().setUserPreferences(response);
}

export async function addSchoolFavorite(id: number) {
  const response = await baseService.postAsync<UserPreferences, unknown>('/user/favorites/colleges', null, {
    params: {
      collegeId: id,
    },
  });

  useUserPreferencesStore.getState().setUserPreferences(response);
}

export async function updateUserPreferences(data: Partial<UserPreferences>) {
  const response = await baseService.patchAsync<UserPreferences, Partial<UserPreferences>>('/user/preferences', data);

  useUserPreferencesStore.getState().setUserPreferences(response);
}

export async function updateSchoolTenantSharing(schoolId: number, data: OptInConnectionData) {
  const response = await baseService.postAsync<UserPreferences, OptInConnectionData>(
    `/user/tenant-sharing/${schoolId}`,
    data,
  );

  useUserPreferencesStore.getState().setUserPreferences(response);
}

type TenantSharingData = {
  optInText: string;
  optOutText: string;
  tenantSharing: {
    collegeId: number | undefined;
    currentlySharing: boolean | undefined;
  }[];
};
export async function updateTenantSharing(data: TenantSharingData) {
  const response = await baseService.patchAsync<UserPreferences, TenantSharingData>('/user/tenant-sharing', data);

  useUserPreferencesStore.getState().setUserPreferences(response);
}

export async function addMajorFavorite(cipCode: string) {
  const response = await baseService.postAsync<UserPreferences, { cipCode: string }>(`/user/favorites/majors`, {
    cipCode,
  });

  useUserPreferencesStore.getState().setUserPreferences(response);
}

export async function deleteFavoriteMajor(cipCode: string) {
  const response = await baseService.deleteAsync<UserPreferences>(`/user/favorites/majors/${cipCode}`);

  useUserPreferencesStore.getState().setUserPreferences(response);
}
