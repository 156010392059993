import { z } from 'zod';
import { awardTypeValidator, scholarshipStatusValidator } from '../common';
import { SchoolSchema } from '../ipeds/responses';
import {
  AgeRangeSchema,
  ApplicationDateSchema,
  ApplicationFeeSchema,
  ApplicationRequirementSchema,
  ApplicationSubmissionSchema,
  AwardInclusionsSchema,
  AwardTotalsSchema,
  CitizenshipSchema,
  ClassificationSchema,
  CodeDescriptionSchema,
  DisabilityTypeSchema,
  GenderSchema,
  InstitutionTypeSchema,
  InstitutionalAidSchema,
  LevelOfStudySchema,
  LimitRangeSchema,
  MaritalStatusSchema,
  MilitaryServiceSchema,
  ProgramCategorySchema,
  RaceSchema,
  ScholarshipDocumentSchema,
  ScoreRequirementSchema,
  ScoreSchema,
  SectorSchema,
  WebAddressSchema,
  WorkStatusSchema,
} from './model';

const AreaOfStudyMajorSchema = z.object({
  cipCode: z.string(),
  cipCodeDescription: z.string(),
  isFavorite: z.boolean(),
});
export type AreaOfStudyMajor = z.infer<typeof AreaOfStudyMajorSchema>;

export const CardAreaOfStudySchema = z.object({
  areaOfStudy: z.string(),
  areaOfStudyDescription: z.string(),
  cipCodes: z.array(AreaOfStudyMajorSchema),
});
export type CardAreaOfStudy = z.infer<typeof CardAreaOfStudySchema>;

const SchoolMatches = z.object({
  hasState: z.boolean().optional(),
  hasAreaOfStudy: z.boolean().optional(),
  hasInstitutionType: z.boolean().optional(),
});

export const ScholarshipCardInfoSchema = ScholarshipDocumentSchema.pick({
  id: true,
  awardType: true,
  programName: true,
  donorName: true,
  description: true,
  applicationDates: true,
  moneyRange: true,
  areasOfStudy: true,
  webAddress: true,
}).merge(SchoolMatches);
export type ScholarshipCardInfo = z.infer<typeof ScholarshipCardInfoSchema>;

export const ScholarshipCardSchema = z
  .object({
    id: z.number().int(),
    awardType: awardTypeValidator,
    programName: z.string(),
    donorName: z.string(),
    description: z.string(),
    applicationDate: z.object({
      deadlineMonth: z.number().int().nullable(),
      deadlineDay: z.number().int().nullable(),
      deadlineYear: z.number().int().nullable(),
      isExpired: z.boolean().optional(),
    }),
    moneyRange: LimitRangeSchema,
    areasOfStudy: z.array(CardAreaOfStudySchema),
    isFavorite: z.boolean().optional(),
    applicationStatus: scholarshipStatusValidator.optional(),
    webAddress: WebAddressSchema,
  })
  .merge(SchoolMatches);
export type ScholarshipCard = z.infer<typeof ScholarshipCardSchema>;

export const ScholarshipsResultSchema = z.object({
  scholarships: z.array(ScholarshipCardSchema),
  totalCount: z.number().int().nullable(),
  limit: z.number().int(),
  skip: z.number().int().nullable(),
});
export type ScholarshipsResult = z.infer<typeof ScholarshipsResultSchema>;

export const ScholarshipDetailSchema = z.object({
  yearLastSurveyed: z.number().int(),
  programName: z.string(),
  description: z.string(),
  donorName: z.string(),
  donorDescription: z.string(),
  programNameAbbr: z.string(),
  renewalRequirements: z.string(),
  programCategories: ProgramCategorySchema,
  employment: z.array(CodeDescriptionSchema),
  essayCategories: z.array(CodeDescriptionSchema),
  stateResidence: z.array(z.string()),
  stateRestrictions: z.array(z.string()),
  isStateFunded: z.boolean(),
  isRenewable: z.boolean(),
  applicantRequiredInHighSchool: z.boolean().nullable(),
  applicantNotRequiredInHighSchool: z.boolean().nullable(),
  areHighSchoolEligible: z.boolean().nullable(),
  areHighSchoolNotEligible: z.boolean().nullable(),
  is911Related: z.boolean(),
  classification: ClassificationSchema,
  awardRange: LimitRangeSchema,
  moneyRange: LimitRangeSchema,
  awardTotals: AwardTotalsSchema,
  awardInclusions: AwardInclusionsSchema,
  workStatus: WorkStatusSchema,
  sector: SectorSchema,
  levelOfStudy: LevelOfStudySchema,
  institutionType: InstitutionTypeSchema,
  citizenship: CitizenshipSchema,
  score: ScoreSchema,
  scoreRequirements: ScoreRequirementSchema,
  ageRange: AgeRangeSchema,
  gender: GenderSchema,
  maritalStatus: MaritalStatusSchema,
  race: RaceSchema,
  disabilityType: DisabilityTypeSchema,
  militaryService: MilitaryServiceSchema,
  institutionalAid: InstitutionalAidSchema,
  applicationSubmission: ApplicationSubmissionSchema,
  applicationRequirements: ApplicationRequirementSchema,
  applicationFee: ApplicationFeeSchema,
  applicationDates: ApplicationDateSchema,
  applicationStatus: scholarshipStatusValidator.optional(),
  webAddress: WebAddressSchema,
  awardType: awardTypeValidator,
  areasOfStudy: z.array(CardAreaOfStudySchema),
  ethnicity: z.array(z.string()),
  isFavorite: z.boolean(),
  schools: z.array(SchoolSchema),
});
export type ScholarshipDetail = z.infer<typeof ScholarshipDetailSchema>;

export const ScholarshipAwardSchema = z.object({
  minLimit: z.number(),
  maxLimit: z.number(),
});
