import { z } from 'zod';

export enum InstitutionTypes {
  PUBLIC = 1,
  PRIVATE_NONPROFIT = 2,
  PRIVATE_FORPROFIT = 3,
}

export enum CarnegieSizeSettings {
  TWO_YEAR = '2-year',
  FOUR_YEAR = '4-year',
  GRADUATE = 'Graduate',
  VOCATIONAL_TECHNICAL = 'Vocational/Technical',
}

export const institutionTypeGroups = new Map([
  ['Public', [InstitutionTypes.PUBLIC]],
  ['Private (non-profit)', [InstitutionTypes.PRIVATE_NONPROFIT]],
  ['Private (for-profit)', [InstitutionTypes.PRIVATE_FORPROFIT]],
]);

export const StudyCostSchema = z.object({
  min: z.number(),
  max: z.number(),
});
export type StudyCost = z.infer<typeof StudyCostSchema>;
