import { createWithEqualityFn } from 'zustand/traditional';
import type { EnrolledSchool, School, SchoolWithScholarships, SetSchoolInfo, ScholarshipCard } from '@common-types';

import useNotificationStore from '@store/NotificationStore';

import { baseService } from '@services/base.service';

export interface TenantAdminAssignment {
  ipedsId: number;
  school: SchoolWithScholarships;
}

export interface HomePageStore {
  adminHomePageData: TenantAdminAssignment;
  landingPageSchoolData: EnrolledSchool;
  updateAdminHomePageData: (data: SetSchoolInfo, notifyMessage: string) => Promise<void>;
  getLandingPageData: () => Promise<void>;
  setLandingPageData: (landingPageData?: EnrolledSchool) => Promise<void>;
  setAdminHomePageData: (data: TenantAdminAssignment) => void;
  getAdminHomePageData: (schoolId: string) => Promise<void>;
}

const initialState = {
  adminHomePageData: {} as TenantAdminAssignment,
  landingPageSchoolData: {} as EnrolledSchool,
};

export const useHomePageStore = createWithEqualityFn<HomePageStore>()(
  (set, get) => ({
    ...initialState,

    setAdminHomePageData: (data: TenantAdminAssignment) => {
      set({ adminHomePageData: data ?? {} });
    },

    getAdminHomePageData: async (schoolId: string) => {
      const school = await baseService.getAsync<School>(`/school-info/${schoolId}`);
      if (school) {
        set({ adminHomePageData: { ipedsId: parseInt(schoolId, 10), school } });
      }
    },

    updateAdminHomePageData: async (data: SetSchoolInfo, notifyMessage: string) => {
      const {
        adminHomePageData: { ipedsId },
        setAdminHomePageData,
      } = get();

      const updatedSchool = await baseService.patchAsync<School, SetSchoolInfo>(`/school-info/${ipedsId}`, data);

      setAdminHomePageData({ ipedsId, school: updatedSchool });

      useNotificationStore.getState().notify({
        title: 'Success!',
        message: notifyMessage,
        severity: 'success',
      });
    },

    getLandingPageData: async () => {
      const response = await baseService.getAsync<EnrolledSchool>('/user/enrolled');
      set({
        landingPageSchoolData: response ?? ({} as EnrolledSchool),
      });
    },

    setLandingPageData: async (landingPageData?: EnrolledSchool) => {
      if (landingPageData?.scholarships && landingPageData?.scholarships?.length < 9) {
        const response = await baseService.getAsync<ScholarshipCard[]>(
          `/scholarship-cards/${landingPageData.id}/similar`,
        );

        const similarScholarships = response ?? [];

        set({
          landingPageSchoolData: {
            ...landingPageData,
            scholarships: [...(landingPageData.scholarships ?? []), ...similarScholarships],
          },
        });
      } else {
        set({ landingPageSchoolData: landingPageData ?? ({} as EnrolledSchool) });
      }
    },
  }),
  Object.is,
);
