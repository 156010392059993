export enum AwardTypeEnum {
  FELLOWSHIP = 'FELLOWSHIP',
  FORGIVABLE_LOAN = 'FORGIVABLE LOAN',
  GRANT = 'GRANT',
  PRIZE = 'PRIZE',
  SCHOLARSHIP = 'SCHOLARSHIP',
}

export enum ScholarshipSort {
  NAME = 'Name',
  DEADLINE = 'Deadline',
  AWARD = 'Award',
}
