import { z } from 'zod';

const ImageSchema = z.object({
  height: z.number(),
  width: z.number(),
  url: z.string(),
});

const CareerSchema = z.object({
  images: z.array(ImageSchema).optional(),
  onetsoc_code: z.string(),
  saved: z.boolean().optional(),
  title: z.string().optional(),
});
export type Career = z.infer<typeof CareerSchema>;

const EmploymentSchema = z.object({
  current_positions: z.number(),
  future_positions: z.number(),
  growth: z.number(),
  openings: z.number(),
  year: z.number(),
});

const OnTheJobSchema = z.object({
  label: z.string(),
  short_label: z.string(),
  time: z.number(),
});

const CareerMatchSchema = z.object({
  ar_fit: z.object({
    breakdowns: z.array(
      z.object({
        aptitude: z.string(),
        occupation_score: z.number(),
        user_score: z.number(),
      }),
    ),
    fit: z.number(),
    highlights: z.array(z.string()),
    scaled_fit: z.number(),
  }),
  composite_scaled_fit: z.number(),
  ir_fit: z.object({
    fit: z.number(),
    occupation_ranked_irs: z.array(z.string()),
    scaled_fit: z.number(),
    user_ranked_irs: z.array(z.string()),
  }),
  onetsoc_code: z.string(),
  ranked_ar_fit: z.number(),
  ranked_ir_fit: z.number(),
  ranked_overall_fit: z.number(),
  raw_overall_fit: z.object({
    fit: z.number(),
  }),
  recommendable: z.boolean(),
  scaled_overall_fit: z.object({
    fit: z.number(),
  }),
});

const CareerMatchFitSchema = z.object({
  ranked_ar_fit: z.number(),
  ranked_ir_fit: z.number(),
  ranked_overall_fit: z.number(),
});

const CareerSalarySchema = z.object({
  national: z.object({
    average: z.number(),
  }),
});

const AverageSalarySchema = z.object({
  average: z.number(),
  starting: z.number(),
  top: z.number(),
});

const EmploymentNationalSchema = z.object({
  national: z.object({
    openings: z.number(),
  }),
});

const InvestmentEducationSchema = z.object({
  education: z.object({
    short_label: z.string(),
  }),
});

const EducationSchema = z.object({
  certification: z.string(),
  license: z.string(),
  on_the_job: z.string(),
  summary: z.string(),
});

const EmploymentFieldSchema = z.object({
  by_state: z.object({}).catchall(EmploymentSchema), // 'CA': EmploymentSchema
  growth_national_average: z.number(),
  national: EmploymentSchema,
});

const IndustriesSchema = z.object({
  national: z.array(
    z.object({
      title: z.string(),
      total_employment: z.number(),
    }),
  ),
});

const InvestmentSchema = z.object({
  common_degrees: z.array(
    z.object({
      category_description: z.string(),
      value: z.number(),
    }),
  ),
  education: OnTheJobSchema,
  majors: z.array(
    z.object({
      cip_code: z.string(),
      cip_family: z.string(),
      num_related_majors: z.number(),
      num_related_occupations: z.number(),
      title: z.string(),
    }),
  ),
  on_the_job: OnTheJobSchema,
  overall: OnTheJobSchema,
});

const SalarySchema = z.object({
  by_state: z.object({}).catchall(AverageSalarySchema), // 'CA': AverageSalarySchema
  national: AverageSalarySchema,
});

const RelatedOccupationsSchema = z.object({
  employment: EmploymentNationalSchema,
  images: z.array(ImageSchema),
  investment: InvestmentEducationSchema,
  match: CareerMatchFitSchema,
  onetsoc_code: z.string(),
  salary: CareerSalarySchema,
  title: z.string(),
});

export const FavoriteCareerSchema = CareerSchema.extend({
  education: EducationSchema.optional(),
  employment: EmploymentFieldSchema.optional(),
  favorite: z.boolean().optional(),
  industries: IndustriesSchema.optional(),
  inserted_at: z.string().optional(),
  investment: InvestmentSchema.optional(),
  lay_titles: z.array(z.string()).optional(),
  long_description: z.array(z.string()).optional(),
  match: CareerMatchSchema.optional(),
  rank: z.string().nullable().optional(),
  related_occupations: z.array(RelatedOccupationsSchema).optional(),
  salary: SalarySchema.optional(),
  short_description: z.array(z.string()).optional(),
  tasks: z.array(z.string()).optional(),
});
export type FavoriteCareer = z.infer<typeof FavoriteCareerSchema>;

export const CareerInfoSchema = CareerSchema.extend({
  education: EducationSchema,
  employment: EmploymentFieldSchema,
  industries: IndustriesSchema,
  investment: InvestmentSchema,
  lay_titles: z.array(z.string()),
  long_description: z.array(z.string()),
  match: CareerMatchSchema.optional(),
  related_occupations: z.array(
    RelatedOccupationsSchema.extend({
      saved: z.boolean(),
    }),
  ),
  salary: SalarySchema,
  short_description: z.array(z.string()),
  tasks: z.array(z.string()),
});
export type CareerInfo = z.infer<typeof CareerInfoSchema>;

const FavoriteCollegeSchema = z.object({
  favorite: z.boolean(),
  ipeds_id: z.number().int(),
});
export type DiscoveryFavoriteCollege = z.infer<typeof FavoriteCollegeSchema>;

export const FavoriteCollegesSchema = z.object({
  data: z.array(FavoriteCollegeSchema),
});

export const OverallFitCareerSchema = CareerSchema.extend({
  employment: EmploymentNationalSchema.optional(),
  investment: InvestmentEducationSchema.optional(),
  match: CareerMatchFitSchema.optional(),
  salary: CareerSalarySchema.optional(),
});
export type OverallFitCareer = z.infer<typeof OverallFitCareerSchema>;

const TenantIdsSchema = z.object({
  id: z.number().int(),
  inserted_at: z.string(),
  role_id: z.number().int(),
});
export type TenantIds = z.infer<typeof TenantIdsSchema>;

export const MeSchema = z.object({
  access_to_igp_section: z.boolean(),
  batteries: z.array(
    z.object({
      product_version: z.string(),
      recommendation_status: z.string(),
      started_at: z.string(),
      status: z.string(),
    }),
  ),
  bio_status: z.string(),
  enforced_user: z.boolean(),
  first_name: z.string(),
  future_plans: z.unknown().nullable(),
  grade: z.unknown().nullable(),
  has_saml_identity: z.boolean(),
  has_youscience_identity: z.boolean(),
  holding_results: z.boolean(),
  id: z.number().int(),
  is_only_retail_admin: z.boolean(),
  is_tenant_admin: z.boolean(),
  is_tenant_manager: z.boolean(),
  party_ids: z.unknown().nullable(),
  password_expiration_remaining_days: z.number().int().nullable(),
  product_version: z.string(),
  require_password_change_reminder: z.boolean(),
  require_password_reset: z.boolean(),
  retail_ids: z.unknown().nullable(),
  state_id: z.number().int(),
  tenant_ids: z.array(TenantIdsSchema),
  tenant_state_id: z.number().int(),
  user_status: z.string(),
});

const CertificationHistorySchema = z.object({
  certificateEarned: z.string(),
  certificateLink: z.string(),
  cips: z.array(z.string()),
  createdStamp: z.string(),
  districtName: z.string(),
  districtNcesId: z.string(),
  examDate: z.string(),
  examScore: z.string(),
  groupCode: z.string(),
  groupName: z.string(),
  isCertAvailable: z.string(),
  lastUpdatedStamp: z.string(),
  partyId: z.string(),
  productDesignation: z.string(),
  productId: z.string(),
  productName: z.string(),
  schoolName: z.string(),
  schoolNcesId: z.string(),
  scoreReportLink: z.string(),
  testPacketId: z.string(),
  voucherId: z.string(),
});
export type CertificationHistory = z.infer<typeof CertificationHistorySchema>;

const CertificationSchema = z.object({
  certificationHistory: CertificationHistorySchema.array(),
  createdStamp: z.string(),
  districtName: z.string(),
  districtNcesId: z.string(),
  dob: z.string(),
  firstName: z.string(),
  gradYear: z.string(),
  lastName: z.string(),
  lastUpdatedStamp: z.string(),
  mi: z.string(),
  partyId: z.string(),
  ssid: z.string(),
  stateId: z.string(),
  studentNumber: z.string(),
});
export type Certification = z.infer<typeof CertificationSchema>;

const DiscoveryMeSchema = z.object({
  access_to_igp_section: z.boolean(),
  batteries: z.array(
    z.object({
      product_version: z.string(),
      recommendation_status: z.string(),
      started_at: z.string(),
      status: z.string(),
    }),
  ),
  bio_status: z.string(),
  enforced_user: z.boolean(),
  first_name: z.string(),
  future_plans: z.unknown().nullable(),
  grade: z.unknown().nullable(),
  has_saml_identity: z.boolean(),
  has_youscience_identity: z.boolean(),
  holding_results: z.boolean(),
  id: z.number(),
  is_only_retail_admin: z.boolean(),
  is_tenant_admin: z.boolean(),
  is_tenant_manager: z.boolean(),
  party_ids: z.unknown().nullable(),
  password_expiration_remaining_days: z.unknown().nullable(),
  product_version: z.string(),
  require_password_change_reminder: z.boolean(),
  require_password_reset: z.boolean(),
  retail_ids: z.unknown().nullable(),
  state_id: z.number(),
  tenant_ids: z.array(TenantIdsSchema),
  tenant_state_id: z.number(),
  user_status: z.string(),
});
export type DiscoveryMe = z.infer<typeof DiscoveryMeSchema>;
