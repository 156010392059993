import { MajorDescription } from '..';

//If this file is updated, all 4 digit cipCodes ending in 0 need to be truncated to 3 characters.  Also, all periods at the end of the cipTitle

export const cipCodeDescriptions: MajorDescription[] = [
  {
    cipFamily: '1',
    cipCode: '1',
    cipTitle: 'Agricultural/Animal/Plant/Veterinary Science and Related Fields',
    cipDefinition:
      'Instructional programs that focus on agriculture, animal, plant, veterinary, and related sciences and that prepares individuals to apply specific knowledge, methods, and techniques to the management and performance of agricultural and veterinary operations.',
  },
  {
    cipFamily: '1',
    cipCode: '1.00',
    cipTitle: 'Agriculture, General',
    cipDefinition: 'Instructional content is defined in code 1.0000.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0000',
    cipTitle: 'Agriculture, General',
    cipDefinition:
      'A program that focuses on the general principles and practice of agricultural research and production and that may prepare individuals to apply this knowledge to the solution of practical agricultural problems.  Includes instruction in basic animal, plant, and soil science; animal husbandry and plant cultivation; soil conservation; and agricultural operations such as farming, ranching, and agricultural business.',
    CrossReferences: '14.0301 - Agricultural Engineering.',
  },
  {
    cipFamily: '1',
    cipCode: '1.01',
    cipTitle: 'Agricultural Business and Management',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0101 - 1.0199.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0101',
    cipTitle: 'Agricultural Business and Management, General',
    cipDefinition:
      'A  general program that focuses on modern business and economic principles involved in the organization, operation, and management of agricultural enterprises.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0102',
    cipTitle: 'Agribusiness/Agricultural Business Operations',
    cipDefinition:
      'A program that prepares individuals to manage agricultural businesses and agriculturally related operations within diversified corporations.  Includes instruction in agriculture, agricultural specialization, business management, accounting, finance, marketing, planning, human resources management, and other managerial responsibilities.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0103',
    cipTitle: 'Agricultural Economics',
    cipDefinition:
      'A program that focuses on the application of economics to the analysis of resource allocation, productivity, investment, and trends in the agricultural sector, both domestically and internationally.  Includes instruction in economics and related subfields as well as applicable agricultural fields.',
    CrossReferences: '3.0204 - Environmental/Natural Resource Economics.',
    Examples: 'Examples: - Agroeconomics',
  },
  {
    cipFamily: '1',
    cipCode: '1.0104',
    cipTitle: 'Farm/Farm and Ranch Management',
    cipDefinition:
      'A program that prepares individuals to manage farms, ranches, and similar enterprises.  Includes instruction in applicable agricultural specialization, business management, accounting, taxation, capitalization, purchasing, government programs and regulations, operational planning and budgeting, contracts and negotiation, and estate planning.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0105',
    cipTitle: 'Agricultural/Farm Supplies Retailing and Wholesaling',
    cipDefinition:
      'A program  that prepares individuals to sell agricultural products and supplies, provide support services to agricultural enterprises, and purchase and market agricultural products.  Includes instruction in basic business management, marketing, retailing and wholesaling operations, and applicable principles of agriculture and agricultural operations.',
    CrossReferences:
      '52.1803 - Retailing and Retail Operations., 52.1901 - Auctioneering., 52.0202 - Purchasing, Procurement/Acquisitions and Contracts Management.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0106',
    cipTitle: 'Agricultural Business Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to perform specialized support functions related to agricultural business offices and operations and to operate agricultural office equipment, software, and information systems.  Includes instruction in basic agricultural business principles, office management, equipment operation, standard software, and database management.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0199',
    cipTitle: 'Agricultural Business and Management, Other',
    cipDefinition: 'Any instructional program in agricultural business and management not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.02',
    cipTitle: 'Agricultural Mechanization',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0201 - 1.0299.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0201',
    cipTitle: 'Agricultural Mechanization, General',
    cipDefinition:
      'A program that generally prepares individuals to sell, select, and service agricultural or agribusiness technical equipment and facilities, including computers, specialized software, power units, machinery, equipment structures, and utilities.  Includes instruction in agricultural power systems, planning and selecting materials for the construction of support facilities, mechanical practices associated with irrigation and water conservation, erosion control, and agricultural data processing systems.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0204',
    cipTitle: 'Agricultural Power Machinery Operation',
    cipDefinition:
      'A program that prepares individuals to operate specialized farm, ranch, and agribusiness power equipment of a stationary, mobile, or hand-operated nature. Includes instruction in operating specialized equipment such as terrestrial and airborne crop-spraying equipment; tractors and hauling equipment; planting and harvesting equipment; cutting equipment; power sources and systems for silos, irrigation, pumping, and applications such as dairy, feeding and shearing operations; processing equipment; and applicable electrical, mechanical, and safety principles.',
    CrossReferences: '49.0202 - Construction/Heavy Equipment/Earthmoving Equipment Operation.',
    Examples: 'Examples: - Farm Equipment Operator',
  },
  {
    cipFamily: '1',
    cipCode: '1.0205',
    cipTitle: 'Agricultural Mechanics and Equipment/Machine Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to maintain and repair specialized farm, ranch, and agribusiness power equipment and vehicles.  Includes instruction in the principles of diesel, combustion, electrical, steam, hydraulic, and mechanical systems and their application to the maintenance of terrestrial and airborne crop-spraying equipment; tractors and hauling equipment; planting and harvesting equipment; cutting equipment; power sources and systems for silos; irrigation and pumping equipment; dairy, feeding, and shearing operations; and processing systems.',
    Examples:
      'Examples: - Agricultural Equipment Mechanic, - Agricultural Equipment Technician, - Agricultural Machinery Technician, - Farm Equipment Service Technician',
  },
  {
    cipFamily: '1',
    cipCode: '1.0207',
    cipTitle: 'Irrigation Management Technology/Technician',
    cipDefinition:
      'A program that prepares individuals for employment in the field of agricultural technology and irrigation. Includes instruction in irrigation and drainage methodologies, pumping and delivery systems, system design and evaluation, and principles of installation and repair of irrigation systems.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0299',
    cipTitle: 'Agricultural Mechanization, Other',
    cipDefinition: 'Any instructional program in agricultural mechanization not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.03',
    cipTitle: 'Agricultural Production Operations',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0301 - 1.0399.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0301',
    cipTitle: 'Agricultural Production Operations, General',
    cipDefinition:
      'A program that focuses on the general planning, economics, and use of facilities, natural resources, equipment, labor, and capital to produce plant and animal products, and that may prepare individuals for work in farming, ranching, and agribusiness.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0302',
    cipTitle: 'Animal/Livestock Husbandry and Production',
    cipDefinition:
      'A program that prepares individuals to select, breed, care for, process, and market livestock and small farm animals.  Includes instruction in basic animal science, animal nutrition, and animal health as applied to various species and breeds; design and operation of housing, feeding, and processing facilities; and related issues of safety, applicable regulations, logistics, and supply.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0303',
    cipTitle: 'Aquaculture',
    cipDefinition:
      'A program that prepares individuals to select, culture, propagate, harvest, and market domesticated fish, shellfish, and marine plants, both freshwater and saltwater.  Includes instruction in the basic principles of aquatic and marine biology; health and nutrition of aquatic and marine life; design and operation of fish farms, breeding facilities, culture beds, and related enterprises; and related issues of safety, applicable regulations, logistics, and supply.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0304',
    cipTitle: 'Crop Production',
    cipDefinition:
      'A program that prepares individuals to cultivate grain, fiber, forage, oilseed, fruits and nuts, vegetables, and other domesticated plant products.  Includes instruction in basic principles of plant science, health, and nutrition as applied to particular species and breeds; soil preparation and irrigation; pest management; planting and harvesting operations; product marketing; and applicable issues of safety, regulation, logistics, and supply.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0306',
    cipTitle: 'Dairy Husbandry and Production',
    cipDefinition:
      'A program that prepares individuals to manage the selection and care of dairy animals and associated dairy farm and processing facilities and operations.  Includes instruction in basic animal and dairy science; dairy animal nutrition and health; design and operation of dairy housing, feeding, and processing facilities and equipment; and related issues of safety, sanitation, shipping and handling, and applicable regulations.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0307',
    cipTitle: 'Horse Husbandry/Equine Science and Management',
    cipDefinition:
      'A program that prepares individuals to manage the selection, breeding, care, and maintenance of work, athletic, and show horses; and to manage horse farms, stables, tracks and related equipment and operations.  Includes instruction in applicable principles of animal science, care, and health; stable and track management; design and operation of facilities and equipment; and related issues such as regulations, business management; and logistics.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0308',
    cipTitle: 'Agroecology and Sustainable Agriculture',
    cipDefinition:
      'A program that focuses on agricultural principles and practices that, over the long term, enhance environmental quality, make efficient use of nonrenewable resources, integrate natural biological cycles and controls, and are economically viable and socially responsible; and that may prepare individuals to apply this knowledge to the solution of agricultural and environmental problems. Includes instruction in principles of agroecology, crop and soil sciences, entomology, horticulture, animal science, weed science and management, soil fertility and nutrient cycling, applied ecology, agricultural economics, and rangeland ecology and watershed management.',
    CrossReferences: '30.3301 - Sustainability Studies.',
    Examples: 'Examples: - Agroecology, - Sustainable Agriculture, - Organic Farming',
  },
  {
    cipFamily: '1',
    cipCode: '1.0309',
    cipTitle: 'Viticulture and Enology',
    cipDefinition: 'Moved from 1.0309 to 1.1004',
  },
  {
    cipFamily: '1',
    cipCode: '1.031',
    cipTitle: 'Apiculture',
    cipDefinition:
      'A program that focuses on improving the beekeeping industry, promoting pollination of fruits and vegetables, and bee colony expansion and maintenance. Includes instruction in apiary behavior, apiary equipment and supplies, beekeeping, bee pests and parasites, handling bees, hive set up and care, queen rearing, and seasonal management practices.',
    CrossReferences: '36.0120 - Beekeeping.',
    Examples: 'Examples: - Scientific Beekeeping, - Apiary Management',
  },
  {
    cipFamily: '1',
    cipCode: '1.0399',
    cipTitle: 'Agricultural Production Operations, Other',
    cipDefinition: 'Any instructional program in agricultural production operations not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.04',
    cipTitle: 'Agricultural and Food Products Processing',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0401 - 1.0480.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0401',
    cipTitle: 'Agricultural and Food Products Processing',
    cipDefinition:
      'A program that prepares individuals to receive, inspect, store, process, and package agricultural products in the form of human food consumables, animal or plant food, or other industrial products.  Includes instruction in the nutrient and industrial properties of various agricultural products; logistics and storage procedures; chemical and mechanical processing operations; packaging; safety and health requirements; and related technical and business principles.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0480',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '1',
    cipCode: '1.05',
    cipTitle: 'Agricultural and Domestic Animal Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0504 - 1.0599.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0504',
    cipTitle: 'Dog/Pet/Animal Grooming',
    cipDefinition:
      'A program that prepares individuals to provide cosmetic services for household pets, show animals, work animals and animal athletes.  Includes instruction in skin, coat, and pelt grooming and styling; skin care; hoof, nail, and claw care; animal massage; minor first aid and veterinary referral; animal grooming products and applications; health and safety; and applicable regulations.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0505',
    cipTitle: 'Animal Training',
    cipDefinition:
      'A program that prepares individuals to teach and exercise animals for leisure, sport, show, and professional purposes.  Includes instruction in animal psychology, health, and safety; human-animal interaction; learning and behavior styles associated with different breeds and species; and the technical and pedagogical aspects of training animals for such specific functions as obedient household pets, performing show animals, animal athletes, caregiving (e.g., seeing-eye dogs), search and rescue, and police/security work.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0507',
    cipTitle: 'Equestrian/Equine Studies',
    cipDefinition:
      'A program that focuses on the horse, horsemanship, and related subjects and prepares individuals to care for horses and horse equipment; ride and drive horses for leisure, sport, show, and professional purposes; and manage the training of horses and riders.  Includes instruction in horse breeding, nutrition, health, and safety; history of the horse and horsemanship; horse development and training; riding and equestrian technique; stable, paddock, and track management; and equipment maintenance and repair.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0508',
    cipTitle: 'Taxidermy/Taxidermist',
    cipDefinition:
      'A program that prepares individuals to reproduce life-like three-dimensional representations of living animals for permanent display using elements of the deceased specimen(s) as well as artificial materials, and to manage taxidermy services and businesses.  Includes instruction in specimen grading and selection, caping and fleshing, preservation procedures, tanning, drying, measurements, form and armature construction, carpentry and woodworking, molding and casting, drawing, sculptural techniques, painting and finishing techniques, basic business operations, customer communications, and applicable laws and regulations.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0509',
    cipTitle: 'Farrier Science',
    cipDefinition:
      'A program of study that prepares individuals to become specialists in equine hoof care (farriers). Includes instruction in equine science, equine health and first aid, general horseshoeing, corrective horseshoeing, gait analysis, and metal forging techniques using gas or coal.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0599',
    cipTitle: 'Agricultural and Domestic Animal Services, Other',
    cipDefinition: 'Any instructional program in agricultural and domestic animal services not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.06',
    cipTitle: 'Applied Horticulture and Horticultural Business Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0601 - 1.0699.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0601',
    cipTitle: 'Applied Horticulture/Horticulture Operations, General',
    cipDefinition:
      'A program that focuses on the general production and processing of domesticated plants, shrubs, flowers, foliage, trees, groundcovers, and related plant materials; the management of technical and business operations connected with horticultural services; and the basic scientific principles needed to understand plants and their management and care.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0603',
    cipTitle: 'Ornamental Horticulture',
    cipDefinition:
      'A program that focuses on  domesticated plants and plant materials used for decorative and recreational applications and prepares individuals to breed, grow, and utilize ornamental plant varieties for commercial and aesthetic purposes.  Includes instruction in applicable plant science subjects, the environmental design and artistic aspects of horticultural product usage, and the management of horticultural operations.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0604',
    cipTitle: 'Greenhouse Operations and Management',
    cipDefinition:
      'A program that prepares individuals to produce, store, and deliver plant species in controlled indoor environments for wholesale, commercial, research, or other purposes.  Includes instruction in applicable principles of plant science; climate, irrigation, and nutrition control equipment operation and maintenance; facilities management; inventory control; safety procedures; and personnel supervision.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0605',
    cipTitle: 'Landscaping and Groundskeeping',
    cipDefinition:
      'A program that prepares individuals to manage and maintain indoor and/or outdoor ornamental and recreational plants and groundcovers and related conceptual designs established by landscape architects, interior designers, enterprise owners or managers, and individual clients.  Includes instruction in applicable principles of horticulture, gardening, plant and soil irrigation and nutrition, turf maintenance, plant maintenance, equipment operation and maintenance, personnel supervision, and purchasing.',
    CrossReferences: '4.0601 - Landscape Architecture.',
    Examples: 'Examples: - Greenspace',
  },
  {
    cipFamily: '1',
    cipCode: '1.0606',
    cipTitle: 'Plant Nursery Operations and Management',
    cipDefinition:
      'A program that prepares individuals to operate and manage outdoor plant farms, tree and shrub nurseries, and related facilities that develop domesticated plant products for propagation, harvesting, and transplantation.  Includes instruction in applicable principles of plant science, farm and business management, nursery operations, equipment operation and maintenance, safety procedures, and personnel supervision.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0607',
    cipTitle: 'Turf and Turfgrass Management',
    cipDefinition:
      'A program that focuses on turfgrasses and related groundcover plants and prepares individuals to develop ornamental or recreational grasses and related products; plant, transplant, and manage grassed areas; and to produce and store turf used for transplantation.  Includes instruction in applicable plant sciences, genetics of grasses, turf science, use analysis, turf management, and related economics.',
    Examples: 'Examples: - Golf and Sports Turf Management',
  },
  {
    cipFamily: '1',
    cipCode: '1.0608',
    cipTitle: 'Floriculture/Floristry Operations and Management',
    cipDefinition:
      'A program that prepares individuals to operate and manage commercial and contract florist enterprises, supply and delivery services, and flower catering services.  Includes instruction in the applicable principles of plant science; purchasing, storage, and delivery systems; floral design and arranging; and principles of business management.',
    Examples: 'Examples: - Floral Arrangement, - Floral Design, - Floristry, - Retail Floristry',
  },
  {
    cipFamily: '1',
    cipCode: '1.0609',
    cipTitle: 'Public Horticulture',
    cipDefinition:
      'A program of study that focuses on combining traditional horticulture and public education and outreach. Includes instruction in botany, soil science, plant science, agricultural economics, leadership, and business.',
    CrossReferences: '36.0123 - Master Gardener/Gardening.',
  },
  {
    cipFamily: '1',
    cipCode: '1.061',
    cipTitle: 'Urban and Community Horticulture',
    cipDefinition:
      'A program that focuses on principles and techniques for the production of horticultural crops in an urban environment. Includes instruction in ecology, environmental sustainability, food production systems, and plant and soil sciences.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0680',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0699',
    cipTitle: 'Applied Horticulture/Horticultural Business Services, Other',
    cipDefinition: 'Any instructional program in horticultural service operations not listed above.',
    CrossReferences: '3.0508 - Urban Forestry.',
  },
  {
    cipFamily: '1',
    cipCode: '1.07',
    cipTitle: 'International Agriculture',
    cipDefinition: 'Instructional content is defined in code 1.0701.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0701',
    cipTitle: 'International Agriculture',
    cipDefinition:
      'A program that focuses on the application of agricultural management and scientific principles to the problems of global food production and distribution, and to the study of the agricultural systems of other countries.  Includes instruction in agricultural economics; comparative agricultural systems; international agribusiness and law; third-world development studies and economic development; and global applications of climate, soil, water resources, ecological and environmental studies, and animal and plant sciences.',
    CrossReferences: '45.0604 - Development Economics and International Development.',
  },
  {
    cipFamily: '1',
    cipCode: '1.08',
    cipTitle: 'Agricultural Public Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0801 - 1.0899.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0801',
    cipTitle: 'Agricultural and Extension Education Services',
    cipDefinition:
      'A program that prepares individuals to provide referral, consulting, technical assistance, and educational services to gardeners, farmers, ranchers, agribusinesses, and other organizations.  Includes instruction in basic agricultural sciences, agricultural business operations, pest control, adult education methods, public relations, applicable state laws and regulations, and communication skills.',
    CrossReferences:
      '13.1301 - Agricultural Teacher Education., 45.1103 - Rural Sociology., 36.0123 - Master Gardener/Gardening.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0802',
    cipTitle: 'Agricultural Communication/Journalism',
    cipDefinition:
      'A program that prepares individuals to apply journalistic, communication, and broadcasting principles to the development, production, and transmittal of agricultural information.  Includes instruction in basic journalism, broadcasting, film/video, and communication techniques; the production of technically specialized information products for agricultural audiences; and the principles of agricultural sciences and business operations needed to develop and communicate agricultural subject matter in effective ways.',
    CrossReferences: '9.0407 - Science/Health/Environmental Journalism.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0899',
    cipTitle: 'Agricultural Public Services, Other',
    cipDefinition: 'Any instructional program in general agricultural sciences and related services not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.09',
    cipTitle: 'Animal Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.0901 - 1.0999.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0901',
    cipTitle: 'Animal Sciences, General',
    cipDefinition:
      'A general program that focuses on the scientific principles that underlie the breeding and husbandry of agricultural animals, and the production, processing, and distribution of agricultural animal products.  Includes instruction in the animal sciences, animal husbandry and production, and agricultural and food products processing.',
    CrossReferences: '26.0701 - Zoology/Animal Biology.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0902',
    cipTitle: 'Agricultural Animal Breeding',
    cipDefinition:
      'A program that focuses on the application of genetics and genetic engineering to the improvement of agricultural animal health, the development of new animal breeds, and the selective improvement of agricultural animal populations.  Includes instruction in genetics, genetic engineering, population genetics, animal health, animal husbandry, and biotechnology.',
    CrossReferences: '26.0804 - Animal Genetics.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0903',
    cipTitle: 'Animal Health',
    cipDefinition:
      'A program that focuses on the application of biological and chemical principles to the study, prevention, and control of diseases in agricultural animal populations.  Includes instruction in environmental science, pharmacology, animal population studies, genetics, animal physiology and diet, disease prevention, treatment methodologies, and laboratory and testing procedures.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0904',
    cipTitle: 'Animal Nutrition',
    cipDefinition:
      'A program that focuses on the biology and chemistry of proteins, fats, carbohydrates, water, vitamins, and feed additives as related to animal health and the production of improved animal products.  Includes instruction in nutrition science, animal health and physiology, biochemistry, cellular and molecular biology, animal husbandry, and food science.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0905',
    cipTitle: 'Dairy Science',
    cipDefinition:
      'A program that focuses on the application of biological and chemical principles to the production and management of dairy animals and the production and handling of dairy products.  Includes instruction in animal sciences, nutrition sciences, food science and technology, biochemistry, and related aspects of human and animal health and safety.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0906',
    cipTitle: 'Livestock Management',
    cipDefinition:
      'A program that focuses on the application of biological and chemical principles to the production and management of livestock animals and the production and handling of meat and other products.  Includes instruction in animal sciences, range science, nutrition sciences, food science and technology, biochemistry, and related aspects of human and animal health and safety.',
    Examples: 'Examples: - Meat Science',
  },
  {
    cipFamily: '1',
    cipCode: '1.0907',
    cipTitle: 'Poultry Science',
    cipDefinition:
      'A program that focuses on the application of biological and chemical principles to the production and management of poultry animals and the production and handling of poultry products.  Includes instruction in avian sciences, nutrition sciences, food science and technology, biochemistry, hatchery design, and related aspects of human and animal health and safety.',
  },
  {
    cipFamily: '1',
    cipCode: '1.0999',
    cipTitle: 'Animal Sciences, Other',
    cipDefinition: 'Any instructional program in the animal sciences not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.10',
    cipTitle: 'Food Science and Technology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.1001 - 1.1099.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1001',
    cipTitle: 'Food Science',
    cipDefinition:
      'A program that focuses on the application of biological, chemical, and physical principles to the study of converting raw agricultural products into processed forms suitable for direct human consumption, and the storage of such products.  Includes instruction in applicable aspects of the agricultural sciences, human physiology and nutrition, food chemistry, agricultural products processing, food additives, food preparation and packaging, food storage and shipment, and related aspects of human health and safety including toxicology and pathology.',
    CrossReferences:
      '19.0501 - Foods, Nutrition, and Wellness Studies, General., 12.0509 - Culinary Science/Culinology.',
    Examples: 'Examples: - Food Safety',
  },
  {
    cipFamily: '1',
    cipCode: '1.1002',
    cipTitle: 'Food Technology and Processing',
    cipDefinition:
      'A program that focuses on the application of chemical, physical, and engineering principles to the development and implementation of manufacturing, packaging, storage, and distribution technologies and processes for food products.  Includes instruction in food engineering, food preservation and handling, food preparation, food packaging and display, food storage and shipment, and related equipment and facilities design, operation, and maintenance.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1003',
    cipTitle: 'Brewing Science',
    cipDefinition:
      'A program that focuses on the business, science, and technology of beer brewing operations. Includes instruction in biology, biochemistry, brewing, entrepreneurship, fermentation, malting, marketing, microbiology, quality control, and sanitation.',
    CrossReferences: '52.0910 - Brewery/Brewpub Operations/Management.',
    Examples: 'Examples: - Sustainable Brewing',
  },
  {
    cipFamily: '1',
    cipCode: '1.1004',
    cipTitle: 'Viticulture and Enology',
    cipDefinition:
      'A program that focuses on the application of scientific and agribusiness principles to the production of grapes, the making of wine, and the wine business. Includes instruction in grapes and wines of the world; grape production; winemaking technology; plant biology; chemistry; food science, safety, and packaging; soil science; pest management; and marketing and business management.',
    Examples: 'Examples: - Viticulture, - Enology',
  },
  {
    cipFamily: '1',
    cipCode: '1.1005',
    cipTitle: 'Zymology/Fermentation Science',
    cipDefinition:
      'A program that focuses on the business, science, and technology of fermented food production. Includes instruction in biology, biochemistry, brewing, entrepreneurship, fermentation, marketing, microbiology, quality control, and sanitation.',
    CrossReferences: '52.0910 - Brewery/Brewpub Operations/Management.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1099',
    cipTitle: 'Food Science and Technology, Other',
    cipDefinition: 'Any instructional program in food sciences and technology not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.11',
    cipTitle: 'Plant Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.1101 - 1.1199.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1101',
    cipTitle: 'Plant Sciences, General',
    cipDefinition:
      'A general program that focuses on the scientific principles that underlie the breeding, cultivation, and production of agricultural plants, and the production, processing, and distribution of agricultural plant products.  Includes instruction in the plant sciences, crop cultivation and production, and agricultural and food products processing.',
    CrossReferences:
      '26.0308 - Plant Molecular Biology., 26.0307 - Plant Physiology., 26.0301 - Botany/Plant Biology., 26.0305 - Plant Pathology/Phytopathology.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1102',
    cipTitle: 'Agronomy and Crop Science',
    cipDefinition:
      'A program that focuses on the chemical, physical, and biological relationships of crops and the soils nurturing them.  Includes instruction in the growth and behavior of agricultural crops, the development of new plant varieties, and the scientific management of soils and nutrients for maximum plant nutrition, health, and productivity.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1103',
    cipTitle: 'Horticultural Science',
    cipDefinition:
      'A program that focuses on the scientific principles related to the cultivation of garden and ornamental plants, including fruits, vegetables, flowers, and landscape and nursery crops.  Includes instruction in specific types of plants, such as citrus; breeding horticultural varieties; physiology of horticultural species; and the scientific management of horticultural plant development and production through the life cycle.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1104',
    cipTitle: 'Agricultural and Horticultural Plant Breeding',
    cipDefinition:
      'A program that focuses on the application of genetics and genetic engineering to the improvement of agricultural plant health, the development of new plant varieties, and the selective improvement of agricultural plant populations.  Includes instruction in genetics, genetic engineering, population genetics, agronomy, plant protection, and biotechnology.',
    CrossReferences: '26.0805 - Plant Genetics.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1105',
    cipTitle: 'Plant Protection and Integrated Pest Management',
    cipDefinition:
      'A program that focuses on the application of scientific principles to the control of animal, insect and weed infestation of domesticated plant populations and other settings, including agricultural crops; the prevention/reduction of attendant economic loss; and the control of environmental pollution and degradation related to pest infestation and pest control measures. Includes instruction in entomology, applicable animal sciences, plant pathology and physiology, weed science, crop science, and environmental toxicology.',
    CrossReferences: '26.0702 - Entomology.',
    Examples: 'Examples: - Pest Management, - Extermination',
  },
  {
    cipFamily: '1',
    cipCode: '1.1106',
    cipTitle: 'Range Science and Management',
    cipDefinition:
      'A program that focuses on the scientific study of rangelands, arid regions, grasslands, and other areas of low productivity, as well as the principles of managing such resources for maximum benefit and environmental balance.  Includes instruction in livestock management, wildlife biology, plant sciences, ecology, soil science, and hydrology.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1180',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1199',
    cipTitle: 'Plant Sciences, Other',
    cipDefinition: 'Any instructional program in plant sciences not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.12',
    cipTitle: 'Soil Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.1201 - 1.1299.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1201',
    cipTitle: 'Soil Science and Agronomy, General',
    cipDefinition:
      'A program that generally focuses on the scientific classification of soils, soil properties, and their relationship to agricultural crops.  Includes instruction in soil chemistry, soil physics, soil biology, soil fertility, morphogenesis, mineralogy, hydrology, agronomy, and soil conservation and management.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1202',
    cipTitle: 'Soil Chemistry and Physics',
    cipDefinition:
      'A program that focuses on the application of chemical and physical principles to research and analysis concerning the nature and properties of soils and the conservation and management of soils.  Includes instruction in soil and fluid mechanics, mineralogy, sedimentology, thermodynamics, geomorphology, environmental systems, analytical methods, and organic and inorganic chemistry.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1203',
    cipTitle: 'Soil Microbiology',
    cipDefinition:
      'A program that focuses on application of microbiological theory and methods to the study of the organismic properties of soils, soil-plant and soil-animal interactions, and the biological components and effects of soil management strategies.  Includes instruction in microbiology and related biological sciences, applicable animal and plant sciences, soil chemistry and physics as related to biological characteristics, and environmental science.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1299',
    cipTitle: 'Soil Sciences, Other',
    cipDefinition: 'Any instructional program in the soil sciences not listed above.',
    Examples: 'Examples: - Environmental Soil Science',
  },
  {
    cipFamily: '1',
    cipCode: '1.13',
    cipTitle: 'Agriculture/Veterinary Preparatory Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.1302 - 1.1399.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1302',
    cipTitle: 'Pre-Veterinary Studies',
    cipDefinition:
      'A program that prepares individuals for admission to a professional program in veterinary medicine.',
  },
  {
    cipFamily: '1',
    cipCode: '1.1399',
    cipTitle: 'Agriculture/Veterinary Preparatory Programs, Other',
    cipDefinition:
      'Any instructional program that prepares individuals for admission to a professional program in agriculture or veterinary medicine or science not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.80',
    cipTitle: 'Veterinary Medicine',
    cipDefinition: 'Instructional content is defined in code 1.8001.',
  },
  {
    cipFamily: '1',
    cipCode: '1.8001',
    cipTitle: 'Veterinary Medicine',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of veterinary medicine, involving the diagnosis, treatment, and health care management of animals and animal populations and the prevention and management of zoonosis.  Includes instruction in the veterinary basic sciences, infectious and noninfectious disease, diagnostic procedures, veterinary clinical medicine, obstetrics, radiology, anesthesiology, surgery, toxicology, animal health and preventive medicine, clinical nutrition, practice management, and professional standards and ethics.',
    Examples: 'Examples: - Veterinary Medicine (DVM)',
  },
  {
    cipFamily: '1',
    cipCode: '1.81',
    cipTitle: 'Veterinary Biomedical and Clinical Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.8101 - 1.8199.',
  },
  {
    cipFamily: '1',
    cipCode: '1.8101',
    cipTitle: 'Veterinary Sciences/Veterinary Clinical Sciences, General',
    cipDefinition:
      'An integrated program of study in one or more of the veterinary medical or clinical sciences or a program undifferentiated as to title.',
    Examples:
      'Examples: - Veterinary Sciences/Veterinary Clinical Sciences, General (Cert.), - Veterinary Sciences/Veterinary Clinical Sciences, General (MS), - Veterinary Sciences/Veterinary Clinical Sciences, General (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8102',
    cipTitle: 'Comparative and Laboratory Animal Medicine',
    cipDefinition:
      'A program that focuses on the scientific study of animal models of human disease and related experimental procedures, and prepares veterinarians and animal health specialists to manage the laboratory use and care of experimental animals.  Includes instruction in laboratory animal husbandry, laboratory animal disease, biohazard control, gnotobiology, breeding, comparative anatomy and physiology, comparative gene mapping, protein function, physical and mathematical modeling, computer modeling, stem cell technology, colony and genetic stock management, cryopreservation, applicable regulations, and bioethics.',
    Examples:
      'Examples: - Comparative and Laboratory Animal Medicine (Cert.), - Comparative and Laboratory Animal Medicine (MS), - Comparative and Laboratory Animal Medicine (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8103',
    cipTitle: 'Large Animal/Food Animal and Equine Surgery and Medicine',
    cipDefinition:
      'A program that focuses on the scientific study of the internal medicine and invasive and noninvasive treatment of herd, work, sport, and food animals.  Includes instruction in equine medicine, swine and dairy medicine, food animal medicine, pathophysiology, large animal diseases, large animal anesthesiology and surgical procedures, preoperative and postoperative care, and specific medical specialties such as dentistry, ophthalmology, oncology, obstetrics/theriogenology, and orthopedics.',
    Examples:
      'Examples: - Large Animal/Food Animal and Equine Surgery and Medicine (Cert.), - Large Animal/Food Animal and Equine Surgery and Medicine (MS), - Large Animal/Food Animal and Equine Surgery and Medicine (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8104',
    cipTitle: 'Small/Companion Animal Surgery and Medicine',
    cipDefinition:
      'A program that focuses on the scientific study of the internal medicine and invasive and noninvasive treatment of companion and household animals.  Includes instruction in avian medicine, pet care, companion animal medicine, pathophysiology, small animal diseases, small animal anesthesiology and surgical procedures, preoperative and postoperative care, and specific medical specialties such as dentistry, ophthalmology, oncology, obstetrics/theriogenology, and orthopedics.',
    Examples:
      'Examples: - Small/Companion Animal Surgery and Medicine (Cert.), - Small/Companion Animal Surgery and Medicine (MS), - Small/Companion Animal Surgery and Medicine (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8105',
    cipTitle: 'Veterinary Anatomy',
    cipDefinition:
      'A program that focuses on the scientific study of the structure of small and large animal cellular, organ, tissue, and body systems and their relation to physiologic function, disease, and therapeutic treatment.  Includes instruction in histology, ultrastructure, molecular biology, biochemistry, developmental biology, neuroscience, electrophysiology, electron microscopy, computer imaging, and applications to specific species.',
    Examples: 'Examples: - Veterinary Anatomy (Cert.), - Veterinary Anatomy (MS), - Veterinary Anatomy (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8106',
    cipTitle: 'Veterinary Infectious Diseases',
    cipDefinition:
      'A program that focuses on the scientific study of zoonotic infectious diseases, disease mechanisms, and prevention and treatment strategies.  Includes instruction in disease pathogenesis, vector biology, mycobacterial infection, blood parasites, retroviruses, food-borne diseases, new infectious agents, ecotoxicology, mechanisms of disease transfer, antibody resistance, comparative pathology, communicable diseases, and disease prevention and eradication.',
    Examples:
      'Examples: - Veterinary Infectious Diseases (Cert.), - Veterinary Infectious Diseases (MS), - Veterinary Infectious Diseases (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8107',
    cipTitle: 'Veterinary Microbiology and Immunobiology',
    cipDefinition:
      'A program that focuses on the scientific study of the microbial causation and immunologic processes related to animal disease, prevention, and treatment.  Includes instruction in bacteriology, mycology, virology, immune response mechanisms, humoral and cell function, cancer defenses, immune system dysfunction, immunologic diseases, parasitology, pathogenesis, and disease vectors.',
    Examples:
      'Examples: - Veterinary Microbiology and Immunobiology (Cert.), - Veterinary Microbiology and Immunobiology (MS), - Veterinary Microbiology and Immunobiology (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8108',
    cipTitle: 'Veterinary Pathology and Pathobiology',
    cipDefinition:
      'A program that focuses on the scientific study of the development and process of disease in domestic and wild animals and applications to diagnosis, prevention, and treatment.  Includes instruction in pathogenesis, immunoparasitology, viral therapy, inherited metabolic diseases, environmental toxicology, anatomical and clinical pathology, necropsy and biopsy techniques, clinical laboratory analysis, pathological interpretation, and disease diagnosis.',
    Examples:
      'Examples: - Veterinary Pathology and Pathobiology (Cert.), - Veterinary Pathology and Pathobiology (MS), - Veterinary Pathology and Pathobiology (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8109',
    cipTitle: 'Veterinary Physiology',
    cipDefinition:
      'A program that focuses on  the scientific study of the functional dynamics of animal biological systems and their relationship to the diagnosis and treatment of disease and injury.  Includes instruction in mammalian and non-mammalian physiology, laboratory physiology, physiological responses to the environment, endocrinology, animal biotechnology, mechanisms of hormone action, organ systems, metabolism, and pathophysiology.',
    Examples: 'Examples: - Veterinary Physiology (Cert.), - Veterinary Physiology (MS), - Veterinary Physiology (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.811',
    cipTitle: 'Veterinary Preventive Medicine, Epidemiology, and Public Health',
    cipDefinition:
      'A program that focuses on the study of the prevention and control of communicable animal diseases, diseases affecting humans, and prepares veterinarians to function as public health specialists.  Includes instruction in animal epidemiology, biostatistics, food safety and quality assurance, food toxicology, zoonotic and infectious diseases, disease vectors and transference, production medicine, animal health,  veterinary public health practice, and inspection and evaluation methods.',
    Examples:
      'Examples: - Veterinary Preventive Medicine, Epidemiology, and Public Health (Cert.), - Veterinary Preventive Medicine, Epidemiology, and Public Health (MS), - Veterinary Preventive Medicine, Epidemiology, and Public Health (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8111',
    cipTitle: 'Veterinary Toxicology and Pharmacology',
    cipDefinition:
      'A program that focuses on the scientific study of specific environmental and food hazards affecting domestic and wild animals and the development and action of chemical antidotes and treatments.  Includes instruction in small and large animal toxicology and pharmacology, neurotoxicology, pharmacodynamics, pharmacokinetics, neuropharmacology, xenobiotics, drug resistance, pesticides, toxicological pathology, genetic and molecular toxicology, environmental toxicology, drug and toxicant analysis and evaluation, and environmental radiology.',
    Examples:
      'Examples: - Veterinary Toxicology and Pharmacology (Cert.), - Veterinary Toxicology and Pharmacology (PhD), - Veterinary Toxicology and Pharmacology (MS)',
  },
  {
    cipFamily: '1',
    cipCode: '1.8199',
    cipTitle: 'Veterinary Biomedical and Clinical Sciences, Other',
    cipDefinition: 'Any instructional program in veterinary biomedical and clinical sciences not listed above.',
    Examples:
      'Examples: - Veterinary Biomedical and Clinical Sciences, Other (Cert.), - Veterinary Biomedical and Clinical Sciences, Other (MS), - Veterinary Biomedical and Clinical Sciences, Other (PhD)',
  },
  {
    cipFamily: '1',
    cipCode: '1.82',
    cipTitle: 'Veterinary Administrative Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.8201 - 1.8299.',
  },
  {
    cipFamily: '1',
    cipCode: '1.8201',
    cipTitle: 'Veterinary Administrative Services, General',
    cipDefinition:
      'A general program that prepares individuals to provide administrative support services in a veterinary office or animal care facility. Includes instruction in business office operations, customer service, principles of veterinary health care operations, record-keeping, veterinary terminology, and professional standards and ethics.',
  },
  {
    cipFamily: '1',
    cipCode: '1.8202',
    cipTitle: 'Veterinary Office Management/Administration',
    cipDefinition:
      'A program that prepares individuals to manage the specialized business functions of a veterinary office. Includes instruction in business office operations, business and financial record-keeping, personnel supervision, veterinary care policy administration, conference planning, scheduling and coordination, public relations, and applicable law and regulations.',
    Examples: 'Examples: - Veterinary Office Administration',
  },
  {
    cipFamily: '1',
    cipCode: '1.8203',
    cipTitle: 'Veterinary Reception/Receptionist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of office managers, veterinary technicians, or veterinarians, to provide customer service, visitor reception, and patient intake and discharge services. Includes instruction in veterinary office and animal care facility procedures, veterinary terminology, interpersonal skills, record-keeping, customer service, telephone skills, data entry, interpersonal communications skills, and applicable policies and regulations.',
    Examples: 'Examples: - Veterinary Receptionist',
  },
  {
    cipFamily: '1',
    cipCode: '1.8204',
    cipTitle: 'Veterinary Administrative/Executive Assistant and Veterinary Secretary',
    cipDefinition:
      'A program that prepares individuals to perform the duties of special assistants and personal secretaries for practicing veterinarians, veterinary health care facilities and services administrators, and other veterinary professionals. Includes instruction in business and veterinary communications, veterinary terminology, principles of veterinary health care operations, public relations and interpersonal communications, software applications, record-keeping and filing systems, scheduling and meeting planning, applicable policy and regulations, and professional standards and ethics.',
    Examples: 'Examples: - Veterinary Secretary',
  },
  {
    cipFamily: '1',
    cipCode: '1.8299',
    cipTitle: 'Veterinary Administrative Services, Other',
    cipDefinition: 'Any instructional program in veterinary administrative services not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.83',
    cipTitle: 'Veterinary/Animal Health Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 1.8301 - 1.8399.',
  },
  {
    cipFamily: '1',
    cipCode: '1.8301',
    cipTitle: 'Veterinary/Animal Health Technology/Technician and Veterinary Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of veterinarians, laboratory animal specialists, and zoological professionals, to provide patient management, care, and clinical procedures assistance as well as owner communication.  Includes instruction in animal nursing care, animal health and nutrition, animal handling, clinical pathology, radiology, anesthesiology, dental prophylaxis, surgical assisting, clinical laboratory procedures, office administration skills, patient and owner management, and applicable standards and regulations.',
  },
  {
    cipFamily: '1',
    cipCode: '1.8399',
    cipTitle: 'Veterinary/Animal Health Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in veterinary or animal health technologies not listed above.',
  },
  {
    cipFamily: '1',
    cipCode: '1.99',
    cipTitle: 'Agricultural/Animal/Plant/Veterinary Science and Related Fields, Other',
    cipDefinition: 'Instructional content is defined in code 1.9999.',
  },
  {
    cipFamily: '1',
    cipCode: '1.9999',
    cipTitle: 'Agricultural/Animal/Plant/Veterinary Science and Related Fields, Other',
    cipDefinition:
      'Any instructional program in agricultural, animal, plant, or veterinary science and related fields not listed above.',
  },
  {
    cipFamily: '3',
    cipCode: '3',
    cipTitle: 'Natural Resources and Conservation',
    cipDefinition:
      'Instructional programs that focus on the various natural resources and conservation fields and prepare individuals for related occupations.',
  },
  {
    cipFamily: '3',
    cipCode: '3.01',
    cipTitle: 'Natural Resources Conservation and Research',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 3.0101 - 3.0199.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0101',
    cipTitle: 'Natural Resources/Conservation, General',
    cipDefinition:
      'A general program that focuses on the studies and activities relating to the natural environment and its conservation, use, and improvement.  Includes instruction in subjects such as climate, air, soil, water, land, fish and wildlife, and plant resources; in the basic principles of environmental science and natural resources management; and the recreational and economic uses of renewable and nonrenewable natural resources.',
    CrossReferences: '26.1307 - Conservation Biology.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0103',
    cipTitle: 'Environmental Studies',
    cipDefinition:
      'A program that focuses on  environment-related issues using scientific, social scientific, or humanistic approaches or a combination.  Includes instruction in the basic principles of ecology and environmental science and related subjects such as policy, politics, law, economics, social aspects, planning, pollution control, natural resources, and the interactions of human beings and nature.',
    CrossReferences:
      '14.1401 - Environmental/Environmental Health Engineering., 30.4401 - Geography and Environmental Studies.',
    Examples: 'Examples: - Ecosystem Studies',
  },
  {
    cipFamily: '3',
    cipCode: '3.0104',
    cipTitle: 'Environmental Science',
    cipDefinition:
      'A program that focuses on the application of biological, chemical, and physical principles to the study of the physical environment and the solution of environmental problems, including subjects such as abating or controlling environmental pollution and degradation; the interaction between human society and the natural environment; and natural resources management.  Includes instruction in biology, chemistry, physics, geosciences, climatology, statistics, and mathematical modeling.',
    CrossReferences:
      '26.1305 - Environmental Biology., 26.1307 - Conservation Biology., 14.1401 - Environmental/Environmental Health Engineering.',
    Examples: 'Examples: - Bioenvironmental Sciences',
  },
  {
    cipFamily: '3',
    cipCode: '3.0199',
    cipTitle: 'Natural Resources Conservation and Research, Other',
    cipDefinition: 'Any instructional program in natural resources conservation and research not listed above.',
    Examples: 'Examples: - Environmental Conservation and Reclamation',
  },
  {
    cipFamily: '3',
    cipCode: '3.02',
    cipTitle: 'Environmental/Natural Resources Management and Policy',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 3.0201 - 3.0299.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0201',
    cipTitle: 'Environmental/Natural Resources Management and Policy, General',
    cipDefinition:
      'A program that prepares individuals to plan, develop, manage, and evaluate programs to protect and regulate natural habitats and renewable natural resources.  Includes instruction in the principles of wildlife and conservation biology, environmental science, animal population surveying, natural resource economics, management techniques for various habitats, applicable law and policy, administrative and communications skills, and public relations.',
    Examples: 'Examples: - Ecosystem Management, - Environmental Policy and Decision Making',
  },
  {
    cipFamily: '3',
    cipCode: '3.0204',
    cipTitle: 'Environmental/Natural Resource Economics',
    cipDefinition:
      'A program that focuses on the application of economic concepts and methods to the analysis of issues such as air and water pollution, land use planning, waste disposal, invasive species and pest control, conservation policies, and related environmental problems.  Includes instruction in cost-benefit analysis, environmental impact assessment, evaluation and assessment of alternative resource management strategies, policy evaluation and monitoring, and descriptive and analytic tools for studying how environmental developments affect the economic system.',
    CrossReferences: '1.0103 - Agricultural Economics.',
    Examples: 'Examples: - Environmental Economics and Policy',
  },
  {
    cipFamily: '3',
    cipCode: '3.0205',
    cipTitle: 'Water, Wetlands, and Marine Resources Management',
    cipDefinition:
      'A program that prepares individuals to apply the principles of marine/aquatic biology, oceanography, natural resource economics, and natural resources management to the development, conservation, and management of freshwater and saltwater environments. Includes instruction in subjects such as wetlands, riverine, lacustrine, coastal, and oceanic water resources; water conservation and use; flood control; pollution control; water supply logistics; wastewater management; aquatic and marine ecology; aquatic and marine life conservation; and the economic and recreational uses of water resources.',
    CrossReferences:
      '26.1304 - Aquatic Biology/Limnology., 40.0605 - Hydrology and Water Resources Science., 14.0805 - Water Resources Engineering.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0206',
    cipTitle: 'Land Use Planning and Management/Development',
    cipDefinition:
      'A program that focuses on how public and/or private land and associated resources can be preserved, developed, and used for maximum social, economic, and environmental benefit.  Includes instruction in natural resources management, natural resource economics, public policy, regional and land use planning, environmental impact assessment, applicable law and regulations, government and politics, principles of business and real estate land use, statistical and analytical tools, computer applications, mapping and report preparation, site analysis, cost analysis, and communications skills.',
    CrossReferences:
      '4.0601 - Landscape Architecture., 31.0301 - Parks, Recreation, and Leisure Facilities Management, General.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0207',
    cipTitle: 'Environmental/Natural Resource Recreation and Tourism',
    cipDefinition:
      'A program that prepares individuals to plan, develop, and manage tourism in a natural resource setting, with an emphasis on applying environmentally sound, culturally sensitive, and economically sustainable principles. Includes instruction in environmental studies; natural resource management and interpretation; tourism planning, management, and policies; public land use management and policies; communications; marketing; and public relations.',
    CrossReferences: '31.0301 - Parks, Recreation, and Leisure Facilities Management, General.',
    Examples:
      'Examples: - Ecotourism, - Resource Recreation and Tourism, - Nature-Based Tourism, - Natural Resources Interpretation, - Hunting and Fishing Guide',
  },
  {
    cipFamily: '3',
    cipCode: '3.0208',
    cipTitle: 'Environmental/Natural Resources Law Enforcement and Protective Services',
    cipDefinition:
      'A program that prepares individuals to enforce natural resource and environmental protection regulations and laws; and to perform emergency duties to protect human life, property and natural resources, including fire prevention and control measures, and emergency and rescue procedures. Includes instruction in natural and physical sciences, natural resource management, outdoor field skills, firearms and outdoor equipment and vehicle operation, evidence collection and environmental sampling and monitoring, natural resource legislation, environmental compliance techniques, patrol procedures, investigation and report writing, interpersonal skills and crisis intervention, community liaison, public safety, public education, and public relations. Note: this program was re-instated after being deleted from CIP 2000; previously, it was coded as 3.0203.',
    Examples: 'Examples: - Conservation Enforcement',
  },
  {
    cipFamily: '3',
    cipCode: '3.0209',
    cipTitle: 'Energy and Environmental Policy',
    cipDefinition:
      'A program of study that focuses on the systematic analysis of public policy issues related to climate change, energy policy, environmental economics, global sustainability, and security. Includes instruction in climate change, clean energy technologies, environmental policy, environmental law, environmental economics, food security, fossil fuels, renewable and sustainable energy sources, and water issues.',
  },
  {
    cipFamily: '3',
    cipCode: '3.021',
    cipTitle: 'Bioenergy',
    cipDefinition:
      'A program of study that focuses on the environmental and economic impact of using plants and microbes for the production of bio-based fuels such as ethanol and biodiesel. Includes instruction in biochemical engineering, bioprocessing, bioseparations, conversion, feedstock, economics, environmental sustainability, hydrology, and natural resource management.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0299',
    cipTitle: 'Environmental/Natural Resources Management and Policy, Other',
    cipDefinition:
      'Any instructional program in environmental or natural resources management and policy not listed above.',
  },
  {
    cipFamily: '3',
    cipCode: '3.03',
    cipTitle: 'Fishing and Fisheries Sciences and Management',
    cipDefinition: 'Instructional content is defined in code 3.0301.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0301',
    cipTitle: 'Fishing and Fisheries Sciences and Management',
    cipDefinition:
      'A program that focuses on the scientific study of the husbandry and production of non-domesticated fish and shellfish populations for recreational and commercial purposes and the management of fishing and marine/aquatic product processing to ensure adequate conservation and efficient utilization.  Includes instruction in the principles of marine/aquatic biology, freshwater and saltwater ecosystems, water resources, fishing production operations and management, fishing policy and regulation, and the management of recreational and commercial fishing activities.',
    CrossReferences: '26.1302 - Marine Biology and Biological Oceanography., 49.0303 - Commercial Fishing.',
  },
  {
    cipFamily: '3',
    cipCode: '3.05',
    cipTitle: 'Forestry',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 3.0501 - 3.0599.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0501',
    cipTitle: 'Forestry, General',
    cipDefinition:
      'A program that generally prepares individuals to manage and develop forest areas for economic, recreational, and ecological purposes.  Includes instruction in forest-related sciences, mapping, statistics, harvesting and production technology, natural resources management and economics, wildlife sciences, administration, and public relations.',
    CrossReferences: '14.3401 - Forest Engineering.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0502',
    cipTitle: 'Forest Sciences and Biology',
    cipDefinition:
      'A program that focuses on the application of one or more forest-related sciences to the study of environmental factors affecting forests and the growth and management of forest resources.  Includes instruction in forest biology, forest hydrology, forest mensuration, silviculture, forest soils, water resources, environmental science, forest resources management, and wood science.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0506',
    cipTitle: 'Forest Management/Forest Resources Management',
    cipDefinition:
      'A program that prepares individuals to apply principles of forestry and natural resources management to the administration of forest lands and related resources.  Includes instruction in silviculture, forest mensuration, forest protection, inventorying, biometrics, geographic information systems, remote sensing, photogrammetry, forest policy and economics, forest land use planning, fire protection and management, and related administrative skills.',
    Examples: 'Examples: - Silviculture, - Sylviculture',
  },
  {
    cipFamily: '3',
    cipCode: '3.0508',
    cipTitle: 'Urban Forestry',
    cipDefinition:
      'A program that prepares individuals to apply the principles of forestry and related sciences to the development, care, and maintenance of individual trees and forested areas within or close to areas of dense human habitation.  Includes instruction in urban environments; effects of pollution on tree species; environmental design and landscaping; urban pest infestation; urban forest management; and applicable policies and regulations.',
    Examples: 'Examples: - Arboriculture',
  },
  {
    cipFamily: '3',
    cipCode: '3.0509',
    cipTitle: 'Wood Science and Wood Products/Pulp and Paper Technology/Technician',
    cipDefinition:
      'A program that focuses on the application of chemical, physical, and engineering principles to the analysis of the properties and behavior of wood and wood products and the development of processes for converting wood into paper and other products.  Includes instruction in wood classification and testing, product development, manufacturing and processing technologies, and the design and development of related equipment and systems.',
    CrossReferences: '14.4001 - Paper Science and Engineering.',
  },
  {
    cipFamily: '3',
    cipCode: '3.051',
    cipTitle: 'Forest Resources Production and Management',
    cipDefinition:
      'A program that focuses on the application of forestry principles to the production, harvesting, and processing of forest resources and that prepares individuals to perform associated technical and managerial functions.  Includes instruction in forest production and utilization, industrial forestry, agroforestry, transplantation, timber harvesting, selection and identification of trees, processing technologies and systems, equipment operations and maintenance, and related management skills.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0511',
    cipTitle: 'Forest Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to assist foresters in the management and production of forest resources.  Includes instruction in woods and field skills, tree identification, timber measurement, logging and timber harvesting, forest propagation and regeneration, forest fire-fighting, resource management, equipment operation and maintenance, record-keeping, sales and purchasing operations, and personnel supervision.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0599',
    cipTitle: 'Forestry, Other',
    cipDefinition: 'Any program in forestry not listed above.',
  },
  {
    cipFamily: '3',
    cipCode: '3.06',
    cipTitle: 'Wildlife and Wildlands Science and Management',
    cipDefinition: 'Instructional content is defined in code 3.0601.',
  },
  {
    cipFamily: '3',
    cipCode: '3.0601',
    cipTitle: 'Wildlife, Fish and Wildlands Science and Management',
    cipDefinition:
      'A program that prepares individuals to conserve and manage wilderness areas and the flora, marine and aquatic life therein, and manage wildlife reservations and zoological/aquarium facilities for recreational, commercial, and ecological purposes. Includes instruction in wildlife biology, marine/aquatic biology, environmental science,  freshwater and saltwater ecosystems, natural resources management and policy, outdoor recreation and parks management, the design and operation of natural and artificial wildlife habitats, applicable law and regulations, and related administrative and communications skills.',
    CrossReferences: '26.0709 - Wildlife Biology.',
  },
  {
    cipFamily: '3',
    cipCode: '3.99',
    cipTitle: 'Natural Resources and Conservation, Other',
    cipDefinition: 'Instructional content is defined in code 3.9999.',
  },
  {
    cipFamily: '3',
    cipCode: '3.9999',
    cipTitle: 'Natural Resources and Conservation, Other',
    cipDefinition: 'Any instructional program in natural resources and conservation not listed above.',
    Examples: 'Examples: - Hunting and Trapping',
  },
  {
    cipFamily: '4',
    cipCode: '4',
    cipTitle: 'Architecture and Related Services',
    cipDefinition:
      'Instructional programs that prepare individuals for professional practice in the various architecture-related fields and focus on the study of related aesthetic and socioeconomic aspects of the built environment.',
  },
  {
    cipFamily: '4',
    cipCode: '4.02',
    cipTitle: 'Architecture',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 4.0200 - 4.0299.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0200',
    cipTitle: 'Pre-Architecture Studies',
    cipDefinition:
      'A program of study that is a precursor to an advanced degree in architecture. Includes instruction in architectural design, architectural graphics, architectural history, architectural technology, architectural theory and criticism, building technology, calculus, design studio, human factors, environmental architecture, geometry and trigonometry, physics, and technical drawing.',
    Examples: 'Examples: - Pre-Architecture',
  },
  {
    cipFamily: '4',
    cipCode: '4.0201',
    cipTitle: 'Architecture',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of architecture and to conduct research in various aspects of the field.  Includes instruction in architectural design, history, and theory; building structures and environmental systems; project and site planning; construction; professional responsibilities and standards; and related cultural, social, economic, and environmental issues.',
    CrossReferences:
      '15.1303 - Architectural Drafting and Architectural CAD/CADD., 14.0401 - Architectural Engineering.',
    Examples: 'Examples: - Architecture (BArch, BA/BS), - Architecture (MArch/MA/MS), - Architecture (PhD)',
  },
  {
    cipFamily: '4',
    cipCode: '4.0202',
    cipTitle: 'Architectural Design',
    cipDefinition:
      'An advanced program of study for architects and related professionals that focuses on theoretical and practical issues in architecture and integrates digital technologies into architectural design, fabrication, and construction. Includes instruction in architectural history and urban theory, 3-D digital technologies, construction, design and fabrication methods, and sustainable practices in architecture.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0299',
    cipTitle: 'Architecture, Other',
    cipDefinition: 'Any instructional program in architecture not listed above.',
  },
  {
    cipFamily: '4',
    cipCode: '4.03',
    cipTitle: 'City/Urban, Community, and Regional Planning',
    cipDefinition: 'Instructional content is defined in code 4.0301.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0301',
    cipTitle: 'City/Urban, Community, and Regional Planning',
    cipDefinition:
      'A program that prepares individuals to apply principles of planning, analysis, and architecture to the development and improvement of urban areas and surrounding regions, and to function as professional planners.  Includes instruction in principles of architecture; master plan development; service, communications, and transportation systems design; community and commercial development; zoning; land use planning; applied economics; policy analysis; applicable laws and regulations; and professional responsibilities and managerial duties.',
    CrossReferences: '45.1201 - Urban Studies/Affairs.',
    Examples: 'Examples: - Urban Design',
  },
  {
    cipFamily: '4',
    cipCode: '4.04',
    cipTitle: 'Environmental Design',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 4.0401 - 4.0499.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0401',
    cipTitle: 'Environmental Design/Architecture',
    cipDefinition:
      'A program that prepares individuals to design public and private spaces, indoor and outdoor, for leisure, recreational, commercial, and living purposes, and for professional practice as environmental designers and architects.  Includes instruction in the design and planning of public and private open spaces and their relationship to buildings and other aspects of the built environment; facilities management; related aspects of interior design and architecture, landscape architecture, and urban planning; and professional responsibilities and standards.',
    CrossReferences: '19.0601 - Housing and Human Environments, General.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0402',
    cipTitle: 'Healthcare Environment Design/Architecture',
    cipDefinition:
      'A program that focuses on the design and performance of healthcare environments to optimize healing. Includes instruction in interior design, architecture, healing sciences, healthcare environments, human-centered design, and healthcare delivery.',
    CrossReferences: '30.3701 - Design for Human Health.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0403',
    cipTitle: 'Sustainable Design/Architecture',
    cipDefinition:
      'A program that focuses on the application of biological, physical, and social science in the design of sustainable cities and metropolitan regions. Includes instruction in community revitalization, community economic development, construction technology, design innovation, environmental economics, ethics, justice and politics, sustainable materials, sustainability theory, structural materials, and urbanism.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0499',
    cipTitle: 'Environmental Design, Other',
    cipDefinition: 'Any instructional program in environmental design not listed above.',
  },
  {
    cipFamily: '4',
    cipCode: '4.05',
    cipTitle: 'Interior Architecture',
    cipDefinition: 'Instructional content is defined in code 4.0501.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0501',
    cipTitle: 'Interior Architecture',
    cipDefinition:
      'A program that prepares individuals to apply architectural principles in the design of structural interiors for living, recreational, and business purposes and to function as professional interior architects.  Includes instruction in architecture, structural systems design, heating and cooling systems, occupational and safety standards, interior design, specific end-use applications, and professional responsibilities and standards.',
    CrossReferences: '50.0408 - Interior Design.',
  },
  {
    cipFamily: '4',
    cipCode: '4.06',
    cipTitle: 'Landscape Architecture',
    cipDefinition: 'Instructional content is defined in code 4.0601.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0601',
    cipTitle: 'Landscape Architecture',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of landscape architecture and research in various aspects of the field.  Includes instruction in geology and hydrology; soils, groundcovers, and horticultural elements; project and site planning; landscape design, history, and theory; environmental design; applicable law and regulations; and professional responsibilities and standards.',
    CrossReferences: '1.0605 - Landscaping and Groundskeeping., 3.0206 - Land Use Planning and Management/Development.',
    Examples:
      'Examples: - Landscape Architecture (BS, BSLA, BLA), - Landscape Architecture (MSLA, MLA), - Landscape Architecture (PhD)',
  },
  {
    cipFamily: '4',
    cipCode: '4.08',
    cipTitle: 'Architectural History, Criticism, and Conservation',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 4.0801 - 4.0899.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0801',
    cipTitle: 'Architectural History and Criticism, General',
    cipDefinition:
      'A general program that focuses on the aesthetic, technical, and social development of the built environment and the architectural profession from earliest times to the present.  Includes instruction in the principles of architecture and related fields; art history; historiography of architecture; architectural theory and criticism; related aspects of the history of technology and social and economic history; and various period, cultural, and stylistic specializations.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0802',
    cipTitle: 'Architectural Conservation',
    cipDefinition:
      'A program that focuses on the architectural design principles and building techniques used in historic structures and environments, and the process of saving and restoring old buildings and districts for contemporary use and enjoyment. Includes instruction in architectural history, building conservation techniques, and material conservation.',
    CrossReferences: '30.1201 - Historic Preservation and Conservation, General.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0803',
    cipTitle: 'Architectural Studies',
    cipDefinition:
      'A program that focuses on the study of architecture, cities, landscapes, designed objects, ornament, architectural photography, and material culture in diverse places and time periods, and the social and aesthetic dimensions of contemporary architecture, landscapes, and cities, emphasizing issues of sustainable environments, new forms of urbanism, and the use of digital media for visualization and analysis. Includes instruction in the fundamentals of architecture, design, environmental and sustainability studies, historic preservation, landscape architecture, studio art, and urban affairs and planning.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0899',
    cipTitle: 'Architectural History, Criticism, and Conservation, Other',
    cipDefinition: 'Any instructional program in architectural history, criticism, and conservation not listed above.',
  },
  {
    cipFamily: '4',
    cipCode: '4.09',
    cipTitle: 'Architectural Sciences and Technology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 4.0901 - 4.0999.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0901',
    cipTitle: 'Architectural Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to assist architects in developing plans and related documentation and in performing architectural office services.  Includes instruction in architectural drafting, computer-assisted drafting and design, construction methods and materials, environmental systems, building codes and standards, structural principles, cost estimation, planning documentation, visual communication skills, display production, and architectural office management.',
    CrossReferences: '15.1303 - Architectural Drafting and Architectural CAD/CADD.',
  },
  {
    cipFamily: '4',
    cipCode: '4.0902',
    cipTitle: 'Architectural and Building Sciences/Technology',
    cipDefinition:
      'A program that  focuses on the application of advanced technology to building design and construction, retrofitting existing buildings, and efficient operation of buildings, including lighting and daylight design, acoustics, solar design, building conservation, and energy-conscious design.  Includes instruction in architecture, building technology, civil and structural engineering, mechanical engineering, environmental control systems, sustainability, and computer tools and applications.',
    Examples:
      'Examples: - Architectural and Building Sciences/Technology (BArch, BA/BS), - Architectural and Building Sciences/Technology (MArch/MA/MS), - Architectural and Building Sciences/Technology (PhD)',
  },
  {
    cipFamily: '4',
    cipCode: '4.0999',
    cipTitle: 'Architectural Sciences and Technology, Other',
    cipDefinition: 'Any instructional program in architectural sciences and technology not listed above.',
  },
  {
    cipFamily: '4',
    cipCode: '4.10',
    cipTitle: 'Real Estate Development',
    cipDefinition: 'Instructional content is defined in code 4.1001.',
  },
  {
    cipFamily: '4',
    cipCode: '4.1001',
    cipTitle: 'Real Estate Development',
    cipDefinition:
      'A program that prepares individuals to create and redevelop housing, offices, retail centers, and industrial sites.  Includes instruction in architectural design and construction, historic preservation, landscape architecture, urban planning and design, business, real estate and land use law, public policy, and engineering.',
    CrossReferences: '52.1501 - Real Estate.',
  },
  {
    cipFamily: '4',
    cipCode: '4.99',
    cipTitle: 'Architecture and Related Services, Other',
    cipDefinition: 'Instructional content is defined in code 4.9999.',
  },
  {
    cipFamily: '4',
    cipCode: '4.9999',
    cipTitle: 'Architecture and Related Services, Other',
    cipDefinition: 'Any instructional program in architecture and related programs not listed above.',
  },
  {
    cipFamily: '5',
    cipCode: '5',
    cipTitle: 'Area, Ethnic, Cultural, Gender, and Group Studies',
    cipDefinition:
      'Instructional programs that focus on the defined areas, regions, and countries of the world; defined minority groups within and across societies; and issues relevant to collective gender and group experience.',
  },
  {
    cipFamily: '5',
    cipCode: '5.01',
    cipTitle: 'Area Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 5.0101 - 5.0199.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0101',
    cipTitle: 'African Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of the African Continent, usually with an emphasis on Africa south of the Sahara, and including the African diasporas overseas.',
    CrossReferences: '5.0201 - African-American/Black Studies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0102',
    cipTitle: 'American/United States Studies/Civilization',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of the United States and its Pre-Columbian and colonial predecessors, and including the flow of immigrants from other societies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0103',
    cipTitle: 'Asian Studies/Civilization',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of the Asian Continent, including the study of the Asian diasporas overseas.',
    CrossReferences: '5.0206 - Asian-American Studies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0104',
    cipTitle: 'East Asian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of East Asia, defined as including China, Korea, Japan, Mongolia, Taiwan, Tibet, related borderlands and island groups, and including the study of the East Asian diasporas overseas.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0105',
    cipTitle: 'Russian, Central European, East European and Eurasian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of the region encompassing Russia, Central Europe, Eastern Europe, and Eurasia, including the Balkan, Baltic, Caucasus, and Central Asian states that were republics of the former Soviet Union.',
    CrossReferences: '5.0110 - Russian Studies.',
    Examples: 'Examples: - Russian and East European Studies, - Central and Eastern European Studies, - CIS Studies',
  },
  {
    cipFamily: '5',
    cipCode: '5.0106',
    cipTitle: 'European Studies/Civilization',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of the European Continent, including the study of European migration patterns and colonial empires.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0107',
    cipTitle: 'Latin American Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the Hispanic peoples of the North and South American Continents outside Canada and the United States, including the study of the Pre-Columbian period and the flow of immigrants from other societies.',
    CrossReferences: '5.0203 - Hispanic-American, Puerto Rican, and Mexican-American/Chicano Studies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0108',
    cipTitle: 'Near and Middle Eastern Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of North Africa, Southwestern Asia, Asia Minor, and the Arabian Peninsula, related borderlands and island groups, and including emigrant and immigrant groups.',
    CrossReferences: '30.2202 - Classical, Ancient Mediterranean, and Near Eastern Studies and Archaeology.',
    Examples: 'Examples: - Modern Middle Eastern Studies',
  },
  {
    cipFamily: '5',
    cipCode: '5.0109',
    cipTitle: 'Pacific Area/Pacific Rim Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of Australasia and the Pacific Ocean, related island groups and bordering coastal regions, and including pre- and post-colonial migration patterns.',
  },
  {
    cipFamily: '5',
    cipCode: '5.011',
    cipTitle: 'Russian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of the Russian Federation and its Soviet, Czarist, and medieval predecessors and related borderlands.',
    CrossReferences: '5.0105 - Russian, Central European, East European and Eurasian Studies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0111',
    cipTitle: 'Scandinavian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of Scandinavia, defined as Northern Europe including Denmark, Finland, Iceland, Norway, Sweden, and related island groups (e.g., Greenland) and borderlands.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0112',
    cipTitle: 'South Asian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of South Asia, defined as including Afghanistan, India, the Maldives, Myanmar (Burma), Pakistan, and Sri Lanka and related borderlands and island groups; and including the study of migration patterns and overseas diasporas.',
    Examples: 'Examples: - Indian/South Asian Studies',
  },
  {
    cipFamily: '5',
    cipCode: '5.0113',
    cipTitle: 'Southeast Asian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of Southeast Asia, defined as including Brunei, Cambodia, Indonesia, Laos, Malaysia, The Philippines, Singapore, Thailand, and Viet Nam; related borderlands and island groups; and including the study of migration patterns and overseas diasporas.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0114',
    cipTitle: 'Western European Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of historical Western Europe, defined as including Britain, Ireland, France, the Low Countries, the Iberian Peninsula, Italy, the Western Mediterranean, and related island groups and borderlands.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0115',
    cipTitle: 'Canadian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of Canada and its Pre-Columbian, colonial, and pre-federation predecessors, including immigrant flows and related borderlands and island groups.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0116',
    cipTitle: 'Balkans Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples inhabiting the Balkan Peninsula and associated island groups and borderlands, Southern Slavic and non-Slavic, during the medieval, Ottoman, and modern periods.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0117',
    cipTitle: 'Baltic Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples inhabiting the coastlands of the Baltic Sea, including Baltic, Germanic, Scandinavian, and Slavic populations, related borderlands and island groups, and problems of cultural survival and assimilation.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0118',
    cipTitle: 'Slavic Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the Slavic peoples inhabiting Europe, Asia, and in immigrant groups elsewhere, including the study of the emergence and migration patterns of Slavic culture, languages, and populations.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0119',
    cipTitle: 'Caribbean Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples inhabiting the major islands and archipelagoes of the Caribbean Sea and related coastal borderlands, including immigration patterns and Pre-Columbian, colonial, and modern societies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.012',
    cipTitle: 'Ural-Altaic and Central Asian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples and countries of Inner/Central Asia, including the Turkic and Mongolian inhabitants of the Caspian, Amur, Tien Shan, Baikal, Gobi, Siberian, and Manchurian areas and the historical Silk Road, in terms of their past and present development.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0121',
    cipTitle: 'Commonwealth Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples and countries comprising components of the historical British Empire and modern British Commonwealth, including migration patterns, shared sociocultural and political features and problems, and contemporary relations.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0122',
    cipTitle: 'Regional Studies (U.S., Canadian, Foreign)',
    cipDefinition:
      'A program that focuses on the defined geographic subregions and subcultures within modern and historical countries and societies. Includes such topics as Acadian Studies, French Canadian and Quebec Studies, Southern (U.S.) Studies, New England Studies, Southwestern Studies, Northern Studies, and others.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0123',
    cipTitle: 'Chinese Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of one or more of the peoples of present-day China and its historical predecessors, related borderlands and island groups, and the overseas Chinese diaspora.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0124',
    cipTitle: 'French Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of France, other Francophone countries inside and outside Europe, and the French colonial experience and the associated French minorities around the world.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0125',
    cipTitle: 'German Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of Germany, the neighboring countries of Austria and Switzerland, the German minorities in neighboring European countries, and the historical areas of German influence across Europe and overseas.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0126',
    cipTitle: 'Italian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of modern Italy and its predecessors on the Italian Peninsula, including the overseas migrations of Italian peoples.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0127',
    cipTitle: 'Japanese Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of the peoples of Japan, and related island groups and coastal neighbors.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0128',
    cipTitle: 'Korean Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of the peoples of Korea, including related island groups and borderlands.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0129',
    cipTitle: 'Polish Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of Poland and the current and historical inhabitants of the Polish lands, including borderlands, from earliest times to the present.',
  },
  {
    cipFamily: '5',
    cipCode: '5.013',
    cipTitle: 'Spanish and Iberian Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of the peoples of the Iberian Peninsula and related island groups and border regions from earliest times to the present, with particular emphasis on the development of Spain and Portugal but including other historical and current cultures.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0131',
    cipTitle: 'Tibetan Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of Tibet and its borderlands, with emphasis on both pre-modern and modern Tibet and associated religious and exile movements.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0132',
    cipTitle: 'Ukraine Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of the Ukraine and its inhabitants, and related border regions, from earliest times to the present.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0133',
    cipTitle: 'Irish Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of Ireland and the Irish Diaspora, from the early Christian period to the present.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0134',
    cipTitle: 'Latin American and Caribbean Studies',
    cipDefinition:
      'An integrated program that focuses on the history, society, politics, culture, and economics of the peoples and countries of the Latin American and Caribbean region.',
    CrossReferences: '5.0107 - Latin American Studies., 5.0119 - Caribbean Studies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0135',
    cipTitle: 'Appalachian Studies',
    cipDefinition:
      'A program of study that focuses on the history, sociology, culture, and economics of the Appalachian region of the United States. Includes instruction in contemporary issues in Appalachia, political economy of Appalachia, community-based research, ethnomusicology, and oral and written languages of Appalachia.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0136',
    cipTitle: 'Arctic Studies',
    cipDefinition:
      'A program that focuses on the history, society, politics, culture, and economics of the Arctic region.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0199',
    cipTitle: 'Area Studies, Other',
    cipDefinition: 'Any instructional program in specifically defined area studies not listed above.',
  },
  {
    cipFamily: '5',
    cipCode: '5.02',
    cipTitle: 'Ethnic, Cultural Minority, Gender, and Group Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 5.0200 - 5.0299.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0200',
    cipTitle: 'Ethnic Studies',
    cipDefinition:
      'A program that focuses on the interdisciplinary and comparative study of the history, sociology, politics, culture, and economics of North American racial and ethnic groups, including African Americans, American Indians/Native Americans, Hispanic Americans, and Asian Americans.',
    CrossReferences:
      '30.2301 - Intercultural/Multicultural and Diversity Studies., 30.3601 - Cultural Studies and Comparative Literature.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0201',
    cipTitle: 'African-American/Black Studies',
    cipDefinition:
      'A program that focuses on the history, sociology, politics, culture, and economics of the North American peoples descended from the African diaspora; focusing on the United States, Canada, and the Caribbean, but also including reference to Latin American elements of the diaspora.',
    CrossReferences: '5.0101 - African Studies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0202',
    cipTitle: 'American Indian/Native American Studies',
    cipDefinition:
      'A program that focuses on the history, sociology, politics, culture, and economics of one or more of the American Indian, Aleut, Inuit, Hawaiian and other native peoples of the Americas from earliest times until the present, with particular emphasis on U.S. and Canadian populations and their problems but including reference to Caribbean and Latin American native peoples.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0203',
    cipTitle: 'Hispanic-American, Puerto Rican, and Mexican-American/Chicano Studies',
    cipDefinition:
      'A program that focuses on the history, sociology, politics, culture, and economics of one or more of the Hispanic American immigrant populations within the U.S. and Canada, including Mexican-American Studies, Cuban American Studies, Puerto Rican Studies, and others.',
    CrossReferences: '5.0107 - Latin American Studies.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0206',
    cipTitle: 'Asian-American Studies',
    cipDefinition:
      'A program that focuses on the history, sociology, politics, culture, and economics of Asian population groups who have immigrated to the United States and Canada, or sought refugee asylum, from the colonial period to the present, and including Chinese-Americans, Japanese-Americans, Korean-Americans, Vietnamese-Americans, Cambodian-Americans, and others.',
    CrossReferences: '5.0103 - Asian Studies/Civilization.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0207',
    cipTitle: "Women's Studies.",
    cipDefinition:
      'A program that focuses on the history, sociology, politics, culture, and economics of women, and the development of modern feminism in relation to the roles played by women in different periods and locations in North America and the world.  Programs may focus on literature, philosophy, and the arts as much as on social studies and policy.',
    Examples: "Examples: - Women's and Gender Studies",
  },
  {
    cipFamily: '5',
    cipCode: '5.0208',
    cipTitle: 'Gay/Lesbian Studies',
    cipDefinition:
      'A program that focuses on homosexuality in history, society, politics, and cultural life; usually with an emphasis on the modern emergence of gays and lesbians as a sociocultural minority movement, the identification of social, literary, and artistic themes and the definition of, and public policy and legal issues surrounding, the gay and lesbian community.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0209',
    cipTitle: 'Folklore Studies',
    cipDefinition:
      'A program that focuses on the study of creativity and aesthetic expression in everyday life, as expressed in narrative, literature, performing arts, culture, work, family and community.',
    Examples: 'Examples: - Folklore Studies, - Folklore and Mythology, - Folklore and Folklife',
  },
  {
    cipFamily: '5',
    cipCode: '5.021',
    cipTitle: 'Disability Studies',
    cipDefinition:
      'A program that focuses on the nature, meaning, and consequences of what it is to be defined as disabled and explores the historical, cultural, economic, physiological, and socio-political dynamics of disability. Includes instruction in disability rights, legal issues, and public policy; literature, philosophy, and the arts; and/or research in the social sciences, education, and health sciences addressing social and experiential aspects of disability.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0211',
    cipTitle: 'Deaf Studies',
    cipDefinition:
      'A program that focuses on the sociological, historical and linguistic aspects of the deaf and hearing impaired, and that prepares individuals to work with the deaf and hearing impaired. Includes instruction in American Sign Language, deaf studies, American deaf culture, structure of American Sign Language, history of the American deaf community, and civil rights of deaf people.',
    CrossReferences: '16.1601 - American Sign Language (ASL).',
    Examples: 'Examples: - Deaf Studies, - American Sign Language (ASL) and Deaf Studies',
  },
  {
    cipFamily: '5',
    cipCode: '5.0212',
    cipTitle: 'Comparative Group Studies',
    cipDefinition:
      'A program that focuses on the comparative study of the history, literature, sociology, politics, culture, and economics of different groups such as racial, gender, sexual, class, ethnic, geographical, and national. Includes instruction in comparative studies, comparative literature, critical race theory, cultural anthropology, cultural studies, religious studies, science studies, social and cultural theory, urban studies, and visual culture.',
    CrossReferences: '30.2601 - Cultural Studies/Critical Theory and Analysis.',
  },
  {
    cipFamily: '5',
    cipCode: '5.0299',
    cipTitle: 'Ethnic, Cultural Minority, Gender, and Group Studies, Other',
    cipDefinition:
      'Any instructional program in ethnic, cultural minority, gender, and group studies not listed above.',
    Examples: "Examples: - Women's, Gay and Lesbian Studies, Integrated",
  },
  {
    cipFamily: '5',
    cipCode: '5.99',
    cipTitle: 'Area, Ethnic, Cultural, Gender, and Group Studies, Other',
    cipDefinition: 'Instructional content is defined in code 5.9999.',
  },
  {
    cipFamily: '5',
    cipCode: '5.9999',
    cipTitle: 'Area, Ethnic, Cultural, Gender, and Group Studies, Other',
    cipDefinition: 'Any instructional program in area, ethnic, cultural, gender, or group studies not listed above.',
  },
  {
    cipFamily: '9',
    cipCode: '9',
    cipTitle: 'Communication, Journalism, and Related Programs',
    cipDefinition:
      'Instructional programs that focus on how messages in various media are produced, used, and interpreted within and across different contexts, channels, and cultures, and that prepare individuals to apply communication knowledge and skills professionally.',
  },
  {
    cipFamily: '9',
    cipCode: '9.01',
    cipTitle: 'Communication and Media Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 9.0100 - 9.0199.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0100',
    cipTitle: 'Communication, General',
    cipDefinition:
      'A program that focuses on the comprehensive study of communication, and that spans the study of  mass communication/media studies, old and new media technologies, social and political applications, and speech communication and rhetoric. Includes instruction in interpersonal, group, organizational, and intercultural communication; theories of communication; critical thinking, argumentation, and persuasion; written communication; printed, electronic, and digital media; rhetorical tradition and criticism; media, society, and culture; consequences and effects of mass media; media social science and criticism; and quantitative and qualitative methods of inquiry.',
    Examples: 'Examples: - Communication Sciences, - Communication',
  },
  {
    cipFamily: '9',
    cipCode: '9.0101',
    cipTitle: 'Speech Communication and Rhetoric',
    cipDefinition:
      'A program that focuses on the scientific, humanistic, and critical study of human communication in a variety of formats, media, and contexts.  Includes instruction in the theory and practice of interpersonal, group, organizational, professional, and intercultural communication; speaking and listening; verbal and nonverbal interaction; rhetorical theory and criticism; performance studies; argumentation and persuasion; technologically mediated communication; popular culture; and various contextual applications.',
    CrossReferences: '23.1304 - Rhetoric and Composition.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0102',
    cipTitle: 'Mass Communication/Media Studies',
    cipDefinition:
      'A program that focuses on the analysis and criticism of media institutions and media texts, how people experience and understand media content, and the roles of media in producing and transforming culture.  Includes instruction in communications regulation, law, and policy; media history; media aesthetics, interpretation, and criticism; the social and cultural effects of mass media; cultural studies; the economics of media industries; visual and media literacy; and the psychology and behavioral aspects of media messages, interpretation, and utilization.',
    Examples: 'Examples: - Media Studies',
  },
  {
    cipFamily: '9',
    cipCode: '9.0199',
    cipTitle: 'Communication and Media Studies, Other',
    cipDefinition: 'Any instructional program in communication and media studies not listed above.',
  },
  {
    cipFamily: '9',
    cipCode: '9.04',
    cipTitle: 'Journalism',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 9.0401 - 9.0499.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0401',
    cipTitle: 'Journalism',
    cipDefinition:
      'A program that focuses on the theory and practice of gathering, processing, and delivering news and that prepares individuals to be professional print journalists, news editors, and news managers.  Includes instruction in news writing and editing; reporting; photojournalism; layout and graphic design; journalism law and policy; professional standards and ethics; research methods; and journalism history and criticism.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0402',
    cipTitle: 'Broadcast Journalism',
    cipDefinition:
      'A program that focuses on the methods and techniques for reporting, producing, and delivering news and news programs via radio, television, and video/film media; and that prepares individuals to be professional broadcast journalists, editors, producers, directors, and managers.  Includes instruction in the principles of broadcast technology; broadcast reporting; on- and off-camera and microphone procedures and techniques; program, sound, and video/film editing; program design and production; media law and policy; and professional standards and ethics.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0404',
    cipTitle: 'Photojournalism',
    cipDefinition:
      'A program that focuses on the use of still and motion photography in journalism and prepares individuals to function as news photographers and photographic editors.  Includes instruction in photography, journalism, studio procedures and techniques, camera and equipment operation and technique, news editing, print and film editing, news scene composition, subject surveillance, media law and policy, news team field operations, and professional standards and ethics.',
    CrossReferences: '50.0605 - Photography.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0405',
    cipTitle: 'Business and Economic Journalism',
    cipDefinition:
      'A program of study that prepares students to gather, analyze, and synthesize verbal and visual information to communicate stories related to business and economics across multiple media platforms. Includes instruction in accounting, business, business and economic reporting, business law, economics, finance, financial accounting and reporting, ethics, journalistic research methods and resources, and journalism skills and techniques.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0406',
    cipTitle: 'Cultural Journalism',
    cipDefinition:
      'A program of study that prepares individuals to be journalists who critically examine current culture as expressed through the visual and performing arts, design, film, music, radio, television, dance, theater, and written text. Includes instruction in arts criticism, art and entertainment reporting, cultural critique, investigative reporting, newspaper reporting, photojournalism, and writing.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0407',
    cipTitle: 'Science/Health/Environmental Journalism',
    cipDefinition:
      'A program that focuses on reporting on science, health, or environmental policy issues; investigating environmental problems and their causes; and public relations. Includes instruction in communication law, health reporting skills, interactive journalism, integrated marketing communication, journalism methods, media ethics, persuasion and political communication, science, health and the environment, social marketing, and video journalism.',
    CrossReferences: '1.0802 - Agricultural Communication/Journalism.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0499',
    cipTitle: 'Journalism, Other',
    cipDefinition: 'Any instructional program in journalism not listed above.',
    Examples: 'Examples: - Online Journalism',
  },
  {
    cipFamily: '9',
    cipCode: '9.07',
    cipTitle: 'Radio, Television, and Digital Communication',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 9.0701 - 9.0799.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0701',
    cipTitle: 'Radio and Television',
    cipDefinition:
      'A program that focuses on the theories, methods, and techniques used to plan, produce, and distribute audio and video programs and messages, and that prepares individuals to function as staff, producers, directors, and managers of radio and television shows and media organizations.  Includes instruction in media aesthetics; planning, scheduling, and production; writing and editing; performing and directing; personnel and facilities management; marketing and distribution; media regulations, law, and policy; and principles of broadcast technology.',
    Examples: 'Examples: - Radio, Television, and Film',
  },
  {
    cipFamily: '9',
    cipCode: '9.0702',
    cipTitle: 'Digital Communication and Media/Multimedia',
    cipDefinition:
      'A program that focuses on the development, use, critical evaluation, and regulation of new electronic communication technologies using computer applications; and that prepares individuals to function as developers and managers of digital communications media. Includes instruction in computer and telecommunications technologies and processes; design and development of digital communications; marketing and distribution; digital communications regulation, law, and policy; the study of human interaction with, and use of, digital media; and emerging trends and issues.',
    CrossReferences: '50.0102 - Digital Arts.',
    Examples: 'Examples: - Digital Media',
  },
  {
    cipFamily: '9',
    cipCode: '9.0799',
    cipTitle: 'Radio, Television, and Digital Communication, Other',
    cipDefinition: 'Any instructional program in radio, television, and digital communications not listed above.',
  },
  {
    cipFamily: '9',
    cipCode: '9.09',
    cipTitle: 'Public Relations, Advertising, and Applied Communication',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 9.0900 - 9.0999.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0900',
    cipTitle: 'Public Relations, Advertising, and Applied Communication',
    cipDefinition:
      'A general program that focuses on organizational communication, public relations, and advertising; and that prepares individuals to function in a wide range of public and private sector positions requiring the skills of persuasive communication. Includes instruction in communications, public relations, and advertising theory; principles and techniques of persuasion; message/image design; marketing strategy; professional writing; public speaking and multi-media presentation skills; digital communications; and applied research.',
    CrossReferences: '9.0903 - Advertising., 9.0902 - Public Relations/Image Management.',
    Examples: 'Examples: - Public Relations and Advertising, - Applied Communication',
  },
  {
    cipFamily: '9',
    cipCode: '9.0901',
    cipTitle: 'Organizational Communication, General',
    cipDefinition:
      'A program that focuses on general communication processes and dynamics within organizations.  Includes instruction in the development and maintenance of interpersonal group relations within organizations; decision-making and conflict management; the use of symbols to create and maintain organizational images, missions, and values; power and politics within organizations; human interaction with computer technology; and how communications socializes and supports employees and team members.',
    CrossReferences: '52.1003 - Organizational Behavior Studies.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0902',
    cipTitle: 'Public Relations/Image Management',
    cipDefinition:
      'A program that focuses on the theories and methods for managing the media image of a business, organization, or individual and the communication process with stakeholders, constituencies, audiences, and the general public; and that prepares individuals to function as public relations assistants, technicians, and managers.  Includes instruction in public relations theory; related principles of advertising, marketing, and journalism; message/image design; image management; special event management; media relations; community relations; public affairs; and internal communications.',
    CrossReferences: '9.0900 - Public Relations, Advertising, and Applied Communication.',
    Examples: 'Examples: - Social Media Management',
  },
  {
    cipFamily: '9',
    cipCode: '9.0903',
    cipTitle: 'Advertising',
    cipDefinition:
      'A program that focuses on the creation, execution, transmission, and evaluation of commercial messages in various media intended to promote and sell products, services, and brands; and that prepares individuals  to function as advertising assistants, technicians, and managers.  Includes instruction in advertising theory, marketing strategy, advertising design and production methods, campaign methods and techniques, media management, related principles of business management, and applicable technical and equipment skills.',
    CrossReferences:
      '50.0402 - Commercial and Advertising Art., 9.0900 - Public Relations, Advertising, and Applied Communication.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0904',
    cipTitle: 'Political Communication',
    cipDefinition:
      'A program that focuses on human and media communication in the political process and that prepares individuals to function as members of political and public affairs organizations, political campaign staffs, and related government and media entities.  Includes instruction in media effects, political speaking and debating, political advertising and marketing, image management, political journalism, opinion polling, and aspects of print and broadcast media related to the production and distribution of media messages in political settings.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0905',
    cipTitle: 'Health Communication',
    cipDefinition:
      'A program that focuses on how people, individually and collectively, understand and accommodate to health and illness and the role of communication and media in shaping professional health care messages and public acceptance of these messages.  Includes instruction in the development and use of health-related and care-related messages and media; the goals and strategies of health care promotion; relationships, roles, situations, and social structures in the context of health maintenance and promotion; and applications to disease prevention, health advocacy, and communications concerning treatments.',
    Examples: 'Examples: - Health and Health Science Communication',
  },
  {
    cipFamily: '9',
    cipCode: '9.0906',
    cipTitle: 'Sports Communication',
    cipDefinition:
      'A program that focuses on the methods and techniques for communicating about sports in a variety of formats, media, and contexts; and that prepares individuals to be sports reporters and writers, photojournalists, radio and television announcers, producers and directors, recreational sports promoters, and public relations specialists.  Includes instruction in sports writing, photography, broadcast journalism, sports production, game rules, media and public relations, and sports promotion.',
    CrossReferences: '31.0504 - Sport and Fitness Administration/Management., 31.0508 - Sports Studies.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0907',
    cipTitle: 'International and Intercultural Communication',
    cipDefinition:
      'A program that focuses on the specialized knowledge and skills needed for effective communication in the international community and among people of different cultures; and that prepares individuals to function at the national and international levels as journalists, public relations specialists, travel industry representatives, intercultural trainers, and/or foreign service officers.  Includes instruction in journalism, communications, intercultural and international relations, marketing and public relations, and global communications policy.',
    CrossReferences: '30.2301 - Intercultural/Multicultural and Diversity Studies.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0908',
    cipTitle: 'Technical and Scientific Communication',
    cipDefinition:
      'A program that focuses on the communication of technical and scientific knowledge to a variety of audiences through print, video, and digital media; and that prepares individuals to function as technical writers and editors, documentation developers, web designers, and usability specialists. Includes instruction in scientific and technical writing and editing, graphic and information design, web design, audience analysis, document usability and field testing, publications management, and applications to specific technical fields.',
    CrossReferences: '23.1303 - Professional, Technical, Business, and Scientific Writing.',
    Examples: 'Examples: - Technical Communication, - Scientific Communication',
  },
  {
    cipFamily: '9',
    cipCode: '9.0909',
    cipTitle: 'Communication Management and Strategic Communications',
    cipDefinition:
      'A program that focuses on the critical thinking, analysis, and practical skills essential to developing and implementing communication strategies that advance organizations goals and missions. Includes instruction in communication management, crisis communication, communications law, digital and traditional marketing strategies, media relations, social media strategies, strategic communication, traditional and emerging media, and writing for the media.',
  },
  {
    cipFamily: '9',
    cipCode: '9.0999',
    cipTitle: 'Public Relations, Advertising, and Applied Communication, Other',
    cipDefinition:
      'Any instructional program in organizational communication, public relations, and advertising not listed above.',
  },
  {
    cipFamily: '9',
    cipCode: '9.10',
    cipTitle: 'Publishing',
    cipDefinition: 'Instructional content is defined in code 9.1001.',
  },
  {
    cipFamily: '9',
    cipCode: '9.1001',
    cipTitle: 'Publishing',
    cipDefinition:
      'A program that focuses on the process of managing the creation, publication, and distribution of print and electronic books and other text products and prepares individuals to manage the editorial, technical, and business aspects of publishing operations.  Includes instruction in product planning and design, editing, author relations, business and copyright law, publishing industry operations, contracting and purchasing, product marketing, electronic publishing and commerce, history of publishing, and professional standards and ethics.',
  },
  {
    cipFamily: '9',
    cipCode: '9.99',
    cipTitle: 'Communication, Journalism, and Related Programs, Other',
    cipDefinition: 'Instructional content is defined in code 9.9999.',
  },
  {
    cipFamily: '9',
    cipCode: '9.9999',
    cipTitle: 'Communication, Journalism, and Related Programs, Other',
    cipDefinition: 'Any instructional program in communication, journalism, and related fields not listed above.',
  },
  {
    cipFamily: '10',
    cipCode: '10',
    cipTitle: 'Communications Technologies/Technicians and Support Services',
    cipDefinition:
      'Instructional programs that prepare individuals to function as equipment operators, support technicians, and operations managers in the film/video, recording, and graphic communications industries.',
  },
  {
    cipFamily: '10',
    cipCode: '10.01',
    cipTitle: 'Communications Technologies/Technicians',
    cipDefinition: 'Instructional content is defined in code 10.0105.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0105',
    cipTitle: 'Communications Technology/Technician',
    cipDefinition:
      'A program that generally prepares individuals to function as workers and managers within communications industries. Includes instruction in business economics; basic management; principles of interpersonal and mediated communications; radio, television, and digital media production; and related aspects of technology and communications systems.',
  },
  {
    cipFamily: '10',
    cipCode: '10.02',
    cipTitle: 'Audiovisual Communications Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 10.0201 - 10.0299.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0201',
    cipTitle: 'Photographic and Film/Video Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the operation and maintenance of camera and lighting equipment and the production of finished still, video, and film products under the supervision of photographers, directors, and editors.  Includes instruction in equipment setup and maintenance; lighting; power supplies; studio and film set tasks such as camera operator, grip, and best boy; plate and negative handling and development; laboratory procedures; and digital software applications.',
    Examples: 'Examples: - Photographic and Film/Video Assistant',
  },
  {
    cipFamily: '10',
    cipCode: '10.0202',
    cipTitle: 'Radio and Television Broadcasting Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the production of radio and television programs, and related operations, under the supervision of broadcast and studio managers, directors, editors, and producers.  Includes instruction in sound, lighting, and camera operation and maintenance; power and feed control; studio operations; production preparation; broadcast engineering; related computer applications; and specialized applications such as news, entertainment, live talk, sports, commercials, and taping.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0203',
    cipTitle: 'Recording Arts Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the production of sound recordings as finished products or as components of film/video, broadcast, live, or mixed media productions.  Includes instruction in sound equipment operation and maintenance; music, dialogue, and sound effects recording; soundtrack editing; dubbing and mixing; sound engineering; tape, disk, and CD production; digital recording and transmission; amplification and modulation; and working with producers, editors, directors, artists, and production managers.',
    CrossReferences: '50.0913 - Music Technology., 50.0917 - Sound Arts.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0204',
    cipTitle: 'Voice Writing Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to use voice writing computer software and speak a verbal shorthand language to create transcripts and screen captioning of live or recorded events intended for the public or a specific audience in a variety of media (e.g., digital, film, print, television) as well as for conferences, conversations, interviews, and meetings. Includes instruction in screen captioning, speed building, spoken shorthand, transcription, and voice input writing.',
    CrossReferences:
      '22.0303 - Court Reporting and Captioning/Court Reporter., 51.0708 - Medical Transcription/Transcriptionist.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0299',
    cipTitle: 'Audiovisual Communications Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in audiovisual communications technologies not listed above.',
    Examples: 'Examples: - Broadcast Captioning',
  },
  {
    cipFamily: '10',
    cipCode: '10.03',
    cipTitle: 'Graphic Communications',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 10.0301 - 10.0399.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0301',
    cipTitle: 'Graphic Communications, General',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills in the manufacture and distribution or transmission of graphic communications products.  Includes instruction in the prepress, press, and postpress phases of production operations and processes such as offset lithography, flexography, gravure, letterpress, screen printing, foil stamping, digital imaging, and other reproduction methods.',
    CrossReferences: '50.0409 - Graphic Design., 50.0402 - Commercial and Advertising Art.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0302',
    cipTitle: 'Printing Management',
    cipDefinition:
      'A program that prepares individuals to apply technical and managerial knowledge and skills to the processes and procedures of managing printing operations from initial design through finished product distribution.  Includes instruction in the principles of graphic communications design and production; quality control; printing operations management; computerization; printing plant management; business finance and marketing; logistics and distribution; personnel supervision and leadership; and professional standards in the graphic communications industry.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0303',
    cipTitle: 'Prepress/Desktop Publishing and Digital Imaging Design',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the layout, design and typographic arrangement of printed and/or electronic graphic and textual products.  Includes instruction in printing and lithographic equipment and operations, computer hardware and software, digital imaging, print preparation, page layout and design, desktop publishing, and applicable principles of graphic design and web page design.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0304',
    cipTitle: 'Animation, Interactive Technology, Video Graphics, and Special Effects',
    cipDefinition:
      'A program that prepares individuals to use computer applications and related visual and sound imaging techniques to manipulate images and information originating as film, video, still photographs, digital copy, soundtracks, and physical objects in order to communicate messages simulating real-world content.  Includes instruction in specialized camerawork and equipment operation and maintenance, image capture, computer programming, dubbing, CAD applications, and applications to specific commercial, industrial, and entertainment needs.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0305',
    cipTitle: 'Graphic and Printing Equipment Operator, General Production',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills to plan, prepare, and execute a variety of commercial and industrial graphic communications jobs.  Includes instruction in the operation and maintenance of mechanical, electronic, and digital graphic, printing, and finishing equipment; and related processes.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0306',
    cipTitle: 'Platemaker/Imager',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to prepare film, digital data, and surfaces to reproduce printed or graphic images.  Includes instruction in platemaking, stripping, camerawork, imposition, digital imaging, computer direct-to-plate and direct-to-press, and related processes.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0307',
    cipTitle: 'Printing Press Operator',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to set up, operate, and maintain printing presses.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0308',
    cipTitle: 'Computer Typography and Composition Equipment Operator',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to design and execute page formats, layouts and text composition, and to make typographical selections using computer graphics and other computer-assisted design programs.',
  },
  {
    cipFamily: '10',
    cipCode: '10.0399',
    cipTitle: 'Graphic Communications, Other',
    cipDefinition: 'Any instructional program in graphic communications not listed above.',
  },
  {
    cipFamily: '10',
    cipCode: '10.99',
    cipTitle: 'Communications Technologies/Technicians and Support Services, Other',
    cipDefinition: 'Instructional content is defined in code 10.9999.',
  },
  {
    cipFamily: '10',
    cipCode: '10.9999',
    cipTitle: 'Communications Technologies/Technicians and Support Services, Other',
    cipDefinition: 'Any instructional program in communications technologies and support services not listed above.',
  },
  {
    cipFamily: '11',
    cipCode: '11',
    cipTitle: 'Computer and Information Sciences and Support Services',
    cipDefinition:
      'Instructional programs that focus on the computer and information sciences and prepare individuals for various occupations in information technology and computer operations fields.',
  },
  {
    cipFamily: '11',
    cipCode: '11.01',
    cipTitle: 'Computer and Information Sciences, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 11.0101 - 11.0199.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0101',
    cipTitle: 'Computer and Information Sciences, General',
    cipDefinition:
      'A general program that focuses on computing, computer science, and information science and systems. Such programs are undifferentiated as to title and content and are not to be confused with specific programs in computer science, information science, or related support services.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0102',
    cipTitle: 'Artificial Intelligence',
    cipDefinition:
      'A program that focuses on the symbolic inference, representation, and simulation by computers and software of human learning and reasoning processes and capabilities, and the computer modeling of human motor control and motion. Includes instruction in computing theory, cybernetics, human factors, natural language processing, and applicable aspects of engineering, technology, and specific end-use applications.',
    CrossReferences: '14.4201 - Mechatronics, Robotics, and Automation Engineering.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0103',
    cipTitle: 'Information Technology',
    cipDefinition:
      'A program that focuses on the design of technological information systems, including computing systems, as solutions to business and research data and communications support needs.  Includes instruction in the principles of computer hardware and software components, algorithms, databases, telecommunications, user tactics, application testing, and human interface design.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0104',
    cipTitle: 'Informatics',
    cipDefinition:
      'A program that focuses on computer systems from a user-centered perspective and studies the structure, behavior and interactions of natural and artificial systems that store, process and communicate information. Includes instruction in information sciences, human computer interaction, information system analysis and design, telecommunications structure and information architecture and management.',
    CrossReferences: '26.1103 - Bioinformatics., 51.2706 - Medical Informatics.',
    Examples: 'Examples: - Social Informatics',
  },
  {
    cipFamily: '11',
    cipCode: '11.0105',
    cipTitle: 'Human-Centered Technology Design',
    cipDefinition:
      'A program that focuses on incorporating a human perspective into designing, researching, and creating technological interfaces. Includes instruction in design, human-computer interaction, learning, neuroscience, perception, product design, user-centered design, and usability.',
    CrossReferences: '30.3101 - Human Computer Interaction.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0199',
    cipTitle: 'Computer and Information Sciences,  Other',
    cipDefinition: 'Any instructional program in computer science not listed above.',
  },
  {
    cipFamily: '11',
    cipCode: '11.02',
    cipTitle: 'Computer Programming',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 11.0201 - 11.0299.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0201',
    cipTitle: 'Computer Programming/Programmer, General',
    cipDefinition:
      'A program that focuses on the general writing and implementation of generic and customized programs to drive operating systems and that generally prepares individuals to apply the methods and procedures of software design and programming  to software installation and maintenance.  Includes instruction in software design, low- and high-level languages and program writing; program customization and linking; prototype testing; troubleshooting; and related aspects of operating systems and networks.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0202',
    cipTitle: 'Computer Programming, Specific Applications',
    cipDefinition:
      'A program that prepares individuals to apply the knowledge and skills of general computer programming to the solution of specific operational problems and customization requirements presented by individual software users and organizational users.  Includes training in specific types of software and its installation and maintenance.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0203',
    cipTitle: 'Computer Programming, Vendor/Product Certification',
    cipDefinition:
      'A program that prepares individuals to fulfill the requirements set by vendors for professional qualification as certified installation, customization, and maintenance engineers for specific software products and/or processes.  Includes training in specific vendor-supported software products and their installation and maintenance.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0204',
    cipTitle: 'Computer Game Programming',
    cipDefinition:
      'A program that prepares individuals to apply the knowledge and skills of design and computer programming to the development of computer games. Includes training in character and story development, computer programming, computer graphics, game design, game physics, human-computer interaction, human-centered design, and usability.',
    CrossReferences: '50.0411 - Game and Interactive Media Design.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0205',
    cipTitle: 'Computer Programming, Specific Platforms',
    cipDefinition:
      'A program that prepares individuals to design, develop, and implement software-based solutions for specific business and consumer platforms including industrial, game, mobile, tactile, and web platforms. Includes instruction in computer programming, computer hardware, data structures, database theory, maintenance, operating systems, project management, software development, and systems analysis.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0299',
    cipTitle: 'Computer Programming, Other',
    cipDefinition: 'Any instructional program in computer programming not listed above.',
  },
  {
    cipFamily: '11',
    cipCode: '11.03',
    cipTitle: 'Data Processing',
    cipDefinition: 'Instructional content is defined in code 11.0301.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0301',
    cipTitle: 'Data Processing and Data Processing Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to master and use computer software programs and applications for inputting, verifying, organizing, storing, retrieving, transforming (changing, updating, and deleting), and extracting information.  Includes instruction in using various operating system configurations and in types of data entry such as word processing, spreadsheets, calculators, management programs, design programs, database programs, and research programs.',
  },
  {
    cipFamily: '11',
    cipCode: '11.04',
    cipTitle: 'Information Science/Studies',
    cipDefinition: 'Instructional content is defined in code 11.0401.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0401',
    cipTitle: 'Information Science/Studies',
    cipDefinition:
      'A program that focuses on the  theory, organization, and process of information collection, transmission, and utilization in traditional and electronic forms.  Includes instruction in information classification and organization; information storage and processing; transmission, transfer, and signaling; communications and networking; systems planning and design; human interfacing and use analysis; database development; information policy analysis; and related aspects of hardware, software, economics, social factors, and capacity.',
    CrossReferences: '25.0101 - Library and Information Science., 52.1201 - Management Information Systems, General.',
  },
  {
    cipFamily: '11',
    cipCode: '11.05',
    cipTitle: 'Computer Systems Analysis',
    cipDefinition: 'Instructional content is defined in code 11.0501.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0501',
    cipTitle: 'Computer Systems Analysis/Analyst',
    cipDefinition:
      'A program that prepares individuals to apply programming and systems analysis principles to the selection, implementation, and troubleshooting of customized computer and software installations across the life cycle.  Includes instruction in computer hardware and software; compilation, composition, execution, and operating systems; low- and high-level languages and language programming; programming and debugging techniques; installation and maintenance testing and documentation; process and data flow analysis; user needs analysis and documentation; cost-benefit analysis; and specification design.',
    CrossReferences: '14.2701 - Systems Engineering.',
  },
  {
    cipFamily: '11',
    cipCode: '11.06',
    cipTitle: 'Data Entry/Microcomputer Applications',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 11.0601 - 11.0699.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0601',
    cipTitle: 'Data Entry/Microcomputer Applications, General',
    cipDefinition:
      'A program that generally prepares individuals to perform basic data and text entry using standard and customized software products.  Includes instruction in keyboarding skills, personal computer and work station operation, reading draft texts and raw data forms, audio and tape dictation, and various interactive software programs used for tasks such as word processing, spreadsheets, databases, and others.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0602',
    cipTitle: 'Word Processing',
    cipDefinition:
      'A program that prepares individuals to perform text typing, table construction, and document formatting tasks on personal computers at a high rate of accuracy and proficiency.  Includes instruction in keyboarding skills, word processor operation, textual draft interpretation, audio and tape dictation, and specific word processing program software.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0699',
    cipTitle: 'Data Entry/Microcomputer Applications, Other',
    cipDefinition: 'Any instructional program in data entry/microcomputer applications not listed above.',
  },
  {
    cipFamily: '11',
    cipCode: '11.07',
    cipTitle: 'Computer Science',
    cipDefinition: 'Instructional content is defined in code 11.0701.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0701',
    cipTitle: 'Computer Science',
    cipDefinition:
      'A program that focuses on computer theory, computing problems and solutions, and the design of computer systems and user interfaces from a scientific perspective. Includes instruction in the principles of computational science, computer development and programming, and applications to a variety of end-use situations.',
    CrossReferences: '14.0901 - Computer Engineering, General.',
  },
  {
    cipFamily: '11',
    cipCode: '11.08',
    cipTitle: 'Computer Software and Media Applications',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 11.0801 - 11.0899.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0801',
    cipTitle: 'Web Page, Digital/Multimedia and Information Resources Design',
    cipDefinition:
      'A program that prepares individuals to apply HTML, CSS, XML, JavaScript, graphics applications, and other authoring tools to the design, editing, and publishing (launching) of documents, images, graphics, sound, and multimedia products on the World Wide Web. Includes instruction in Internet theory, web page standards and policies, elements of web page design, user interfaces, vector tools, special effects, interactive and multimedia components, search engines, navigation, morphing, e-commerce tools, and emerging web technologies.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0802',
    cipTitle: 'Data Modeling/Warehousing and Database Administration',
    cipDefinition:
      'A program that prepares individuals to design and manage the construction of databases and related software programs and applications, including the linking of individual data sets to create complex searchable databases (warehousing) and the use of analytical search tools (mining). Includes instruction in database theory, logic, and semantics; operational and warehouse modeling; dimensionality; attributes and hierarchies; data definition; technical architecture; access and security design; integration; formatting and extraction; data delivery; index design; implementation problems; planning and budgeting; and client and networking issues.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0803',
    cipTitle: 'Computer Graphics',
    cipDefinition:
      'A program that focuses on the software, hardware, and mathematical tools used to represent, display, and manipulate topological, two-, and three-dimensional objects on a computer screen and that prepares individuals to function as computer graphics specialists.  Includes instruction in graphics software and systems; digital multimedia; graphic design; graphics devices, processors, and standards; attributes and transformations; projections; surface identification and rendering; color theory and application; and applicable geometry and algorithms.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0804',
    cipTitle: 'Modeling, Virtual Environments and Simulation',
    cipDefinition:
      'A program focusing on the principles of applied visual simulation technology and the application of quantitative analyses to human-computer interaction.  Includes instruction in object-oriented programming, artificial intelligence, computer communications and networks, computer graphics, virtual worlds and simulation systems, probability, statistics, stochastic modeling, data analysis, human-performance evaluation, and human-behavior modeling.',
    CrossReferences: '50.0411 - Game and Interactive Media Design.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0899',
    cipTitle: 'Computer Software and Media Applications, Other',
    cipDefinition: 'Any instructional program in computer software and media applications not listed above.',
  },
  {
    cipFamily: '11',
    cipCode: '11.09',
    cipTitle: 'Computer Systems Networking and Telecommunications',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 11.0901 - 11.0999.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0901',
    cipTitle: 'Computer Systems Networking and Telecommunications',
    cipDefinition:
      'A program that focuses on the design, implementation, and management of linked systems of computers, peripherals, and associated software to maximize efficiency and productivity, and that prepares individuals to function as network specialists and managers at various levels.  Includes instruction in operating systems and applications; systems design and analysis; networking theory and solutions; types of networks; network management and control; network and flow optimization; security; configuring; and troubleshooting.',
    Examples: 'Examples: - Computer Systems Telecommunications, - Computer Systems Networking',
  },
  {
    cipFamily: '11',
    cipCode: '11.0902',
    cipTitle: 'Cloud Computing',
    cipDefinition:
      'A program that prepares individuals to design and implement enterprise software systems that rely on distributed computing and service-oriented architecture, including databases, web services, cloud computing, and mobile apps. Includes instruction in data management, distributed and cloud computing, enterprise software architecture, enterprise and cloud security, mobile systems and applications, server administration, and web development.',
  },
  {
    cipFamily: '11',
    cipCode: '11.0999',
    cipTitle: 'Computer Systems Networking and Telecommunications, Other',
    cipDefinition: 'Any instructional program in computer systems networking and telecommunications not listed above.',
  },
  {
    cipFamily: '11',
    cipCode: '11.10',
    cipTitle: 'Computer/Information Technology Administration and Management',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 11.1001 - 11.1099.',
  },
  {
    cipFamily: '11',
    cipCode: '11.1001',
    cipTitle: 'Network and System Administration/Administrator',
    cipDefinition:
      'A program that prepares individuals to manage the computer operations and control the system configurations emanating from a specific site or network hub.  Includes instruction in computer hardware and software and applications; local area (LAN) and wide area (WAN) networking; principles of information systems security; disk space and traffic load monitoring; data backup; resource allocation; and setup and takedown procedures.',
    Examples: 'Examples: - Network Administration',
  },
  {
    cipFamily: '11',
    cipCode: '11.1002',
    cipTitle: 'System, Networking, and LAN/WAN Management/Manager',
    cipDefinition:
      'A program that prepares individuals to oversee and regulate the computer system and performance requirements of an entire organization or network of satellite users.  Includes instruction in performance balancing; redundancy; local area (LAN) and wide area (WAN) network management; system migration and upgrading; outage control; problem diagnosis and troubleshooting; and system maintenance, budgeting, and management.',
  },
  {
    cipFamily: '11',
    cipCode: '11.1003',
    cipTitle: 'Computer and Information Systems Security/Auditing/Information Assurance',
    cipDefinition:
      'A program that prepares individuals to assess the security needs of computer and network systems, recommend safeguard solutions, and manage the implementation, auditing, and maintenance of security devices, systems, and procedures.  Includes instruction in computer architecture, programming, and systems analysis; networking; telecommunications; cryptography; security system auditing and design; applicable law and regulations; risk assessment and policy analysis; contingency planning; user access issues; investigation techniques; and troubleshooting.',
    CrossReferences: '43.0403 - Cyber/Computer Forensics and Counterterrorism.',
    Examples:
      'Examples: - Information Assurance, - IT Security, - Internet Security, - Network Security, - Information Systems Security, - Information Systems Auditing and Control',
  },
  {
    cipFamily: '11',
    cipCode: '11.1004',
    cipTitle: 'Web/Multimedia Management and Webmaster',
    cipDefinition:
      'A program that prepares individuals to develop and maintain web servers and the hosted web pages at one or a group of web sites, and to function as designated webmasters.  Includes instruction in computer systems and networks, server installation and maintenance, web page design and editing, information resources management, web policy and procedures, Internet applications of information systems security, user interfacing and usability research, and relevant management and communications skills.',
    Examples: 'Examples: - Website Development, - Web Development, - Webmaster',
  },
  {
    cipFamily: '11',
    cipCode: '11.1005',
    cipTitle: 'Information Technology Project Management',
    cipDefinition:
      'A program that prepares individuals to design, develop, and manage information technology projects in a variety of companies and organizations. Includes instruction in principles of project management, risk management, procurement and contract management, information security management, software management, organizational principles and behavior, communications, quality assurance, financial analysis, leadership, and team effectiveness.',
    CrossReferences: '52.0211 - Project Management.',
  },
  {
    cipFamily: '11',
    cipCode: '11.1006',
    cipTitle: 'Computer Support Specialist',
    cipDefinition:
      'A program that prepares individuals to provide technical assistance, support, and advice to computer users to help troubleshoot software and hardware problems. Includes instruction in computer concepts, information systems, networking, operating systems, computer hardware, the Internet, software applications, help desk concepts and problem solving, and principles of customer service.',
    Examples: 'Examples: - Technical Support Specialist, - Help Desk Specialist, - IT Support Specialist',
  },
  {
    cipFamily: '11',
    cipCode: '11.1099',
    cipTitle: 'Computer/Information Technology Services Administration and Management, Other',
    cipDefinition:
      'Any instructional program in computer/information technology services administration and management not listed above.',
    CrossReferences: '51.0706 - Health Information/Medical Records Administration/Administrator.',
  },
  {
    cipFamily: '11',
    cipCode: '11.99',
    cipTitle: 'Computer and Information Sciences and Support Services, Other',
    cipDefinition: 'Instructional content is defined in code 11.9999.',
  },
  {
    cipFamily: '11',
    cipCode: '11.9999',
    cipTitle: 'Computer and Information Sciences and Support Services, Other',
    cipDefinition:
      'Any instructional program in computer and information sciences and support services not listed above.',
    CrossReferences:
      '51.0709 - Medical Office Computer Specialist/Assistant., 51.0707 - Health Information/Medical Records Technology/Technician.',
  },
  {
    cipFamily: '12',
    cipCode: '12',
    cipTitle: 'Culinary, Entertainment, and Personal Services',
    cipDefinition:
      'Instructional programs that prepare individuals to provide professional services related to cosmetology, funeral services, entertainment, and food preparation and service.',
  },
  {
    cipFamily: '12',
    cipCode: '12.03',
    cipTitle: 'Funeral Service and Mortuary Science',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 12.0301 - 12.0399.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0301',
    cipTitle: 'Funeral Service and Mortuary Science, General',
    cipDefinition:
      'A program that generally prepares individuals for careers in the funeral service industry and for licensure as funeral service directors or morticians.  Includes instruction in the basic elements of mortuary science and the business, counseling, and operational aspects of funeral service.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0302',
    cipTitle: 'Funeral Direction/Service',
    cipDefinition:
      'A program that prepares individuals for professional licensure as funeral directors and as managers of funeral homes, cemeteries, and related services.  Includes instruction in the sociology of death and dying, psychology of grief and grief counseling, history of funeral service, funeral direction, business law, funeral service law, funeral home management, accounting and related computer operations, and funeral services marketing and merchandising.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0303',
    cipTitle: 'Mortuary Science and Embalming/Embalmer',
    cipDefinition:
      'A program that prepares individuals for licensure as embalmers and morticians.  Includes instruction in pathogenic microbiology, systematic pathology, thanatochemistry, gross anatomy, clinical mortuary science, embalming, restorative art, applicable laws and regulations, and special services such as cremation and preparations required by specific religious communities.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0399',
    cipTitle: 'Funeral Service and Mortuary Science, Other',
    cipDefinition: 'Any instructional program in funeral service and mortuary science not listed above.',
  },
  {
    cipFamily: '12',
    cipCode: '12.04',
    cipTitle: 'Cosmetology and Related Personal Grooming Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 12.0401 - 12.0499.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0401',
    cipTitle: 'Cosmetology/Cosmetologist, General',
    cipDefinition:
      'A program that generally prepares individuals to cut, trim, and style scalp, facial, and body hair; apply cosmetic preparations; perform manicures and pedicures; massage the head and extremities; and prepare for practice as licensed cosmetologists in specialized or full-service salons.  Includes instruction in hair cutting and styling, manicuring, pedicuring, facial treatments, shampooing, chemical applications, esthetics, shop management, sanitation and safety, customer service, and applicable professional and labor laws and regulations.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0402',
    cipTitle: 'Barbering/Barber',
    cipDefinition:
      'A program that prepares individuals to shave and trim facial/neck hair and beards, cut and dress hair, fit hairpieces, give facial and scalp massages, apply cosmetic treatments, and to prepare for licensure as professional barbers at various levels.  Includes instruction in facial shaving; beard and mustache shaping and trimming; shampooing; hair cutting; hair styles and styling art; facial treatments and massage; chemical applications; hair and scalp anatomy and physiology; hairpiece and toupee fitting; equipment operation; health and safety; customer service; and shop business practices.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0404',
    cipTitle: 'Electrolysis/Electrology and Electrolysis Technician',
    cipDefinition:
      'A program that prepares individuals to permanently remove hair from the human scalp, face, and body using specialized charged solid needle probes, and to function as licensed electrologists and electrolysis technicians.  Includes instruction in direct current electrolysis, alternating current/high frequency thermolysis, blend/dual modality treatments, equipment theory and operation, safety and sanitation, client evaluation and care, laws and regulations, and business practices.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0406',
    cipTitle: 'Make-Up Artist/Specialist',
    cipDefinition:
      'A program that prepares individuals to professionally apply cosmetic makeup preparations and perform complete and specialized appearance makeovers including hairdressing, wig work, masking, temporary prosthesis applications, cosmetic applications, and related costuming for leisure or for professional stage, camera, clinical, or security purposes.  Includes instruction in period and contemporary hairstyling and costuming; wig work and hairpiece application; synthetic hair and masks; cosmetic preparations and treatments; makeup artistry; attachment, removal, and camouflaging of prostheses; health and safety; client consultation and care; pre- and post-production operations; set and crew conduct and relations; script, instruction, and prescription interpretation; labor relations; and business practices.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0407',
    cipTitle: 'Hair Styling/Stylist and Hair Design',
    cipDefinition:
      'A program that prepares individuals to shampoo, cut, style, set, and dress the scalp hair of women and/or men, braid hair, apply hair cosmetics and wigs, and prepare for licensure as professional cosmetologists, hairdressers, and barbers at various levels.  Includes instruction in hair design theory and concepts, hair cleaning and shampooing, hair coloring, chemical applications and permanent reconstructions, hair anatomy and physiology, scalp diseases and treatments, wig and hairpiece fitting, hair braiding, customer service, shop management, equipment operation, health and safety, and applicable laws and regulations.',
    Examples: 'Examples: - Natural Hair Braider',
  },
  {
    cipFamily: '12',
    cipCode: '12.0408',
    cipTitle: 'Facial Treatment Specialist/Facialist',
    cipDefinition:
      'A program that prepares individuals to massage and treat the face, neck, and scalp with chemical and cosmetic preparations, and to function as licensed facial specialists in specialized and full service salons.  Includes instruction in facial surface anatomy; skin properties; skin decontamination and infection control; facial massage; facial makeup; facial wrapping; selection and application of creams, lotions, oils, and other chemicals and cosmetics; European and other facial techniques; client evaluation and care; applicable laws and regulations; and business practices.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0409',
    cipTitle: 'Aesthetician/Esthetician and Skin Care Specialist',
    cipDefinition:
      'A program that prepares individuals to cleanse, depilate, massage, and beautify the human body and to function as licensed estheticians and skin care specialists.  Includes instruction in skin anatomy, physiology, and health; principles of nutrition; decontamination and infection control; health and safety; facial and body massage; body wrapping and spa treatments; temporary hair removal including waxing and tweezing; color and skin analysis; client consultation and care; applicable laws and regulations; business practices; and sometimes related alternative healing regimens.',
    Examples: 'Examples: - Waxing, - Basic Aesthetician/Esthetician',
  },
  {
    cipFamily: '12',
    cipCode: '12.041',
    cipTitle: 'Nail Technician/Specialist and Manicurist',
    cipDefinition:
      'A program that prepares individuals to shape fingernails and toenails, remove unwanted skin and blemishes, apply polish and cosmetics to nails, and function as licensed manicurists or nail technicians/specialists.  Includes instruction in manicuring theory; skin anatomy; nail growth, irregularities, and diseases; sterilization and sanitation; equipment and table maintenance; cuticle, blemish, and rough skin removal; nail filing, shaping, and polishing; cream application and extremity massage; nail sculpture and design art; product storage and use; customer service; laws and regulations; and business practices.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0411',
    cipTitle: 'Permanent Cosmetics/Makeup and Tattooing',
    cipDefinition:
      'A program that prepares individuals to prepare and apply permanent markings, color, and cosmetic products to the face and body for aesthetic or clinical purposes; to remove unwanted markings; and to function as licensed permanent makeup artists and tattooists.  Includes instruction in client consultation; sterilization and sanitation; skin anatomy, diseases, and disorders; skin preparation and anesthetics; color theory; machine theory and operation; needle selection; applications such as eyebrows, eyeliners, lips, lipliners, beauty marks, and blemish/mark removal; after care; laws and regulations; and business practices.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0412',
    cipTitle: 'Salon/Beauty Salon Management/Manager',
    cipDefinition:
      'A program that prepares cosmetologists, hairstylists, and other personal grooming specialists to manage beauty parlors, shops, and full-service or specialized salons and to prepare for licensure as professional salon owners and operators.  Includes instruction in cosmetic services marketing and retailing; advertising and promotion; salon management; the cosmetic and salon supply industries; hiring, supervision, and labor relations; applicable business and professional laws and regulations; professional standards and image; and customer service.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0413',
    cipTitle: 'Cosmetology, Barber/Styling, and Nail Instructor',
    cipDefinition:
      'A program that prepares cosmetologists, barbers, stylists, nail technicians, and other grooming specialists to teach their specializations in licensed and accredited schools of cosmetology and barbering, and to prepare for licensure as instructors.  Includes instruction in cosmetology arts and sciences teaching principles; student learning styles; professional curricula development; instructional planning; instructional methods and aids; student counseling and career assistance; test development; and classroom/clinic management.',
    Examples: 'Examples: - Cosmetology Master Instructor',
  },
  {
    cipFamily: '12',
    cipCode: '12.0414',
    cipTitle: 'Master Aesthetician/Esthetician',
    cipDefinition:
      "A program that prepares individuals to massage and treat the face, neck, and body with advanced chemical and cosmetic preparations and esthetic medical treatments, and to function as licensed master or medical estheticians in dermatologists' and plastic surgeons' offices as well as in salons and spas. Includes instruction in advanced anatomy and physiology, skin properties and disorders, sanitation and infection control, advanced facial treatments, spa body treatments, advanced extractions, corrective makeup, chemical peels, microdermabrasion, lymphatic drainage and advanced massage techniques, pre- and post-operative esthetic skin care, client/patient evaluation and care, applicable laws and regulations, and business practices.",
    Examples:
      'Examples: - Medical Aesthetician/Esthetician, - Advanced Aesthetician/Esthetician, - Advanced Paramedical Aesthetician/Esthetician',
  },
  {
    cipFamily: '12',
    cipCode: '12.0499',
    cipTitle: 'Cosmetology and Related Personal Grooming Arts, Other',
    cipDefinition: 'Any instructional program in cosmetology and related personal grooming services not listed above.',
  },
  {
    cipFamily: '12',
    cipCode: '12.05',
    cipTitle: 'Culinary Arts and Related Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 12.0500 - 12.0599.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0500',
    cipTitle: 'Cooking and Related Culinary Arts, General',
    cipDefinition:
      'A program that focuses on the general study of the cooking and related culinary arts, and that may prepare individuals for a variety of jobs within the food service industry.  Includes instruction in food preparation, cooking techniques, equipment operation and maintenance, sanitation and safety, communication skills, applicable regulations, and principles of food service management.',
    Examples: 'Examples: - Cook',
  },
  {
    cipFamily: '12',
    cipCode: '12.0501',
    cipTitle: 'Baking and Pastry Arts/Baker/Pastry Chef',
    cipDefinition:
      'A program that prepares  individuals to serve as professional bakers and pastry specialists in restaurants or other commercial baking establishments.  Includes instruction in bread and pastry making, bread and pastry handling and storage, cake and pastry decorating, baking industry operations, product packaging and marketing operations, and counter display and service.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0502',
    cipTitle: 'Bartending/Bartender',
    cipDefinition:
      'A program that prepares individuals to professionally prepare mixed alcoholic and non-alcoholic beverages and related products and manage bars, lounges, and beverage service operations in the hospitality industry.  Includes instruction in mixology, oenology, accounting and cash management, inventory and cellar management, bar and lounge management, applicable laws and regulations, customer service, and labor/employment regulations.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0503',
    cipTitle: 'Culinary Arts/Chef Training',
    cipDefinition:
      'A program that prepares individuals to provide professional chef and related cooking services in restaurants and other commercial food establishments.  Includes instruction in recipe and menu planning, preparing and cooking of foods, supervising and training kitchen assistants, the management of food supplies and kitchen resources, aesthetics of food presentation, and familiarity or mastery of a wide variety of cuisines and culinary techniques.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0504',
    cipTitle: 'Restaurant, Culinary, and Catering Management/Manager',
    cipDefinition:
      'A program that prepares individuals to plan, supervise, and manage food and beverage preparation and service operations, restaurant facilities, and catering services.  Includes instruction in food/beverage industry operations, cost control, purchasing and storage, business administration, logistics, personnel management, culinary arts, restaurant and menu planning, executive chef functions, event planning and management, health and safety, insurance, and applicable law and regulations.',
    CrossReferences: '52.0905 - Restaurant/Food Services Management.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0505',
    cipTitle: 'Food Preparation/Professional Cooking/Kitchen Assistant',
    cipDefinition:
      'A program that prepares individuals to serve under the supervision of chefs and other food service professionals as kitchen support staff and commercial food preparation workers.  Includes instruction in kitchen organization and operations, sanitation and quality control, basic food preparation and cooking skills, kitchen and kitchen equipment maintenance, and quantity food measurement and monitoring.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0506',
    cipTitle: 'Meat Cutting/Meat Cutter',
    cipDefinition:
      'A program that prepares individuals to receive, cut, and package animal meat products in commercial establishments and to function as licensed meat cutters/butchers.  Includes instruction in product recognition for beef, veal, lamb, pork, poultry, and fancy and smoked meats; retail and wholesale cutting and specialty cuts; packaging and counter display; shop safety; meat sanitation, storage and rotation; quality control; meat handling laws and regulations; and customer service.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0507',
    cipTitle: 'Food Service, Waiter/Waitress, and Dining Room Management/Manager',
    cipDefinition:
      'A program that prepares individuals to serve food to customers in formal or informal settings.  Includes instruction in the nutritional, sensory, and functional properties of food and its ingredients; food services principles; table and counter services; dining room operations and procedures; service personnel supervision and management; communication skills; business math; safety; and sanitation.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0508',
    cipTitle: 'Institutional Food Workers',
    cipDefinition:
      'A program that prepares individuals in production and service used in governmental, commercial or independently owned institutional food establishments and related food industry occupations. Includes instruction in selecting, storing, preparing, and serving food and food products; basic nutrition; sanitation, and food safety; the use and care of commercial equipment; serving techniques; and the operation of institutional food establishments.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0509',
    cipTitle: 'Culinary Science/Culinology',
    cipDefinition:
      'A program that focuses on the blending of food science and the culinary arts and that prepares individuals to work as research chefs and related research and development positions in the food industry. Includes instruction in culinary arts, food chemistry, food safety and quality, food processing, nutrition, and business management.',
    CrossReferences: '1.1001 - Food Science.',
  },
  {
    cipFamily: '12',
    cipCode: '12.051',
    cipTitle: 'Wine Steward/Sommelier',
    cipDefinition:
      'A program that prepares individuals to manage wine selection, purchasing, storage, sales, and service for a variety of establishments in the hospitality industry. Includes instruction in wine production, wine regions of the world, wine tasting, food and wine matching, service techniques, and wine program management.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0580',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0599',
    cipTitle: 'Culinary Arts and Related Services, Other',
    cipDefinition: 'Any instructional program in culinary arts and related services not listed above.',
  },
  {
    cipFamily: '12',
    cipCode: '12.06',
    cipTitle: 'Casino Operations and Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 12.0601 - 12.0699.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0601',
    cipTitle: 'Casino Operations and Services, General',
    cipDefinition:
      'A program that focuses on casino operations and services. Includes instruction in casino operations, casino security and surveillance, casino dealing, principles of the gaming industry, ethics, and gaming law.',
    CrossReferences: '52.0908 - Casino Management.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0602',
    cipTitle: 'Casino Dealing',
    cipDefinition:
      'A program of study that prepares individuals to apply technical skills and knowledge to become table games dealers in casinos or other recreational settings. Includes instruction in dealing techniques for Baccarat, Blackjack, Carnival Games, Craps, Poker, and Roulette.',
    CrossReferences: '52.0908 - Casino Management.',
  },
  {
    cipFamily: '12',
    cipCode: '12.0699',
    cipTitle: 'Casino Operations and Services, Other',
    cipDefinition: 'Any instructional program in casino operations and services not listed above.',
  },
  {
    cipFamily: '12',
    cipCode: '12.99',
    cipTitle: 'Culinary, Entertainment, and Personal Services, Other',
    cipDefinition: 'Instructional content is defined in code 12.9999.',
  },
  {
    cipFamily: '12',
    cipCode: '12.9999',
    cipTitle: 'Culinary, Entertainment, and Personal Services, Other',
    cipDefinition: 'Any instructional program in culinary, entertainment, and personal services not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13',
    cipTitle: 'Education',
    cipDefinition:
      'Instructional programs that focus on the theory and practice of learning and teaching, and related research, administrative and support services.',
  },
  {
    cipFamily: '13',
    cipCode: '13.01',
    cipTitle: 'Education, General',
    cipDefinition: 'Instructional content is defined in code 13.0101.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0101',
    cipTitle: 'Education, General',
    cipDefinition:
      'A program that focuses on the general theory and practice of learning and teaching, the basic principles of educational psychology, the art of teaching, the planning and administration of educational activities, school safety and health issues, and the social foundations of education.',
  },
  {
    cipFamily: '13',
    cipCode: '13.02',
    cipTitle: 'Bilingual, Multilingual, and Multicultural Education',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.0201 - 13.0299.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0201',
    cipTitle: 'Bilingual and Multilingual Education',
    cipDefinition:
      'A program that focuses on the design and provision of teaching and other educational services to bilingual/bicultural children or adults, and/or the design and implementation of educational programs having the goal of producing bilingual/bicultural individuals.  Includes preparation to serve as teachers and administrators in bilingual/bicultural education programs.',
    CrossReferences: '13.1401 - Teaching English as a Second or Foreign Language/ESL Language Instructor.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0202',
    cipTitle: 'Multicultural Education',
    cipDefinition:
      'A program that focuses on the design, and implementation of instructional and advising services for culturally diverse learning populations.  Includes instruction in cultural diversity, at-risk populations, multilingual and ESL education, program and curriculum design, instructional technology, information resources, LEP and minority education strategies, counseling and communicating with multicultural populations, law and regulations, and applications to specific cultural groups, educational services, and research issues.',
    CrossReferences: '13.1401 - Teaching English as a Second or Foreign Language/ESL Language Instructor.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0203',
    cipTitle: 'Indian/Native American Education',
    cipDefinition:
      'A program that focuses on the design and implementation of instructional and advising services for American Indian, Alaska Native, and Hawaiian Native students at various learning levels and that prepares individuals to perform teaching and administrative functions in Tribal/First Nation schools.  Includes instruction in the cultural traditions and social patterns, history and politics, learning styles and issues, curriculum design, LEP and minority education strategies, counseling techniques and social issues, rural education services, distance education, adult education techniques, school administration, applicable laws and regulations, and research issues.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0299',
    cipTitle: 'Bilingual, Multilingual, and Multicultural Education, Other',
    cipDefinition:
      'Any instructional program in bilingual, multilingual, and multicultural Education not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.03',
    cipTitle: 'Curriculum and Instruction',
    cipDefinition: 'Instructional content is defined in code 13.0301.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0301',
    cipTitle: 'Curriculum and Instruction',
    cipDefinition:
      'A program that focuses on the curriculum and related instructional processes and tools, and that may prepare individuals to serve as professional curriculum specialists.  Includes instruction in curriculum theory, curriculum design and planning, instructional material design and evaluation, curriculum evaluation, and applications to specific subject matter, programs or educational levels.',
    Examples: 'Examples: - Effective Teaching',
  },
  {
    cipFamily: '13',
    cipCode: '13.04',
    cipTitle: 'Educational Administration and Supervision',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.0401 - 13.0499.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0401',
    cipTitle: 'Educational Leadership and Administration, General',
    cipDefinition:
      'A  program that focuses on the general principles and techniques of administering a wide variety of schools and other educational organizations and facilities, supervising educational personnel at the school or staff level, and that may prepare individuals as general administrators and supervisors.',
    Examples:
      'Examples: - Educational Leadership and Policy, - Educational Administration and Policy, - K-12 Educational Leadership, - K-12 Educational Administration, - School Business Management',
  },
  {
    cipFamily: '13',
    cipCode: '13.0402',
    cipTitle: 'Administration of Special Education',
    cipDefinition:
      'A program that prepares individuals to plan, supervise, and manage programs for exceptional students and their parents.  Includes instruction in special education theory and practice, special education program development, evaluation and assessment in special education, state and federal law and regulations, managing individual education plans, problems of low- and high- disability students, mainstreaming, special education curricula, staff management, parent education, communications and community relations, budgeting, and professional standards and ethics.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0403',
    cipTitle: 'Adult and Continuing Education Administration',
    cipDefinition:
      'A program  that focuses on the principles and techniques of administering programs and facilities designed to serve the basic education needs of undereducated adults, or the continuing education needs of adults seeking further or specialized instruction, and that prepares individuals to serve as administrators of such programs.  Includes instruction in adult education principles, program and facilities planning, personnel management, community and client relations, budgeting and administration, professional standards, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0404',
    cipTitle: 'Educational, Instructional, and Curriculum Supervision',
    cipDefinition:
      'A program that prepares individuals to supervise instructional and support personnel at the school building, facility or staff level.  Includes instruction in the principles of staffing and organization, the supervision of learning activities, personnel relations, administrative duties related to departmental or unit management, and specific applications to various educational settings and curricula.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0406',
    cipTitle: 'Higher Education/Higher Education Administration',
    cipDefinition:
      'A program that focuses on the principles and practice of administration in four-year colleges, universities and higher education systems, the study of higher education as an object of applied research, and which may prepare individuals to function as administrators in such settings.  Includes instruction in higher education economics and finance; policy and planning studies; curriculum; faculty and labor relations; higher education law; college student services; research on higher education; institutional research; marketing and promotion; and issues of evaluation, accountability and philosophy.',
    CrossReferences: '13.1102 - College Student Counseling and Personnel Services.',
    Examples: 'Examples: - Higher Education Policy',
  },
  {
    cipFamily: '13',
    cipCode: '13.0407',
    cipTitle: 'Community College Administration',
    cipDefinition:
      'A program that focuses on the principles and techniques of administering community and junior colleges and related postsecondary systems, the study of community and junior colleges as objects of applied research, and that may prepare individuals to function as administrators in such settings.  Includes instruction in community and junior college finance; policy and planning studies; curriculum; faculty and labor relations; higher education law; student services; research on community and junior colleges; institutional research; marketing and promotion; and issues of evaluation, accountability and philosophy.',
    Examples: 'Examples: - Community College Leadership, - Community College Education',
  },
  {
    cipFamily: '13',
    cipCode: '13.0408',
    cipTitle: 'Elementary and Middle School Administration/Principalship',
    cipDefinition:
      'A program that focuses on educational administration at the elementary and middle school (K-7) levels and prepares individuals to serve as principals and masters of elementary and middle schools.  Includes instruction in elementary and/or middle school education, program and facilities planning, budgeting and administration, public relations, human resources management, childhood and pre-adolescent growth and development, counseling skills, applicable law and regulations, school safety, policy studies, and professional standards and ethics.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0409',
    cipTitle: 'Secondary School Administration/Principalship',
    cipDefinition:
      'A program that focuses on educational administration at the junior high, secondary, or senior high school (7-12) levels and prepares individuals to serve as principals and masters of secondary schools.  Includes instruction in secondary school education, program and facilities planning, budgeting and administration, public relations, human resources management, adolescent growth and development, counseling skills, applicable law and regulations, school safety, policy studies, and professional standards and ethics.',
  },
  {
    cipFamily: '13',
    cipCode: '13.041',
    cipTitle: 'Urban Education and Leadership',
    cipDefinition:
      'A program that focuses on issues and problems specific to the educational needs of populations and communities located in metropolitan, inner city, and related areas and prepares individuals to provide educational services and administrative leadership in urban contexts.  Includes instruction in multicultural education, at-risk studies, urban sociology, multilingual education, urban studies and planning, human services, health services and nutrition, cross-cultural communication, diversity studies, safety and security, and urban educational administration and leadership.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0411',
    cipTitle: 'Superintendency and Educational System Administration',
    cipDefinition:
      'A program that focuses on leading and managing multi-school educational systems and school districts and prepares individuals to serve as systems administrators and district superintendents.  Includes instruction in educational administration; education of students at various levels; system planning and budgeting; educational policy; educational law and regulations; public relations; professional standards and ethics; and applications to specific issues, cultural context, and geographic locales.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0412',
    cipTitle: 'International School Administration/Leadership',
    cipDefinition:
      'A program that focuses on the principles and practice of administration in international schools, the study of international education as an object of applied research, and which may prepare individuals to function as administrators in such settings. Includes instruction in culture in the classroom, educational leadership, globalization, instructional design and technology, international research methods, evaluation, and education policy.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0413',
    cipTitle: 'Education Entrepreneurship',
    cipDefinition:
      'A program that prepares individuals to create, fund, and manage innovations in education. Includes instruction in philanthropy, business modeling, the design of learning environments, the economics of education, entrepreneurship, evaluation, finance, human-centered design, investing, resource management, project management, and marketing.',
    CrossReferences: '52.0704 - Social Entrepreneurship.',
    Examples: 'Examples: - Education Innovation',
  },
  {
    cipFamily: '13',
    cipCode: '13.0414',
    cipTitle: 'Early Childhood Program Administration',
    cipDefinition:
      'A program that focuses on early childhood educational program administration and prepares individuals to serve as a principal or director of an early childhood educational program. Includes instruction in early childhood education, program and facilities planning, budgeting and administration, public relations, human resources management, early childhood growth and development, counseling skills, applicable law and regulations, school safety, policy studies, and professional standards and ethics.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0499',
    cipTitle: 'Educational Administration and Supervision, Other',
    cipDefinition: 'Any instructional program in education administration and supervision not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.05',
    cipTitle: 'Educational/Instructional Media Design',
    cipDefinition: 'Instructional content is defined in code 13.0501.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0501',
    cipTitle: 'Educational/Instructional Technology',
    cipDefinition:
      'A program that focuses on integrating technology into educational curricula. Includes instruction in foundations of educational technology, computer applications, utilizing technology for assessment, multimedia instruction, web-based instruction, distance education, and designing and producing educational software and materials.',
    Examples: 'Examples: - Advanced Learning Technologies',
  },
  {
    cipFamily: '13',
    cipCode: '13.06',
    cipTitle: 'Educational Assessment, Evaluation, and Research',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.0601 - 13.0699.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0601',
    cipTitle: 'Educational Evaluation and Research',
    cipDefinition:
      'A program that focuses on the principles and procedures for generating information about educational programs, personnel and methods, and the analysis of such information for planning purposes.  Includes instruction in evaluation theory, evaluation research design and planning, administering evaluations and related data collection activities, data reporting requirements, data analysis and interpretation, and related economic and policy issues.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0603',
    cipTitle: 'Educational Statistics and Research Methods',
    cipDefinition:
      'A program that focuses on the application of statistics to the analysis and solution of educational research problems, and the development of technical designs for research studies.  Includes instruction in mathematical statistics, research design, computer applications, instrument design, research methodologies, and applications to research problems in specific education subjects.',
    CrossReferences: '27.0501 - Statistics, General., 45.0102 - Research Methodology and Quantitative Methods.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0604',
    cipTitle: 'Educational Assessment, Testing, and Measurement',
    cipDefinition:
      'A program that focuses on the principles and procedures for designing, developing, implementing and evaluating tests and other mechanisms used to measure learning, evaluate student progress, and assess the performance of specific teaching tools, strategies and curricula.  Includes instruction in psychometric measurement, instrument design, test implementation techniques, research evaluation, data reporting requirements, and data analysis and interpretation.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0607',
    cipTitle: 'Learning Sciences',
    cipDefinition:
      'A program that focuses on the multiple aspects of learning in different environments, including specific aspects of the content to be mastered, cognitive aspects of the student, the instructional environment and materials, the preparation and activities of the instructor, socio-cultural and linguistic components, and assessment outcomes. Includes instruction in the social, organizational, and cultural dynamics of learning; learning and cognition; learning strategies; educational psychology; educational testing and measurement; instructional design and technology; and statistical design of educational research.',
    Examples: 'Examples: - Learning Sciences Research, - Learning Sciences and Policy',
  },
  {
    cipFamily: '13',
    cipCode: '13.0608',
    cipTitle: 'Institutional Research',
    cipDefinition:
      'A program of study that prepares an individual to be an institutional researcher at a postsecondary educational institution. Includes instruction in data analysis, data-driven decision-making, data mining, higher education administration and organization, research methods, and statistics.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0699',
    cipTitle: 'Educational Assessment, Evaluation, and Research, Other',
    cipDefinition: 'Any instructional program in educational evaluation, research and statistics not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.07',
    cipTitle: 'International and Comparative Education',
    cipDefinition: 'Instructional content is defined in code 13.0701.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0701',
    cipTitle: 'International and Comparative Education',
    cipDefinition:
      'A program that focuses on the educational phenomena, practices, and institutions within different societies and states in a comparative perspective, and the study of international educational issues. Includes instruction in comparative research methods, country- or area-specific studies, cross-national studies of learning and teaching styles, international educational policy and development, and analyses of educational migration patterns and experiences.',
  },
  {
    cipFamily: '13',
    cipCode: '13.09',
    cipTitle: 'Social and Philosophical Foundations of Education',
    cipDefinition: 'Instructional content is defined in code 13.0901.',
  },
  {
    cipFamily: '13',
    cipCode: '13.0901',
    cipTitle: 'Social and Philosophical Foundations of Education',
    cipDefinition:
      'A program that focuses on the systematic study of education as a social and cultural institution, and the educational process as an object of humanistic inquiry.  Includes instruction in such subjects as the philosophy of education, history of education, educational literature, educational anthropology, sociology of education, economics and politics of education, educational policy studies, and studies of education in relation to specific populations, issues, social phenomena, and types of work.',
  },
  {
    cipFamily: '13',
    cipCode: '13.10',
    cipTitle: 'Special Education and Teaching',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.1001 - 13.1099.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1001',
    cipTitle: 'Special Education and Teaching, General',
    cipDefinition:
      'A general program that focuses on the design and provision of teaching and other educational services to children or adults with special learning needs or disabilities, and that may prepare individuals to function as special education teachers in a collaborative or team environment.  Includes instruction in diagnosing learning disabilities, developing individual education plans, teaching and supervising special education students, special education counseling, and applicable laws and policies.',
    Examples: 'Examples: - Special Education Collaborative Teacher/Teaching',
  },
  {
    cipFamily: '13',
    cipCode: '13.1003',
    cipTitle: 'Education/Teaching of Individuals with Hearing Impairments Including Deafness',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with hearing impairments including deafness which adversely affect their educational performance, and that may prepare individuals to teach such students.  Includes instruction in identifying students with hearing impairments, including deafness, developing individual education plans, teaching and supervising hearing-impaired students, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1004',
    cipTitle: 'Education/Teaching of the Gifted and Talented',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults exhibiting exceptional intellectual, psychomotor or artistic talent or potential, or who exhibit exceptional maturity or social leadership talents, and that may prepare individuals to teach such students.  Includes instruction in identifying gifted and talented students, developing individual education plans, teaching and supervising gifted and talented students, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1005',
    cipTitle: 'Education/Teaching of Individuals with Emotional Disturbances',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with emotional conditions which adversely affect their educational performance and that may prepare individuals to teach such students.  Includes instruction in identifying students with emotional disturbances, developing individual education plans, teaching and supervising individuals with emotional disturbances, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1006',
    cipTitle: 'Education/Teaching of Individuals with Intellectual Disabilities',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with intellectual disabilities which adversely affect their educational performance and that may prepare individuals to teach such students.  Includes instruction in identifying students with intellectual disabilities, developing individual education plans, teaching and supervising mentally handicapped students, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1007',
    cipTitle: 'Education/Teaching of Individuals with Multiple Disabilities',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with multiple disabilities which adversely affect their educational performance and that may prepare individuals to teach such students.  Includes instruction in identifying students with multiple disabilities, developing individual education plans, teaching and supervising students with multiple handicaps, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1008',
    cipTitle: 'Education/Teaching of Individuals with Orthopedic and Other Physical Health Impairments',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with orthopedic and other health impairments which adversely affect their educational performance and that may prepare individuals to teach such students.  Includes instruction in identifying physically disabled students, developing individual education plans, teaching and supervising students with orthopedic and other health impairments, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1009',
    cipTitle: 'Education/Teaching of Individuals with Vision Impairments Including Blindness',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with visual disabilities which adversely affect their educational performance and that may prepare individuals to teach such students.  Includes instruction in identifying students with visual impairments, developing individual education plans, teaching and supervising blind or visually handicapped students, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1011',
    cipTitle: 'Education/Teaching of Individuals with Specific Learning Disabilities',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with specific learning disabilities which adversely affect their educational performance and that may prepare individuals to teach such students.  Includes instruction in identifying students with specific learning disabilities, developing individual education plans, teaching and supervising students with specific learning disabilities, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1012',
    cipTitle: 'Education/Teaching of Individuals with Speech or Language Impairments',
    cipDefinition:
      'A program that focuses on the study and design of educational services for children or adults with speech and language impairments which adversely affect their educational performance and that may prepare individuals to teach such students.  Includes instruction in identifying students with speech and language impairments, developing individual education plans, teaching and supervising students with speech disabilities, counseling, and applicable laws and policies.',
    CrossReferences: '51.0816 - Speech-Language Pathology Assistant.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1013',
    cipTitle: 'Education/Teaching of Individuals with Autism',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults with autism, and that prepares individuals to teach such students.  Includes instruction in identifying students with autism, developing individual education plans, teaching and supervising autistic students, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1014',
    cipTitle: 'Education/Teaching of Individuals Who are Developmentally Delayed',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults who exhibit slow social, physical, cognitive, or emotional growth patterns related to age, or a combination of such factors that affect learning, and that prepares individuals to teach such students.  Includes instruction in identification of developmental delay patterns, developing individual education plans, teaching and supervising developmentally delayed students, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1015',
    cipTitle: 'Education/Teaching of Individuals in Early Childhood Special Education Programs',
    cipDefinition:
      'A program that focuses on the design and provision of teaching and other educational services to infant and pre-school age children with special learning needs or disabilities, and that prepares individuals to teach such students.  Includes instruction in diagnosing learning disabilities, developing individual education plans, teaching and supervising special education students, special education counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1016',
    cipTitle: 'Education/Teaching of Individuals with Traumatic Brain Injuries',
    cipDefinition:
      'A program that focuses on the design of educational services for children or adults who are surviving and recovering from brain and brain stem injuries affecting their cognitive, perceptive, and motor functions.  Includes instruction in  neurophysiology and neuropathology; speech, other sensory, and motor pathologies; emotional and cognitive disabilities; physical and occupational rehabilitation techniques; developing individual education plans, teaching and supervising brain-injured students, counseling, and applicable laws and policies.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1017',
    cipTitle: 'Education/Teaching of Individuals in Elementary Special Education Programs',
    cipDefinition:
      'A program that focuses on the design and provision of teaching and other educational services to children with special learning needs or disabilities who are in the elementary grades. Includes instruction in diagnosing learning disabilities; developing individual education plans; curriculum development, instructional strategies, and classroom management for elementary special education; and teaching elementary education subject matter.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1018',
    cipTitle: 'Education/Teaching of Individuals in Junior High/Middle School Special Education Programs',
    cipDefinition:
      'A program that focuses on the design and provision of teaching and other educational services to children with special learning needs or disabilities who are in junior high/middle school. Includes instruction in diagnosing learning disabilities; developing individual education plans; curriculum development, instructional strategies, and classroom management for middle school special education; and principles of middle school instruction in specific subjects.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1019',
    cipTitle: 'Education/Teaching of Individuals in Secondary Special Education Programs',
    cipDefinition:
      'A program that focuses on the design and provision of teaching and other educational services to children with special learning needs or disabilities who are in the secondary grades. Includes instruction in diagnosing learning disabilities; developing individual education plans; curriculum development, instructional strategies, and classroom management for secondary grades special education; content, methods, and modifications in specific subject areas; issues in vocational education; and promoting successful transitions to postsecondary settings.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1099',
    cipTitle: 'Special Education and Teaching, Other',
    cipDefinition: 'Any instructional program in special education not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.11',
    cipTitle: 'Student Counseling and Personnel Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.1101 - 13.1199.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1101',
    cipTitle: 'Counselor Education/School Counseling and Guidance Services',
    cipDefinition:
      'A program that prepares individuals to apply the theory and principles of guidance and counseling to the provision of support for the personal, social, educational, and vocational development of students, and the organizing of guidance services within elementary, middle and secondary educational institutions.  Includes instruction in legal and professional requirements, therapeutic counselor intervention, vocational counseling, and related sociological and psychological foundations.',
    CrossReferences: '42.2805 - School Psychology.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1102',
    cipTitle: 'College Student Counseling and Personnel Services',
    cipDefinition:
      'A program that focuses on the organization and provision of counseling, referral, assistance and administrative services to students in postsecondary educational institutions and adult education facilities, and that may prepare individuals to function as professional counselors in such settings.  Includes instruction in applicable laws and policies, residential counseling and services, vocational counseling and placement services, remedial skills counseling, and therapeutic counselor intervention.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1199',
    cipTitle: 'Student Counseling and Personnel Services, Other',
    cipDefinition: 'Any instructional program in student counseling and personnel services not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.12',
    cipTitle: 'Teacher Education and Professional Development, Specific Levels and Methods',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.1201 - 13.1299.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1201',
    cipTitle: 'Adult and Continuing Education and Teaching',
    cipDefinition:
      'A program that prepares individuals to teach adult students in various settings, including basic and remedial education programs, continuing education programs, and programs designed to develop or upgrade specific employment-related knowledge and skills.',
    Examples: 'Examples: - Workforce Training and Development Instruction',
  },
  {
    cipFamily: '13',
    cipCode: '13.1202',
    cipTitle: 'Elementary Education and Teaching',
    cipDefinition:
      'A program that prepares individuals to teach students in the elementary grades, which may include kindergarten through grade eight, depending on the school system or state regulations.  Includes preparation to teach all elementary education subject matter.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1203',
    cipTitle: 'Junior High/Intermediate/Middle School Education and Teaching',
    cipDefinition:
      'A program that prepares individuals to teach students in the middle, intermediate or junior high grades, which may include grades four through nine by regulation.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1205',
    cipTitle: 'Secondary Education and Teaching',
    cipDefinition:
      'A program that prepares individuals to teach students in the secondary grades, which may include grades seven through twelve, depending on the school system or state regulations.  May include preparation to teach a comprehensive curriculum or specific subject matter.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1206',
    cipTitle: 'Teacher Education, Multiple Levels',
    cipDefinition:
      'A program that prepares individuals to teach students at more than one educational level, such as a combined program in elementary/secondary, early childhood/elementary, elementary/middle school, or junior high/high school teacher education.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1207',
    cipTitle: 'Montessori Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach students at various grade levels according to the pedagogical principles and methods developed by Maria Montessori and her followers.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1208',
    cipTitle: 'Waldorf/Steiner Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach students at various grade levels according to the pedagogical principles and methods developed by Rudolf Steiner and his followers.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1209',
    cipTitle: 'Kindergarten/Preschool Education and Teaching',
    cipDefinition:
      'A program that prepares individuals to teach students in formal settings prior to beginning regular elementary school, usually ranging in age from three to six years (or grade one), depending on the school system or state regulations.  Includes preparation to teach all relevant subject matter.',
  },
  {
    cipFamily: '13',
    cipCode: '13.121',
    cipTitle: 'Early Childhood Education and Teaching',
    cipDefinition:
      'A program that prepares individuals to teach students ranging in age from infancy through eight years (grade three), depending on the school system or state regulations.  Includes preparation to teach all relevant subject matter.',
    CrossReferences: '13.1501 - Teacher Assistant/Aide.',
    Examples:
      'Examples: - Early Childhood Education (AA), - Early Childhood Education (BA), - Early Childhood Education (MA), - Early Childhood Education (PhD)',
  },
  {
    cipFamily: '13',
    cipCode: '13.1211',
    cipTitle: 'Online Educator/Online Teaching',
    cipDefinition:
      'A program that prepares individuals to teach students at various academic levels through online instructional technologies. Includes instruction in andragogy, pedagogy, assessment, instructional design and technology, and learning science.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1212',
    cipTitle: 'International Teaching and Learning',
    cipDefinition:
      'A program that prepares individuals to teach in schools that are implementing or proposing to implement the International Baccalaureate curriculum. Includes instruction in culturally-responsive classrooms, cross-cultural awareness, foundations of international education, human learning, human development, international teaching and learning, and pedagogy.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1213',
    cipTitle: 'Science, Technology, Engineering, and Mathematics (STEM) Educational Methods',
    cipDefinition:
      'A program that prepares individuals to connect education practices with scientific, engineering, and mathematical principles for PreK-12 students. Includes instruction in critical thinking, curriculum and instruction, history of STEM education, integrating STEM across curricula, learning sciences and technology, STEM education methods, and teacher leadership in STEM education.',
    Examples: 'Examples: - Science, Technology, Engineering, the Arts, and Mathematics (STEAM) Educational Methods',
  },
  {
    cipFamily: '13',
    cipCode: '13.1214',
    cipTitle: 'College/Postsecondary/University Teaching',
    cipDefinition:
      'A program that prepares individuals to teach at a postsecondary institution. Includes instruction in andragogy, assessment, classroom motivation, instructional design and technology, learner-centered teaching, learning science, syllabus construction, and teaching critical thinking.',
    Examples: 'Examples: - University and College Teaching',
  },
  {
    cipFamily: '13',
    cipCode: '13.1299',
    cipTitle: 'Teacher Education and Professional Development, Specific Levels and Methods, Other',
    cipDefinition: 'Any instructional program in teacher education and professional development not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.13',
    cipTitle: 'Teacher Education and Professional Development, Specific Subject Areas',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.1301 - 13.1399.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1301',
    cipTitle: 'Agricultural Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach vocational agricultural programs at various educational levels.',
    CrossReferences: '1.0801 - Agricultural and Extension Education Services.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1302',
    cipTitle: 'Art Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach art and art appreciation programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1303',
    cipTitle: 'Business and Innovation/Entrepreneurship Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach vocational business, innovation, and entrepreneurship programs at various educational levels. Includes instruction in business administration, business development, finance, idea generation, investing, logistics, management, and marketing.',
    Examples: 'Examples: - Innovation and Entrepreneurship Teacher Education',
  },
  {
    cipFamily: '13',
    cipCode: '13.1304',
    cipTitle: 'Driver and Safety Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach driver and safety education programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1305',
    cipTitle: 'English/Language Arts Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach English grammar, composition and literature programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1306',
    cipTitle: 'Foreign Language Teacher  Education',
    cipDefinition:
      'A program that prepares individuals to teach foreign languages programs at various educational levels, other than French, German or Spanish.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1307',
    cipTitle: 'Health Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach health education programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1308',
    cipTitle: 'Family and Consumer Sciences/Home Economics Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach vocational home economics programs at various educational levels.',
    CrossReferences: '19.0101 - Family and Consumer Sciences/Human Sciences, General.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1309',
    cipTitle: 'Technology Teacher Education/Industrial Arts Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach technology education/industrial arts programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.131',
    cipTitle: 'Sales and Marketing Operations/Marketing and Distribution   Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach vocational sales and marketing operations/marketing and distributive education programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1311',
    cipTitle: 'Mathematics Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach mathematics programs at various educational levels.',
    Examples:
      'Examples: - Elementary Mathematics Teacher Education, - Middle School Mathematics Teacher Education, - Secondary Mathematics Teacher Education',
  },
  {
    cipFamily: '13',
    cipCode: '13.1312',
    cipTitle: 'Music Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach music and music appreciation programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1314',
    cipTitle: 'Physical Education Teaching and Coaching',
    cipDefinition:
      'A program that prepares individuals to teach physical education programs and/or to coach sports at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1315',
    cipTitle: 'Reading Teacher Education',
    cipDefinition:
      'A program that prepares individuals to diagnose reading difficulties and to teach reading programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1316',
    cipTitle: 'Science Teacher Education/General Science Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach general science programs, or a combination of the biological and physical science subject matter areas, at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1317',
    cipTitle: 'Social Science Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach specific social science subjects and programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1318',
    cipTitle: 'Social Studies Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach general social studies programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1319',
    cipTitle: 'Technical Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach specific vocational technical education programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.132',
    cipTitle: 'Trade and Industrial Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach specific vocational trades and industries programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1321',
    cipTitle: 'Computer Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach computer education programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1322',
    cipTitle: 'Biology Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach biology programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1323',
    cipTitle: 'Chemistry Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach chemistry programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1324',
    cipTitle: 'Drama and Dance Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach drama and/or dance programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1325',
    cipTitle: 'French Language Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach French language programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1326',
    cipTitle: 'German Language Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach German language programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1327',
    cipTitle: 'Health Occupations Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach specific vocational health occupations programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1328',
    cipTitle: 'History Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach history programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1329',
    cipTitle: 'Physics Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach physics programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.133',
    cipTitle: 'Spanish Language Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach Spanish language programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1331',
    cipTitle: 'Speech Teacher Education',
    cipDefinition:
      'A program that prepares individuals to teach speech and language arts programs at various educational levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1332',
    cipTitle: 'Geography Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach geography at various grade levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1333',
    cipTitle: 'Latin Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach Latin at various grade levels.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1334',
    cipTitle: 'School Librarian/School Library Media Specialist',
    cipDefinition:
      'A program that prepares individuals to serve as librarians and media specialists in elementary and secondary schools as well as special instructional centers.',
    CrossReferences: '25.0101 - Library and Information Science.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1335',
    cipTitle: 'Psychology Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach general psychology at the secondary school level.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1337',
    cipTitle: 'Earth Science Teacher Education',
    cipDefinition: 'A program that prepares individuals to teach earth science programs at various educational levels.',
    Examples: 'Examples: - Geosciences Education',
  },
  {
    cipFamily: '13',
    cipCode: '13.1338',
    cipTitle: 'Environmental Education',
    cipDefinition:
      'A program that prepares individuals to teach environmental education at various educational levels as a K-12 classroom educator. Includes instruction in foundations of environmental education, instructional methods, and related content knowledge.',
    CrossReferences: '31.0601 - Outdoor Education.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1339',
    cipTitle: 'Communication Arts and Literature Teacher Education',
    cipDefinition:
      'A program of study that prepares individuals to teach oral expression, literature, and writing to students at various education levels. Includes instruction in interpersonal communication, intercultural communication, language learning, listening instructional techniques, public speaking, reading instructional techniques, writing instructional techniques, and practical teaching experience.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1399',
    cipTitle: 'Teacher Education and Professional Development, Specific Subject Areas, Other',
    cipDefinition:
      'Any instructional program in teacher education, specific academic and vocational programs not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.14',
    cipTitle: 'Teaching English or French as a Second or Foreign Language',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.1401 - 13.1499.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1401',
    cipTitle: 'Teaching English as a Second or Foreign Language/ESL Language Instructor',
    cipDefinition:
      'A program that focuses on the principles and practice of teaching English to students who are not proficient in English or who do not speak, read or write English, and that may prepare individuals to function as teachers and administrators in such programs.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1402',
    cipTitle: 'Teaching French as a Second or Foreign Language',
    cipDefinition:
      'A program that focuses on the principles and practice of teaching French to students who are not proficient in French or who do not speak, read, or write French, and prepares individuals to serve as teachers and administrators.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1499',
    cipTitle: 'Teaching English or French as a Second or Foreign Language, Other',
    cipDefinition: 'Any program in teaching English or French as a second or foreign language not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.15',
    cipTitle: 'Teaching Assistants/Aides',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 13.1501 - 13.1599.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1501',
    cipTitle: 'Teacher Assistant/Aide',
    cipDefinition:
      'A program that prepares individuals to assist a teacher in regular classroom settings or in providing instruction and supervision to special student populations, such as bilingual/bicultural students, special education students, adult learners, and students learning English.  Includes instruction in techniques of general classroom supervision, maintaining order, assisting with lessons and carrying out related assignments.',
    CrossReferences: '13.1210 - Early Childhood Education and Teaching.',
    Examples:
      'Examples: - Early Childhood Education Teacher Assistant/Aide, - Elementary Education Teacher Assistant/Aide, - Special Education Teacher Assistant/Aide, - Paraprofessional Educator',
  },
  {
    cipFamily: '13',
    cipCode: '13.1502',
    cipTitle: 'Adult Literacy Tutor/Instructor',
    cipDefinition:
      'A program that prepares individuals to serve as instructors and mentors for adults in basic or functional literacy programs in school, institutional, community, and private settings.',
  },
  {
    cipFamily: '13',
    cipCode: '13.1599',
    cipTitle: 'Teaching Assistants/Aides, Other',
    cipDefinition: 'Any teaching assistant/aide program not listed above.',
  },
  {
    cipFamily: '13',
    cipCode: '13.99',
    cipTitle: 'Education, Other',
    cipDefinition: 'Instructional content is defined in code 13.9999.',
  },
  {
    cipFamily: '13',
    cipCode: '13.9999',
    cipTitle: 'Education, Other',
    cipDefinition: 'Any instructional program in education not listed above.',
  },
  {
    cipFamily: '14',
    cipCode: '14',
    cipTitle: 'Engineering',
    cipDefinition:
      'Instructional programs that prepare individuals to apply mathematical and     scientific principles to the solution of practical problems.',
  },
  {
    cipFamily: '14',
    cipCode: '14.01',
    cipTitle: 'Engineering, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 14.0101 - 14.0103.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0101',
    cipTitle: 'Engineering, General',
    cipDefinition:
      'A program that generally prepares individuals to apply mathematical and scientific principles to solve a wide variety of practical problems in industry, social organization, public works, and commerce.  Includes instruction in undifferentiated and individualized programs in engineering.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0102',
    cipTitle: 'Pre-Engineering',
    cipDefinition:
      'A program that prepares individuals for admission or transfer to a baccalaureate-level program in any of the fields of engineering.',
    Examples: 'Examples: - Pre-Engineering Transfer Program',
  },
  {
    cipFamily: '14',
    cipCode: '14.0103',
    cipTitle: 'Applied Engineering',
    cipDefinition:
      'A program that generally prepares individuals to apply mathematical and scientific principles inherent to engineering to the management and design of systems, execution of new product designs, improvement of manufacturing processes, and the management and direction of the physical or technical functions of an organization. Includes instruction in basic engineering principles, project management, industrial processes, production and operations management, systems integration and control, quality control, and statistics.',
  },
  {
    cipFamily: '14',
    cipCode: '14.02',
    cipTitle: 'Aerospace, Aeronautical, and Astronautical/Space Engineering',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 14.0201 - 14.0299.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0201',
    cipTitle: 'Aerospace, Aeronautical, and Astronautical/Space Engineering, General',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of aircraft, missiles, space vehicles, and their systems; applied research on flight and orbital characteristics; and the development of systems and procedures for the launching, guidance, and control of air and space vehicles.',
    Examples:
      'Examples: - Space Systems Engineering, - Space Facilities Engineering, - Missile Systems Engineering, - Air-Breathing Propulsion, - Aerospace Navigation, - Rocket Propulsion',
  },
  {
    cipFamily: '14',
    cipCode: '14.0202',
    cipTitle: 'Astronautical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development, and operational evaluation of aircraft and space vehicles. Includes instruction in control systems, fluid dynamics, electrical circuits systems, orbital mechanics, propulsion, remote sensing, satellites, spacecraft centers, spacecraft systems engineering, and space exploration.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0299',
    cipTitle: 'Aerospace, Aeronautical, and Astronautical/Space Engineering, Other',
    cipDefinition: 'Any program in aerospace, aeronautical, astronautical, or space engineering not listed above.',
  },
  {
    cipFamily: '14',
    cipCode: '14.03',
    cipTitle: 'Agricultural Engineering',
    cipDefinition: 'Instructional content is defined in code 14.0301.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0301',
    cipTitle: 'Agricultural Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems, equipment and facilities for production, processing, storage, handling, distribution and use of food, feed, and fiber. Includes applications to aquaculture, forestry, human and natural resources.',
    Examples: 'Examples: - Bioprocessing Engineering, - Food Engineering, - Soil Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.04',
    cipTitle: 'Architectural Engineering',
    cipDefinition: 'Instructional content is defined in code 14.0401.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0401',
    cipTitle: 'Architectural Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of materials, systems, and methods used to construct and equip buildings intended for human habitation or other purposes.',
    CrossReferences: '4.0201 - Architecture.',
  },
  {
    cipFamily: '14',
    cipCode: '14.05',
    cipTitle: 'Biomedical/Medical Engineering',
    cipDefinition: 'Instructional content is defined in code 14.0501.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0501',
    cipTitle: 'Bioengineering and Biomedical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of biomedical and health systems and products such as integrated biomedical systems, instrumentation, medical information systems, artificial organs and prostheses, and health management and care delivery systems.',
    CrossReferences: '31.0505 - Exercise Science and Kinesiology., 26.0913 - Biomechanics.',
    Examples: 'Examples: - Cell and Tissue Engineering, - Neural Engineering, - Biomaterials Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.06',
    cipTitle: 'Ceramic Sciences and Engineering',
    cipDefinition: 'Instructional content is defined in code 14.0601.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0601',
    cipTitle: 'Ceramic Sciences and Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of inorganic non-metallic materials, such as porcelains, cements, industrial ceramics, ceramic superconductors, abrasive, and related materials and systems.',
    CrossReferences: '50.0711 - Ceramic Arts and Ceramics.',
  },
  {
    cipFamily: '14',
    cipCode: '14.07',
    cipTitle: 'Chemical Engineering',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 14.0701 - 14.0799.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0701',
    cipTitle: 'Chemical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems employing chemical processes, such as chemical reactors, kinetic systems, electrochemical systems, energy conservation processes, heat and mass transfer systems, and separation processes; and the applied analysis of chemical problems such as corrosion, particle abrasion, energy loss, pollution, and fluid mechanics.',
    CrossReferences: '40.0501 - Chemistry, General.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0702',
    cipTitle: 'Chemical and Biomolecular Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems at the interface of chemical engineering and biology, with an emphasis at the molecular level, such as biopharmaceutical processes, protein engineering, metabolic engineering, gene therapy, biomaterials, cell and tissue engineering, and drug delivery. Includes instruction in chemical engineering, thermodynamics, organic chemistry, biochemistry, momentum and heat transfer, cellular and molecular biotechnology, process design, and chemical reactor design.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0799',
    cipTitle: 'Chemical Engineering, Other',
    cipDefinition: 'Any instructional program in chemical engineering not listed above.',
  },
  {
    cipFamily: '14',
    cipCode: '14.08',
    cipTitle: 'Civil Engineering',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 14.0801 - 14.0899.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0801',
    cipTitle: 'Civil Engineering, General',
    cipDefinition:
      'A program that generally prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of structural, load-bearing, material moving, transportation, water resource, and material control systems; and environmental safety measures.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0802',
    cipTitle: 'Geotechnical and Geoenvironmental Engineering',
    cipDefinition:
      'A program that prepares individuals to apply geotechnical engineering methods, which deal with the analysis, design and construction of earth and earth supported structures,  to the application of environmental problems, such as waste containment, waste disposal, construction of land fills, soil permeation, soil analysis, and soil improvement. Includes instruction in soil mechanics, soil dynamics, soil behavior, waste management and containment systems, geosynthetics, geochemistry, earth structures, geoenvironmental engineering, geotechnical engineering, earthquake engineering, and foundation engineering',
    Examples:
      'Examples: - Geotechnical Engineering, - Geoenvironmental Engineering, - Geotechnical and Geoenvironmental Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.0803',
    cipTitle: 'Structural Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of materials and systems used in building load-bearing structures for various purposes and in different environments, including buildings, roads, rail lines, bridges, dams, conduits, offshore platforms and work stations, and other structural shells; and the analysis of structural problems such as, failure, fabrication, safety, and natural hazards.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0804',
    cipTitle: 'Transportation and Highway Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of total systems for the physical movement of people, materials and information, including general network design and planning, facilities planning, site evaluation, transportation management systems, needs projections and analysis, and analysis of costs.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0805',
    cipTitle: 'Water Resources Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems for collecting, storing, moving, conserving and controlling surface- and groundwater, including water quality control, water cycle management, management of human and industrial water requirements, water delivery, and flood control.',
    CrossReferences:
      '40.0605 - Hydrology and Water Resources Science., 3.0205 - Water, Wetlands, and Marine Resources Management.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0899',
    cipTitle: 'Civil Engineering, Other',
    cipDefinition: 'Any instructional program in civil engineering not listed above.',
  },
  {
    cipFamily: '14',
    cipCode: '14.09',
    cipTitle: 'Computer Engineering',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 14.0901 - 14.0999.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0901',
    cipTitle: 'Computer Engineering, General',
    cipDefinition:
      'A program that generally prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of computer hardware and software systems and related equipment and facilities; and the analysis of specific problems of computer applications to various tasks.',
    CrossReferences: '11.0701 - Computer Science.',
    Examples: 'Examples: - Computer Systems Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.0902',
    cipTitle: 'Computer Hardware Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development, and evaluation of computer hardware and related peripheral equipment.  Includes instruction in computer circuit and chip design, circuitry, computer systems design, computer equipment design, computer layout planning, testing procedures, and related computer theory and software topics.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0903',
    cipTitle: 'Computer Software Engineering',
    cipDefinition:
      'A program that prepares individuals to apply scientific and mathematical principles to the design, analysis, verification, validation, implementation, and maintenance of computer software systems using a variety of computer languages.  Includes instruction in discrete mathematics, probability and statistics, computer science, managerial science, and applications to complex computer systems.',
  },
  {
    cipFamily: '14',
    cipCode: '14.0999',
    cipTitle: 'Computer Engineering, Other',
    cipDefinition: 'Any instructional program in computer engineering not listed above.',
    Examples: 'Examples: - Internet of Things',
  },
  {
    cipFamily: '14',
    cipCode: '14.10',
    cipTitle: 'Electrical, Electronics, and Communications Engineering',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 14.1001 - 14.1099.',
  },
  {
    cipFamily: '14',
    cipCode: '14.1001',
    cipTitle: 'Electrical and Electronics Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of electrical and electronic systems and their components, including electrical power generation systems; and the analysis of problems such as superconductor, wave propagation, energy storage and retrieval, and reception and amplification.',
    Examples: 'Examples: - Electrical Engineering, - Total Ship Systems Engineering, - Electrodynamic Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.1003',
    cipTitle: 'Laser and Optical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of optical systems, lasers and related electronic devices.  Includes instruction in wave theory and mechanics, electromagnetic applications, linear and non-linear optics, photon detecting, laser beam properties, directed energy, harmonic generation, optical systems, shielding and the design and implementation of related systems and equipment.',
    CrossReferences: '40.0807 - Optics/Optical Sciences.',
    Examples: 'Examples: - Electro-Optic Engineering, - Optical Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.1004',
    cipTitle: 'Telecommunications Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development, and maintenance of telecommunications technology, networks, and systems. Includes instruction in telecommunications, computer networking, communications networks and systems, signals, circuits, fiber optics, and wireless systems and technology.',
    Examples: 'Examples: - Communications Systems Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.1099',
    cipTitle: 'Electrical, Electronics, and Communications Engineering, Other',
    cipDefinition:
      'Any instructional program in electrical, electronics, and communications engineering not listed above.',
  },
  {
    cipFamily: '14',
    cipCode: '14.11',
    cipTitle: 'Engineering Mechanics',
    cipDefinition: 'Instructional content is defined in code 14.1101.',
  },
  {
    cipFamily: '14',
    cipCode: '14.1101',
    cipTitle: 'Engineering Mechanics',
    cipDefinition:
      'A program with a general focus on the application of the mathematical and scientific principles of classical mechanics to the analysis and evaluation of the behavior of structures, forces and materials in engineering problems.  Includes instruction in statics, kinetics, dynamics, kinematics, celestial mechanics, stress and failure, and electromagnetism.',
  },
  {
    cipFamily: '14',
    cipCode: '14.12',
    cipTitle: 'Engineering Physics',
    cipDefinition: 'Instructional content is defined in code 14.1201.',
  },
  {
    cipFamily: '14',
    cipCode: '14.1201',
    cipTitle: 'Engineering Physics/Applied Physics',
    cipDefinition:
      'A program focusing on the use of physics principles in the analysis and evaluation of engineering problems and other scientific applications. Includes instruction in high- and low-temperature phenomena, computational physics, superconductivity, applied thermodynamics, molecular and particle physics applications, and space science research.',
    CrossReferences: '40.0801 - Physics, General.',
    Examples: 'Examples: - Applied Physics, - Directed Energy/Laser Physics, - Semiconductor Physics',
  },
  {
    cipFamily: '14',
    cipCode: '14.13',
    cipTitle: 'Engineering Science',
    cipDefinition: 'Instructional content is defined in code 14.1301.',
  },
  {
    cipFamily: '14',
    cipCode: '14.1301',
    cipTitle: 'Engineering Science',
    cipDefinition:
      'A program with a general focus on the general application of various combinations of mathematical and scientific principles to the analysis and evaluation of engineering problems, including applied research in human behavior, statistics, biology, chemistry, the earth and planetary sciences, atmospherics and meteorology, and computer applications.',
  },
  {
    cipFamily: '14',
    cipCode: '14.14',
    cipTitle: 'Environmental/Environmental Health Engineering',
    cipDefinition: 'Instructional content is defined in code 14.1401.',
  },
  {
    cipFamily: '14',
    cipCode: '14.1401',
    cipTitle: 'Environmental/Environmental Health Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems for controlling contained living environments and for monitoring and controlling factors in the external natural environment, including pollution control, waste and hazardous material disposal, health and safety protection, conservation, life support, and requirements for protection of special materials and related work environments.',
    CrossReferences: '3.0103 - Environmental Studies., 3.0104 - Environmental Science.',
    Examples: 'Examples: - Environmental Engineering, - Environmental Management Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.18',
    cipTitle: 'Materials Engineering',
    cipDefinition: 'Instructional content is defined in code 14.1801.',
  },
  {
    cipFamily: '14',
    cipCode: '14.1801',
    cipTitle: 'Materials Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and materials science principles to the design, development and operational evaluation of materials and related processes used in manufacturing in a wide variety of settings; the synthesis of new industrial materials, including marrying and bonding composites; analysis of materials requirements and specifications; and related problems of system design dependent on materials factors.',
    CrossReferences: '40.1001 - Materials Science., 40.1002 - Materials Chemistry.',
    Examples: 'Examples: - Materials Processing and Manufacturing, - Materials Science Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.19',
    cipTitle: 'Mechanical Engineering',
    cipDefinition: 'Instructional content is defined in code 14.1901.',
  },
  {
    cipFamily: '14',
    cipCode: '14.1901',
    cipTitle: 'Mechanical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of physical systems used in manufacturing and end-product systems used for specific uses, including machine tools, jigs and other manufacturing equipment; stationary power units and appliances; engines; self-propelled vehicles; housings and containers; hydraulic and electric systems for controlling movement; and the integration of computers and remote control with operating systems.',
  },
  {
    cipFamily: '14',
    cipCode: '14.20',
    cipTitle: 'Metallurgical Engineering',
    cipDefinition: 'Instructional content is defined in code 14.2001.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2001',
    cipTitle: 'Metallurgical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and metallurgical principles to the design, development and operational evaluation of metal components of structural, load-bearing, power, transmission, and moving systems; and the analysis of engineering problems such as stress, creep, failure, alloy behavior, environmental fluctuations, stability, electromagnetic and thermodynamic characteristics, optimal manufacturing processes, and related design considerations.',
  },
  {
    cipFamily: '14',
    cipCode: '14.21',
    cipTitle: 'Mining and Mineral Engineering',
    cipDefinition: 'Instructional content is defined in code 14.2101.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2101',
    cipTitle: 'Mining and Mineral Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of mineral extraction, processing and refining systems, including open pit and shaft mines, prospecting and site analysis equipment and instruments, environmental and safety systems, mine equipment and facilities, mineral processing and refining methods and systems, and logistics and communications systems.',
  },
  {
    cipFamily: '14',
    cipCode: '14.22',
    cipTitle: 'Naval Architecture and Marine Engineering',
    cipDefinition: 'Instructional content is defined in code 14.2201.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2201',
    cipTitle: 'Naval Architecture and Marine Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of self-propelled, stationary, or towed vessels operating on or under the water, including inland, coastal and ocean environments; and the analysis of related engineering problems such as corrosion, power transfer, pressure, hull efficiency, stress factors, safety and life support, environmental hazards and factors, and specific use requirements.',
  },
  {
    cipFamily: '14',
    cipCode: '14.23',
    cipTitle: 'Nuclear Engineering',
    cipDefinition: 'Instructional content is defined in code 14.2301.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2301',
    cipTitle: 'Nuclear Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems for controlling and manipulating nuclear energy, including nuclear power plant design, fission reactor design, fusion reactor design, reactor control and safety systems design, power transfer systems, containment vessels and structures design; and the analysis of related engineering problems such as fission and fusion processes, human and environmental factors, construction, and operational considerations.',
    CrossReferences: '40.0806 - Nuclear Physics.',
    Examples:
      'Examples: - Nuclear Reactor Engineering, - Nuclear Fuels, - Nuclear Power Engineering, - Nuclear Radiation and Shielding, - Nuclear Instrumentation and Measurement',
  },
  {
    cipFamily: '14',
    cipCode: '14.24',
    cipTitle: 'Ocean Engineering',
    cipDefinition: 'Instructional content is defined in code 14.2401.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2401',
    cipTitle: 'Ocean Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems to monitor, control, manipulate and operate within coastal or ocean environments, such as underwater platforms, flood control systems, dikes, hydroelectric power systems, tide and current control and warning systems, and communications equipment; the planning and design of total systems for working and functioning in water or underwater environments; and the analysis of related engineering problems such as the action of water properties and behavior on physical systems and people, tidal forces, current movements, and wave motion.',
  },
  {
    cipFamily: '14',
    cipCode: '14.25',
    cipTitle: 'Petroleum Engineering',
    cipDefinition: 'Instructional content is defined in code 14.2501.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2501',
    cipTitle: 'Petroleum Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems for locating, extracting, processing and refining crude petroleum and natural gas, including prospecting instruments and equipment, mining and drilling systems, processing and refining systems and facilities, storage facilities, transportation systems, and related environmental and safety systems.',
  },
  {
    cipFamily: '14',
    cipCode: '14.27',
    cipTitle: 'Systems Engineering',
    cipDefinition: 'Instructional content is defined in code 14.2701.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2701',
    cipTitle: 'Systems Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of total systems solutions to a wide variety of engineering problems, including the integration of human, physical, energy, communications, management, and information requirements as needed, and the application of requisite analytical methods to specific situations.',
    CrossReferences: '11.0501 - Computer Systems Analysis/Analyst., 30.0601 - Systems Science and Theory.',
    Examples:
      'Examples: - Product Development Engineering, - Logistics Engineering, - Systems Engineering Management, - Capability-Based Systems, - Decision Engineering, - Dynamical Systems',
  },
  {
    cipFamily: '14',
    cipCode: '14.28',
    cipTitle: 'Textile Sciences and Engineering',
    cipDefinition:
      'Instructional content is defined in code 14.2801. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '14',
    cipCode: '14.2801',
    cipTitle: 'Textile Sciences and Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of systems to test and manufacture fibers and fiber products, both synthetic and natural; to develop new and improved fibers, textiles and their uses; and to the analysis of related engineering problems such as structural factors, molecular synthesis, chemical manufacturing, weaves, strength and stress, useful life, dyes, and applications to composite systems.',
    CrossReferences: '19.0904 - Textile Science.',
  },
  {
    cipFamily: '14',
    cipCode: '14.32',
    cipTitle: 'Polymer/Plastics Engineering',
    cipDefinition: 'Instructional content is defined in code 14.3201.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3201',
    cipTitle: 'Polymer/Plastics Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of synthesized macromolecular compounds and their application to specific engineering uses, including the development of industrial materials with tailored properties, the design of lightweight structural components, the use of liquid or solid polymers, and the analysis and control of polymerization processes.',
    CrossReferences: '40.0507 - Polymer Chemistry.',
  },
  {
    cipFamily: '14',
    cipCode: '14.33',
    cipTitle: 'Construction Engineering',
    cipDefinition: 'Instructional content is defined in code 14.3301.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3301',
    cipTitle: 'Construction Engineering',
    cipDefinition:
      'A program that prepares individuals to apply scientific, mathematical, and management principles to the planning, design, and building of facilities and structures.  Includes instruction in civil engineering, structural principles, site analysis, computer-assisted design, geology, evaluation and testing, materials, contracting, project management, graphic communications, and applicable laws and regulations.',
    Examples: 'Examples: - Construction Engineering and Management',
  },
  {
    cipFamily: '14',
    cipCode: '14.34',
    cipTitle: 'Forest Engineering',
    cipDefinition: 'Instructional content is defined in code 14.3401.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3401',
    cipTitle: 'Forest Engineering',
    cipDefinition:
      'A program that prepares individuals to apply scientific, mathematical, and forestry principles to the design of mechanical devices and processes for efficient forest management, timber production and related forest logistics systems.  Includes instruction in forest products processing, forest management, forest harvesting, timber structure design, production analysis, road and bridge construction, vehicle adaptation and design, and harvesting equipment design.',
    CrossReferences: '3.0501 - Forestry, General.',
  },
  {
    cipFamily: '14',
    cipCode: '14.35',
    cipTitle: 'Industrial Engineering',
    cipDefinition: 'Instructional content is defined in code 14.3501.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3501',
    cipTitle: 'Industrial Engineering',
    cipDefinition:
      'A program that prepares individuals to apply scientific and mathematical principles to the design, improvement, and installation of integrated systems of people, material, information, and energy.  Includes instruction in applied mathematics, physical sciences, the social sciences, engineering analysis, systems design, computer applications, and forecasting and evaluation methodology.',
    Examples: 'Examples: - Ergonomics Engineering, - Human Factors Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.36',
    cipTitle: 'Manufacturing Engineering',
    cipDefinition: 'Instructional content is defined in code 14.3601.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3601',
    cipTitle: 'Manufacturing Engineering',
    cipDefinition:
      'A program that prepares individuals to apply scientific and mathematical principles to the design, development, and implementation of manufacturing systems.  Includes instruction in materials science and engineering, manufacturing processes, process engineering, assembly and product engineering, manufacturing systems design, and manufacturing competitiveness.',
  },
  {
    cipFamily: '14',
    cipCode: '14.37',
    cipTitle: 'Operations Research',
    cipDefinition: 'Instructional content is defined in code 14.3701.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3701',
    cipTitle: 'Operations Research',
    cipDefinition:
      'A program that focuses on the development and application of complex mathematical or simulation models to solve problems involving operational systems, where the system concerned is subject to human intervention.  Includes instruction in advanced multivariate analysis, application of judgment and statistical tests, optimization theory and techniques, resource allocation theory, mathematical modeling, control theory, statistical analysis, and applications to specific research problems.',
    Examples:
      'Examples: - Decision Support Systems, - Decision Analysis, - Operations Analysis, - Operational Sciences',
  },
  {
    cipFamily: '14',
    cipCode: '14.38',
    cipTitle: 'Surveying Engineering',
    cipDefinition: 'Instructional content is defined in code 14.3801.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3801',
    cipTitle: 'Surveying Engineering',
    cipDefinition:
      'A program that prepares individuals to apply scientific and mathematical principles to the determination of the location, elevations, and alignment of natural and manmade topographic features.  Includes instruction in property line location, surveying, surface measurement, aerial and terrestrial photogrammetry, remote sensing, satellite imagery, global positioning systems, computer applications, and photographic data processing.',
  },
  {
    cipFamily: '14',
    cipCode: '14.39',
    cipTitle: 'Geological/Geophysical Engineering',
    cipDefinition: 'Instructional content is defined in code 14.3901.',
  },
  {
    cipFamily: '14',
    cipCode: '14.3901',
    cipTitle: 'Geological/Geophysical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and geological principles to the analysis and evaluation of engineering problems, including the geological evaluation of construction sites, the analysis of geological forces acting on structures and systems, the analysis of potential natural resource recovery sites, and applied research on geological phenomena.',
    CrossReferences: '40.0601 - Geology/Earth Science, General.',
  },
  {
    cipFamily: '14',
    cipCode: '14.40',
    cipTitle: 'Paper Science and Engineering',
    cipDefinition: 'Instructional content is defined in code 14.4001.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4001',
    cipTitle: 'Paper Science and Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development, and manufacturing of pulp and paper products. Includes instruction in pulp and paper science, pulping and bleaching processes, paper structure and processes, pulp and paper manufacturing, coating technology, surface and colloid science, and fibers.',
    CrossReferences: '3.0509 - Wood Science and Wood Products/Pulp and Paper Technology/Technician.',
    Examples:
      'Examples: - Paper Science, - Paper Science Engineering, - Pulp and Paper Science, - Wood and Paper Science',
  },
  {
    cipFamily: '14',
    cipCode: '14.41',
    cipTitle: 'Electromechanical Engineering',
    cipDefinition: 'Instructional content is defined in code 14.4101.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4101',
    cipTitle: 'Electromechanical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply scientific and mathematical principles to the problems associated with combining electrical and mechanical components with special emphasis on manufacturing and automated processes. Includes instruction in applied mechanics, instrumentation and monitoring, machine design, automated control techniques, fluid and thermal dynamics, circuit analysis, and solid state electronics.',
  },
  {
    cipFamily: '14',
    cipCode: '14.42',
    cipTitle: 'Mechatronics, Robotics, and Automation Engineering',
    cipDefinition: 'Instructional content is defined in code 14.4201.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4201',
    cipTitle: 'Mechatronics, Robotics, and Automation Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of computer controlled electro-mechanical systems and products with embedded electronics, sensors, and actuators; and which includes, but is not limited to, automata, robots and automation systems. Includes instruction in mechanical engineering, electronic and electrical engineering, computer and software engineering, and control engineering.',
    CrossReferences: '11.0102 - Artificial Intelligence.',
  },
  {
    cipFamily: '14',
    cipCode: '14.43',
    cipTitle: 'Biochemical Engineering',
    cipDefinition: 'Instructional content is defined in code 14.4301.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4301',
    cipTitle: 'Biochemical Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the study of biochemical processes in living organisms, properties of biological materials, and processes using biochemical agents such as cells, enzymes, and antibodies. Includes instruction in biology, chemistry, physics, biochemistry, thermodynamics, fluid dynamics, bioprocesses, and chemical engineering.',
    Examples: 'Examples: - Biochemical Engineering, - Chemical and Biochemical Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.44',
    cipTitle: 'Engineering Chemistry',
    cipDefinition: 'Instructional content is defined in code 14.4401.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4401',
    cipTitle: 'Engineering Chemistry',
    cipDefinition:
      'A program that focuses on the general application of chemical principles to the analysis and evaluation of engineering problems, such as development of electronic materials, solid-state science and technology, polymers, ceramics, and biomaterials. Includes instruction in physical chemistry, organic chemistry, materials science, chemical processes and systems, chemical reaction engineering, biochemical engineering, engineering mathematics, classical and modern physics, and computer science.',
  },
  {
    cipFamily: '14',
    cipCode: '14.45',
    cipTitle: 'Biological/Biosystems Engineering',
    cipDefinition: 'Instructional content is defined in code 14.4501.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4501',
    cipTitle: 'Biological/Biosystems Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and management of biological systems; and includes applications to biology, biochemistry, ecology, and microbiology. Includes instruction in organic chemistry; microbiology; biochemistry; chemical, biological, biochemical, and process engineering; thermodynamics; process control; kinetics and reactor design; electric circuits; biosystem modeling; and bioelectronics and instrumentation.',
    Examples:
      'Examples: - Biological Engineering, - Biosystems Engineering, - Biological Systems Engineering, - Biomass Engineering, - Biological/Biosystems Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.47',
    cipTitle: 'Electrical and Computer Engineering',
    cipDefinition: 'Instructional content is defined in code 14.4701.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4701',
    cipTitle: 'Electrical and Computer Engineering',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design and development of computer systems. Includes instruction in computer architecture, cybersecurity, electronic circuits, electromagnetism, electronic materials and design, micro-fabrication methods and techniques, signal and image processing, and wireless communication networks.',
  },
  {
    cipFamily: '14',
    cipCode: '14.48',
    cipTitle: 'Energy Systems Engineering',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 14.4801 - 14.4899.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4801',
    cipTitle: 'Energy Systems Engineering, General',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the design, development and operational evaluation of energy generation, storage, conversion, and distribution systems. Includes instruction in conventional and alternative/renewable energy systems, electrical power systems, and electrical system design.',
    CrossReferences: '15.1701 - Energy Systems Technology/Technician., 3.0210 - Bioenergy.',
    Examples: 'Examples: - Sustainable/Renewable Energy Engineering',
  },
  {
    cipFamily: '14',
    cipCode: '14.4802',
    cipTitle: 'Power Plant Engineering',
    cipDefinition:
      'A program that prepares individuals to plan electrical systems and modify existing electrical systems that generate and use large amounts of electricity required for distribution networks that are economical, safe, and functional. Includes instruction in alternative/renewable energy systems; calculus; circuit analysis; electrical power systems and industry practices; electrical system design; microprocessor architecture; motor control systems; power electronics operation, planning and protection; programmable logic controllers; and project management.',
    CrossReferences: '15.1702 - Power Plant Technology/Technician.',
  },
  {
    cipFamily: '14',
    cipCode: '14.4899',
    cipTitle: 'Energy Systems Engineering, Other',
    cipDefinition: 'Any instructional program in energy systems engineering not listed above.',
  },
  {
    cipFamily: '14',
    cipCode: '14.99',
    cipTitle: 'Engineering, Other',
    cipDefinition: 'Instructional content is defined in code 14.9999.',
  },
  {
    cipFamily: '14',
    cipCode: '14.9999',
    cipTitle: 'Engineering, Other',
    cipDefinition: 'Any instructional program in engineering not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15',
    cipTitle: 'Engineering/Engineering-Related Technologies/Technicians',
    cipDefinition:
      'Instructional programs that prepare individuals to apply basic engineering principles and technical skills in support of engineering and related projects or to prepare for engineering-related fields.',
  },
  {
    cipFamily: '15',
    cipCode: '15.00',
    cipTitle: 'Engineering Technologies/Technicians, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0000 - 15.0001.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0000',
    cipTitle: 'Engineering Technologies/Technicians, General',
    cipDefinition:
      'A program that generally prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in a wide variety of projects. Includes instruction in various engineering support functions for research, production, and operations, and applications to specific engineering specialties.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0001',
    cipTitle: 'Applied Engineering Technologies/Technicians',
    cipDefinition:
      'A program that generally prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in the management and design of systems, execution of new product designs, improvement of manufacturing processes, and the management and direction of the physical or technical functions of an organization. Includes instruction in basic engineering principles, project management, industrial processes, production and operations management, systems integration and control, quality control, and statistics.',
    Examples: 'Examples: - Applied Engineering',
  },
  {
    cipFamily: '15',
    cipCode: '15.01',
    cipTitle: 'Architectural Engineering Technologies/Technicians',
    cipDefinition: 'Instructional content is defined in code 15.0101.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0101',
    cipTitle: 'Architectural Engineering Technologies/Technicians',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of architects, engineers and planners engaged in designing and developing buildings, urban complexes, and related systems.  Includes instruction in design testing procedures, building site analysis, model building and computer graphics, engineering drawing, structural systems testing, analysis of prototype mechanical and interior systems, test equipment operation and maintenance, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.02',
    cipTitle: 'Civil Engineering Technologies/Technicians',
    cipDefinition: 'Instructional content is defined in code 15.0201.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0201',
    cipTitle: 'Civil Engineering Technologies/Technicians',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of civil engineers engaged in designing and executing public works projects such as highways, dams, bridges, tunnels and other facilities.  Includes instruction in site analysis, structural testing procedures, field and laboratory testing procedures, plan and specification preparation, test equipment operation and maintenance, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.03',
    cipTitle: 'Electrical/Electronic Engineering Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0303 - 15.0399.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0303',
    cipTitle: 'Electrical, Electronic, and Communications Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of electrical, electronics and communication engineers.  Includes instruction in electrical circuitry, prototype development and testing, systems analysis and testing, systems maintenance, instrument calibration, and report preparation.',
    CrossReferences: '46.0302 - Electrician.',
    Examples: 'Examples: - Electronics Technician, - Consumer Product Electronics Technician',
  },
  {
    cipFamily: '15',
    cipCode: '15.0304',
    cipTitle: 'Laser and Optical Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing and using lasers and other optical for commercial or research purposes.  Includes instruction in laser and optical principles, testing and maintenance procedures, safety precautions, specific applications to various tasks, and report preparation.',
    Examples: 'Examples: - Photonics Engineering Technology',
  },
  {
    cipFamily: '15',
    cipCode: '15.0305',
    cipTitle: 'Telecommunications Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to help design and implement telecommunications systems.  Includes instruction in communications protocol, data networking, digital compression algorithms, digital signal processing, Internet access, object-oriented and relational databases, and programming languages.',
    Examples: 'Examples: - Wireless Communications Technician, - Telecommunications Engineering Technology/Technician',
  },
  {
    cipFamily: '15',
    cipCode: '15.0306',
    cipTitle: 'Integrated Circuit Design Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to design circuits in microelectronics. Includes instruction in circuit design, circuit layout, circuit analysis, computer-aided drafting, and semi-conductor technologies.',
    Examples:
      'Examples: - Integrated Circuit Design, - Integrated Circuit Design and Layout, - Microcircuit Mask Design',
  },
  {
    cipFamily: '15',
    cipCode: '15.0307',
    cipTitle: 'Audio Engineering Technology/Technician',
    cipDefinition:
      'A program of study that prepares individuals to apply mathematical and scientific principles to the mixing, recording, and production of music. Includes instruction in acoustics, audio mixing, audio production, audio recording, computer composition of music, music theory, digital devices, and sound technology.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0399',
    cipTitle: 'Electrical/Electronic Engineering Technologies/Technicians, Other',
    cipDefinition:
      'Any instructional program in electrical and electronic engineering-related technologies not listed above.',
    Examples: 'Examples: - Microelectronics Technology',
  },
  {
    cipFamily: '15',
    cipCode: '15.04',
    cipTitle: 'Electromechanical Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0401 - 15.0499.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0401',
    cipTitle: 'Biomedical Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in developing biological or medical systems and products.  Includes instruction in instrument calibration, design and installation testing, system safety and maintenance procedures, procurement and installation procedures, and report preparation.',
    Examples: 'Examples: - Bioengineering Technology',
  },
  {
    cipFamily: '15',
    cipCode: '15.0403',
    cipTitle: 'Electromechanical/Electromechanical Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in developing and testing automated, servomechanical, and other electromechanical systems.  Includes instruction in prototype testing, manufacturing and operational testing, systems analysis and maintenance procedures, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0404',
    cipTitle: 'Instrumentation Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in developing control and measurement systems and procedures.  Includes instruction in instrumentation design and maintenance, calibration, design and production testing and scheduling, automated equipment functions, applications to specific industrial tasks, and report preparation.',
    Examples: 'Examples: - Industrial Instrument Mechanic, - Instrumentation and Control Technician',
  },
  {
    cipFamily: '15',
    cipCode: '15.0405',
    cipTitle: 'Robotics Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing and using robots.  Includes instruction in the principles of robotics, design and operational testing, system maintenance and repair procedures, robot computer systems and control language, specific system types and applications to specific industrial tasks, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0406',
    cipTitle: 'Automation Engineer Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing, installing, calibrating, modifying and maintaining automated systems. Includes instruction in computer systems; electronics and instrumentation; programmable logic controllers (PLCs); electric, hydraulic and pneumatic control systems; actuator and sensor systems; process control; robotics; applications to specific industrial tasks; and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0407',
    cipTitle: 'Mechatronics, Robotics, and Automation Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in the support of engineers to the design, development, and operational evaluation of autonomous, computer-controlled, electro-mechanical systems. Includes instruction in computer and software engineering, control engineering, electronic and electrical engineering, mechanical engineering, and robotics.',
    CrossReferences: '14.4201 - Mechatronics, Robotics, and Automation Engineering.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0499',
    cipTitle: 'Electromechanical Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in electromechanical technologies not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.05',
    cipTitle: 'Environmental Control Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0501 - 15.0599.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0501',
    cipTitle: 'Heating, Ventilation, Air Conditioning and Refrigeration Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing and using air conditioning, refrigeration, ventilation, and heating systems. Includes instruction in principles of heating and cooling technology, design and operational testing, inspection and maintenance procedures, installation and operation procedures, and report preparation.',
    CrossReferences:
      '47.0201 - Heating, Air Conditioning, Ventilation and Refrigeration Maintenance Technology/Technician.',
    Examples:
      'Examples: - Air Conditioning and Heating (ACH), - Air Conditioning and Refrigeration (ACR), - Air Conditioning, Heating and Refrigeration (ACHR), - Heating, Refrigeration and Air Conditioning (HRAC), - Heating,  Ventilation, and Air Conditioning (HVAC), - Heating, Ventilation, Air Conditioning and Refrigeration (HVAC/R), - Air Conditioning Technology',
  },
  {
    cipFamily: '15',
    cipCode: '15.0503',
    cipTitle: 'Energy Management and Systems Technology/Technician',
    cipDefinition: 'Moved from 15.0503 to 15.1701',
  },
  {
    cipFamily: '15',
    cipCode: '15.0505',
    cipTitle: 'Solar Energy Technology/Technician',
    cipDefinition: 'Moved from 15.0505 to 15.1703',
  },
  {
    cipFamily: '15',
    cipCode: '15.0506',
    cipTitle: 'Water Quality and Wastewater Treatment Management and Recycling Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing and using water storage, waterpower, and wastewater treatment systems.  Includes instruction in water storage, power and/or treatment systems and equipment; testing and inspection procedures; system maintenance procedures; and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0507',
    cipTitle: 'Environmental/Environmental Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing and using indoor and outdoor environmental pollution control systems.  Includes instruction in environmental safety principles, testing and sampling procedures, laboratory techniques, instrumentation calibration, safety and protection procedures, equipment maintenance, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0508',
    cipTitle: 'Hazardous Materials Management and Waste Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in identifying and disposing of hazardous materials.  Includes instruction in environmental safety principles, biohazard identification, testing and sampling procedures, laboratory techniques, instrumentation calibration, hazardous waste disposal procedures and systems, safety and protection procedures, equipment maintenance, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0599',
    cipTitle: 'Environmental Control Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in environmental control technologies not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.06',
    cipTitle: 'Industrial Production Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0607 - 15.0699.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0607',
    cipTitle: 'Plastics and Polymer Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing and using industrial polymers.  Includes instruction in the principles of macromolecular chemistry, polymerization and plastic manufacturing processes and equipment, design and operational testing procedures, equipment maintenance and repair procedures, safety procedures, applications to specific products, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0611',
    cipTitle: 'Metallurgical Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and metallurgists engaged in developing and using industrial metals and manufacturing processes.  Includes instruction in principles of metallurgy, related manufacturing systems, laboratory techniques, testing and inspection procedures, instrument calibration, system and equipment maintenance and repair, applications to specific processes, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0612',
    cipTitle: 'Industrial Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of industrial engineers and managers.  Includes instruction in optimization theory, human factors, organizational behavior, industrial processes, industrial planning procedures, computer applications, and report and presentation  preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0613',
    cipTitle: 'Manufacturing Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to the identification and resolution of production problems in the manufacture of products.  Includes instruction in machine operations, production line operations, engineering analysis, systems analysis, instrumentation, physical controls, automation, computer-aided manufacturing (CAM), manufacturing planning, quality control, and informational infrastructure.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0614',
    cipTitle: 'Welding Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to the design and engineering of welding and joining systems and the implementation of welding processes.  Includes instruction in materials science; computer-aided design; welding design; welding processes; welding metallurgy; automation and robotics; and codes, inspections, testing, and quality assurance.',
    CrossReferences: '48.0508 - Welding Technology/Welder.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0615',
    cipTitle: 'Chemical Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in the production and utilization of chemicals on an industrial scale. Includes instruction in organic chemistry, inorganic chemistry, analytical chemistry, biochemistry, thermodynamics, fluid mechanics, industrial processes, instrumental analysis, sampling and monitoring, and chemical and environmental safety.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0616',
    cipTitle: 'Semiconductor Manufacturing Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to operate and monitor equipment for the fabrication of semiconductors or microchips from silicon wafers, and to troubleshoot, maintain, and repair the specialized equipment used in this process. Includes instruction in AC and DC circuits, digital fundamentals, solid state devices, manufacturing processes, vacuum principles and technology, industrial electronics, quality assurance, and semiconductor manufacturing technology.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0617',
    cipTitle: 'Composite Materials Technology/Technician',
    cipDefinition:
      'A program of study that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in the development, manufacture, and use of composite materials in aircraft technology, automotive technology, boats, medical prostheses, and wind turbines. Includes instruction in computer-aided design and drafting, composite materials and processes, composite maintenance, composite manufacturing, composite repair, material science, and mold manufacturing and production.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0699',
    cipTitle: 'Industrial Production Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in industrial production technologies not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.07',
    cipTitle: 'Quality Control and Safety Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0701 - 15.0799.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0701',
    cipTitle: 'Occupational Safety and Health Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in maintaining job-related health and safety standards.  Includes instruction in safety engineering principles, inspection and monitoring procedures, testing and sampling procedures, laboratory techniques, applications to specific work environments, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0702',
    cipTitle: 'Quality Control Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in maintaining consistent manufacturing and construction standards.  Includes instruction in quality control systems management principles, technical standards applicable to specific engineering and manufacturing projects, testing procedures, inspection procedures, related instrumentation and equipment operation and maintenance, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0703',
    cipTitle: 'Industrial Safety Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to assist engineers and other professionals in implementing and enforcing industrial safety standards to mitigate damage, reduce risks, and prevent accidents. Includes instruction in industrial processes, industrial hygiene, injury prevention, toxicology, ergonomics, risk analysis, system and process safety, safety performance measurement, human factors, human behavior, and applicable law and regulations.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0704',
    cipTitle: 'Hazardous Materials Information Systems Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to assist engineers and other professionals in implementing, monitoring, and enforcing hazardous materials management and removal.  Includes instruction in environmental science, environmental health, human behavior, economics, management science, information systems and applications, and communication skills.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0705',
    cipTitle: 'Process Safety Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in developing methods to mitigate damage, reduce risks, and prevent accidents. Includes instruction in design for injury prevention, engineering for process safety, environmental psychology, function-based risk analysis, fundamentals of industrial engineering, operations research, probability and statistics, risk assessment and reduction, and safety engineering.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0799',
    cipTitle: 'Quality Control and Safety Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in quality control and safety technologies not listed above.',
    Examples: 'Examples: - Non-Destructive Testing Technology',
  },
  {
    cipFamily: '15',
    cipCode: '15.08',
    cipTitle: 'Mechanical Engineering Related Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0801 - 15.0899.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0801',
    cipTitle: 'Aeronautical/Aerospace Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing, manufacturing and testing aircraft, spacecraft and their systems.  Includes instruction in aircraft/spacecraft systems technology, design and development testing, prototype and operational testing, inspection and maintenance procedures, instrument calibration, test equipment operation and maintenance, and report preparation.',
    CrossReferences: '47.0609 - Avionics Maintenance Technology/Technician.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0803',
    cipTitle: 'Automotive Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing, manufacturing and testing self-propelled ground vehicles and their systems.  Includes instruction in vehicular systems technology, design and development testing, prototype and operational testing, inspection and maintenance procedures, instrument calibration, test equipment operation and maintenance, and report preparation.',
    CrossReferences: '47.0604 - Automobile/Automotive Mechanics Technology/Technician.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0805',
    cipTitle: 'Mechanical/Mechanical Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in the design and development phases of a wide variety of projects involving mechanical systems.  Includes instruction in principles of mechanics, applications to specific engineering systems, design testing procedures, prototype and operational testing and inspection procedures, manufacturing system-testing procedures, test equipment operation and maintenance, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0806',
    cipTitle: 'Marine Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in shipboard mechanical and electrical engineering and the maintenance and operation of marine engines, including gas, diesel, and steam engines. Includes instruction in automation, diesel-powered systems, electrical circuits and systems, engine performance, hydraulics, manufacturing, motor-powered systems, refrigeration and air-conditioning, steam-powered systems, and welding.',
    CrossReferences: '48.0704 - Wooden Boatbuilding Technology/Technician.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0807',
    cipTitle: 'Motorsports Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in the design, construction, and repair of vehicles used in racing and motorsports. Includes instruction in aerodynamics, design, vehicle dynamics, performance engines, fluid mechanics, computer-aided design (CAD), mechanical engineering, and welding.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0899',
    cipTitle: 'Mechanical Engineering Related Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in mechanical engineering-related technologies not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.09',
    cipTitle: 'Mining and Petroleum Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.0901 - 15.0999.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0901',
    cipTitle: 'Mining Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in the development and operation of mines and related mineral processing facilities.  Includes instruction in principles of mineral extraction and related geology, mineral field mapping and site analysis, testing and sampling methods, instrument calibration, assay analysis, test equipment operation and maintenance, mine environment and safety monitoring procedures, mine inspection procedures, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0903',
    cipTitle: 'Petroleum Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in the development and operation of oil and natural gas extraction and processing facilities.  Includes instruction in principles of petroleum extraction and related geology, petroleum field mapping and site analysis, testing and sampling methods, instrument calibration, laboratory analysis, test equipment operation and maintenance, environment and safety monitoring procedures for oil/gas fields and facilities, facility inspection procedures, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.0999',
    cipTitle: 'Mining and Petroleum Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in mining and petroleum engineering technologies not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.10',
    cipTitle: 'Construction Engineering Technology/Technician',
    cipDefinition: 'Instructional content is defined in code 15.1001.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1001',
    cipTitle: 'Construction Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers, engineering contractors and other professionals engaged in the construction of buildings and related structures.  Includes instruction in basic structural engineering principles and construction techniques, building site inspection, site supervision, construction personnel supervision, plan and specification interpretation, supply logistics and procurement, applicable building codes, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.11',
    cipTitle: 'Engineering-Related Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.1102 - 15.1199.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1102',
    cipTitle: 'Surveying Technology/Surveying',
    cipDefinition:
      'A program that prepares individuals to apply mathematical and scientific principles to the delineation, determination, planning and positioning of land tracts, land and water boundaries, land contours and features; and the preparation of related maps, charts and reports.  Includes instruction in applied geodesy, computer graphics, photointerpretation, plane and geodetic surveying, mensuration, traversing, survey equipment operation and maintenance, instrument calibration, and basic cartography.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1103',
    cipTitle: 'Hydraulics and Fluid Power Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing and using fluid power and transportation systems.  Includes instruction in fluid mechanics and hydraulics principles, fluid power systems, pipeline and pumping systems, design and operational testing, inspection and maintenance procedures, related instrumentation, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1199',
    cipTitle: 'Engineering-Related Technologies/Technicians, Other',
    cipDefinition: 'Any programs in engineering-related technologies and technicians not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.12',
    cipTitle: 'Computer Engineering Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.1201 - 15.1299.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1201',
    cipTitle: 'Computer Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of computer engineers engaged in designing and developing computer systems and installations.  Includes instruction in computer electronics and programming, prototype development and testing, systems installation and testing, solid state and microminiature circuitry, peripheral equipment, and report preparation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1202',
    cipTitle: 'Computer/Computer Systems Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of professionals who use computer systems.  Includes instruction in basic computer design and architecture, programming, problems of specific computer applications, component and system maintenance and inspection procedures, hardware and software problem diagnosis and repair, and report preparation.',
    CrossReferences: '47.0104 - Computer Installation and Repair Technology/Technician.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1203',
    cipTitle: 'Computer Hardware Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to support engineers in designing computer hardware and peripheral systems.  Includes instruction in computer systems design, computer architecture, computer electronics, processors, peripherals, testing equipment, and computer manufacturing processes.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1204',
    cipTitle: 'Computer Software Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills to support engineers in developing, implementing, and evaluating computer software and program applications.  Includes instruction in computer programming,  programming languages, databases, user interfaces, networking and warehousing, encryption and security, software testing and evaluation, and customization.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1299',
    cipTitle: 'Computer Engineering Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in computer engineering technologies not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.13',
    cipTitle: 'Drafting/Design Engineering Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.1301 - 15.1399.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1301',
    cipTitle: 'Drafting and Design Technology/Technician, General',
    cipDefinition:
      'A program that prepares individuals to generally apply technical skills to create working drawings and computer simulations for a variety of applications.  Includes instruction in specification interpretation, dimensioning techniques, drafting calculations, material estimation, technical communications, computer applications, and interpersonal communications.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1302',
    cipTitle: 'CAD/CADD Drafting and/or Design Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical skills and advanced computer software and hardware to the creation of graphic representations and simulations in support of engineering projects.  Includes instruction in engineering graphics, two-dimensional and three-dimensional engineering design, solids modeling, engineering animation, computer-aided drafting (CAD), computer-aided design (CADD), and auto-CAD techniques.',
    Examples: 'Examples: - Engineering Graphics and Design, - Engineering Graphics Technology',
  },
  {
    cipFamily: '15',
    cipCode: '15.1303',
    cipTitle: 'Architectural Drafting and Architectural CAD/CADD',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to develop working drawings and electronic simulations for architectural and related construction projects.  Includes instruction in basic construction and structural design, architectural rendering, architectural-aided drafting (CAD), layout and designs, architectural blueprint interpretation, building materials, and basic structural wiring diagramming.',
    CrossReferences: '4.0901 - Architectural Technology/Technician., 4.0201 - Architecture.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1304',
    cipTitle: 'Civil Drafting and Civil Engineering CAD/CADD',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to develop working drawing and electronic simulations in support of civil engineers, geological engineers, and related professionals.  Includes instruction in basic civil engineering principles, geological and seismographic mapping, machine drafting, computer-aided drafting (CAD), pipe drafting, survey interpretation, and blueprint reading.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1305',
    cipTitle: 'Electrical/Electronics Drafting and Electrical/Electronics CAD/CADD',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to develop working schematics and representations in support of electrical/electronic engineers, computer engineers, and related professionals.  Includes instruction in basic electronics, electrical systems and computer layouts; electrode-mechanical drafting; manufacturing circuitry; computer-aided drafting (CAD); and electrical systems specification interpretation.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1306',
    cipTitle: 'Mechanical Drafting and Mechanical Drafting CAD/CADD',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to develop working drawings and electronic simulations in support of mechanical and industrial engineers, and related professionals.  Includes instruction in manufacturing materials and processes, mechanical drafting, electrode-mechanical drafting, basic metallurgy, geometric dimensioning and tolerancing, blueprint reading, and technical communication.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1307',
    cipTitle: '3-D Modeling and Design Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills in the use of three-dimensional (3-D) computer technology to create technical illustrations and models used in manufacturing, design, production, and construction. Includes instruction in 3-D computer-aided design (CAD), 3-D printing, 3-D model design and construction, and 3-D scanning.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1399',
    cipTitle: 'Drafting/Design Engineering Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in drafting/design engineering technologies not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.14',
    cipTitle: 'Nuclear Engineering Technology/Technician',
    cipDefinition: 'Instructional content is defined in code 15.1401.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1401',
    cipTitle: 'Nuclear Engineering Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering, knowledge and technical skills in support of engineer and other professionals operating nuclear facilities and engaged in nuclear applications and safety procedures.  Includes instruction in physics, nuclear science, nuclear systems, nuclear plant and systems design, radiological safety, radiological applications, and applicable law and regulations.',
  },
  {
    cipFamily: '15',
    cipCode: '15.15',
    cipTitle: 'Engineering-Related Fields',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.1501 - 15.1599.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1501',
    cipTitle: 'Engineering/Industrial Management',
    cipDefinition:
      'A program that focuses on the application of engineering principles to the planning and operational management of industrial and manufacturing operations, and prepares individuals to plan and manage such operations.  Includes instruction in accounting, engineering economy, financial management, industrial and human resources management, industrial psychology, management information systems, mathematical modeling and optimization, quality control, operations research, safety and health issues, and environmental program management.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1502',
    cipTitle: 'Engineering Design',
    cipDefinition:
      'An instructional program that prepares individuals to apply mathematical and scientific principles to engineering problems involving marrying or coordinating multiple dissimilar systems to carry out single functions or achieve common purposes, organizing system components for maximum flexibility and utility, planning engineering projects involving multiple tasks and design solutions, planning design testing and evaluation procedures, resolving specification and requirement conflicts, and choosing among competing theoretical solutions. Note: this program was re-instated after being deleted from CIP 2000; previously, it was coded as 14.2901.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1503',
    cipTitle: 'Packaging Science',
    cipDefinition:
      'A program that focuses on the application of scientific, technological, design, and business principles to the development of packages and packaging materials, including raw material production, conversion of raw materials into usable forms, design, distribution, and post-use recycling and reuse. Includes instruction in principles of packaging, materials sciences, distribution and transportation, engineering and technology, graphic design, packaging regulations, and business and marketing.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1599',
    cipTitle: 'Engineering-Related Fields, Other',
    cipDefinition: 'Any instructional program in engineering-related fields not listed above.',
  },
  {
    cipFamily: '15',
    cipCode: '15.16',
    cipTitle: 'Nanotechnology',
    cipDefinition: 'Instructional content is defined in code 15.1601.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1601',
    cipTitle: 'Nanotechnology',
    cipDefinition:
      'A program that prepares individuals to apply mathematical, scientific, and engineering principles and technical skills to manipulate matter at the atomic and molecular level (in the range of 1-100 nanometers) and to design, fabricate, and integrate nanoscale structures, devices, and systems. Includes instruction in materials science, thermodynamics, nanomaterials, nanoelectronics, and nano/micro device fabrication and testing.',
    Examples: 'Examples: - Nanofabrication Technology, - Nanoscience Technician',
  },
  {
    cipFamily: '15',
    cipCode: '15.17',
    cipTitle: 'Energy Systems Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 15.1701 - 15.1799.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1701',
    cipTitle: 'Energy Systems Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing energy-efficient systems or monitoring energy use.  Includes instruction in principles of energy conservation, instrumentation calibration, monitoring systems and test procedures, energy loss inspection procedures, energy conservation techniques, and report preparation.',
    CrossReferences:
      '3.0209 - Energy and Environmental Policy., 3.0210 - Bioenergy., 14.4801 - Energy Systems Engineering, General., 47.0701 - Energy Systems Installation and Repair Technology/Technician.',
    Examples: 'Examples: - Energy Management and Systems Technology/Technician',
  },
  {
    cipFamily: '15',
    cipCode: '15.1702',
    cipTitle: 'Power Plant Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers engaged in the operation and maintenance of electricity generating power plants. Includes instruction in basic electricity, electrical power, generator operations, industrial controls, power plant instrumentation, power plant theory, pollution control, reactor theory, thermodynamics, turbines, and water chemistry.',
    CrossReferences: '14.4802 - Power Plant Engineering.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1703',
    cipTitle: 'Solar Energy Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing solar-powered energy systems.  Includes instruction in solar energy principles, energy storage and transfer technologies, testing and inspection procedures, system maintenance procedures, and report preparation.',
    CrossReferences: '47.0703 - Solar Energy System Installation and Repair Technology/Technician.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1704',
    cipTitle: 'Wind Energy Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing wind-powered energy systems. Includes instruction in wind energy principles, energy storage and transfer technologies, testing and inspection procedures, system maintenance procedures, and report preparation.',
    CrossReferences: '47.0704 - Wind Energy System Installation and Repair Technology/Technician.',
    Examples: 'Examples: - Wind Turbine Technology/Technician',
  },
  {
    cipFamily: '15',
    cipCode: '15.1705',
    cipTitle: 'Hydroelectric Energy Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing hydroelectric energy systems. Includes instruction in hydroelectric energy principles, energy storage and transfer technologies, testing and inspection procedures, system maintenance procedures, and report preparation.',
    CrossReferences: '47.0705 - Hydroelectric Energy System Installation and Repair Technology/Technician.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1706',
    cipTitle: 'Geothermal Energy Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply basic engineering principles and technical skills in support of engineers and other professionals engaged in developing geothermal energy systems. Includes instruction in geothermal energy principles, energy storage and transfer technologies, testing and inspection procedures, system maintenance procedures, and report preparation.',
    CrossReferences: '47.0706 - Geothermal Energy System Installation and Repair Technology/Technician.',
  },
  {
    cipFamily: '15',
    cipCode: '15.1799',
    cipTitle: 'Energy Systems Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in energy systems technologies not listed above.',
    CrossReferences: '47.0799 - Energy Systems Maintenance and Repair Technologies/Technicians, Other.',
  },
  {
    cipFamily: '15',
    cipCode: '15.99',
    cipTitle: 'Engineering/Engineering-Related Technologies/Technicians, Other',
    cipDefinition: 'Instructional content is defined in code 15.9999.',
  },
  {
    cipFamily: '15',
    cipCode: '15.9999',
    cipTitle: 'Engineering/Engineering-Related Technologies/Technicians, Other',
    cipDefinition:
      'Any instructional program in engineering technologies and engineering-related fields not listed above.',
    Examples: 'Examples: - Product Design Engineering Technology',
  },
  {
    cipFamily: '16',
    cipCode: '16',
    cipTitle: 'Foreign Languages, Literatures, and Linguistics',
    cipDefinition:
      'Instructional programs that focus on foreign languages and literatures, the humanistic and scientific study of linguistics, and the provision of professional interpretation and translation services.',
  },
  {
    cipFamily: '16',
    cipCode: '16.01',
    cipTitle: 'Linguistic, Comparative, and Related Language Studies and Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.0101 - 16.0199.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0101',
    cipTitle: 'Foreign Languages and Literatures, General',
    cipDefinition:
      'A general program that focuses on one or more modern foreign languages that is not specific as to the name of the language(s) studied; that is otherwise undifferentiated; or that introduces students to language studies at the basic/elementary level.',
    CrossReferences: '30.4001 - Economics and Foreign Language/Literature., 30.4501 - History and Language/Literature.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0102',
    cipTitle: 'Linguistics',
    cipDefinition:
      'A program that focuses on language, language development, and relationships among languages and language groups from a humanistic and/or scientific perspective.  Includes instruction in subjects such as psycholinguistics, behavioral linguistics, language acquisition, sociolinguistics, mathematical and computational linguistics, grammatical theory and theoretical linguistics, philosophical linguistics, philology and historical linguistics, comparative linguistics, phonetics, phonemics, dialectology, semantics, functional grammar and linguistics, language typology, lexicography, morphology and syntax, orthography, stylistics, structuralism, rhetoric, and applications to artificial intelligence.',
    CrossReferences:
      '16.1602 - Linguistics of ASL and Other Sign Languages., 30.4801 - Linguistics and Computer Science., 30.4701 - Linguistics and Anthropology.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0103',
    cipTitle: 'Language Interpretation and Translation',
    cipDefinition:
      'A program that prepares individuals to be professional interpreters and/or translators of documents and data files, either from English or (Canadian) French into another language or languages or vice versa.  Includes intensive instruction in one or more foreign languages plus instruction in subjects such as single- and multiple-language interpretation, one- or two-way interpretation, simultaneous interpretation, general and literary translation, business translation, technical translation, and other specific applications of linguistic skills.',
    CrossReferences: '16.1603 - Sign Language Interpretation and Translation.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0104',
    cipTitle: 'Comparative Literature',
    cipDefinition:
      'A program that focuses on two or more literary traditions in the original languages or in translation.  Includes instruction in comparative linguistics; applicable foreign languages; English/French language and literature; literary criticism; and applications to genre, period, national, and textual studies as well as literary forms such as poetry, prose, and drama.',
    CrossReferences:
      '23.1401 - General Literature., 30.2601 - Cultural Studies/Critical Theory and Analysis., 30.3601 - Cultural Studies and Comparative Literature.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0105',
    cipTitle: 'Applied Linguistics',
    cipDefinition:
      'A program that focuses on language-related concerns in the everyday world, including language education, acquisition of first and additional languages, discourse analysis, language assessment, literacy, and language policy and planning.  Includes instruction in linguistic theory; language teaching and learning; discourse analysis; language and cognition; and language, culture, and identity.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0199',
    cipTitle: 'Linguistic, Comparative, and Related Language Studies and Services, Other',
    cipDefinition:
      'Any instructional program in linguistic, comparative, and related language studies and services not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.02',
    cipTitle: 'African Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content is defined in code 16.0201.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0201',
    cipTitle: 'African Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on one or more of the languages native to the African continent, including, but not limited to, Bantu languages, Nilo-Saharan languages, Niger-Congo languages, Khoisan languages, and the Chadic and Ethiopic languages of the Hamito-Semitic language family. Includes instruction in philology, dialects, and oral and written literatures.',
  },
  {
    cipFamily: '16',
    cipCode: '16.03',
    cipTitle: 'East Asian Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.0300 - 16.0399.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0300',
    cipTitle: 'East Asian Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on one or more of the languages of East Asia, including, but not limited to, the Sino-Tibetan, Japanese, and Korean languages. Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0301',
    cipTitle: 'Chinese Language and Literature',
    cipDefinition:
      'A program that focuses on the languages of China and associated dialects and literature. Includes instruction in philology; linguistics; dialects and pidgins; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0302',
    cipTitle: 'Japanese Language and Literature',
    cipDefinition:
      'A program that focuses on the Japanese language. Includes instruction in philology; Ancient, Medieval, and Modern Japanese; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0303',
    cipTitle: 'Korean Language and Literature',
    cipDefinition:
      'A program that focuses on the Korean language. Includes instruction in philology; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0304',
    cipTitle: 'Tibetan Language and Literature',
    cipDefinition:
      'A program that focuses on the Tibetan language. Includes instruction in philology; secular and religious Tibetan; dialects; and applications to business, science/technology, Buddhist studies, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0399',
    cipTitle: 'East Asian Languages, Literatures, and Linguistics, Other',
    cipDefinition: 'Any instructional program in East Asian languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.04',
    cipTitle: 'Slavic, Baltic and Albanian Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.0400 - 16.0499.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0400',
    cipTitle: 'Slavic Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on one or more of the Slavic languages of Central and Eastern Europe.  Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0401',
    cipTitle: 'Baltic Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on the languages of the Baltic peoples, including, but not limited to, the modern languages of Latvian and Lithuanian, and extinct languages such as Old Prussian and Curionian. Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0402',
    cipTitle: 'Russian Language and Literature',
    cipDefinition:
      'A program that focuses on the Russian language. Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0404',
    cipTitle: 'Albanian Language and Literature',
    cipDefinition:
      'A program that focuses on the Albanian language. Includes instruction in Albanian/Illyrian philology; Ghegg and Tosk dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0405',
    cipTitle: 'Bulgarian Language and Literature',
    cipDefinition:
      'A program that focuses on the Bulgarian language.  Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0406',
    cipTitle: 'Czech Language and Literature',
    cipDefinition:
      'A program that focuses on the Czech language.  Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0407',
    cipTitle: 'Polish Language and Literature',
    cipDefinition:
      'A program that focuses on the Polish language.  Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0408',
    cipTitle: 'Bosnian, Serbian, and Croatian Languages and Literatures',
    cipDefinition:
      'A program that focuses on the Bosnian, Serbian and/or Croatian languages. Includes instruction in philology; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0409',
    cipTitle: 'Slovak Language and Literature',
    cipDefinition:
      'A program that focuses on the Slovak language.  Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.041',
    cipTitle: 'Ukrainian Language and Literature',
    cipDefinition:
      'A program that focuses on the Ukrainian language.  Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0499',
    cipTitle: 'Slavic, Baltic, and Albanian Languages, Literatures, and Linguistics, Other',
    cipDefinition: 'A program that focuses on Slavic languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.05',
    cipTitle: 'Germanic Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.0500 - 16.0599.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0500',
    cipTitle: 'Germanic Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on one or more of the Germanic languages of Western, Central, and Northern Europe.  Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0501',
    cipTitle: 'German Language and Literature',
    cipDefinition:
      'A program that focuses on the German language and related dialects. Includes instruction in philology; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0502',
    cipTitle: 'Scandinavian Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on one or more of the languages, literatures, and linguistics of the peoples of Scandinavia and associated Northern European island groups.  Programs may involve multiple languages and language families, not be specific as to the name of the language(s) studied, or be otherwise undifferentiated.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0503',
    cipTitle: 'Danish Language and Literature',
    cipDefinition:
      'A program that focuses on the Danish language and related dialects. Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0504',
    cipTitle: 'Dutch/Flemish Language and Literature',
    cipDefinition:
      'A program that focuses on the Dutch language and related dialects. Includes instruction in philology; literature; Dutch Creoles; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0505',
    cipTitle: 'Norwegian Language and Literature',
    cipDefinition:
      'A program that focuses on the Norwegian language and related dialects. Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0506',
    cipTitle: 'Swedish Language and Literature',
    cipDefinition:
      'A program that focuses on the Swedish language and related dialects. Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0599',
    cipTitle: 'Germanic Languages, Literatures, and Linguistics, Other',
    cipDefinition: 'Any instructional program in Germanic languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.06',
    cipTitle: 'Modern Greek Language and Literature',
    cipDefinition: 'Instructional content is defined in code 16.0601.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0601',
    cipTitle: 'Modern Greek Language and Literature',
    cipDefinition:
      'A program that focuses on the development and use of the Greek language in the period dating from the late 15th century to the present.  Includes instruction in modern Greek literature, current Greek dialects, and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.07',
    cipTitle: 'South Asian Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.0700 - 16.0799.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0700',
    cipTitle: 'South Asian Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on one or more of the languages, literatures, and linguistics of the peoples of the Indian subcontinent and associated borderlands and island groups.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0701',
    cipTitle: 'Hindi Language and Literature',
    cipDefinition:
      'A program that focuses on the Hindi language, antecessors, and related dialects. Includes instruction in philology; Modern Hindi; Hindustani; related dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0702',
    cipTitle: 'Sanskrit and Classical Indian Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on Sanskrit and related ancient and classical Indo-Aryan languages.  Includes instruction in Vedic and Classical Sanskrit, Pali and other Prakrits, and the historical development of Indo-Aryan languages and their relation to Indo-European languages such as Greek, Latin and the Baltic languages.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0704',
    cipTitle: 'Bengali Language and Literature',
    cipDefinition:
      'A program that focuses on the Bengali language (Bangla) and related dialects. Includes instruction in philology; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0705',
    cipTitle: 'Punjabi Language and Literature',
    cipDefinition:
      'A program that focuses on the Punjabi language (Punjabi) and related dialects. Includes instruction in philology; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0706',
    cipTitle: 'Tamil Language and Literature',
    cipDefinition:
      'A program that focuses on the Tamil language and related dialects. Includes instruction in philology; Classical and Modern Tamil; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0707',
    cipTitle: 'Urdu Language and Literature',
    cipDefinition:
      'A program that focuses on the Urdu language and related dialects. Includes instruction in philology; Modern Urdu; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0799',
    cipTitle: 'South Asian Languages, Literatures, and Linguistics, Other',
    cipDefinition: 'Any instructional program in South Asian languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.08',
    cipTitle: 'Iranian/Persian Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content is defined in code 16.0801.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0801',
    cipTitle: 'Iranian Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on the languages used in ancient, medieval, and modern Iran and its border regions, including modern Persian, Avestan/Old Persian, and related modern and extinct languages. Includes instruction in applications of modern Persian and other languages in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.09',
    cipTitle: 'Romance Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.0900 - 16.0999.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0900',
    cipTitle: 'Romance Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on one or more of the Romance languages of Western, Central, and Southern Europe.  Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0901',
    cipTitle: 'French Language and Literature',
    cipDefinition:
      'A program that focuses on the French language and related dialects and creoles. Includes instruction in philology; Metropolitan French; Canadian French; African and Caribbean Creoles; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0902',
    cipTitle: 'Italian Language and Literature',
    cipDefinition:
      'A program that focuses on the Italian language and related dialects. Includes instruction in philology; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0904',
    cipTitle: 'Portuguese Language and Literature',
    cipDefinition:
      'A program that focuses on the Portuguese language and related dialects. Includes instruction in philology; Metropolitan Portuguese; Luso-Brazilian Portuguese; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0905',
    cipTitle: 'Spanish Language and Literature',
    cipDefinition:
      'A program that focuses on the Spanish language and related dialects. Includes instruction in philology; Modern Castillan; Latin American and regional Spanish dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0906',
    cipTitle: 'Romanian Language and Literature',
    cipDefinition:
      'A program that focuses on the Romanian language and related dialects. Includes instruction in philology; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0907',
    cipTitle: 'Catalan Language and Literature',
    cipDefinition:
      'A program that focuses on the Catalan language and related dialects. Includes instruction in philology; dialects; and applications in business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0908',
    cipTitle: 'Hispanic and Latin American Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A program that focuses on the languages and literatures of the Spanish- and Portuguese-speaking areas of the world, including the Iberian, Latin American, and Lusophone worlds.  Includes instruction in Spanish and Portuguese language and linguistics; regional and Latin American dialects; and Spanish, Portuguese, Spanish American, Luso-Brazilian, and Chicano literature.',
  },
  {
    cipFamily: '16',
    cipCode: '16.0999',
    cipTitle: 'Romance Languages, Literatures, and Linguistics, Other',
    cipDefinition: 'Any instructional program in Romance languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.10',
    cipTitle: 'American Indian/Native American Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content is defined in code 16.1001.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1001',
    cipTitle: 'American Indian/Native American Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on one or more of the languages native to the Western Hemisphere, with an emphasis on American Indian languages, but including other Native American languages. Includes instruction in philology; oral and written literatures; Inuit and Aleut languages; North American language families, including, but not limited to, Algonkian, Athabascan, Siouan, Muskogean, Iroquoian, Kumeyaay, Yuman, Mayan, Zapotecan, and Uto-Aztecan; South American language families, including, but not limited to, Andean-Equatorial, Ge-Pano-Carib, and Macro-Chibchan; and other minor languages.',
  },
  {
    cipFamily: '16',
    cipCode: '16.11',
    cipTitle: 'Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.1100 - 16.1199.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1100',
    cipTitle: 'Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on one or more of the Middle/Near Eastern and Semitic languages of the ancient and modern Middle/Near East, Western Asia, North Africa, and Europe. Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1101',
    cipTitle: 'Arabic Language and Literature',
    cipDefinition:
      'A program that focuses on the Arabic language. Includes instruction in philology; Classical Arabic; Modern Standard Arabic; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1102',
    cipTitle: 'Hebrew Language and Literature',
    cipDefinition:
      'A program that focuses on the Hebrew language. Includes instruction in philology; Biblical Hebrew (including Pre- and Post-Exilic scripts); Modern Hebrew; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1103',
    cipTitle: 'Ancient Near Eastern and Biblical Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on one or more of the extinct Semitic and/or Non-Semitic languages spoken in the ancient Near East, including those used to write historical Jewish and Christian religious texts. These languages include, but are not limited to, Egyptian/Coptic, Sumerian, Akkadian, Babylonian, Assyrian, Aramaic, Cannanite, Phonecian, Samarian, Ugaritic, Syriac, Mandean, Hattic, Elamite, Hurrian, Hittite, Urartian, Lydian, Luwian, Lycian, Palaic, Sabaean/South Arabian, and other associated languages. Includes instruction in philology, epigraphy, papyrology, numismatics, and textual studies.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1199',
    cipTitle: 'Middle/Near Eastern and Semitic Languages, Literatures, and Linguistics, Other',
    cipDefinition:
      'Any instructional program in Middle/Near Eastern and Semitic languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.12',
    cipTitle: 'Classics and Classical Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.1200 - 16.1299.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1200',
    cipTitle: 'Classics and Classical Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on the literary culture of the ancient Graeco-Roman world and the Greek and Latin languages and literatures and their development prior to the fall of the Roman Empire.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1202',
    cipTitle: 'Ancient/Classical Greek Language and Literature',
    cipDefinition:
      'A program that focuses on the Greek language and literature from its origins through the fall of the Byzantine (Eastern Roman) Empire, as a secular and/or theological subject. Includes instruction in philology, Attic and Hellenistic dialects, Koine (Biblical) Greek, and Medieval or Byzantine Greek.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1203',
    cipTitle: 'Latin Language and Literature',
    cipDefinition:
      'A program that focuses on the Latin language and literature from its origins through its decline and its current ecclesiastical usage, as a secular and/or theological subject.  Includes instruction in philology, related Italic dialects, Late Roman and Medieval Latin, and modern Church Latin.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1299',
    cipTitle: 'Classics and Classical Languages, Literatures, and Linguistics, Other',
    cipDefinition:
      'Any instructional program in classics and classical languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.13',
    cipTitle: 'Celtic Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content is defined in code 16.1301.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1301',
    cipTitle: 'Celtic Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on the historical and modern languages spoken by the Celtic peoples of the British Isles, Continental Europe, and Asia Minor, including, but not limited to, modern languages such as Irish, Scots Gaelic, Welsh, and Breton; and extinct or revived languages such as Cornish, Manx, Galatian, and others. Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.14',
    cipTitle: 'Southeast Asian and Australasian/Pacific Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.1400 - 16.1499.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1400',
    cipTitle: 'Southeast Asian Languages, Literatures, and Linguistics, General',
    cipDefinition:
      'A general program that focuses on one or more of the modern languages spoken in mainland Southeast Asia and the Indonesian and Philippines Archipelagoes, including, but not limited to, members of the Thai, Tibeto-Burman, and Malayo-Polynesian language families.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1401',
    cipTitle: 'Australian/Oceanic/Pacific Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on the languages native to Papua/New Guinea, Australia, New Zealand, and the island groups of the Pacific Ocean, including, but not limited to, the Papuan languages, the Australian languages, the Micronesian languages, and the Polynesian languages. Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1402',
    cipTitle: 'Indonesian/Malay Languages and Literatures',
    cipDefinition:
      'A program that focuses on the Malay and Indonesian languages. Includes instruction in philology; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1403',
    cipTitle: 'Burmese Language and Literature',
    cipDefinition:
      'A program that focuses on the Burmese language.  Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1404',
    cipTitle: 'Filipino/Tagalog Language and Literature',
    cipDefinition:
      'A program that focuses on the modern Filipino/Tagalog language as used in the Philippines.  Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1405',
    cipTitle: 'Khmer/Cambodian Language and Literature',
    cipDefinition:
      'A program that focuses on the Khmer language as spoken in Cambodia. Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1406',
    cipTitle: 'Lao Language and Literature',
    cipDefinition:
      'A program that focuses on the Lao language. Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1407',
    cipTitle: 'Thai Language and Literature',
    cipDefinition:
      'A program that focuses on the Thai languages.  Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1408',
    cipTitle: 'Vietnamese Language and Literature',
    cipDefinition:
      'A program that focuses on the Vietnamese language. Includes instruction in philology; dialects; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1409',
    cipTitle: 'Hawaiian Language and Literature',
    cipDefinition:
      'A program that focuses on the Hawaiian language and related dialects. Includes instruction in philology; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1499',
    cipTitle: 'Southeast Asian and Australasian/Pacific Languages, Literatures, and Linguistics, Other',
    cipDefinition:
      'Any instructional program in Southeast Asian and Australasian/Pacific languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.15',
    cipTitle: 'Turkic, Uralic-Altaic, Caucasian, and Central Asian Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.1501 - 16.1599.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1501',
    cipTitle: 'Turkish Language and Literature',
    cipDefinition:
      'A program that focuses on the Turkish language in either or both of its pre-modern and modern variants. Includes instruction in philology; Ottoman Turkish; Modern Turkish; dialects; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1502',
    cipTitle: 'Uralic Languages, Literatures, and Linguistics',
    cipDefinition:
      'A program that focuses on Uralic languages, including, but not limited to, Finnish, Estonian, Karelian, and Sami. Includes instruction in philology; oral and written literatures; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1503',
    cipTitle: 'Hungarian/Magyar Language and Literature',
    cipDefinition:
      'A program that focuses on the Hungarian language. Includes instruction in philology; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1504',
    cipTitle: 'Mongolian Language and Literature',
    cipDefinition:
      'A program that focuses on the Mongolian language. Includes instruction in philology; dialects; oral and written literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1599',
    cipTitle: 'Turkic, Uralic-Altaic, Caucasian, and Central Asian Languages, Literatures, and Linguistics, Other',
    cipDefinition:
      'Any instructional program in the Turkic, Uralic-Altaic, Caucasian, and Central Asian languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.16',
    cipTitle: 'American Sign Language',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.1601 - 16.1699.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1601',
    cipTitle: 'American Sign Language (ASL)',
    cipDefinition:
      'A program that focuses on American Sign Language as a visual and motor medium of communication and discourse for deaf individuals and deaf culture.  Includes instruction in the development of ASL, ASL morphology and syntax, signing technique, English translation of ASL, formal and colloquial ASL, and ASL transcription.',
    CrossReferences: '5.0211 - Deaf Studies.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1602',
    cipTitle: 'Linguistics of ASL and Other Sign Languages',
    cipDefinition:
      'A program that focuses on the scientific and scholarly study of the development, structure, and use of American Sign Language (ASL) and other visual signed languages, both as vehicles for communication within the deaf community and in relation to spoken and written languages. Includes instruction in cognitive linguistics; ASL and sign language phonology, syntax, and morphology; sociolinguistics of the deaf community; comparative linguistics; and studies of specific sign languages, including, but not limited to, ASL, Auslan (Australian Sign Language), LSF (French Sign Language), Shuwa jiten (Japanese Sign Language), HamNoSys (German Sign Language), and Gestuno.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1603',
    cipTitle: 'Sign Language Interpretation and Translation',
    cipDefinition:
      'A program that prepares individuals to function as simultaneous interpreters of American Sign Language',
  },
  {
    cipFamily: '16',
    cipCode: '16.1699',
    cipTitle: 'American Sign Language, Other',
    cipDefinition:
      'Any instructional program that focuses on American Sign Language as a communication medium or language skill that is not listed above.',
  },
  {
    cipFamily: '16',
    cipCode: '16.17',
    cipTitle: 'Second Language Learning',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 16.1701 - 16.1799.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1701',
    cipTitle: 'English as a Second Language',
    cipDefinition:
      'A program that focuses on the development of proficiency in reading, writing, and speaking English for those for whom English is not their mother tongue. Includes instruction in the use of basic communication skills to develop and transmit ideas and thoughts in English as well as specialized programs that focus on the development of proficiency in English sufficient to meet specific occupational or academic demands. Note: These programs are for academic credit towards a postsecondary credential. For second language programs that are not for academic credit towards a postsecondary credential, see 32.0109.',
    CrossReferences: '32.0109 - Second Language Learning., 23.0101 - English Language and Literature, General.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1702',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1799',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '16',
    cipCode: '16.18',
    cipTitle: 'Armenian Languages, Literatures, and Linguistics',
    cipDefinition: 'Instructional content is defined in code 16.1801.',
  },
  {
    cipFamily: '16',
    cipCode: '16.1801',
    cipTitle: 'Armenian Language and Literature',
    cipDefinition:
      'A program that focuses on the historical and modern languages spoken by Armenians. Includes instruction in philology; linguistics; dialects and pidgins; literature; and applications to business, science/technology, and other settings.',
  },
  {
    cipFamily: '16',
    cipCode: '16.99',
    cipTitle: 'Foreign Languages, Literatures, and Linguistics, Other',
    cipDefinition: 'Instructional content is defined in code 16.9999.',
  },
  {
    cipFamily: '16',
    cipCode: '16.9999',
    cipTitle: 'Foreign Languages, Literatures, and Linguistics, Other',
    cipDefinition: 'Any instructional program in foreign languages, literatures, and linguistics not listed above.',
  },
  {
    cipFamily: '19',
    cipCode: '19',
    cipTitle: 'Family and Consumer Sciences/Human Sciences',
    cipDefinition:
      'Instructional programs that focus on the human interface with the physical, social, emotional, and intellectual environments and the developmental stages and needs of individuals in the interrelated spheres of family, workplace, and community.',
  },
  {
    cipFamily: '19',
    cipCode: '19.00',
    cipTitle: 'Work and Family Studies',
    cipDefinition: 'Moved from 19.00 to 19.10',
  },
  {
    cipFamily: '19',
    cipCode: '19.0000',
    cipTitle: 'Work and Family Studies',
    cipDefinition: 'Moved from 19.0000 to 19.1001',
  },
  {
    cipFamily: '19',
    cipCode: '19.01',
    cipTitle: 'Family and Consumer Sciences/Human Sciences, General',
    cipDefinition: 'Instructional content is defined in code 19.0101.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0101',
    cipTitle: 'Family and Consumer Sciences/Human Sciences, General',
    cipDefinition:
      'A general program that focuses on family and consumer sciences, including how individuals develop and function in family, work, and community settings and how they relate to their physical, social, emotional, and intellectual environments.',
    CrossReferences: '13.1308 - Family and Consumer Sciences/Home Economics Teacher Education.',
  },
  {
    cipFamily: '19',
    cipCode: '19.02',
    cipTitle: 'Family and Consumer Sciences/Human Sciences Business Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 19.0201 - 19.0299.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0201',
    cipTitle: 'Business Family and Consumer Sciences/Human Sciences',
    cipDefinition:
      'A program that focuses on the relationship between the economy and the consuming individual and family.  Includes instruction in consumption theory and practice, the production and distribution of retail goods and services, and the management of business enterprises.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0202',
    cipTitle: 'Family and Consumer Sciences/Human Sciences Communication',
    cipDefinition:
      'A program that focuses on communication of human sciences subject matter and related consumer information to a variety of audiences through print and non-print media.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0203',
    cipTitle: 'Consumer Merchandising/Retailing Management',
    cipDefinition:
      'A program that focuses on product and service promotion from the perspective of individual and family consumers and that prepares individuals to function as consumer management and marketing consultants in for-profit and non-profit enterprises.  Includes instruction in applied market research, consumer behavior, product sourcing and distribution, profitability, customer feedback and evaluation methods, promotion and retailing campaign development, and applications to specific domestic and international consumer markets.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0299',
    cipTitle: 'Family and Consumer Sciences/Human Sciences Business Services, Other',
    cipDefinition:
      'Any instructional program in family and consumer sciences/human sciences business services not listed above.',
  },
  {
    cipFamily: '19',
    cipCode: '19.04',
    cipTitle: 'Family and Consumer Economics and Related Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 19.0401 - 19.0499.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0401',
    cipTitle: 'Family Resource Management Studies, General',
    cipDefinition:
      'A general program that focuses on the design and implementation of policies and processes contributing to successful individual and family resource management.  Includes instruction in financial goal-setting and strategies; household income, assets, and debt management; preventing and resolving financial difficulties; and the use of relevant public resources.',
    CrossReferences: '52.0804 - Financial Planning and Services.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0402',
    cipTitle: 'Consumer Economics',
    cipDefinition:
      'A program that focuses on the application of micro- and macro-economic theory to consumer behavior and individual and family consumption of goods and services.  Includes instruction in modeling, economic forecasting, indexing, price theory, and analysis of individual commodities and services and/or groups of related commodities and services.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0403',
    cipTitle: 'Consumer Services and Advocacy',
    cipDefinition:
      'A program that focuses on providing end-use advice and representational services to individuals and groups within a variety of settings, and the analytical and research techniques needed to protect consumers from unsafe, unreliable, and/or unhealthy products and services.  Includes instruction in consumer advocacy, family management systems, ecological impacts of consumption practices, and analyzing and testing product quality and safety.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0499',
    cipTitle: 'Family and Consumer Economics and Related Services, Other',
    cipDefinition: 'Any instructional program in family and consumer economics and related services not listed above.',
  },
  {
    cipFamily: '19',
    cipCode: '19.05',
    cipTitle: 'Foods, Nutrition, and Related Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 19.0501 - 19.0599.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0501',
    cipTitle: 'Foods, Nutrition, and Wellness Studies, General',
    cipDefinition:
      'A general program that focuses on the role of foods and nutrition in human health and wellness.  Includes instruction in nutritional care and education, the planning and provision of food services, the development of consumable food products, life-span nutrition and wellness, the principles of nutritional assessment, and food safety and food composition.',
    CrossReferences: '1.1001 - Food Science.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0504',
    cipTitle: 'Human Nutrition',
    cipDefinition:
      'A program that focuses on the relationships between food consumption and human development and health.  Includes instruction in the cellular and molecular processes of food processing in the human body, related metabolic processes, the relationship of food and nutrition to disease, and nutritional needs across the life span.',
    CrossReferences: '51.3102 - Clinical Nutrition/Nutritionist., 30.1901 - Nutrition Sciences.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0505',
    cipTitle: 'Foodservice Systems Administration/Management',
    cipDefinition:
      'A program that focuses on the principles and practices relating to the administration of food service systems in institutional settings, and that prepares individuals to manage such operations in public and private facilities.  Includes instruction in human nutrition, food safety, the design and organization of food service systems, purchasing, personnel management, and related business practices.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0599',
    cipTitle: 'Foods, Nutrition, and Related Services, Other',
    cipDefinition: 'Any instructional program in foods, nutrition, and related services not listed above.',
    CrossReferences: '12.0509 - Culinary Science/Culinology.',
  },
  {
    cipFamily: '19',
    cipCode: '19.06',
    cipTitle: 'Housing and Human Environments',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 19.0601 - 19.0699.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0601',
    cipTitle: 'Housing and Human Environments, General',
    cipDefinition:
      'A general program that focuses on the behavioral, social, economic, functional, and aesthetic aspects of housing, interiors, and other built environments.  Includes instruction in analyzing, planning, designing, furnishing, and equipping residential, work, and leisure spaces to meet user needs and the study of related public policies.',
    CrossReferences: '4.0401 - Environmental Design/Architecture., 50.0408 - Interior Design.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0604',
    cipTitle: 'Facilities Planning and Management',
    cipDefinition:
      'A program that focuses on strategic workplace and facility planning and that prepares individuals to function as facility and event managers and workplace consultants.  Includes instruction in the principles of aesthetic and functional design, environmental psychology and organizational behavior, real estate planning, principles of occupational health and safety, event planning and management, operations management, and applicable regulatory and policy issues.',
    CrossReferences: '52.0907 - Meeting and Event Planning.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0605',
    cipTitle: 'Home Furnishings and Equipment Installers',
    cipDefinition:
      'A program that prepares individuals to assist in home furnishings and decorations. Includes instruction in selecting, purchasing, and designing home furnishings, decorations, and equipment; floral design; accessory construction; textiles; and upholstery.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0699',
    cipTitle: 'Housing and Human Environments, Other',
    cipDefinition: 'Any instructional program in housing and human environments not listed above.',
  },
  {
    cipFamily: '19',
    cipCode: '19.07',
    cipTitle: 'Human Development, Family Studies, and Related Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 19.0701 - 19.0799.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0701',
    cipTitle: 'Human Development and Family Studies, General',
    cipDefinition:
      'A general program that focuses on basic human developmental and behavioral characteristics of the individual within the context of the family.  Includes instruction in the conditions that influence human growth and development; strategies that promote growth and development across the life span; and the study of family systems.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0702',
    cipTitle: 'Adult Development and Aging',
    cipDefinition:
      'A program that focuses on the characteristics of aging populations and the needs of older individuals in family and institutional settings.  Includes instruction in the biological and psychological stages of aging; the provision of dependent care; serving the social, economic, and psychological needs of aging adults; related public policy issues; and adult community resources.',
    CrossReferences: '42.2809 - Geropsychology., 30.1101 - Gerontology.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0704',
    cipTitle: 'Family Systems',
    cipDefinition:
      'A program that focuses on the family as a social unit in its developmental, dynamic, comparative, and structural aspects, and the significance of the family as a system that impacts individuals and society.  Includes instruction in related principles of sociology, psychology, behavioral sciences, and the humanities.',
    CrossReferences: '42.2811 - Family Psychology., 51.1505 - Marriage and Family Therapy/Counseling.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0706',
    cipTitle: 'Child Development',
    cipDefinition:
      'A program that focuses on the intellectual, social, emotional, and biological development of children and the planning and design of related human services.  Includes instruction in parent-child relations, parenting practices, special needs of children, parental and environmental influences on child development, external support services, and related public policy issues.',
    CrossReferences:
      '42.2703 - Developmental and Child Psychology., 42.2710 - Developmental and Adolescent Psychology.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0707',
    cipTitle: 'Family and Community Services',
    cipDefinition:
      'A program that focuses on the development and implementation of public, private, and voluntary support services for individuals, families, and localities and that prepares individuals to function in a variety of occupations promoting family life, and family/community development.  Includes instruction in family systems, human development, social services, community social and economic development, social policy, voluntary sector activities, and outreach and community education.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0708',
    cipTitle: 'Child Care and Support Services Management',
    cipDefinition:
      "A program that focuses on the provision and management of child care services and that prepares individuals to plan, design, and manage child care facilities and programs that meet children's developmental needs and interests and that provide safe and healthy environments.  Includes instruction in child development and psychology; home- and institution-based child care; identification of diseases, injuries, and psychological trauma and applicable referrals; parent relations; personnel and business management principles; and related laws and policies.",
  },
  {
    cipFamily: '19',
    cipCode: '19.0709',
    cipTitle: 'Child Care Provider/Assistant',
    cipDefinition:
      'A program that prepares individuals to be primary providers of home, family, residential, or institutional-based child care services.   Includes instruction in child growth and development, nutrition, recreation, planning and supervision of play and learning activities, child abuse and neglect prevention, parent-child relationships, and applicable legal and administrative requirements.',
  },
  {
    cipFamily: '19',
    cipCode: '19.071',
    cipTitle: 'Developmental Services Worker',
    cipDefinition:
      'A program that prepares individuals to apply practical knowledge and skills to support individuals with a variety of physical, mental or developmental disabilities in a family or community setting. Includes instruction in health care, basic nutrition, pharmacology, nursing, autism, mental health, psychology, sociology, behavioral analysis, communication, intervention, counseling and community services, and developmental services, such as literacy and life skills training.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0711',
    cipTitle: 'Early Childhood and Family Studies',
    cipDefinition:
      'A program of study that focuses on the development and learning of children from birth to six years old within the context of the family. Includes instruction in child abuse and neglect, child and infant growth and development, early childhood education, early childhood language and literacy, early childhood math and science, early childhood special education, family literacy, family sociology, family and marriage relations, assessment and measurement, psychology, and psychopathology.',
    CrossReferences: '13.1210 - Early Childhood Education and Teaching., 42.2811 - Family Psychology.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0712',
    cipTitle: 'Parent Education Services',
    cipDefinition:
      'A program that prepares individuals to plan, coordinate, and teach parent education programs and services that address the cultural, emotional, intellectual, physical and social needs of children and parents. Includes instruction in child development, cultural diversity in schools, child behavior, family development, family-community partnerships, family dynamics, interpersonal relationships, parent-child relationships, and social services.',
    Examples: 'Examples: - Parent Education',
  },
  {
    cipFamily: '19',
    cipCode: '19.0799',
    cipTitle: 'Human Development, Family Studies, and Related Services, Other',
    cipDefinition:
      'Any instructional program in human development, family studies, and related services not listed above.',
  },
  {
    cipFamily: '19',
    cipCode: '19.09',
    cipTitle: 'Apparel and Textiles',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 19.0901 - 19.0999.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0901',
    cipTitle: 'Apparel and Textiles, General',
    cipDefinition:
      'A general program that focuses on the development of textile products and their distribution and use in terms of the psychological, social, economic, and physical needs of consumers.  Includes instruction in the production, distribution, marketing, and end use of various apparel and textile products.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0902',
    cipTitle: 'Apparel and Textile Manufacture',
    cipDefinition:
      'A program that focuses on the design, development, and production of textile products and related processes and systems.  Includes instruction in functional and aesthetic design, human factors research, production planning, manufacturing processes, quality assessment, and distribution systems.',
    CrossReferences: '50.0407 - Fashion/Apparel Design., 50.0712 - Fiber, Textile and Weaving Arts.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0904',
    cipTitle: 'Textile Science',
    cipDefinition:
      'A program that focuses on the properties and processing of fibers, yarns, whole fabrics, dyes, and finishes, both natural and synthetic.  Includes instruction in the chemical and physical properties of textile materials; end-use analysis; interior furnishing applications; and industrial applications.',
    CrossReferences: '14.2801 - Textile Sciences and Engineering.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0905',
    cipTitle: 'Apparel and Textile Marketing Management',
    cipDefinition:
      'A program that focuses on marketing research and management as applied to the products and services of the apparel and textile industries.  Includes instruction in applicable principles of textile and apparel design and manufacturing, sales and distribution systems, domestic and international market research, profitability, consumer research, and the design and implementation of marketing campaigns.',
    CrossReferences: '52.1902 - Fashion Merchandising.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0906',
    cipTitle: 'Fashion and Fabric Consultant',
    cipDefinition:
      'A program that prepares individuals to assist in apparel and fashion selection, style coordination, customer sales and consulting, fabric selection, clothing specifications, and contract buying activities. Includes instruction in supplying regular clothing needs or acting as a consultant for special events such as weddings.',
  },
  {
    cipFamily: '19',
    cipCode: '19.0999',
    cipTitle: 'Apparel and Textiles, Other',
    cipDefinition: 'Any instructional program in apparel and textiles not listed above.',
  },
  {
    cipFamily: '19',
    cipCode: '19.10',
    cipTitle: 'Work and Family Studies',
    cipDefinition: 'Instructional content is defined in code 19.1001.',
  },
  {
    cipFamily: '19',
    cipCode: '19.1001',
    cipTitle: 'Work and Family Studies',
    cipDefinition:
      'An introductory program that focuses on family and consumer science concepts and principles at the basic and vocational levels, and the various potential career paths open to interested students.  Includes instruction in career, community, and family connections; family and family/consumer resources; human development; interpersonal relationships; nutrition and wellness; parenting; and career possibilities in various related areas.',
  },
  {
    cipFamily: '19',
    cipCode: '19.99',
    cipTitle: 'Family and Consumer Sciences/Human Sciences, Other',
    cipDefinition: 'Instructional content is defined in code 19.9999.',
  },
  {
    cipFamily: '19',
    cipCode: '19.9999',
    cipTitle: 'Family and Consumer Sciences/Human Sciences, Other',
    cipDefinition: 'Any instructional program in family and consumer sciences/human sciences not listed above.',
  },
  {
    cipFamily: '21',
    cipCode: '21',
    cipTitle: 'RESERVED',
    cipDefinition: 'Reserved for use by Statistics Canada. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '21',
    cipCode: '21.01',
    cipTitle: 'Reserved',
    cipDefinition: 'Instructional content is defined in code 21.0101.',
  },
  {
    cipFamily: '21',
    cipCode: '21.0101',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '22',
    cipCode: '22',
    cipTitle: 'Legal Professions and Studies',
    cipDefinition:
      'Instructional programs that prepare individuals for the legal profession, for related support professions and professional legal research, and focus on the study of legal issues in non-professional programs.',
  },
  {
    cipFamily: '22',
    cipCode: '22.00',
    cipTitle: 'Non-Professional Legal Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 22.0000 - 22.0099.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0000',
    cipTitle: 'Legal Studies',
    cipDefinition:
      'A program of study that focuses on law and legal issues from the perspective of the social sciences and humanities.',
    Examples: 'Examples: - Juris Master (J.M.) Degree, - Master of Legal Studies',
  },
  {
    cipFamily: '22',
    cipCode: '22.0001',
    cipTitle: 'Pre-Law Studies',
    cipDefinition:
      'A program that prepares individuals for the professional study of law at the post-baccalaureate level.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0099',
    cipTitle: 'Non-Professional Legal Studies, Other',
    cipDefinition: 'Any program in non-professional legal studies not listed above.',
  },
  {
    cipFamily: '22',
    cipCode: '22.01',
    cipTitle: 'Law',
    cipDefinition: 'Instructional content is defined in code 22.0101.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0101',
    cipTitle: 'Law',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of law, for taking state and national bar examinations, and for advanced research in jurisprudence.  Includes instruction in the theory and practice of the legal system, including the statutory, administrative, and judicial components of civil and criminal law.',
    Examples: 'Examples: - Law (LL.B.), - Law (J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.02',
    cipTitle: 'Legal Research and Advanced Professional Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 22.0201 - 22.0299.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0201',
    cipTitle: 'Advanced Legal Research/Studies, General',
    cipDefinition:
      'An integrated or undifferentiated program in one or more of  the legal research or advanced practice fields.',
    Examples:
      'Examples: - Advanced Legal Research/Studies, General (LL.M., M.C.L., M.L.I., M.S.L.), - Advanced Legal Research/Studies, General (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0202',
    cipTitle: 'Programs for Foreign Lawyers',
    cipDefinition:
      'A program that prepares lawyers educated outside the United States to understand U. S. or Canadian law and jurisprudence.',
    Examples: 'Examples: - Programs for Foreign Lawyers (LL.M., M.C.L.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0203',
    cipTitle: 'American/U.S. Law/Legal Studies/Jurisprudence',
    cipDefinition:
      'An advanced, professional program of the U.S. legal system, Constitution law, and jurisprudence.  Includes instruction in legal history, legal sociology, philosophy of law, Constitutional law, legal procedure, and related topics.',
    Examples:
      'Examples: - American/U.S. Law/Legal Studies/Jurisprudence (LL.M., M.C.J.), - American/U.S. Law/Legal Studies/Jurisprudence (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0204',
    cipTitle: 'Canadian Law/Legal Studies/Jurisprudence',
    cipDefinition:
      'An advanced, professional study of the Canadian legal system, Constitution law, and jurisprudence.  Includes instruction in legal history, legal sociology, philosophy of law, Constitutional law, Commonwealth law, legal procedure, and related topics.',
    Examples:
      'Examples: - Canadian Law/Legal Studies/Jurisprudence (LL.M., M.C.J.), - Canadian Law/Legal Studies/Jurisprudence (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0205',
    cipTitle: 'Banking, Corporate, Finance, and Securities Law',
    cipDefinition:
      'An advanced, professional study of the law and process related to the regulation of businesses and the financial services industry.  Includes instruction in corporate law, antitrust law,  securities law and regulation, negotiation, incorporation, partnerships, trusts, and related topics',
    Examples:
      'Examples: - Banking, Corporate, Finance, and Securities Law (LL.M.), - Banking, Corporate, Finance, and Securities Law (J.S.D./S.J.D.), - Estate Planning Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.0206',
    cipTitle: 'Comparative Law',
    cipDefinition: 'An advanced, professional study of legal systems and philosophies in comparative perspective.',
    Examples: 'Examples: - Comparative Law (LL.M., M.C.L.), - Comparative Law (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0207',
    cipTitle: 'Energy, Environment, and Natural Resources Law',
    cipDefinition:
      'An advanced, professional study of  the law, policies, and regulations governing the energy industry, environmental protection, natural resources and land use, and related topics.',
    Examples:
      'Examples: - Energy, Environment, and Natural Resources Law (LL.M., M.S.), - Energy, Environment, and Natural Resources Law (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0208',
    cipTitle: 'Health Law',
    cipDefinition:
      'An advanced, professional study of the law, policies and regulations affecting the health care industry, health professions, health services and insurance industries, and patients.',
    Examples:
      'Examples: - Health Law (LL.M., M.J.), - Health Law (J.S.D./S.J.D.), - Healthcare Compliance Law, - Mental Disability Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.0209',
    cipTitle: 'International Law and Legal Studies',
    cipDefinition:
      'An advanced, professional study of the law affecting relations between nations, the behavior of international organizations, and the international activities of private citizens and organizations.',
    Examples:
      'Examples: - International Law and Legal Studies (LL.M.), - International Law and Legal Studies (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.021',
    cipTitle: 'International Business, Trade, and Tax Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations governing transnational business and commercial practices, including the specialized tax law related to international financial transactions.',
    Examples:
      'Examples: - International Business, Trade, and Tax Law (LL.M.), - International Business, Trade, and Tax Law (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0211',
    cipTitle: 'Tax Law/Taxation',
    cipDefinition:
      'An advanced, professional study of tax law and taxation procedures in U.S. or Canadian jurisdictions affecting individuals and corporations.',
    Examples: 'Examples: - Tax Law/Taxation (LL.M.), - Tax Law/Taxation (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0212',
    cipTitle: 'Intellectual Property Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations related to copyrights, patents, trademarks, trade secrets, and related issues. Includes instruction in patent law, copyright law, trademark law, intellectual property licensing and litigation, and applications such as art and entertainment law, communications law, information technology law, sports law, and chemical and biotech patent law.',
    Examples: 'Examples: - Intellectual Property Law ( LL.M.), - Intellectual Property Law (J.S.D./S.J.D.)',
  },
  {
    cipFamily: '22',
    cipCode: '22.0213',
    cipTitle: 'Patent Law',
    cipDefinition: 'An advanced, professional study of the law, policies, and regulations affecting patents.',
    Examples: 'Examples: - Patent Law, - Patent Prosecution',
  },
  {
    cipFamily: '22',
    cipCode: '22.0214',
    cipTitle: 'Agriculture Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations for lawyers and non-legal professionals in food and agriculture.',
    Examples: 'Examples: - Agriculture and Food Law, - Global Food Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.0215',
    cipTitle: 'Arts and Entertainment Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations affecting the sports, fashion, entertainment, arts, and media industries.',
    Examples:
      'Examples: - Entertainment, Arts, and Sports Law, - Fashion Law, - Gaming Law and Regulation, - Sports Law, - Title IX Compliance',
  },
  {
    cipFamily: '22',
    cipCode: '22.0216',
    cipTitle: 'Compliance Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations for lawyers and non-legal professionals in compliance, ethics, internal monitoring, regulatory affairs, and related areas.',
    Examples: 'Examples: - Corporate Compliance, - Enterprise Risk Management and Compliance Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.0217',
    cipTitle: 'Criminal Law and Procedure',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations affecting criminal law and trial procedures.',
    Examples: 'Examples: - Federal Criminal Practice and Procedure',
  },
  {
    cipFamily: '22',
    cipCode: '22.0218',
    cipTitle: 'Entrepreneurship Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations affecting entrepreneurship and innovation.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0219',
    cipTitle: 'Family/Child/Elder Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations affecting domestic relations across the lifespan.',
  },
  {
    cipFamily: '22',
    cipCode: '22.022',
    cipTitle: 'Human Resources Law',
    cipDefinition: 'An advanced, professional study of the law, policies, and regulations affecting human resources.',
    Examples: 'Examples: - Employee Benefits Law, - Labor and Employment Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.0221',
    cipTitle: 'Insurance Law',
    cipDefinition: 'An advanced, professional study of the law, policies, and regulations affecting insurance.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0222',
    cipTitle: 'Real Estate and Land Development Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations affecting real estate and land development.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0223',
    cipTitle: 'Transportation Law',
    cipDefinition:
      'An advanced, professional study of the law, policies, and regulations affecting land, sea, air, and space transportation.',
    Examples:
      'Examples: - Admiralty Law, - Marine Affairs Law, - Aviation Law, - Ocean and Coastal Law, - Air and Space Law, - Logistics and Transportation Law, - Space and Telecommunications Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.0224',
    cipTitle: 'Tribal/Indigenous Law',
    cipDefinition:
      "An advanced, professional study of the law, policies, and regulations affecting federal Indian law, tribal law and policy, and Indigenous peoples' human rights.",
    Examples:
      'Examples: - Tribal Policy, Law, and Government, - Indigenous Law, - Indigenous and Federal Aboriginal Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.0299',
    cipTitle: 'Legal Research and Advanced Professional Studies, Other',
    cipDefinition: 'Any program in legal research and advanced professional studies not listed above.',
    Examples:
      'Examples: - Animal Law, - Biotechnology and Genomics Law, - Cannabis Law, - Communications Law, - Cybersecurity and Information Privacy Law, - Economic Law, - Education Law',
  },
  {
    cipFamily: '22',
    cipCode: '22.03',
    cipTitle: 'Legal Support Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 22.0301 - 22.0399.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0301',
    cipTitle: 'Legal Administrative Assistant/Secretary',
    cipDefinition:
      'A program that prepares individuals to serve as legal office managers, special assistants, and legal secretaries.  Includes instruction in office management, secretarial science, principles of U.S. or Canadian law, legal terminology and documentation, legal research, legal software applications, law office procedures, record-keeping, billing, applicable policies and regulations, and professional standards and ethics.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0302',
    cipTitle: 'Legal Assistant/Paralegal',
    cipDefinition:
      'A program that prepares individuals to perform research, drafting, investigatory, record-keeping and related administrative functions under the supervision of an attorney or court.  Includes instruction in legal research, drafting legal documents, appraising, pleading, courthouse procedures, and legal specializations.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0303',
    cipTitle: 'Court Reporting and Captioning/Court Reporter',
    cipDefinition:
      'A program that prepares individuals to record and transcribe examinations, testimony, judicial orders and instructions, legal opinions, and other formal proceedings via print or electronic methods.  Includes instruction in legal terminology, legal transcription, shorthand, verbatim recording, equipment operation and procedures, applicable regulations, and professional standards and ethics.',
    CrossReferences:
      '51.0708 - Medical Transcription/Transcriptionist., 10.0204 - Voice Writing Technology/Technician.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0304',
    cipTitle: 'Court Interpreter',
    cipDefinition:
      'A program of study that prepares individuals to provide accurate translation and interpretation between two or more languages for parties involved in a legal proceeding. Includes instruction in courtroom standards and procedures, court interpreting, legal terminology, consecutive interpretation, simultaneous interpretation, and sight translation.',
  },
  {
    cipFamily: '22',
    cipCode: '22.0305',
    cipTitle: 'Scopist',
    cipDefinition:
      'An instructional program that prepares individuals to perform editing and word processing on a court reporter transcript to ensure the accuracy of the transcripts. Includes instruction in legal terminology, legal transcriptioning, machine shorthand, and computer-aided transcription software.',
    Examples: 'Examples: - Court Reporter Transcript Editor',
  },
  {
    cipFamily: '22',
    cipCode: '22.0399',
    cipTitle: 'Legal Support Services, Other',
    cipDefinition: 'Any program in legal support services not listed above.',
  },
  {
    cipFamily: '22',
    cipCode: '22.99',
    cipTitle: 'Legal Professions and Studies, Other',
    cipDefinition: 'Instructional content is defined in code 22.9999.',
  },
  {
    cipFamily: '22',
    cipCode: '22.9999',
    cipTitle: 'Legal Professions and Studies, Other',
    cipDefinition: 'Any program in law, legal services, and legal studies not listed above.',
  },
  {
    cipFamily: '23',
    cipCode: '23',
    cipTitle: 'English Language and Literature/Letters',
    cipDefinition:
      'Instructional programs that focus on the structure and use of the English language and dialects, speech, writing, and various aspects of the literatures and cultures of the English-speaking peoples.',
  },
  {
    cipFamily: '23',
    cipCode: '23.01',
    cipTitle: 'English Language and Literature, General',
    cipDefinition: 'Instructional content is defined in code 23.0101.',
  },
  {
    cipFamily: '23',
    cipCode: '23.0101',
    cipTitle: 'English Language and Literature, General',
    cipDefinition:
      'A general program that focuses on the English language, including its history, structure and related communications skills; and the literature and culture of English-speaking peoples.',
    CrossReferences: '16.1701 - English as a Second Language.',
  },
  {
    cipFamily: '23',
    cipCode: '23.13',
    cipTitle: 'Rhetoric and Composition/Writing Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 23.1301 - 23.1399.',
  },
  {
    cipFamily: '23',
    cipCode: '23.1301',
    cipTitle: 'Writing, General',
    cipDefinition:
      'A program that focuses on writing for applied and liberal arts purposes. Includes instruction in writing and document design in multiple genres, modes, and media; writing technologies; research, evaluation, and use of information; editing and publishing; theories and processes of composing; rhetorical theories, traditions, and analysis; communication across audiences, contexts, and cultures; and practical applications for professional, technical, organizational, academic, and public settings.',
    Examples: 'Examples: - English Composition, - Writing',
  },
  {
    cipFamily: '23',
    cipCode: '23.1302',
    cipTitle: 'Creative Writing',
    cipDefinition:
      'A program that focuses on the process and techniques of original composition in various literary forms such as the short story, poetry, the novel, and others.  Includes instruction in technical and editorial skills, criticism, and the marketing of finished manuscripts.',
    CrossReferences: '50.0504 - Playwriting and Screenwriting.',
  },
  {
    cipFamily: '23',
    cipCode: '23.1303',
    cipTitle: 'Professional, Technical, Business, and Scientific Writing',
    cipDefinition:
      'A program that focuses on professional, technical, business, and scientific writing; and that prepares individuals for academic positions or for professional careers as writers, editors, researchers, and related careers in business, government, non-profits, and the professions. Includes instruction in theories of rhetoric, writing, and digital literacy; document design, production, and management; visual rhetoric and multimedia composition; documentation development; usability testing; web writing; and publishing in print and electronic media.',
    CrossReferences: '9.0908 - Technical and Scientific Communication.',
    Examples:
      'Examples: - Biomedical Writing, - Medical Writing, - Professional, Technical, and Scientific Writing/Communication',
  },
  {
    cipFamily: '23',
    cipCode: '23.1304',
    cipTitle: 'Rhetoric and Composition',
    cipDefinition:
      'A program that focuses on the humanistic and scientific study of rhetoric, composition, literacy, and language/linguistic theories and their practical and pedagogical applications. Includes instruction in historical and contemporary rhetoric/composition theories; composition and criticism of written, visual, and mixed-media texts; analysis of literacy practices in cultural and cross-cultural contexts; and writing program administration.',
    CrossReferences: '9.0101 - Speech Communication and Rhetoric.',
    Examples: 'Examples: - Rhetoric and Writing, - Rhetoric and Writing Studies, - Rhetoric and Composition',
  },
  {
    cipFamily: '23',
    cipCode: '23.1399',
    cipTitle: 'Rhetoric and Composition/Writing Studies, Other',
    cipDefinition: 'Any instructional program in rhetoric and composition/writing studies not listed above.',
  },
  {
    cipFamily: '23',
    cipCode: '23.14',
    cipTitle: 'Literature',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 23.1401 - 23.1499.',
  },
  {
    cipFamily: '23',
    cipCode: '23.1401',
    cipTitle: 'General Literature',
    cipDefinition:
      'A program that focuses on literature from one or more genres, cultures or traditions. Includes instruction in period and genre studies, author studies, literary criticism, and studies of various types of literary text.',
    CrossReferences: '16.0104 - Comparative Literature.',
    Examples: 'Examples: - Literary Studies',
  },
  {
    cipFamily: '23',
    cipCode: '23.1402',
    cipTitle: 'American Literature (United States)',
    cipDefinition:
      'A program that focuses on the literature and literary development, both formal and folkloric, of the United States from the Colonial Era to the present.  Includes instruction in period and genre studies, author studies, literary criticism, and regional and oral traditions.',
  },
  {
    cipFamily: '23',
    cipCode: '23.1403',
    cipTitle: 'American Literature (Canadian)',
    cipDefinition:
      'A program that focuses on the literature and literary development, both formal and folkloric, of Canada from its origins to the present.  Includes instruction in period and genre studies, author studies, literary criticism, and regional and oral traditions.',
  },
  {
    cipFamily: '23',
    cipCode: '23.1404',
    cipTitle: 'English Literature (British and Commonwealth)',
    cipDefinition:
      'A program that focuses on the literatures and literary developments of the English-speaking peoples of the British Isles and the British Commonwealth, from the origins of the English language to the present.  Includes instruction in period and genre studies, author studies, country and regional specializations, literary criticism, and the study of folkloric traditions.',
  },
  {
    cipFamily: '23',
    cipCode: '23.1405',
    cipTitle: "Children's and Adolescent Literature.",
    cipDefinition:
      "A program that focuses on the scholarly study of children's and adolescent literature and that may enhance the professional work of teachers, librarians, publishers, booksellers, and creative writers.  Includes instruction in period and genre studies, author studies, literary criticism, studies of various types of literary text, book artists, and children's films.",
    CrossReferences: '25.0102 - Children and Youth Library Services.',
    Examples: 'Examples: - Literature for Children and Young Adults',
  },
  {
    cipFamily: '23',
    cipCode: '23.1499',
    cipTitle: 'Literature, Other',
    cipDefinition: 'Any instructional program in English language literature not listed above.',
    CrossReferences: '16.0104 - Comparative Literature.',
  },
  {
    cipFamily: '23',
    cipCode: '23.99',
    cipTitle: 'English Language and Literature/Letters, Other',
    cipDefinition: 'Instructional content is defined in code 23.9999.',
  },
  {
    cipFamily: '23',
    cipCode: '23.9999',
    cipTitle: 'English Language and Literature/Letters, Other',
    cipDefinition: 'Any instructional program in English language and literature not listed above.',
    Examples: 'Examples: - Writing and Literature',
  },
  {
    cipFamily: '24',
    cipCode: '24',
    cipTitle: 'Liberal Arts and Sciences, General Studies and Humanities',
    cipDefinition:
      'General instructional programs and independent or individualized studies in the liberal arts subjects, the humanities disciplines and the general curriculum.',
  },
  {
    cipFamily: '24',
    cipCode: '24.01',
    cipTitle: 'Liberal Arts and Sciences, General Studies and Humanities',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 24.0101 - 24.0199.',
  },
  {
    cipFamily: '24',
    cipCode: '24.0101',
    cipTitle: 'Liberal Arts and Sciences/Liberal Studies',
    cipDefinition:
      'A program that is a structured combination of the arts, biological and physical sciences, social sciences, and humanities, emphasizing breadth of study.  Includes instruction in independently designed, individualized, or regular programs.',
  },
  {
    cipFamily: '24',
    cipCode: '24.0102',
    cipTitle: 'General Studies',
    cipDefinition:
      'An undifferentiated program that includes instruction in the general arts, general science, or unstructured studies.',
    Examples: 'Examples: - Undeclared Major, - Undecided',
  },
  {
    cipFamily: '24',
    cipCode: '24.0103',
    cipTitle: 'Humanities/Humanistic Studies',
    cipDefinition:
      'A program that focuses on combined studies and research in the humanities subjects as distinguished from the social and physical sciences, emphasizing languages, literatures, art, music, philosophy and religion.',
  },
  {
    cipFamily: '24',
    cipCode: '24.0199',
    cipTitle: 'Liberal Arts and Sciences, General Studies and Humanities, Other',
    cipDefinition:
      'Any single instructional program in liberal arts and sciences, general studies and humanities not listed above.',
  },
  {
    cipFamily: '25',
    cipCode: '25',
    cipTitle: 'Library Science',
    cipDefinition:
      'Instructional programs that focus on the knowledge and skills required for managing and/or maintaining libraries and related information and record systems,  collections and facilities for research and general use.',
  },
  {
    cipFamily: '25',
    cipCode: '25.01',
    cipTitle: 'Library Science and Administration',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 25.0101 - 25.0199.',
  },
  {
    cipFamily: '25',
    cipCode: '25.0101',
    cipTitle: 'Library and Information Science',
    cipDefinition:
      'A program that focuses on the knowledge and skills required to develop, organize, store, retrieve, administer, and facilitate the use of local, remote, and networked collections of information in print, audiovisual, and electronic formats and that prepares individuals for professional service as librarians and information consultants.',
    CrossReferences:
      '13.1334 - School Librarian/School Library Media Specialist., 11.0401 - Information Science/Studies.',
    Examples: 'Examples: - Library Management',
  },
  {
    cipFamily: '25',
    cipCode: '25.0102',
    cipTitle: 'Children and Youth Library Services',
    cipDefinition:
      'A program that prepares individuals to provide library and information services and programming for children and young adults. Includes instruction in information needs of children and young adults, resources and services for children and young adults, literature for youth, storytelling, multi-ethnic materials, library management, and information technology for librarians.',
    CrossReferences: "23.1405 - Children's and Adolescent Literature.",
    Examples: "Examples: - Children's Library Services, - Youth Library Services, - Young Adult Library Services",
  },
  {
    cipFamily: '25',
    cipCode: '25.0103',
    cipTitle: 'Archives/Archival Administration',
    cipDefinition:
      'A program that prepares individuals to identify, manage, preserve, and make available records with long-term value for documentation, legal, research, and other purposes. Includes instruction in appraisal and collection development, information and records management, archival preservation, access systems, management of electronic records, archival outreach, and legal and ethical issues.',
    CrossReferences: '54.0105 - Public/Applied History.',
    Examples: 'Examples: - Archival Administration and Records Management, - Archival Studies, - Records Management',
  },
  {
    cipFamily: '25',
    cipCode: '25.0199',
    cipTitle: 'Library Science and Administration, Other',
    cipDefinition: 'Any instructional program in library science and administration not listed above.',
  },
  {
    cipFamily: '25',
    cipCode: '25.03',
    cipTitle: 'Library and Archives Assisting',
    cipDefinition: 'Instructional content is defined in code 25.0301.',
  },
  {
    cipFamily: '25',
    cipCode: '25.0301',
    cipTitle: 'Library and Archives Assisting',
    cipDefinition:
      'A program that prepares individuals to assist professional librarians and archivists. Includes instruction in principles, systems, processes, and procedures of library and archive operation; library resources and services; processes of acquisition, cataloging, storage, and display systems; discovery and retrieval of requested materials; and management of books, periodicals, and other documents and records.',
    Examples: 'Examples: - Library Assistant, - Library Technician, - Archives Assistant, - Archives Technician',
  },
  {
    cipFamily: '25',
    cipCode: '25.99',
    cipTitle: 'Library Science, Other',
    cipDefinition: 'Instructional content is defined in code 25.9999.',
  },
  {
    cipFamily: '25',
    cipCode: '25.9999',
    cipTitle: 'Library Science, Other',
    cipDefinition: 'Any instructional program in library science not listed above.',
  },
  {
    cipFamily: '26',
    cipCode: '26',
    cipTitle: 'Biological and Biomedical Sciences',
    cipDefinition:
      'Instructional programs that focus on the biological sciences and the non-clinical biomedical sciences, and that prepare individuals for research and professional careers as biologists and biomedical scientists.',
  },
  {
    cipFamily: '26',
    cipCode: '26.01',
    cipTitle: 'Biology, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0101 - 26.0102.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0101',
    cipTitle: 'Biology/Biological Sciences, General',
    cipDefinition:
      'A general program of biology at the introductory, basic level or a program in biology or the biological sciences that is undifferentiated as to title or content.  Includes instruction in general biology and programs covering a variety of biological specializations.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0102',
    cipTitle: 'Biomedical Sciences, General',
    cipDefinition:
      'A general, program that focuses on the integrative scientific study of biological issues related to health and medicine, or a program in one or more of the biomedical sciences that is undifferentiated as to title.  Includes instruction in any of the basic medical sciences at the research level; biological science research in biomedical faculties; and general studies encompassing a variety of the biomedical disciplines.',
  },
  {
    cipFamily: '26',
    cipCode: '26.02',
    cipTitle: 'Biochemistry, Biophysics and Molecular Biology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0202 - 26.0299.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0202',
    cipTitle: 'Biochemistry',
    cipDefinition:
      'A program that focuses on the scientific study of the chemistry of living systems, their fundamental chemical substances and reactions, and their chemical pathways and information transfer systems, with particular reference to carbohydrates, proteins, lipids, and nucleic acids.  Includes instruction in bio-organic chemistry, protein chemistry, bioanalytical chemistry, bioseparations, regulatory biochemistry, enzymology, hormonal chemistry, calorimetry, and research methods and equipment operation.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0203',
    cipTitle: 'Biophysics',
    cipDefinition:
      'A program that focuses on the application of physics principles to the scientific study of the mechanisms of biological processes and assemblies at all levels of complexity.  Includes instruction in research methods and equipment operation and applications to subjects such as bioenergetics, biophysical theory and modeling, electrophysics, membrane biology, channels, receptors and transporters, contractility and muscle function, protein shaping and folding, molecular and supramolecular structures and assemblies, and computational science.',
    CrossReferences: '51.2205 - Health/Medical  Physics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0204',
    cipTitle: 'Molecular Biology',
    cipDefinition:
      'A program that focuses on the scientific study of the structure and function of biological macromolecules and the role of molecular constituents and mechanisms in supramolecular assemblies and cells.  Includes instruction in such topics as molecular signaling and transduction, regulation of cell growth, enzyme substrates and mechanisms of enzyme action, DNA-protein interaction, and applications to fields such as biotechnology, genetics, cell biology, and physiology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0205',
    cipTitle: 'Molecular Biochemistry',
    cipDefinition:
      'A program that focuses on the scientific relationship of physiological function to the structure and actions of macromolecules and supramolecular assemblies such as multienzyme complexes, membranes, and viruses.  Includes instruction in the chemical mechanisms of regulation and catalysis, protein synthesis and other syntheses, and biomolecular chemical reactions.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0206',
    cipTitle: 'Molecular Biophysics',
    cipDefinition:
      'A scientific program that focuses on the dynamics and interactions of macromolecules and other three-dimensional ultrastructures, the architecture of supramolecular structures, and energy transfer in biomolecular systems.  Includes instruction in energy transduction, structural dynamics, mechanisms of electron and proton transfer in biological systems, bioinformatics, automated analysis, and specialized research techniques.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0207',
    cipTitle: 'Structural Biology',
    cipDefinition:
      'A program that focuses on the scientific study of submolecular and molecular components and assemblies of living systems and how they are organized into functional units such as cells and anatomic tissues.  Includes instruction in glycoprotein, carbohydrate, protein, and nucleic acid structures and chemistry; cytoskeletal structure; nuclear and intracellular structures; molecular recognition; molecular chaperones; transcription and folding; multicellular organization; microtubules and microfilaments; cell differentiation; immunophysics; and DNA sequencing.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0208',
    cipTitle: 'Photobiology',
    cipDefinition:
      'A program that focuses on the scientific study of the effects of light energy on living organisms, the manufacture and processing of luminescence by organisms, and the uses of light in biological research.  Includes instruction in bioluminescence, chronobiology, photomedicine, environmental photobiology, organic photochemistry, photomorphogenesis, photoreceptors and photosensitization, molecular mechanics of photosynthesis, phototechnology, vision, ultraviolet radiation, radiation physics, and spectral research methods.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0209',
    cipTitle: 'Radiation Biology/Radiobiology',
    cipDefinition:
      'A program that focuses on the  effects of radiation on organisms and biological systems.  Includes instruction in particle physics, ionization, and biophysics of radiation perturbations, cellular and organismic repair systems, genetic and pathological effects of radiation, and the measurement of radiation dosages.',
  },
  {
    cipFamily: '26',
    cipCode: '26.021',
    cipTitle: 'Biochemistry and Molecular Biology',
    cipDefinition:
      'A program of study that combines the biological sub-disciplines of biochemistry and molecular biology. Includes instruction in general biology, general and organic chemistry, physics, biochemistry, molecular biology, immunology, microbiology, genetics, and cellular biology.',
    CrossReferences: '26.0204 - Molecular Biology., 26.0202 - Biochemistry.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0299',
    cipTitle: 'Biochemistry, Biophysics and Molecular Biology, Other',
    cipDefinition: 'Any instructional program in biochemistry, biophysics and molecular biology not listed above.',
    Examples: 'Examples: - Comparative Biochemistry',
  },
  {
    cipFamily: '26',
    cipCode: '26.03',
    cipTitle: 'Botany/Plant Biology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0301 - 26.0399.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0301',
    cipTitle: 'Botany/Plant Biology',
    cipDefinition:
      'A program that focuses on the scientific study of plants, related microbial organisms, and plant habitats and ecosystem relations.  Includes instruction in plant anatomy and structure, phytochemistry, cytology, plant genetics, plant morphology and physiology, plant ecology, plant taxonomy and systematics, paleobotany, and applications of biophysics and molecular biology.',
    CrossReferences: '1.1101 - Plant Sciences, General.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0305',
    cipTitle: 'Plant Pathology/Phytopathology',
    cipDefinition:
      'A program that focuses on the scientific study of plant diseases and plant health, and the development of disease control mechanisms.  Includes instruction in plant anatomy and physiology, pathogenesis, molecular plant virology, molecular genetics, bacterial epidemiology, causal agent identification, host/agent interactions, disease resistance and response mechanisms, developing plant disease treatments, disease prevention, and disease physiology and control.',
    CrossReferences: '1.1101 - Plant Sciences, General.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0307',
    cipTitle: 'Plant Physiology',
    cipDefinition:
      'A program that focuses on the scientific study of plant internal dynamics and systems, plant-environment interaction, and plant life cycles and processes.  Includes instruction in cell and molecular biology; plant nutrition; plant respiration; plant growth, behavior, and reproduction; photosynthesis; plant systemics; and ecology.',
    CrossReferences: '1.1101 - Plant Sciences, General.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0308',
    cipTitle: 'Plant Molecular Biology',
    cipDefinition:
      'A program that focuses on the application of molecular biology, biochemistry, and biophysics to the study of biomolecular structures, functions, and processes specific to plants and plant substances.  Includes instruction in the biochemistry of plant cells, nuclear-cytoplasmic interactions, molecular cytostructures, photosynthesis, plant molecular genetics, and the molecular biology of plant diseases.',
    CrossReferences: '1.1101 - Plant Sciences, General.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0399',
    cipTitle: 'Botany/Plant Biology, Other',
    cipDefinition: 'Any instructional program in botany/plant biology not listed above.',
    Examples: 'Examples: - Phycology',
  },
  {
    cipFamily: '26',
    cipCode: '26.04',
    cipTitle: 'Cell/Cellular Biology and Anatomical Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0401 - 26.0499.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0401',
    cipTitle: 'Cell/Cellular Biology and Histology',
    cipDefinition:
      'A program that focuses on the scientific study of the structure, function, and regulation of cells as individual units and as components of larger systems.  Includes instruction in cell chemistry, cellular dynamics, cellular replication and reproduction, cell anatomy, membrane function, organelles, cell adhesion and extracellular matrices, cell dynamics and motility, meiosis and mytosis, signal transduction, regulation, recognition and defense mechanisms, the cell cycle, cell metabolism and respiration, gene expression, and studies of cell types and characteristics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0403',
    cipTitle: 'Anatomy',
    cipDefinition:
      'A program that focuses on the scientific study of organ systems, tissue structures, and whole bodies together with their cellular and structural components and dynamics.  Includes instruction in cell biology and histology, structural biology, molecular mechanics, regional and gross anatomy, embryology, neuroanatomy, endocrinology and secretory dynamics, and applications to such topics as aging and disease conditions.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0404',
    cipTitle: 'Developmental Biology and Embryology',
    cipDefinition:
      'A program that focuses on the scientific study of embryology, development, and growth of animals and human beings.  Includes instruction in fertilization, oogenesis, histogenesis, gastrulation, and cell differentiation; embryological development including organ and pattern formation, morphogenesis, gene regulation, cell lineage, and fate maps; disease and defect studies; transgenic and evolutionary models of growth and development; and applications to specific organisms and phyla.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0406',
    cipTitle: 'Cell/Cellular and Molecular Biology',
    cipDefinition:
      'An integrated, combined program that focuses on the scientific study of cells, cellular systems, and the molecular basis of cell structure and function.  Includes instruction in cell biology, cell chemistry, molecular biology, biophysics, and structural biology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0407',
    cipTitle: 'Cell Biology and Anatomy',
    cipDefinition:
      'An integrated, combined program that focuses on the scientific study of cell structure, function, and dynamics within the context of organismic, regional, and gross anatomical systems.  Includes instruction in molecular biology, cell biology and histology, structural biology, anatomy, embryology, endocrinology, and applications to specific systems, diseases, defects, and processes.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0499',
    cipTitle: 'Cell/Cellular Biology and Anatomical Sciences, Other',
    cipDefinition: 'Any instructional program in cell/cellular biology and anatomical sciences not listed above.',
    Examples: 'Examples: - Cell and Developmental Biology',
  },
  {
    cipFamily: '26',
    cipCode: '26.05',
    cipTitle: 'Microbiological Sciences and Immunology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0502 - 26.0599.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0502',
    cipTitle: 'Microbiology, General',
    cipDefinition:
      'A program that focuses on the scientific study of unicellular organisms and colonies, and subcellular genetic matter and their ecological interactions with human beings and other life.  Includes instruction in microbial genetics, cell biology, cell physiology, virology, pathogenic microbiology, environmental microbiology, immunology, biostatistics, bioinformatics, and laboratory methods including microscopy.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0503',
    cipTitle: 'Medical Microbiology and Bacteriology',
    cipDefinition:
      'A program that focuses on the scientific study of pathogenic bacteria that are significant factors in causing or facilitating human disease.  Includes instruction in the pathogenesis of bacterial diseases, bacterial genetics and physiology, bacterial anatomy and structure, antigens, bacterial reproduction, bacterial adhesion, phagocytes, and the identification of new or mutated bacteria and bacterial agents.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0504',
    cipTitle: 'Virology',
    cipDefinition:
      'A program that focuses on the scientific study of subcellular pieces of genetic material, called viruses, that inhabit living cells in parasitical relationships and their role in disease.  Includes instruction in virus taxonomy and systematics, viral structures, viral genetics, prions, virus/host cell interaction, viral pathogenesis, and applications to specific topics such as cancer biology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0505',
    cipTitle: 'Parasitology',
    cipDefinition:
      'A program that focuses on the scientific study of biological organisms living in ecologically exploitative and competitive relationships with host organisms, and the role of parasites in causing injury, disease, and environmental damage.  Includes instruction in vector biology, immunoparasitology, medical parasitology, molecular biology of parasitical associations, veterinary and comparative parasitology, chemotherapeutics, and ecological and systematic parasitology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0506',
    cipTitle: 'Mycology',
    cipDefinition:
      'A program that focuses on the scientific study of fungi, lichenous plants, eukaryotic microorganisms, myxomycetes, and plasmodiophorales and their relationship to diseases in higher plants, animals, and human beings as well as to pharmacologically useful products.  Includes instruction in cell and molecular biology; histopathology; fungal growth and behavior; environmental mycology; antifungal sensitivity; mycoses; pathogens and pathogenesis; pharmacological properties; and computer and laboratory research methods.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0507',
    cipTitle: 'Immunology',
    cipDefinition:
      'A program that focuses on scientific study of the biological mechanisms involved in the pathogenesis of disease, host-pathogen interactions, and host response to disease.  Includes instruction in antigen and antibody structure and function, effector mechanisms, receptors, histocompatibility, host-pathogen recognition, disease modeling, autoimmune systems, antibody formation, cytotoxic responses, regulation of immune response, virulence determinants, intercellular signaling, immunosuppression, immunotherapy, immunogenetics, disease markers, transplantation, antibody humanization, and microbial pathogenesis.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0508',
    cipTitle: 'Microbiology and Immunology',
    cipDefinition:
      'A program that focuses on the scientific study of the microorganisms that cause disease and the host immune response to them. Includes instruction in microbiology, microbial genetics, molecular virology, pathogenic bacteriology, disease mechanisms, immunology, immunogenetics, autoimmunity, and bioinformatics.',
    CrossReferences: '26.0502 - Microbiology, General., 26.0507 - Immunology.',
    Examples: 'Examples: - Medical Microbiology and Immunology',
  },
  {
    cipFamily: '26',
    cipCode: '26.0509',
    cipTitle: 'Infectious Disease and Global Health',
    cipDefinition:
      'A program that focuses on the biological, social, and behavioral factors contributing to the cause and spread of infectious diseases. Includes instruction in research design and evaluation, infectious disease epidemiology, international health policy and management, microbiology, microbial genetics, molecular virology, disease mechanisms, antimicrobial resistance, immunology, and bioterrorism.',
    CrossReferences: '51.2210 - International Public Health/International Health.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0599',
    cipTitle: 'Microbiological Sciences and Immunology, Other',
    cipDefinition: 'Any instructional program in the microbiological sciences and immunology not listed above.',
  },
  {
    cipFamily: '26',
    cipCode: '26.07',
    cipTitle: 'Zoology/Animal Biology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0701 - 26.0799.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0701',
    cipTitle: 'Zoology/Animal Biology',
    cipDefinition:
      'A general program that focuses on the scientific study of the biology of animal species and phyla, with reference to their molecular and cellular systems, anatomy, physiology, and behavior.  Includes instruction in molecular and cell biology, microbiology, anatomy and physiology, ecology and behavior, evolutionary biology, and applications to specific species and phyla.',
    CrossReferences: '1.0901 - Animal Sciences, General.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0702',
    cipTitle: 'Entomology',
    cipDefinition:
      'A program that focuses on the scientific study of insect species and populations in respect of their life cycles, morphology, genetics, physiology, ecology, taxonomy, population dynamics, and environmental and economic impacts.  Includes instruction in applicable biological and physical sciences as well as insect toxicology and the biochemical control of insect populations.',
    CrossReferences: '1.1105 - Plant Protection and Integrated Pest Management.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0707',
    cipTitle: 'Animal Physiology',
    cipDefinition:
      'A program that focuses on the scientific study of function, morphology, regulation, and intercellular communications and dynamics within vertebrate and invertebrate in animal species, with comparative applications to homo sapiens and its relatives and antecedents.  Includes instruction in reproduction, growth, lactation, digestion, performance, behavioral adaptation, sensory perception, motor action, phylogenetics, biotic and abiotic function, membrane biology, and related aspects of biochemistry and biophysics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0708',
    cipTitle: 'Animal Behavior and Ethology',
    cipDefinition:
      'A program that focuses on the scientific study of the psychological and neurological bases of animal sensation, perception, cognition, behavior, and behavioral interactions within and outside the species.  Includes instruction in ethology, behavioral neuroscience, neurobiology, behavioral evolution, cognition and sensory perception, motivators, learning and instinct, hormonal controls, reproductive and developmental biology, community ecology, functional behavior, and applications to specific behaviors and patterns as well as to specific phyla and species.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0709',
    cipTitle: 'Wildlife Biology',
    cipDefinition:
      'A program that focuses on the application of biological principles to the study of vertebrate wildlife, wildlife habitats, and related ecosystems in remote and urban areas.  Includes instruction in animal ecology; adaptational biology; urban ecosystems; natural and artificial habitat management; limnology; wildlife pathology; and vertebrate zoological specializations such as mammalogy, herpetology, ichthyology, ornithology, and others.',
    CrossReferences: '3.0601 - Wildlife, Fish and Wildlands Science and Management.',
    Examples: 'Examples: - Wildlife and Conservation Biology',
  },
  {
    cipFamily: '26',
    cipCode: '26.0799',
    cipTitle: 'Zoology/Animal Biology, Other',
    cipDefinition: 'Any instructional program in zoology/animal biology not listed above.',
  },
  {
    cipFamily: '26',
    cipCode: '26.08',
    cipTitle: 'Genetics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0801 - 26.0899.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0801',
    cipTitle: 'Genetics, General',
    cipDefinition:
      'A general program that focuses on the scientific study of the organization, recombination, function, regulation, and transmission of heritable information in biological organisms at all levels of complexity.  Includes instruction in Mendelian genetics, mechanisms of gene regulation, chromosome structure and replication, epigenetic phenomena, DNA repair and recombination, sex determination, genetic interactions between genomes, and molecular evolution.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0802',
    cipTitle: 'Molecular Genetics',
    cipDefinition:
      'A program that focuses on the scientific study of genetic structures and mechanisms of organisms, including bacteria, phages, and viruses.  Includes instruction in the replication, expression, transfer, structure, maintenance, and stability of heritable information in DNA, RNA, and proteins; the regulation of these processes; and neurogenetics.',
    Examples: 'Examples: - Neurogenetics, - Proteomics, - Protein Expression',
  },
  {
    cipFamily: '26',
    cipCode: '26.0803',
    cipTitle: 'Microbial and Eukaryotic Genetics',
    cipDefinition:
      'A program that focuses on the  scientific study of the genetics of viruses, infectious agents, organelles, nuclear genomes, eubacteria, archaebacteria, and eukaryotic organisms.  Includes instruction in molecular genetics and evolution, parasitic and symbiotic interaction at the genetic level, epigenetic phenomena, microbial interactions, and genomics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0804',
    cipTitle: 'Animal Genetics',
    cipDefinition:
      'A program that focuses on the scientific study of the genetics of multicellular animal life forms from the experimental, comparative, and clinical (veterinary and medical) viewpoints.  Includes instruction in molecular genetics, gene expression, gene regulation, genomics, epigenetic phenomena, DNA recombination and repair, genetic interactions at the microbial and higher levels, and molecular evolution.',
    CrossReferences: '1.0902 - Agricultural Animal Breeding.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0805',
    cipTitle: 'Plant Genetics',
    cipDefinition:
      'A program that focuses on the scientific study of the genetics of multicellular plants and fungi as related to botanical research as well as to applications in comparative genetics, ecology and evolutionary studies, clinical studies, and industrial research.  Includes instruction in molecular genetics, gene expression, gene regulation, genomics, epigenetic phenomena, DNA recombination and repair, genetic interactions at the microbial and higher levels, and molecular evolution.',
    CrossReferences: '1.1104 - Agricultural and Horticultural Plant Breeding.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0806',
    cipTitle: 'Human/Medical Genetics',
    cipDefinition:
      'A program that focuses on the scientific study of human genetics from the standpoint of medical applications such as clinical diagnosis, genetic engineering and therapy, transplantation, and the study of genetic diseases and disabilities and their defense.  Includes instruction in human molecular genetics; genetic factors causing disease; changes in gene expression during development, differentiation, and pathogenesis; recombinant DNA; gene therapy; clinical genetics; genetic epidemiology; immunogenetics; cytogenetics; and genetics of specific disorders and diseases.',
    CrossReferences:
      '51.0914 - Gene/Genetic Therapy., 51.1010 - Cytogenetics/Genetics/Clinical Genetics Technology/Technologist., 51.1509 - Genetic Counseling/Counselor.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0807',
    cipTitle: 'Genome Sciences/Genomics',
    cipDefinition:
      'A program that focuses on the scientific study of whole genome sequences and patterns of gene expression. Includes instruction in molecular and cellular biology, genetics, protein technologies, genomic sciences and techniques, bioinformatics, and scientific and research ethics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0899',
    cipTitle: 'Genetics, Other',
    cipDefinition: 'Any instructional program in genetics not listed above.',
  },
  {
    cipFamily: '26',
    cipCode: '26.09',
    cipTitle: 'Physiology, Pathology and Related Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.0901 - 26.0999.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0901',
    cipTitle: 'Physiology, General',
    cipDefinition:
      'A general program that focuses on the scientific study of the functional dynamics, morphology, and biochemical and biophysical communications within organisms and between living systems at all levels of complexity and integration.  Includes instruction in reproduction, growth, hormonal action, vascular function, respiration, digestion, sensory perception and processing, sensorimotor integration, signal encoding and conveyance, homeostasis, physical function and malfunction, evolutionary physiology, and disease processes.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0902',
    cipTitle: 'Molecular Physiology',
    cipDefinition:
      'A program that focuses on the scientific study of dynamic interactive processes and biochemical communications at the subcellular level.  Includes instruction in ion channels and transporters, molecular signaling pathways, endocrine control and regulation, genetic information transfer, homeostasis and molecular control systems, electrophysiology and sensory mechanisms, protein synthesis, and applicable research methods and technologies.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0903',
    cipTitle: 'Cell Physiology',
    cipDefinition:
      'A program that focuses on the scientific study of physiological processes operating within and among cells, and intracellular communication and behavior, in the context of larger systems and whole organisms.  Includes instruction in cell and molecular biology, molecular physiology, cell cycle control, signal transduction, protein structure, membrane biochemistry and structure, ion channel physics, cell respiration and digestion, secretory functions, cell adhesion and communication, information encoding and decoding, and the relation of cell physiology to tissue, organ, and organismic functioning.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0904',
    cipTitle: 'Endocrinology',
    cipDefinition:
      'A program that focuses on the scientific study of the composition, manufacture, and secretion of protein compounds by cells and glands and the role of endocrine substances in bodily processes.  Includes instruction in protein chemistry, protein secretion, membrane biogenesis and transfer methods, cellular communication, gene and cell regulation, cytochemistry, fractionation, radioautography, and applications such as neuroendocrinology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0905',
    cipTitle: 'Reproductive Biology',
    cipDefinition:
      'A program that focuses on the scientific study of reproductive processes and biogenesis in animals and human beings.  Includes instruction in reproductive ecology and behavior, reproductive system physiology, endocrinology, developmental biology, neuroendocrinology, evolution and types of reproductive systems, reproductive genetics, physiopathology of reproductive processes, and applications of molecular biology and biophysics to the study of reproductive physiology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0907',
    cipTitle: 'Cardiovascular Science',
    cipDefinition:
      'A program that focuses on the scientific study of the structure and functioning of the heart, vascular system, and blood in animals and human beings and the disorders and diseases associated with the cardiovascular system.  Includes instruction in cardiovascular physiology, blood physiology, vasculature, vascular metabolism, neural control of cardiovascular function, microvascular permeability and membrane transport, cardiac contraction mechanisms, homeostasis, and applications to topics such as arteriosclerosis, heart disease, diabetes, vascular remodeling, transplantation, transfusion, and pacemakers and artificial organs.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0908',
    cipTitle: 'Exercise Physiology and Kinesiology',
    cipDefinition:
      'A program that focuses on the scientific study of the physiological processes involved in physical or motor activity, including sensorimotor interactions, response mechanisms, and the effects of injury, disease, and disability.  Includes instruction in muscular and skeletal anatomy; molecular and cellular basis of muscle contraction;  fuel utilization; neurophysiology of motor mechanics; systemic physiological responses (respiration, blood flow, endocrine secretions, and others); fatigue and exhaustion; muscle and body training; physiology of specific exercises and activities; physiology of injury; and the effects of disabilities and disease.',
    CrossReferences: '51.0913 - Athletic Training/Trainer., 31.0505 - Exercise Science and Kinesiology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0909',
    cipTitle: 'Vision Science/Physiological Optics',
    cipDefinition:
      'A program that focuses on the scientific study of vision, visual processes, and related phenomena and clinical research and treatment modalities.  Includes instruction in ocular anatomy and physiology, microbiology of the eye, electrophysiology, neurophysiology, corneal physiology, photochemistry, psychophysics, visual biophysics and motor systems, sensory mechanisms and photoreception, eye circulation and metabolism, geometric and physical optics, ocular development across the life span, visual stimuli and perception, color vision, eye motility, biometrics and measurement techniques, visual pathology, and environmental issues.',
  },
  {
    cipFamily: '26',
    cipCode: '26.091',
    cipTitle: 'Pathology/Experimental Pathology',
    cipDefinition:
      'A program that focuses on the scientific study of the expression, initiation, maintenance and progression of tissue injury and disease, including death, and the relationship of pathogenesis to fundamental molecular and cellular mechanisms.  Includes instruction in immunology, microbiology, gene expression, inflammation, cell injury, apoptosis, immunopathology, molecular markers of disease and toxins, neoplasia, growth regulation, and organ- and system-specific investigations.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0911',
    cipTitle: 'Oncology and Cancer Biology',
    cipDefinition:
      'A program that focuses on the scientific study of carcinogens; the onset of malignancy in cells, tissues, blood, and organs; the genetics of cancer; the anatomy and physiology of cancer cells; and the study of cancer behaviors and treatments.  Includes instruction in gene expression; oncogenes and tumor suppressor genes; viral genes and cancer proliferation; regulation of signal transduction; cancer proteins; hormonal and growth factors in cancer cells; tumor promotion, progression, and metastasis; carcinogen receptors and metabolism; carcinogen ecology; immunological targeting; and studies of genetic, chemical, radiologic and other treatment therapies.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0912',
    cipTitle: 'Aerospace Physiology and Medicine',
    cipDefinition:
      'A program that focuses on the scientific study of the physiological processes involved in low and high-altitude flight and living in space and related low-pressure and low-gravity environments, including sensorimotor interactions, response mechanisms, and the effects of injury, disease, and disability.  Includes instruction in anatomy, molecular and cellular basis of muscle contraction,  fuel utilization, neurophysiology of motor mechanics, systemic physiological responses (respiration, blood flow, endocrine secretions, and others), fatigue and exhaustion, systemic environmental pathologies, muscle and body training, physiology of specific exercises and activities, physiology of injury, and the effects of disabilities and disease.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0913',
    cipTitle: 'Biomechanics',
    cipDefinition:
      'A program of study that focuses on the biological, anatomical, and mechanical structure and function of the mechanical aspects of biological systems. Includes courses in anatomy, physiology, kinesiology, mechanics, measurement and instrumentation, and research methods.',
    CrossReferences:
      '14.0501 - Bioengineering and Biomedical Engineering., 31.0505 - Exercise Science and Kinesiology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.0999',
    cipTitle: 'Physiology, Pathology, and Related Sciences, Other',
    cipDefinition: 'Any instructional program in physiology, pathology, and related sciences not listed above.',
  },
  {
    cipFamily: '26',
    cipCode: '26.10',
    cipTitle: 'Pharmacology and Toxicology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.1001 - 26.1099.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1001',
    cipTitle: 'Pharmacology',
    cipDefinition:
      'A program that focuses on the scientific study of drug interactions on biological systems and organisms and the sources, chemical properties, biological effects, and therapeutic uses of drugs.  Includes instruction in pharmacodynamics, pharmacokinetics, toxicology, drug therapeutics, drug action, bodily responses to drug events, biochemical proliferation and differentiation, apoptosis, cell biology, medicinal chemistry, and studies of specific drugs and drug interactions.',
    CrossReferences: '51.2003 - Pharmaceutics and Drug Design., 51.2010 - Pharmaceutical Sciences.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1002',
    cipTitle: 'Molecular Pharmacology',
    cipDefinition:
      'A program that focuses on the scientific study of the biochemical and biophysical characteristics of drugs at the molecular level and their interaction with, and effects on, biological macromolecules and cellular structures and processes.  Includes instruction in molecular biology and biophysics; pharmacology of signal transduction, transmitters, and protein synthesis and release; receptors, protein interaction and binding; drug discovery and recognition; molecular toxicology; drug design; pharmacodynamics; developmental genetics; and studies of therapeutic strategies.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1003',
    cipTitle: 'Neuropharmacology',
    cipDefinition:
      'A program that focuses on the scientific study of drugs that modify the function of the brain and central nervous system, the effects of such drugs on health, disease, perception, motor action, and behavior; and the development of countermeasures and treatment therapies.  Includes instruction in neuroanatomy, neurophysiology, behavioral neuroscience, neurobiochemistry, neuropathology, the mechanisms of brain function, medicinal chemistry, pharmaceutics, and studies of specific drugs and drug therapies.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1004',
    cipTitle: 'Toxicology',
    cipDefinition:
      'A program that focuses on the scientific study of poisons and other biohazards; their interactions with organisms and their food and respiratory systems; and their prevention, management, and counteraction.  Includes instruction in toxicological biochemistry, toxic agents and transporters, toxin fate, toxicokinetics and metabolism, toxin classification, molecular toxic mechanisms, extracellular matrices and cell function, bacterial pathogenesis and mutagenesis, pathophysiology and apoptosis, cell stress and injury, studies of specific toxins, and studies of specific organ systems and physiological functions in relation to toxicological problems.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1005',
    cipTitle: 'Molecular Toxicology',
    cipDefinition:
      'A program that focuses on the scientific study of the genetic determinants of susceptibility to external pollutants and poisons; the interaction of toxic agents with biological systems at the molecular and cellular levels; and the development of countermeasures and treatments.  Includes instruction in pharmacogenetics, biomolecular structure, gene expression and regulation, transgenic modeling, toxic events signaling, transcriptional activation, mutagenesis and carcinogenesis, pulmonary toxicology, xenobiotic metabolism, oxidative stress, risk assessment, molecular dosimetry, and studies of specific toxins and treatment therapies.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1006',
    cipTitle: 'Environmental Toxicology',
    cipDefinition:
      'A program that focuses on the scientific study of the health effects associated with exposure to toxic chemicals and systems occurring in the natural, work, and living environments; the management of environmental toxins and toxicity; and the development of protections for humans, animals, and plants.  Includes instruction in applied ecology; microbiology; toxin transport, fate, and degradation; dermal toxicology; reproductive and genetic toxicology; atmospheric and environmental chemistry; metals toxicology; environmental mutagens and biotransformation; nutrient interaction; chemical sensitivity, disorders, and disease; risk assessment; animal waste management; environmental health; and hazardous materials management.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1007',
    cipTitle: 'Pharmacology and Toxicology',
    cipDefinition:
      'A program with an integrated, combined approach to the study of pharmacological and toxicological issues in biology and the biomedical sciences.  Includes instruction in topics such as solvents, xenobiotic metabolism, chemical toxicity, neurotoxicology, immunopharmacology, biotransformation, tissue culture and in vitro studies, biomolecular analysis, bioactivation and inactivation, enzyme regulation, chemoprevention and chemotherapy, industrial  and chemical studies, radiation health, and bioinformatics.',
    CrossReferences: '26.1001 - Pharmacology., 26.1004 - Toxicology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1099',
    cipTitle: 'Pharmacology and Toxicology, Other',
    cipDefinition: 'Any instructional program in pharmacology and toxicology not listed above.',
  },
  {
    cipFamily: '26',
    cipCode: '26.11',
    cipTitle: 'Biomathematics, Bioinformatics, and Computational Biology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.1101 - 26.1199.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1101',
    cipTitle: 'Biometry/Biometrics',
    cipDefinition:
      'A program that focuses on the application of statistics and other computational methods to the study of problems in the biological sciences and related fields in agriculture and natural resources.  Includes instruction in computational biology, mathematical statistics, matrix algebra, applied calculus, experimental design, linear modeling, sampling theory, stochastic processes, spatial and temporal analysis, longitudinal analysis, sparse/unbalanced data and complex error, and applications to such topics as population genetics, animal breeding, forest genetics, population dynamics, wildlife biometry, ecology, and agricultural and natural resource management.',
    CrossReferences: '27.0306 - Mathematical Biology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1102',
    cipTitle: 'Biostatistics',
    cipDefinition:
      'A program that focuses on the application of descriptive and inferential statistics to biomedical research and clinical, public health, and industrial issues related to human populations.  Includes instruction in mathematical statistics, modeling, clinical trials methodology, disease and survival analysis, longitudinal analysis, missing data analysis, spatial analysis, computer tomography, biostatistics consulting, and applications to such topics as genetics, oncology, pharmacokinetics, physiology, neurobiology, and biophysics.',
    CrossReferences: '27.0501 - Statistics, General.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1103',
    cipTitle: 'Bioinformatics',
    cipDefinition:
      'A program that focuses on the application of computer-based technologies and services to biological, biomedical, and biotechnology research.  Includes instruction in algorithms, network architecture, principles of software design, human interface design, usability studies, search strategies, database management and data mining, digital image processing, computer graphics and animation, CAD, computer programming, and applications to experimental design and analysis and to specific quantitative, modeling, and analytical studies in the various biological specializations.',
    CrossReferences: '51.2706 - Medical Informatics., 11.0104 - Informatics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1104',
    cipTitle: 'Computational Biology',
    cipDefinition:
      'A program that focuses on computational theoretical approaches to understanding biological systems, including computational models of biological processes, computational management of large-scale projects, database development and data-algorithm development, and high-performance computing, as well as statistical and mathematical analyses.',
    Examples: 'Examples: - Computational and Systems Biology',
  },
  {
    cipFamily: '26',
    cipCode: '26.1199',
    cipTitle: 'Biomathematics, Bioinformatics, and Computational Biology, Other',
    cipDefinition:
      'Any instructional program in biomathematics, bioinformatics, and computational biology not listed above.',
    Examples: 'Examples: - Biomathematics',
  },
  {
    cipFamily: '26',
    cipCode: '26.12',
    cipTitle: 'Biotechnology',
    cipDefinition: 'Instructional content is defined in code 26.1201.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1201',
    cipTitle: 'Biotechnology',
    cipDefinition:
      'A program that focuses on the application of the biological sciences, biochemistry, and genetics to the preparation of new and enhanced agricultural, environmental, clinical, and industrial products, including the commercial exploitation of microbes, plants, and animals.  Includes instruction in bioinformatics, gene identification, phylogenetics and comparative genomics, bioinorganic chemistry, immunoassaying, DNA sequencing, xenotransplantation, genetic engineering, industrial microbiology, drug and biologic development, enzyme-based production processes, patent law, biotechnology management and marketing, applicable regulations, and biotechnology ethics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.13',
    cipTitle: 'Ecology, Evolution, Systematics, and Population Biology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.1301 - 26.1399.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1301',
    cipTitle: 'Ecology',
    cipDefinition:
      'A program that focuses on the scientific study of the relationships and interactions of small-scale biological systems, such as organisms, to each other, to complex and whole systems, and to the physical and other non-biological aspects of their environments.  Includes instruction in biogeochemistry; landscape and/or marine/aquatic dynamics; decomposition; global and regional elemental budgets; biotic and abiotic regulation of nutrient cycles; ecophysiology; ecosystem resilience, disturbance, and succession; community and habitat dynamics; organismal interactions (co-evolution, competition, predation); paleoecology; and evolutionary ecology.',
    Examples: 'Examples: - Ecosystem Science',
  },
  {
    cipFamily: '26',
    cipCode: '26.1302',
    cipTitle: 'Marine Biology and Biological Oceanography',
    cipDefinition:
      'A program that focuses on the scientific study of the ecology and behavior of microbes, plants, and animals inhabiting oceans, coastal waters, and saltwater wetlands and their interactions with the physical environment.  Includes instruction in chemical, physical, and geological oceanography; molecular, cellular, and biochemical studies; marine microbiology; marine botany; ichthyology; mammalogy; marine population dynamics and biodiversity; reproductive biology; studies of specific species, phyla, habitats, and ecosystems; marine paleocology and palentology; and applications to fields such as fisheries science and biotechnology.',
    CrossReferences:
      '40.0607 - Oceanography, Chemical and Physical., 3.0301 - Fishing and Fisheries Sciences and Management., 30.3201 - Marine Sciences.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1303',
    cipTitle: 'Evolutionary Biology',
    cipDefinition:
      'A program that focuses on the scientific study of the genetic, developmental, functional, and morphological patterns and processes, and theoretical principles; and the emergence and mutation of organisms over time.  Includes instruction in molecular and morphological systematics; genetics and development; evolutionary transformation; paleobiology and paleontology; morphogenesis; mutation; locomotor, biomechanical and craniodental form and function; evolutionary theory; and systematic biology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1304',
    cipTitle: 'Aquatic Biology/Limnology',
    cipDefinition:
      'A program that focuses on the scientific study of the ecology and behavior of microbes, plants, and animals inhabiting inland fresh waters such as lakes, ponds, rivers, creeks, estuaries, and wetlands.  Includes instruction in geology and hydrology; aquatic ecosystems; microbiology; mycology; botany; ichthyology; mammalogy; population biology and biodiversity; studies of specific species, phyla, and habitats; and applications to fields such as natural resources conservation, fisheries science, and biotechnology.',
    CrossReferences: '3.0205 - Water, Wetlands, and Marine Resources Management.',
    Examples: 'Examples: - Aquatic Ecology',
  },
  {
    cipFamily: '26',
    cipCode: '26.1305',
    cipTitle: 'Environmental Biology',
    cipDefinition:
      'A program that focuses on the scientific study of the origins, functions, relationships, interactions, and natural history of living populations, communities, species, and ecosystems in relation to dynamic environmental processes.  Includes instruction in biodiversity, molecular genetic and genomic evolution, mesoscale ecology, computational biology and modeling, conservation biology, local and global environmental change, and restoration ecology.',
    CrossReferences: '3.0104 - Environmental Science.',
    Examples: 'Examples: - Environmental Microbiology',
  },
  {
    cipFamily: '26',
    cipCode: '26.1306',
    cipTitle: 'Population Biology',
    cipDefinition:
      'A program that focuses on the scientific study of the natural history, life cycle behavior, and ecosystem dynamics of single species and multi-species communities, and the patterns and causes of diversity within and among such populations.  Includes instruction in biostatistics, population dynamics, population and quantitative genetics, RNA and DNA sequences, genomics, evolutionary ecology, natural adaptation and hybridization, geographic differentiation, life history and life cycle studies, and animal and plant demography.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1307',
    cipTitle: 'Conservation Biology',
    cipDefinition:
      'A program that focuses on the application of the biological sciences to the specific problems of biodiversity, species preservation, ecological sustainability, and habitat fragmentation in the face of advancing human social, economic, and industrial pressures.  Includes instruction in ecology, environmental science, biological systems, extinction theory, human-animal and human-plant interaction, ecosystem science and management, wetland conservation, field biology, forest and wildlife biology, and natural history.',
    CrossReferences: '3.0101 - Natural Resources/Conservation, General., 3.0104 - Environmental Science.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1308',
    cipTitle: 'Systematic Biology/Biological Systematics',
    cipDefinition:
      'A program that focuses on the theoretical and empirical study of the principles and processes underlying the origin and maintenance of biological taxonomic diversity; related biogeographical and evolutionary patterns; and studies of the origin, diversification, distribution, and extinction of species and lineages.  Includes instruction in phylogenetic analysis, structural development and molecular evolution, classification and taxonomic theory, biological nomenclature, taxonomic assignment, evolutionary theory, biological surveys and inventories, computer modeling, and database building.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1309',
    cipTitle: 'Epidemiology',
    cipDefinition:
      'A program that focuses on the scientific study of disease, disability, and trauma patterns within and across populations and the development of health management mechanisms to prevent and control disease outbreaks and injurious behaviors.  Includes instruction in biostatistics, biochemistry, molecular biology, immunology, disease and injury determinants, genetic disease and disability factors, behavioral studies, health services research, environmental disease and injury factors, and population studies.',
    CrossReferences: '51.2201 - Public Health, General.',
  },
  {
    cipFamily: '26',
    cipCode: '26.131',
    cipTitle: 'Ecology and Evolutionary Biology',
    cipDefinition:
      'A program that focuses on the scientific study of the relationships and interactions across levels of biological organization--genes and genomes, organisms, species, and ecosystems--and how these change over time, including the origins and history of species, the processes by which biodiversity has evolved, and the ecological context in which this evolution takes place. Includes instruction in animal, plant, population, functional, evolutionary, and ecosystem ecology; ecological and evolutionary genetics; molecular evolution; population and conservation biology; animal and plant diversity; biometry; and scientific and research ethics.',
    CrossReferences: '26.1301 - Ecology., 26.1303 - Evolutionary Biology.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1311',
    cipTitle: 'Epidemiology and Biostatistics',
    cipDefinition:
      'A program with a general synthesis of epidemiology and biostatistics or a specialization which draws from epidemiology and biostatistics. Includes instruction in biostatistics, disease and injury determinants, epidemiology, health services research, pathology, spatial analysis, and statistics.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1399',
    cipTitle: 'Ecology, Evolution, Systematics and Population Biology, Other',
    cipDefinition: 'Any instructional program in ecology, evolution, and systematics not listed above.',
  },
  {
    cipFamily: '26',
    cipCode: '26.14',
    cipTitle: 'Molecular Medicine',
    cipDefinition: 'Instructional content is defined in code 26.1401.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1401',
    cipTitle: 'Molecular Medicine',
    cipDefinition:
      'A program that focuses on the scientific study of the molecular and cellular basis of disease, and the application of this knowledge to the development of new clinical and diagnostic tools, medications, and therapies. Includes instruction in cell biology, molecular biology, physiology, pharmacology, biochemistry, genetics, pathology, molecular immunology, research and quantitative methods, and biomedical research computing.',
  },
  {
    cipFamily: '26',
    cipCode: '26.15',
    cipTitle: 'Neurobiology and Neurosciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 26.1501 - 26.1599.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1501',
    cipTitle: 'Neuroscience',
    cipDefinition:
      'A program that focuses on the interdisciplinary scientific study of the molecular, structural, physiologic, cognitive, and behavioral aspects of the brain and nervous system.  Includes instruction in molecular and cellular neuroscience, brain science, anatomy and physiology of the central nervous system, molecular and biochemical bases of information processing, behavioral neuroscience, biology of neuropsychiatric disorders, and applications to the clinical sciences and biomedical engineering.',
    Examples:
      'Examples: - Behavioral Neuroscience, - Cognitive Neuroscience, - Computational Neuroscience, - Developmental Neuroscience, - Molecular and Cellular Neuroscience, - Systems Neuroscience',
  },
  {
    cipFamily: '26',
    cipCode: '26.1502',
    cipTitle: 'Neuroanatomy',
    cipDefinition:
      'A program that focuses on the scientific study of the structure of the central and peripheral nervous system in vertebrates or the nervous system of invertebrates. Includes instruction in cellular and circuit anatomy, mapping of neuronal pathways, anatomical distribution and mapping of neuronal signaling molecules and their receptors, and the anatomical basis of central nervous system diseases and disorders.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1503',
    cipTitle: 'Neurobiology and Anatomy',
    cipDefinition:
      'A program that focuses on the scientific study of the structure and function of the central and peripheral nervous system in vertebrates and invertebrates. Includes instruction in molecular and cellular studies of neuronal and glial cells and circuits, neural transmitters and receptors, neuronal signaling processes, membrane and synapse structure and communication, autonomic function, nervous system circuitry and mapping, and anatomical and functional basis of central nervous system diseases and disorders.',
    Examples: 'Examples: - Neurobiology and Neurophysiology',
  },
  {
    cipFamily: '26',
    cipCode: '26.1504',
    cipTitle: 'Neurobiology and Behavior',
    cipDefinition:
      'A program that focuses on the scientific study of the structure and function of the central and peripheral nervous system in vertebrates and invertebrates as it relates to control of behavior. Includes instruction in neuronal systems and circuits, higher level studies of behavior and cognition, computational neurobiology, and behavioral manifestations of central nervous system diseases and disorders.',
    CrossReferences: '30.1701 - Behavioral Sciences., 30.1001 - Biopsychology., 42.2706 - Behavioral Neuroscience.',
  },
  {
    cipFamily: '26',
    cipCode: '26.1599',
    cipTitle: 'Neurobiology and Neurosciences, Other',
    cipDefinition: 'Any instructional program in neurobiology and neurosciences not listed above.',
    Examples: 'Examples: - Medical Neurobiology, - Computational Neurobiology',
  },
  {
    cipFamily: '26',
    cipCode: '26.99',
    cipTitle: 'Biological and Biomedical Sciences, Other',
    cipDefinition: 'Instructional content is defined in code 26.9999.',
  },
  {
    cipFamily: '26',
    cipCode: '26.9999',
    cipTitle: 'Biological and Biomedical Sciences, Other',
    cipDefinition: 'Any instructional program in the biological and biomedical sciences not listed above.',
  },
  {
    cipFamily: '27',
    cipCode: '27',
    cipTitle: 'Mathematics and Statistics',
    cipDefinition:
      'Instructional programs that focus on the systematic study of logical symbolic language and its applications.',
  },
  {
    cipFamily: '27',
    cipCode: '27.01',
    cipTitle: 'Mathematics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 27.0101 - 27.0199.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0101',
    cipTitle: 'Mathematics, General',
    cipDefinition:
      'A general program that focuses on the analysis of quantities, magnitudes, forms, and their relationships, using symbolic logic and language.  Includes instruction in algebra, calculus, functional analysis, geometry, number theory, logic, topology and other mathematical specializations.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0102',
    cipTitle: 'Algebra and Number Theory',
    cipDefinition:
      'A program that focuses on the expression of quantities and their relationships by means of symbols, vectors, matrices, and equations, and the properties of integers.  Includes instruction in algebraic structures, quadratic and automorphic forms, combinatorics, linear algebra, and algebraic geometry.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0103',
    cipTitle: 'Analysis and Functional Analysis',
    cipDefinition:
      'A program that focuses on the properties and behavior of equations, multivariate solutions, functions, and dynamic systems.  Includes instruction in differential equations, variation, approximations, complex variables, integrals, harmonic analysis and wavelet theory, dynamic systems, and applications to mathematical physics.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0104',
    cipTitle: 'Geometry/Geometric Analysis',
    cipDefinition:
      'A program that focuses on the properties, measurements, and relationships pertaining to points, lines, angles, surfaces, and solids.  Includes instruction in global analysis, differential geometry, Euclidian and Non-Euclidian geometry, set theory, manifolds, integral geometry, and applications to algebra and other topics.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0105',
    cipTitle: 'Topology and Foundations',
    cipDefinition:
      'A program that focuses on the properties of unaltered geometric configurations under conditions of continuous, multi-directional transformations.  Includes instruction in mathematical logic, proof theory, model theory, set theory, combinatorics, continua, homotopy, homology, links, and transformation actions.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0199',
    cipTitle: 'Mathematics, Other',
    cipDefinition: 'Any program in mathematics not listed above.',
  },
  {
    cipFamily: '27',
    cipCode: '27.03',
    cipTitle: 'Applied Mathematics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 27.0301 - 27.0399.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0301',
    cipTitle: 'Applied Mathematics, General',
    cipDefinition:
      'A program that focuses on the application of mathematics to the solution of functional problems in fields such as engineering and the applied sciences.  Includes instruction in natural phenomena modeling continuum mechanics, reaction-diffusion, wave propagation, dynamic systems, numerical analysis, controlled theory, asymptotic methods, variation, optimization theory, inverse problems, and applications to specific scientific and industrial topics.',
    Examples:
      'Examples: - Fundamental Analysis and Real Variables, - Mathematical Analysis, - Numerical Analysis/Methods/Computation',
  },
  {
    cipFamily: '27',
    cipCode: '27.0303',
    cipTitle: 'Computational Mathematics',
    cipDefinition:
      'A program that focuses on the application of mathematics to the theory, architecture, and design of computers, computational techniques, and algorithms.  Includes instruction in computer theory,  cybernetics, numerical analysis, algorithm development, binary structures, combinatorics, advanced statistics, and related topics.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0304',
    cipTitle: 'Computational and Applied Mathematics',
    cipDefinition:
      'A program that focuses on the application of a broad range of mathematical and computational methods to modeling, analysis, algorithm development, and simulation for the solution of complex scientific and engineering problems. Includes instruction in numerical analysis, discrete mathematics, operations research, optimization, differential equations, statistics, scientific computation, and applications to specific scientific and industrial topics.',
    CrossReferences: '27.0303 - Computational Mathematics., 27.0301 - Applied Mathematics, General.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0305',
    cipTitle: 'Financial Mathematics',
    cipDefinition:
      'A program that focuses on the application of mathematics and statistics to the finance industry, including the development, critique, and use of various financial models. Includes instruction in probability theory, statistical analysis, numerical methods, computation and simulation methods, stochastic processes, economics, and financial markets and applications.',
    Examples: 'Examples: - Mathematical Finance, - Quantitative Finance, - Quantitative and Computational Finance',
  },
  {
    cipFamily: '27',
    cipCode: '27.0306',
    cipTitle: 'Mathematical Biology',
    cipDefinition:
      'A program that focuses on the application of mathematics to genomics, molecular modeling, structural biology, ecology, evolutionary biology and systems of analysis of neurobiology, physiology, and metabolism.  Includes instruction in computational mathematics, chaos and nonlinear systems, perturbation methods, evolutionary biology, molecular biology, bioinformatics, and neurobiology.',
    CrossReferences: '26.1101 - Biometry/Biometrics.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0399',
    cipTitle: 'Applied Mathematics, Other',
    cipDefinition: 'Any instructional program in applied mathematics not listed above.',
    Examples: 'Examples: - Combinatorics and Optimization',
  },
  {
    cipFamily: '27',
    cipCode: '27.05',
    cipTitle: 'Statistics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 27.0501 - 27.0599.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0501',
    cipTitle: 'Statistics, General',
    cipDefinition:
      'A general program that focuses on the relationships between groups of measurements, and similarities and differences, using probability theory and techniques derived from it.  Includes instruction in the principles in probability theory, binomial distribution, regression analysis, standard deviation, stochastic processes, Monte Carlo method, Bayesian statistics, non-parametric statistics, sampling theory, and statistical techniques.',
    CrossReferences:
      '26.1102 - Biostatistics., 13.0603 - Educational Statistics and Research Methods., 45.0102 - Research Methodology and Quantitative Methods., 30.7102 - Business Analytics.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0502',
    cipTitle: 'Mathematical Statistics and Probability',
    cipDefinition:
      'A program that focuses on the mathematical theory underlying statistical methods and their use.  Includes instruction in probability theory parametric and non-parametric inference, sequential analysis, multivariate analysis, Bayesian analysis, experimental design, time series analysis, resampling, robust statistics, limit theory, infinite particle systems, stochastic processes, martingales, Markov processes, and Banach spaces.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0503',
    cipTitle: 'Mathematics and Statistics',
    cipDefinition:
      'A program with a general synthesis of mathematics and statistics or a specialization which draws from mathematics and statistics. Includes instruction in calculus, linear algebra, numerical analysis and partial differential equations, discrete mathematics, probability theory, statistics, computing, and other related topics.',
    CrossReferences: '27.0101 - Mathematics, General., 27.0501 - Statistics, General.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0599',
    cipTitle: 'Statistics, Other',
    cipDefinition: 'Any instructional program in statistics not listed above.',
  },
  {
    cipFamily: '27',
    cipCode: '27.06',
    cipTitle: 'Applied Statistics',
    cipDefinition: 'Instructional content is defined in code 27.0601.',
  },
  {
    cipFamily: '27',
    cipCode: '27.0601',
    cipTitle: 'Applied Statistics, General',
    cipDefinition:
      'A program that focuses on the application of statistics to the solution of functional problems in fields such as business, engineering, medicine, and the applied sciences. Includes instruction in the principles in inference, probability theory, regression analysis, descriptive statistics, stochastic processes, Monte Carlo method, Bayesian statistics, non-parametric statistics, sampling theory, statistical computing, and statistical techniques.',
  },
  {
    cipFamily: '27',
    cipCode: '27.99',
    cipTitle: 'Mathematics and Statistics, Other',
    cipDefinition: 'Instructional content is defined in code 27.9999.',
  },
  {
    cipFamily: '27',
    cipCode: '27.9999',
    cipTitle: 'Mathematics and Statistics, Other',
    cipDefinition: 'Any instructional program in mathematics and  statistics not listed above.',
  },
  {
    cipFamily: '28',
    cipCode: '28',
    cipTitle: 'Military Science, Leadership and Operational Art',
    cipDefinition:
      'Instructional programs that provide professional education and training of military officers in leadership, military science and operational studies, security policy and strategy, military economics and management, and pre-officer training. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.01',
    cipTitle: 'Air Force ROTC, Air Science and Operations',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 28.0101 - 28.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0101',
    cipTitle: 'Air Force JROTC/ROTC',
    cipDefinition:
      'A program that introduces students to the theory and practice of air science, life in the U.S. Air Force, and prepares them for cadet status (Junior ROTC or JROTC) or for service as commissioned reserve or active duty officers (senior AFROTC or ROTC).  Programs are offered as adjuncts to regular high school or college instructional programs. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0199',
    cipTitle: 'Air Force ROTC, Air Science and Operations, Other',
    cipDefinition:
      'Any instructional program in Air Force ROTC, air science and operations not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.03',
    cipTitle: 'Army ROTC, Military Science and Operations',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 28.0301 - 28.0399. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0301',
    cipTitle: 'Army JROTC/ROTC',
    cipDefinition:
      'A program that introduces students to the theory and practice of military science, life in the U.S. Army, and prepares them for cadet status (Junior ROTC or JROTC) or for service as commissioned reserve or active duty officers (senior AROTC or ROTC).  Programs are offered as adjuncts to regular high school or college instructional programs. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0399',
    cipTitle: 'Army ROTC, Military Science and Operations, Other',
    cipDefinition:
      'Any instructional program in Army ROTC, military science and operations not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.04',
    cipTitle: 'Navy/Marine ROTC, Naval Science and Operations',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 28.0401 - 28.0499. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0401',
    cipTitle: 'Navy/Marine Corps JROTC/ROTC',
    cipDefinition:
      'A program that introduces students to the theory and practice of naval science, life in the U.S. Navy and/or Marine Corps, and prepares them for cadet status (Junior ROTC or JROTC) or for service as commissioned reserve or active duty officers (senior NROTC or ROTC).  Programs are offered as adjuncts to regular high school or college instructional programs. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0499',
    cipTitle: 'Navy/Marine Corps ROTC, Naval Science and Operations, Other',
    cipDefinition:
      'Any instructional program in Navy/Marine Corps ROTC, naval science and operations not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.05',
    cipTitle: 'Military Science and Operational Studies',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 28.0501 - 28.0599. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0501',
    cipTitle: 'Air Science/Airpower Studies',
    cipDefinition:
      'A program that focuses on the theory, doctrine and principles of airpower in the tactical and strategic contexts, as well as in conjunction with other armed forces.  Includes instruction in air warfighting, leadership and ethics, national security decisionmaking, strategic studies, global security, joint and coalition operations, and applicable regional or cultural studies and research methods. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Strategic Airpower Studies',
  },
  {
    cipFamily: '28',
    cipCode: '28.0502',
    cipTitle: 'Air and Space Operational Art and Science',
    cipDefinition:
      'A program that focuses on the principles and art of leading, managing, and conducting air force operations on the ground, in the air, and in space.  Includes instruction in leadership, staff operations, principles of command, air and space warfare, expeditionary operations, campaign planning, tactics and strategy. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0503',
    cipTitle: 'Military Operational Art and Science/Studies',
    cipDefinition:
      'A program that focuses on the study of military operational issues and the preparation of field officers for warfighting, leadership, and related capabilities in land and joint operations.  Includes instruction in leadership studies, military culture, national security policy, joint campaign planning, strategic estimation, strategic logistics, tactical and strategic doctrine, force capabilities, command and control, intelligence, movement, firepower, attack and defense, multinational issues, space operations, and applications to specific regional and operational issues. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0504',
    cipTitle: 'Advanced Military and Operational Studies',
    cipDefinition:
      'A program that focuses on military operational issues at the theatre and general strategic level, emphasizing both land force operations and joint operations related to peacekeeping, war, and other emergencies. Includes instruction in military theory, military history, national and military strategy, campaign design and planning, senior leadership, theatre operational technologies and communications, civil-military relations, and applications to the study of specific world regions and types of operations. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0505',
    cipTitle: 'Naval Science and Operational Studies',
    cipDefinition:
      'A program that focuses on in-depth professional study of naval warfare and related combined/joint operations at the tactical, operational and strategic levels.  Includes instruction in maritime security, naval warfighting, naval tactics, naval strategy, operational art, planning and conducting joint operations, command and control, civil-military relations, information networking, leadership, ocean and nearshore operations, and related subjects. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0506',
    cipTitle: 'Special, Irregular and Counterterrorist Operations',
    cipDefinition:
      'A program that focuses on the role and use of special operations forces in the context of low-intensity warfare such as asymmetric warfare and counterterrorism as well as irregular operations in conventional warfare.  Includes instruction in special operations doctrine, equipment, support and strategy; the role of civilian leadership and the joint staffs; mission selection and employment; integration with regular forces; civil relations and psychological warfare. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Special Operations, - Low-Intensity Warfare, - Irregular and Guerilla Warfare, - Terrorism and Counterterorrism',
  },
  {
    cipFamily: '28',
    cipCode: '28.0599',
    cipTitle: 'Military Science and Operational Studies, Other',
    cipDefinition:
      'Any instructional program in military science and operational studies not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.06',
    cipTitle: 'Security Policy and Strategy',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 28.0601 - 28.0699. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0601',
    cipTitle: 'Strategic Studies, General',
    cipDefinition:
      'A program that focuses on coercion theory, defense strategy, warfighting, and strategic operations in the context of joint or combined operations, coalitions, and overall national security.  May be offered at the advanced level and in the context of one or more armed forces, and may focus on various scenarios including campaign, theatre, joint, global and advanced strategy. This CIP code is not valid for IPEDS reporting.',
    CrossReferences: '45.0902 - National Security Policy Studies.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0602',
    cipTitle: 'Military and Strategic Leadership',
    cipDefinition:
      'A program that focuses on the analysis and systematic study of the intersection between leadership and operational art at the tactical and/or strategic levels.  Includes instruction in leadership case studies, the dynamics of battle and associated  responsibilities, the use of opportunities and resources, the human and physical dimensions of warfare, the effects of technology and logistics, doctrine, logistics, stress and the legal authority of command. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0603',
    cipTitle: 'Military and International Operational Law',
    cipDefinition:
      'A program that focuses on the international and domestic law applicable to the armed forces, in war and peace.  Includes instruction in the Uniform Code of Military Justice, case law and legislation, international law and treaties, warfighting doctrine, leadership and ethics, peacekeeping, and related issues affecting combatants, prisoners, and noncombatants. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0604',
    cipTitle: 'Joint Operations Planning and Strategy',
    cipDefinition:
      'A program that focuses on the principles, operational methods and strategy of planning and conducting joint military operations.  Includes instruction in civil-military affairs, effects-based operations, interservice doctrine, force integration and force multipliers, multinational forces, and applications specific to air, army, military, special forces and asymmetric contexts. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Force Doctrine',
  },
  {
    cipFamily: '28',
    cipCode: '28.0605',
    cipTitle: 'Weapons of Mass Destruction',
    cipDefinition:
      'A program that focuses on the scientific principles, technology, policy issues and operational art required to prevent the spread and combat the effects of weapons of mass destruction, defined as chemical, biological and nuclear devices as well as some conventional weapons.  Includes instruction in the scientific foundations of such weaponry, production, weaponization, effects protection and mitigation, state and terrorist threat analysis, and applications to specific weapon types and scenarios. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Nuclear Weapons and Counterproliferation, - Combating Weapons of Mass Destruction',
  },
  {
    cipFamily: '28',
    cipCode: '28.0699',
    cipTitle: 'National Security Policy and Strategy, Other',
    cipDefinition:
      'Any instructional program in national security policy and strategy not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.07',
    cipTitle: 'Military Economics and Management',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 28.0701 - 28.0799. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0701',
    cipTitle: 'National Resource Strategy and Policy',
    cipDefinition:
      'A program that focuses on the application of economics, policy analysis, management and leadership studies to the formulation of national strategy and security policy in the context of the international economy.  Includes instruction in military strategy and logistics, strategic leadership, national and international economic policy, national security policy, acquisition and procurement, industry economics and studies of specific regions, industries or issues. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0702',
    cipTitle: 'Industry Studies',
    cipDefinition:
      'A program that focuses on the study of specific industries and economic sectors from domestic and comparative perspectives in order to assess strength, weakness and relationship to national security needs.  Includes instruction in resource economics, finance and monetary policy, industrial economics, econometrics and forecasting, labor and organizational studies, technology policy, national security policy, military strategy, acquisition needs assessment, crisis management, international economics and development studies. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0703',
    cipTitle: 'Military Installation Management',
    cipDefinition:
      'A program that focuses on the planning, acquisition and management of military bases, housing, installations and other facilities plus their associated buildings, systems, equipment, staffing and maintenance.  Includes instruction in operations management, acquisitions planning, contracts management, building construction, real estate, facilities management, hospitality management, administrative budgeting and finance, personnel supervision and leadership, related regulations and policies, and applications to specific service requirements, operational needs, and types of facilities. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0799',
    cipTitle: 'Military Economics and Management, Other',
    cipDefinition:
      'Any instructional program in military economics and management not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.08',
    cipTitle: 'Reserved',
    cipDefinition:
      'Instructional content is defined in code 28.0801. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.0801',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.99',
    cipTitle: 'Military Science, Leadership and Operational Art, Other',
    cipDefinition:
      'Instructional content is defined in code 28.9999. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '28',
    cipCode: '28.9999',
    cipTitle: 'Military Science, Leadership and Operational Art, Other',
    cipDefinition:
      'Any instructional program in military science, leadership and operational art not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '29',
    cipCode: '29',
    cipTitle: 'MILITARY TECHNOLOGIES AND APPLIED SCIENCES',
    cipDefinition:
      'Instructional programs that prepare individuals in specialized and advanced subject matter for the armed services and related national security organizations, including intelligence operations, military applied sciences, and military technologies.',
  },
  {
    cipFamily: '29',
    cipCode: '29.02',
    cipTitle: 'Intelligence, Command Control and Information Operations',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 29.0201 - 29.0299.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0201',
    cipTitle: 'Intelligence, General',
    cipDefinition:
      'A program that focuses on the principles and techniques of intelligence acquisition, analysis and exploitation.  Includes instruction in intelligence organizations, the intelligence cycle, intelligence operations planning, intelligence analysis and reporting, intelligence methods, electronic and signals intelligence, operations and communications security, human intelligence management, intelligence chain of command, information exploitation and psychological warfare, and the relationship to national security policy and strategy.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0202',
    cipTitle: 'Strategic Intelligence',
    cipDefinition:
      'A program that focuses on the management, analysis and use of national-level, theatre-level, and international intelligence and related policy issues.  Includes instruction in intelligence organization and management, strategic uses of intelligence, management of intelligence systems and assets, command and control, joint intelligence operations, electronic and signals intelligence, threat assessment and estimation, intelligence analysis and reporting, counterintelligence, and studies of specific regions and situations.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0203',
    cipTitle: 'Signal/Geospatial Intelligence',
    cipDefinition:
      'A program that focuses on the theory, technology and operational aspects of collecting, processing and exploiting remote-sensed, radar, acoustic and other forms of signals intelligence.  Includes instruction in applied physics, wave propagation and capture, radar systems, acoustics and underwater systems, infrared systems, synthetic aperture systems, collection and processing systems, signal phenomenology, signal analysis and exploitation, and applications to specific intelligence problems.',
    Examples: 'Examples: - Advanced Geospatial Intelligence',
  },
  {
    cipFamily: '29',
    cipCode: '29.0204',
    cipTitle: 'Command & Control (C3, C4I) Systems and Operations',
    cipDefinition:
      'A program that focuses on the theory, technology and operational use of information and decision systems in support of battlefield, theatre, and global strategic operations.  Includes instruction in applied mathematics and statistics, computer systems, real-time analysis and decision systems, surveillance and navigation systems, information and communications technology, information security, situational awareness, system integration, joint operations and applications to specific command problems and services.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0205',
    cipTitle: 'Information Operations/Joint Information Operations',
    cipDefinition:
      'A program that focuses on the strategic and operational use of information relative to the support of military and strategic policy and objectives.  Includes instruction in information technology, decision theory and applications, military operations, command and control technology, network operations, network systems integration, computer network defense, space communications technology, and applications to specific military operational tasks.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0206',
    cipTitle: 'Information/Psychological Warfare and Military Media Relations',
    cipDefinition:
      'A program that focuses on the support of military and strategic operations and policy via the use of information as a tool of statecraft and warfighting.  Includes instruction in information technology and systems, information security, command and control, satellite communications, global information dissemination, communications and media management, intelligence, psychological warfare, strategic planning, security policy and doctrine, and applications to specific operations, services, and scenarios.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0207',
    cipTitle: 'Cyber/Electronic Operations and Warfare',
    cipDefinition:
      'A program that focuses on the technological and operation aspects of information warfare, including cyber attack and cyber defense.  Includes instruction in computer and network security, cryptography, computer forensics, systems security engineering, software applications, threat and vulnerability assessment, wireless networks and satellite communications, tactical and strategic planning, legal and ethical issues, and cyber warfare systems development and acquisition.',
    Examples: 'Examples: - Cyberspace Operations, - Electronic Warfare, - Information Warfare',
  },
  {
    cipFamily: '29',
    cipCode: '29.0299',
    cipTitle: 'Intelligence, Command Control and Information Operations, Other',
    cipDefinition:
      'Any instructional program in intelligence, command control and information operations not listed above.',
  },
  {
    cipFamily: '29',
    cipCode: '29.03',
    cipTitle: 'Military Applied Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 29.0301 - 29.0399.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0301',
    cipTitle: 'Combat Systems Engineering',
    cipDefinition:
      'A program that focuses on the application of systems engineering and system architecture to the design and construction of modern combat systems and their integration with each other, with host platforms and with other forces into network-centric warfighting systems.  Includes instruction in systems engineering, computer programming, chemical systems, biological systems, material systems, human factors, combat environments, sensor systems, threat and system risk assessment, conventional and unconventional weapons, combat simulation, reliability and maintenance, testing, engineering project management, strategic planning and applications to aerospace, ground, and naval combat systems.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0302',
    cipTitle: 'Directed Energy Systems',
    cipDefinition:
      'A program that focuses on the study of lasers and other directed energy phenomena and their application to the creation of energy beam weapons systems.  Includes instruction in photonics, electro-optics, microwave phenomena, laser chemistry, spectroscopy, chemical lasers, fibre lasers, imaging and optics, laser propagation and control, laser communication, infrared remote sensing, modeling and simulation, and related technologies.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0303',
    cipTitle: 'Engineering Acoustics',
    cipDefinition:
      'A program that focuses on the application of acoustics and signals processing to undersea and antisubmarine warfare.  Includes instruction in acoustics; electrical engineering; the generation, propagation and reception of underwater sound waves; military applications of underwater sound; and acoustic signal processing.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0304',
    cipTitle: 'Low-Observables and Stealth Technology',
    cipDefinition:
      'A program that focuses on the application of electromagnetic field theory, electro-optics and materials science to the reduction of radar, optical and acoustic signatures of weapons systems.  Includes instruction in computational electromagnetics, electro-optics, acoustics, guided wave theory, radiation capture, antenna applications in layered environments, material characterization, radar cross-section analysis, sonar signature analysis, non-destructive testing, remote sensing, and applications to specific weapons systems and operational environments.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0305',
    cipTitle: 'Space Systems Operations',
    cipDefinition:
      'A program that focuses on the design, development, and operation of missiles, satellites and other space-based systems for military purposes.  Includes instruction in the military applications of space, space technology, aerospace engineering, systems architecture, orbital mechanics, launch and retrieval systems, ground support systems, satellite communications, and space-based sensor systems.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0306',
    cipTitle: 'Operational Oceanography',
    cipDefinition:
      'A program that focuses on the study of physical oceanography as applied to the naval tactical and strategic environment and the support of military operations.  Includes instruction in atmospheric thermodynamics and radiation propagation, air-ocean fluid dynamics, ocean waves, nearshore environments and processes, ocean acoustics, ocean analysis, tactical oceanography, prediction, and related quantitative and experimental methods.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0307',
    cipTitle: 'Undersea Warfare',
    cipDefinition:
      'A program that focuses on the principles, engineering design and military concepts that govern the operational employment of underwater sensors and weapons systems.  Includes instruction in acoustics, electrical, and mechanical engineering; mathematics; meteorology; oceanography; physics; operations analysis; human factors; computer science; and robotics.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0399',
    cipTitle: 'Military Applied Sciences, Other',
    cipDefinition: 'Any instructional program in military applied sciences not listed above.',
  },
  {
    cipFamily: '29',
    cipCode: '29.04',
    cipTitle: 'Military Systems and Maintenance Technology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 29.0401 - 29.0499.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0401',
    cipTitle: 'Aerospace Ground Equipment Technology',
    cipDefinition:
      'A program that focuses on the principles, technology, and maintenance of ground-based systems and equipments used to support aviation flight operations and space operations.  Includes instruction in computer science, electronics, basic sciences and quantitative methods, air conditioning and refrigeration, corrosion control, fluid power, hazardous materials, industrial safety, maintenance management, vehicle and equipment operation, and applications to specific ground support systems.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0402',
    cipTitle: 'Air and Space Operations Technology',
    cipDefinition:
      'A program that focuses on the principles, technology and maintenance of systems and equipment used in aerospace operations.  Includes instruction in astronomy and astronautics, solid state theory, air and space operations, electronics, computer science, aviation and space flight safety, life support systems, flight operations management systems, programming, propulsion systems, weaponry, maintenance management and applications to specific systems and operations.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0403',
    cipTitle: 'Aircraft Armament Systems Technology',
    cipDefinition:
      'A program that focuses on the principles, technology and maintenance of aircraft weapons systems and associated peripherals.  Includes instruction in basic science and quantitative methods, computer science, electronics, engineering graphics, fluid power, heavy equipment operation, armament systems, weapon materials and processes, weapon safety, corrosion control, aircraft systems maintenance, and maintenance management.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0404',
    cipTitle: 'Explosive Ordinance/Bomb Disposal',
    cipDefinition:
      'A program that focuses on the identification, containment, analysis and neutralization of explosive devices.  Includes instruction in nuclear science, computer science, ordinance and bomb systems, electronics, computer science, industrial radiography, non-destructive testing, equipment operation, inventory management, investigative techniques, forensics, schematic interpretation, safety procedures, site containment and emergency medicine.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0405',
    cipTitle: 'Joint Command/Task Force (C3, C4I) Systems',
    cipDefinition:
      'A program that focuses on the principles, technology and operational use of command and control (C3, C4I) systems as applied to joint and combined military operations involving unified commands.  Includes instruction in information technology, communications systems, network systems and architecture, systems engineering, C3 and C4I doctrine and policy, C3 and C4I systems management, intelligence, operational and strategic planning, interagency operations, operational security and deception.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0406',
    cipTitle: 'Military Information Systems Technology',
    cipDefinition:
      'A program that focuses on the principles, design and application of computer and networking technology to the military environment. Includes instruction in planning; program development; graphical user interfaces; rapid prototyping; program construction; data types, operations; control flow; arrays; records; file I/O; database access; event-driven OOP structures; and enabling global-networked communications, including databases, systems analysis and design, decision support systems, and network security.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0407',
    cipTitle: 'Missile and Space Systems Technology',
    cipDefinition:
      'A program that focuses on the principles, technology and maintenance of rocket systems, guided missiles, and space vehicles as well as related systems.  Includes instruction in engineering mechanics, engineering graphics, materials and processes, electronics, propellant and guidance systems, control systems, fluid power, nuclear and conventional weapons systems, hazardous materials, non-destructive inspection and testing, quality assurance, safety procedures and maintenance management.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0408',
    cipTitle: 'Munitions Systems/Ordinance Technology',
    cipDefinition:
      'A program that focuses on the principles, technology and maintenance of ground or sea-based weapons systems, ordinance and munitions.  Includes instruction in basic sciences, electronics, systems technology, fluid power, computer science, conventional weapons systems, nuclear weapons systems, munitions systems, storage and safety, equipment operation, guidance and control systems, hazardous materials, corrosion control, nondestructive testing and quality control, and maintenance management.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0409',
    cipTitle: 'Radar Communications and Systems Technology',
    cipDefinition:
      'A program that focuses on the principles, technology and maintenance of air, sea and ground-based radar systems.  Includes instruction in electronic principles and digital techniques, transistors and solid-state component theory, radar systems; maintenance technology, data processing systems, wiring and circuit schematics, maintenance management, safety procedures, and applications to specific systems and services.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0499',
    cipTitle: 'Military Systems and Maintenance Technology, Other',
    cipDefinition: 'Any instructional program in military systems and maintenance technology not listed above.',
  },
  {
    cipFamily: '29',
    cipCode: '29.05',
    cipTitle: 'Reserved',
    cipDefinition: 'Instructional content is defined in code 29.0501.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0501',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '29',
    cipCode: '29.06',
    cipTitle: 'Military Technology and Applied Sciences Management',
    cipDefinition: 'Instructional content is defined in code 29.0601.',
  },
  {
    cipFamily: '29',
    cipCode: '29.0601',
    cipTitle: 'Military Technology and Applied Sciences Management',
    cipDefinition:
      'A program that focuses on applied military science, military operations, intelligence gathering, military technology, and national security. Includes instruction in computer science, engineering, ethics, field training, leadership, military strategy, logistics, management skills, and politics.',
  },
  {
    cipFamily: '29',
    cipCode: '29.99',
    cipTitle: 'Military Technologies and Applied Sciences, Other',
    cipDefinition: 'Instructional content is defined in code 29.9999.',
  },
  {
    cipFamily: '29',
    cipCode: '29.9999',
    cipTitle: 'Military Technologies and Applied Sciences, Other',
    cipDefinition: 'Any instructional program in military technologies and applied sciences not listed above.',
  },
  {
    cipFamily: '30',
    cipCode: '30',
    cipTitle: 'Multi/Interdisciplinary Studies',
    cipDefinition:
      'Instructional programs that derive from two or more distinct programs to provide a cross-cutting focus on a subject concentration that is not subsumed under a single discipline or occupational field.',
  },
  {
    cipFamily: '30',
    cipCode: '30.00',
    cipTitle: 'Multi-/Interdisciplinary Studies, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 30.0000 - 30.0001.',
  },
  {
    cipFamily: '30',
    cipCode: '30.0000',
    cipTitle: 'Multi-/Interdisciplinary Studies, General',
    cipDefinition:
      'A program that derives from two or more distinct programs and that is integrated around a unifying theme or topic that cannot be subsumed under a single discipline or occupational field.',
    Examples: 'Examples: - Student-Designed Major',
  },
  {
    cipFamily: '30',
    cipCode: '30.0001',
    cipTitle: 'Comprehensive Transition and Postsecondary (CTP) Program',
    cipDefinition:
      'A comprehensive transition and postsecondary (CTP) program that provides students with intellectual disabilities with academic enrichment, socialization, independent living skills, self-advocacy skills, and integrated work experiences and career skills that lead to gainful employment.',
  },
  {
    cipFamily: '30',
    cipCode: '30.01',
    cipTitle: 'Biological and Physical Sciences',
    cipDefinition: 'Instructional content is defined in code 30.0101.',
  },
  {
    cipFamily: '30',
    cipCode: '30.0101',
    cipTitle: 'Biological and Physical Sciences',
    cipDefinition:
      'A program that is either a general synthesis of one or more of the biological and physical sciences, or a specialization which draws from the biological and physical sciences.',
    CrossReferences: '26.0101 - Biology/Biological Sciences, General., 40.0101 - Physical Sciences, General.',
    Examples: 'Examples: - Astrobiology',
  },
  {
    cipFamily: '30',
    cipCode: '30.05',
    cipTitle: 'Peace Studies and Conflict Resolution',
    cipDefinition: 'Instructional content is defined in code 30.0501.',
  },
  {
    cipFamily: '30',
    cipCode: '30.0501',
    cipTitle: 'Peace Studies and Conflict Resolution',
    cipDefinition:
      'A program that focuses on the origins, resolution and prevention of international and inter-group conflicts. Includes instruction in peace research methods and related social scientific and psychological knowledge bases.',
    CrossReferences: '30.2801 - Dispute Resolution.',
  },
  {
    cipFamily: '30',
    cipCode: '30.06',
    cipTitle: 'Systems Science and Theory',
    cipDefinition: 'Instructional content is defined in code 30.0601.',
  },
  {
    cipFamily: '30',
    cipCode: '30.0601',
    cipTitle: 'Systems Science and Theory',
    cipDefinition:
      'A program with a multidisciplinary approach to the analysis and solution of complex problems, requiring a combined approach using data and models from the natural, social, technological, behavioral and life sciences, and other specialized fields.',
    CrossReferences: '14.2701 - Systems Engineering.',
  },
  {
    cipFamily: '30',
    cipCode: '30.08',
    cipTitle: 'Mathematics and Computer Science',
    cipDefinition: 'Instructional content is defined in code 30.0801.',
  },
  {
    cipFamily: '30',
    cipCode: '30.0801',
    cipTitle: 'Mathematics and Computer Science',
    cipDefinition:
      'A program with a general synthesis of mathematics and computer science or a specialization which draws from mathematics and computer science.',
    CrossReferences: '11.0701 - Computer Science., 27.0101 - Mathematics, General.',
  },
  {
    cipFamily: '30',
    cipCode: '30.10',
    cipTitle: 'Biopsychology',
    cipDefinition: 'Instructional content is defined in code 30.1001.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1001',
    cipTitle: 'Biopsychology',
    cipDefinition:
      'A program that focuses on biological and psychological linkages, especially the linkages between biochemical and biophysical activity and the functioning of the central nervous system.',
    CrossReferences: '42.2706 - Behavioral Neuroscience.',
  },
  {
    cipFamily: '30',
    cipCode: '30.11',
    cipTitle: 'Gerontology',
    cipDefinition: 'Instructional content is defined in code 30.1101.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1101',
    cipTitle: 'Gerontology',
    cipDefinition:
      'A program that focuses on the human aging process and aged human populations, using the knowledge and methodologies of the social sciences, psychology and the biological and health sciences.',
    CrossReferences: '19.0702 - Adult Development and Aging., 42.2809 - Geropsychology.',
  },
  {
    cipFamily: '30',
    cipCode: '30.12',
    cipTitle: 'Historic Preservation and Conservation',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 30.1201 - 30.1299.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1201',
    cipTitle: 'Historic Preservation and Conservation, General',
    cipDefinition:
      'A program that focuses on the design and implementation of plans to restore and maintain historic buildings, districts, and landscapes in a way that balances conservation and preservation with commercial and development interests. Includes instruction in architectural history, building conservation, cultural resource management, economics, historical documentation and preservation, land-use and zoning codes, public policy, public relations, real estate law, and taxation.',
    CrossReferences: '4.0802 - Architectural Conservation.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1202',
    cipTitle: 'Cultural Resource Management and Policy Analysis',
    cipDefinition:
      'A program that focuses on the application of cultural studies, public policy analysis, and management skills to planning, promoting, and implementing programs to preserve and protect cultural heritage sites and artifacts.  Includes instruction in historical preservation and conservation, business management, policy analysis, applied economics, public relations, applied history, historical archaeology, and environmental impact studies.',
    CrossReferences: '54.0105 - Public/Applied History.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1299',
    cipTitle: 'Historic Preservation and Conservation, Other',
    cipDefinition:
      'Any instructional program that focuses on architectural design and building techniques for historic and restored structures not listed above.',
  },
  {
    cipFamily: '30',
    cipCode: '30.13',
    cipTitle: 'Medieval and Renaissance Studies',
    cipDefinition: 'Instructional content is defined in code 30.1301.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1301',
    cipTitle: 'Medieval and Renaissance Studies',
    cipDefinition:
      'A program that focuses on the  study of the Medieval and/or Renaissance periods in European and circum-Mediterranean history from the perspective of various disciplines in the humanities and social sciences, including history and archeology, as well as studies of period art and music.',
    Examples: 'Examples: - Medieval Studies, - Renaissance Studies',
  },
  {
    cipFamily: '30',
    cipCode: '30.14',
    cipTitle: 'Museology/Museum Studies',
    cipDefinition: 'Instructional content is defined in code 30.1401.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1401',
    cipTitle: 'Museology/Museum Studies',
    cipDefinition:
      'A program that focuses on the attitudes, knowledge, and skills required to develop, prepare, organize, administer, conserve, store and retrieve artifacts, exhibits and entire collections in museums and galleries, and that prepares individuals to assume curatorial, technical and managerial positions in museums.  Includes instruction in institutional management, acquisition, exhibit design, conservation, packing techniques, and public relations.',
    CrossReferences: '50.1002 - Fine and Studio Arts Management.',
  },
  {
    cipFamily: '30',
    cipCode: '30.15',
    cipTitle: 'Science, Technology and Society',
    cipDefinition: 'Instructional content is defined in code 30.1501.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1501',
    cipTitle: 'Science, Technology and Society',
    cipDefinition:
      'A program that focuses on the contemporary social and public policy ramifications of science and technology, the interrelationship of science and engineering with the public policy process, and the social and ethical dimensions of scientific and technological enterprises.',
    CrossReferences: '54.0104 - History and Philosophy of Science and Technology., 51.3205 - History of Medicine.',
    Examples: 'Examples: - Social Studies of Science',
  },
  {
    cipFamily: '30',
    cipCode: '30.16',
    cipTitle: 'Accounting and Computer Science',
    cipDefinition: 'Instructional content is defined in code 30.1601.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1601',
    cipTitle: 'Accounting and Computer Science',
    cipDefinition: 'A program that combines accounting with computer science and/or computer studies.',
    CrossReferences: '11.0701 - Computer Science., 52.0301 - Accounting.',
  },
  {
    cipFamily: '30',
    cipCode: '30.17',
    cipTitle: 'Behavioral Sciences',
    cipDefinition: 'Instructional content is defined in code 30.1701.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1701',
    cipTitle: 'Behavioral Sciences',
    cipDefinition:
      'A program with a combined or undifferentiated focus on the social sciences, psychology, and biomedical sciences to study complex problems of human individual and social growth and behavior.',
    CrossReferences: '42.0101 - Psychology, General.',
  },
  {
    cipFamily: '30',
    cipCode: '30.18',
    cipTitle: 'Natural Sciences',
    cipDefinition: 'Instructional content is defined in code 30.1801.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1801',
    cipTitle: 'Natural Sciences',
    cipDefinition:
      'A program with a combined or undifferentiated focus on one or more of the physical and biological sciences.',
  },
  {
    cipFamily: '30',
    cipCode: '30.19',
    cipTitle: 'Nutrition Sciences',
    cipDefinition: 'Instructional content is defined in code 30.1901.',
  },
  {
    cipFamily: '30',
    cipCode: '30.1901',
    cipTitle: 'Nutrition Sciences',
    cipDefinition:
      'A scientific program that focuses on the utilization of food for human growth and metabolism, in both normal and dysfunctional states, from the interdisciplinary perspective of the agricultural, human, biological, and biomedical sciences.  Includes instruction in food science, biochemistry, physiology, dietetics, food and nutrition studies, biotechnology, biophysics, and the clinical sciences.',
    CrossReferences: '19.0504 - Human Nutrition., 51.3102 - Clinical Nutrition/Nutritionist.',
  },
  {
    cipFamily: '30',
    cipCode: '30.20',
    cipTitle: 'International/Globalization Studies',
    cipDefinition: 'Instructional content is defined in code 30.2001.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2001',
    cipTitle: 'International/Globalization Studies',
    cipDefinition:
      'A program that focuses on global and international issues from the perspective of the social sciences, social services, and related fields.',
    CrossReferences: '45.0901 - International Relations and Affairs.',
    Examples: 'Examples: - Global Studies',
  },
  {
    cipFamily: '30',
    cipCode: '30.21',
    cipTitle: 'Holocaust and Related Studies',
    cipDefinition: 'Instructional content is defined in code 30.2101.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2101',
    cipTitle: 'Holocaust and Related Studies',
    cipDefinition:
      'A program that focuses on genocide in human history with particular reference to Twentieth Century genocides such as that of the Ottoman Armenians during World War I and the Nazi Holocaust during World War II.  Programs also focus on topics such as survivor studies and counseling, war crimes research and prevention, reconciliation and compensation, ethical and spiritual issues, holocaust art and literature, and legacy and effect in relation to subsequent generations.',
    CrossReferences: '38.0206 - Jewish/Judaic Studies.',
  },
  {
    cipFamily: '30',
    cipCode: '30.22',
    cipTitle: 'Classical and Ancient Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 30.2201 - 30.2299.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2201',
    cipTitle: 'Ancient Studies/Civilization',
    cipDefinition:
      'A program that focuses on Western and Non-Western cultures and related topics in the periods of Pre-history and Antiquity.  Includes such disciplines as ancient languages, archeology, history, art history, geography, population studies, environmental studies, religious studies, and the social sciences.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2202',
    cipTitle: 'Classical, Ancient Mediterranean, and Near Eastern Studies and Archaeology',
    cipDefinition:
      'A program that focuses on the study of the classical ancient civilizations of the ancient Near East, Europe, and the Mediterranean Sea, such as Athens, Anatolia, Egypt, Greece, Israel, Mesopotamia, North Africa, and Rome. Includes instruction in ancient languages, ancient history, archaeology, anthropology, Greek and Roman mythology, philosophy, and religion.',
    CrossReferences: '5.0108 - Near and Middle Eastern Studies.',
    Examples: 'Examples: - Ancient Mediterranean Studies',
  },
  {
    cipFamily: '30',
    cipCode: '30.2299',
    cipTitle: 'Classical and Ancient Studies, Other',
    cipDefinition: 'Any program in classical and ancient studies not listed above.',
  },
  {
    cipFamily: '30',
    cipCode: '30.23',
    cipTitle: 'Intercultural/Multicultural and Diversity Studies',
    cipDefinition: 'Instructional content is defined in code 30.2301.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2301',
    cipTitle: 'Intercultural/Multicultural and Diversity Studies',
    cipDefinition:
      'A program that focuses on the dynamic social relations between and among majority and minority groups and different minority groups, and ways to promote mutual tolerance, inter-group cooperation, and the leadership and management of diverse groups and diversity issues in family, educational, recreational, and work settings.  Includes instruction in ethnic and cultural studies, group psychology, sociology, anthropology, conflict resolution, human services and resources, and applications to various functional environments.',
    CrossReferences: '5.0200 - Ethnic Studies., 9.0907 - International and Intercultural Communication.',
  },
  {
    cipFamily: '30',
    cipCode: '30.25',
    cipTitle: 'Cognitive Science',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 30.2501 - 30.2599.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2501',
    cipTitle: 'Cognitive Science, General',
    cipDefinition:
      'A program that focuses on the study of the mind and the nature of intelligence from the interdisciplinary perspectives of computer science, philosophy, mathematics, psychology, neuroscience, and other disciplines.  Includes instruction in mathematics and logic, cognitive process modeling, dynamic systems, learning theories, brain and cognition, neural networking, programming, and applications to topics such as language acquisition, computer systems, and perception and behavior.',
    CrossReferences: '42.2701 - Cognitive Psychology and Psycholinguistics.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2502',
    cipTitle: 'Contemplative Studies/Inquiry',
    cipDefinition:
      'A program that focuses on the philosophical, psychological, and scientific bases of human contemplative experience. Includes instruction in cognitive science, meditation, mindfulness, neuroscience, philosophy, psychology, and religion.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2599',
    cipTitle: 'Cognitive Science, Other',
    cipDefinition: 'Any instructional program in cognitive science not listed above.',
  },
  {
    cipFamily: '30',
    cipCode: '30.26',
    cipTitle: 'Cultural Studies/Critical Theory and Analysis',
    cipDefinition: 'Instructional content is defined in code 30.2601.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2601',
    cipTitle: 'Cultural Studies/Critical Theory and Analysis',
    cipDefinition:
      'An interdisciplinary program that focuses on the analysis and critique of culture in its varied forms, including values, ideas, belief systems, and expressive acts, and the relationship between cultural forms, everyday life, and structures of power.  Includes instruction in anthropology, communications, history, literary studies, philosophy, political economy, and sociology, as well as recent theories and methodologies such as semiotics, deconstruction, postcolonial studies, gender theory, and ethnography.',
    CrossReferences:
      '16.0104 - Comparative Literature., 30.3601 - Cultural Studies and Comparative Literature., 5.0212 - Comparative Group Studies.',
    Examples: 'Examples: - Cultural Studies, - Critical Theory and Analysis',
  },
  {
    cipFamily: '30',
    cipCode: '30.27',
    cipTitle: 'Human Biology',
    cipDefinition: 'Instructional content is defined in code 30.2701.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2701',
    cipTitle: 'Human Biology',
    cipDefinition:
      'An interdisciplinary program that focuses on understanding the human being from the biological, social science, and humanities perspectives and that addresses contemporary issues related to biology and society, such as global health and disease, environmental policy, bioethics, and biotechnology.',
    Examples: 'Examples: - Biology and Society',
  },
  {
    cipFamily: '30',
    cipCode: '30.28',
    cipTitle: 'Dispute Resolution',
    cipDefinition:
      'Instructional content is defined in code 30.2801. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2801',
    cipTitle: 'Dispute Resolution',
    cipDefinition:
      'A program that provides individuals with skills in negotiation, mediation, and arbitration, that can be applied to resolve disputes in a variety of settings, including business, legal, domestic, and labor relations.',
    CrossReferences: '30.0501 - Peace Studies and Conflict Resolution.',
    Examples:
      'Examples: - Dispute Resolution, - Dispute and Conflict Resolution, - Negotiation and Dispute Resolution, - Alternative Dispute Resolution',
  },
  {
    cipFamily: '30',
    cipCode: '30.29',
    cipTitle: 'Maritime Studies',
    cipDefinition: 'Instructional content is defined in code 30.2901.',
  },
  {
    cipFamily: '30',
    cipCode: '30.2901',
    cipTitle: 'Maritime Studies',
    cipDefinition:
      'A program that focuses on the history, science, policy issues, and literature of the ocean. Includes instruction in maritime history, maritime law, maritime literature, oceanography, maritime security, and maritime politics.',
  },
  {
    cipFamily: '30',
    cipCode: '30.30',
    cipTitle: 'Computational Science',
    cipDefinition: 'Instructional content is defined in code 30.3001.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3001',
    cipTitle: 'Computational Science',
    cipDefinition:
      'A program that focuses on the study of scientific computing and its application.  Includes instruction in scientific visualization, multi-scale analysis, grid generation, data analysis, applied mathematics, numerical algorithms, high performance parallel computing, and numerical modeling and simulation with applications in science, engineering and other disciplines in which computation plays an integral role.',
    CrossReferences: '27.0303 - Computational Mathematics., 26.1104 - Computational Biology.',
    Examples: 'Examples: - Scientific Computing',
  },
  {
    cipFamily: '30',
    cipCode: '30.31',
    cipTitle: 'Human Computer Interaction',
    cipDefinition: 'Instructional content is defined in code 30.3101.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3101',
    cipTitle: 'Human Computer Interaction',
    cipDefinition:
      'An interdisciplinary program that focuses on the study of the interaction between people and technology and how that technology impacts society, and combines disciplines within the fields of computing and information science (information systems, software engineering, artificial intelligence and design), engineering, and the behavior sciences (cognitive science, cognitive psychology, sociology, organizational psychology, and social psychology).  Includes instruction in information technology, cognitive and behavioral sciences, and systems design.',
    CrossReferences: '42.2813 - Applied Psychology., 11.0105 - Human-Centered Technology Design.',
    Examples: 'Examples: - Human Factors Psychology',
  },
  {
    cipFamily: '30',
    cipCode: '30.32',
    cipTitle: 'Marine Sciences',
    cipDefinition: 'Instructional content is defined in code 30.3201.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3201',
    cipTitle: 'Marine Sciences',
    cipDefinition:
      'A program that focuses on the study of biology, chemistry, geology and physics applied to marine, estuarine and coastal environments.  Includes instruction in marine biogeochemistry, atmosphere and ocean dynamics, coastal ecology, coastal ocean processes, microbial ecology, marine ecosystem modeling, and polar microbiology.',
    CrossReferences: '26.1302 - Marine Biology and Biological Oceanography.',
  },
  {
    cipFamily: '30',
    cipCode: '30.33',
    cipTitle: 'Sustainability Studies',
    cipDefinition: 'Instructional content is defined in code 30.3301.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3301',
    cipTitle: 'Sustainability Studies',
    cipDefinition:
      'A program that focuses on the concept of sustainability from an interdisciplinary perspective. Includes instruction in sustainable development, environmental policies, ethics, ecology, landscape architecture, city and regional planning, economics, natural resources, sociology, and anthropology.',
    CrossReferences: '1.0308 - Agroecology and Sustainable Agriculture.',
    Examples:
      'Examples: - Sustainability, - Sustainable Living, - Organizational and Environmental Sustainability, - Sustainability Studies',
  },
  {
    cipFamily: '30',
    cipCode: '30.34',
    cipTitle: 'Anthrozoology',
    cipDefinition: 'Instructional content is defined in code 30.3401.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3401',
    cipTitle: 'Anthrozoology',
    cipDefinition:
      'A program of study that combines anthropology and zoology in order to examine the relationship between animals and humans. Includes instruction in animal behavior and communication, animal welfare, animal conservation, animal training, animal-assisted therapy techniques, biology, ethics, and education.',
  },
  {
    cipFamily: '30',
    cipCode: '30.35',
    cipTitle: 'Climate Science',
    cipDefinition: 'Instructional content is defined in code 30.3501.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3501',
    cipTitle: 'Climate Science',
    cipDefinition:
      'A program that focuses on the scientific study of the climate system of the earth with emphasis on the physical, dynamical, and chemical interactions of the atmosphere, ocean, land, ice, and the terrestrial and marine biospheres. Includes instruction in biology, chemistry, climate analysis, climate change adaptation/mitigation, climate policy, ecology, energy development, environmental impacts, marine chemistry, meteorology, and oceanography.',
  },
  {
    cipFamily: '30',
    cipCode: '30.36',
    cipTitle: 'Cultural Studies and Comparative Literature',
    cipDefinition: 'Instructional content is defined in code 30.3601.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3601',
    cipTitle: 'Cultural Studies and Comparative Literature',
    cipDefinition:
      'A program with a general synthesis of cultural studies and comparative literature or a specialization which draws from cultural studies and comparative literature. Includes instruction in anthropology, comparative linguistics, cultural studies, history, literary criticism, literary studies, philosophy, political economy, sociology, and textual studies.',
    CrossReferences:
      '16.0104 - Comparative Literature., 5.0200 - Ethnic Studies., 30.2601 - Cultural Studies/Critical Theory and Analysis.',
  },
  {
    cipFamily: '30',
    cipCode: '30.37',
    cipTitle: 'Design for Human Health',
    cipDefinition: 'Instructional content is defined in code 30.3701.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3701',
    cipTitle: 'Design for Human Health',
    cipDefinition:
      'A program that focuses on the impact of design and architecture on the health of individuals and larger populations, and how to create settings that promote health, healing, and well-being. Includes instruction in architecture, interior design, human health services, communication studies, medical and clinical innovations, behavioral and engineering sciences, hospitals and health systems, and environmental conditions that influence health.',
    CrossReferences: '4.0402 - Healthcare Environment Design/Architecture.',
  },
  {
    cipFamily: '30',
    cipCode: '30.38',
    cipTitle: 'Earth Systems Science',
    cipDefinition: 'Instructional content is defined in code 30.3801.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3801',
    cipTitle: 'Earth Systems Science',
    cipDefinition:
      "A program that focuses on the interaction of the Earth's oceanographic, atmospheric, and terrestrial systems. Includes instruction in biogeochemistry, climate dynamics, geographical information science (GIS), geophysics, hydrology, landscape ecology, meteorology, and satellite remote sensing analysis.",
  },
  {
    cipFamily: '30',
    cipCode: '30.39',
    cipTitle: 'Economics and Computer Science',
    cipDefinition: 'Instructional content is defined in code 30.3901.',
  },
  {
    cipFamily: '30',
    cipCode: '30.3901',
    cipTitle: 'Economics and Computer Science',
    cipDefinition:
      'A program of study that focuses on the theoretical and practical connections between computer science and economics. Includes instruction in data analysis, database design, data mining, computer algorithms, economics, econometrics, computer programing, mathematics, and statistics.',
  },
  {
    cipFamily: '30',
    cipCode: '30.40',
    cipTitle: 'Economics and Foreign Language/Literature',
    cipDefinition: 'Instructional content is defined in code 30.4001.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4001',
    cipTitle: 'Economics and Foreign Language/Literature',
    cipDefinition:
      'A program with a general synthesis of economics and the language and literature of one or more countries or regions or a specialization which draws from economics and the language and literature of one or more countries or regions. Includes instruction in comparative economic systems, econometrics, economic theory, money and banking systems, international economics, international trade, linguistics, philology, and translation.',
    CrossReferences: '16.0101 - Foreign Languages and Literatures, General., 45.0605 - International Economics.',
  },
  {
    cipFamily: '30',
    cipCode: '30.41',
    cipTitle: 'Environmental Geosciences',
    cipDefinition: 'Instructional content is defined in code 30.4101.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4101',
    cipTitle: 'Environmental Geosciences',
    cipDefinition:
      'A program that focuses on the scientific study of the environmental implications of geological processes and human activities on Earth. Includes instruction in environmental/natural resource management, geographic information systems (GIS), geology, hydrology, regulatory agency compliance, hazard identification and mitigation, environmental law, environmental policy, and sustainability studies.',
  },
  {
    cipFamily: '30',
    cipCode: '30.42',
    cipTitle: 'Geoarcheaology',
    cipDefinition: 'Instructional content is defined in code 30.4201.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4201',
    cipTitle: 'Geoarcheaology',
    cipDefinition:
      'A program that focuses on the application of analytical techniques, concepts, and field methods from the earth sciences to solve archaeological questions related to human settlement, artifacts, site taphonomy, and paleoenvironments. Includes instruction in anthropology, ancient civilizations, archeology, geology, paleoclimatic reconstruction, sedimentology, and site taphonomic research.',
  },
  {
    cipFamily: '30',
    cipCode: '30.43',
    cipTitle: 'Geobiology',
    cipDefinition: 'Instructional content is defined in code 30.4301.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4301',
    cipTitle: 'Geobiology',
    cipDefinition:
      'A program that focuses on the scientific study of how living things interact with geological systems. Includes instruction in evolution of Earth systems, geochemistry, geology, geomicrobiology, marine chemistry, paleobiology, paleoecology, paleontology, and petrology.',
  },
  {
    cipFamily: '30',
    cipCode: '30.44',
    cipTitle: 'Geography and Environmental Studies',
    cipDefinition: 'Instructional content is defined in code 30.4401.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4401',
    cipTitle: 'Geography and Environmental Studies',
    cipDefinition:
      'A program that focuses on interactions between people and the natural and built environments. Includes instruction in climate science, sustainability, environmental science and policy, research methods, geographic information systems (GIS), human geography, physical geography, remote sensing, and public policy.',
    CrossReferences: '45.0701 - Geography., 3.0103 - Environmental Studies.',
  },
  {
    cipFamily: '30',
    cipCode: '30.45',
    cipTitle: 'History and Language/Literature',
    cipDefinition: 'Instructional content is defined in code 30.4501.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4501',
    cipTitle: 'History and Language/Literature',
    cipDefinition:
      'A program with a general synthesis of history and the language and literature of one or more countries or regions or a specialization which draws from history and the language and literature of one or more countries or regions. Includes instruction in historiography; historical research methods; linguistics; literature, philology; and studies of specific periods, issues and cultures.',
    CrossReferences: '54.0101 - History, General., 16.0101 - Foreign Languages and Literatures, General.',
  },
  {
    cipFamily: '30',
    cipCode: '30.46',
    cipTitle: 'History and Political Science',
    cipDefinition: 'Instructional content is defined in code 30.4601.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4601',
    cipTitle: 'History and Political Science',
    cipDefinition:
      'A program with a general synthesis of history and political science or a specialization which draws from history and political science. Includes instruction in comparative government and politics, historiography, historical research methods, political science, political theory, records administration, and systematic study of political institutions and behavior.',
    CrossReferences: '54.0101 - History, General., 45.1001 - Political Science and Government, General.',
  },
  {
    cipFamily: '30',
    cipCode: '30.47',
    cipTitle: 'Linguistics and Anthropology',
    cipDefinition: 'Instructional content is defined in code 30.4701.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4701',
    cipTitle: 'Linguistics and Anthropology',
    cipDefinition:
      'A program that focuses on the study of the anthropological, historical, and sociological context of language with a particular emphasis on language use and its role in shaping cultural and social diversity. Includes instruction in applied linguistics, data management, field methods, language heritage, socialization, structure, linguistic analysis, linguistic theory, and multilingualism.',
    CrossReferences: '16.0102 - Linguistics., 45.0201 - Anthropology, General.',
  },
  {
    cipFamily: '30',
    cipCode: '30.48',
    cipTitle: 'Linguistics and Computer Science',
    cipDefinition: 'Instructional content is defined in code 30.4801.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4801',
    cipTitle: 'Linguistics and Computer Science',
    cipDefinition:
      'A program that focuses on the relationship between computer and human language and computational techniques applied to natural language. Includes instruction in computer programming, human languages, linguistic analysis, logic, natural language processing, semantics, machine learning, psycholinguistics, software engineering, and syntax.',
    CrossReferences: '11.0101 - Computer and Information Sciences, General., 16.0102 - Linguistics.',
  },
  {
    cipFamily: '30',
    cipCode: '30.49',
    cipTitle: 'Mathematical Economics',
    cipDefinition: 'Instructional content is defined in code 30.4901.',
  },
  {
    cipFamily: '30',
    cipCode: '30.4901',
    cipTitle: 'Mathematical Economics',
    cipDefinition:
      'A program that focuses on the application of mathematical methods to the development of economic theory, models, and quantitative analysis. Includes instruction in data analysis, applied business economics, calculus, econometrics, linear algebra, microeconomic theory, probability, and statistical methods.',
    CrossReferences: '45.0603 - Econometrics and Quantitative Economics.',
    Examples: 'Examples: - Math Economics, - Mathematics and Economics, - Economics and Mathematics',
  },
  {
    cipFamily: '30',
    cipCode: '30.50',
    cipTitle: 'Mathematics and Atmospheric/Oceanic Science',
    cipDefinition: 'Instructional content is defined in code 30.5001.',
  },
  {
    cipFamily: '30',
    cipCode: '30.5001',
    cipTitle: 'Mathematics and Atmospheric/Oceanic Science',
    cipDefinition:
      'A program that focuses on the application of mathematics to atmospheric and oceanic problems. Includes instruction in chemistry, physics, atmospheric/ocean dynamics, climatology, weather simulation, climate modeling, mathematics, oceanography, and atmospheric science.',
  },
  {
    cipFamily: '30',
    cipCode: '30.51',
    cipTitle: 'Philosophy, Politics, and Economics',
    cipDefinition: 'Instructional content is defined in code 30.5101.',
  },
  {
    cipFamily: '30',
    cipCode: '30.5101',
    cipTitle: 'Philosophy, Politics, and Economics',
    cipDefinition:
      'A program that focuses on philosophical foundations, political institutions, collective behavior, economic theory, and economic influences. Includes instruction in econometrics, logic, metaphysics, moral responsibility, philosophy of language, philosophy of perception, philosophy through history, reasoning and persuasion, and social epistemology.',
    CrossReferences:
      '38.0101 - Philosophy., 45.0601 - Economics, General., 45.1001 - Political Science and Government, General.',
  },
  {
    cipFamily: '30',
    cipCode: '30.52',
    cipTitle: 'Digital Humanities and Textual Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 30.5201 - 30.5299.',
  },
  {
    cipFamily: '30',
    cipCode: '30.5201',
    cipTitle: 'Digital Humanities and Textual Studies, General',
    cipDefinition:
      'A program that focuses on digital textual editing, archiving, and publishing, and on the study of new media and computing platforms from the perspectives of the humanities and computer science. Includes instruction in archiving, computer programming, cultural studies, and textual criticism.',
  },
  {
    cipFamily: '30',
    cipCode: '30.5202',
    cipTitle: 'Digital Humanities',
    cipDefinition:
      'A program that focuses on the use of new technologies, advanced computing, and public engagement to investigate and analyze questions in the humanities. Includes instruction in anthropology, art, data mining and machine learning, design, digital archives, geospatial technologies, history, human-computer interface design, literature, markup languages, social media technologies, and software development.',
  },
  {
    cipFamily: '30',
    cipCode: '30.5203',
    cipTitle: 'Textual Studies',
    cipDefinition:
      'A program that focuses on the interconnected histories of digital or print texts and manuscripts for cultural and literary study. Includes instruction in archiving texts, bibliography, book and literary history, cultural studies, codicology, paleography, stemmatology, and textual criticism.',
  },
  {
    cipFamily: '30',
    cipCode: '30.5299',
    cipTitle: 'Digital Humanities and Textual Studies, Other',
    cipDefinition: 'Any instructional program in digital humanities and textual studies not listed above.',
  },
  {
    cipFamily: '30',
    cipCode: '30.53',
    cipTitle: 'Thanatology',
    cipDefinition: 'Instructional content is defined in code 30.5301.',
  },
  {
    cipFamily: '30',
    cipCode: '30.5301',
    cipTitle: 'Thanatology',
    cipDefinition:
      'A program of study that focuses on the biological, philosophical, psychological, sociological, and theological aspects of death, dying, and bereavement. Includes instruction in counseling, ethics, grief, palliative and hospice care, philosophy, thanatology, and theology.',
  },
  {
    cipFamily: '30',
    cipCode: '30.70',
    cipTitle: 'Data Science',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 30.7001 - 30.7099.',
  },
  {
    cipFamily: '30',
    cipCode: '30.7001',
    cipTitle: 'Data Science, General',
    cipDefinition:
      'A program that focuses on the analysis of large scale data sources from the interdisciplinary perspectives of applied statistics, computer science, data storage, data representation, data modeling, mathematics, and statistics. Includes instruction in computer algorithms, computer programming, data management, data mining, information policy, information retrieval, mathematical modeling, quantitative analysis, statistics, trend spotting, and visual analytics.',
  },
  {
    cipFamily: '30',
    cipCode: '30.7099',
    cipTitle: 'Data Science, Other',
    cipDefinition: 'Any instructional program in data science not listed above.',
  },
  {
    cipFamily: '30',
    cipCode: '30.71',
    cipTitle: 'Data Analytics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 30.7101 - 30.7199.',
  },
  {
    cipFamily: '30',
    cipCode: '30.7101',
    cipTitle: 'Data Analytics, General',
    cipDefinition:
      'A program that prepares individuals to apply data science to generate insights from data and identify and predict trends. Includes instruction in computer databases, computer programming, inference, machine learning, optimization, probability and stochastic models, statistics, strategy, uncertainty quantification, and visual analytics.',
  },
  {
    cipFamily: '30',
    cipCode: '30.7102',
    cipTitle: 'Business Analytics',
    cipDefinition:
      'A program that prepares individuals to apply data science to solve business challenges. Includes instruction in machine learning, optimization methods, computer algorithms, probability and stochastic models, information economics, logistics, strategy, consumer behavior, marketing, and visual analytics.',
    CrossReferences: '52.1302 - Business Statistics.',
    Examples: 'Examples: - Business Forecasting and Trend Spotting',
  },
  {
    cipFamily: '30',
    cipCode: '30.7103',
    cipTitle: 'Data Visualization',
    cipDefinition:
      'A program that prepares individuals to organize and derive meaning from data by using visual presentation tools and techniques. Includes instruction in cognitive science, computer programming, data management, data visualization theory, graphic design, infographics, perceptual psychology, statistics, and visual design.',
    Examples: 'Examples: - Infographics and Data Visualization Design',
  },
  {
    cipFamily: '30',
    cipCode: '30.7104',
    cipTitle: 'Financial Analytics',
    cipDefinition:
      'A program that focuses on financial big data modeling from algorithms to cloud-based data-driven financial technologies. Includes instruction in financial analytics, financial data processing, knowledge management, data visualization, effective decision communication, machine learning for finance, statistical inference and dynamic modeling on financial data, and project management.',
  },
  {
    cipFamily: '30',
    cipCode: '30.7199',
    cipTitle: 'Data Analytics, Other',
    cipDefinition: 'Any instructional program in data analytics not listed above.',
    Examples: 'Examples: - Social Media Trend Spotting',
  },
  {
    cipFamily: '30',
    cipCode: '30.99',
    cipTitle: 'Multi/Interdisciplinary Studies, Other',
    cipDefinition: 'Instructional content is defined in code 30.9999.',
  },
  {
    cipFamily: '30',
    cipCode: '30.9999',
    cipTitle: 'Multi-/Interdisciplinary Studies, Other',
    cipDefinition: 'Any instructional program in multi/interdisciplinary studies not listed above.',
  },
  {
    cipFamily: '31',
    cipCode: '31',
    cipTitle: 'Parks, Recreation, Leisure, Fitness, and Kinesiology',
    cipDefinition:
      'Instructional programs that focus on the principles and practices of managing parks and other recreational and fitness facilities; providing recreational, leisure and fitness services; and the study of human kinesiology and fitness.',
  },
  {
    cipFamily: '31',
    cipCode: '31.01',
    cipTitle: 'Parks, Recreation, and Leisure Studies',
    cipDefinition: 'Instructional content is defined in code 31.0101.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0101',
    cipTitle: 'Parks, Recreation, and Leisure Studies',
    cipDefinition:
      'A program that focuses on the principles underlying recreational and leisure activities, and the practices involved in providing indoor and outdoor recreational facilities and services for the general public.',
  },
  {
    cipFamily: '31',
    cipCode: '31.03',
    cipTitle: 'Parks, Recreation, and Leisure Facilities Management',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 31.0301 - 31.0399.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0301',
    cipTitle: 'Parks, Recreation, and Leisure Facilities Management, General',
    cipDefinition:
      'A program that prepares individuals to develop and manage park facilities and other indoor and outdoor recreation and leisure facilities.  Includes instruction in supervising support personnel, health and safety standards, public relations, and basic business and marketing principles.',
    CrossReferences:
      '3.0207 - Environmental/Natural Resource Recreation and Tourism., 3.0206 - Land Use Planning and Management/Development.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0302',
    cipTitle: 'Golf Course Operation and Grounds Management',
    cipDefinition:
      'A program that prepares individuals to manage the operation of golf courses. Includes instruction in turf grass science and management, golf course design and construction, grounds equipment and operation, pest control, and grounds management.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0399',
    cipTitle: 'Parks, Recreation, and Leisure Facilities Management, Other',
    cipDefinition:
      'Any instructional program in parks, recreation, and leisure facilities management not listed above.',
  },
  {
    cipFamily: '31',
    cipCode: '31.05',
    cipTitle: 'Sports, Kinesiology, and Physical Education/Fitness',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 31.0501 - 31.0599.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0501',
    cipTitle: 'Sports, Kinesiology, and Physical Education/Fitness, General',
    cipDefinition:
      'A general program that focuses on activities and principles that promote physical fitness, achieve and maintain athletic prowess, and accomplish related research and service goals. Includes instruction in human movement studies, motivation studies, rules and practice of specific sports, exercise and fitness principles and techniques, basic athletic injury prevention and treatment, and organizing and leading fitness and sports programs.',
    CrossReferences: '13.1314 - Physical Education Teaching and Coaching., 51.0001 - Health and Wellness, General.',
    Examples: 'Examples: - Coaching',
  },
  {
    cipFamily: '31',
    cipCode: '31.0504',
    cipTitle: 'Sport and Fitness Administration/Management',
    cipDefinition:
      'A program that prepares individuals to apply business, coaching and physical education principles to the organization, administration and management of athletic programs and teams, fitness/rehabilitation facilities and health clubs, sport recreation services, and related services.  Includes instruction in program planning and development; business and financial management principles; sales, marketing and recruitment; event promotion, scheduling and management; facilities management; public relations; legal aspects of sports; and applicable health and safety standards.',
    CrossReferences: '31.0508 - Sports Studies., 9.0906 - Sports Communication.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0505',
    cipTitle: 'Exercise Science and Kinesiology',
    cipDefinition:
      'A scientific program that focuses on the anatomy, physiology, biochemistry, and biophysics of human movement, and applications to exercise and therapeutic rehabilitation. Includes instruction in biomechanics, motor behavior, motor development and coordination, motor neurophysiology, performance research, rehabilitative therapies, the development of diagnostic and rehabilitative methods and equipment, and related analytical methods and procedures in applied exercise and therapeutic rehabilitation.',
    CrossReferences:
      '26.0908 - Exercise Physiology and Kinesiology., 51.2311 - Kinesiotherapy/Kinesiotherapist., 51.0913 - Athletic Training/Trainer., 13.1314 - Physical Education Teaching and Coaching., 26.0913 - Biomechanics.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0507',
    cipTitle: 'Physical Fitness Technician',
    cipDefinition:
      'A program that prepares individuals for employment in health and fitness clubs, wellness centers, public and private recreation facilities, hospitals and corporate fitness programs where they will perform a variety of instructional and administrative duties. Includes instruction in human anatomy and physiology, fitness techniques, exercise science, personal training, nutrition, and customer service.',
    CrossReferences: '51.0913 - Athletic Training/Trainer.',
    Examples: 'Examples: - Fitness Technician, - Fitness Trainer, - Personal Trainer',
  },
  {
    cipFamily: '31',
    cipCode: '31.0508',
    cipTitle: 'Sports Studies',
    cipDefinition:
      'A program that focuses on the psychological, sociological, and historical aspects of sport and physical activity. Includes instruction in theory of sport, sport psychology, sport in American society, gender and race/ethnicity and sport, and sports history.',
    CrossReferences:
      '31.0504 - Sport and Fitness Administration/Management., 9.0906 - Sports Communication., 42.2815 - Performance and Sport Psychology.',
    Examples: 'Examples: - Psychosocial Aspects of Sport',
  },
  {
    cipFamily: '31',
    cipCode: '31.0599',
    cipTitle: 'Sports, Kinesiology, and Physical Education/Fitness, Other',
    cipDefinition: 'Any instructional program in sports, kinesiology, and physical education/fitness not listed above.',
  },
  {
    cipFamily: '31',
    cipCode: '31.06',
    cipTitle: 'Outdoor Education',
    cipDefinition: 'Instructional content is defined in code 31.0601.',
  },
  {
    cipFamily: '31',
    cipCode: '31.0601',
    cipTitle: 'Outdoor Education',
    cipDefinition:
      'A program that prepares individuals to work as an educator, instructor or facilitator in parks, recreational facilities, camps and other outdoor settings. Includes instruction in leadership skills, wilderness survival skills, first aid, group processes, counseling techniques, environmental studies and instruction in recreational activities such as rock climbing, ropes courses, backpacking, kayaking and canoeing.',
    CrossReferences: '13.1338 - Environmental Education.',
  },
  {
    cipFamily: '31',
    cipCode: '31.99',
    cipTitle: 'Parks, Recreation, Leisure, Fitness, and Kinesiology, Other',
    cipDefinition: 'Instructional content is defined in code 31.9999.',
  },
  {
    cipFamily: '31',
    cipCode: '31.9999',
    cipTitle: 'Parks, Recreation, Leisure, Fitness, and Kinesiology, Other',
    cipDefinition:
      'Any instructional program in parks, recreation, leisure, fitness, and kinesiology not listed above.',
  },
  {
    cipFamily: '32',
    cipCode: '32',
    cipTitle: 'Basic Skills and Developmental/Remedial Education',
    cipDefinition:
      'Instructional programs that focus on the fundamental knowledge and skills that individuals need to function productively in society. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.01',
    cipTitle: 'Basic Skills and Developmental/Remedial Education',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 32.0101 - 32.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.0101',
    cipTitle: 'Basic Skills and Developmental/Remedial Education, General',
    cipDefinition:
      'A general program that focuses on the fundamental knowledge and skills that individuals need to function productively in society. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Adult Developmental Education, - Basic Skills, General, - Developmental Education, General, - Remedial Education, General',
  },
  {
    cipFamily: '32',
    cipCode: '32.0104',
    cipTitle: 'Developmental/Remedial Mathematics',
    cipDefinition:
      'A program that focuses on the development of computing and other mathematical reasoning abilities and skills. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Numeracy and Computational Skills, - Adult Developmental Mathematics',
  },
  {
    cipFamily: '32',
    cipCode: '32.0105',
    cipTitle: 'Job-Seeking/Changing Skills',
    cipDefinition:
      "A program that focuses on the development of skills related to job searches and self-marketing.  Includes instruction in assessing one's own capabilities and skills; filling out an application; and handling an interview. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '32',
    cipCode: '32.0107',
    cipTitle: 'Career Exploration/Awareness Skills',
    cipDefinition:
      'A program that focuses on the linkage between individual capabilities and needs and the job market.  Includes instruction in the variety and scope of available employment, how to access job information, and techniques of self-analysis. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.0108',
    cipTitle: 'Developmental/Remedial English',
    cipDefinition:
      'A program that focuses on the fundamental knowledge and skills in reading, writing and speaking that individuals need to function productively in society. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Developmental/Remedial Reading and Writing, - Developmental/Remedial Literacy Skills, - Literacy and Communication Skills',
  },
  {
    cipFamily: '32',
    cipCode: '32.0109',
    cipTitle: 'Second Language Learning',
    cipDefinition:
      'A program that focuses on the development of proficiency in reading, writing, and speaking a language or languages, other than the mother tongue, that are needed to perform day-to-day tasks.  Includes instruction in the use of basic communication skills to develop and transmit ideas and thoughts. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - English as a Second Language',
  },
  {
    cipFamily: '32',
    cipCode: '32.011',
    cipTitle: 'Basic Computer Skills',
    cipDefinition:
      'A program that focuses on the knowledge and skills needed to operate a computer, including computer concepts, keyboarding and mouse skills, using computer hardware and software, and basic troubleshooting. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Basic Computer Application',
  },
  {
    cipFamily: '32',
    cipCode: '32.0111',
    cipTitle: 'Workforce Development and Training',
    cipDefinition:
      'A program that focuses on learning or upgrading basic skills in order to enhance job performance, promote career development, or train for a new job. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.0112',
    cipTitle: 'Accent Reduction/Modification',
    cipDefinition:
      'A program that focuses on accent modification and effective pronunciation in order to assist individuals in changing their accents/dialects to Standard American English. Includes instruction in changing speech patterns, intonation, phonology, pronunciation, rhythm, semantics, syntax, vocabulary, voice production, and word order. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.0199',
    cipTitle: 'Basic Skills and Developmental/Remedial Education, Other',
    cipDefinition:
      'Any instructional program in basic skills not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.02',
    cipTitle: 'General Exam Preparation and Test-Taking Skills',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 32.0201 - 32.0299. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.0201',
    cipTitle: 'Exam Preparation and Test-Taking Skills, General',
    cipDefinition:
      'A program of study that focuses on general study and exam-taking skills. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '32',
    cipCode: '32.0202',
    cipTitle: 'High School Equivalent Exam Preparation',
    cipDefinition:
      'A program of study that focuses on general study and exam-taking skills associated with preparation for the high school equivalency examinations. This CIP code is not valid for IPEDS reporting.',
    CrossReferences: '53.0201 - High School Equivalence Certificate Program.',
    Examples:
      'Examples: - General Educational Development (GED) Exam, - High School Equivalency Certificate Exam, - Certificate of School Completion Exam',
  },
  {
    cipFamily: '32',
    cipCode: '32.0203',
    cipTitle: 'Undergraduate Entrance/Placement Examination Preparation',
    cipDefinition:
      'A program of study that focuses on general study and exam-taking skills associated with preparation for undergraduate college/university entrance or placement examinations. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Scholastic Assessment Test (SAT), - SAT Subject Tests, - The ACT Test, - Texas Higher Education Assessment, - Postsecondary Education Readiness Test (PERT)',
  },
  {
    cipFamily: '32',
    cipCode: '32.0204',
    cipTitle: 'Graduate/Professional School Entrance Examination Preparation',
    cipDefinition:
      'A program of study that focuses on general study and exam-taking skills associated with preparation for graduate and professional school entrance examinations. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Graduate Management Admission Test (GMAT), - Law School Admissions Test (LSAT), - Optometry Admission Test (OAT), - HESI Admission Assessment Exam, - Dental Admission Test (DAT), - Pharmacy College Admission Test (PCAT), - Medical College Admissions Test (MCAT), - Graduate Record Examination (GRE) General Test, - Veterinary College Admission Test (VCAT), - Miller Analogies Test (MAT)',
  },
  {
    cipFamily: '32',
    cipCode: '32.0205',
    cipTitle: 'Professional Certification/Licensure Examination Preparation',
    cipDefinition:
      'A program of study that focuses on general study and exam-taking skills associated with preparation for professional certification and licensure examinations. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - National Council Licensure Examination (NCLEX), - Praxis Test, - Comprehensive Osteopathic Medical Licensing Examination (COMLEX), - United States Medical Licensing Examination (USMLE), - Physician Assistant National Certifying Exam (PANCE), - Professional Surveyor (P.S.) Exam, - National Registry of Emergency Medical Technicians (NREMT) Exam, - Certified Information Systems Auditor (CISA) Examination, - Certified Public Accountant (CPA) Examination, - Chartered Financial Analyst (CFA) Examination, - Professional Engineer (P.E.) Examination, - Project Management Professional (PMP) Examination, - North American Pharmacist Licensure Examination (NAPLEX)',
  },
  {
    cipFamily: '32',
    cipCode: '32.0299',
    cipTitle: 'General Exam Preparation and Test-Taking Skills, Other',
    cipDefinition: 'Any instructional program in general exam preparation and test-taking skills not listed above.',
  },
  {
    cipFamily: '33',
    cipCode: '33',
    cipTitle: 'Citizenship Activities',
    cipDefinition:
      'Instructional programs that prepare individuals for citizenship, and provide instruction in how citizens may engage in civic activities. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.01',
    cipTitle: 'Citizenship Activities',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 33.0101 - 33.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.0101',
    cipTitle: 'Citizenship Activities, General',
    cipDefinition:
      'A program that generally prepares individuals for citizenship, and focuses on how citizens may engage in civic activities. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.0102',
    cipTitle: 'American Citizenship Education',
    cipDefinition:
      'A program that prepares individuals to take the oath of United States citizenship and to exercise the attendant rights and responsibilities of citizenship. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.0103',
    cipTitle: 'Community Awareness',
    cipDefinition:
      'A program that focuses on local government and history, current issues, and focuses on how individuals can keep abreast of important issues that may affect them. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.0104',
    cipTitle: 'Community Involvement',
    cipDefinition:
      'A program that focuses on how individuals may become actively involved in the social, economic and political issues and events affecting them; and the roles and methods that are available to influence community life and public policy. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.0105',
    cipTitle: 'Canadian Citizenship Education',
    cipDefinition:
      'A program that prepares individuals to take the oath of Canadian citizenship and to exercise the attendant rights and responsibilities of citizenship. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.0106',
    cipTitle: 'Personal Emergency Preparedness',
    cipDefinition:
      'A program of study that focuses on the leadership and management skills needed to prepare and respond to critical threats such as natural disasters, hazardous spills, and security threats. Includes instruction in communication, community preparedness, decision-making, disaster management, disaster recovery, disaster preparation, emergency planning and preparation, resource planning, and risk and hazard impact. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '33',
    cipCode: '33.0199',
    cipTitle: 'Citizenship Activities, Other',
    cipDefinition:
      'Any instructional program in citizenship activities not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '34',
    cipCode: '34',
    cipTitle: 'Health-Related Knowledge and Skills',
    cipDefinition:
      'Instructional programs that focus on the promotion of personal and family health. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '34',
    cipCode: '34.01',
    cipTitle: 'Health-Related Knowledge and Skills',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 34.0102 - 34.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '34',
    cipCode: '34.0102',
    cipTitle: 'Birthing and Parenting Knowledge and Skills',
    cipDefinition:
      "A program that focuses on all facets of the mother's and father's roles in family planning, prenatal preparation and care, the birthing experience, post-natal care and the raising of children. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '34',
    cipCode: '34.0103',
    cipTitle: 'Personal Health Improvement and Maintenance',
    cipDefinition:
      'A program that focuses on the principles, techniques, and methods by which individuals can maintain or improve their overall physical and emotional well-being, as well as work on specific areas of personal health. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '34',
    cipCode: '34.0104',
    cipTitle: 'Addiction Prevention and Treatment',
    cipDefinition:
      'A program that focuses on how individuals can avoid addictive substances and behaviors; the methods by which individuals can be treated for various addictions and related behavior problems; and the knowledge and coping skills needed by relatives and associates of addicted individuals. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '34',
    cipCode: '34.0105',
    cipTitle: 'Meditation/Mind-Body Wellness',
    cipDefinition:
      'A program that focuses on meditation and mindfulness training, and how these techniques can enhance brain function, regulate emotions, reduce stress, and increase compassion. Includes instruction in alternative medicine, innovative and ancient healing practices, inspired creativity and the arts, meditation, mindfulness, neuroscience, religious belief systems, spiritual psychology, therapeutic movement, and visualization meditation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '34',
    cipCode: '34.0199',
    cipTitle: 'Health-Related Knowledge and Skills, Other',
    cipDefinition:
      'Any instructional program in health-related knowledge and skills not listed above. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - First Aid, - CPR',
  },
  {
    cipFamily: '35',
    cipCode: '35',
    cipTitle: 'Interpersonal and Social Skills',
    cipDefinition:
      'Programs that focus on the development of methods and skills for effective interactions with others in a variety of settings (private, social, and business settings). These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '35',
    cipCode: '35.01',
    cipTitle: 'Interpersonal and Social Skills',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 35.0101 - 35.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '35',
    cipCode: '35.0101',
    cipTitle: 'Interpersonal and Social Skills, General',
    cipDefinition:
      'A general program that focuses on how to effectively interact with others in private, social and business settings. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '35',
    cipCode: '35.0102',
    cipTitle: 'Interpersonal Relationships Skills',
    cipDefinition:
      "A program that focuses on how to increase one's ability to establish and maintain mutually satisfactory ties with other human beings. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '35',
    cipCode: '35.0103',
    cipTitle: 'Business and Social Skills',
    cipDefinition:
      "A program that focuses on how to increase one's ability to function effectively in social and business settings where interpersonal communication is required. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '35',
    cipCode: '35.0105',
    cipTitle: 'Life Coaching',
    cipDefinition:
      'A program that focuses on assisting individuals in achieving personal goals such as life transitions, relationships, physical well-being, emotional well-being, and career choices. Includes instruction in career and vocational development, coaching methodologies, coaching research techniques, group coaching, leadership techniques, and personal coaching. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '35',
    cipCode: '35.0199',
    cipTitle: 'Interpersonal Social Skills, Other',
    cipDefinition:
      'Any instructional program in interpersonal social skills not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36',
    cipTitle: 'Leisure and Recreational Activities',
    cipDefinition:
      'Programs that focus on the development of an appreciation for and competency in recreational and leisure-related activities. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.01',
    cipTitle: 'Leisure and Recreational Activities',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 36.0101 - 36.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0101',
    cipTitle: 'Leisure and Recreational Activities, General',
    cipDefinition:
      'A general program that focuses on the development of an appreciation for and competency in recreational and leisure-related activities. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0102',
    cipTitle: 'Handicrafts and Model-Making',
    cipDefinition:
      'A program that focuses on the fashioning of objects of decoration, utility or representation from various materials, including related matters of research, tool use and appreciation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0103',
    cipTitle: 'Board, Card and Role-Playing Games',
    cipDefinition:
      'A  program that focuses on the rules and techniques of participation and skill-building in competitive activities of skill or chance, such as board games, card games or role-playing activities. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0105',
    cipTitle: 'Home Maintenance and Improvement',
    cipDefinition:
      'A program that focuses on the knowledge and skills associated with maintaining living space and related equipment and furnishings, as well as do-it-yourself repairs and improvement projects of varying complexity. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0106',
    cipTitle: 'Nature Appreciation',
    cipDefinition:
      "A program that focuses on how to increase one's understanding and knowledge of the natural environment in which we live, as well as techniques of wildlife observation and management. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '36',
    cipCode: '36.0107',
    cipTitle: 'Pet Ownership and Care',
    cipDefinition:
      "A program that focuses on how to increase one's ability to care for domesticated animals kept for pleasure or work. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '36',
    cipCode: '36.0108',
    cipTitle: 'Sports and Exercise',
    cipDefinition:
      'A program that focuses on the rules and techniques of participation and skill building in competitive physical activities, as well as non-competitive physical fitness programs. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0109',
    cipTitle: 'Travel and Exploration',
    cipDefinition:
      'A program that focuses on particular geographic areas or phenomena, and provides opportunities for organized trips or tours, including related knowledge and skills. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.011',
    cipTitle: 'Art',
    cipDefinition:
      'A program that focuses on the techniques and methods of creative self-expression in visual or plastic media, such as painting or sculpture. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0111',
    cipTitle: 'Collecting',
    cipDefinition:
      'A program that focuses on the knowledge and techniques necessary for acquiring and maintaining personal collections of objects, such as autographs, stamps, models, specimens, vehicles and antiques. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0112',
    cipTitle: 'Cooking and Other Domestic Skills',
    cipDefinition:
      'A program that focuses on the knowledge and skills related to food buying and preparation, home decoration, sewing and other domestic activities, either as hobbies or as routine tasks. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0113',
    cipTitle: 'Computer Games and Programming Skills',
    cipDefinition:
      'A program that focuses on the knowledge and skills associated with creating and maintaining computer programs, as well as playing computer-based games. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0114',
    cipTitle: 'Dancing',
    cipDefinition:
      'A program that focuses on the knowledge and skills related to recreational dance, such as square dancing, ballroom dancing, classical or modern dance. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0115',
    cipTitle: 'Music',
    cipDefinition:
      'A program that focuses on the knowledge and skills associated with personal music appreciation, the playing of a musical instrument, singing or recreational composition. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0116',
    cipTitle: 'Reading',
    cipDefinition:
      'A program that focuses on the activity of reading for pleasure, either alone or as part of a group experience. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0117',
    cipTitle: 'Theatre/Theater',
    cipDefinition:
      'A program that focuses on the knowledge and skills associated with participation in amateur theatrical productions, drama appreciation, and writing amateur plays. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0118',
    cipTitle: 'Writing',
    cipDefinition:
      'A program that focuses on the knowledge and skills related to creative writing and poetry composition for pleasure or profit, including methods of publication. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0119',
    cipTitle: 'Aircraft Pilot (Private)',
    cipDefinition: 'Moved from 36.0119 to 36.0202',
  },
  {
    cipFamily: '36',
    cipCode: '36.012',
    cipTitle: 'Beekeeping',
    cipDefinition:
      'A program that focuses on keeping bees for personal enjoyment, pollination of plants and vegetables, and domestic honey and other bee product production. Includes instruction in bee behavior, hive equipment, beekeeping supplies, bee pests and parasites, handling bees, hive set up and care, honey production, and seasonal management practices. This CIP code is not valid for IPEDS reporting.',
    CrossReferences: '1.0310 - Apiculture.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0121',
    cipTitle: 'Firearms Training/Safety',
    cipDefinition:
      'A program that focuses on the fundamentals of gun operation, shooting techniques, and firearm safety. Includes instruction in ammunition, firearm cleaning, firearms law, firearm operation, and shooting techniques. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0122',
    cipTitle: 'Floral Design/Arrangement',
    cipDefinition:
      'A program that focuses on the techniques of floral design based on color palette, theme, season, and flower selection. Includes instruction in botanical terminology, flower handling and identification, foliage and plants, traditional and contemporary floral design, and use of seasonal, dry, silk, or exotic plant materials. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0123',
    cipTitle: 'Master Gardener/Gardening',
    cipDefinition:
      'A program that prepares individuals to educate the public on science-based, environmentally-sound practices in sustainable gardening and horticulture. Includes instruction in vegetables, houseplants, composting, flowers, fruits, turf grass, woody plants, plant propagation, pollination, soils, wildlife management, integrated pest management, plant pathology, and entomology. This CIP code is not valid for IPEDS reporting.',
    CrossReferences: '1.0801 - Agricultural and Extension Education Services., 1.0609 - Public Horticulture.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0199',
    cipTitle: 'Leisure and Recreational Activities, Other',
    cipDefinition:
      'Any instructional program in leisure and recreational activities not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.02',
    cipTitle: 'Noncommercial Vehicle Operation',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 36.0202 - 36.0299. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0202',
    cipTitle: 'Aircraft Pilot (Private)',
    cipDefinition:
      "A program that prepares individuals to fly aircraft for personal use, and qualifies individuals to sit for the FAA pilot's license examination.  Includes instruction in principles of aircraft design and performance; aircraft flight systems and controls; flight crew operations and procedures; radio communications and navigation procedures and systems; airways safety and traffic regulations; and governmental rules and regulations pertaining to piloting aircraft. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '36',
    cipCode: '36.0203',
    cipTitle: 'Automobile Driver Education',
    cipDefinition:
      "A program that prepares individuals to drive automobiles for personal use and qualifies them to sit for a driver's license examination. Includes instruction in automobile operation, driving skills, safety, and traffic rules. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '36',
    cipCode: '36.0204',
    cipTitle: 'Helicopter Pilot (Private)',
    cipDefinition:
      'A program that prepares individuals to fly helicopters for personal use, and qualifies individuals to sit for the FAA Private Pilot - Helicopter (PRH) examination. Includes instruction in principles of helicopter design and performance; helicopter flight systems and controls; flight crew operations and procedures; radio communications and navigation procedures and systems; airways safety and traffic regulations; and governmental rules and regulations pertaining to piloting helicopters. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0205',
    cipTitle: 'Motorcycle Rider Education',
    cipDefinition:
      'A program that prepares individuals to ride motorcycles for personal use and qualifies them to sit for a motorcycle license examination. Includes instruction in motorcycle operation, riding skills, safety, and traffic rules. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0206',
    cipTitle: 'Personal Watercraft/Boating Education',
    cipDefinition:
      'A program that prepares individuals to operate boats and other watercraft for personal use and qualifies them to sit for a boating license examination. Includes instruction in boat and watercraft operation, safety, and applicable laws and rules. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Boating Safety Education, - Boater Education Certificate',
  },
  {
    cipFamily: '36',
    cipCode: '36.0207',
    cipTitle: 'Remote Aircraft Pilot (Personal)',
    cipDefinition:
      'A program that prepares individuals to fly an unmanned aircraft system (UAS) for personal use, and qualifies individuals to sit for the FAA Remote Pilot Certificate with small unmanned aircraft systems (sUAS) rating knowledge examination. Includes instruction in principles of unmanned aircraft system design and performance; aircraft flight systems and controls; airway safety and traffic regulations; and governmental rules and regulations pertaining to piloting unmanned aircraft. This CIP code is not valid for IPEDS reporting.',
    CrossReferences: '49.0109 - Remote Aircraft Pilot.',
  },
  {
    cipFamily: '36',
    cipCode: '36.0299',
    cipTitle: 'Noncommercial Vehicle Operation, Other',
    cipDefinition: 'Any instructional program in noncommercial vehicle operation not listed above.',
  },
  {
    cipFamily: '37',
    cipCode: '37',
    cipTitle: 'Personal Awareness and Self-Improvement',
    cipDefinition:
      'Programs that focus on the development of improved self-awareness, avoidance of stressful behavior, and improved decision-making skills. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '37',
    cipCode: '37.01',
    cipTitle: 'Personal Awareness and Self-Improvement',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 37.0101 - 37.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '37',
    cipCode: '37.0101',
    cipTitle: 'Self-Awareness and Personal Assessment',
    cipDefinition:
      "A program that focuses on the knowledge and skills useful in becoming aware of one's feelings, using methods of assessing one's personal attributes, and being aware of how one is perceived by others. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '37',
    cipCode: '37.0102',
    cipTitle: 'Stress Management and Coping Skills',
    cipDefinition:
      'A program that focuses on the knowledge and skills useful in avoiding stressful situations and managing them when they occur, including dealing with complex and long-term stressful relationships. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '37',
    cipCode: '37.0103',
    cipTitle: 'Personal Decision-Making Skills',
    cipDefinition:
      "A program that focuses on how to develop individuals' abilities to assess decisions affecting their lives and to make life choices consistent with needs and beliefs. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '37',
    cipCode: '37.0104',
    cipTitle: 'Self-Esteem and Values Clarification',
    cipDefinition:
      'A program that focuses on the development of personal philosophies and ideas of positive self-concept and self-worth, and applying these knowledge and skills in everyday circumstances. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '37',
    cipCode: '37.0106',
    cipTitle: 'Investing/Wealth Management/Retirement Planning',
    cipDefinition:
      'A program that prepares individuals to evaluate their personal financial health, establish financial planning strategies, and solve financial problems, based on their short term and long term financial goals. Includes instruction in asset management, estate planning, college savings, investment theory, insurance, real estate, retirement planning, and taxation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '37',
    cipCode: '37.0107',
    cipTitle: 'Self-Defense',
    cipDefinition:
      'A program that focuses on defensive concepts and techniques against various types of assault. Includes instruction in basic principles of defense, continuum of survival, date rape, defensive mindset, weapons and strategies, offensive and defensive postures, recognizing vulnerable locations, risk reduction strategies, and using personal weapons. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '37',
    cipCode: '37.0199',
    cipTitle: 'Personal Awareness and Self-Improvement, Other',
    cipDefinition:
      'Any instructional program in personnel awareness and self-improvement not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '38',
    cipCode: '38',
    cipTitle: 'Philosophy and Religious Studies',
    cipDefinition:
      'Instructional programs that focus on logical inquiry, philosophical analysis, and the academic study of organized systems of belief and religious practices.',
  },
  {
    cipFamily: '38',
    cipCode: '38.00',
    cipTitle: 'Philosophy and Religious Studies, General',
    cipDefinition: 'Instructional content is defined in code 38.0001.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0001',
    cipTitle: 'Philosophy and Religious Studies, General',
    cipDefinition:
      'A general program that combines the study of philosophy and religious studies. Includes instruction in logic; ethics; epistemology; symbolism; phenomenology; the sociology, psychology, philosophy, anthropology, literature, and art of religion; and world religions.',
    CrossReferences: '38.0101 - Philosophy., 38.0201 - Religion/Religious Studies.',
  },
  {
    cipFamily: '38',
    cipCode: '38.01',
    cipTitle: 'Philosophy',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 38.0101 - 38.0199.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0101',
    cipTitle: 'Philosophy',
    cipDefinition:
      'A program that focuses on ideas and their logical structure, including arguments and investigations about abstract and real phenomena.  Includes instruction in logic, ethics, aesthetics, epistemology, metaphysics, symbolism, and history of philosophy, and applications to the theoretical foundations and methods of other disciplines.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0102',
    cipTitle: 'Logic',
    cipDefinition:
      'A program that focuses on the systematic study of valid inference, argument, and sound reasoning.  Includes instruction in Classical logic, properties of argument, validity, proof, fallacies, formal and material logic, symbolic and mathematical logic, analysis, specific schools of logic, and applications to problems in other academic disciplines and professional fields.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0103',
    cipTitle: 'Ethics',
    cipDefinition:
      'A program that focuses on the systematic study of the theory of moral good and its application to various theoretical and practical problems.  Includes instruction in ethical theory, history of ethics, belief and value systems, ethical constructs, and applications to specific topics, issues and problems.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0104',
    cipTitle: 'Applied and Professional Ethics',
    cipDefinition:
      'A program that focuses on the systematic study of ethical issues in the workplace and public life, and the application of ethical decision-making to the practical problems of society and the professions. Includes instruction in ethical theory; history of ethics; contemporary social dilemmas; methods in applied ethics; and applications including medical ethics, legal ethics, business ethics, environmental ethics, and criminal justice ethics.',
    CrossReferences: '51.3201 - Bioethics/Medical Ethics.',
    Examples: 'Examples: - Applied Ethics, - Professional Ethics, - Engineering Ethics',
  },
  {
    cipFamily: '38',
    cipCode: '38.0199',
    cipTitle: 'Philosophy, Other',
    cipDefinition: 'Any instructional program in Philosophy not listed above.',
    CrossReferences: '30.5101 - Philosophy, Politics, and Economics.',
  },
  {
    cipFamily: '38',
    cipCode: '38.02',
    cipTitle: 'Religion/Religious Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 38.0201 - 38.0299.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0201',
    cipTitle: 'Religion/Religious Studies',
    cipDefinition:
      'A program that focuses on the nature of religious belief and specific religious and quasi-religious systems.  Includes instruction in phenomenology; the sociology, psychology, philosophy, anthropology, literature and art of religion; mythology; scriptural and textual studies; religious history and politics; and specific studies of particular faith communities and their behavior.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0202',
    cipTitle: 'Buddhist Studies',
    cipDefinition:
      'A program that focuses on the philosophy preached by Siddartha Gautama, the Buddha, in ancient India and subsequently interpreted by his disciples and apostles; together with the intellectual, cultural, social, and ritual developments of the faith and its branches.  Includes instruction in Buddhist sacred literature (Tripitaka, etc.) and study of one or more of the main branches including Early Buddhism, Hinayana, Theravada, Madhyamaka, Yogacara, Pure Land, Shingon, Tendai, Nichiren Shu, Zen, Tibetan, Chinese, Korean, Vietnamese, and others.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0203',
    cipTitle: 'Christian Studies',
    cipDefinition:
      'A program that focuses on the philosophy and teachings of Jesus Christ and his apostles, and the subsequent intellectual and cultural movements and institutions based on Christianity.  Includes instruction in Christian sacred scripture, Christian literature and philosophy, and studies of one or more of the main branches of the faith and their cultural context including Orthodoxy, Roman Catholicism, Protestantism, Evangelicalism, and others.',
    CrossReferences: '39.0201 - Bible/Biblical Studies.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0204',
    cipTitle: 'Hindu Studies',
    cipDefinition:
      'A program that focuses on the group of South Asian theologies and philosophies collectively known as Hinduism; together with the religious history and cultural and social manifestations of the faith.  Includes instruction in Hindu theology and philosophy (dharma); literature (Vedas, Upanishads, Epics, and commentaries); the Hindu Pantheon, sects, and movements; schools and disciplines; and related arts and sciences.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0205',
    cipTitle: 'Islamic Studies',
    cipDefinition:
      'A program that focuses on Islam as preached by the Prophet Muhammad in 6th century Arabia and subsequently interpreted and elaborated by Islamic scholars and others; together with the cultural and social milieu related to the faith in various periods, localities, and branches.  Includes instruction in Islamic scripture and related written authorities and commentaries',
  },
  {
    cipFamily: '38',
    cipCode: '38.0206',
    cipTitle: 'Jewish/Judaic Studies',
    cipDefinition:
      'A program that focuses on the history, culture, and religion of the Jewish people.  Includes instruction in Jewish religious heritage, sacred texts, and law; Jewish philosophy and intellectual history; Jewish life and culture, both in Israel and the Jewish Diaspora; historical Jewish minority cultures such as the Yiddish, Sephardic, and other; anti-Semitism, gentile relations and Shoa; Zionism; and modern developments within Judaism.',
    CrossReferences:
      '30.2101 - Holocaust and Related Studies., 39.0605 - Rabbinical Studies., 38.0207 - Talmudic Studies., 39.0201 - Bible/Biblical Studies.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0207',
    cipTitle: 'Talmudic Studies',
    cipDefinition:
      'A program focused on intense study of the Talmud that fosters values, critical thinking, and scholarship skills; prepares students for entry into a broad spectrum of graduate and professional schools; and prepares students for entry into a variety of careers including the Rabbinate. Includes instruction in Jewish law, Jewish jurisprudence, ethics, philosophy, and related disciplines.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0208',
    cipTitle: 'Catholic Studies',
    cipDefinition:
      'A program that focuses on Catholic thought, culture, and how Catholic tradition informs theology, culture, institutions, and identity. Includes instruction in Biblical Greek, Biblical Hebrew, Catholic bioethics, Catholic social thought, church history, Latin, philosophy, and theology.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0209',
    cipTitle: 'Mormon Studies',
    cipDefinition:
      'A program that focuses on beliefs, practices, culture, and history of the Church of Jesus Christ of Latter-day Saints (Mormon). Includes instruction in Mormon culture, history, literature, philosophy, sociology, and theology.',
  },
  {
    cipFamily: '38',
    cipCode: '38.0299',
    cipTitle: 'Religion/Religious Studies, Other',
    cipDefinition: 'Any instructional program in religion/religious studies not listed above.',
  },
  {
    cipFamily: '38',
    cipCode: '38.99',
    cipTitle: 'Philosophy and Religious Studies, Other',
    cipDefinition: 'Instructional content is defined in code 38.9999.',
  },
  {
    cipFamily: '38',
    cipCode: '38.9999',
    cipTitle: 'Philosophy and Religious Studies, Other',
    cipDefinition: 'Any instructional program in philosophy and religion not listed above.',
  },
  {
    cipFamily: '39',
    cipCode: '39',
    cipTitle: 'Theology and Religious Vocations',
    cipDefinition:
      'Instructional programs that focus on the intramural study of theology and that prepare individuals for the professional practice of religious vocations.',
  },
  {
    cipFamily: '39',
    cipCode: '39.02',
    cipTitle: 'Bible/Biblical Studies',
    cipDefinition: 'Instructional content is defined in code 39.0201.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0201',
    cipTitle: 'Bible/Biblical Studies',
    cipDefinition:
      'A program that focuses on the Christian and/or Jewish Bible and related literature, with an emphasis on understanding and interpreting the theological, doctrinal, and ethical messages contained therein.  May include preparation for applying these studies in various religious vocations.',
    CrossReferences: '38.0203 - Christian Studies., 38.0206 - Jewish/Judaic Studies.',
  },
  {
    cipFamily: '39',
    cipCode: '39.03',
    cipTitle: 'Missions/Missionary Studies and Missiology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 39.0301 - 39.0399.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0301',
    cipTitle: 'Missions/Missionary Studies',
    cipDefinition:
      'A program that focuses on the theory and practice of religious outreach, social service and proselytization, and that prepares individuals for mission vocations.  Includes instruction in theology, evangelism, preaching, medical and social mission work, missionary education, missionary aviation, emergency services, legal and political aspects of working in other countries, and preparation for ordination as missionaries.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0302',
    cipTitle: 'Church Planting',
    cipDefinition:
      'A program of study that prepares individuals to establish congregations of Christians in new locations. Includes instruction in models of church planting, church multiplication strategies, church leadership, evangelism, missiology, Christian ethics, and mission-oriented theology.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0399',
    cipTitle: 'Missions/Missionary Studies and Missiology, Other',
    cipDefinition: 'Any instructional program in missions, missionary studies, and missiology not listed above.',
  },
  {
    cipFamily: '39',
    cipCode: '39.04',
    cipTitle: 'Religious Education',
    cipDefinition: 'Instructional content is defined in code 39.0401.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0401',
    cipTitle: 'Religious Education',
    cipDefinition:
      'A program that focuses on the theory and practice of providing educational services to members of faith communities, within the context of a particular religion, and that prepares individuals to serve as religious educators.  Includes instruction in planning and teaching lessons, organizing and supervising instructional activities, designing and developing instructional materials, and administering religious education programs and facilities.',
  },
  {
    cipFamily: '39',
    cipCode: '39.05',
    cipTitle: 'Religious Music and Worship',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 39.0501 - 39.0599.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0501',
    cipTitle: 'Religious/Sacred Music',
    cipDefinition:
      'A program that focuses on the history, theory, composition, and performance of music for religious or sacred purposes, and that prepares individuals for religious musical vocations such as choir directors, cantors, organists, and chanters.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0502',
    cipTitle: 'Worship Ministry',
    cipDefinition:
      'A program of study that focuses on the use of musical and theatrical arts in Christian worship. Includes instruction in digital media, electronic hardware design and installation, music, theater, and worship theology.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0599',
    cipTitle: 'Religious Music and Worship, Other',
    cipDefinition: 'Any instructional program in religious music and worship not listed above.',
  },
  {
    cipFamily: '39',
    cipCode: '39.06',
    cipTitle: 'Theological and Ministerial Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 39.0601 - 39.0699.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0601',
    cipTitle: 'Theology/Theological Studies',
    cipDefinition:
      'A program that focuses on the beliefs and doctrine of a particular religious faith from the intramural point of view of that faith.  Includes instruction in systematic theology, historical theology, moral theology, doctrinal studies, dogmatics, apologetics, and applications to specific questions of ecclesiastical polity and religious life.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0602',
    cipTitle: 'Divinity/Ministry',
    cipDefinition:
      'A program that prepares individuals for ordination as ministers or priests in any of the Christian religious traditions.  Includes instruction in the theology and polity of a particular church, church law, liturgy and ritual, principles of pastoral ministry, homiletics, evangelism, church/parish organization and management, Christian ethics, church history, and related studies.',
    Examples: 'Examples: - Divinity/Ministry (BD), - Divinity/Ministry (MDiv)',
  },
  {
    cipFamily: '39',
    cipCode: '39.0604',
    cipTitle: 'Pre-Theology/Pre-Ministerial Studies',
    cipDefinition:
      'A program that prepares individuals to enter a seminary or other program leading to religious ordination, or a related religious vocation.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0605',
    cipTitle: 'Rabbinical Studies',
    cipDefinition:
      'A program that prepares individuals for ordination as Rabbis. Includes instruction in Talmud, Halacha, Liturgy and Rituals, Rabbinical Thought, Jewish Ethics, Jewish Education, Pastoral Counseling and Homiletics.',
    CrossReferences: '38.0207 - Talmudic Studies., 38.0206 - Jewish/Judaic Studies.',
    Examples: 'Examples: - Rabbinical Studies (M.H.L./Rav)',
  },
  {
    cipFamily: '39',
    cipCode: '39.0606',
    cipTitle: 'Talmudic Studies',
    cipDefinition: 'Moved from 39.0606 to 38.0207',
  },
  {
    cipFamily: '39',
    cipCode: '39.0699',
    cipTitle: 'Theological and Ministerial Studies, Other',
    cipDefinition:
      'Any instructional program in theological and ministerial studies not listed above, including preparation for religious vocations in faiths other than Christianity and Judaism.',
  },
  {
    cipFamily: '39',
    cipCode: '39.07',
    cipTitle: 'Pastoral Counseling and Specialized Ministries',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 39.0701 - 39.0799.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0701',
    cipTitle: 'Pastoral Studies/Counseling',
    cipDefinition:
      'A program that focuses on the theory and principles of pastoral care and prepares ordained clergy to provide non-clinical pastoral counseling to individuals and groups.  Includes instruction in pastoral leadership, counseling psychology; crisis intervention; individual and group procedures; theological and spiritual counseling; pastoral care; and applications to specific types of ministry.',
    CrossReferences: '51.1506 - Clinical Pastoral Counseling/Patient Counseling.',
    Examples: 'Examples: - Christian Counseling',
  },
  {
    cipFamily: '39',
    cipCode: '39.0702',
    cipTitle: 'Youth Ministry',
    cipDefinition:
      'A program that prepares the ordained clergy and other religious professionals to provide spiritual, counseling, and leadership services to children, adolescents, and young adults.  Includes instruction in child and adolescent psychology, human growth and development, family studies, communication skills, religious education, youth counseling, small group leadership, planning and organizing youth activities, volunteer supervision, and youth evangelism and worship.',
    Examples: 'Examples: - Youth and Family Ministries',
  },
  {
    cipFamily: '39',
    cipCode: '39.0703',
    cipTitle: 'Urban Ministry',
    cipDefinition:
      'A program that prepares the ordained clergy and other religious professionals to provide spiritual, counseling, and leadership services to individuals and groups in urban communities. Includes instruction in bible study and theology, pastoral leadership and care, urban structure and context, inner city ministry, counseling ministries, family studies, homiletics, social ethics, and community organizing.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0704',
    cipTitle: "Women's Ministry.",
    cipDefinition:
      "A program that prepares non-ordained women to provide spiritual, counseling, and leadership services to other women in local churches, parachurch organizations, and other settings. Includes instruction in foundations of women's ministry, women's ministry in the local church, spiritual formation, women's evangelism and discipleship, bible study and theology, homiletics, lay counseling, women's issues, and leadership training.",
  },
  {
    cipFamily: '39',
    cipCode: '39.0705',
    cipTitle: 'Lay Ministry',
    cipDefinition:
      'A program that prepares non-ordained individuals to serve as lay pastors, religious educators, youth leaders, and other professional positions open to the laity in local churches and other settings. Includes instruction in bible studies, theology, spirituality, pastoral counseling, foundations of ministry, effective communication, and church leadership.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0706',
    cipTitle: 'Chaplain/Chaplaincy Studies',
    cipDefinition:
      'A program that prepares individuals to provide caregiver, pastoral, and spiritual support in an institutional, hospice, military, or sports setting. Includes instruction in global, healthcare, prison, and sports chaplaincy; addiction, crisis, disaster, family, marriage, grief, and loss counseling; ethics; leadership; multi-cultural, multi-faith contexts; and worship.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0799',
    cipTitle: 'Pastoral Counseling and Specialized Ministries, Other',
    cipDefinition: 'Any instructional program in pastoral counseling and specialized ministries not listed above.',
  },
  {
    cipFamily: '39',
    cipCode: '39.08',
    cipTitle: 'Religious Institution Administration and Law',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 39.0801 - 39.0899.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0801',
    cipTitle: 'Religious Institution Administration and Management',
    cipDefinition:
      'A program that prepares individuals to manage the business affairs and administration of religious institutions. Includes instruction in business management, principles of accounting and financial management, human resources management, taxation of religious institutions, and business law as applied to religious institutions.',
    CrossReferences: '52.0206 - Non-Profit/Public/Organizational Management.',
    Examples: 'Examples: - Church Administration',
  },
  {
    cipFamily: '39',
    cipCode: '39.0802',
    cipTitle: 'Religious/Canon Law',
    cipDefinition:
      'A program of study that focuses on the history, theology, and philosophy of religious, church, and ecclesiastical law and that prepares individuals to serve as professional canonists.',
  },
  {
    cipFamily: '39',
    cipCode: '39.0899',
    cipTitle: 'Religious Institution Administration and Law, Other',
    cipDefinition: 'Any instructional program in religious institution administration and law not listed above.',
  },
  {
    cipFamily: '39',
    cipCode: '39.99',
    cipTitle: 'Theology and Religious Vocations, Other',
    cipDefinition: 'Instructional content is defined in code 39.9999.',
  },
  {
    cipFamily: '39',
    cipCode: '39.9999',
    cipTitle: 'Theology and Religious Vocations, Other',
    cipDefinition: 'Any instructional program in theological studies and religious vocations not listed above.',
    Examples: 'Examples: - Sacred Scripture Studies',
  },
  {
    cipFamily: '40',
    cipCode: '40',
    cipTitle: 'Physical Sciences',
    cipDefinition:
      'Instructional programs that focus on the scientific study of inanimate objects, processes of matter and energy, and associated phenomena.',
  },
  {
    cipFamily: '40',
    cipCode: '40.01',
    cipTitle: 'Physical Sciences, General',
    cipDefinition: 'Instructional content is defined in code 40.0101.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0101',
    cipTitle: 'Physical Sciences, General',
    cipDefinition:
      'A program that focuses on the major topics, concepts, processes, and interrelationships of physical phenomena as studied in any combination of physical science disciplines.',
  },
  {
    cipFamily: '40',
    cipCode: '40.02',
    cipTitle: 'Astronomy and Astrophysics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 40.0201 - 40.0299.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0201',
    cipTitle: 'Astronomy',
    cipDefinition:
      'A general program that focuses on the planetary, galactic, and stellar phenomena occurring in outer space.  Includes instruction in celestial mechanics, cosmology, stellar physics, galactic evolution, quasars, stellar distribution and motion, interstellar medium, atomic and molecular constituents of astronomical phenomena, planetary science, solar system evolution, and specific methodologies such as optical astronomy, radioastronomy, and theoretical astronomy.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0202',
    cipTitle: 'Astrophysics',
    cipDefinition:
      'A program that focuses on the theoretical and observational study of the structure, properties, and behavior of stars, star systems and clusters, stellar life cycles, and related phenomena.  Includes instruction in cosmology, plasma kinetics, stellar physics, convolution and non-equilibrium radiation transfer theory, non-Euclidean geometries, mathematical modeling, galactic structure theory, and relativistic astronomy.',
    CrossReferences: '40.1101 - Physics and Astronomy.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0203',
    cipTitle: 'Planetary Astronomy and Science',
    cipDefinition:
      'A program that focuses on the scientific study of planets, small objects, and related gravitational systems.  Includes instruction in the structure and composition of planetary surfaces and interiors, planetary atmospheres, satellites, orbital mechanics, asteroids and comets, solar system evolution and dynamics, planetary evolution, gravitational physics, and radiation physics.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0299',
    cipTitle: 'Astronomy and Astrophysics, Other',
    cipDefinition: 'Any instructional program in astronomy and astrophysics not listed above.',
  },
  {
    cipFamily: '40',
    cipCode: '40.04',
    cipTitle: 'Atmospheric Sciences and Meteorology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 40.0401 - 40.0499.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0401',
    cipTitle: 'Atmospheric Sciences and Meteorology, General',
    cipDefinition:
      "A general program that focuses on the scientific study of the composition and behavior of the atmospheric envelopes surrounding the earth, the effect of earth's atmosphere on terrestrial weather, and related problems of environment and climate.  Includes instruction in atmospheric chemistry and physics, atmospheric dynamics, climatology and climate change, weather simulation, weather forecasting, climate modeling and mathematical theory; and studies of specific phenomena such as clouds, weather systems, storms, and precipitation patterns.",
    Examples: 'Examples: - Atmospheric Sciences',
  },
  {
    cipFamily: '40',
    cipCode: '40.0402',
    cipTitle: 'Atmospheric Chemistry and Climatology',
    cipDefinition:
      'A program that focuses on the scientific study of atmospheric constituents, reactions, measurement techniques, and processes in predictive, current, and historical contexts.  Includes instruction in climate modeling, gases and aerosols, trace gases, aqueous phase chemistry, sinks, transport mechanisms, computer measurement, climate variability, paleoclimatology, climate diagnosis, numerical modeling and data analysis, ionization, recombination, photoemission, and plasma chemistry.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0403',
    cipTitle: 'Atmospheric Physics and Dynamics',
    cipDefinition:
      'A program that focuses on the scientific study of the processes governing the interactions, movement, and behavior of atmospheric phenomena and related terrestrial and solar phenomena.  Includes instruction in cloud and precipitation physics, solar radiation transfer, active and passive remote sensing, atmospheric electricity and acoustics, atmospheric wave phenomena, turbulence and boundary layers, solar wind, geomagnetic storms, coupling, natural plasma, and energization.',
    Examples: 'Examples: - Magnetospheric Physics, - Aeronomy, - Ionospheric Environment',
  },
  {
    cipFamily: '40',
    cipCode: '40.0404',
    cipTitle: 'Meteorology',
    cipDefinition:
      'A program that focuses on the scientific study of the prediction of atmospheric motion and climate change.  Includes instruction in general circulation patterns, weather phenomena, atmospheric predictability, parameterization, numerical and statistical analysis, large- and mesoscale phenomena, kinematic structures, precipitation processes, and forecasting techniques.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0499',
    cipTitle: 'Atmospheric Sciences and Meteorology, Other',
    cipDefinition: 'Any instructional program in atmospheric sciences and meteorology not listed above.',
  },
  {
    cipFamily: '40',
    cipCode: '40.05',
    cipTitle: 'Chemistry',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 40.0501 - 40.0599.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0501',
    cipTitle: 'Chemistry, General',
    cipDefinition:
      'A general program that focuses on the scientific study of the composition and behavior of matter, including its micro- and macro-structure, the processes of chemical change, and the theoretical description and laboratory simulation of these phenomena.',
    CrossReferences: '14.0701 - Chemical Engineering.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0502',
    cipTitle: 'Analytical Chemistry',
    cipDefinition:
      'A program that focuses on the scientific study of techniques for analyzing and describing matter, including its precise composition and the interrelationships of constituent elements and compounds.  Includes instruction in spectroscopy, chromatography, atomic absorption, photometry, chemical modeling, mathematical analysis, laboratory analysis procedures and equipment maintenance, and applications to specific research, industrial and health problems.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0503',
    cipTitle: 'Inorganic Chemistry',
    cipDefinition:
      'A program that focuses on the scientific study of the elements and their compounds, other than the hydrocarbons and their derivatives.  Includes instruction in the characterization and synthesis of non-carbon molecules, including their structure and their bonding, conductivity, and reactive properties; research techniques such as spectroscopy, X-ray diffraction, and photoelectron analysis; and the study of specific compounds, such as transition metals, and compounds composed of inorganic and organic molecules.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0504',
    cipTitle: 'Organic Chemistry',
    cipDefinition:
      'A program that focuses on the scientific study of the properties and behavior of hydrocarbon compounds and their derivatives. Includes instruction in molecular conversion and synthesis, molecular synthesis and design, the molecular structure of living cells and systems, the mutual reactivity of organic and inorganic compounds in combination, the spectroscopic analysis of hydrocarbon compounds, and applications to specific problems in research, industry, and health.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0506',
    cipTitle: 'Physical Chemistry',
    cipDefinition:
      'A program that focuses on the scientific study of understanding and predicting the behavior of chemical systems ranging from nuclear particles to atoms, molecules, clusters, biological materials and macroscopic assemblies.  Includes instruction in quantum mechanics, spectroscopy, thermodynamics, statistical mechanics, reaction dynamics, group theory, collision theory, and polymer science.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0507',
    cipTitle: 'Polymer Chemistry',
    cipDefinition:
      'A program that focuses on the scientific study of synthesized macromolecules and their interactions with other substances.  Includes instruction in molecular bonding theory, polymerization, properties and behavior of unstable compounds, the development of tailored polymers, transition phenomena, and applications to specific industrial problems and technologies.',
    CrossReferences: '14.3201 - Polymer/Plastics Engineering.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0508',
    cipTitle: 'Chemical Physics',
    cipDefinition:
      'A program that focuses on the scientific study of structural phenomena combining the disciplines of physical chemistry and atomic/molecular physics. Includes instruction in heterogeneous structures, alignment and surface phenomena, quantum theory, mathematical physics, statistical and classical mechanics, chemical kinetics, and laser physics.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0509',
    cipTitle: 'Environmental Chemistry',
    cipDefinition:
      'A program that focuses on the scientific study of natural systems (air, water, and soil) through the use of chemical techniques and instrumentation, with an emphasis on the movement and fate of pollutants and chemical aspects of contaminant remediation. Includes instruction in analytical, inorganic, organic, and physical chemistry; aquatic, soil, and atmospheric chemistry; environmental engineering; environmental toxicology; and analytical methods.',
  },
  {
    cipFamily: '40',
    cipCode: '40.051',
    cipTitle: 'Forensic Chemistry',
    cipDefinition:
      'A program that focuses on the application of chemical techniques and instrumentation to the analysis and evaluation of physical evidence to aid in criminal investigations. Includes instruction in analytical, inorganic, organic, and physical chemistry; biochemistry; forensic toxicology; instrumental analysis; crime scene processing; criminal investigation; forensic laboratory technology; and applicable law.',
    CrossReferences: '43.0406 - Forensic Science and Technology.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0511',
    cipTitle: 'Theoretical Chemistry',
    cipDefinition:
      'A program that focuses on the study of mathematical and computational methods and fundamental laws of physics to describe chemical phenomena and to develop empirical models of molecules and their interactions. Includes instruction in properties of small, isolated molecules; dynamics of elementary chemical processes; reaction pathways of organic molecules; hydrogen bonding patterns in liquids; reaction rates of biological pathways; and advanced computational techniques.',
    CrossReferences: '40.0512 - Cheminformatics/Chemistry Informatics.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0512',
    cipTitle: 'Cheminformatics/Chemistry Informatics',
    cipDefinition:
      'A program that focuses on applying computer science approaches in the representation, analysis, design, and modeling of chemical structures and associated metadata, such as biological activity endpoints and physicochemical properties. Includes instruction in chemical information technology, computational chemistry, computer science, database design, molecular modeling, scientific computing, and statistics.',
    Examples: 'Examples: - Computational Chemistry',
  },
  {
    cipFamily: '40',
    cipCode: '40.0599',
    cipTitle: 'Chemistry, Other',
    cipDefinition: 'Any instructional program in chemistry not listed above.',
  },
  {
    cipFamily: '40',
    cipCode: '40.06',
    cipTitle: 'Geological and Earth Sciences/Geosciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 40.0601 - 40.0699.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0601',
    cipTitle: 'Geology/Earth Science, General',
    cipDefinition:
      'A program that focuses on the scientific study of the earth; the forces acting upon it; and the behavior of the solids, liquids and gases comprising it.  Includes instruction in historical geology, geomorphology, and sedimentology, the chemistry of rocks and soils, stratigraphy, mineralogy, petrology, geostatistics, volcanology, glaciology, geophysical principles, and applications to research and industrial problems.',
    CrossReferences: '14.3901 - Geological/Geophysical Engineering.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0602',
    cipTitle: 'Geochemistry',
    cipDefinition:
      'A program that focuses on the scientific study of the chemical properties and behavior of the silicates and other substances forming, and formed by geomorphological processes of the earth and other planets.  Includes instruction in chemical thermodynamics, equilibrium in silicate systems, atomic bonding, isotopic fractionation, geochemical modeling, specimen analysis, and studies of specific organic and inorganic substances.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0603',
    cipTitle: 'Geophysics and Seismology',
    cipDefinition:
      'A program that focuses on the scientific study of the physics of solids and its application to the study of the earth and other planets.  Includes instruction in gravimetric, seismology, earthquake forecasting, magnetrometry, electrical properties of solid bodies, plate tectonics, active deformation, thermodynamics, remote sensing, geodesy, and laboratory simulations of geological processes.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0604',
    cipTitle: 'Paleontology',
    cipDefinition:
      'A program that focuses on the scientific study of extinct life forms and associated fossil remains, and the reconstruction and analysis of ancient life forms, ecosystems, and geologic processes. Includes instruction in sedimentation and fossilization processes, fossil chemistry, evolutionary biology, paleoecology, paleoclimatology, trace fossils, micropaleontology, invertebrate paleontology, vertebrate paleontology, paleobotany, field research methods, and laboratory research and conservation methods.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0605',
    cipTitle: 'Hydrology and Water Resources Science',
    cipDefinition:
      'A program that focuses on the scientific study of the occurrence, circulation, distribution, chemical and physical properties, and environmental interaction of surface and subsurface waters, including groundwater.  Includes instruction in geophysics, thermodynamics, fluid mechanics, chemical physics, geomorphology, mathematical modeling, hydrologic analysis, continental water processes, global water balance, and environmental science.',
    CrossReferences:
      '14.0805 - Water Resources Engineering., 3.0205 - Water, Wetlands, and Marine Resources Management.',
    Examples: 'Examples: - Hydrogeology',
  },
  {
    cipFamily: '40',
    cipCode: '40.0606',
    cipTitle: 'Geochemistry and Petrology',
    cipDefinition:
      'A program that focuses on the scientific study of the igneous, metamorphic, and hydrothermal processes within the earth and the mineral, fluid, rock, and ore deposits resulting from them.  Includes instruction in mineralogy, crystallography, petrology, volcanology, economic geology, meteoritics, geochemical reactions, deposition, compound transformation, core studies, theoretical geochemistry, computer applications, and laboratory studies.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0607',
    cipTitle: 'Oceanography, Chemical and Physical',
    cipDefinition:
      'A program that focuses on the scientific study of the chemical components, mechanisms, structure, and movement of ocean waters and their interaction with terrestrial and atmospheric phenomena.  Includes instruction in material inputs and outputs, chemical and biochemical transformations in marine systems, equilibria studies, inorganic and organic ocean chemistry, oceanographic processes, sediment transport, zone processes, circulation, mixing, tidal movements, wave properties, and seawater properties.',
    CrossReferences: '26.1302 - Marine Biology and Biological Oceanography.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0699',
    cipTitle: 'Geological and Earth Sciences/Geosciences, Other',
    cipDefinition: 'Any instructional program in geological and related sciences not listed above.',
    Examples: 'Examples: - Geodetics, - Geodesy',
  },
  {
    cipFamily: '40',
    cipCode: '40.08',
    cipTitle: 'Physics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 40.0801 - 40.0899.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0801',
    cipTitle: 'Physics, General',
    cipDefinition:
      'A general program that focuses on the scientific study of matter and energy, and the formulation and testing of the laws governing the behavior of the matter-energy continuum.  Includes instruction in classical and modern physics, electricity and magnetism, thermodynamics, mechanics, wave properties, nuclear processes, relativity and quantum theory, quantitative methods, and laboratory methods.',
    CrossReferences: '14.1201 - Engineering Physics/Applied Physics.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0802',
    cipTitle: 'Atomic/Molecular Physics',
    cipDefinition:
      'A program that focuses on the scientific study of the behavior of matter-energy phenomena at the level of atoms and molecules. Includes instruction in chemical physics, atomic forces and structure, molecular orbital theory, magnetic resonance, molecular bonding, phase equilibria, quantum theory of solids, and applications to the study of specific elements and higher compounds.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0804',
    cipTitle: 'Elementary Particle Physics',
    cipDefinition:
      'A program that focuses on the scientific study of the basic constituents of sub-atomic matter and energy, and the forces governing fundamental processes.  Includes instruction in quantum theory, field theory, single-particle systems, perturbation and scattering theory, matter-radiation interaction, symmetry, quarks, capture, Schroedinger mechanics, methods for detecting particle emission and absorption, and research equipment operation and maintenance.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0805',
    cipTitle: 'Plasma and High-Temperature Physics',
    cipDefinition:
      'A program that focuses on the scientific study of properties and behavior of matter at high temperatures, such that molecular and atomic structures are in a disassociated ionic or electronic state.  Includes instruction in magnetohydrodynamics, free electron phenomena, fusion theory, electromagnetic fields and dynamics, plasma and non-linear wave theory, instability theory, plasma shock phenomena, quantitative modeling, and research equipment operation and maintenance.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0806',
    cipTitle: 'Nuclear Physics',
    cipDefinition:
      'A program that focuses on the scientific study of the properties and behavior of atomic nuclei.  Includes instruction in nuclear reaction theory, quantum mechanics, energy conservation, nuclear fission and fusion, strong and weak forces, nuclear modeling, nuclear decay, nucleon scattering, pairing, photon and electron reactions, the physics of nuclear effects, statistical methods, and research equipment operation and maintenance.',
    CrossReferences: '14.2301 - Nuclear Engineering.',
    Examples: 'Examples: - Nuclear Effects',
  },
  {
    cipFamily: '40',
    cipCode: '40.0807',
    cipTitle: 'Optics/Optical Sciences',
    cipDefinition:
      'A program that focuses on the scientific study of light energy, including its structure, properties and behavior under different conditions.  Includes instruction in wave theory, wave mechanics, electromagnetic theory, physical optics, geometric optics, quantum theory of light, photon detecting, laser theory, wall and beam properties, chaotic light, non-linear optics, harmonic generation, optical systems theory, and applications to engineering problems.',
    CrossReferences: '14.1003 - Laser and Optical Engineering.',
    Examples: 'Examples: - Electro-Optics, - Atmospheric and Space Optics',
  },
  {
    cipFamily: '40',
    cipCode: '40.0808',
    cipTitle: 'Condensed Matter and Materials Physics',
    cipDefinition:
      'A program that focuses on the scientific study of macroscopic physical phenomena and properties that arise from basic microscopic interactions.  Includes instruction in low-temperature and solid-state physics, x-ray physics, liquids and soft materials, including the study of semiconductors, metals, superliquids, magnets, superconductors, glasses, gels, polymers, colloids, neural networks, and macromolecules.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0809',
    cipTitle: 'Acoustics',
    cipDefinition:
      'A program that focuses on the scientific study of sound, and the properties and behavior of acoustic wave phenomena under different conditions.  Includes instruction in wave theory, the acoustic wave equation, energy transformation, vibration phenomena, sound reflection and transmission, scattering and surface wave phenomena, singularity expansion theory, ducting, and applications to specific research problems such as underwater acoustics, crystallography, and health diagnostics.',
  },
  {
    cipFamily: '40',
    cipCode: '40.081',
    cipTitle: 'Theoretical and Mathematical Physics',
    cipDefinition:
      'A program that focuses on the scientific and mathematical formulation and evaluation of the physical laws governing, and models describing, matter-energy phenomena, and the analysis of related experimental designs and results.  Includes instruction in classical and quantum theory, relativity theory, field theory, mathematics of infinite series, vector and coordinate analysis, wave and particle theory, advanced applied calculus and geometry, analyses of continuum, cosmology, and statistical theory and analysis.',
  },
  {
    cipFamily: '40',
    cipCode: '40.0899',
    cipTitle: 'Physics, Other',
    cipDefinition: 'Any instructional program in physics not listed above.',
    Examples: 'Examples: - Electrodynamics',
  },
  {
    cipFamily: '40',
    cipCode: '40.10',
    cipTitle: 'Materials Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 40.1001 - 40.1099.',
  },
  {
    cipFamily: '40',
    cipCode: '40.1001',
    cipTitle: 'Materials Science',
    cipDefinition:
      'A program that focuses on the general application of mathematical and scientific principles to the analysis and evaluation of the characteristics and behavior of solids, including internal structure, chemical properties, transport and energy flow properties, thermodynamics of solids, stress and failure factors, chemical transformation states and processes, compound materials, and research on industrial applications of specific materials.',
    CrossReferences: '14.1801 - Materials Engineering., 40.1002 - Materials Chemistry.',
    Examples:
      'Examples: - Mechanical Properties of Materials, - Structural Materials, - Electronic and Optical Materials, - Biomaterials, - Materials Theory, - Surface Technology',
  },
  {
    cipFamily: '40',
    cipCode: '40.1002',
    cipTitle: 'Materials Chemistry',
    cipDefinition:
      'A program that focuses on the synthesis and study of organic or inorganic materials and their electronic, magnetic, optical or mechanical properties. Includes instruction in advanced materials for photonics, lasers, chemical sensors and arrays or nanochemistry; semiconductor nanowires; and molecular electronics.',
    CrossReferences: '14.1801 - Materials Engineering., 40.1001 - Materials Science.',
  },
  {
    cipFamily: '40',
    cipCode: '40.1099',
    cipTitle: 'Materials Sciences, Other',
    cipDefinition: 'Any instructional program in materials sciences not listed above.',
  },
  {
    cipFamily: '40',
    cipCode: '40.11',
    cipTitle: 'Physics and Astronomy',
    cipDefinition: 'Instructional content is defined in code 40.1101.',
  },
  {
    cipFamily: '40',
    cipCode: '40.1101',
    cipTitle: 'Physics and Astronomy',
    cipDefinition:
      'A program that focuses on the scientific study of the theories that explain the fundamental structure of our world and the universe and which draws from physics and astronomy. Includes instruction in astronomy, calculus, cosmology, differential equations, electricity, magnetism, data analysis, physics, quantum mechanics, space science, spectroscopy, and thermal physics.',
    CrossReferences: '40.0202 - Astrophysics.',
  },
  {
    cipFamily: '40',
    cipCode: '40.99',
    cipTitle: 'Physical Sciences, Other',
    cipDefinition: 'Instructional content is defined in code 40.9999.',
  },
  {
    cipFamily: '40',
    cipCode: '40.9999',
    cipTitle: 'Physical Sciences, Other',
    cipDefinition: 'Any instructional program in physical sciences not listed above.',
  },
  {
    cipFamily: '41',
    cipCode: '41',
    cipTitle: 'Science Technologies/Technicians',
    cipDefinition:
      'Instructional programs that prepare individuals to apply scientific principles and technical skills in support of scientific research and development.',
  },
  {
    cipFamily: '41',
    cipCode: '41.00',
    cipTitle: 'Science Technologies/Technicians, General',
    cipDefinition: 'Instructional content is defined in code 41.0000.',
  },
  {
    cipFamily: '41',
    cipCode: '41.0000',
    cipTitle: 'Science Technologies/Technicians, General',
    cipDefinition:
      'A general program that prepares individuals to apply scientific principles and technical skills in support of scientific research and development. Includes instruction in standard laboratory practices and procedures; techniques for analysis, testing and inspection; laboratory instrumentation and equipment operation and maintenance; laboratory and materials handling safety; and computer applications.',
    Examples: 'Examples: - Science Laboratory Technology',
  },
  {
    cipFamily: '41',
    cipCode: '41.01',
    cipTitle: 'Biology/Biotechnology Technologies/Technicians',
    cipDefinition: 'Instructional content is defined in code 41.0101.',
  },
  {
    cipFamily: '41',
    cipCode: '41.0101',
    cipTitle: 'Biology/Biotechnology Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply scientific principles and technical skills in support of biologists and biotechnologists in research, industrial, and government settings.  Includes instruction in fermentation technology, cell culturing, protein purification, biologic synthesis, assaying and testing, quality control, industrial microbiology, bioprocessing, chromatography and bioseparation, genetic technology, laboratory and hazardous materials safety, and computer applications.',
    CrossReferences: '51.1004 - Clinical/Medical Laboratory Technician.',
    Examples: 'Examples: - Biotechnology Laboratory Technician, - Biology Laboratory Technician',
  },
  {
    cipFamily: '41',
    cipCode: '41.02',
    cipTitle: 'Nuclear and Industrial Radiologic Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 41.0204 - 41.0299.',
  },
  {
    cipFamily: '41',
    cipCode: '41.0204',
    cipTitle: 'Industrial Radiologic Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply scientific principles and technical skills to the operation of industrial and research testing equipment using radioisotopes.  Includes instruction in x-ray analysis of materials, nondestructive testing and inspection of materials, and continuous measurement of paper or metal thickness.',
  },
  {
    cipFamily: '41',
    cipCode: '41.0205',
    cipTitle: 'Nuclear/Nuclear Power Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply scientific principles and technical skills in support of research scientists and operating engineers engaged in the running of nuclear reactors, and in nuclear materials processing and disposal.  Includes instruction in basic nuclear physics and nuclear engineering, monitoring and safety procedures, radioactive materials handling and disposal, equipment maintenance and operation, and record keeping.',
  },
  {
    cipFamily: '41',
    cipCode: '41.0299',
    cipTitle: 'Nuclear and Industrial Radiologic Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in nuclear and industrial radiologic technologies not listed above.',
  },
  {
    cipFamily: '41',
    cipCode: '41.03',
    cipTitle: 'Physical Science Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 41.0301 - 41.0399.',
  },
  {
    cipFamily: '41',
    cipCode: '41.0301',
    cipTitle: 'Chemical Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply scientific principles and technical skills in support of chemical and biochemical research and industrial operations.  Includes instruction in principles of chemistry and biochemistry, technical mathematics, computer applications, radiochemistry, industrial biochemistry, chemical instrumentation, physical chemistry, laboratory research methods, industrial processing methods and equipment, and test equipment operation and maintenance.',
  },
  {
    cipFamily: '41',
    cipCode: '41.0303',
    cipTitle: 'Chemical Process Technology',
    cipDefinition:
      'A program that prepares individuals to apply scientific principles and technical skills to the operation of chemical processing equipment in industries such as chemical manufacturing, petroleum refining, pharmaceutical manufacturing, and waste water treatment. Includes instruction in mathematics, chemistry, and physics; computer applications; chemical and refinery plant operations, processes, and equipment; safety, health, and environment; instrumentation; troubleshooting; and applications to specific industries.',
    Examples: 'Examples: - Chemical Process Technology',
  },
  {
    cipFamily: '41',
    cipCode: '41.0399',
    cipTitle: 'Physical Science Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in physical science technologies not listed above.',
  },
  {
    cipFamily: '41',
    cipCode: '41.99',
    cipTitle: 'Science Technologies/Technicians, Other',
    cipDefinition: 'Instructional content is defined in code 41.9999.',
  },
  {
    cipFamily: '41',
    cipCode: '41.9999',
    cipTitle: 'Science Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in science technologies not listed above.',
  },
  {
    cipFamily: '42',
    cipCode: '42',
    cipTitle: 'Psychology',
    cipDefinition:
      'Instructional programs that focus on the scientific study of the behavior of individuals, independently or collectively, and the physical and environmental bases of mental, emotional, and neurological activity.',
  },
  {
    cipFamily: '42',
    cipCode: '42.01',
    cipTitle: 'Psychology, General',
    cipDefinition: 'Instructional content is defined in code 42.0101.',
  },
  {
    cipFamily: '42',
    cipCode: '42.0101',
    cipTitle: 'Psychology, General',
    cipDefinition:
      'A general program that focuses on the scientific study of individual and collective behavior, the physical and environmental bases of behavior, and the analysis and treatment of behavior problems and disorders.  Includes instruction in the principles of the various subfields of psychology, research methods, and psychological assessment and testing methods.',
    CrossReferences: '30.1701 - Behavioral Sciences.',
  },
  {
    cipFamily: '42',
    cipCode: '42.27',
    cipTitle: 'Research and Experimental Psychology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 42.2701 - 42.2799.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2701',
    cipTitle: 'Cognitive Psychology and Psycholinguistics',
    cipDefinition:
      'A program that focuses on the scientific study of the mechanisms and processes of learning and thinking, and associated information encoding, decoding, processing and transmitting systems.  Includes instruction in theories of cognition and intelligence; studies of cognitive processes such as memory, sensation, perception, pattern recognition, problem solving, and conceptual thinking; cybernetics; psycholinguistics; and the study of biological and social communications mechanisms and processes.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2702',
    cipTitle: 'Comparative Psychology',
    cipDefinition:
      'A program that focuses on the behavior of members of particular species or groups and the relationship of the behaviors of the species or group to their evolutionary origins.  Includes instruction in differential psychology, experimental and physiological psychology, psychopharmacology, psychology of individual differences, reinforcement theory, and neurophysiology.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2703',
    cipTitle: 'Developmental and Child Psychology',
    cipDefinition:
      'A program that focuses on the scientific study of the unique stages of psychological growth and development of individuals from infancy through childhood. Includes instruction in cognitive and perceptual development, emotional development, personality development, the effects of biological maturation on behavior, theories of cognitive growth and related research methods, testing and assessment methods for different age levels, research on child and adolescent behavior therapy, and the psychology of aging.',
    CrossReferences: '19.0706 - Child Development.',
    Examples: 'Examples: - Child Psychology, - Lifespan Development',
  },
  {
    cipFamily: '42',
    cipCode: '42.2704',
    cipTitle: 'Experimental Psychology',
    cipDefinition:
      'A program that focuses on the scientific study of behavior under experimental conditions and the analysis of controlled behavioral responses.  Includes instruction in learning theory, research design and experimental methods, psychological measurement, statistical design and methods, analysis of cognitive and behavioral variables, and the conduct of specialized and large-scale studies.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2705',
    cipTitle: 'Personality Psychology',
    cipDefinition:
      'A program that focuses on the unique organization of fairly permanent characteristics that set the individual apart from other individuals and, at the same time, determine how others respond to that individual.   Includes instruction in Freudian, Skinnerian, Rogerian, and Ellisonian psychology; neo-Freudian psychology; psychological systems, neurophysiology, and psychopharmacology.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2706',
    cipTitle: 'Behavioral Neuroscience',
    cipDefinition:
      'A program that focuses on the scientific study of the biological bases of behavior and psychological functioning, and their application to experimental and therapeutic research problems.  Includes instruction in functional neuroanatomy, neural system development, biochemical neural regulatory mechanisms, neurological biophysics, memory storage and retrieval, physiology of cognition and perception, physiological bases of psychopathology and behavioral disorders, psychopharmacology, comparative psychobiology, molecular and cellular neuroscience, brain science, anatomy and physiology of the central nervous system, and specialized experimental design and research methods.',
    CrossReferences:
      '30.1701 - Behavioral Sciences., 26.1501 - Neuroscience., 30.1001 - Biopsychology., 26.1504 - Neurobiology and Behavior.',
    Examples: 'Examples: - Physiological Psychology, - Psychobiology',
  },
  {
    cipFamily: '42',
    cipCode: '42.2707',
    cipTitle: 'Social Psychology',
    cipDefinition:
      'A program that focuses on the scientific study of individual behavior in group contexts, group behavior, and associated phenomena.  Includes instruction in social learning theory, group theory and dynamics, sex roles, social cognition and inference, attribution theory, attitude formation, criminal behavior and other social pathologies, altruistic behavior, social development, and social ecology.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2708',
    cipTitle: 'Psychometrics and Quantitative Psychology',
    cipDefinition:
      'A program that focuses on the mathematical and statistical procedures used in psychological test construction and validation; the general problems of the measurement of behavior; and the application of quantitative methods to research design and other methodological topics.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2709',
    cipTitle: 'Psychopharmacology',
    cipDefinition:
      'A program that focuses on the study of the behavioral effects of medications, drugs, nutrients, and chemicals (including natural and artificial toxins) in laboratory and clinical settings.  Includes instruction in pharmacology, behavioral toxicology and pharmacology, neuroscience, physiology, clinical psychopharmacology, substance abuse treatments and antidotes, neurochemical mechanisms, epidemiology of chemicals and substances, biochemical interactions, and psychoactive drugs.',
  },
  {
    cipFamily: '42',
    cipCode: '42.271',
    cipTitle: 'Developmental and Adolescent Psychology',
    cipDefinition:
      'A program that focuses on the scientific study of the unique stages of psychological growth and development of individuals from adolescence to adulthood. Includes instruction in cognitive and perceptual development, emotional development, personality development, the effects of biological maturation on behavior, theories of cognitive growth and related research methods, testing and assessment methods for different age levels, research on child and adolescent behavior therapy, and the psychology of aging.',
    Examples: 'Examples: - Adolescent Development',
  },
  {
    cipFamily: '42',
    cipCode: '42.2799',
    cipTitle: 'Research and Experimental Psychology, Other',
    cipDefinition: 'Any instructional program in research and experimental psychology not listed above.',
  },
  {
    cipFamily: '42',
    cipCode: '42.28',
    cipTitle: 'Clinical, Counseling and Applied Psychology',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 42.2801 - 42.2899. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2801',
    cipTitle: 'Clinical Psychology',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of clinical psychology, involving the analysis, diagnosis, and clinical treatment of psychological disorders and behavioral pathologies.  Includes instruction in clinical assessment and diagnosis, personality appraisal, psychopathology, clinical psychopharmacology, behavior modification, therapeutic intervention skills, patient interviewing, personalized and group therapy, child and adolescent therapy, cognitive and behavioral therapy, supervised clinical practice, ethical standards, and applicable regulations.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2802',
    cipTitle: 'Community Psychology',
    cipDefinition:
      'A program that prepares individuals to apply psychological principles to the analysis of social problems, and the implementation of intervention strategies for addressing these problems.  Includes instruction in social ecology, primary and secondary prevention of social pathologies, social intervention strategies and tactics, large group counseling, social services systems behavior, creating settings, cultural stress, and the dynamics of social change.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2803',
    cipTitle: 'Counseling Psychology',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of psychological counseling, involving the rendering of therapeutic services to individuals and groups experiencing psychological problems and exhibiting distress symptoms.  Includes instruction in counseling theory, therapeutic intervention strategies, patient/counselor relationships, testing and assessment methods and procedures, group therapy, marital and family therapy, child and adolescent therapy, supervised counseling practice, ethical standards, and applicable regulations.',
    CrossReferences: '51.1508 - Mental Health Counseling/Counselor.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2804',
    cipTitle: 'Industrial and Organizational Psychology',
    cipDefinition:
      'A program that focuses on the scientific study of individual and group behavior in institutional settings, applications to related problems of organization and industry, and that may prepare individuals to apply such principles in industrial and organizational settings.  Includes instruction in group behavior theory, organizational theory, reward/punishment structures, human-machine and human-computer interactions, motivation dynamics, human stress studies, environmental and organizational influences on behavior, alienation and satisfaction, and job testing and assessment.',
    CrossReferences: '52.1003 - Organizational Behavior Studies.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2805',
    cipTitle: 'School Psychology',
    cipDefinition:
      'A  program that prepares individuals to apply clinical and counseling psychology principles to the diagnosis and treatment of student behavioral problems.  Includes instruction in child and/or adolescent development; learning theory; testing, observation and other procedures for assessing educational, personality, intelligence and motor skill development; therapeutic intervention strategies for students and families; identification and classification of disabilities and disorders affecting learning; school psychological services planning; supervised counseling practice; ethical standards; and applicable regulations.',
    CrossReferences: '13.1101 - Counselor Education/School Counseling and Guidance Services.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2806',
    cipTitle: 'Educational Psychology',
    cipDefinition:
      'A program that focuses on the application of psychology to the study of the behavior of individuals in the roles of teacher and learner, the nature and effects of learning environments, and the psychological effects of methods, resources, organization and non-school experience on the educational process.  Includes instruction in learning theory, human growth and development, research methods, and psychological evaluation.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2807',
    cipTitle: 'Clinical Child Psychology',
    cipDefinition:
      'A program that focuses on the developmental processes of children and associated disorders and that prepares individuals for the independent professional practice of clinical child psychology.  Includes instruction in developmental neuropsychology, child psychopathology, testing of children and adolescents, pediatric psychology, psychosocial variables in child development, clinical skills, family therapy, behavioral assessment and intervention, clinical skills, consultation, professional standards and ethics, and applications to topics such as infancy, childhood, and adolescence.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2808',
    cipTitle: 'Environmental Psychology',
    cipDefinition:
      'A program that focuses on the study of behavioral interactions between human beings and the environment in individual and group contexts, and ways to improve them.  Includes instruction in contextual theory; statistics; physiological, social, and psychological responses to natural and technological hazards and disease; environmental perception and cognition; loneliness and stress; and psychological aspects of environmental design and planning.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2809',
    cipTitle: 'Geropsychology',
    cipDefinition:
      'A program that focuses on the psychology of aging and of elderly individuals and populations, with reference to growth and decline across the life span.  Includes instruction in gerontology, developmental and adult psychology, age-related development and decline of cognitive processes, age-related psychological and neurological disorders, social and personality development in aging populations and individuals, and applications to the clinical treatment and care of older adults.',
    CrossReferences: '19.0702 - Adult Development and Aging., 30.1101 - Gerontology.',
  },
  {
    cipFamily: '42',
    cipCode: '42.281',
    cipTitle: 'Health/Medical Psychology',
    cipDefinition:
      'A program that focuses on the psychological study of health and illness, and recovery,  from the perspective that these phenomena arise from a combination of physical, behavioral, and social conditions.  Includes instruction in abnormal and social psychology, psychophysiology, psychopharmacology, learning processes, clinical and behavioral therapies, biopsychosocial modeling, clinical procedures and assessment, preventive education, psychotherapy, rehabilitation processes, and research methods.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2811',
    cipTitle: 'Family Psychology',
    cipDefinition:
      'A program that prepares individuals to provide therapeutic, evaluative, and research services to families and individuals in the family unit context.  Includes instruction in natural and family systems theory, family and group rituals, family evaluation and assessment, marital and couples therapy, sex therapy, parenting, interviewing techniques, genogram construction, family sculpting, diversity issues, family violence, family law, and professional standards and ethics.',
    CrossReferences: '51.1505 - Marriage and Family Therapy/Counseling., 19.0704 - Family Systems.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2812',
    cipTitle: 'Forensic Psychology',
    cipDefinition:
      'A program that prepares individuals to apply clinical, counseling, school, and neuropsychology skills to the provision of psychological services within the criminal justice and civil legal systems, including consultation, assessment, and interventions.  Includes instruction in the epidemiology of mental/behavioral disorders, risk factors for violence and criminality, profiling and patterning, psychological testing, prediction and intervention measurement, forensic assessment, criminal and civil law and procedures, secure environments, forensic treatment and intervention skills, and professional standards and ethics.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2813',
    cipTitle: 'Applied Psychology',
    cipDefinition:
      'A program that focuses on the application of psychological theories and methods to real-world settings and problems, such as business and industry, government, education, military, and community settings. Includes instruction in applications of psychology, industrial/organizational psychology, developmental psychology, social psychology, cognitive psychology, counseling, human factors, research methods and statistics, and program evaluation.',
    CrossReferences: '30.3101 - Human Computer Interaction.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2814',
    cipTitle: 'Applied Behavior Analysis',
    cipDefinition:
      'A program that focuses on the application of principles of learning and behavior to enhance the development, abilities, and choices of children and adults; and that prepares individuals to address the behavioral needs of individuals, especially those with developmental disabilities and autism. Includes instruction in behavior analysis and learning, behavioral and functional assessment, developmental psychology, applied behavioral analysis in developmental disabilities and autism, applied behavioral analysis in mental health and aging, research methods, evaluation of interventions, and professional and ethical issues.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2815',
    cipTitle: 'Performance and Sport Psychology',
    cipDefinition:
      'A program that focuses on the motor, physiological, and psychosocial aspects of sport behavior. Includes instruction in applied sport psychology, clinical mental health counseling, coaching, exercise physiology, ethical performance enhancement; human development, leadership and team building, mind-body practices, neuroscience, and personality and health.',
    CrossReferences: '31.0508 - Sports Studies.',
    Examples: 'Examples: - Sport and Exercise Psychology',
  },
  {
    cipFamily: '42',
    cipCode: '42.2816',
    cipTitle: 'Somatic Psychology',
    cipDefinition:
      'A program that focuses on a holistic approach to solving interpersonal issues and psychological problems. Includes instruction in authentic movement, body-mind balance and integration, contact improvisation, dance, Pilates, Tai Chi, relaxation techniques, and Yoga.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2817',
    cipTitle: 'Transpersonal/Spiritual Psychology',
    cipDefinition:
      'A program that prepares individuals to pursue spiritual and psychological practices for counseling. Includes instruction in emotional intelligence, expressive movement, healing practices and rituals, human consciousness, movement and meditation, psychosomatic connection, spiritual systems and wellness, transpersonal counseling, and therapeutic trance.',
  },
  {
    cipFamily: '42',
    cipCode: '42.2899',
    cipTitle: 'Clinical, Counseling and Applied Psychology, Other',
    cipDefinition: 'Any instructional program in clinical, counseling and applied psychology not listed above.',
  },
  {
    cipFamily: '42',
    cipCode: '42.99',
    cipTitle: 'Psychology, Other',
    cipDefinition: 'Instructional content is defined in code 42.9999.',
  },
  {
    cipFamily: '42',
    cipCode: '42.9999',
    cipTitle: 'Psychology, Other',
    cipDefinition: 'Any instructional program in psychology not listed above.',
    Examples: 'Examples: - Neuropsychology',
  },
  {
    cipFamily: '43',
    cipCode: '43',
    cipTitle: 'Homeland Security, Law Enforcement, Firefighting and Related Protective Services',
    cipDefinition:
      'Instructional programs that focus on the principles and procedures for providing homeland security, police, fire, and other safety services and managing penal institutions. Note: this series is titled Security and Protective Services" in the Canadian CIP."',
  },
  {
    cipFamily: '43',
    cipCode: '43.01',
    cipTitle: 'Criminal Justice and Corrections',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 43.0100 - 43.0199.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0100',
    cipTitle: 'Criminal Justice and Corrections, General',
    cipDefinition:
      'A program of study that focuses on the general study of criminal justice and corrections. Includes instruction in criminology, criminal justice, correctional science, forensic science, law enforcement, psychology, and ethics.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0102',
    cipTitle: 'Corrections',
    cipDefinition:
      'A program that prepares individuals to study the theories and principles, of correctional science and to function as professional corrections officers and other workers in public and/or private incarceration facilities.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0103',
    cipTitle: 'Criminal Justice/Law Enforcement Administration',
    cipDefinition:
      'A program that prepares individuals to apply theories and practices of organization management and criminal justice to the administration of public law enforcement agencies and operations.  Includes instruction in law enforcement history and theory, operational command leadership, administration of public police organizations, labor relations, incident response strategies, legal and regulatory responsibilities, budgeting, public relations, and organizational leadership.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0104',
    cipTitle: 'Criminal Justice/Safety Studies',
    cipDefinition:
      'A program that focuses on the criminal justice system, its organizational components and processes, and its legal and public policy contexts.  Includes instruction in criminal law and policy, police and correctional systems organization, the administration of justice and the judiciary, and public attitudes regarding criminal justice issues.',
    CrossReferences: '45.0401 - Criminology.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0106',
    cipTitle: 'Forensic Science and Technology',
    cipDefinition: 'Moved from 43.0106 to 43.0406',
  },
  {
    cipFamily: '43',
    cipCode: '43.0107',
    cipTitle: 'Criminal Justice/Police Science',
    cipDefinition:
      'A program that prepares individuals to perform the duties of police and public security officers, including patrol and investigative activities, traffic control, crowd control and public relations, witness interviewing, evidence collection and management, basic crime prevention methods, weapon and equipment operation and maintenance, report preparation and other routine law enforcement responsibilities.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0109',
    cipTitle: 'Security and Loss Prevention Services',
    cipDefinition:
      'A program that prepares individuals to perform routine inspection, patrol and crime prevention services for private clients.  Includes instruction in the provision of personal protection as well as property security.',
  },
  {
    cipFamily: '43',
    cipCode: '43.011',
    cipTitle: 'Juvenile Corrections',
    cipDefinition:
      'A program that prepares individuals to specialize in the provision of correction services to underage minor populations.  Includes instruction in corrections, juvenile delinquency, juvenile development and psychology, juvenile law and justice administration, social services, record-keeping procedures, and communication skills.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0111',
    cipTitle: 'Criminalistics and Criminal Science',
    cipDefinition: 'Moved from 43.0111 to 43.0402',
  },
  {
    cipFamily: '43',
    cipCode: '43.0112',
    cipTitle: 'Securities Services Administration/Management',
    cipDefinition:
      'A program that prepares individuals to plan, manage, and supervise services providing private security protection for people and property and related investigative and consulting functions.  Includes instruction in security and loss prevention services, private security and investigation techniques, security technologies, personnel management, business operations, marketing, applicable law and regulations, and client relations.',
    Examples: 'Examples: - Business and Organizational Security Management',
  },
  {
    cipFamily: '43',
    cipCode: '43.0113',
    cipTitle: 'Corrections Administration',
    cipDefinition:
      'A program that prepares individuals to plan and manage institutional facilities and programs for housing and rehabilitating prisoners in the public and/or private sectors.  Includes instruction in the principles and practice of correction, facilities planning and management, safety and security, social and health services, staff and inmate management, budgeting, communication skills, correctional psychology, and applications to specific types of correctional facilities approaches.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0114',
    cipTitle: 'Law Enforcement Investigation and Interviewing',
    cipDefinition:
      'A program focusing on the principles, procedures, techniques, legal concerns, and problems associated with a criminal investigation.  Includes instruction in administrative law and procedures, courtroom evidence management and preparation, case presentation and court testimony, description and identification, state and federal criminal law and procedures, informant and suspect rights, officer liability, informant and witness management, victim awareness, tactical interviewing, psychology, criminal investigation methods and procedures, report writing and documentation, investigative techniques, and case management.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0115',
    cipTitle: 'Law Enforcement Record-Keeping and Evidence Management',
    cipDefinition:
      'A program focusing on the principles and procedures for managing records in police or other security offices, including storing and retrieving evidence and related data.  Includes instruction in administrative law and procedures, office management, report preparation, organizational software, database software, physical and digital evidence properties, controlled storage and retrieval, investigative file inventories, case management, security systems, and communications skills.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0116',
    cipTitle: 'Cyber/Computer Forensics and Counterterrorism',
    cipDefinition: 'Moved from 43.0116 to 43.0403',
  },
  {
    cipFamily: '43',
    cipCode: '43.0117',
    cipTitle: 'Financial Forensics and Fraud Investigation',
    cipDefinition: 'Moved from 43.0117 to 43.0405',
  },
  {
    cipFamily: '43',
    cipCode: '43.0118',
    cipTitle: 'Law Enforcement Intelligence Analysis',
    cipDefinition: 'Moved from 43.0118 to 43.0408',
  },
  {
    cipFamily: '43',
    cipCode: '43.0119',
    cipTitle: 'Critical Incident Response/Special Police Operations',
    cipDefinition:
      'A program focusing on the principles and techniques for dealing with police emergencies such as hostage situations, bomb threats, barricades and terrorist incidents.  Includes instruction in crisis management, command procedures, incident containment, information collection and debriefing, first responder negotiation, victim and criminal psychology, site survey and surveillance, special weapons and tactics, interagency communications and joint operations, pre-confrontation and contingency planning, assault and rescue operations, security and crowd control, media relations, and post-operation procedures.',
  },
  {
    cipFamily: '43',
    cipCode: '43.012',
    cipTitle: 'Protective Services Operations',
    cipDefinition:
      'A program focusing on the principles and techniques of providing physical security protection to clients in various environments and situations.  Includes instruction in security intelligence and information resources, operations planning and surveying, operations security, weapons and explosives, defense and offense  techniques, security and surveillance systems, communications systems, perimeter and access control, weapons of mass destruction, contingency planning, crowd control, cover and evacuation, combat and vehicle techniques, and applicable legal and administrative responsibilities.',
    Examples:
      'Examples: - Land Transportation Security, - Physical Security, - Protective Operations, - Evasive and Defensive Driving',
  },
  {
    cipFamily: '43',
    cipCode: '43.0121',
    cipTitle: 'Suspension and Debarment Investigation',
    cipDefinition:
      'A program focusing on the principles and techniques for investigating criminal behavior among government contractors and grantees at different levels.  Includes instruction in financial fraud and money-laundering, auditing, computer investigations, legal concepts and applications, procurement law, non-procurement debarment, jurisdiction and lead agency, analytical methods, suspension, parallel proceedings, evidence protection, equitable considerations, investigative techniques, administrative and global solutions, case management and preparation.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0122',
    cipTitle: 'Maritime Law Enforcement',
    cipDefinition:
      'A program focusing on the application of law enforcement and security principles and procedures to the marine environment.  Includes instruction in criminal and nautical law, nautical terminology, seamanship and vessel operation, vessel maintenance, navigation and navigation systems, meteorology, vessel and passenger safety, radar and remote sensing systems, communications systems, vessel pursuit and stop, boarding and arrest techniques, investigative and evidence techniques, weaponry  and applications to specific situations such as piracy, drug trafficking and smuggling.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0123',
    cipTitle: 'Cultural/Archaelogical Resources Protection',
    cipDefinition:
      'A program focusing on the application of law enforcement principles and techniques to the protection of cultural resources and the investigation of related crimes.  Includes instruction in cultural resources law, archaeological standards of value, site damage assessment, evidence collection, surveillance techniques, investigative techniques, case management and preparation.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0199',
    cipTitle: 'Corrections and Criminal Justice, Other',
    cipDefinition: 'Any instructional program in corrections and criminal justice not listed above.',
    CrossReferences: '3.0208 - Environmental/Natural Resources Law Enforcement and Protective Services.',
  },
  {
    cipFamily: '43',
    cipCode: '43.02',
    cipTitle: 'Fire Protection',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 43.0201 - 43.0299.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0201',
    cipTitle: 'Fire Prevention and Safety Technology/Technician',
    cipDefinition:
      'A program focusing on the application of fire science and technology to problems of reducing fire risk, limiting loss, supervising substance removal, conducting safety inspections and investigations, and advising on matters of safety procedures and fire prevention policy.  Includes instruction in fire behavior, fire simulation, structural risk assessment, materials analysis, detection and suppression systems, smoke management, supply and evacuation, public education, legal aspects of fire prevention, and related research and communications methods.',
    Examples: 'Examples: - Fire Prevention Education, - Fire Risk Prevention and Inspection',
  },
  {
    cipFamily: '43',
    cipCode: '43.0202',
    cipTitle: 'Fire Services Administration',
    cipDefinition:
      'A program focusing on the principles, theory, and practices associated with the management of fire operations, firefighting services, and community fire issues.  Includes instruction in fire protection history and theory, incident command leadership, administration of public fire organizations, labor relations, emergency medical services management, fire emergency response strategies and mitigation, legal and regulatory responsibilities, budgeting, public relations, and organizational leadership.',
    Examples:
      'Examples: - Fire Administration, - Executive Fire Officer, - Fire Service and Management Information Systems',
  },
  {
    cipFamily: '43',
    cipCode: '43.0203',
    cipTitle: 'Fire Science/Fire-fighting',
    cipDefinition:
      'A program focusing on the theory and practice of fires and fire-fighting.  Includes instruction in fire chemistry and physics, combustible materials, computer science, building construction, fire codes and related laws, fire hydraulics, fire command, fire prevention/inspection, fire protection systems, fire suppression systems, fire/arson investigation, occupational safety, equipment operation, emergency medicine and communications.',
    Examples: 'Examples: - Firefighter Training',
  },
  {
    cipFamily: '43',
    cipCode: '43.0204',
    cipTitle: 'Fire Systems Technology',
    cipDefinition:
      'A program focusing on the principles and practice of firefighting systems, building construction and related resources as applied to fire prevention, control, and mitigation.  Includes instruction in fire hydraulics and dynamics, fire protection structures and systems design, fire behavior and combustion, fire protection hydraulics and water supply, fire protection equipment and systems, building construction for fire protection, and fire apparatus.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0205',
    cipTitle: 'Fire/Arson Investigation and Prevention',
    cipDefinition:
      'A program focusing on the theory and principles of fire combustion and behavior applied to the analysis of fires and their causes.  Includes instruction in fire behavior and combustion, fire dynamics, hazardous materials chemistry, incendiary fire analysis, fire-related human behavior, forensic procedures, investigative techniques, case management and case preparation.',
    Examples: 'Examples: - Arson Prevention and Investigation, - Fire Investigation, - Fire Analysis',
  },
  {
    cipFamily: '43',
    cipCode: '43.0206',
    cipTitle: 'Wildland/Forest Firefighting and Investigation',
    cipDefinition:
      'A program focusing on the application of fire science, firefighting and investigation to the prevention, control and mitigation of wildland fires and the analysis of causes.  Includes instruction in fire science and behavior, wildland environments, meteorology, wildland fire hydraulics and dynamics, water and chemical supply systems, wildland firefighting operations, airborne firefighting, firebreak engineering, communications systems, wildland arson, forensic procedures, wildland fire prevention, investigation techniques, case management and preparation.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0299',
    cipTitle: 'Fire Protection, Other',
    cipDefinition: 'Any instructional program in fire protection not listed above.',
  },
  {
    cipFamily: '43',
    cipCode: '43.03',
    cipTitle: 'Homeland Security',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 43.0301 - 43.0399.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0301',
    cipTitle: 'Homeland Security',
    cipDefinition:
      'A program focusing on security policy, planning and operations dedicated to the protection of U.S. territory, assets, infrastructure, institutions and citizens from external threats.  Includes instruction in national security policy, government relations, intelligence, law enforcement, security technology, communications and information technology, homeland security planning and operations, disaster planning and applications to specific threat scenarios.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0302',
    cipTitle: 'Crisis/Emergency/Disaster Management',
    cipDefinition:
      'A program focusing on the application of the incident command system model to formulating and implementing effective response to natural and man-made disasters.  Includes instruction in contingency planning, hazard and risk assessment, joint operations, law and ethics, emergency response and recovery, event mitigation, emergency rescue and medical operations, incident command, terrorism and national security issues, law enforcement, relief administration, volunteer and citizen coordination, public relations and applications to specific types of incidents.',
    Examples:
      'Examples: - Disaster Planning and Management, - Emergency and Disaster Mitigation, - Emergency Preparedness and Technology, - Incident Command Systems, - Emergency Training, - Disaster Operations and Recovery, - Integrated Emergency Management, - Disaster Medicine and Management, - Public Health Preparedness and Emergency Management',
  },
  {
    cipFamily: '43',
    cipCode: '43.0303',
    cipTitle: 'Critical Infrastructure Protection',
    cipDefinition:
      'A program focusing on the design, planning and management of systems and procedures for protecting critical national physical and cyber infrastructure from external threats, including terrorism.  Includes instruction in homeland security policy, critical infrastructure policy, information security, matrix vulnerability assessment, threat assessment, physical security, personnel security, operational security, contingency planning, case analyses of specific industries and systems, redundancy planning, emergency and disaster planning, security systems, and intelligence operations.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0304',
    cipTitle: 'Terrorism and Counterterrorism Operations',
    cipDefinition:
      'A program focusing on the study of terrorism as a global and national threat and the methods for analyzing and countering it.  Includes instruction in psychology, cultural studies, terrorist history and organization, terrorist capabilities, terrorist finance and international money-laundering, threat assessment, intelligence operations, incident command systems, border security, emergency response, joint operations, surveillance and communications systems, cyberterrorism, weapons of mass destruction, counterterrorist operations, and applications to specific terrorist organizations and threats.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0399',
    cipTitle: 'Homeland Security, Other',
    cipDefinition: 'Any instructional program in homeland security not listed above.',
    Examples:
      'Examples: - Search and Rescue Technician, - Emergency Telecommunicator, - Emergency Services Communications, - Public Safety Communications',
  },
  {
    cipFamily: '43',
    cipCode: '43.04',
    cipTitle: 'Security Science and Technology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 43.0401 - 43.0499.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0401',
    cipTitle: 'Security Science and Technology, General',
    cipDefinition: 'A program of study that focuses on the general application of science and technology to security.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0402',
    cipTitle: 'Criminalistics and Criminal Science',
    cipDefinition:
      'A program that focuses on the application of clinical and criminal laboratory science, investigative techniques, and criminology to the reconstruction of crimes and the analysis of physical evidence. Includes instruction in laboratory science, laboratory procedures, criminology and police science, evidentiary testing and analysis, computer applications, record-keeping, reconstruction techniques, evidence handling and storage, and applications to specific types of evidence and crimes.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0403',
    cipTitle: 'Cyber/Computer Forensics and Counterterrorism',
    cipDefinition:
      'A program focusing on the principles and techniques used to identify, search, seize and analyze digital media and to conduct cyber investigations against criminal and terrorist activity.  Includes instruction in computer boot processes and drives, jumper setting, file access and reconstruction, hacking, network systems, cryptography, programming, investigative techniques, forensic imagery, web-based investigation methods, cyberterrorism, and applicable laws and administrative procedures.',
    CrossReferences: '11.1003 - Computer and Information Systems Security/Auditing/Information Assurance.',
    Examples: 'Examples: - Internet Investigation',
  },
  {
    cipFamily: '43',
    cipCode: '43.0404',
    cipTitle: 'Cybersecurity Defense Strategy/Policy',
    cipDefinition:
      'A program that focuses on the study of strategy, policy, and standards regarding the security of and operations in cyberspace. Includes instruction in incident response, information assurance, recovery policies, vulnerability reduction, deterrence, threat reduction, and resiliency.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0405',
    cipTitle: 'Financial Forensics and Fraud Investigation',
    cipDefinition:
      'A program focusing on the principles and techniques of conducting investigations into financial crime, terrorist activity, and the analysis and use of accounting data as evidence.  Includes instruction in the principles of accounting, investigative auditing, computer investigations,  accounting system documents and software, business corruption, criminal and terrorist financial networks, international money markets and movement, net worth analysis, financial fraud, exposing concealed assets, records seizure, fraud and money laundering statutes, fraud case initiation, case management, and case presentation.',
    Examples:
      'Examples: - Fraud and Money-Laundering, - International Banking and Money-Laundering, - Terrorist Finance, - Asset Forfeiture, - Economic Crime Investigation, - Investigative and Forensic Accounting',
  },
  {
    cipFamily: '43',
    cipCode: '43.0406',
    cipTitle: 'Forensic Science and Technology',
    cipDefinition:
      'A program that focuses on the application of the physical, biomedical, and social sciences to the analysis and evaluation of physical evidence, human testimony and criminal suspects. Includes instruction in forensic medicine, forensic dentistry, anthropology, psychology, entomology, pathology, forensic laboratory technology and autopsy procedures, DNA and blood pattern analysis, crime scene analysis, crime scene photography, fingerprint technology, document analysis, witness and suspect examination procedures, applicable law and regulations, and professional standards and ethics.',
    CrossReferences:
      '42.2812 - Forensic Psychology., 40.0510 - Forensic Chemistry., 44.0703 - Forensic Social Work., 45.0205 - Forensic Anthropology.',
    Examples: 'Examples: - Crime Scene Investigation',
  },
  {
    cipFamily: '43',
    cipCode: '43.0407',
    cipTitle: 'Geospatial Intelligence',
    cipDefinition:
      'A program that prepares individuals to analyze security and intelligence problems using a geographic perspective by relating human actions to cultural, political, economic, social, and physical landscapes. Includes instruction in aerial photography analysis, cartography, geographic information systems (GIS), physical geography, remote sensing, spatial programming, and quantitative methods in geographic research.',
    CrossReferences: '45.0702 - Geographic Information Science and Cartography.',
  },
  {
    cipFamily: '43',
    cipCode: '43.0408',
    cipTitle: 'Law Enforcement Intelligence Analysis',
    cipDefinition:
      'A program focusing on the preparation of law enforcement personnel to perform intelligence and surveillance operations and to analyze and use data collected via such operations.  Includes instruction in the intelligence cycle, information sources, data retrieval, digital investigation, financial investigations, document analysis, external sources, geographic information systems, link analysis, operation planning and security, case management, applicable law and regulations, and case preparation.',
    Examples: 'Examples: - Electronic Surveillance',
  },
  {
    cipFamily: '43',
    cipCode: '43.0499',
    cipTitle: 'Security Science and Technology, Other',
    cipDefinition: 'Any instructional program in security science and technology not listed above.',
  },
  {
    cipFamily: '43',
    cipCode: '43.99',
    cipTitle: 'Homeland Security, Law Enforcement, Firefighting and Related Protective Services, Other',
    cipDefinition: 'Instructional content is defined in code 43.9999.',
  },
  {
    cipFamily: '43',
    cipCode: '43.9999',
    cipTitle: 'Homeland Security, Law Enforcement, Firefighting and Related Protective Services, Other',
    cipDefinition:
      'Any instructional program in homeland security, law enforcement, firefighting, and related protective services not listed above.',
  },
  {
    cipFamily: '44',
    cipCode: '44',
    cipTitle: 'Public Administration and Social Service Professions',
    cipDefinition:
      'Instructional programs that prepare individuals to analyze, manage, and deliver public programs and services.',
  },
  {
    cipFamily: '44',
    cipCode: '44.00',
    cipTitle: 'Human Services, General',
    cipDefinition: 'Instructional content is defined in code 44.0000.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0000',
    cipTitle: 'Human Services, General',
    cipDefinition:
      'A program that focuses on the general study and provision of human and social services to individuals and communities and prepares individuals to work in public and private human services agencies and organizations.  Includes instruction in the social sciences, psychology, principles of social service, human services policy, planning and evaluation, social services law and administration, and applications to particular issues, services, localities, and populations.',
    Examples: 'Examples: - Human Service Studies, - Human Services Studies',
  },
  {
    cipFamily: '44',
    cipCode: '44.02',
    cipTitle: 'Community Organization and Advocacy',
    cipDefinition: 'Instructional content is defined in code 44.0201.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0201',
    cipTitle: 'Community Organization and Advocacy',
    cipDefinition:
      'A program that focuses on the theories, principles, and practice of organizing and providing services to communities. May prepare individuals to apply such knowledge and skills in community service positions.',
  },
  {
    cipFamily: '44',
    cipCode: '44.04',
    cipTitle: 'Public Administration',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 44.0401 - 44.0499.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0401',
    cipTitle: 'Public Administration',
    cipDefinition:
      'A program that prepares individuals to serve as managers in the executive arm of local, state, and federal government and that focuses on the systematic study of executive organization and management.  Includes instruction in the roles, development, and principles of public administration; the management of public policy; executive-legislative relations; public budgetary processes and financial management; administrative law; public personnel management; professional ethics; and research methods.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0402',
    cipTitle: 'Public Works Management',
    cipDefinition:
      'A program of study that focuses on public works management skills and public works operation. Includes instruction in accountability, budget, conflict resolution strategies, ethics, finance, human resources, labor and employee relations, and operations management.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0403',
    cipTitle: 'Transportation and Infrastructure Planning/Studies',
    cipDefinition:
      'A program that focuses on the economic, social, spatial, and environmental aspects of transportation and infrastructure planning. Includes instruction in economics, environmental analysis, geographic information systems (GIS), logistics, risk analysis, transportation economics, transportation evaluation, transportation planning, transportation policy, and urban transportation planning.',
    CrossReferences: '52.0209 - Transportation/Mobility Management.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0499',
    cipTitle: 'Public Administration, Other',
    cipDefinition: 'Any program in public administration not listed above.',
  },
  {
    cipFamily: '44',
    cipCode: '44.05',
    cipTitle: 'Public Policy Analysis',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 44.0501 - 44.0599.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0501',
    cipTitle: 'Public Policy Analysis, General',
    cipDefinition:
      'A program that focuses on the systematic analysis of public policy issues and decision processes.  Includes instruction in the role of economic and political factors in public decision-making and policy formulation, microeconomic analysis of policy issues, resource allocation and decision modeling, cost/benefit analysis, statistical methods, and applications to specific public policy topics.',
    Examples: 'Examples: - Public Policy Analysis',
  },
  {
    cipFamily: '44',
    cipCode: '44.0502',
    cipTitle: 'Education Policy Analysis',
    cipDefinition:
      'A program that focuses on the systematic analysis of public policy issues related to education and educational systems at the local, state, national, and international levels. Includes instruction in social and cultural issues in education, contemporary educational policies, history of education, economics of education, international and comparative education, education and social change, educational research and evaluation methods, and applications to specific public policy topics.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0503',
    cipTitle: 'Health Policy Analysis',
    cipDefinition:
      'A program that focuses on the systematic analysis of public policy issues related to domestic and international health and health care systems. Includes instruction in contemporary health issues and policies, politics and economics of health care delivery, epidemiology, public health, comparative and global health, medical law and ethics, statistical methods, and applications to specific public policy topics.',
    CrossReferences: '51.2201 - Public Health, General.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0504',
    cipTitle: 'International Policy Analysis',
    cipDefinition:
      'A program that focuses on the systematic analysis of public policy issues related to relationships among nations and among governments and non-governmental entities. Includes instruction in international politics, governance, financial policy, and security affairs; globalization; economic and social policies of developing nations; and applications to specific public policy topics such as labor and employment, immigration, and human rights.',
    CrossReferences: '51.2210 - International Public Health/International Health.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0580',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0599',
    cipTitle: 'Public Policy Analysis, Other',
    cipDefinition: 'Any instructional program in public policy analysis not listed above.',
  },
  {
    cipFamily: '44',
    cipCode: '44.07',
    cipTitle: 'Social Work',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 44.0701 - 44.0799.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0701',
    cipTitle: 'Social Work',
    cipDefinition:
      'A program that prepares individuals for the professional practice of social welfare administration and counseling, and that focus on the study of organized means of providing basic support services for vulnerable individuals and groups.   Includes instruction in social welfare policy; case work planning; social counseling and intervention strategies; administrative procedures and regulations; and specific applications in areas such as child welfare and family services, probation, employment services, and disability counseling.',
    CrossReferences: '51.1503 - Clinical/Medical Social Work.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0702',
    cipTitle: 'Youth Services/Administration',
    cipDefinition:
      'A program that prepares individuals to plan, manage, and implement social services for children, youth, and families.  Includes instruction in child development and psychology, adolescence, family studies, social work, social services administration, juvenile and family law, program and facilities planning, youth leadership, counseling, probation, casework, applicable procedures and regulations, and professional standards and ethics.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0703',
    cipTitle: 'Forensic Social Work',
    cipDefinition:
      'A program that prepares individuals to serve as social workers in correctional facilities, mental health hospitals, the justice system, substance abuse treatment programs, and victim assistance. Includes instruction in forensic social work, criminal justice administration, domestic violence, ethics, juvenile justice systems, mental illness and crime, program evaluation, and research methods.',
  },
  {
    cipFamily: '44',
    cipCode: '44.0799',
    cipTitle: 'Social Work, Other',
    cipDefinition: 'Any program providing instruction in social  work and related services not listed above.',
    CrossReferences: '51.1503 - Clinical/Medical Social Work.',
  },
  {
    cipFamily: '44',
    cipCode: '44.99',
    cipTitle: 'Public Administration and Social Service Professions, Other',
    cipDefinition: 'Instructional content is defined in code 44.9999.',
  },
  {
    cipFamily: '44',
    cipCode: '44.9999',
    cipTitle: 'Public Administration and Social Service Professions, Other',
    cipDefinition: 'Any instructional program in public administration and services not listed above.',
  },
  {
    cipFamily: '45',
    cipCode: '45',
    cipTitle: 'Social Sciences',
    cipDefinition:
      'Instructional programs that focus on the systematic study of social systems, social institutions, and social behavior.',
  },
  {
    cipFamily: '45',
    cipCode: '45.01',
    cipTitle: 'Social Sciences, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.0101 - 45.0199.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0101',
    cipTitle: 'Social Sciences, General',
    cipDefinition:
      'A program that focuses on the general study of human social behavior and social institutions using any of the methodologies common to the social sciences and/or history, or an undifferentiated program of study in the social sciences.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0102',
    cipTitle: 'Research Methodology and Quantitative Methods',
    cipDefinition:
      'A program that focuses on the design of research studies, measurement of variables, data analysis, and formulation of models.  Includes instruction in experimental, quasi-experimental, and case study methods; historical research; participant observation; questionnaire design; sampling theory; and statistical methods.',
    CrossReferences: '13.0603 - Educational Statistics and Research Methods., 27.0501 - Statistics, General.',
    Examples: 'Examples: - Methodology, Measurement, and Statistics',
  },
  {
    cipFamily: '45',
    cipCode: '45.0103',
    cipTitle: 'Survey Research/Methodology',
    cipDefinition:
      'A program that focuses on survey research design to gather data about behaviors, demographics, opinions, and data analysis to answer complex questions. Includes instruction in the analysis of survey data, cross-cultural and multi-population survey methodology, data collection methods, modes of survey analysis, quantitative analysis, questionnaire design, research design, sampling, survey error, and structural equation modeling.',
    CrossReferences: '27.0601 - Applied Statistics, General.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0199',
    cipTitle: 'Social Sciences, Other',
    cipDefinition: 'Any program in general social sciences not listed above.',
  },
  {
    cipFamily: '45',
    cipCode: '45.02',
    cipTitle: 'Anthropology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.0201 - 45.0299.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0201',
    cipTitle: 'Anthropology, General',
    cipDefinition:
      'A program that focuses on the systematic study of human beings, their antecedents and related primates, and their cultural behavior and institutions, in comparative perspective.  Includes instruction in biological/physical anthropology, primatology, human paleontology and prehistoric archeology, hominid evolution, anthropological linguistics, ethnography, ethnology, ethnohistory, socio-cultural anthropology, psychological anthropology, research methods, and applications to areas such as medicine, forensic pathology, museum studies, and international affairs.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0202',
    cipTitle: 'Physical and Biological Anthropology',
    cipDefinition:
      'A program that focuses on the application of the biological sciences and anthropology to the study of the adaptations, variability, and the evolution of human beings and their living and fossil relatives.  Includes instructions in anthropology, human and mammalian anatomy, cell biology, paleontology, human culture and behavior, neuroscience, forensic anthropology, anatomical reconstruction, comparative anatomy, and laboratory science and methods.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0203',
    cipTitle: 'Medical Anthropology',
    cipDefinition:
      'A program that focuses on biological, socio-cultural, psychological, and behavioral factors as they relate to health, illness, medical practices, and access to health care services. Includes instruction in ethno-medicine, urban health, international health, psychiatric and psychological anthropology, aging, and health and identity.',
    CrossReferences: '51.3299 - Health Professions Education, Ethics, and Humanities, Other.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0204',
    cipTitle: 'Cultural Anthropology',
    cipDefinition:
      'A program that focuses on the study of culture and the relationship of culture to other aspects of social life. Includes instruction in cultural anthropology, anthropological theory, enthnography, linguistics, and quantitative and qualitative research methods.',
    Examples: 'Examples: - Cultural Anthropology, - Cultural and Social Anthropology, - Socio-Cultural Anthropology',
  },
  {
    cipFamily: '45',
    cipCode: '45.0205',
    cipTitle: 'Forensic Anthropology',
    cipDefinition:
      'A program that focuses on the application of the biological sciences and skeletal anthropology in medicolegal death investigations. Includes instruction in biological anthropology theory, crime scene investigation, forensic anthropology field methods, forensic anthropological techniques and procedures, human anatomy, methods of human identification, mortuary archaeology, osteology, and taphonomy.',
    CrossReferences: '43.0406 - Forensic Science and Technology.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0299',
    cipTitle: 'Anthropology, Other',
    cipDefinition: 'Any instructional program in Anthropology not listed above.',
  },
  {
    cipFamily: '45',
    cipCode: '45.03',
    cipTitle: 'Archeology',
    cipDefinition: 'Instructional content is defined in code 45.0301.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0301',
    cipTitle: 'Archeology',
    cipDefinition:
      'A program that focuses on the systematic study of extinct societies, and the past of living societies, via the excavation, analysis and interpretation of their artifactual, human, and associated remains.  Includes instruction in archeological theory, field methods, dating methods, conservation and museum studies, cultural and physical evolution, and the study of specific selected past cultures.',
    CrossReferences:
      '30.2201 - Ancient Studies/Civilization., 30.2202 - Classical, Ancient Mediterranean, and Near Eastern Studies and Archaeology., 30.4201 - Geoarcheaology.',
    Examples: 'Examples: - Archaeometry',
  },
  {
    cipFamily: '45',
    cipCode: '45.04',
    cipTitle: 'Criminology',
    cipDefinition: 'Instructional content is defined in code 45.0401.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0401',
    cipTitle: 'Criminology',
    cipDefinition:
      'A program that focuses on the systematic study of crime as a sociopathological phenomenon, the behavior of criminals, and the social institutions evolved to respond to crime.  Includes instruction in the theory of crime, psychological and social bases of criminal behavior, social value systems and the theory of punishment, criminal law and criminal justice systems, penology, rehabilitation and recidivism, studies of specific types of crime, social attitudes and policy, and applications to specific issues in law enforcement administration and policy.',
    CrossReferences: '43.0104 - Criminal Justice/Safety Studies.',
  },
  {
    cipFamily: '45',
    cipCode: '45.05',
    cipTitle: 'Demography',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.0501 - 45.0599.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0501',
    cipTitle: 'Demography and Population Studies',
    cipDefinition:
      'A program that focuses on the systematic study of population models and population phenomena, and related problems of social structure and behavior.  Includes instruction in population growth, spatial distribution, mortality and fertility factors, migration, dynamic population modeling, population estimation and projection, mathematical and statistical analysis of population data, population policy studies, and applications to problems in economics and government planning.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0502',
    cipTitle: 'Applied Demography',
    cipDefinition:
      'A program of study that focuses on the practical application of demographic methods and materials for decision-making in business, education, health, and government. Includes instruction in statistics, research methods, geographic information systems, and demographic methods and techniques.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0599',
    cipTitle: 'Demography, Other',
    cipDefinition: 'Any instructional program in demography not listed above.',
  },
  {
    cipFamily: '45',
    cipCode: '45.06',
    cipTitle: 'Economics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.0601 - 45.0699.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0601',
    cipTitle: 'Economics, General',
    cipDefinition:
      'A general program that focuses on the systematic study of the production, conservation and allocation of resources in conditions of scarcity, together with the organizational frameworks related to these processes.  Includes instruction in economic theory, micro- and macroeconomics, comparative economic systems, money and banking systems, international economics, quantitative analytical methods, and applications to specific industries and public policy issues.',
    CrossReferences:
      '1.0103 - Agricultural Economics., 19.0402 - Consumer Economics., 52.0601 - Business/Managerial Economics., 3.0204 - Environmental/Natural Resource Economics., 30.3901 - Economics and Computer Science., 30.4001 - Economics and Foreign Language/Literature.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0602',
    cipTitle: 'Applied Economics',
    cipDefinition:
      'A program that focuses on the application of economic principles and analytical techniques to the study of particular industries, activities, or the exploitation of particular resources.  Includes instruction in economic theory; microeconomic analysis and modeling of specific industries, commodities; the economic consequences of resource allocation decisions; regulatory and consumer factors; and the technical aspects of specific subjects as they relate to economic analysis.',
    CrossReferences:
      '1.0103 - Agricultural Economics., 19.0402 - Consumer Economics., 52.0601 - Business/Managerial Economics., 3.0204 - Environmental/Natural Resource Economics.',
    Examples: 'Examples: - Behavioral and Experimental Economics',
  },
  {
    cipFamily: '45',
    cipCode: '45.0603',
    cipTitle: 'Econometrics and Quantitative Economics',
    cipDefinition:
      'A program that focuses on the systematic study of mathematical and statistical analysis of economic phenomena and problems.  Includes instruction in economic statistics, optimization theory, cost/benefit analysis, price theory, economic modeling, and economic forecasting and evaluation.',
    CrossReferences: '30.4901 - Mathematical Economics.',
    Examples: 'Examples: - Cost Analysis, - Economic Forecasting',
  },
  {
    cipFamily: '45',
    cipCode: '45.0604',
    cipTitle: 'Development Economics and International Development',
    cipDefinition:
      'A program that focuses on the systematic study of the economic development process and its application to the problems of specific countries and regions.  Includes instruction in economic development theory, industrialization, land reform, infrastructural development, investment policy, the role of governments and business in development, international development organizations, and the study of social, health, and environmental influences on economic development.',
    CrossReferences: '1.0701 - International Agriculture.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0605',
    cipTitle: 'International Economics',
    cipDefinition:
      'A program that focuses on the systematic study and analysis of international commercial behavior and trade policy.  Includes instruction in international trade theory, tariffs and quotas, commercial policy, trade factor flows, international finance and investment, currency regulation and trade exchange rates and markets, international trade negotiation, and international payments and accounting policy.',
    CrossReferences:
      '52.0806 - International Finance., 52.1101 - International Business/Trade/Commerce., 52.1403 - International Marketing., 30.4001 - Economics and Foreign Language/Literature.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0699',
    cipTitle: 'Economics, Other',
    cipDefinition: 'Any instructional program in economics not listed above.',
    CrossReferences: '30.5101 - Philosophy, Politics, and Economics.',
    Examples: 'Examples: - Game Theory',
  },
  {
    cipFamily: '45',
    cipCode: '45.07',
    cipTitle: 'Geography and Cartography',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.0701 - 45.0799.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0701',
    cipTitle: 'Geography',
    cipDefinition:
      'A program that focuses on the systematic study of the spatial distribution and interrelationships of people, natural resources, plant and animal life.  Includes instruction in historical and political geography, cultural geography, economic and physical geography, regional science, cartographic methods, remote sensing, spatial analysis, and applications to areas such as land-use planning, development studies, and analyses of specific countries, regions, and resources.',
    CrossReferences: '30.4401 - Geography and Environmental Studies.',
    Examples: 'Examples: - Environmental Geography, - Physical Geography, - Social Geography',
  },
  {
    cipFamily: '45',
    cipCode: '45.0702',
    cipTitle: 'Geographic Information Science and Cartography',
    cipDefinition:
      'A program that focuses on the systematic study of map-making and the application of mathematical, computer, and other techniques to the analysis of large amounts of geographic data and the science of mapping geographic information. Includes instruction in cartographic theory and map projections, computer-assisted cartography, geographic information systems, map design and layout, photogrammetry, air photo interpretation, remote sensing, spatial analysis, geodesy, cartographic editing, and applications to specific industrial, commercial, research, and governmental mapping problems.',
    CrossReferences: '43.0407 - Geospatial Intelligence.',
    Examples: 'Examples: - Geographic Information Systems (GIS), - Spatial Analysis, - Geomatics, - Remote Sensing',
  },
  {
    cipFamily: '45',
    cipCode: '45.0799',
    cipTitle: 'Geography, Other',
    cipDefinition: 'Any instructional program in geography not listed above.',
  },
  {
    cipFamily: '45',
    cipCode: '45.09',
    cipTitle: 'International Relations and National Security Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.0901 - 45.0999.',
  },
  {
    cipFamily: '45',
    cipCode: '45.0901',
    cipTitle: 'International Relations and Affairs',
    cipDefinition:
      'A program that focuses on the systematic study of international politics and institutions, and the conduct of diplomacy and foreign policy. Includes instruction in international relations theory, foreign policy analysis, international law and organization, the comparative study of specific countries and regions, and the theory and practice of diplomacy.',
    CrossReferences: '30.2001 - International/Globalization Studies.',
    Examples:
      'Examples: - International Relations, - International Affairs, - Foreign Policy and Diplomacy, - International Relations and Affairs-Economics, - International Relations and Affairs-History, - International Relations and Affairs-Political Science',
  },
  {
    cipFamily: '45',
    cipCode: '45.0902',
    cipTitle: 'National Security Policy Studies',
    cipDefinition:
      'A program that focuses on the theory and application of intelligence, diplomacy, military power, and related tools of statecraft to national defense policy formulation and power projection.  Includes instruction in history, political theory, public policy analysis, civil-military relations, military and defense studies, homeland security, leadership, threat scenarios, regional and local issues, alliance and coalition issues, defense budgeting and economics, intelligence, and strategy.',
    CrossReferences: '28.0601 - Strategic Studies, General.',
    Examples: 'Examples: - National Security Studies, - Strategic Studies',
  },
  {
    cipFamily: '45',
    cipCode: '45.0999',
    cipTitle: 'International Relations and National Security Studies, Other',
    cipDefinition:
      'Any instructional program in international relations and national security studies not listed above.',
  },
  {
    cipFamily: '45',
    cipCode: '45.10',
    cipTitle: 'Political Science and Government',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.1001 - 45.1099.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1001',
    cipTitle: 'Political Science and Government, General',
    cipDefinition:
      'A  general program that focuses on the systematic study of political institutions and behavior.  Includes instruction in political philosophy, political theory, comparative government and politics, political parties and interest groups, public opinion, political research methods, studies of the government and politics of specific countries, and studies of specific political institutions and processes.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1002',
    cipTitle: 'American Government and Politics (United States)',
    cipDefinition:
      'A program that focuses on the systematic study of United States political institutions and behavior.  Includes instruction in American political theory, political parties and interest groups, state and local governments, Constitutional law, federalism and national institutions, executive and legislative politics, judicial politics, popular attitudes and media influences, political research methods, and applications to the study of specific issues and institutions.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1003',
    cipTitle: 'Canadian Government and Politics',
    cipDefinition:
      'A program that focuses on the systematic study of Canadian political institutions and behavior.  Includes instruction in British and North American political theory, political parties and interest groups, provincial and local governments, Constitutional law, federalism and national institutions, executive and legislative politics, judicial politics, popular attitudes and media influences, political research methods, and applications to the study of specific issues and institutions.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1004',
    cipTitle: 'Political Economy',
    cipDefinition:
      'A program that focuses on the interaction between politics and economics in the formation of public policy.  Includes instruction in microeconomics; macroeconomics; political theory; American, comparative, and international political economy; and quantitative methods.',
    Examples: 'Examples: - International Political Economy, - Political Economy and Government',
  },
  {
    cipFamily: '45',
    cipCode: '45.1099',
    cipTitle: 'Political Science and Government, Other',
    cipDefinition: 'Any instructional program in political science and government not listed above.',
    CrossReferences: '30.4601 - History and Political Science., 30.5101 - Philosophy, Politics, and Economics.',
  },
  {
    cipFamily: '45',
    cipCode: '45.11',
    cipTitle: 'Sociology',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 45.1101 - 45.1199.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1101',
    cipTitle: 'Sociology, General',
    cipDefinition:
      'A program that focuses on the systematic study of human social institutions and social relationships.  Includes instruction in social theory, sociological research methods, social organization and structure, social stratification and hierarchies, dynamics of social change, family structures, social deviance and control, and applications to the study of specific social groups, social institutions, and social problems.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1102',
    cipTitle: 'Applied/Public Sociology',
    cipDefinition:
      'A program that focuses on the application of sociological theory, methods, skills, and research to resolve particular issues in real-world settings. Includes instruction in data collection, group and organizational dynamics, participatory action research, program evaluation, sociological research methods, and sociological theory.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1103',
    cipTitle: 'Rural Sociology',
    cipDefinition:
      'A program that focuses on the structure and function of rural societies. Includes instruction in sociological theory, research methods, statistics, sociology of agriculture, community development, social and economic development, demography, rural poverty, gender roles in rural societies, and environmental sociology.',
    Examples: 'Examples: - Rural Development Studies',
  },
  {
    cipFamily: '45',
    cipCode: '45.1199',
    cipTitle: 'Sociology, Other',
    cipDefinition: 'Any instructional program in sociology not listed above.',
  },
  {
    cipFamily: '45',
    cipCode: '45.12',
    cipTitle: 'Urban Studies/Affairs',
    cipDefinition: 'Instructional content is defined in code 45.1201.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1201',
    cipTitle: 'Urban Studies/Affairs',
    cipDefinition:
      'A program that focuses on the application of social science principles to the study of urban institutions and the forces influencing urban social and political life.  Includes instruction in urban theory, the development and evolution of urban areas, urban sociology, principles of urban and social planning, and the politics and economics of urban government and services.',
    CrossReferences: '4.0301 - City/Urban, Community, and Regional Planning.',
    Examples: 'Examples: - Urban and Regional Studies',
  },
  {
    cipFamily: '45',
    cipCode: '45.13',
    cipTitle: 'Sociology and Anthropology',
    cipDefinition: 'Instructional content is defined in code 45.1301.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1301',
    cipTitle: 'Sociology and Anthropology',
    cipDefinition:
      'A program that combines sociology and anthropology to study how society is organized, the origins and development of social institutions, social change, social organizations, race, class, gender and culture.',
    CrossReferences: '45.0201 - Anthropology, General., 45.1101 - Sociology, General.',
  },
  {
    cipFamily: '45',
    cipCode: '45.14',
    cipTitle: 'Rural Sociology',
    cipDefinition: 'Deleted, Report under 45.1103',
  },
  {
    cipFamily: '45',
    cipCode: '45.1401',
    cipTitle: 'Rural Sociology',
    cipDefinition: 'Moved from 45.1401 to 45.1103',
  },
  {
    cipFamily: '45',
    cipCode: '45.15',
    cipTitle: 'Geography and Anthropology',
    cipDefinition: 'Instructional content is defined in code 45.1501.',
  },
  {
    cipFamily: '45',
    cipCode: '45.1501',
    cipTitle: 'Geography and Anthropology',
    cipDefinition:
      'A program that focuses on human cultures and their adaptation to physical environments. Includes instruction in archaeology, biogeography, climatology, cultural anthropology, cultural geography, economic development, environmental geography, geomorphology, global environmental change, historical geography, mapping sciences, physical anthropology, political ecology, Quaternary studies, and urban geography.',
  },
  {
    cipFamily: '45',
    cipCode: '45.99',
    cipTitle: 'Social Sciences, Other',
    cipDefinition: 'Instructional content is defined in code 45.9999.',
  },
  {
    cipFamily: '45',
    cipCode: '45.9999',
    cipTitle: 'Social Sciences, Other',
    cipDefinition: 'Any instructional program in social sciences not listed above.',
  },
  {
    cipFamily: '46',
    cipCode: '46',
    cipTitle: 'Construction Trades',
    cipDefinition:
      'Instructional programs that prepare individuals to apply technical knowledge and skills in the building, inspecting, and maintaining of structures and related properties.',
  },
  {
    cipFamily: '46',
    cipCode: '46.00',
    cipTitle: 'Construction Trades, General',
    cipDefinition: 'Instructional content is defined in code 46.0000.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0000',
    cipTitle: 'Construction Trades, General',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills in the building, inspecting, and maintaining of structures and related properties.  May include instruction in masonry, carpentry, electrical and power transmission installation, building/construction finishing, management, inspection and other construction-related applications.',
    Examples: 'Examples: - Construction Craft Worker',
  },
  {
    cipFamily: '46',
    cipCode: '46.01',
    cipTitle: 'Mason/Masonry',
    cipDefinition: 'Instructional content is defined in code 46.0101.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0101',
    cipTitle: 'Mason/Masonry',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills in the laying and/or setting of exterior brick, concrete block, hard tile, marble and related materials, using trowels, levels, hammers, chisels, and other hand tools. Includes instruction in technical mathematics, blueprint reading, structural masonry, decorative masonry, foundations, reinforcement, mortar preparation, cutting and finishing, and applicable codes and standards.',
    Examples: 'Examples: - Bricklayer, - Brick and Stone Mason',
  },
  {
    cipFamily: '46',
    cipCode: '46.02',
    cipTitle: 'Carpenters',
    cipDefinition: 'Instructional content is defined in code 46.0201.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0201',
    cipTitle: 'Carpentry/Carpenter',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to lay out, cut, fabricate, erect, install, and repair wooden structures and fixtures, using hand and power tools.  Includes instruction in technical mathematics, framing, construction materials and selection, job estimating, blueprint reading, foundations and roughing-in, finish carpentry techniques, and applicable codes and standards.',
    Examples: 'Examples: - General Carpenter',
  },
  {
    cipFamily: '46',
    cipCode: '46.03',
    cipTitle: 'Electrical and Power Transmission Installers',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 46.0301 - 46.0399.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0301',
    cipTitle: 'Electrical and Power Transmission Installation/Installer, General',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills to install indoor and outdoor residential, commercial, and industrial electrical systems, and associated power transmission lines.  Includes instruction in electricity, safety procedures, wiring, insulation and grounding, schematic blueprint interpretation, equipment operation and maintenance, and applicable codes and standards.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0302',
    cipTitle: 'Electrician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to install, operate, maintain, and repair electric apparatus and systems such as residential, commercial, and industrial electric-power wiring; and DC and AC motors, controls, and electrical distribution panels.  Includes instruction in the principles of electronics and electrical systems, wiring, power transmission, safety, industrial and household appliances, job estimation, electrical testing and inspection, and applicable codes and standards.',
    CrossReferences: '15.0303 - Electrical, Electronic, and Communications Engineering Technology/Technician.',
    Examples: 'Examples: - Construction Electrician, - Industrial Electrician',
  },
  {
    cipFamily: '46',
    cipCode: '46.0303',
    cipTitle: 'Lineworker',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to install, operate, maintain and repair local, long-distance, and rural electric power cables and communication lines; erect and construct pole and tower lines; and install underground lines and cables.  Includes instruction in cable installation and repair, fibre-optic technology, trenching, mobile equipment and crane operation, high-voltage installations, maintenance and inspection, safety, remote communications, and applicable codes and standards.',
    Examples:
      'Examples: - Power Line Electrician, - Power Lineman, - Powerline Technician, - Powerline Technician (Operating)',
  },
  {
    cipFamily: '46',
    cipCode: '46.0399',
    cipTitle: 'Electrical and Power Transmission Installers, Other',
    cipDefinition: 'Any instructional program in electrical and power transmission installation not listed above.',
  },
  {
    cipFamily: '46',
    cipCode: '46.04',
    cipTitle: 'Building/Construction Finishing, Management, and Inspection',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 46.0401 - 46.0499.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0401',
    cipTitle: 'Building/Property Maintenance',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to keep a building functioning, and to service a variety of structures including commercial and industrial buildings and mobile homes. Includes instruction in the basic maintenance and repair skills required to service  building systems, such as air conditioning, heating, plumbing, electrical, major appliances, and other mechanical systems.',
    Examples: 'Examples: - Building Maintenance, - Property Maintenance, - Custodial Services',
  },
  {
    cipFamily: '46',
    cipCode: '46.0402',
    cipTitle: 'Concrete Finishing/Concrete Finisher',
    cipDefinition:
      'A program that prepares individuals to prepare, construct, and finish buildings, components, and related structures using compound masonry mixtures of stone, gravel, sand, cement, and water. Includes instruction in site preparation, applied mathematics, mixing formulas and applications, job estimation, concrete design, finishing techniques, mold preparation, reinforcement installation, site safety, and equipment operation and maintenance.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0403',
    cipTitle: 'Building/Home/Construction Inspection/Inspector',
    cipDefinition:
      'A program that prepares individuals to apply industrial, labor, and governmental standards and laws to the oversight of construction projects and the maintenance of completed buildings and other structures.  Includes instruction in construction processes and techniques, materials analysis, occupational safety and health, industry standards, building codes and specifications, blueprint interpretation, testing equipment and procedures, communication skills, accident investigation, and documentation.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0404',
    cipTitle: 'Drywall Installation/Drywaller',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to install, tape, and plaster drywall installations in interior and exterior construction jobs. Includes instruction in drywall handling and transport, cutting and mounting, taping and spackling, finishing, job analysis and estimation, site safety, tool operation and maintenance, blueprint and specification interpretation, and applicable codes and standards.',
    Examples:
      'Examples: - Drywall, Acousting and Lathing Applicator, - Drywall and Acoustical Mechanic, - Lather (Interior Systems Mechanic), - Interior Systems Installer, - Wall and Ceiling Installer',
  },
  {
    cipFamily: '46',
    cipCode: '46.0406',
    cipTitle: 'Glazier',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to install and repair fixtures, sheets, windows, and skylights made of glass, plexiglass, and other transparent materials.  Includes instruction in site measurement, job analysis and estimation, material cutting and trimming, window and door installation, site safety, equipment operation and maintenance, specification interpretation, and applicable codes and standards.',
    Examples: 'Examples: - Erector Mechanic (Glazier), - Glassworker',
  },
  {
    cipFamily: '46',
    cipCode: '46.0408',
    cipTitle: 'Painting/Painter and Wall Coverer',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to finish exterior and interior structural surfaces by applying protective or decorative coating materials, such as paint, lacquer, and wallpaper.  Includes instruction in surface preparation; selecting, preparing, and applying paints and other coatings; hanging wallpaper; equipment operation and maintenance; finish selection; safety and clean-up; environmental effects on finishes; adhesion properties; and applicable codes and standards.',
    Examples:
      'Examples: - Painting (Commercial), - Painter and Decorator, - Painter and Decorator - Commercial and Residential',
  },
  {
    cipFamily: '46',
    cipCode: '46.041',
    cipTitle: 'Roofer',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to prepare, install, and maintain exterior roofing materials and roofs.  Includes instruction in building materials, flashing, lag bolts, site safety, equipment operation and maintenance, layering, site preparation, basic internal structural design, finishing, waterproofing, and applicable codes and standards.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0411',
    cipTitle: 'Metal Building Assembly/Assembler',
    cipDefinition:
      'A program that prepares individuals to construct industrial, storage, and commercial metal structures using prefabricated framing and siding components.  Includes instruction in sheet metal working, ironworking, assembly and fastening techniques, blueprint reading, site preparation, structural design principles, safety, and applicable codes and regulations.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0412',
    cipTitle: 'Building/Construction Site Management/Manager',
    cipDefinition:
      'A program that prepares individuals to supervise, manage, and inspect construction sites, buildings, and associated facilities.  Includes instruction in site safety, personnel supervision, labor relations, diversity training, construction documentation, scheduling, resource and cost control, bid strategies, rework prevention, construction insurance and bonding, accident management and investigation, applicable law and regulations, and communication skills.',
    CrossReferences: '52.2001 - Construction Management, General.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0413',
    cipTitle: 'Carpet, Floor, and Tile Worker',
    cipDefinition:
      'A program that prepares individuals to plan, prepare, install, and repair carpet; linoleum; vinyl; ceramic, marble, quarry, mosaic, and terazzo tiles; and wood materials on floors, walls, and stairs. Includes instruction in measuring, cutting, and installing materials; use of hand and power-operated equipment; estimation of material and labor costs; and safety training.',
    Examples:
      'Examples: - Carpet, Flooring, and Tile Technician, - Floorcovering Installer, - Carpet, Linoleum, and Soft Tile Layer, - Carpet/Flooring and Hardwood Technician, - Tiling and Flooring Technician, - Tilesetter, - Terrazzo, Tile and Marble Setter',
  },
  {
    cipFamily: '46',
    cipCode: '46.0414',
    cipTitle: 'Insulator',
    cipDefinition:
      'A program that prepares individuals to apply insulation materials to pipes, boilers, ducts, refrigeration systems, and related equipment to reduce the passage of heat, cold, sound, or fire. Includes instruction in insulation specifications, measuring and cutting insulating material, applying and securing insulation, installing vapor barriers, insulation system maintenance, asbestos removal and abatement, and safety training.',
    Examples:
      'Examples: - Heat and Frost Insulator, - Asbestos Worker/Insulator, - Thermal Insulation Installer, - Boiler and Pipe Insulator, - Firestopping Insulator, - Sound Insulator',
  },
  {
    cipFamily: '46',
    cipCode: '46.0415',
    cipTitle: 'Building Construction Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to residential and commercial building construction and remodeling. Includes instruction in construction equipment and safety; site preparation and layout; construction estimating; blueprint reading; building codes; framing; masonry; heating, ventilation, and air conditioning; electrical and mechanical systems; interior and exterior finishing; and plumbing.',
    Examples: 'Examples: - Construction Technology',
  },
  {
    cipFamily: '46',
    cipCode: '46.0499',
    cipTitle: 'Building/Construction Finishing, Management, and Inspection, Other',
    cipDefinition:
      'Any instructional program in building/construction finishing, management, and inspection not listed above.',
  },
  {
    cipFamily: '46',
    cipCode: '46.05',
    cipTitle: 'Plumbing and Related Water Supply Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 46.0502 - 46.0599.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0502',
    cipTitle: 'Pipefitting/Pipefitter and Sprinkler Fitter',
    cipDefinition:
      'A program that prepares individuals to design, install, and test industrial and commercial piping systems and automatic fire and exposure protection systems.  Includes instruction in water systems, steam systems, heating and cooling systems, lubricating systems, piping materials, installation tools operation and maintenance, valve installation and repair, technical mathematics, blueprint interpretation, and applicable codes and standards.',
    Examples:
      'Examples: - Sprinkler and Fire Protection Installer, - Sprinkler System Installer, - Steamfitter/Pipefitter',
  },
  {
    cipFamily: '46',
    cipCode: '46.0503',
    cipTitle: 'Plumbing Technology/Plumber',
    cipDefinition:
      'A program that prepares individuals to practice as licensed plumbers by applying technical knowledge and skills to lay out, assemble, install, and maintain piping fixtures and systems for steam, natural gas, oil,  hot water, heating, cooling, drainage, lubricating, sprinkling, and industrial processing systems in home and business environments.  Includes instruction in source determination, water distribution, waster removal, pressure adjustment, basic physics, technical mathematics, blueprint reading, pipe installation, pumps, welding and soldering, plumbing inspection, and applicable codes and standards.',
    Examples: 'Examples: - Pipefitter - Plumber Specialty',
  },
  {
    cipFamily: '46',
    cipCode: '46.0504',
    cipTitle: 'Well Drilling/Driller',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to set up, maintain, repair, and operate well drilling equipment; locate, drill, construct, and develop water, gas, and oil wells; and test and monitor wells to ensure adequate flow.  Includes applications to home, business, and industrial uses.',
    Examples: 'Examples: - Rig Technician, - Derrickhand, - Motorhand',
  },
  {
    cipFamily: '46',
    cipCode: '46.0505',
    cipTitle: 'Blasting/Blaster',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills in using a variety of explosive materials to aid in the construction process.  Includes instruction in safety procedures for storing, handling, placement, charge power determination, drilling, pounding, building demolition, preparing rocky surfaces for building foundations and demolition of explosives.',
  },
  {
    cipFamily: '46',
    cipCode: '46.0599',
    cipTitle: 'Plumbing and Related Water Supply Services, Other',
    cipDefinition: 'Any instructional program in plumbing and related water supply services not listed above.',
  },
  {
    cipFamily: '46',
    cipCode: '46.99',
    cipTitle: 'Construction Trades, Other',
    cipDefinition: 'Instructional content is defined in code 46.9999.',
  },
  {
    cipFamily: '46',
    cipCode: '46.9999',
    cipTitle: 'Construction Trades, Other',
    cipDefinition: 'Any instructional program in construction trades not listed above.',
    Examples: 'Examples: - Elevator Constructor, - Manlift Technician',
  },
  {
    cipFamily: '47',
    cipCode: '47',
    cipTitle: 'Mechanic and Repair Technologies/Technicians',
    cipDefinition:
      'Instructional programs that prepare individuals to apply technical knowledge and skills in the adjustment, maintenance, part replacement, and repair of tools, equipment, and machines.',
  },
  {
    cipFamily: '47',
    cipCode: '47.00',
    cipTitle: 'Mechanics and Repairers, General',
    cipDefinition: 'Instructional content is defined in code 47.0000.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0000',
    cipTitle: 'Mechanics and Repairers, General',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills in the adjustment, maintenance, part replacement, and repair of tools, equipment, and machines.',
  },
  {
    cipFamily: '47',
    cipCode: '47.01',
    cipTitle: 'Electrical/Electronics Maintenance and Repair Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 47.0101 - 47.0199.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0101',
    cipTitle: 'Electrical/Electronics Equipment Installation and Repair Technology/Technician, General',
    cipDefinition:
      'A  program that generally prepares individuals to apply technical knowledge and skills to operate, maintain, and repair electrical and electronic equipment.  Includes instruction in electrical circuitry, simple gearing, linkages and lubrication of machines and appliances, and the use of testing equipment.',
    Examples:
      'Examples: - Electric Motor System Technician, - Electric Motor Winder, - Electrical Rewind Mechanic, - Winder Electrician',
  },
  {
    cipFamily: '47',
    cipCode: '47.0102',
    cipTitle: 'Business Machine Repair',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to maintain and repair a variety of office machines, such as typewriters, word processing and dictation machines, calculators, data processing equipment, duplicating machines, and mailing machines.  Includes instruction in diagnostic techniques, the use of testing equipment, and the principles of mechanics, electricity, and electronics as they relate to the repair of business machines.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0103',
    cipTitle: 'Communications Systems Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair one- and two- way communications equipment and systems, including television cable systems and mobile or stationary communication devices.  Includes instruction in diagnostic techniques, the use of testing equipment and the principles of mechanics, electricity, and electronics as they relate to the repair of communications systems.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0104',
    cipTitle: 'Computer Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair computers and related instruments.  Includes instruction in power supplies, number systems, memory structure, buffers and registers, microprocessor design, peripheral equipment, programming, and networking.',
    CrossReferences: '15.1202 - Computer/Computer Systems Technology/Technician.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0105',
    cipTitle: 'Industrial Electronics Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair electrical/electronic equipment used in industry and manufacturing.  Includes instruction in installing, maintaining and testing various types of equipment.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0106',
    cipTitle: 'Appliance Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, install, and service major gas, electric, and microwave consumer appliances such as stoves, refrigerators, dryers, water heaters, washers, dishwashers, and commercial units such as ice makers and coffee makers.',
    Examples: 'Examples: - Appliance Service Technician',
  },
  {
    cipFamily: '47',
    cipCode: '47.011',
    cipTitle: 'Security System Installation, Repair, and Inspection Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to install and repair household, business, and industrial security alarms, sensors, video and sound recording devices, identification systems, protective barriers, and related technologies.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0199',
    cipTitle: 'Electrical/Electronics Maintenance and Repair Technologies/Technicians, Other',
    cipDefinition:
      'Any instructional program in electrical and electronics equipment installation and repair technologies not listed above.',
  },
  {
    cipFamily: '47',
    cipCode: '47.02',
    cipTitle:
      'Heating, Air Conditioning, Ventilation and Refrigeration Maintenance Technology/Technician (HAC, HACR, HVAC, HVACR)',
    cipDefinition: 'Instructional content is defined in code 47.0201.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0201',
    cipTitle: 'Heating, Air Conditioning, Ventilation and Refrigeration Maintenance Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, install, service and maintain the operating condition of heating, air conditioning, and refrigeration systems.  Includes instruction in diagnostic techniques, the use of testing equipment and the principles of mechanics, electricity, and electronics as they relate to the repair of heating, air conditioning and refrigeration systems.',
    CrossReferences:
      '15.0501 - Heating, Ventilation, Air Conditioning and Refrigeration Engineering Technology/Technician.',
    Examples:
      'Examples: - Heating and Air Conditioning Maintenance Technology/Technician (HAC), - Heating, Air Conditioning, and Refrigeration Maintenance Technology/Technician (HACR), - Heating, Ventilation, and Air Conditioning Maintenance Technology/Technician (HVAC), - Heating, Air Conditioning, Ventilation, and Refrigeration Maintenance Technology/Technician (HVACR), - Oil Burner Mechanic, - Refrigeration Mechanic, - Refrigeration and Air Conditioning Mechanic, - Refrigeration and Air Conditioning Systems Mechanic',
  },
  {
    cipFamily: '47',
    cipCode: '47.03',
    cipTitle: 'Heavy/Industrial Equipment Maintenance Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 47.0302 - 47.0399.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0302',
    cipTitle: 'Heavy Equipment Maintenance Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills in the field maintenance and repair of heavy equipment, and in the general maintenance and overhaul of such equipment.  Includes instruction in inspection, maintenance, and repair of tracks, wheels, brakes, operating controls, pneumatic and hydraulic systems, electrical circuitry, engines and in techniques of welding and brazing.',
    Examples:
      'Examples: - Heavy Duty Equipment Mechanic, - Heavy Duty Equipment Technician, - Heavy Duty Equipment Technician (Off Road), - Heavy Equipment Service Technician',
  },
  {
    cipFamily: '47',
    cipCode: '47.0303',
    cipTitle: 'Industrial Mechanics and Maintenance Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair and maintain industrial machinery and equipment such as cranes, pumps, engines and motors, pneumatic tools, conveyor systems, production machinery, marine deck machinery, and steam propulsion, refinery, and pipeline-distribution systems.',
    Examples:
      'Examples: - Industrial Plant Technician, - Industrial Equipment Technology, - Industrial Mechanic, - Millwright',
  },
  {
    cipFamily: '47',
    cipCode: '47.0399',
    cipTitle: 'Heavy/Industrial Equipment Maintenance Technologies/Technicians, Other',
    cipDefinition:
      'Any instructional program in heavy or industrial equipment maintenance technologies not listed above.',
  },
  {
    cipFamily: '47',
    cipCode: '47.04',
    cipTitle: 'Precision Systems Maintenance and Repair Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 47.0402 - 47.0499.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0402',
    cipTitle: 'Gunsmithing/Gunsmith',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to make, repair, maintain, and modify firearms according to blueprints or customer specifications, using specialized hand tools and machines.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0403',
    cipTitle: 'Locksmithing and Safe Repair',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to make, repair, maintain, modify, and open locks; to make keys; to enter and change lock and safe combinations; and install and repair safes.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0404',
    cipTitle: 'Musical Instrument Fabrication and Repair',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to make, repair, maintain, and tune acoustic and electric musical instruments.  Includes instruction in methods and equipment for making musical instruments, electronic instrument amplification systems, techniques of cleaning, and methods of tuning musical instruments.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0408',
    cipTitle: 'Watchmaking and Jewelrymaking',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to make repairs, and maintain timepieces, time-measuring devices, and jewelry items.  Includes instruction in mechanical timekeeping systems, digital timekeeping systems, timesetting, casting, engraving, polishing, stonesetting, soldering, fine microscopic work, equipment and tool maintenance, redesign and restyling techniques, and customer relations.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0409',
    cipTitle: 'Parts and Warehousing Operations and Maintenance Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to maintain inventory control, care for inventory, and make minor repairs to warehouse equipment.',
    Examples: 'Examples: - Automotive Partsperson, - Parts Technician, - Partsperson',
  },
  {
    cipFamily: '47',
    cipCode: '47.0499',
    cipTitle: 'Precision Systems Maintenance and Repair Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in precision systems maintenance and repair not listed above.',
    Examples: 'Examples: - Sports Equipment Fabrication and Repair Technician',
  },
  {
    cipFamily: '47',
    cipCode: '47.06',
    cipTitle: 'Vehicle Maintenance and Repair Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 47.0600 - 47.0699.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0600',
    cipTitle: 'Vehicle Maintenance and Repair Technology/Technician, General',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills in the adjustment, maintenance, part replacement, and repair of vehicles and mobile equipment.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0603',
    cipTitle: 'Autobody/Collision and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, reconstruct and finish automobile bodies, fenders, and external features.  Includes instruction in structure analysis, damage repair, non-structural analysis, mechanical and electrical components, plastics and adhesives, painting and refinishing techniques, and damage analysis and estimating.',
    Examples:
      'Examples: - Auto Body Refinisher, - Auto Body Technician, - Automotive Collision Repair Technician, - Automotive Painter, - Automotive Refinishing Technician, - Motor Vehicle Body Painter, - Motor Vehicle Body Refinisher, - Motor Vehicle Body Refinisher Sub-Trade, - Painting (autobody)',
  },
  {
    cipFamily: '47',
    cipCode: '47.0604',
    cipTitle: 'Automobile/Automotive Mechanics Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain all types of automobiles.  Includes instruction in brake systems, electrical systems, engine performance, engine repair, suspension and steering, automatic and manual transmissions and drive trains, and heating and air condition systems.',
    CrossReferences: '15.0803 - Automotive Engineering Technology/Technician.',
    Examples: 'Examples: - Automotive Service Technician, - Motor Vehicle Mechanic',
  },
  {
    cipFamily: '47',
    cipCode: '47.0605',
    cipTitle: 'Diesel Mechanics Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain diesel engines in vehicles such as automobiles, buses, ships, trucks, railroad locomotives, and construction equipment; as well as stationary diesel engines in electrical generators and related equipment.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0606',
    cipTitle: 'Small Engine Mechanics and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain small internal-combustion engines used on portable power equipment such as lawnmowers, chain saws, rotary tillers, and snowmobiles.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0607',
    cipTitle: 'Airframe Mechanics and Aircraft Maintenance Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain all aircraft components other than engines, propellers, avionics, and instruments.  Includes instruction in layout and fabrication of sheet metal, fabric, wood, and other materials into structural members, parts, and fittings, and replacement of damaged or worn parts such as control cables and hydraulic units.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0608',
    cipTitle: 'Aircraft Powerplant Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain all types of aircraft powerplant and related systems.  Instruction includes engine inspection and maintenance, lubrication and cooling, electrical and ignition systems, carburetion, fuels and fuel systems, propeller and fan assemblies.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0609',
    cipTitle: 'Avionics Maintenance Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain all types of aircraft operating, control, and electronic systems.  Includes instruction in flight instrumentation, aircraft communications and homing systems, radar and other sensory systems, navigation aids, and specialized systems for various types of civilian and military aircraft.',
    CrossReferences: '15.0801 - Aeronautical/Aerospace Engineering Technology/Technician.',
  },
  {
    cipFamily: '47',
    cipCode: '47.061',
    cipTitle: 'Bicycle Mechanics and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain bicycles and other human-powered vehicles.  Includes instruction in lubrication, adjustments of moving parts, and wheel building.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0611',
    cipTitle: 'Motorcycle Maintenance and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair, service, and maintain motorcycles and other similar powered vehicles.  Includes instruction in lubrication and cooling systems, electrical and ignition systems, carburetion, fuel systems and adjustments of moving parts.',
    Examples: 'Examples: - Motorcycle Mechanic',
  },
  {
    cipFamily: '47',
    cipCode: '47.0612',
    cipTitle: 'Vehicle Emissions Inspection and Maintenance Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to test, repair, service, and maintain vehicle emission systems in accordance with relevant laws and regulations.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0613',
    cipTitle: 'Medium/Heavy Vehicle and Truck Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the specialized maintenance and repair of trucks, buses, and other commercial and industrial vehicles.  Includes instruction in diesel engine mechanics, suspension and steering, brake systems, electrical and electronic systems, preventive maintenance inspections, drive trains, gasoline engine mechanics, HVAC systems, and auxiliary equipment installation and repair.',
    Examples:
      'Examples: - Commercial Trailer Mechanic, - Transport Trailer Technician, - Truck and Coach Technician, - Truck and Transport Mechanic, - Truck-Trailer Service Technician, - Commercial Transport Vehicle Mechanic',
  },
  {
    cipFamily: '47',
    cipCode: '47.0614',
    cipTitle: 'Alternative Fuel Vehicle Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the maintenance of alternative fuel vehicles and the conversion of standard vehicles to AFV status.  Includes instruction in electrical vehicles, liquefied petroleum gas (LPG) vehicles, compressed natural gas (CNG) vehicles, hybrid fuel technology, electrical and electronic systems, engine performance, diagnosis and repair, and conversion/installation.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0615',
    cipTitle: 'Engine Machinist',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the building and reconstruction of automotive and commercial vehicle engines.   Includes instruction in cylinder head and block, crack repair, crankshaft inspection and repair, connecting rods and pistons, balancing, block preparation, disassembly and repair, engine assembly, gas and diesel technology, and inspection and machining.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0616',
    cipTitle: 'Marine Maintenance/Fitter and Ship Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair outboard and inboard engines; test, maintain, and repair steering devices and electrical systems; repair metal, wood, and fiberglass hulls and vessel components; fabricate and maintain sails; and repair and balance propellers and drive shafts.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0617',
    cipTitle: 'High Performance and Custom Engine Technician/Mechanic',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to increase the power of diesel and automotive engines; enhance the performance of automobiles; and repair, service, and maintain high performance vehicles. Includes instruction in adding performance accessories; modifying powertrains; tuning custom engines, suspension, and exhaust systems; and using dynamometers and other diagnostic equipment.',
    Examples:
      'Examples: - High Performance Powertrain Technician/Mechanic, - Automotive High Performance Technician/Mechanic',
  },
  {
    cipFamily: '47',
    cipCode: '47.0618',
    cipTitle: 'Recreation Vehicle (RV) Service Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to build, test, inspect, repair, service and maintain recreational vehicles, systems, and interior and exterior components. Includes instruction in brake, hydraulic, and towing systems; electrical systems; propane systems and propane and electric appliances; carpentry; plumbing; welding; and structural frames.',
    Examples:
      'Examples: - Recreation Vehicle (RV) Mechanic, - Recreation Vehicle (RV) Service Technician, - Recreation Vehicle (RV) Technician',
  },
  {
    cipFamily: '47',
    cipCode: '47.0699',
    cipTitle: 'Vehicle Maintenance and Repair Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in vehicle maintenance and repair technologies not listed above.',
  },
  {
    cipFamily: '47',
    cipCode: '47.07',
    cipTitle: 'Energy Systems Maintenance and Repair Technologies/Technicians',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 47.0701 - 47.0799.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0701',
    cipTitle: 'Energy Systems Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair energy systems. Includes instruction in installing, maintaining and testing various types of equipment.',
    CrossReferences: '15.1701 - Energy Systems Technology/Technician.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0703',
    cipTitle: 'Solar Energy System Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair solar energy systems. Includes instruction in installing, maintaining and testing various types of equipment.',
    CrossReferences: '15.1703 - Solar Energy Technology/Technician.',
    Examples: 'Examples: - Solar Photovoltaic Installation',
  },
  {
    cipFamily: '47',
    cipCode: '47.0704',
    cipTitle: 'Wind Energy System Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair wind energy systems. Includes instruction in installing, maintaining and testing various types of equipment.',
    CrossReferences: '15.1704 - Wind Energy Technology/Technician.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0705',
    cipTitle: 'Hydroelectric Energy System Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair hydroelectric energy systems. Includes instruction in installing, maintaining and testing various types of equipment.',
    CrossReferences: '15.1705 - Hydroelectric Energy Technology/Technician.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0706',
    cipTitle: 'Geothermal Energy System Installation and Repair Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to assemble, install, operate, maintain, and repair geothermal energy systems. Includes instruction in installing, maintaining and testing various types of equipment.',
    CrossReferences: '15.1706 - Geothermal Energy Technology/Technician.',
  },
  {
    cipFamily: '47',
    cipCode: '47.0799',
    cipTitle: 'Energy Systems Maintenance and Repair Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in energy systems maintenance and repair technologies not listed above.',
    CrossReferences: '15.1799 - Energy Systems Technologies/Technicians, Other.',
  },
  {
    cipFamily: '47',
    cipCode: '47.99',
    cipTitle: 'Mechanic and Repair Technologies/Technicians, Other',
    cipDefinition: 'Instructional content is defined in code 47.9999.',
  },
  {
    cipFamily: '47',
    cipCode: '47.9999',
    cipTitle: 'Mechanic and Repair Technologies/Technicians, Other',
    cipDefinition: 'Any instructional program in mechanic and repair technologies not listed above.',
  },
  {
    cipFamily: '48',
    cipCode: '48',
    cipTitle: 'Precision Production',
    cipDefinition:
      'Instructional programs that prepare individuals to apply technical knowledge and skills to create products using techniques of precision craftsmanship or technical illustration.',
  },
  {
    cipFamily: '48',
    cipCode: '48.00',
    cipTitle: 'Precision Production Trades, General',
    cipDefinition: 'Instructional content is defined in code 48.0000.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0000',
    cipTitle: 'Precision Production Trades, General',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills in creating products using precision crafting and technical illustration.',
  },
  {
    cipFamily: '48',
    cipCode: '48.03',
    cipTitle: 'Leatherworking and Upholstery',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 48.0303 - 48.0399.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0303',
    cipTitle: 'Upholstery/Upholsterer',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to install springs, filling, padding, covering and finishing on items such as furniture, automobile seats, caskets, mattresses, and bedsprings.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0304',
    cipTitle: 'Shoe, Boot and Leather Repair',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to repair all types of footwear, including replacement and mending of worn parts, repairing orthopedic footwear, refinishing and dyeing leather, and repairing other leather goods such as handbags, belts, and luggage.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0399',
    cipTitle: 'Leatherworking and Upholstery, Other',
    cipDefinition: 'Any instructional program in leatherworking and upholstering not listed above.',
  },
  {
    cipFamily: '48',
    cipCode: '48.05',
    cipTitle: 'Precision Metal Working',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 48.0501 - 48.0599.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0501',
    cipTitle: 'Machine Tool Technology/Machinist',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to plan, manufacture, assemble, test, and repair parts, mechanisms, machines, and structures in which materials are cast, formed, shaped, molded, heat treated, cut, twisted, pressed, fused, stamped or worked.',
    Examples: 'Examples: - General Machinist, - Machinist',
  },
  {
    cipFamily: '48',
    cipCode: '48.0503',
    cipTitle: 'Machine Shop Technology/Assistant',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to fabricate and modify metal parts in support of other manufacturing, repair or design activities, or as an independent business.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0506',
    cipTitle: 'Sheet Metal Technology/Sheetworking',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to form, shape, bend and fold extruded metals, including the creation of new products, using hand tools and machines such as cornice brakes, forming rolls, and squaring shears.',
    Examples: 'Examples: - Sheet Metal Worker',
  },
  {
    cipFamily: '48',
    cipCode: '48.0507',
    cipTitle: 'Tool and Die Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to operate machine tools used in the forming of metal components, as well as the fabrication of special tools, dies, jigs and fixtures used in cutting, working and finishing metal components.',
    Examples: 'Examples: - Tool and Die Maker',
  },
  {
    cipFamily: '48',
    cipCode: '48.0508',
    cipTitle: 'Welding Technology/Welder',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to join or cut metal surfaces.  Includes instruction in arc welding, resistance welding, brazing and soldering, cutting, high-energy beam welding and cutting, solid state welding, ferrous and non-ferrous materials, oxidation-reduction reactions, welding metallurgy, welding processes and heat treating, structural design, safety, and applicable codes and standards.',
    CrossReferences: '15.0614 - Welding Engineering Technology/Technician.',
    Examples:
      'Examples: - Arc Welding, - Flux-Core Arc Welding, - Gas Metal Arc Welding, - Gas Tungsten Arc Welding, - Shielded Metal Arc Welding, - Structural Steel Welding, - Tungsten Inert Gas (TIG) Welding, - Pipe Welding, - Wire Welding, - Industrial Welder, - Welder, - Metal Inert Gas (MIG) Welding',
  },
  {
    cipFamily: '48',
    cipCode: '48.0509',
    cipTitle: 'Ironworking/Ironworker',
    cipDefinition:
      'A program that prepares individuals to make and install structural, ornamental, and reinforcing metal structures and supports.  Includes instruction in drafting, technical mathematics, blueprint interpretation, welding, riveting, beam placement, ornamental design, structural reinforcement, crane operation, safety, and applicable codes and standards.',
    Examples:
      'Examples: - Ironworker (Generalist), - Ironworker (Reinforcing), - Ironworker (Structural/Ornamental), - Ironworker Reinforcing Rebar, - Ironworker Rodworker',
  },
  {
    cipFamily: '48',
    cipCode: '48.051',
    cipTitle: 'Computer Numerically Controlled (CNC) Machinist Technology/CNC Machinist',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to operate computer numerically controlled (CNC) machine tools, such as lathes, mills, precision measuring tools, and related attachments and accessories, to perform machining functions, such as cutting, drilling, shaping, and finishing products and component parts. Includes instruction in CNC terminology, setup, programming, operations, and troubleshooting; blueprint reading; machining; lathe and mill operations; technical mathematics; computer literacy; CAD/CAM systems; shop and safety practices; equipment capabilities; and regulations and laws.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0511',
    cipTitle: 'Metal Fabricator',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to further process ferrous and/or non-ferrous metal plates or shapes, and to manufacture and/or install products as interpreted through technical drawings. Includes instruction in trade tools, shop equipment, reading and interpreting blueprints, material handling, welding, thermal cutting, metallurgy, template pattern development, plate and structural fabrication, destructive and non-destructive testing, inspection, shipment preparation and fabrication installation.',
    Examples:
      'Examples: - Metal Fabricator, - Steel Fabricator, - Steel Fabricator (Fitter), - Structural Steel and Plate Fitter',
  },
  {
    cipFamily: '48',
    cipCode: '48.0599',
    cipTitle: 'Precision Metal Working, Other',
    cipDefinition: 'Any instructional program in precision metal work not listed above.',
  },
  {
    cipFamily: '48',
    cipCode: '48.07',
    cipTitle: 'Woodworking',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 48.0701 - 48.0799.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0701',
    cipTitle: 'Woodworking, General',
    cipDefinition:
      'A program that generally prepares individuals to apply technical knowledge and skills to lay out and shape stock; assemble wooden articles or subassemblies; mark, bind, saw, carve, and sand wooden products; repair wooden articles, and use a variety of hand and power tools.',
    CrossReferences: '46.0201 - Carpentry/Carpenter.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0702',
    cipTitle: 'Furniture Design and Manufacturing',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to prepare and execute furniture design projects, assemble and finish furniture articles or subassemblies, repair furniture, and use a variety of hand and power tools.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0703',
    cipTitle: 'Cabinetmaking and Millwork',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to set up, operate and repair industrial woodworking machinery, and to use such machinery to design and fabricate wooden components and complete articles.',
    Examples: 'Examples: - Cabinetmaker, - Joiner',
  },
  {
    cipFamily: '48',
    cipCode: '48.0704',
    cipTitle: 'Wooden Boatbuilding Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to construct and repair traditional and modern wooden boats. Includes instruction in budgeting, drafting, fairing, lofting, joinery, planking, spars and rigging, and woodworking.',
    CrossReferences: '15.0806 - Marine Engineering Technology/Technician.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0799',
    cipTitle: 'Woodworking, Other',
    cipDefinition: 'Any instructional program in woodworking not listed above.',
  },
  {
    cipFamily: '48',
    cipCode: '48.08',
    cipTitle: 'Boilermaking/Boilermaker',
    cipDefinition: 'Instructional content is defined in code 48.0801.',
  },
  {
    cipFamily: '48',
    cipCode: '48.0801',
    cipTitle: 'Boilermaking/Boilermaker',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to fabricate and repair steam boiler components.  Includes instruction in principles of steam power, material selection, welding, riveting, sealing materials, pressure testing, pipefitting, and applicable codes and standards.',
    Examples: 'Examples: - Construction Boilermaker',
  },
  {
    cipFamily: '48',
    cipCode: '48.99',
    cipTitle: 'Precision Production, Other',
    cipDefinition: 'Instructional content is defined in code 48.9999.',
  },
  {
    cipFamily: '48',
    cipCode: '48.9999',
    cipTitle: 'Precision Production, Other',
    cipDefinition: 'Any instructional program in precision production not listed above.',
    Examples: 'Examples: - Diamond Cutting, - Diamond Polishing, - Diamond Cutting and Polishing',
  },
  {
    cipFamily: '49',
    cipCode: '49',
    cipTitle: 'TRANSPORTATION AND MATERIALS MOVING',
    cipDefinition:
      'Instructional programs that prepare individuals to apply technical knowledge and skills to perform tasks and services that facilitate the movement of people or materials.',
  },
  {
    cipFamily: '49',
    cipCode: '49.01',
    cipTitle: 'Air Transportation',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 49.0101 - 49.0199.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0101',
    cipTitle: 'Aeronautics/Aviation/Aerospace Science and Technology, General',
    cipDefinition:
      'A program that focuses on the general study of aviation and the aviation industry, including in-flight and ground support operations.  Includes instruction in the technical, business, and general aspects of air transportation systems.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0102',
    cipTitle: 'Airline/Commercial/Professional Pilot and Flight Crew',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the flying and/or navigation of commercial passenger and cargo, agricultural, public service, corporate and rescue fixed wing aircraft.  Includes instruction in principles of aircraft design and performance, aircraft flight systems and controls, flight crew operations and procedures, radio communications, navigation procedures and systems, airways safety and traffic regulations, and governmental rules and regulations pertaining to piloting aircraft.  Programs may qualify individuals to sit for the FAA commercial and airline aircrew examinations.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0104',
    cipTitle: 'Aviation/Airway Management and Operations',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the management of aviation industry operations and services.  Includes instruction in airport operations, ground traffic direction, ground support and flightline operations, passenger and cargo operations, flight safety and security operations, aviation industry regulation, and related business aspects of managing aviation enterprises.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0105',
    cipTitle: 'Air Traffic Controller',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to air-traffic management and control, usually with additional training at the FAA Flight Control Center in a cooperative education program.  Includes instruction in flight control, the use of radar and electronic scanning devices, plotting of flights, radio communication, interpretation of weather conditions affecting flights, flight instrumentation used by pilots, and maintenance of flight-control center or control-tower log books.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0106',
    cipTitle: 'Airline Flight Attendant',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the performance of a variety of personal services conducive to the safety and comfort of airline passengers during flight, including verifying tickets, explaining the use of safety equipment, providing passenger services, and responding to in-flight emergencies.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0108',
    cipTitle: 'Flight Instructor',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the training of pilots or navigators, to prepare them to fly and/or navigate commercial passenger and cargo, agricultural, public service, corporate and rescue aircraft, and fixed or rotary wing aircraft.  Includes instruction in principles of aircraft design and performance, aircraft flight systems and controls, flight crew operations and procedures, radio communications and navigation procedures and systems, airways safety and traffic regulations, and governmental rules and regulations pertaining to piloting aircraft.  Programs may qualify individuals to administer the FAA commercial air crew examinations.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0109',
    cipTitle: 'Remote Aircraft Pilot',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to fly unmanned aircraft system (UAS) for commercial, professional, or personal use, and qualifies individuals to sit for the FAA Remote Pilot Certificate with small unmanned aircraft systems (sUAS) rating knowledge examination. Includes instruction in principles of unmanned aircraft system design and performance; aircraft flight systems and controls; airway safety and traffic regulations; and governmental rules and regulations pertaining to piloting unmanned aircraft.',
    CrossReferences: '36.0207 - Remote Aircraft Pilot (Personal).',
    Examples: 'Examples: - Unmanned Aircraft Systems (UAS)',
  },
  {
    cipFamily: '49',
    cipCode: '49.0199',
    cipTitle: 'Air Transportation, Other',
    cipDefinition: 'Any instructional program in aviation and air transportation services not listed above.',
  },
  {
    cipFamily: '49',
    cipCode: '49.02',
    cipTitle: 'Ground Transportation',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 49.0202 - 49.0299.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0202',
    cipTitle: 'Construction/Heavy Equipment/Earthmoving Equipment Operation',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to operate and maintain a variety of heavy equipment, such as a crawler tractors, motor graders and scrapers, shovels, rigging devices, hoists, and jacks.  Includes instruction in digging, ditching, sloping, stripping, grading, and backfiling, clearing and excavating.',
    CrossReferences: '1.0204 - Agricultural Power Machinery Operation.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0205',
    cipTitle: 'Truck and Bus Driver/Commercial Vehicle Operator and Instructor',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to drive trucks and buses, delivery vehicles, for-hire vehicles and other commercial vehicles, or to instruct commerical vehicle operators. Includes instruction in operating gas, diesel, or electrically-powered vehicles; loading and unloading cargo or passengers; reporting delays or accidents on the road; verifying load against shipping papers; arranging transportation for personnel; and keeping records of receipts and fares.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0206',
    cipTitle: 'Mobil Crane Operator/Operation',
    cipDefinition:
      'A program that prepares individuals to hoist and swing loads via complex rotating machinery that is driver operated.  Includes instruction in crane operational characteristics, load and capacity chart usage, emergency control skills, safety, specific types of mobile cranes and loading devices, applicable codes and standards, and applications to particular types of jobs and loads.',
    Examples:
      'Examples: - Crane and Hoist Operator, - Crane and Hoisting Equipment Operator, - Crane Operator, - Mobile Crane Operator',
  },
  {
    cipFamily: '49',
    cipCode: '49.0207',
    cipTitle: 'Flagging and Traffic Control',
    cipDefinition:
      'A program that prepares individuals to control the movement of traffic through construction and maintenance zones and to protect the safety of work crews, drivers, and pedestrians. Includes instruction in flagging duties and responsibilities, the traffic control zone, flagging signals, pavement markers, freeway flagging, utility flagging, emergency response flagging, and night traffic control.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0208',
    cipTitle: 'Railroad and Railway Transportation',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to the operation of railroads and other aspects of the railway industry, including railroad and railyard service. Includes instruction in railway culture, operating skills, General Code of Operation rules, conductor service, signal systems, switching, transportation of hazardous materials, safety, and railway telecommunications systems.',
    Examples:
      'Examples: - Railway Operations, - Railroad Conductor, - Locomotive Engineer, - Brakeman/Brakewoman, - Railway Dispatcher, - Yardmaster, - Railroad Maintenance of Way Welding',
  },
  {
    cipFamily: '49',
    cipCode: '49.0209',
    cipTitle: 'Forklift Operation/Operator',
    cipDefinition:
      'A program that prepares individuals to operate forklifts in a variety of work environments. Includes instruction in accident prevention, forklift operation, materials handling, and worksite safety.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0299',
    cipTitle: 'Ground Transportation, Other',
    cipDefinition: 'Any instructional program in vehicle and equipment operation not listed above.',
  },
  {
    cipFamily: '49',
    cipCode: '49.03',
    cipTitle: 'Marine Transportation',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 49.0303 - 49.0399.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0303',
    cipTitle: 'Commercial Fishing',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to function as commercial fishermen, fishing operations supervisors or in related fishing industry operations.  Includes instruction in fishing vessel operation; fishing equipment operation and maintenance; equipment repair; catch identification, sorting, and storage; safety procedures; record keeping; and applicable legal regulations.',
    CrossReferences: '3.0301 - Fishing and Fisheries Sciences and Management.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0304',
    cipTitle: 'Diver, Professional and Instructor',
    cipDefinition:
      'A program that prepares individuals to apply technical knowledge and skills to function as professional deep-water or scuba divers, diving instructors, or diving support personnel.  Includes instruction in the use of diving equipment and related specialized gear; diving safety procedures; operation and maintenance of underwater life-support systems; underwater communication systems; decompression systems; underwater salvage; exploration, rescue, and photography; and installation and fitting of underwater mechanical systems and their maintenance, repair or demolition.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0309',
    cipTitle: 'Marine Science/Merchant Marine Officer',
    cipDefinition:
      'A program that prepares individuals to serve as captains, executive officers, engineers and ranking mates on commercially licensed inland, coastal and ocean-going vessels.  Includes instruction in maritime traditions and law, maritime policy, economics and management of commercial marine operations, basic naval architecture and engineering, shipboard power systems engineering, crew supervision, and administrative procedures.',
  },
  {
    cipFamily: '49',
    cipCode: '49.0399',
    cipTitle: 'Marine Transportation, Other',
    cipDefinition: 'Any instructional program in water transportation not listed above.',
  },
  {
    cipFamily: '49',
    cipCode: '49.99',
    cipTitle: 'Transportation and Materials Moving, Other',
    cipDefinition: 'Instructional content is defined in code 49.9999.',
  },
  {
    cipFamily: '49',
    cipCode: '49.9999',
    cipTitle: 'Transportation and Materials Moving, Other',
    cipDefinition: 'Any instructional program in transportation and materials moving not listed above.',
  },
  {
    cipFamily: '50',
    cipCode: '50',
    cipTitle: 'Visual and Performing Arts',
    cipDefinition:
      'Instructional programs that focus on the creation and interpretation of works and performances that use auditory, kinesthetic, and visual phenomena to express ideas and emotions in various forms, subject to aesthetic criteria.',
  },
  {
    cipFamily: '50',
    cipCode: '50.01',
    cipTitle: 'Visual and Performing Arts, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.0101 - 50.0102.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0101',
    cipTitle: 'Visual and Performing Arts, General',
    cipDefinition:
      'A general, undifferentiated program that focuses on the visual and performing arts and that may prepare individuals in any of the visual artistic media or performing disciplines.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0102',
    cipTitle: 'Digital Arts',
    cipDefinition:
      'A general, undifferentiated program that focuses on the use of computerized digital images as the primary medium of expression in the visual and performing arts, and that may prepare individuals for a wide variety of careers using new media, including graphic design, digital animation, motion graphics, 3D visualization, game and interactive media design, music and sound design, video production, web design, photography, and other fields.',
    CrossReferences: '9.0702 - Digital Communication and Media/Multimedia.',
    Examples: 'Examples: - Digital Art',
  },
  {
    cipFamily: '50',
    cipCode: '50.02',
    cipTitle: 'Crafts/Craft Design, Folk Art and Artisanry',
    cipDefinition: 'Instructional content is defined in code 50.0201.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0201',
    cipTitle: 'Crafts/Craft Design, Folk Art and Artisanry',
    cipDefinition:
      'A program that focuses on the aesthetics, techniques, and creative processes for designing and fashioning objects in one or more of the handcraft or folk art traditions, and that prepares individuals to create in any of these media.',
  },
  {
    cipFamily: '50',
    cipCode: '50.03',
    cipTitle: 'Dance',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.0301 - 50.0399.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0301',
    cipTitle: 'Dance, General',
    cipDefinition:
      'A general program that prepares individuals to express ideas, feelings, and/or inner visions through the performance of one or more of the dance disciplines, including but not limited to ballet, modern, jazz, ethnic, and folk dance, and that focuses on the study and analysis of dance as a cultural phenomenon.  Includes instruction in  technique, choreography, Laban notation, dance history and criticism, and dance production.',
    Examples: 'Examples: - Dance Movement Studies',
  },
  {
    cipFamily: '50',
    cipCode: '50.0302',
    cipTitle: 'Ballet',
    cipDefinition:
      'A program that prepares individuals to express ideals and feelings through interpretation of classical dance choreography.  Includes instruction in ballet exercise, solo and ensemble dance, history of ballet, ballet choreography, Laban notation, ballet technique, and interpretations of specific styles and schools.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0399',
    cipTitle: 'Dance, Other',
    cipDefinition: 'Any instructional program in dance not listed above.',
  },
  {
    cipFamily: '50',
    cipCode: '50.04',
    cipTitle: 'Design and Applied Arts',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.0401 - 50.0499.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0401',
    cipTitle: 'Design and Visual Communications, General',
    cipDefinition:
      'A program in the applied visual arts that focuses on the general principles and techniques for effectively communicating ideas and information, and packaging products, in digital and other formats to business and consumer audiences, and that may prepare individuals in any of the applied art media.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0402',
    cipTitle: 'Commercial and Advertising Art',
    cipDefinition:
      'A program in the applied visual arts that prepares individuals to use artistic techniques to effectively communicate ideas and information to business and consumer audiences via illustrations and other forms of digital or printed media.  Includes instruction in concept design, layout, paste-up, and techniques such as engraving, etching, silkscreen, lithography, offset, drawing and cartooning, painting, collage, and computer graphics.',
    CrossReferences: '9.0903 - Advertising.',
    Examples: 'Examples: - Sign Painting, - Commercial Art Painting',
  },
  {
    cipFamily: '50',
    cipCode: '50.0404',
    cipTitle: 'Industrial and Product Design',
    cipDefinition:
      'A program in the applied visual arts that prepares individuals to use artistic techniques to effectively communicate ideas and information to business and consumer audiences via the creation of effective forms, shapes, and packaging for manufactured products.  Includes instruction in designing in a wide variety of plastic and digital media, prototype construction, design development and refinement, principles of cost saving, and product structure and performance criteria relevant to aesthetic design parameters.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0406',
    cipTitle: 'Commercial Photography',
    cipDefinition:
      'A program in the applied visual arts that prepares individuals to use artistic techniques to effectively communicate ideas and information to business and consumer audiences, and recording events and people, via digital, film, still and video photography.  Includes instruction in specialized camera and equipment operation and maintenance, applications to commercial and industrial needs, and photography business operations.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0407',
    cipTitle: 'Fashion/Apparel Design',
    cipDefinition:
      "A program that prepares individuals to apply artistic principles and techniques to the professional design of commercial fashions, apparel, and accessories, and the management of fashion development projects.  Includes instruction in apparel design; accessory design; the design of men's', women's', and children's' wear; flat pattern design; computer-assisted design and manufacturing; concept planning; designing in specific materials; labor and cost analysis; history of fashion; fabric art and printing; and the principles of management and operations in the fashion industry.",
    CrossReferences: '19.0902 - Apparel and Textile Manufacture.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0408',
    cipTitle: 'Interior Design',
    cipDefinition:
      'A program in the applied visual arts that prepares individuals to apply artistic principles and techniques to the professional planning, designing, equipping, and furnishing of residential and commercial interior spaces.  Includes instruction in computer applications, drafting, and graphic techniques; principles of interior lighting, acoustics, systems integration, and color coordination; furniture and furnishings; textiles and their finishing; the history of interior design and period styles; basic structural design; building codes and inspection regulations; and applications to office, hotel, factory, restaurant and housing design.',
    CrossReferences: '4.0501 - Interior Architecture., 19.0601 - Housing and Human Environments, General.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0409',
    cipTitle: 'Graphic Design',
    cipDefinition:
      'A program that prepares individuals to apply artistic and computer techniques to the interpretation of technical and commercial concepts. Includes instruction in computer-assisted art and design, printmaking, concepts sketching, technical drawing, color theory, imaging, studio technique, still and life modeling, multimedia applications, communication skills and commercial art business operations.',
    CrossReferences: '10.0301 - Graphic Communications, General.',
  },
  {
    cipFamily: '50',
    cipCode: '50.041',
    cipTitle: 'Illustration',
    cipDefinition:
      'A program that prepares individuals to use artistic techniques to develop and execute interpretations of the concepts of authors and designers to specifications.  Includes instruction in book illustration, fashion illustration, map illustration, rendering, exhibit preparation, textual layout, cartooning, and the use of various artistic techniques as requested by clients.',
    CrossReferences: '51.2703 - Medical Illustration/Medical Illustrator.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0411',
    cipTitle: 'Game and Interactive Media Design',
    cipDefinition:
      'A program that focuses on the design, development, and programming of interactive media entertainment, including computer and video games, virtual environments,  Internet applications, and other interactive media. Includes instruction in theory of games, turn-based games, real-time games, visual and interactive design, story development, animation, simulation, and programming.',
    CrossReferences: '11.0804 - Modeling, Virtual Environments and Simulation., 11.0204 - Computer Game Programming.',
    Examples: 'Examples: - Game Design, - Game Development',
  },
  {
    cipFamily: '50',
    cipCode: '50.0499',
    cipTitle: 'Design and Applied Arts, Other',
    cipDefinition: 'Any instructional program in design and applied arts not listed above.',
  },
  {
    cipFamily: '50',
    cipCode: '50.05',
    cipTitle: 'Drama/Theatre Arts and Stagecraft',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.0501 - 50.0599.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0501',
    cipTitle: 'Drama and Dramatics/Theatre Arts, General',
    cipDefinition:
      'A program that focuses on the general study of dramatic works and their performance.  Includes instruction in major works of dramatic literature, dramatic styles and types, and the principles of organizing and producing full live or filmed productions.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0502',
    cipTitle: 'Technical Theatre/Theatre Design and Technology',
    cipDefinition:
      'A program that prepares individuals to apply artistic, technical and dramatic principles and techniques to the communication of dramatic information, ideas, moods, and feelings through technical theatre methods.  Includes instruction in set design, lighting design, sound effects, theatre acoustics, scene painting, property management, costume design, and technical direction and production and use of computer applications to support these functions.',
    Examples: 'Examples: - Scene Painting',
  },
  {
    cipFamily: '50',
    cipCode: '50.0504',
    cipTitle: 'Playwriting and Screenwriting',
    cipDefinition:
      'A program that focuses on the principles and techniques for communicating dramatic information, ideas, moods, and feelings through the composition of creative written works for the theatre, film, and/or television. Includes instruction in creative writing craft, scene writing, script development, stage and/or camera instructions, line and moment analysis, script reading, script editing, and the creation of full productions.',
    Examples: 'Examples: - Dramatic Writing, - Writing for Screen and Television',
  },
  {
    cipFamily: '50',
    cipCode: '50.0505',
    cipTitle: 'Theatre Literature, History and Criticism',
    cipDefinition:
      'A program that focuses on the study of the history, literature, theory, and analysis of written plays, theatrical productions, and theatre methods and organization.  Includes instruction in historical method; critical theory; literary analysis; the study of themes and archetypes in dramatic literature; the history of acting, directing, and technical theatre; and the study of specific historical and cultural styles and traditions.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0506',
    cipTitle: 'Acting',
    cipDefinition:
      'A program that prepares individuals to communicate dramatic information, ideas, moods, and feelings through the achievement of naturalistic and believable behavior in imaginary circumstances.  Includes instruction in voice and acting speech, stage dialects, movement, improvisation, acting styles, theatre history, script interpretation, and actor coaching.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0507',
    cipTitle: 'Directing and Theatrical Production',
    cipDefinition:
      'A program that prepares individuals to manage the planning, design, preparation, and production of plays and other theatrical entertainment programs.  Includes instruction in theatrical design, program management, dramatic production, rehearsal management, personnel management and casting, actor coaching, directing concepts and techniques, theater history, scene work, script interpretation, business management, marketing, public relations, and communications skills.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0509',
    cipTitle: 'Musical Theatre',
    cipDefinition:
      'A program that focuses on the principles and techniques for integrating theatre, music, and dance into a unified production. Includes instruction in acting, dance, voice, technical theater, musical genres, piano, and history of musical theater.',
  },
  {
    cipFamily: '50',
    cipCode: '50.051',
    cipTitle: 'Costume Design',
    cipDefinition:
      'A program that prepares individuals to design and select costumes for characters in theatre and the performing arts and to serve as part of a production team. Includes instruction in costume design, script analysis, theory and criticism, period styles, history of costume, stage makeup, rendering, and costume construction.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0511',
    cipTitle: 'Comedy Writing and Performance',
    cipDefinition:
      'A program that focuses on the historical, cultural, and theoretical dimensions of comedy as an art form, and the writing, performance, and production of comedy. Includes instruction in comedic storytelling, comedy production, comedy writing, history of comedy, improvisation, and sketch writing.',
    Examples: 'Examples: - Comedic Arts, - Comedy Studies',
  },
  {
    cipFamily: '50',
    cipCode: '50.0512',
    cipTitle: 'Theatre and Dance',
    cipDefinition:
      'A program of study that focuses on integrating the studies of dance and theatre arts to provide perspectives in all facets of production, performance, and critical engagement. Includes instruction in acting, aesthetics, choreography, costume design, criticism, dance, improvisation, music analysis, playwriting, theatre performance history, scene design, stage lighting, and technical theatre.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0599',
    cipTitle: 'Dramatic/Theatre Arts and Stagecraft, Other',
    cipDefinition: 'Any instructional program in dramatic/theatre arts and stagecraft not listed above.',
    CrossReferences: '51.2315 - Drama Therapy/Therapist.',
  },
  {
    cipFamily: '50',
    cipCode: '50.06',
    cipTitle: 'Film/Video and Photographic Arts',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.0601 - 50.0699.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0601',
    cipTitle: 'Film/Cinema/Media Studies',
    cipDefinition:
      'A program in the visual arts that focuses on the study of the history, development, theory, and criticism of the film/media arts, as well as the basic principles of filmmaking and film production.',
    Examples: 'Examples: - Video Studies',
  },
  {
    cipFamily: '50',
    cipCode: '50.0602',
    cipTitle: 'Cinematography and Film/Video Production',
    cipDefinition:
      'A program that prepares individuals to communicate dramatic information, ideas, moods, and feelings through the making and producing of films and videos. Includes instruction in theory of film, film technology and equipment operation, film production, film directing, film editing, cinematographic art, film audio, techniques for making specific types of films and/or videos, media technologies, computer image making, multi-media production, and the planning and management of film/video operations.',
    Examples: 'Examples: - Cinema Production',
  },
  {
    cipFamily: '50',
    cipCode: '50.0605',
    cipTitle: 'Photography',
    cipDefinition:
      'A program that focuses on the principles and techniques of communicating information, ideas, moods, and feelings through the creation of images on photographic film, plates, and digital images and that may prepare individuals to be professional photographic artists.  Includes instruction in camera and equipment operation and maintenance, film and plate developing, light and composition, films and printing media, color and special effects, photographic art, photographic history, use of computer applications to record or enhance images and applications to the photography of various subjects.',
    CrossReferences: '9.0404 - Photojournalism.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0607',
    cipTitle: 'Documentary Production',
    cipDefinition:
      'A program that prepares individuals to produce nonfiction film and video. Includes instruction in film and video writing, directing, and production; film studies; history of the documentary; research techniques; videography; editing; and business aspects of film production.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0699',
    cipTitle: 'Film/Video and Photographic Arts, Other',
    cipDefinition: 'Any instructional program in film/video and photographic arts not listed above.',
  },
  {
    cipFamily: '50',
    cipCode: '50.07',
    cipTitle: 'Fine and Studio Arts',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.0701 - 50.0799.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0701',
    cipTitle: 'Art/Art Studies, General',
    cipDefinition:
      'A general program that focuses on the introductory study and appreciation of the visual arts.  Includes instruction in art, photography, and other visual communications media.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0702',
    cipTitle: 'Fine/Studio Arts, General',
    cipDefinition:
      'A program that prepares individuals to generally function as creative artists in the visual and plastic media.  Includes instruction in the traditional fine arts media (drawing, painting, sculpture, printmaking, CAD/CAM) and/or modern media (ceramics, textiles, intermedia, photography, digital images), theory of art, color theory, composition and perspective, anatomy, the techniques and procedures for maintaining equipment and managing a studio, and art portfolio marketing.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0703',
    cipTitle: 'Art History, Criticism and Conservation',
    cipDefinition:
      'A program that focuses on the study of the historical development of art as social and intellectual phenomenon, the analysis of works of art, and art conservation.  Includes instruction in the theory of art, art history research methods, connoisseurship, the preservation and conservation of works of art, and the study of specific periods, cultures, styles, and themes.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0705',
    cipTitle: 'Drawing',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions through representation by lines made on a surface.  Includes instruction in eye-hand coordination; line, value, shape, and perspective; figure and still life drawing; the use of media such as pen and ink, pencil, charcoal, pastel, and brush; and personal style development.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0706',
    cipTitle: 'Intermedia/Multimedia',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions in either two or three dimensions, through simultaneous use of a variety of materials and media.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0708',
    cipTitle: 'Painting',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions by the application of paints and related chemical color substances to canvases or other materials.  Includes instruction in color and color mixing, surface preparation, composition, oil and acrylic media, watercolor media, painting techniques, and personal style development.',
    Examples: 'Examples: - Painting (Fine Art)',
  },
  {
    cipFamily: '50',
    cipCode: '50.0709',
    cipTitle: 'Sculpture',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions by creating three-dimensional art works.  Includes instruction in the analysis of form in space; round and relief concepts; sculptural composition; modern and experimental methods; different media such as clay, plaster, wood, stone, and metal; techniques such as carving, molding, welding, casting, and modeling; and personal style development.',
  },
  {
    cipFamily: '50',
    cipCode: '50.071',
    cipTitle: 'Printmaking',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions by rendering art concepts onto surfaces and transferring images, via ink or dyes, onto paper or fabric.  Includes instruction in monochrome and color printing; tonality; chemistry; equipment set-up and maintenance; techniques such as serigraphy, lithography, intaglio, woodcut, block, stencil, relief, etching, and composite; and personal style development.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0711',
    cipTitle: 'Ceramic Arts and Ceramics',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions by producing art works out of clay and similar materials.  Includes instruction in handbuilt and wheelthrown techniques; molding; slips and glazes; trimming and decorating; firing and kiln operation; oxidation; mixed media; ceramic murals; and personal style development.',
    CrossReferences: '14.0601 - Ceramic Sciences and Engineering.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0712',
    cipTitle: 'Fiber, Textile and Weaving Arts',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions by constructing art works from woven or non-woven fabrics and fibrous materials.  Includes instruction in weaving techniques and loom operation; non-woven techniques such as knitting, coiling, netting, and crocheting; quilting; dyeing and pigmentation; printing and other finishing techniques; pattern design; tapestry; and personal style development.',
    CrossReferences: '19.0902 - Apparel and Textile Manufacture.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0713',
    cipTitle: 'Jewelry Arts',
    cipDefinition:
      'A program that prepares individuals creatively and technically to express emotions, ideas, or inner visions by fashioning jewelry from gems, other stones, and precious metals. Includes instruction in design concepts, electroforming, enameling, gemology, lapidary technique and art, metal and non-metal casting and molding, metal coloring, metalsmithing and finishing, personal style development, photo etching, and stone cutting and polishing.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0714',
    cipTitle: 'Metal Arts',
    cipDefinition:
      'A program that focuses on the development of individual artistic expression and technical skills necessary for fabricating refined metal works. Includes instruction in aesthetics, anodizing, art history, casting, design, electroforming, enameling, metalsmithing, sculpture, two and three-dimensional design, wax carving, and welding.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0799',
    cipTitle: 'Fine Arts and Art Studies, Other',
    cipDefinition: 'Any instructional program in fine arts and art studies not listed above.',
    Examples: 'Examples: - Glass Making',
  },
  {
    cipFamily: '50',
    cipCode: '50.09',
    cipTitle: 'Music',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.0901 - 50.0999.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0901',
    cipTitle: 'Music, General',
    cipDefinition:
      'A general program that focuses on the introductory study and appreciation of music and the performing arts.  Includes instruction in music, dance, and other performing arts media.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0902',
    cipTitle: 'Music History, Literature, and Theory',
    cipDefinition:
      'A program that focuses on the study of the historical evolution of music as a social and intellectual phenomenon, the development of musical instruments and techniques, and the analysis and criticism of musical literature.  Includes instruction in music history research methods, aesthetic analysis of musical compositions, history of musical writing and notation, the development of musical instruments, the development of music theory, and the study of specific periods, cultural traditions, styles, and themes.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0903',
    cipTitle: 'Music Performance, General',
    cipDefinition:
      'A program that generally prepares individuals to master musical instruments and performing art as solo and/or ensemble performers.  Includes instruction on one or more specific instruments from various instrumental groupings.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0904',
    cipTitle: 'Music Theory and Composition',
    cipDefinition:
      'A program that focuses on the study of the principles of sound manipulation as applied to the creation of music, and the techniques of creating and arranging music.  Includes instruction in aural theory, melody, counterpoint, complex harmony, modulation, chromatics, improvisation, progressions, musical writing, instrumentation, orchestration, electronic and computer applications, studies of specific musical styles, and development of original creative ability.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0905',
    cipTitle: 'Musicology and Ethnomusicology',
    cipDefinition:
      'A program that focuses on the systematic study of the forms and methods of music art, and the functions of music, in Western and non-Western societies and cultures.  Includes instruction in music theory, musicological research methods, and studies of specific cultural styles such as jazz, folk music, rock, ethnic musical traditions, and the music of non-Western cultures.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0906',
    cipTitle: 'Conducting',
    cipDefinition:
      'A program that prepares individuals to master the art of leading bands, choirs, orchestras and other ensembles in performance, and related music leadership.  Includes instruction in score analysis and arranging, rehearsal and performance leadership, music coaching, arrangement and performance planning, ensemble operations management, and applications to specific school or professional ensembles.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0907',
    cipTitle: 'Keyboard Instruments',
    cipDefinition:
      'A program that prepares individuals to master the piano, organ, or related keyboard instruments and performing art as solo, ensemble, and/or accompanist performers.  Includes instruction in piano and keyboard pedagogy, ensemble playing, accompanying, service playing, repertoire, keyboard and pedal skills, recital, and personal style development.',
    Examples: 'Examples: - Piano, - Organ, - Harpsichord',
  },
  {
    cipFamily: '50',
    cipCode: '50.0908',
    cipTitle: 'Voice and Opera',
    cipDefinition:
      'A program that prepares individuals to master the human voice and performing art as solo and/or ensemble performers in concert, choir, opera, or other forms of singing.  Includes instruction in voice pedagogy, diction, vocal physiology and exercise, expressive movement, repertoire, recital, and personal style development.',
  },
  {
    cipFamily: '50',
    cipCode: '50.091',
    cipTitle: 'Jazz/Jazz Studies',
    cipDefinition:
      'A program that prepares individuals to study and master the performance and composition of Jazz.  Includes instruction in the history of Jazz and related musical styles such as the Blues, Jazz composition and theory, improvisation, Jazz instrument and ensemble performance, and related topics.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0911',
    cipTitle: 'Stringed Instruments',
    cipDefinition:
      'A program that prepares individual to master a stringed instrument and performing arts as solo, ensemble and/or accompanist performers.  Includes instruction in playing and personal style development.',
    Examples: 'Examples: - Violin, - Viola, - Double Bass, - Guitar, - Banjo, - Harp',
  },
  {
    cipFamily: '50',
    cipCode: '50.0912',
    cipTitle: 'Music Pedagogy',
    cipDefinition:
      'A program that prepares individuals to provide musical instruction and tutoring to clients in private and institutional settings.  Includes instruction in music theory, music composition, mastery of one or more instruments, familiarity with various musical genres and styles, principles of music education, and client communications.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0913',
    cipTitle: 'Music Technology',
    cipDefinition:
      'A program that focuses on the creative aspects of the blending of music and technology; and that prepares individuals to apply technical knowledge and skills to the composition, recording, synthesis, and performance of music; audio-visual production; scoring for film and multimedia; and software and multimedia development. Includes instruction in music theory, music history, composition, acoustics, recording technology, computer composition, electronic music synthesis, technology-based performance, 3D sound and spatial audio, and music business and law.',
    CrossReferences: '10.0203 - Recording Arts Technology/Technician.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0914',
    cipTitle: 'Brass Instruments',
    cipDefinition:
      'A program that prepares individuals to master a brass instrument and performing art as solo, ensemble, and/or accompanist performers. Includes instruction in playing and personal style development.',
    Examples: 'Examples: - Trumpet, - Horn, - Trombone, - Euphonium/Baritone, - Tuba',
  },
  {
    cipFamily: '50',
    cipCode: '50.0915',
    cipTitle: 'Woodwind Instruments',
    cipDefinition:
      'A program that prepares individuals to master a woodwind instrument and performing art as solo, ensemble, and/or accompanist  performers. Includes instruction in playing and personal style development.',
    Examples: 'Examples: - Flute, - Oboe, - Clarinet, - Bassoon, - Saxophone',
  },
  {
    cipFamily: '50',
    cipCode: '50.0916',
    cipTitle: 'Percussion Instruments',
    cipDefinition:
      'A program that prepares individuals to master percussion instruments and performing art for a variety of musical settings, including orchestral, solo, chamber, commercial, or nontraditional. Includes instruction in playing and personal style development.',
    Examples: 'Examples: - Bass Drum, - Snare Drum, - Timpani, - Vibraphone',
  },
  {
    cipFamily: '50',
    cipCode: '50.0917',
    cipTitle: 'Sound Arts',
    cipDefinition:
      'A program that focuses on sound as a form of cultural expression, and its relationship to other disciplines such as computer programming, performance, and video. Includes instruction in acoustics; arranging, composing, and editing music; music theory; musicology; orchestration and scoring; and sound design.',
    CrossReferences: '10.0203 - Recording Arts Technology/Technician.',
  },
  {
    cipFamily: '50',
    cipCode: '50.0999',
    cipTitle: 'Music, Other',
    cipDefinition: 'Any instructional program in music not listed above.',
  },
  {
    cipFamily: '50',
    cipCode: '50.10',
    cipTitle: 'Arts, Entertainment, and Media Management',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 50.1001 - 50.1099.',
  },
  {
    cipFamily: '50',
    cipCode: '50.1001',
    cipTitle: 'Arts, Entertainment, and Media Management, General',
    cipDefinition:
      'A program that prepares individuals to organize and manage various aspects of the visual arts, performing arts, and entertainment media industries.  Includes instruction in business and financial management, marketing and fund-raising, labor relations for the arts, event promotion and management, product and artist management, and applicable law.',
    Examples: 'Examples: - Entertainment Business, - Performing Arts Administration',
  },
  {
    cipFamily: '50',
    cipCode: '50.1002',
    cipTitle: 'Fine and Studio Arts Management',
    cipDefinition:
      'A program that prepares individuals to organize and manage fine and studio art organizations, operations, and facilities. Includes instruction in business and financial management; marketing and fundraising; personnel management and labor relations; event promotion and management; public relations and arts advocacy; arts law; and applications to specific arts activities such as galleries, museums, studios, foundations, and community organizations.',
    CrossReferences: '30.1401 - Museology/Museum Studies.',
    Examples: 'Examples: - Visual Arts Management',
  },
  {
    cipFamily: '50',
    cipCode: '50.1003',
    cipTitle: 'Music Management',
    cipDefinition:
      'A program that prepares individuals to organize and manage music organizations, operations, facilities, and personnel.  Includes instruction in business and financial management; marketing and fundraising; personnel management and labor relations; event promotion and management; music products merchandising; artist agency and promotion; music law; and applications to specific activities such as managing theaters, recording studios and companies, bands and other ensembles, individual artists, and music organizations.',
    Examples: 'Examples: - Music Merchandising',
  },
  {
    cipFamily: '50',
    cipCode: '50.1004',
    cipTitle: 'Theatre/Theatre Arts Management',
    cipDefinition:
      'A program that prepares individuals to apply business management principles to the management of theatres and production corporations.  Includes instruction in theatrical production, theatre design and planning, fund-raising and promotion, investment strategies, human resources management, theatre operations management, marketing, public relations, financial management and insurance, and applicable laws and regulations.',
  },
  {
    cipFamily: '50',
    cipCode: '50.1099',
    cipTitle: 'Arts, Entertainment, and Media Management, Other',
    cipDefinition: 'Any instructional program in arts, entertainment, and media management not listed above.',
  },
  {
    cipFamily: '50',
    cipCode: '50.11',
    cipTitle: 'Community/Environmental/Socially-Engaged Art',
    cipDefinition: 'Instructional content is defined in code 50.1101.',
  },
  {
    cipFamily: '50',
    cipCode: '50.1101',
    cipTitle: 'Community/Environmental/Socially-Engaged Art',
    cipDefinition:
      'A program that focuses on using the arts to promote activism, civic dialog, community development, ecological and environmental awareness, or social justice. Includes instruction in community organizing, cultural criticism, social practice, studio art, and visual and performing arts.',
    Examples:
      'Examples: - Community Arts Practice, - Socially-Engaged Practice in Design and the Arts, - Socially-Engaged Art, - Socially-Engaged Studio Art, - Art and Social Practice',
  },
  {
    cipFamily: '50',
    cipCode: '50.99',
    cipTitle: 'Visual and Performing Arts, Other',
    cipDefinition: 'Instructional content is defined in code 50.9999.',
  },
  {
    cipFamily: '50',
    cipCode: '50.9999',
    cipTitle: 'Visual and Performing Arts, Other',
    cipDefinition: 'Any instructional program in visual and performing arts not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51',
    cipTitle: 'Health Professions and Related Programs',
    cipDefinition:
      'Instructional programs that prepare individuals to practice as licensed professionals and assistants in the health care professions and related clinical sciences and administrative and support services.',
  },
  {
    cipFamily: '51',
    cipCode: '51.00',
    cipTitle: 'Health Services/Allied Health/Health Sciences, General',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.0000 - 51.0001.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0000',
    cipTitle: 'Health Services/Allied Health/Health Sciences, General',
    cipDefinition:
      'A general, introductory, undifferentiated, or joint program in health services occupations that prepares individuals for either entry into specialized training programs or for a variety of concentrations in the allied health area.  Includes instruction in the basic sciences, research and clinical procedures, and aspects of the subject matter related to various health occupations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0001',
    cipTitle: 'Health and Wellness, General',
    cipDefinition:
      'A program of study that prepares individuals to assume roles as health/wellness professionals in private business and industry, community organizations, and health care settings. Includes instruction in personal health, community health and welfare, nutrition, epidemiology, disease prevention, fitness and exercise, and health behaviors.',
    CrossReferences: '31.0501 - Sports, Kinesiology, and Physical Education/Fitness, General.',
    Examples:
      'Examples: - Health and Wellness, - Health Promotion and Wellness, - Wellness, Health Promotion and Injury Prevention',
  },
  {
    cipFamily: '51',
    cipCode: '51.01',
    cipTitle: 'Chiropractic',
    cipDefinition: 'Instructional content is defined in code 51.0101.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0101',
    cipTitle: 'Chiropractic',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of chiropractic, a health care and healing system based on the application of non-invasive treatments and spinal adjustments to alleviate health problems caused by vertebral misalignments affecting bodily function as derived from the philosophy of Daniel Palmer.  Includes instruction in the basic medical sciences, chiropractic theory and science, postural and spinal analysis, diagnostic radiology and ultrasound, adjustment technique, patient counseling, professional standards and ethics, and practice management.',
    Examples: 'Examples: - Chiropractic (DC)',
  },
  {
    cipFamily: '51',
    cipCode: '51.02',
    cipTitle: 'Communication Disorders Sciences and Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.0201 - 51.0299.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0201',
    cipTitle: 'Communication Sciences and Disorders, General',
    cipDefinition:
      'A program that focuses on the application of biomedical, psychological, and physical principles to the study of the scientific bases, development, and treatment of speech, language, hearing, and cognitive communication problems caused by disease, injury, or disability. Includes instruction in language science, hearing science, speech and voice science, biology of communication, behavioral linguistics, psychology, and applications to the development of diagnostic and rehabilitative strategies and technologies.',
    Examples: 'Examples: - Communication Disorders',
  },
  {
    cipFamily: '51',
    cipCode: '51.0202',
    cipTitle: 'Audiology/Audiologist',
    cipDefinition:
      'A program that prepares individuals to diagnose and treat hearing loss and other disorders involving the ear, advise patients on means to use their remaining hearing, and select and fit hearing aids and other devices. Includes instruction in acoustics, anatomy and physiology of hearing, hearing measurement, auditory pathology, middle and inner ear analysis, rehabilitation therapies and assistive technologies, and pediatric and other special applications.',
    Examples: 'Examples: - Audiology and Hearing Sciences',
  },
  {
    cipFamily: '51',
    cipCode: '51.0203',
    cipTitle: 'Speech-Language Pathology/Pathologist',
    cipDefinition:
      'A program that prepares individuals to evaluate the speaking, language interpretation, and related physiological and cognitive capabilities of children and/or adults and develop treatment and rehabilitative solutions in consultation with clinicians and educators.  Includes instruction in the anatomy and physiology of speech and hearing, biomechanics of swallowing and vocal articulation, communications disorders, psychology of auditory function and cognitive communication, language assessment and diagnostic techniques, and rehabilitative and management therapies.',
    CrossReferences: '51.0816 - Speech-Language Pathology Assistant.',
    Examples: 'Examples: - Speech Pathology, - Speech-Language Pathology',
  },
  {
    cipFamily: '51',
    cipCode: '51.0204',
    cipTitle: 'Audiology/Audiologist and Speech-Language Pathology/Pathologist',
    cipDefinition:
      'An integrated program that prepares individuals to work as both audiologists and speech-language pathologists. Includes instruction in a variety of communication disorder studies, audiology, speech pathology, language acquisition, and the design and implementation of comprehensive therapeutic and rehabilitative solutions to communications problems.',
    CrossReferences: '51.0816 - Speech-Language Pathology Assistant.',
    Examples: 'Examples: - Audiology and Speech Pathology, - Audiology and Speech-Language Pathology',
  },
  {
    cipFamily: '51',
    cipCode: '51.0299',
    cipTitle: 'Communication Disorders Sciences and Services, Other',
    cipDefinition: 'Any  instructional program in communications disorders sciences and services not listed above.',
    CrossReferences:
      '51.0816 - Speech-Language Pathology Assistant., 51.0918 - Hearing Instrument Specialist., 5.0211 - Deaf Studies., 16.1601 - American Sign Language (ASL).',
    Examples: 'Examples: - Child Speech-Language Disorders, - Pre-Speech Language Pathology and Audiology',
  },
  {
    cipFamily: '51',
    cipCode: '51.04',
    cipTitle: 'Dentistry',
    cipDefinition: 'Instructional content is defined in code 51.0401.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0401',
    cipTitle: 'Dentistry',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of dentistry/dental medicine, encompassing the evaluation, diagnosis, prevention, and treatment of diseases, disorders, and conditions of the oral cavity, maxillofacial area, and adjacent structures and their impact on the human body and health.  Includes instruction in the basic biomedical sciences, occlusion, dental health and prevention, oral pathology, cariology, operative dentistry, oral radiology, principles of the various dental specialties, pain management, oral medicine, clinic and health care management, patient counseling, and professional standards and ethics.',
    Examples: 'Examples: - Dentistry (DDS, DMD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.05',
    cipTitle: 'Advanced/Graduate Dentistry and Oral Sciences',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.0501 - 51.0599.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0501',
    cipTitle: 'Dental Clinical Sciences, General',
    cipDefinition:
      'An integrated or undifferentiated program that generally prepares dentists in one or more of the oral sciences and advanced/graduate dentistry specialties.',
    Examples: 'Examples: - Dental Clinical Sciences, General (MS), - Dental Clinical Sciences, General (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0502',
    cipTitle: 'Advanced General Dentistry',
    cipDefinition:
      'A program that focuses on the advanced study of dental clinical techniques and dental practice issues.  Includes instruction in subjects such as the history of dentistry, advanced dental practice management, dental ethics and jurisprudence, social and behavioral science studies of dentistry, advanced restorative dentistry, oral medicine, oral radiology, advanced clinical procedures and technology, and others.',
    Examples:
      'Examples: - Advanced General Dentistry (Cert.), - Advanced General Dentistry (MS), - Advanced General Dentistry (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0503',
    cipTitle: 'Oral Biology and Oral and Maxillofacial Pathology',
    cipDefinition:
      'A program that focuses on the scientific study of the growth, development, diseases, healing properties, and neurological components of the oral cavity, related tissues and organs, and associated craniofacial areas.  Includes instruction in oral microbiology microanatomy, craniofacial pain, humoral aspects of disease, etiology and histology of caries, plaque ecology, wound healing, oral disease epidemiology, oral manifestations of systemic disease, lesions, normal and pathologic physiology, and related molecular and physical studies.',
    Examples:
      'Examples: - Oral Biology and Oral and Maxillofacial Pathology (MS), - Oral Biology and Oral and Maxillofacial Pathology (PhD), - Oral and Craniofacial Biology, - Craniofacial Biology, - Oral Biology and Oral Pathology',
  },
  {
    cipFamily: '51',
    cipCode: '51.0504',
    cipTitle: 'Dental Public Health and Education',
    cipDefinition:
      'A program that focuses on the scientific study of dental disease prevention and control, community dental health promotion, and prepares dentists and public health professionals to function as dental health specialists.  Includes instruction in preventive dentistry, the relationship of oral disease to health and quality of life, patient and practitioner behavior, dental epidemiology, nutrition and dental health, dental care policy and delivery, oral health program planning and administration, biostatistics, and research methods.',
    Examples:
      'Examples: - Dental Public Health and Education (Cert.), - Dental Public Health and Education (MS, MPH), - Dental Public Health and Education (PhD, DPH)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0505',
    cipTitle: 'Dental Materials',
    cipDefinition:
      'A program that focuses on the scientific study of the biomaterials and inert and active compounds used in dental procedures; the development of dental materials; and their effects on the living tissues of the oral cavity and systemic bodily health.  Includes instruction in materials science, dental bioengineering, biocompatibility of materials, physics and mechanics of dental materials, elastic and plastic deformation, surface bonding, and applications to fixed and removable prostheses and restorative procedures.',
    Examples: 'Examples: - Dental Materials (MS), - Dental Materials (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0506',
    cipTitle: 'Endodontics/Endodontology',
    cipDefinition:
      'A program that focuses on the advanced study of the morphology, physiology, and pathology of human dental pulp and periradicular tissues, and prepares dentists to diagnose and treat internal diseases and injuries of teeth.  Includes instruction in theory and practice of endodontics; pulpal disease; relationship of endodontic conditions to other dental/oral health issues; endodontic operative technique; and patient care and management.',
    Examples:
      'Examples: - Endodontics/Endodontology (Cert.), - Endodontics/Endodontology (MS), - Endodontics/Endodontology (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0507',
    cipTitle: 'Oral/Maxillofacial Surgery',
    cipDefinition:
      'A program that focuses on the advanced study of the diagnosis and intrusive and adjunctive treatment of diseases, injuries, and defects of the oral and maxillofacial regions, including functional and aesthetic aspects.  Includes instruction in pharmacology, analgesia, anesthesia, anxiety control, surgical procedures and techniques, surgical instrumentation, exodontia, oral diseases and malfunctions, soft and hard tissue pathology, dentoalveolar surgery, infection management, and prosthetic implantation.',
    Examples:
      'Examples: - Oral/Maxillofacial Surgery (Cert.), - Oral/Maxillofacial Surgery (MS), - Oral/Maxillofacial Surgery (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0508',
    cipTitle: 'Orthodontics/Orthodontology',
    cipDefinition:
      'A program that focuses on the advanced study of the guidance of growing dental structures and the correction of misalignments, disproportionate growth, and traumas caused by genetics, disease, injuries, and dysfunctional behaviors. Includes instruction in craniofacial growth and development, malocclusion, design and fabrication of orthodontic appliances, craniofacial alignment, physiological function and analysis, cephalometrics, model analysis, space analysis, surgical orthodontics, cleft lip and palate, and treatment planning and management.',
    Examples:
      'Examples: - Orthodontics/Orthodontology (Cert.), - Orthodontics/Orthodontology (MS), - Orthodontics/Orthodontology (PhD), - Orthodontics and Dentofacial Orthopedics',
  },
  {
    cipFamily: '51',
    cipCode: '51.0509',
    cipTitle: 'Pediatric Dentistry/Pedodontics',
    cipDefinition:
      'A program that focuses on the advanced study of the therapeutic and preventive care of the oral health of children from birth through adolescence, and the care of adults with physical, mental, and emotional disabilities.  Includes instruction in developmental oral biology, preventive medicine, diet therapy and counseling, patient management, pediatric restorative procedures, pulp therapy, trauma management, anesthesia, treatment planning, and the treatment of handicapped patients.',
    Examples:
      'Examples: - Pediatric Dentistry/Pedodontics (Cert.), - Pediatric Dentistry/Pedodontics (MS), - Pediatric Dentistry/Pedodontics (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.051',
    cipTitle: 'Periodontics/Periodontology',
    cipDefinition:
      'A program that focuses on the advanced study of the etiology and treatment of diseases of the gingivae (gum tissue) and supporting bone, nerve, and vascular structures.  Includes instruction in periodontium pathology, diagnostic procedures and equipment, occulsion, nutritional aspects of periodontology, surgical treatments, and patient care and management.',
    Examples:
      'Examples: - Periodontics/Periodontology (Cert.), - Periodontics/Periodontology (MS), - Periodontics/Periodontology (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0511',
    cipTitle: 'Prosthodontics/Prosthodontology',
    cipDefinition:
      'A program that focuses on the advanced study of the restoration and maintenance of teeth, oral function, and aesthetic form through the use of single or compound materials, structures, and artificial devices.  Includes instruction in implant dentistry, surgical procedures, denture design and fabrication, fixed and removable prosthodontics, restorative techniques, occlusion therapy and devices, geriatric dentistry, cosmetic dentistry, and tempromandibular joint dysfunction.',
    Examples:
      'Examples: - Prosthodontics/Prosthodontology (Cert.), - Prosthodontics/Prosthodontology (MS), - Prosthodontics/Prosthodontology (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.0512',
    cipTitle: 'Digital Dentistry',
    cipDefinition:
      'A program that focuses on the use of digital and CAD/CAM technology in dental treatment. Includes instruction in digital diagnostic and treatment planning techniques, CAD/CAM restoration, and patient management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0513',
    cipTitle: 'Geriatric Dentistry',
    cipDefinition:
      'A program that focuses on the advanced study of the therapeutic and preventive care of the oral health of geriatric patients. Includes instruction in aging, preventive medicine, diet therapy and counseling, geriatric restorative procedures, pulp therapy, trauma management, anesthesia, treatment planning, and patient management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0514',
    cipTitle: 'Implantology/Implant Dentistry',
    cipDefinition:
      'A program that focuses on dental implantology. Includes instruction in diagnosis and treatment planning, surgical protocols, implant placement, sinus grafting, guided bone regeneration, soft tissue grafting, prosthetic protocols, prosthesis design, and aftercare.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0599',
    cipTitle: 'Advanced/Graduate Dentistry and Oral Sciences, Other',
    cipDefinition: 'Any instructional program in advanced/graduate dentistry and oral sciences not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.06',
    cipTitle: 'Dental Support Services and Allied Professions',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.0601 - 51.0699.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0601',
    cipTitle: 'Dental Assisting/Assistant',
    cipDefinition:
      'A program that prepares individuals to provide patient care, take dental radiographs (x-ray photographs), prepare patients and equipment for dental procedures, and discharge office administrative functions under the supervision of dentists and dental hygienists.  Includes instruction in medical record-keeping, general office duties, reception and patient intake, scheduling, equipment maintenance and sterilization, basic radiography, pre- and post-operative patient care and instruction, chairside assisting, taking tooth and mouth impressions, and supervised practice.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0602',
    cipTitle: 'Dental Hygiene/Hygienist',
    cipDefinition:
      'A program that prepares individuals to clean teeth and apply preventive materials, provide oral health education and treatment counseling to patients, identify oral pathologies and injuries, and manage dental hygiene practices.  Includes instruction in dental anatomy, microbiology, and pathology; dental hygiene theory and techniques; cleaning equipment operation and maintenance; dental materials; radiology; patient education and counseling; office management; supervised clinical training; and professional standards.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0603',
    cipTitle: 'Dental Laboratory Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of dentists, to design and construct dental prostheses such as caps, crowns, bridges, dentures, splints, and orthodontic appliances.  Includes instruction in dental anatomy, dental materials, ceramics technology, impressions, complete dentures, partial dentures, orthodontics, crowns and bridges, sculpture, bonding and assembly techniques, and equipment operation.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0699',
    cipTitle: 'Dental Services and Allied Professions, Other',
    cipDefinition: 'Any instructional program in dental services and allied professions not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.07',
    cipTitle: 'Health and Medical Administrative Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.0701 - 51.0799.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0701',
    cipTitle: 'Health/Health Care Administration/Management',
    cipDefinition:
      'A program that prepares individuals to develop, plan, and manage health care operations and services within health care facilities and across health care systems.  Includes instruction in planning, business management, financial management, public relations, human resources management, health care systems operation and management, health care resource allocation and policy making, health law and regulations, and applications to specific types of health care services.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0702',
    cipTitle: 'Hospital and Health Care Facilities Administration/Management',
    cipDefinition:
      'A program that prepares individuals to apply managerial principles to the administration of hospitals, clinics, nursing homes, and other health care facilities.  Includes instruction in facilities planning, building and operations management, business management, financial management and insurance, fund-raising and marketing, public relations, human resources management and labor relations, health care facilities operations, principles of health care delivery, and applicable law and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0703',
    cipTitle: 'Health Unit Coordinator/Ward Clerk',
    cipDefinition:
      'A program that prepares individuals, under the supervision of nurses or ward supervisors, to perform routine administrative and reception duties in a patient care unit within a hospital or other health care facility.  Includes instruction in receiving and directing visitors, transcribing medical and nursing orders, preparing requisition forms, scheduling patient appointments and procedures, monitoring patients and personnel, and interpersonal skills.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0704',
    cipTitle: 'Health Unit Manager/Ward Supervisor',
    cipDefinition:
      'A program that prepares individuals, under the supervision of nursing or medical service administrators, to supervise and coordinate the operations of one or more patient care units in hospitals or other health care facilities.  Includes instruction in planning and initiating administrative procedures, personnel supervision, interpersonal skills, general and/or specialized health care operations and procedures, and consultation with other medical, nursing, and administrative team members.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0705',
    cipTitle: 'Medical Office Management/Administration',
    cipDefinition:
      'A program that prepares individuals to manage the specialized business functions of a medical or clinical office.  Includes instruction in business office operations, business and financial record-keeping, personnel supervision, medical and health care policy administration, conference planning, scheduling and coordination, public relations, and applicable law and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0706',
    cipTitle: 'Health Information/Medical Records Administration/Administrator',
    cipDefinition:
      'A program that prepares individuals to plan, design, and manage systems, processes, and facilities used to collect, store, secure, retrieve, analyze, and transmit medical records and other health information used by clinical professionals and health care organizations.  Includes instruction in the principles and basic content of the biomedical and clinical sciences, information technology and applications, data and database management, clinical research methodologies, health information resources and systems, office management, legal requirements, and professional standards.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0707',
    cipTitle: 'Health Information/Medical Records Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of health information administrators and other professionals, to construct medical records and clinical databases, perform manipulations on retrieved data, control the security and quality of records, and supervise data entry and technical maintenance personnel.  Includes instruction in clinical and biomedical science data and information requirements, database management, data coding and validation, information security, quality control, health information content and structure, medical business procedures, and legal requirements.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0708',
    cipTitle: 'Medical Transcription/Transcriptionist',
    cipDefinition:
      'A program that prepares individuals to execute verbatim medical conference minutes, medical reports, and medical orders.  Includes instruction in dictation and simultaneous recording, analysis of notes and visual evidence, medical terminology, data processing applications and skills, formal medical report and correspondence formats, professional standards, and applicable law and regulations.',
    CrossReferences:
      '22.0303 - Court Reporting and Captioning/Court Reporter., 10.0204 - Voice Writing Technology/Technician.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0709',
    cipTitle: 'Medical Office Computer Specialist/Assistant',
    cipDefinition:
      'A program that prepares individuals to apply computer programming, troubleshooting, and information technology skills to the installation, maintenance, and upgrade of standard, customized, or proprietary medical software and associated hardware.  Includes instruction in computer programming, systems analysis, networking, telecommunications, medical records and informatics software applications, information technology, systems administration, computer security, and applicable regulations and certifications or licenses.',
  },
  {
    cipFamily: '51',
    cipCode: '51.071',
    cipTitle: 'Medical Office Assistant/Specialist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of office managers and other professionals, to perform routine administrative duties in a medical, clinical, or health care facility/system office environment.  Includes instruction in general office skills, data processing, office equipment operation, principles of medical record-keeping and business regulations, medical/clinical office procedures, and communications skills.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0711',
    cipTitle: 'Medical/Health Management and Clinical Assistant/Specialist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, nurses, or other health care professionals, to perform medical office or facilities management services and perform one or more clinical allied health specialties.  Includes instruction in health care management; medical office administration; medical law and regulations; training in one or more diagnostic, treatment, therapy, or laboratory specialties at the assistant level or above; and applicable professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0712',
    cipTitle: 'Medical Reception/Receptionist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of office managers, nurses, or physicians, to provide customer service, visitor reception, and patient intake and discharge services.  Includes instruction in medical office and health care facility procedures, medical terminology, interpersonal skills, record-keeping, customer service, telephone skills, data entry, interpersonal communications skills, and applicable policies and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0713',
    cipTitle: 'Medical Insurance Coding Specialist/Coder',
    cipDefinition:
      'A program that prepares individuals to perform specialized data entry, classification, and record-keeping procedures related to medical diagnostic, treatment, billing, and insurance documentation.  Includes instruction in medical records and insurance software applications, basic anatomy and physiology, medical terminology, fundamentals of medical science and treatment procedures, data classification and coding, data entry skills, and regulations relating to Medicare and insurance documentation.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0714',
    cipTitle: 'Medical Insurance Specialist/Medical Biller',
    cipDefinition:
      'A program that prepares individuals to perform and manage the medical and health insurance operations in a medical office, health care facility, health maintenance organization, or insurance provider.  Includes instruction in health and medical insurance processes; health insurance law, policy, and regulations; insurance records and paperwork administration; insurance office administration; health/medical insurance software applications; personnel supervision; business mathematics; billing and collection procedures; medical terminology; and communications skills.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0715',
    cipTitle: 'Health/Medical Claims Examiner',
    cipDefinition:
      'A program that prepares individuals to perform specialized investigatory, analytical, evaluative, and consultative functions related to health/medical insurance operations and their regulation.  Includes instruction in health and medical insurance processes and procedures, health insurance law and regulations, health care industry law, record-keeping, analytic and investigatory techniques, evaluation methods, field operations methods, office procedures, related software applications, oral and written communications skills, interviewing techniques, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0716',
    cipTitle: 'Medical Administrative/Executive Assistant and Medical Secretary',
    cipDefinition:
      'A program that prepares individuals to perform the duties of special assistants and personal secretaries for practicing physicians and nurses, health care facilities and services administrators, and other health care professionals.  Includes instruction in business and medical communications, medical terminology, principles of health care operations, public relations and interpersonal communications, software applications, record-keeping and filing systems, scheduling and meeting planning, applicable policy and regulations, and professional standards and ethics.',
    Examples: 'Examples: - Dental Secretary',
  },
  {
    cipFamily: '51',
    cipCode: '51.0717',
    cipTitle: 'Medical Staff Services Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to perform credentialing, privileging, personnel management, and accreditation compliance services for hospitals and other health care facilities and organizations.  Includes instruction in medical staff organization and management, medical terminology, credentialing and re-credentialing, health care accreditation and regulatory standards, health care law, meeting and negotiation management, and office information systems management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0718',
    cipTitle: 'Long Term Care Administration/Management',
    cipDefinition:
      'A program that prepares individuals to apply managerial principles to the administration of nursing homes, assisted living facilities, adult day care, home health services, and other long term care settings and agencies serving the elderly and disabled. Includes instruction in social and clinical aspects of aging, health care delivery systems, nursing home administration, assisted living administration, aging policy and government programs, ethics, business management, financial management, human resource management, marketing, and applicable laws and regulations.',
    Examples: 'Examples: - Aging Services Management',
  },
  {
    cipFamily: '51',
    cipCode: '51.0719',
    cipTitle: 'Clinical Research Coordinator',
    cipDefinition:
      'A program that prepares individuals for careers as clinical research administrators or clinical research coordinators where they work under the supervision of a Principal Investigator to organize, coordinate and administer clinical research trials. Includes instruction in pharmacology, medical terminology, foundations of clinical research, project management, ethics, research design and data management, and legal and regulatory compliance .',
    CrossReferences: '51.1005 - Clinical Laboratory Science/Medical Technology/Technologist.',
    Examples:
      'Examples: - Clinical Research Trial Coordination, - Clinical Trials Research, - Clinical Trials Management',
  },
  {
    cipFamily: '51',
    cipCode: '51.072',
    cipTitle: 'Regulatory Science/Affairs',
    cipDefinition:
      'A program that prepares individuals to manage the regulatory process for companies innovating and developing cutting edge products in science, biotechnology, pharmaceuticals, and medicine. Includes instruction in regulatory affairs, clinical documentation, human and animal subject protection, Food and Drug Administration (FDA) regulations, data management and analysis, good manufacturing practices, and quality management.',
    CrossReferences: '52.0214 - Research Administration.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0721',
    cipTitle: 'Disease Registry Data Management',
    cipDefinition:
      'A program that prepares individuals to find, interpret, and record demographic and medical information on individuals for healthcare agencies and disease registries. Includes instruction in anatomy, data management, disease registry organization and operations, epidemiology, ethics, fundamentals of disease coding and staging, medical terminology, pathophysiology, pharmacology, physiology, quality management, and statistics.',
    Examples: 'Examples: - Cancer Data Management',
  },
  {
    cipFamily: '51',
    cipCode: '51.0722',
    cipTitle: 'Healthcare Innovation',
    cipDefinition:
      'A program that prepares individuals to innovate solutions and lead change within a healthcare organization. Includes instruction in innovation management, medical device development, evidence-based practice, systems thinking, information technology, healthcare policy and finance, organizational change, and leadership.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0723',
    cipTitle: 'Healthcare Information Privacy Assurance and Security',
    cipDefinition:
      'A program that prepares individuals for careers in designing, implementing, and administering comprehensive privacy and security protection programs in all types of healthcare organizations. Includes instruction in health information ethics, healthcare information privacy and security, healthcare compliance, healthcare information security and disaster recovery, and healthcare privacy law.',
    Examples: 'Examples: - Information Governance in Healthcare',
  },
  {
    cipFamily: '51',
    cipCode: '51.0799',
    cipTitle: 'Health and Medical Administrative Services, Other',
    cipDefinition: 'Any instructional program in health and medical administrative services not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.08',
    cipTitle: 'Allied Health and Medical Assisting Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.0801 - 51.0899.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0801',
    cipTitle: 'Medical/Clinical Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to provide medical office administrative services and perform clinical duties including patient intake and care, routine diagnostic and recording procedures, pre-examination and examination assistance, and the administration of medications and first aid.  Includes instruction in basic anatomy and physiology; medical terminology; medical law and ethics; patient psychology and communications; medical office procedures; and clinical diagnostic, examination, testing, and treatment procedures.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0802',
    cipTitle: 'Clinical/Medical Laboratory Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians or laboratory scientists or technologists, to perform waived testing procedures (Clinical Laboratory Improvement Amendments of 1988), phlebotomy, and other duties in support of laboratory teams.  Includes instruction in clinical procedures, various laboratory tests, laboratory mathematics, computer technology, equipment operation and maintenance, sterilization and safety, communications skills, and interpersonal skills.',
    CrossReferences: '51.1004 - Clinical/Medical Laboratory Technician.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0803',
    cipTitle: 'Occupational Therapist Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of occupational therapists, to direct patient participation in skill-enhancing, learning, and motivational tasks; correct or diminish pathologies; and to provide direct health education and promotion services.  Includes instruction in human life span development, occupational therapy principles and practice skills, treatment planning and implementation, record-keeping and documentation, patient education and intervention, activity and program direction, and assistive services management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0805',
    cipTitle: 'Pharmacy Technician/Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of pharmacists, to prepare medications, provide medications and related assistance to patients, and manage pharmacy clinical and business operations.  Includes instruction in medical and pharmaceutical terminology, principles of pharmacology and pharmaceutics, drug identification, pharmacy laboratory procedures, prescription interpretation, patient communication and education, safety procedures, record-keeping, measurement and testing techniques, pharmacy business operations, prescription preparation, logistics and dispensing operations, and applicable standards and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0806',
    cipTitle: 'Physical Therapy Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of a physical therapist, to implement physical therapy treatment care plans, train patients, conduct treatment interventions, use equipment, and observe and record patient progress. Includes instruction in applied anatomy and physiology, behavioral sciences, biomechanics, clinical documentation, clinical pathology, communication, exercise physiology, kinesiology, neuroscience, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0808',
    cipTitle: 'Veterinary/Animal Health Technology/Technician and Veterinary Assistant',
    cipDefinition: 'Moved from 51.0808 to 1.8301',
  },
  {
    cipFamily: '51',
    cipCode: '51.0809',
    cipTitle: 'Anesthesiologist Assistant',
    cipDefinition:
      'A program that prepares individuals to assist anesthesiologists and nurse anesthetists in developing and implementing patient anesthesia care plans, including preoperative, surgical theater, recovery room, and postoperative/intensive care procedures.  Includes instruction in patient data collection, catheter insertion, airway management and drug administration, assisting the administration and monitoring of regional and peripheral nerve blockades, support therapy, adjusting anesthetic levels during surgery, interoperative monitoring, postoperative procedures, pain clinics and patient education, and administrative tasks.',
  },
  {
    cipFamily: '51',
    cipCode: '51.081',
    cipTitle: 'Emergency Care Attendant (EMT Ambulance)',
    cipDefinition:
      'A program that prepares individuals to assist licensed EMTs, under the supervision of a physician, to prepare and transport ill or injured patients, and to operate emergency vehicles and equipment such as life support units.  Includes instruction in first aid and emergency medicine field techniques, patient stabilization and care, medical field communications, equipment operation and maintenance, emergency vehicle operation, and applicable standards and regulations.',
    CrossReferences: '51.0904 - Emergency Medical Technology/Technician (EMT Paramedic).',
  },
  {
    cipFamily: '51',
    cipCode: '51.0811',
    cipTitle: 'Pathology/Pathologist Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of pathologists, to assist in the performance of autopsies, obtaining and preparing surgical specimens, preparing autopsy reports, and interacting with other clinicians, officials, and individuals.  Includes instruction in anatomy; physiology; medical terminology; general, systemic, forensic, and pediatric pathology; autopsy procedures; surgical technique; medical photography; tissue preparation; record keeping and laboratory administration; interpersonal skills; and applicable standards and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0812',
    cipTitle: 'Respiratory Therapy Technician/Assistant',
    cipDefinition:
      'A program that prepares individuals to administer general respiratory care procedures under the supervision of respiratory therapists in a variety of clinical settings.  Includes instruction in patient data collection and monitoring, airway management, installation of nebulizers and other respiratory assistance devices, application and monitoring of breathing gases, equipment operation and maintenance, safety and sanitation procedures, and applicable regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0813',
    cipTitle: 'Chiropractic Technician/Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of a chiropractor, to provide patient management, examination, and treatment services.  Includes instruction in the principles and procedures of chiropractic, radiographic X-Ray procedures and techniques, X-Ray film analysis and media studies, orthopedic and neurological testing, clinical laboratory procedures, patient management and education, office administrative skills, and applicable law and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0814',
    cipTitle: 'Radiologist Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of a physician, to perform patient assessment, patient management, selected imaging procedures and to make initial observations of diagnostic images. Includes instruction in radiologic procedures and techniques, cross sectional anatomy, pharmacology, pathophysiology, patient assessment, patient care and management, and radiation biology.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0815',
    cipTitle: 'Lactation Consultant',
    cipDefinition:
      'A program that prepares individuals to provide lactation management services and breastfeeding support to women, and to work as a Lactation Consultant in a variety of clinical settings. Includes instruction in anatomy and physiology of lactation, nutrition, breastfeeding techniques and technologies, common concerns and solutions, management of special situations, breastfeeding instruction, professional and legal concerns, and advocacy.',
    Examples: 'Examples: - Lactation Education, - Lactation Counselor, - Lactation Management',
  },
  {
    cipFamily: '51',
    cipCode: '51.0816',
    cipTitle: 'Speech-Language Pathology Assistant',
    cipDefinition:
      'A program that prepares individuals, under the supervision of speech-language pathologists, to assist in the provision of speech-language pathology services to individuals of all ages, including screenings and assessments, following treatment plans or protocols, documenting and reporting patient performance, performing checks and maintenance of equipment, and providing support in research projects.  Includes instruction in speech and language development, phonetics, childhood and adult communication disorders, speech and hearing screening, and clinical procedures in speech-language pathology.',
    CrossReferences:
      '13.1012 - Education/Teaching of Individuals with Speech or Language Impairments., 51.0203 - Speech-Language Pathology/Pathologist., 51.0204 - Audiology/Audiologist and Speech-Language Pathology/Pathologist.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0817',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0899',
    cipTitle: 'Allied Health and Medical Assisting Services, Other',
    cipDefinition: 'Any instructional program in allied health and medical assisting services not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.09',
    cipTitle: 'Allied Health Diagnostic, Intervention, and Treatment Professions',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.0901 - 51.0999.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0901',
    cipTitle: 'Cardiovascular Technology/Technologist',
    cipDefinition:
      'A program that prepares individuals to perform invasive, noninvasive, and peripheral examinations of the cardiovascular system at the request of physicians to aid in diagnoses and therapeutic treatments. Includes instruction in reviewing and recording patient histories and clinical data, patient care, investigative and examination procedures, diagnostic procedures, data analysis and documentation, physician consultation, equipment operation and monitoring, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0902',
    cipTitle: 'Electrocardiograph Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians and nurses, to administer EKG and ECG diagnostic examinations and report results to the treatment team.  Includes instruction in basic anatomy and physiology, the cardiovascular system, medical terminology, cardiovascular medications and effects, patient care, EKG and ECG administration, equipment operation and maintenance, interpretation of cardiac rhythm, patient record management, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0903',
    cipTitle: 'Electroneurodiagnostic/Electroencephalographic Technology/Technologist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of a physician, to study and record electrical activity in the brain and nervous system for purposes of patient monitoring and supporting diagnoses. Includes instruction in patient communication and care; taking and abstracting patient histories; application of recording electrodes; EEG, EP, and PSG equipment operation and procedural techniques; and data recording and documentation.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0904',
    cipTitle: 'Emergency Medical Technology/Technician (EMT Paramedic)',
    cipDefinition:
      'A program  that prepares individuals, under the remote supervision of physicians, to recognize, assess, and manage medical emergencies in prehospital settings and to supervise Ambulance personnel.  Includes instruction in basic, intermediate, and advanced EMT procedures; emergency surgical procedures; medical triage; rescue operations; crisis scene management and personnel supervision; equipment operation and maintenance; patient stabilization, monitoring, and care; drug administration; identification and preliminary diagnosis of diseases and injuries; communication and computer operations; basic anatomy, physiology, pathology, and toxicology; and professional standards and regulations.',
    CrossReferences: '51.0810 - Emergency Care Attendant (EMT Ambulance).',
  },
  {
    cipFamily: '51',
    cipCode: '51.0905',
    cipTitle: 'Nuclear Medical Technology/Technologist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to employ radioactive and stable nuclides in diagnostic evaluations and therapeutic applications while monitoring for patient health and safety.  Includes instruction in nuclear physics, health physics, instrumentation and statistics, biochemistry, immunology, radiopharmacology, radiation biology, clinical nuclear medicine, radionuclide therapy, computer applications, safety regulations, equipment operation, quality control, laboratory procedures, taking patient histories, patient evaluation and monitoring, emergency first aid, administration and record-keeping, and personnel supervision.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0906',
    cipTitle: 'Perfusion Technology/Perfusionist',
    cipDefinition:
      "A program that prepares individuals, under the supervision of physicians and nurses, to operate extracorporeal circulation and autotransfusion equipment during medical procedures and surgeries where the support or temporary replacement of a patient's own respiratory or circulatory system is required.  Includes instruction in equipment selection and operation, physician and medical team consultation, patient condition monitoring, procedural techniques, and principles of respiratory and circulatory physiology.",
  },
  {
    cipFamily: '51',
    cipCode: '51.0907',
    cipTitle: 'Medical Radiologic Technology/Science - Radiation Therapist',
    cipDefinition:
      'A program that prepares individuals to administer prescribed courses of radiation treatment, manage patients undergoing radiation therapy, and maintain pertinent records.  Includes instruction in applied anatomy and physiology, oncologic pathology, radiation biology, radiation oncology procedures and techniques, radiation dosimetry, tumor localization, treatment planning, patient communication and management, data collection, record-keeping, and applicable standards and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0908',
    cipTitle: 'Respiratory Care Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to assist in developing respiratory care plans, administer respiratory care procedures, supervise personnel and equipment operation, maintain records, and consult with other health care team members.  Includes instruction in the applied basic biomedical sciences; anatomy, physiology, and pathology of the respiratory system; clinical medicine; therapeutic procedures; clinical expressions; data collection and record-keeping; patient communication; equipment operation and maintenance; personnel supervision; and procedures for special population groups.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0909',
    cipTitle: 'Surgical Technology/Technologist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians and surgical nurses, to maintain, monitor, and enforce the sterile field and adherence to aseptic technique by preoperative, surgical team, and postoperative personnel.  Includes instruction in instrument and equipment sterilization and handling, surgical supplies management, wound exposure and closure, surgical computer and robot operation and monitoring, maintenance of hemostasis, and patient and team scrubbing.',
    CrossReferences: '51.1012 - Sterile Processing Technology/Technician.',
  },
  {
    cipFamily: '51',
    cipCode: '51.091',
    cipTitle: 'Diagnostic Medical Sonography/Sonographer and Ultrasound Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to utilize medical ultrasound techniques to gather sonographic data used to diagnose a variety of conditions and diseases.  Includes instruction in obtaining, reviewing, and integrating patient histories and data; patient instruction and care; anatomic, physiologic and pathologic data recording; sonographic data processing; sonography equipment operation; and professional standards and ethics.',
    Examples: 'Examples: - Vascular Sonography',
  },
  {
    cipFamily: '51',
    cipCode: '51.0911',
    cipTitle: 'Radiologic Technology/Science - Radiographer',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to provide medical imaging services to patients and attending health care professionals.  Includes instruction in applied anatomy and physiology, patient positioning, radiographic technique, radiation biology, safety and emergency procedures, equipment operation and maintenance, quality assurance, patient education, and medical imaging/radiologic services management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0912',
    cipTitle: 'Physician Associate/Assistant',
    cipDefinition:
      'A program that prepares individuals to practice medicine, including diagnoses and treatment therapies, under the supervision of a physician.  Includes instruction in the basic medical and clinical sciences and specialized preparation in fields such as family medicine, pediatrics, obstetrics, gynecology, general surgery, psychiatry, and behavioral medicine; the delivery of health care services to homebound patients, rural populations, and underserved populations; and community health services.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0913',
    cipTitle: 'Athletic Training/Trainer',
    cipDefinition:
      'A program that prepares individuals to work in consultation with, and under the supervision of physicians to prevent and treat sports injuries and associated conditions.  Includes instruction in the identification, evaluation, and treatment of athletic injuries and illnesses; first aid and emergency care; therapeutic exercise; anatomy and physiology; exercise physiology; kinesiology and biomechanics; nutrition; sports psychology; personal and community health; knowledge of various sports and their biomechanical and physiological demands; and applicable professional standards and regulations.',
    CrossReferences:
      '26.0908 - Exercise Physiology and Kinesiology., 31.0505 - Exercise Science and Kinesiology., 31.0507 - Physical Fitness Technician.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0914',
    cipTitle: 'Gene/Genetic Therapy',
    cipDefinition:
      'A program that focuses on the application of genetics to the therapeutic treatment of diseases and inherited abnormalities through the targeted modification of gene expression, and prepares individuals to administer genetic therapies.  Includes instruction in medical genetics, genome analysis, pharmacology of gene transfer, viral vectors, nonviral therapeutic techniques, genetic screening, targeted gene delivery, vector production, safety and quality assurance, clinical trials management, and bioethics.',
    CrossReferences: '51.1509 - Genetic Counseling/Counselor., 26.0806 - Human/Medical Genetics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0915',
    cipTitle: 'Cardiopulmonary Technology/Technologist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians and nurses, to perform both cardiovascular and pulmonary diagnostic and therapeutic procedures.  Includes instruction in cardiopulmonary pharmacology; anatomy and physiology of the heart, peripheral vascular system, and lungs; pulmonary and cardiovascular disease and dysfunction; peripheral vascular Doppler procedures; invasive and non-invasive cardiology; pulmonary function tests; blood gas analysis; echocardiograph procedures; bronchoscopy; patient management; patient history taking and record-keeping; applications to clinical research; and emergency procedures.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0916',
    cipTitle: 'Radiation Protection/Health Physics Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of health physicists, occupational safety and health specialists, and public health officials, to monitor and control radiation exposure and implement preventive measures in health care, work, and natural environments.  Includes instruction in radiation physics, environmental radioactivity, radiological instrumentation, electronics of radiation detection equipment, radioactive waste management and control, safety and handling procedures, decontamination procedures, radioactivity testing and analysis, and communications skills.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0917',
    cipTitle: 'Polysomnography',
    cipDefinition:
      'A program that prepares individuals to work under the supervision of a physician to provide comprehensive evaluation and treatment of sleep disorders. Includes instruction in human anatomy and physiology, polysomnographic technology, polysomnography scoring, neuroanatomy and neurophysiology of sleep, sleep disorders, and medical terminology.',
    Examples: 'Examples: - Polysomnography, - Polysomnography/Sleep Technology',
  },
  {
    cipFamily: '51',
    cipCode: '51.0918',
    cipTitle: 'Hearing Instrument Specialist',
    cipDefinition:
      'A program that prepares individuals to test the hearing of, and to select, fit and dispense appropriate hearing aids for, patients. Includes instruction in acoustics, anatomy and physiology of the ear, hearing testing, hearing aid fitting and technology, health promotion and rehabilitation, counseling and communication, business management, and professional ethics.',
    Examples: 'Examples: - Hearing Aid Technician',
  },
  {
    cipFamily: '51',
    cipCode: '51.0919',
    cipTitle: 'Mammography Technology/Technician',
    cipDefinition:
      'A program that prepares registered radiographers to become registered mammographers. Includes instruction in anatomy and physiology, mammography instrumentation, mammography positioning, principles and procedures of mammography, and quality assurance.',
    Examples: 'Examples: - Mammography, - Breast Imaging and Mammography',
  },
  {
    cipFamily: '51',
    cipCode: '51.092',
    cipTitle: 'Magnetic Resonance Imaging (MRI) Technology/Technician',
    cipDefinition:
      'A program that prepares individuals who are AART-certified radiological technicians to utilize MRI technology to obtain still and moving images of various vascular structures in the human body that aid the physician in the diagnosis or treatment of disease and injury. Includes instruction in MRI imaging, sectional anatomy and pathology, MRI technology, MRI techniques and procedures, MRI physics, and clinical training.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0921',
    cipTitle: 'Hyperbaric Medicine Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to operate hyperbaric (recompression) chambers for the treatment of disease. Includes instruction in patient assessment and management, hyperbaric safety, physics of hyperbaric and hypobaric exposures, physiology, hyperbaric chamber operation, and air decompression procedures.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0922',
    cipTitle: 'Intraoperative Neuromonitoring Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to become neuromonitoring technicians who analyze, monitor, and record nervous system function to promote the effective treatment of pathological conditions. Includes instruction in EEG technology, EMG technology, anatomy, and neuroanatomy.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0923',
    cipTitle: 'Orthopedic Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians, to cast, splint, and brace orthopedic injuries. Includes instruction in patient assessment; casting, splinting, and orthopedic appliances; radiographic interpretation; traction; and wound care.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0924',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '51',
    cipCode: '51.0999',
    cipTitle: 'Allied Health Diagnostic, Intervention, and Treatment Professions, Other',
    cipDefinition:
      'Any instructional program in allied health diagnostic, intervention, and treatment professions not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.10',
    cipTitle: 'Clinical/Medical Laboratory Science/Research and Allied Professions',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.1001 - 51.1099.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1001',
    cipTitle: 'Blood Bank Technology Specialist',
    cipDefinition:
      'A program that prepares individuals to perform routine and specialized blood testing procedures, collect and process blood donations, and support physicians and other clinicians administering transfusion therapy.  Includes instruction in basic cellular biology, immunohematology, blood bank procedures, blood typing, antigen and antibody identification tests, hemolytic and transfusion responses and diseases, donor selection, blood drawing and storage, blood separation, viral marker testing, laboratory and personnel supervision, safety and sterilization, and applicable laws and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1002',
    cipTitle: 'Cytotechnology/Cytotechnologist',
    cipDefinition:
      'A program that prepares individuals to work with pathologists to detect changes in body cells that may indicate, and permit diagnosis of, the early development of cancers and other diseases.  Includes instruction in biochemistry, microbiology, applied mathematics, microscopy, slide preparation, identification of cellular structures and abnormalities, and laboratory procedures and safety.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1003',
    cipTitle: 'Hematology Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of clinical laboratory scientists and health care professionals, to perform tests and analyses of blood samples.  Includes instruction in clinical laboratory procedures; blood anatomy and physiology; cellular and molecular biology; blood pathology and disease indicators; quantitative, qualitative, and coagulation testing methodologies; equipment operation and maintenance; and record-keeping.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1004',
    cipTitle: 'Clinical/Medical Laboratory Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of clinical laboratory scientists/medical technologists, to perform routine medical laboratory procedures and tests and to apply preset strategies to record and analyze data.  Includes instruction in general laboratory procedures and skills; laboratory mathematics; medical computer applications; interpersonal and communications skills; and the basic principles of hematology, medical microbiology, immunohematology, immunology, clinical chemistry, and urinalysis.',
    CrossReferences:
      '41.0101 - Biology/Biotechnology Technology/Technician., 51.0802 - Clinical/Medical Laboratory Assistant.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1005',
    cipTitle: 'Clinical Laboratory Science/Medical Technology/Technologist',
    cipDefinition:
      'A program that prepares individuals to conduct and supervise complex medical tests, clinical trials, and research experiments; manage clinical laboratories; and consult with physicians and clinical researchers on diagnoses, disease causation and spread, and research outcomes. Includes instruction in the theory and practice of hematology, clinical chemistry, microbiology, immunology, immunohematology, physiological relationships to test results, laboratory procedures and quality assurance controls, test and research design and implementation, analytic techniques, laboratory management, data development and reporting, medical informatics, and professional standards and regulations.',
    CrossReferences: '51.0719 - Clinical Research Coordinator.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1006',
    cipTitle: 'Ophthalmic Laboratory Technology/Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of ophthalmologists and optometrists, to cut, grind, edge, and finish corrective lenses and to fabricate eyewear.  Includes instruction in optical theory, applied mathematics, lens surfacing and finishing, tinting and coating, impact resistance treatment and testing, frame construction and repair, prescription interpretation, equipment operation and maintenance, follow-up adjustment, record-keeping, and laboratory safety procedures.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1007',
    cipTitle: 'Histologic Technology/Histotechnologist',
    cipDefinition:
      'A program that prepares individuals, at the request of physicians, researchers, and laboratory scientists, to identify tissue structures and cell components and relate these findings to physiological functions and to the detection and diagnosis of diseases and other abnormalities.  Includes instruction in medical chemistry, histochemistry, biochemistry, anatomy, physiology, medical informatics, applied mathematics, electron microscopy, instrumentation, quality control procedures, data recording and analysis, laboratory administration, medical terminology, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1008',
    cipTitle: 'Histologic Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of histotechnologists and other clinical laboratory scientists, to prepare and process tissue samples and perform routine histologic procedures and tests.  Includes instruction in fixation, dehydration, embedding, sectioning, decalcification, microincineration, mounting, routine and special steaming, laboratory mathematics, laboratory procedures and safety, instrumentation and microscopy, computer applications, preparation of museum specimens, and recording and administrative procedures.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1009',
    cipTitle: 'Phlebotomy Technician/Phlebotomist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of physicians and other health care professionals, to draw blood samples from patients using a variety of intrusive procedures.  Includes instruction in basic vascular anatomy and physiology, blood physiology, skin puncture techniques, venipuncture, venous specimen collection and handling, safety and sanitation procedures, and applicable standards and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.101',
    cipTitle: 'Cytogenetics/Genetics/Clinical Genetics Technology/Technologist',
    cipDefinition:
      'A program that prepares individuals to perform analyses of the human genome and chromosomes for the research and diagnosis of genetic diseases and defects, in preparation for organ transplants, and in support of treatments for cancers and leukemias.  Includes instruction in chromosome analysis, fluorescent in situ hybridization (FISH), molecular genetic techniques, cytotechnology, and photomicrography.',
    CrossReferences: '26.0806 - Human/Medical Genetics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1011',
    cipTitle: 'Renal/Dialysis Technologist/Technician',
    cipDefinition:
      'A program that prepares individuals to administer hemodialysis treatments to patients with renal failure under the supervision of a nurse or physician.  Includes instruction in basic anatomy and physiology, dialysis prescription interpretation, dialysite preparation, extracorporeal circuit and dialyzer set-up and maintenance, equipment monitoring, patient preparation, taking vital signs, documentation and communication, venipuncture and local anesthesia administration, emergency intervention, safety and sanitation, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1012',
    cipTitle: 'Sterile Processing Technology/Technician',
    cipDefinition:
      'A program that prepares individuals to clean, sterilize, and assemble surgical instruments, equipment, and supplies for use in operating rooms and other medical and surgical facilities. Includes instruction in sterilization; infection control; decontamination; and surgical instrumentation processing, distribution, and record-keeping.',
    CrossReferences: '51.0909 - Surgical Technology/Technologist.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1099',
    cipTitle: 'Clinical/Medical Laboratory Science and Allied Professions, Other',
    cipDefinition:
      'Any instructional program in clinical/medical laboratory science and allied professions not listed above.',
    Examples: 'Examples: - Pharmaceutical Technician',
  },
  {
    cipFamily: '51',
    cipCode: '51.11',
    cipTitle: 'Health/Medical Preparatory Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.1101 - 51.1199.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1101',
    cipTitle: 'Pre-Dentistry Studies',
    cipDefinition: 'A program that prepares individuals for admission to a professional program in dentistry.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1102',
    cipTitle: 'Pre-Medicine/Pre-Medical Studies',
    cipDefinition:
      'A program that prepares individuals for admission to a professional program in medicine, osteopathic medicine, or podiatric medicine.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1103',
    cipTitle: 'Pre-Pharmacy Studies',
    cipDefinition: 'A program that prepares individuals for admission to a professional program in pharmacy.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1104',
    cipTitle: 'Pre-Veterinary Studies',
    cipDefinition: 'Moved from 51.1104 to 1.1302',
  },
  {
    cipFamily: '51',
    cipCode: '51.1105',
    cipTitle: 'Pre-Nursing Studies',
    cipDefinition: 'A program that prepares individuals for admission to a professional program in Nursing.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1106',
    cipTitle: 'Pre-Chiropractic Studies',
    cipDefinition:
      'A program that prepares individuals for admission to a professional program in chiropractic medicine.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1107',
    cipTitle: 'Pre-Occupational Therapy Studies',
    cipDefinition:
      'A program that prepares individuals for admission to a professional program in occupational therapy.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1108',
    cipTitle: 'Pre-Optometry Studies',
    cipDefinition: 'A program that prepares individuals for admission to a professional program in optometry.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1109',
    cipTitle: 'Pre-Physical Therapy Studies',
    cipDefinition: 'A program that prepares individuals for admission to a professional program in physical therapy.',
  },
  {
    cipFamily: '51',
    cipCode: '51.111',
    cipTitle: 'Pre-Art Therapy',
    cipDefinition: 'A program that prepares individuals for admission to a professional art therapy program.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1111',
    cipTitle: 'Pre-Physician Assistant',
    cipDefinition: 'A program that prepares individuals for admission to a professional physician assistant program.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1199',
    cipTitle: 'Health/Medical Preparatory Programs, Other',
    cipDefinition: 'Any instructional program in health and medical professional preparation not listed above.',
    CrossReferences: '1.1302 - Pre-Veterinary Studies.',
  },
  {
    cipFamily: '51',
    cipCode: '51.12',
    cipTitle: 'Medicine',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.1201 - 51.1299.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1201',
    cipTitle: 'Medicine',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of medicine, involving the prevention, diagnosis, and treatment of illnesses, injuries, and other disorders of the human body. Includes instruction in the basic medical sciences, clinical medicine, examination and diagnosis, patient communications, medical ethics and law, professional standards, and rotations in specialties such as internal medicine, surgery, pediatrics, obstetrics and gynecology, orthopedics, neurology, ophthalmology, radiology, clinical pathology, anesthesiology, family medicine, and psychiatry.',
    Examples: 'Examples: - Medicine (MD), - Allopathic Medicine',
  },
  {
    cipFamily: '51',
    cipCode: '51.1202',
    cipTitle: 'Osteopathic Medicine/Osteopathy',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of osteopathic medicine, involving a combination of medical principles and procedures and osteopathic principles of holistic medicine and the relationship of the musculoskeletal system to general health.  Includes instruction in the basic medical sciences, preventive medicine, family and community medicine, medical informatics, disease systems, organ and physiologic systems, osteopathic principles and practice, osteopathic manipulative treatment, musculoskeletal and skin systems, clinical specialty rotations, medical ethics, and professional standards and practice management.',
    Examples: 'Examples: - Osteopathy, - Osteopathic Medicine (DO)',
  },
  {
    cipFamily: '51',
    cipCode: '51.1203',
    cipTitle: 'Podiatric Medicine/Podiatry',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of podiatric medicine, involving the prevention, diagnosis, and treatment of diseases, disorders, and injuries to the foot and lower extremities.  Includes instruction in the basic medical sciences, anatomy of the lower extremity, functional orthopedics, foot biomechanics, podiatric radiology, dermatology, podiatric surgery, podopediatrics, sports medicine, physical diagnosis, emergency medicine and traumatology, practice management, and professional standards and ethics.',
    Examples: 'Examples: - Podiatric Medicine/Podiatry (DPM)',
  },
  {
    cipFamily: '51',
    cipCode: '51.1299',
    cipTitle: 'Medicine, Other',
    cipDefinition: 'Any program in medicine not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.14',
    cipTitle: 'Medical Clinical Sciences/Graduate Medical Studies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.1401 - 51.1499.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1401',
    cipTitle: 'Medical Science/Scientist',
    cipDefinition:
      'An undifferentiated clinical science program that prepares clinicians to conduct clinical and translational research in various areas. Note: programs that prepare clinicians to conduct research in specific scientific fields should report under the relevant CIP code series (e.g., Series 26 Biological and Biomedical Sciences).',
    Examples: 'Examples: - Medical Scientist (MS), - Medical Scientist (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.1402',
    cipTitle: 'Clinical and Translational Science',
    cipDefinition:
      'A program of study that prepares clinicians to direct a broad range of scientific or clinical or translational studies, translate scientific knowledge into clinical practice, and bridge clinical science with laboratory investigations. Includes instruction in bioinformatics, Information technology, biostatistics, epidemiology, grant writing, management and analysis of clinical data, clinical and translational research law and policy, responsible conduct of research, and scientific writing.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1403',
    cipTitle: 'Pain Management',
    cipDefinition:
      'A post-residency program of study that prepares physicians in the diagnosis, management, and treatment of acute and chronic pain. Includes instruction in anesthesiology, pain assessment, pain classification and diagnosis, pain neuroscience, palliative and end-of-life care, physical medicine and rehabilitation, psychiatry of pain, pain pharmacology, public policy, and legal issues of pain management.',
    CrossReferences: '61.0210 - Pain Medicine Fellowship Program.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1404',
    cipTitle: 'Temporomandibular Disorders and Orofacial Pain',
    cipDefinition:
      'A program that prepares clinicians to evaluate, diagnose, and treat of a wide variety of temporomandibular disorders (TMD) and orofacial pain disorders as well as the treatment of sleep apnea and snoring with intraoral appliances. Includes instruction in head and neck anatomy, physical diagnosis and evaluation, orofacial pain, neurobiology of pain and analgesia, and diagnostic radiography.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1405',
    cipTitle: 'Tropical Medicine',
    cipDefinition:
      "A program that prepares clinicians to prevent and control vector-borne and tropical infectious diseases. Includes instruction in microbiology, parasitology, mycology, virology, vector biology, vaccinology, laboratory diagnosis, travelers' health, epidemiology, and disease management.",
    Examples:
      "Examples: - Diploma in Tropical Medicine, - Diploma in Tropical Medicine and Public Health, - Master of Tropical Medicine (MTM), - Clinical Tropical Medicine and Travelers' Health, - Graduate Certificate in Tropical Medicine, - Tropical Medicine and Global Health",
  },
  {
    cipFamily: '51',
    cipCode: '51.1499',
    cipTitle: 'Medical Clinical Sciences/Graduate Medical Studies, Other',
    cipDefinition: 'Any program in medical clinical sciences or graduate medical studies not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.15',
    cipTitle: 'Mental and Social Health Services and Allied Professions',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.1501 - 51.1599.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1501',
    cipTitle: 'Substance Abuse/Addiction Counseling',
    cipDefinition:
      'A program that prepares individuals to help prevent substance abuse, counsel individuals and families with drug and alcohol problems, and perform intervention and therapeutic services for persons suffering from addiction.  Includes instruction in individual and group counseling skills, psychology of addiction, sociology, crisis intervention, substance abuse identification methodologies, substance abuse treatment modalities, substance abuse prevention and treatment resources, pharmacology and behavioral aspects of abused substances, treatment evaluation, patient observation and education, group dynamics, professional standards and ethics, and applicable law and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1502',
    cipTitle: 'Psychiatric/Mental Health Services Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of psychiatrists, psychologists, nurses, and other mental health care professionals, to provide direct patient care services, assist in developing and implementing treatment plans, administer medications, and perform related administrative functions.  Includes instruction in mental health theory, applied psychopathology, patient communication and management, crisis intervention, psychotropic medication, mental health treatment procedures, substance abuse, record-keeping, clinical administrative skills, and applicable standards and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1503',
    cipTitle: 'Clinical/Medical Social Work',
    cipDefinition:
      'A program that prepares individuals for the specialized professional practice of social work, in collaboration with other health care professionals, in hospitals and other health care facilities and organizations.  Includes instruction in social work, psychiatric case work, clinical interviewing techniques, therapeutic intervention strategies, patient testing and evaluation, patient and family counseling, social rehabilitation, patient care planning, record-keeping, and support services liaison.',
    CrossReferences: '44.0701 - Social Work.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1504',
    cipTitle: 'Community Health Services/Liaison/Counseling',
    cipDefinition:
      'A program that prepares individuals to serve as facilitators, advocates, and referral professionals linking health care and related social services with affected recipient communities.  Includes instruction in public and community health, human and social services, health services administration, group counseling, health education, group advocacy, cross-cultural and multilingual communication, and applicable laws and policies.',
    Examples: 'Examples: - Community Health Education',
  },
  {
    cipFamily: '51',
    cipCode: '51.1505',
    cipTitle: 'Marriage and Family Therapy/Counseling',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of marriage and family therapy, involving the diagnosis of cognitive, affective, and behavioral domain disorders, both mental and emotional, within the context of marriage and family systems and the application of short- and long-term therapeutic strategies in family group contexts.  Includes instruction in psychotherapy, family systems and studies, small group intervention and therapy, marital problems, depression, identification of psychopathologies and behavioral disorders, holistic health care, practice management, applicable regulations, and professional standards and ethics.',
    CrossReferences: '42.2811 - Family Psychology., 19.0704 - Family Systems.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1506',
    cipTitle: 'Clinical Pastoral Counseling/Patient Counseling',
    cipDefinition:
      'A program that prepares individuals, including but not limited to, ordained clergy and other counseling professionals to respond to the emotional and/or spiritual needs of patients and families in health care emergencies and other situations, and to consult with and counsel other health care professionals. Includes instruction in advanced interpersonal communication; individual and small group counseling; interdisciplinary teamwork; crisis management; extended care relationships; self-analysis; medical ethics; pastoral care art; spiritual dimensions of human growth and health; counseling psychology and therapy; and applications to grief, death, emotional collapse, injury, individual/family adjustment, and chronic illness situations.',
    CrossReferences: '39.0701 - Pastoral Studies/Counseling.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1507',
    cipTitle: 'Psychoanalysis and Psychotherapy',
    cipDefinition:
      'A program that prepares individuals to practice the provision of psychoanalytic counseling to individuals and groups based on the psychodynamic principles evolved from authorities such as Freud, Adler, and Jung.  Includes instruction in self-analysis, personality theory, dream analysis, free association and transference theory and techniques, psychodynamic theory, developmental processes, applications to specific clinical conditions, practice management, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1508',
    cipTitle: 'Mental Health Counseling/Counselor',
    cipDefinition:
      'A program that prepares individuals to provide evaluations, referrals, and short-term counseling services to help people prevent or remediate personal problems, conflicts, and emotional crises.  Includes instruction in human development, psychopathology, individual and group counseling, personality theory, career assessment, patient screening and referral, observation and testing techniques, interviewing skills, professional standards and ethics, and applicable laws and regulations.',
    CrossReferences: '42.2803 - Counseling Psychology.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1509',
    cipTitle: 'Genetic Counseling/Counselor',
    cipDefinition:
      'A program that prepares individuals to counsel patients and families concerning inherited genetic disorders and diseases; assess risk factors and planning options associated with potential and actual inherited conditions; and serve as patient advocates and provide referral services in relation to private and public support services.  Includes instruction in clinical/medical genetics, methods of genetic testing, interviewing and counseling skills, genetic and support services delivery, principles of public health, medical ethics, law and regulations, patient advocacy, and professional standards.',
    CrossReferences: '51.0914 - Gene/Genetic Therapy., 26.0806 - Human/Medical Genetics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.151',
    cipTitle: 'Infant/Toddler Mental Health Services',
    cipDefinition:
      'A program that prepares individuals to provide mental health services to infants, toddlers, and preschoolers and their families. Includes instruction in developmental psychology, cognitive and biological development, social and emotional development, research methods, and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1511',
    cipTitle: 'Medical Family Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals to work with patients and their families who are experiencing emotional, physical, relational and spiritual difficulties related to acute and chronic illnesses. Includes instruction in cultural context, ethics, family dynamics, family therapy, gender, human development, marriage counseling, psychopathology, research design and methods, sexuality, and systems thinking.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1512',
    cipTitle: 'Hospice and Palliative Care',
    cipDefinition:
      'A program of study that prepares individuals to work with patients in hospice and palliative care and focuses on the physical, psychological, spiritual, and social needs of patients and families affected by advanced illness. Includes instruction in aging, thanatology, pharmacology, complementary and integrative therapies, pathophysiology, and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1513',
    cipTitle: 'Trauma Counseling',
    cipDefinition:
      'A program that focuses on understanding elements of traumatic exposure, trauma-sensitive care, and crisis intervention. Includes instruction in disaster response, grief and loss counseling, crisis intervention and management, trauma counseling, neurobiology of trauma, PTSD and combat-related trauma, relational trauma and treatment of trauma-related disorders.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1580',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1599',
    cipTitle: 'Mental and Social Health Services and Allied Professions, Other',
    cipDefinition:
      'Any instructional program in mental and social health services and allied professions not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.17',
    cipTitle: 'Optometry',
    cipDefinition: 'Instructional content is defined in code 51.1701.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1701',
    cipTitle: 'Optometry',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of optometry and that focuses on the principles and techniques for examining, diagnosing and treating conditions of the visual system. Includes instruction in prescribing glasses and contact lenses, other optical aids, corrective therapies, patient counseling, physician referral, practice management, and ethics and professional standards.',
    Examples: 'Examples: - Optometry (OD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.18',
    cipTitle: 'Ophthalmic and Optometric Support Services and Allied Professions',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.1801 - 51.1899.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1801',
    cipTitle: 'Opticianry/Ophthalmic Dispensing Optician',
    cipDefinition:
      'A program that prepares individuals to adapt and fit corrective eyewear, including eyeglasses and contact lenses, as prescribed by ophthalmologists or optometrists; to assist patients in selecting appropriate frames; and to prepare work orders for ophthalmic laboratory technicians.  Includes instruction in geometrical optics, ophthalmic optics, anatomy of the eye, optical instrumentation, use and maintenance of adjustment and cleaning tools, prescription interpretation, contact lens adaptation and fitting, prosthesis fitting, facial and eye measuring, pathology identification, record-keeping, and patient and business administrative tasks.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1802',
    cipTitle: 'Optometric Technician/Assistant',
    cipDefinition:
      'A program that prepares individuals to assist optometrists in providing patient care, administering examinations and treatments, and performing office administrative functions.  Includes instruction in applied anatomy and physiology of the eye, visual testing, patient communication, patient preparation, medications and administration, dispensing and fitting of eyeglasses and contact lenses, record-keeping, and office management skills.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1803',
    cipTitle: 'Ophthalmic Technician/Technologist',
    cipDefinition:
      'A program that prepares individuals to assist ophthalmologists and optometrists in examining and treating patients with vision problems, vision disorders, and eye diseases.  Includes instruction in taking patient histories, administering directed treatments and topical medications, diagnostic test procedures and equipment operation, anatomical and functional ocular measurements, patient care and instruction, ophthalmic and surgical equipment maintenance, safety and sterilization procedures, and office administrative procedures.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1804',
    cipTitle: 'Orthoptics/Orthoptist',
    cipDefinition:
      'A program that prepares individuals, under the supervision of ophthalmologists, to analyze, evaluate, and treat specific disorders of vision, eye movement, and eye alignment in children and adults.  Includes instruction in eye anatomy, neuroanatomy, physiology, pharmacology, ophthalmic optics, diagnostic testing and measurement, orthoptic treatment therapy, systemic ocular diseases and disorders, principles of surgery, examination techniques, patient education, child psychology and development, learning disabilities, medical writing, and record-keeping.',
  },
  {
    cipFamily: '51',
    cipCode: '51.1899',
    cipTitle: 'Ophthalmic and Optometric Support Services and Allied Professions, Other',
    cipDefinition:
      'Any instructional program in ophthalmic and optometric support services and allied professions not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.19',
    cipTitle: 'Osteopathic Medicine/Osteopathy',
    cipDefinition: 'Deleted, Report under 51.1202',
  },
  {
    cipFamily: '51',
    cipCode: '51.1901',
    cipTitle: 'Osteopathic Medicine/Osteopathy',
    cipDefinition: 'Moved from 51.1901 to 51.1202',
  },
  {
    cipFamily: '51',
    cipCode: '51.20',
    cipTitle: 'Pharmacy, Pharmaceutical Sciences, and Administration',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.2001 - 51.2099.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2001',
    cipTitle: 'Pharmacy',
    cipDefinition:
      'A program that prepares individuals for the independent or employed practice of preparing and dispensing drugs and medications in consultation with prescribing physicians and other health care professionals, and for managing pharmacy practices and counseling patients.   Includes instruction in mathematics, physics, chemistry, biochemistry, anatomy, physiology, pharmacology, pharmaceutical chemistry, pharmacognosy, pharmacy practice, pharmacy administration, applicable regulations, and professional standards and ethics.',
    Examples: 'Examples: - Pharmacy (BS, BPharm - Canada), - Pharmacy (PharmD - USA and Canada)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2002',
    cipTitle: 'Pharmacy Administration and Pharmacy Policy and Regulatory Affairs',
    cipDefinition:
      'A program that prepares individuals to apply managerial, social, and economic sciences to the study and management of the distribution and use of pharmaceutical products and the provision of pharmacy services.  Includes instruction in research design and methods, statistics, social and organizational behavior, pharmacoeconomics, management of pharmacy services, outcomes research, product planning and reimbursement, cost-benefit analysis, drug marketing, pharmacy and pharmaceutics law and regulation, risk assessment, and organization of the health care system.',
    Examples:
      'Examples: - Pharmacy Administration, - Drug Regulatory Affairs, - Drug Regulatory and Quality Compliance, - Pharmacy Quality Assurance and Regulatory Affairs',
  },
  {
    cipFamily: '51',
    cipCode: '51.2003',
    cipTitle: 'Pharmaceutics and Drug Design',
    cipDefinition:
      'A program that focuses on the scientific study of the formulation of medicinal substances into product vehicles capable of being stored, transported, and then introduced into the patient and behaving in ways optimal to therapeutic interaction.  Includes instruction in statistics, biopharmaceutics, drug metabolism, pharmacokinetics, pharmacodynamics, physical pharmacy, pharmacological analysis, drug design and development, pharmacological biotechnology, chemical separations, spectroscopy, drug-host interactions, immunology, quantitative drug measurement, enzymatic transformations, and metabolic excretion.',
    CrossReferences: '26.1001 - Pharmacology.',
    Examples: 'Examples: - Pharmaceutics and Drug Design (MS), - Pharmaceutics and Drug Design (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2004',
    cipTitle: 'Medicinal and Pharmaceutical Chemistry',
    cipDefinition:
      'A program that focuses on the application of chemistry to the study of biologically and clinically active substances, biological and pharmacological interactions, and the development of associated research methods, techniques, and clinical trial procedures.  Includes instruction in organic chemistry, biochemistry, molecular graphics, rational drug design, toxicology, molecular biology, pharmacology, enzyme mechanisms, receptor theory, neurochemistry, drug metabolism, drug synthesis, biological mechanisms of drug action, research tools and techniques, and laboratory safety.',
    Examples: 'Examples: - Medicinal and Pharmaceutical Chemistry (MS), - Medicinal and Pharmaceutical Chemistry (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2005',
    cipTitle: 'Natural Products Chemistry and Pharmacognosy',
    cipDefinition:
      'A program that focuses on the scientific study of biologically active compounds found in naturally occurring sources such as plants, animals, and microbes and their use and/or modification as medicinal substances.  Includes instruction in medicinal chemistry, organic medicinals, pharmacological therapeutics, pharmacokinetics, molecular biology, structural chemistry, enzymes, nucleic acids, economic botany, ethnobotany, bioprospecting, bioassay, compound identification and isolation, mass spectrometry, and analytical instrumentation and techniques.',
    Examples:
      'Examples: - Natural Products Chemistry and Pharmacognosy (MS), - Natural Products Chemistry and Pharmacognosy (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2006',
    cipTitle: 'Clinical and Industrial Drug Development',
    cipDefinition:
      'A program that focuses on the scientific application of pharmacology, pharmaceutics, and industrial management to the development, production, marketing, and distribution of pharmaceutical products.  Includes instruction in industrial microbiology, plasmids, expression vectors, protein chemistry, assay and evaluation, drug synthesis and purification, quality control, industrial management, production security, patent procedures, intellectual property regulations and issues, patent enforcement and defense, and research design and testing.',
    Examples:
      'Examples: - Clinical and Industrial Drug Development (MS), - Clinical and Industrial Drug Development (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2007',
    cipTitle: 'Pharmacoeconomics/Pharmaceutical Economics',
    cipDefinition:
      'A program that focuses on the application of economics and policy analysis to the study of the relationship of pharmacy services and pharmaceutical processes and products to the health care system and their impact on health care organizations.  Includes instruction in health economics, pharmacoeconomics, health care systems, health care organization and management, statistics and biostatistics, outcomes research, health care policy, pharmacy services, and pharmaceutical industry operations.',
    Examples:
      'Examples: - Pharmacoeconomics/Pharmaceutical Economics (MS), - Pharmacoeconomics/Pharmaceutical Economics (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2008',
    cipTitle: 'Clinical, Hospital, and Managed Care Pharmacy',
    cipDefinition:
      'A program that prepares individuals to deliver specialized pharmacy services and produce pharmaceutical products in clinical settings in conjunction with other health care professionals.  Includes instruction in biostatistics; clinical pharmacokinetics; biopharmaceutics; neuropharmacology; pharmacodynamics; drug metabolism; clinical sampling and research design; drug delivery systems; chemotherapy; pharmacotherapy; clinical pharmacy management; medical and research ethics; and applications to nuclear, nutrition support, oncology, psychiatric, and therapeutic pharmacy.',
    Examples:
      'Examples: - Clinical, Hospital, and Managed Care Pharmacy (MS), - Clinical, Hospital, and Managed Care Pharmacy (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2009',
    cipTitle: 'Industrial and Physical Pharmacy and Cosmetic Sciences',
    cipDefinition:
      'A program that focuses on the application of pharmaceutical sciences and pharmacy to the study of pharmaceuticals production and distribution, and prepares individuals to manage pharmaceutical industry operations.  Includes instruction in pharmacoeconomics, industrial management, operations management, preformulation evaluation, product formulation/reformulation, drug product development, stability testing, dosage design, pilot scale-up, drug marketing, quality control, drug information, and legal and regulatory affairs.',
    Examples:
      'Examples: - Industrial and Physical Pharmacy and Cosmetic Sciences (MS), - Industrial and Physical Pharmacy and Cosmetic Sciences (PhD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.201',
    cipTitle: 'Pharmaceutical Sciences',
    cipDefinition:
      'A program that focuses on the basic sciences that underlie drugs and drug therapy and that prepares individuals for further study and/or careers in pharmaceutical science and research, pharmaceutical administration and sales, biotechnology, drug manufacturing, regulatory affairs, and related fields. Includes instruction in mathematics, biology, chemistry, physics, statistics, pharmaceutics, pharmacology and toxicology, dosage formulation, manufacturing, quality assurance, and regulations.',
    CrossReferences: '26.1001 - Pharmacology.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2011',
    cipTitle: 'Pharmaceutical Marketing and Management',
    cipDefinition:
      'A program that combines the study of basic and pharmaceutical sciences with marketing and management studies; and that prepares individuals for careers in pharmaceutical sales, marketing, management, and related fields within the health care industry. Includes instruction in biology, chemistry, pharmaceutics, pharmacology, health care systems, issues and structure of pharmaceutical industry, management, pharmaceutical marketing, pharmaceutical and medical product management, and legal issues.',
    Examples: 'Examples: - Pharmaceutical Marketing',
  },
  {
    cipFamily: '51',
    cipCode: '51.2099',
    cipTitle: 'Pharmacy, Pharmaceutical Sciences, and Administration, Other',
    cipDefinition:
      'Any instructional program in pharmacy, pharmaceutical sciences, and administration not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.21',
    cipTitle: 'Podiatric Medicine/Podiatry',
    cipDefinition: 'Deleted, Report under 51.1203',
  },
  {
    cipFamily: '51',
    cipCode: '51.2101',
    cipTitle: 'Podiatric Medicine/Podiatry',
    cipDefinition: 'Moved from 51.2101 to 51.1203',
  },
  {
    cipFamily: '51',
    cipCode: '51.22',
    cipTitle: 'Public Health',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.2201 - 51.2299.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2201',
    cipTitle: 'Public Health, General',
    cipDefinition:
      'A program that generally prepares individuals to plan, manage, and evaluate public health care services; to function as public health professionals in public agencies, the private sector, and other settings; and to provide leadership in the field of public health. Includes instruction in epidemiology, biostatistics, public health principles, preventive medicine, health policy and regulations, health care services and related administrative functions, public health law enforcement, health economics and budgeting, public communications, and professional standards and ethics.',
    CrossReferences: '26.1309 - Epidemiology., 44.0503 - Health Policy Analysis.',
    Examples: 'Examples: - Public Health, General (MPH), - Public Health, General (DPH)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2202',
    cipTitle: 'Environmental Health',
    cipDefinition:
      'A program that focuses on the application of environmental sciences, public health, the biomedical sciences, and environmental toxicology to the study of environmental factors affecting human health, safety, and related ecological issues, and prepares individuals to function as professional environmental health specialists. Includes instruction in epidemiology, biostatistics, toxicology, public policy analysis, public management, risk assessment, communications, environmental law, occupational health and safety emergency response, and applications such as air quality, food protection, radiation protection, solid and hazardous waste management, water quality, soil quality, noise abatement, housing quality, and environmental control of recreational areas.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2205',
    cipTitle: 'Health/Medical  Physics',
    cipDefinition:
      'A program that focuses on the application of physics, nuclear science, and engineering physics to diagnostic, treatment, and therapeutic processes and public health protection.  Includes instruction in radiation biophysics, biophysics, health effects of natural and artificially induced radiation, hazard evaluation, environmental radioactivity, nuclear physics, engineering physics, radiobiology, medical radiology, calibration and dosage theory, computer applications and medical informatics, and specific research problems.',
    CrossReferences: '26.0203 - Biophysics.',
    Examples: 'Examples: - Biomedical Physics',
  },
  {
    cipFamily: '51',
    cipCode: '51.2206',
    cipTitle: 'Occupational Health and Industrial Hygiene',
    cipDefinition:
      'A program that prepares public health specialists to monitor and evaluate health and related safety standards in industrial, commercial, and government workplaces and facilities.  Includes instruction in occupational health and safety standards and regulations; health-related aspects of various occupations and work environments; health hazard testing and evaluation; test equipment operation and maintenance; industrial toxicology; worker health and safety education; and the analysis and testing of job-related equipment, behavior practices, and protective devices and procedures.',
    Examples: 'Examples: - Industrial Hygiene',
  },
  {
    cipFamily: '51',
    cipCode: '51.2207',
    cipTitle: 'Public Health Education and Promotion',
    cipDefinition:
      'A program that focuses on  the application of educational and communications principles and methods to the promotion of preventive health measures and the education of targeted populations on health issues, and prepares individuals to function as public health educators and health promotion specialists.  Includes instruction in human development, health issues across the life span, population-specific health issues, principles and methods of public health education, administration of health education campaigns and programs, evaluation methods, public communications, and applications to specific public health subjects and issues.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2208',
    cipTitle: 'Community Health and Preventive Medicine',
    cipDefinition:
      'A program that prepares public health specialists to plan and manage health services in local community settings, including the coordination of related support services, government agencies, and private resources.  Includes instruction in public health, community health services and delivery, health behavior and cultural factors, local government operations, human services, health communication and promotion, health services administration in local settings, environmental health, preventive and comparative medicine, epidemiology, biostatistics, family and community health, and applicable law and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2209',
    cipTitle: 'Maternal and Child Health',
    cipDefinition:
      "A program that focuses on the application of public health specializations, public policy studies, and the social and behavioral sciences to issues of health affecting women, children, and families; and prepares individuals to function as maternal and child health specialists.  Includes instruction in research design and testing, program evaluation, public policy analysis, public finance, economics of health care, community health, family development and dynamics, women's studies, social psychology, fetal and child development, biostatistics, health education and promotion, nutrition, neonatal development, psychology, and social services delivery.",
  },
  {
    cipFamily: '51',
    cipCode: '51.221',
    cipTitle: 'International Public Health/International Health',
    cipDefinition:
      'A program that focuses on the application of public health specializations, the social and behavioral sciences, and policy and communications methods to the study of health problems in low- and middle-income countries and regions, and prepares individuals to function as professional international health specialists.  Includes instruction in health education and promotion, research design and evaluation, infectious disease epidemiology, international health policy and management, public nutrition and food security, information systems, program evaluation, medical anthropology, international communication, behavioral sciences, maternal and child health, demography and population policy, and health care finance and economics.',
    CrossReferences: '26.0509 - Infectious Disease and Global Health.',
    Examples: 'Examples: - Global Health',
  },
  {
    cipFamily: '51',
    cipCode: '51.2211',
    cipTitle: 'Health Services Administration',
    cipDefinition:
      'A program that focuses on the application of policy analysis, public administration, business management, and communications to the planning and management of health services delivery systems in the public and private sectors, and prepares individuals to function as health services administrators and managers.  Includes instruction in health systems planning, public health organization and management, pubic health policy formulation and analysis, finance, business and operations management, economics of health care, organizational and health communications, marketing, human resources management, and public health law and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2212',
    cipTitle: 'Behavioral Aspects of Health',
    cipDefinition:
      'A program that focuses on the biological, behavioral, and socio-cultural determinants of health and health behavior, and the interventions and policies aimed at improving community and population health. Includes instruction in behavioral sciences, public health practice and policy, human services, and research methods.',
    Examples: 'Examples: - Behavioral Health, - Biobehavioral Health, - Health and Social Behavior, - Health Behavior',
  },
  {
    cipFamily: '51',
    cipCode: '51.2213',
    cipTitle: 'Patient Safety and Healthcare Quality',
    cipDefinition:
      'A program that focuses on the application of transformative mechanisms and evidence-based protocols to reduce preventable patient harm and improve clinical outcomes. Includes instruction in healthcare quality, patient safety, research methods, program evaluation, epidemiology, legal and regulatory compliance, systems thinking, human factors engineering, and risk management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2214',
    cipTitle: 'Public Health Genetics',
    cipDefinition:
      'A program that prepares individuals to respond to the complex social, legal, ethical, and public health policy implications generated by genetic research. Includes instruction in biochemical genetics of complex diseases, bioethics, chromosomes and human disease, genetic counseling, genetic epidemiology, gene mapping, human population genetics, and the molecular basis of human inherited disease.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2280',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2299',
    cipTitle: 'Public Health, Other',
    cipDefinition: 'Any instructional program in public health not listed above.',
    CrossReferences:
      '51.0504 - Dental Public Health and Education., 26.1309 - Epidemiology., 44.0503 - Health Policy Analysis.',
  },
  {
    cipFamily: '51',
    cipCode: '51.23',
    cipTitle: 'Rehabilitation and Therapeutic Professions',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.2300 - 51.2399.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2300',
    cipTitle: 'Rehabilitation and Therapeutic Professions, General',
    cipDefinition:
      'A program that prepares individuals to work with people who have learning disabilities, mental illness, and physical disabilities in a clinical, educational, or institutional setting. Includes instruction in abnormal psychology, assistive technology, case management, counseling techniques, cultural diversity, disability assessment and measurement, ethics, psychological and physiological aspects of disability, rehabilitation services, rehabilitation science, and vocational counseling.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2301',
    cipTitle: 'Art Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals, in consultation with other rehabilitation team members or in private practice, to use drawing and other art media forms to assess, treat, and rehabilitate individuals with mental, emotional, developmental, or physical disorders.  Includes instruction in art, history and theory of art therapy, art therapeutic techniques, psychopathology, patient assessment and diagnosis, cultural diversity issues, legal and ethical practice issues, and professional standards and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2302',
    cipTitle: 'Dance Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals, in consultation with other rehabilitation team members or in private practice, to use the therapeutic application of creative dance movement to assist in promoting client rehabilitation and physical, emotional, and mental health.  Includes instruction in neuroanatomy, personality development, movement and motor behavior, psychology, dance, creative expression modalities, improvisation, group psychology and leadership, client evaluation and supervision, dance therapy practice, and professional standards and ethics.',
    CrossReferences: '51.3601 - Movement Therapy and Movement Education.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2305',
    cipTitle: 'Music Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals, in association with a rehabilitation team or in private practice, to use music in therapeutic relationships to address the physical, psychological, cognitive, emotional, and social needs of clients. Includes instruction in music theory and performance, human growth and development, biomedical sciences, abnormal psychology, disabling conditions, patient assessment and diagnosis, treatment plan development and implementation, clinical evaluation, record-keeping, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2306',
    cipTitle: 'Occupational Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals to assist patients limited by physical, cognitive, psychosocial, mental, developmental, and learning disabilities, as well as adverse environmental conditions, to maximize their independence and maintain optimum health through a planned mix of acquired skills, performance motivation, environmental adaptations, assistive technologies, and physical agents.  Includes instruction in the basic medical sciences, psychology, sociology, patient assessment and evaluation, standardized and non-standardized tests and measurements, assistive and rehabilitative technologies, ergonomics, environmental health, special education, vocational counseling, health education and promotion, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2307',
    cipTitle: 'Orthotist/Prosthetist',
    cipDefinition:
      'A program that prepares individuals, in consultation with physicians and other therapists, to design and fit orthoses for patients with disabling conditions of the limbs and/or spine, and prostheses for patients who have partial or total absence of a limb or significant superficial deformity.  Includes instruction in biomechanics, gait analysis, pathomechanics, kinesiology, pathology, neuroanatomy, materials science, diagnostic imaging, patient analysis and measurement, impression taking, model rectification, assistive/restorative technology and engineering applications, product finishing, diagnostic and definitive fitting and alignment, power devices, postoperative management, and patient counseling and follow-up.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2308',
    cipTitle: 'Physical Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals to evaluate, examine, diagnose, and alleviate physical functional impairments and limitations caused by injury or disease through the design and implementation of therapeutic interventions to promote fitness and health. Includes instruction in anatomy, behavioral sciences, biology, biomechanics, biophysical agents, care plan development and documentation, cellular histology, clinical evaluation and measurement, clinical reasoning, communication, exercise physiology, kinesiology, neuroscience, pharmacology, pathology, physiology, professional standards and ethics, rehabilitation psychology, and therapeutic exercise.',
    Examples: 'Examples: - Doctor of Physical Therapy (DPT)',
  },
  {
    cipFamily: '51',
    cipCode: '51.2309',
    cipTitle: 'Therapeutic Recreation/Recreational Therapy',
    cipDefinition:
      'A program that prepares individuals to plan, organize, and direct recreational activities designed to promote health and well-being for patients who are physically, mentally, or emotionally disabled.  Includes instruction in the foundations of therapeutic recreation, leisure education and counseling, program planning, therapeutic recreational modalities, basic anatomy and physiology, psychology, medical terminology, human growth and development, patient observation and evaluation, special needs populations, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.231',
    cipTitle: 'Vocational Rehabilitation Counseling/Counselor',
    cipDefinition:
      'A program that prepares individuals to counsel and assist disabled individuals and recovering patients in order to achieve their greatest physical, mental, social, educational, and vocational potential. Includes instruction in patient evaluation and testing, rehabilitation program planning, patient support services and referral, job analysis, adjustment psychology, rehabilitation services provision, patient counseling and education, applicable law and regulations, and professional standards and ethics.',
    Examples: 'Examples: - Rehabilitation Counseling, - Rehabilitation Services',
  },
  {
    cipFamily: '51',
    cipCode: '51.2311',
    cipTitle: 'Kinesiotherapy/Kinesiotherapist',
    cipDefinition:
      'A program that prepares individuals, under the direction of physicians, to treat the effects of disease, injury, and congenital disorders through therapeutic exercise and education.  Includes instruction in human anatomy, human physiology, kinesiology, biomechanics, therapeutic exercise and adapted physical education, human growth and development, motor learning and performance, testing and measurement, first aid and cardiopulmonary resuscitation, psychology, rehabilitation procedures, patient assessment and management, and professional standards and ethics.',
    CrossReferences: '31.0505 - Exercise Science and Kinesiology.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2312',
    cipTitle: 'Assistive/Augmentative Technology and Rehabilitation Engineering',
    cipDefinition:
      'A program that prepares individuals to apply the principles of psychology, engineering, and occupational therapy to the design and implementation of technological interventions and systems to promote patient rehabilitation and function.  Includes instruction in ergonomics, seating and mobility design, universal and near environment systems design, rehabilitation research, rehabilitation biomechanics, access and communications design, microprocessor-based technologies, neurological trauma, standards and testing, and service delivery management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2313',
    cipTitle: 'Animal-Assisted Therapy',
    cipDefinition:
      'A program that prepares health and human service professionals to integrate the use of animals into their treatment practices in order to facilitate change in people with mental and physical disabilities. Includes instruction in psychology, psychology of disabilities, animal behavior, animal training, the human-animal bond, integration of animals into therapeutic settings, and ethical and cultural issues.',
    Examples: 'Examples: - Equine-Assisted Therapy',
  },
  {
    cipFamily: '51',
    cipCode: '51.2314',
    cipTitle: 'Rehabilitation Science',
    cipDefinition:
      'A program that focuses on human function, disability, and rehabilitation from the perspectives of the health sciences, social sciences, psychology, engineering, and related fields. Includes instruction in psychological, social, and cultural aspects of disability and rehabilitation; sensory and motor function and dysfunction; biomechanics and kinesiology; assistive technology; rehabilitation instrumentation and methodology; statistics; and health and rehabilitation policy.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2315',
    cipTitle: 'Drama Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals, in association with a rehabilitation team or in private practice, to use dramatic play, theater, role play, psychodrama, and dramatic ritual in therapeutic relationships to address the physical, psychological, cognitive, emotional, and social needs of clients. Includes instruction in drama theory and performance, human growth and development, biomedical sciences, abnormal psychology, disabling conditions, patient assessment and diagnosis, treatment plan development and implementation, clinical evaluation, record-keeping, and professional standards and ethics.',
    CrossReferences: '50.0599 - Dramatic/Theatre Arts and Stagecraft, Other.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2316',
    cipTitle: 'Horticulture Therapy/Therapist',
    cipDefinition:
      'A program that focuses on plant science and horticulture techniques to achieve measurable physical and mental health outcomes for individuals with physical or mental challenges. Includes instruction in abnormal psychology, adult development and aging, botany, facilities design, horticultural therapy, plant pathology, people-plant relationships, and psychology.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2317',
    cipTitle: 'Play Therapy/Therapist',
    cipDefinition:
      'A program that prepares individuals to be a Registered Play Therapist (RPT) or a School Based-Registered Play Therapist (SB-RPT). Includes instruction in child development, theories of personality, psychotherapy, child and adolescent psychopathology, and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2399',
    cipTitle: 'Rehabilitation and Therapeutic Professions, Other',
    cipDefinition: 'Any instructional program in rehabilitation and therapeutic professions not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.24',
    cipTitle: 'Veterinary Medicine',
    cipDefinition: 'Moved from 51.24 to 1.80',
  },
  {
    cipFamily: '51',
    cipCode: '51.2401',
    cipTitle: 'Veterinary Medicine',
    cipDefinition: 'Moved from 51.2401 to 1.8001',
  },
  {
    cipFamily: '51',
    cipCode: '51.25',
    cipTitle: 'Veterinary Biomedical and Clinical Sciences',
    cipDefinition: 'Moved from 51.25 to 1.81',
  },
  {
    cipFamily: '51',
    cipCode: '51.2501',
    cipTitle: 'Veterinary Sciences/Veterinary Clinical Sciences, General',
    cipDefinition: 'Moved from 51.2501 to 1.8101',
  },
  {
    cipFamily: '51',
    cipCode: '51.2502',
    cipTitle: 'Veterinary Anatomy',
    cipDefinition: 'Moved from 51.2502 to 1.8105',
  },
  {
    cipFamily: '51',
    cipCode: '51.2503',
    cipTitle: 'Veterinary Physiology',
    cipDefinition: 'Moved from 51.2503 to 1.8109',
  },
  {
    cipFamily: '51',
    cipCode: '51.2504',
    cipTitle: 'Veterinary Microbiology and Immunobiology',
    cipDefinition: 'Moved from 51.2504 to 1.8107',
  },
  {
    cipFamily: '51',
    cipCode: '51.2505',
    cipTitle: 'Veterinary Pathology and Pathobiology',
    cipDefinition: 'Moved from 51.2505 to 1.8108',
  },
  {
    cipFamily: '51',
    cipCode: '51.2506',
    cipTitle: 'Veterinary Toxicology and Pharmacology',
    cipDefinition: 'Moved from 51.2506 to 1.8111',
  },
  {
    cipFamily: '51',
    cipCode: '51.2507',
    cipTitle: 'Large Animal/Food Animal and Equine Surgery and Medicine',
    cipDefinition: 'Moved from 51.2507 to 1.8103',
  },
  {
    cipFamily: '51',
    cipCode: '51.2508',
    cipTitle: 'Small/Companion Animal Surgery and Medicine',
    cipDefinition: 'Moved from 51.2508 to 1.8104',
  },
  {
    cipFamily: '51',
    cipCode: '51.2509',
    cipTitle: 'Comparative and Laboratory Animal Medicine',
    cipDefinition: 'Moved from 51.2509 to 1.8102',
  },
  {
    cipFamily: '51',
    cipCode: '51.251',
    cipTitle: 'Veterinary Preventive Medicine, Epidemiology, and Public Health',
    cipDefinition: 'Moved from 51.2510 to 1.8110',
  },
  {
    cipFamily: '51',
    cipCode: '51.2511',
    cipTitle: 'Veterinary Infectious Diseases',
    cipDefinition: 'Moved from 51.2511 to 1.8106',
  },
  {
    cipFamily: '51',
    cipCode: '51.2599',
    cipTitle: 'Veterinary Biomedical and Clinical Sciences, Other',
    cipDefinition: 'Moved from 51.2599 to 1.8199',
  },
  {
    cipFamily: '51',
    cipCode: '51.26',
    cipTitle: 'Health Aides/Attendants/Orderlies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.2601 - 51.2699.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2601',
    cipTitle: 'Health Aide',
    cipDefinition:
      'A program that prepares individuals to provide routine care and assistance to patients under the direct supervision of other health care professionals, and/or to perform routine maintenance and general assistance in health care facilities and laboratories.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2602',
    cipTitle: 'Home Health Aide/Home Attendant',
    cipDefinition:
      'A program that prepares individuals to provide routine care and support services for homebound disabled, recovering, or elderly people.  Includes instruction in basic nutrition, home sanitation, infection control, first aid, taking vital signs, personal hygiene, interpersonal communication skills, supervised home management, emergency recognition and referral, geriatric care, and legal and ethical responsibilities.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2603',
    cipTitle: 'Medication Aide',
    cipDefinition:
      'A program that prepares individuals to administer prescribed medications; observe and report patient reactions and side effects; and perform related emergency and recording duties under the supervision of nurses and/or physicians.  Includes instruction in basic anatomy and physiology, common medications and their effects, taking vital signs, oxygen administration, medication administration and application, record-keeping, and patient observation.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2604',
    cipTitle: 'Rehabilitation Aide',
    cipDefinition:
      'A program that prepares individuals to assist in rehabilitation services under the supervision of occupational therapists, speech-language pathologists, and other therapeutic professionals, and to perform routine functions in support of rehabilitation. Includes instruction in roles and responsibilities of rehabilitation providers, basic function of the human body, disabling conditions, therapeutic skills, client management, and communication skills.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2605',
    cipTitle: 'Physical Therapy Technician/Aide',
    cipDefinition:
      'A program that prepares individuals to assist in rehabilitation services under the supervision of physical therapists, and to perform routine functions in support of physical therapy and rehabilitation. Includes instruction in applied kinesiology, basic function of the human body, communication skills, principles and procedures of physical therapy, physical therapy modalities, and professional ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2699',
    cipTitle: 'Health Aides/Attendants/Orderlies, Other',
    cipDefinition:
      'Any other instructional program that prepares individuals to provide routine patient care and assistance.',
  },
  {
    cipFamily: '51',
    cipCode: '51.27',
    cipTitle: 'Medical Illustration and Informatics',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.2703 - 51.2799.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2703',
    cipTitle: 'Medical Illustration/Medical Illustrator',
    cipDefinition:
      'A program that prepares individuals to apply the principles and techniques of art and computer-assisted imaging, graphics, and animation to create visual materials to facilitate the recording and dissemination of biomedical knowledge for educational, research, and clinical purposes.  Includes instruction in anatomy, physiology, pathology, histology, embryology, neuroanatomy, medical terminology, artistic media and techniques, illustration techniques, three-dimensional modeling, prosthetics, data display design, exhibit design and production, medical photography, multimedia, computer graphics and animation, digital imaging, business management, production technology, and instructional design.',
  },
  {
    cipFamily: '51',
    cipCode: '51.2706',
    cipTitle: 'Medical Informatics',
    cipDefinition:
      'A program that focuses on the application of computer science and software engineering to medical research and clinical information technology support, and the development of advanced imaging, database, and decision systems.  Includes instruction in computer science, health information systems architecture, medical knowledge structures, medical language and image processing, quantitative medical decision modeling, imaging techniques, electronic medical records, medical research systems, clinical decision support, and informatics aspects of specific research and practice problems.',
    CrossReferences: '26.1103 - Bioinformatics., 11.0104 - Informatics.',
    Examples: 'Examples: - Biomedical Informatics, - Health Informatics, - Dental Informatics, - Nursing Informatics',
  },
  {
    cipFamily: '51',
    cipCode: '51.2799',
    cipTitle: 'Medical Illustration and Informatics, Other',
    cipDefinition: 'Any instructional program in medical illustration and informatics not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.31',
    cipTitle: 'Dietetics and Clinical Nutrition Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3101 - 51.3199.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3101',
    cipTitle: 'Dietetics/Dietitian',
    cipDefinition:
      'A program that prepares individuals to integrate and apply the principles of the food and nutrition sciences, human behavior, and the biomedical sciences to design and manage effective nutrition programs in a variety of settings.  Includes instruction in human nutrition; nutrient metabolism; the role of foods and nutrition in health promotion and disease prevention; planning and directing food service activities; diet and nutrition analysis and planning; supervision of food storage and preparation; client education; and professional standards and regulations.',
    Examples: 'Examples: - Dietetics/Dietitian (RD), - Registered Dietitian (RD)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3102',
    cipTitle: 'Clinical Nutrition/Nutritionist',
    cipDefinition:
      'A program that prepares individuals to apply the principles of dietetics and the biomedical and nutrition sciences to design and manage effective nutrition programs as part of clinical treatment and therapy programs, and to manage health care facility food services.  Includes instruction in human nutrition, nutrient metabolism, the role of foods and nutrition in health promotion and disease prevention, nutrition as a treatment regime, planning and directing hospital food service programs, diet and nutrition analysis and planning, supervision of food storage and preparation, special diets, client education, and professional standards and regulations.',
    CrossReferences: '19.0504 - Human Nutrition., 30.1901 - Nutrition Sciences.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3103',
    cipTitle: 'Dietetic Technician',
    cipDefinition:
      'A program that prepares individuals, under the supervision of registered dietitians and nutritionists, to implement nutritional and dietetic plans and provide direct client and patient services.  Includes instruction in human nutrition, client interviewing and record keeping, dietary planning, food preparation and safety, food worker supervision, food service establishment monitoring and inspection, and applicable procedures and regulations.',
    Examples: 'Examples: - Dietetic Technician (DTR), - Registered Dietetic Technician (DTR)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3104',
    cipTitle: 'Dietitian Assistant',
    cipDefinition:
      'A program that prepares individuals to assist registered dietitians in planning, preparing and serving meals to individuals with specific dietary needs.  Includes instruction in equipment use, food preparation, diet regulations, food handling, safety and sanitary standards and administrative techniques and procedures.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3199',
    cipTitle: 'Dietetics and Clinical Nutrition Services, Other',
    cipDefinition: 'Any instructional program in dietetics and clinical nutrition services not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.32',
    cipTitle: 'Health Professions Education, Ethics, and Humanities',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3201 - 51.3299.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3201',
    cipTitle: 'Bioethics/Medical Ethics',
    cipDefinition:
      'A program that focuses on the application of ethics, religion, jurisprudence, and the social sciences to the analysis of health care issues, clinical decision making, and research procedures. Includes instruction in philosophical ethics, moral value, medical sociology, theology, spirituality and health, policy analysis, decision theory, and applications to problems such as death and dying, therapeutic relationships, organ transplantation, human and animal subjects, reproduction and fertility, health care justice, cultural sensitivity, needs assessment, professionalism, conflict of interest, chaplaincy, and clinical or emergency procedures.',
    CrossReferences: '38.0104 - Applied and Professional Ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3202',
    cipTitle: 'Health Professions Education',
    cipDefinition:
      'A program that focuses on education and administration leadership skills for clinical educators across health professions and specialties. Includes instruction in academic leadership, assessment, clinical decision making, curriculum development, ethics, primary care education, program evaluation, research methods, and statistics.',
    CrossReferences: '61.0207 - Medical Education Fellowship Program.',
    Examples: 'Examples: - Teaching Certificate for Pharmacists, - Medical Education Teaching',
  },
  {
    cipFamily: '51',
    cipCode: '51.3203',
    cipTitle: 'Nursing Education',
    cipDefinition:
      'A program that prepares registered nurses to teach in academic and clinical settings, including staff development. Includes instruction in advanced nursing practice, nursing role development, curriculum and instruction, program and course design, clinical instruction, and evaluation of learning.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3204',
    cipTitle: 'Medical/Health Humanities',
    cipDefinition:
      'A program that explores the ethical, historical, literary, philosophical, and religious dimensions of medicine or health. Includes instruction in art, cultural studies, economics, ethics, history, literature, medical anthropology, philosophy, religion and spiritual thought, science and technology, visual art, and writing.',
    Examples: 'Examples: - Narrative Medicine',
  },
  {
    cipFamily: '51',
    cipCode: '51.3205',
    cipTitle: 'History of Medicine',
    cipDefinition:
      'A program that focuses on the historical evolution and socio-economic context of medical theories, education, practices, and technologies; and on the history of diseases, therapeutics, patients, and healers. Includes instruction in the concepts and methods of the historiography of medicine; the history of science, medicine, and technology; and of research methods in the history of medicine.',
    CrossReferences:
      '54.0104 - History and Philosophy of Science and Technology., 30.1501 - Science, Technology and Society.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3206',
    cipTitle: 'Arts in Medicine/Health',
    cipDefinition:
      'A program that focuses on the design, implementation, and management of programs that use the visual and performing arts in support of health and wellness among diverse individuals and communities. Includes instruction in dance, literary arts, music, theatre, healthcare systems, program administration, and public health.',
    Examples:
      'Examples: - Theatre for Health, - Visual Arts in Medicine, - Dance in Medicine, - Music in Medicine, - Arts and Health, - Arts in Healthcare, - Arts in Public Health',
  },
  {
    cipFamily: '51',
    cipCode: '51.3299',
    cipTitle: 'Health Professions Education, Ethics, and Humanities, Other',
    cipDefinition:
      'Any instructional program in health professions education, ethics, and humanities not listed above.',
    CrossReferences:
      '54.0104 - History and Philosophy of Science and Technology., 30.1501 - Science, Technology and Society., 45.0203 - Medical Anthropology.',
  },
  {
    cipFamily: '51',
    cipCode: '51.33',
    cipTitle: 'Alternative and Complementary Medicine and Medical Systems',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3300 - 51.3399.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3300',
    cipTitle: 'Alternative and Complementary Medicine and Medical Systems, General',
    cipDefinition:
      'A general program that focuses on the theories, science, and practice of alternative and complementary medicine and medical systems. Includes instruction in understanding acupuncture, herbal medicine, naturopathy, homeopathy, and other alternative and complementary medicine modalities; mind-body therapies; nutrition; comparison of conventional and alternative medicine; and medical ethics; and may include training in specific alternative and complementary medicine modalities.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3301',
    cipTitle: 'Acupuncture and Oriental Medicine',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of acupuncture, a Chinese system of health care and healing based on the use of pulse diagnosis, acupuncture point selection, and needle insertion, as well as herbs and other Asian treatment modalities. Includes instruction in basic Western medical sciences, Asian philosophy, Chinese medical anatomy and physiology, Chinese herbs, diagnostic techniques, patient observation and communication, acupuncture points and point selection, acupuncture techniques, acupuncture treatment of disease, pain management, practice management, and professional standards and ethics.',
    Examples: 'Examples: - Acupuncture, - Acupuncture and Oriental Medicine, - Oriental Medicine',
  },
  {
    cipFamily: '51',
    cipCode: '51.3302',
    cipTitle: 'Traditional Chinese Medicine and Chinese Herbology',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of Chinese herbal medicine, a system based on the use of nutrition, natural products, and prescribed medical formulae to treat imbalances in the state of bodily health as defined in the Nei Ching Su Wen and other authorities.  Includes instruction in the basic Western medical sciences, history and theory of Chinese medicine, Chinese medical anatomy and physiology, Chinese herbology, herbal prescription preparation, herbal patent medicine, herbal pharmacology, Chinese internal medicine and gynecology, febrile diseases, food therapy, practice management, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3303',
    cipTitle: 'Naturopathic Medicine/Naturopathy',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of naturopathic medicine, an holistic therapeutic approach combining medical knowledge with noninvasive natural, homeopathic, and non-Western therapies and emphasizing disease prevention, wellness, and self-healing.  Includes instruction in the basic medical sciences, epidemiology, neuroscience, biostatistics, public and community health, laboratory and clinical diagnosis, pharmacognosy, botanical medicine, homeopathy, principles of Oriental medicine and Ayurveda, manipulative therapy, naturopathic therapeutics, therapeutic nutrition, case analysis and management, philosophy of naturopathy, patient counseling, practice management, and professional standards and ethics.',
    Examples: 'Examples: - Naturopathic Medicine/Naturopathy (ND)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3304',
    cipTitle: 'Homeopathic Medicine/Homeopathy',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of homeopathic medicine, an holistic therapeutic system using naturally occurring substances in highly diluted forms to mimic symptoms of illness and trigger autoimmune responses, based on the law of similars and other principles derived from the work of Samuel Hahnemann and others.  Includes instruction in anatomy, physiology, pathology, toxicology, philosophy and theory of homeopathy, casetaking and case analysis, repertory, materia medica, constitutional typing, proving, practice management, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3305',
    cipTitle: 'Ayurvedic Medicine/Ayurveda',
    cipDefinition:
      'A program that prepares individuals for the independent professional practice of Ayurveda, a holistic health care system based on the use of dietary, lifestyle, spiritual, pharmacological and physical therapies to remove imbalances and restore health as defined in Vedic and other classical Sanskrit authorities in the Indian tradition.  Includes instruction in Sanskrit; history, philosophy, and principles of Ayurveda; Ayurvedic pharmacology; anatomy and physiology; internal medicine; herbology; diagnostic techniques; patient examination and communication; therapeutic modalities; detoxification theory and practice; mind and body behavior modification; Yoga; clinical pediatrics and gynecology; diet therapies; treatment of specific diseases; and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3306',
    cipTitle: 'Holistic/Integrative Health',
    cipDefinition:
      'A program that focuses on the holistic interconnectedness of physical, psychological, social, spiritual, and environmental well-being and that integrates aspects of alternative, complementary, and conventional medicine to alleviate illness and promote, maintain, and optimize wellness. Includes instruction in anatomy and physiology, alternative diagnostic and healing methods, aromatherapy, biofeedback, energy medicine, environmental psychology, ethics, health coaching, herbal medicine, mind-body medicine, mindfulness, nutrition, somatic bodywork, and stress management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3399',
    cipTitle: 'Alternative and Complementary Medicine and Medical Systems, Other',
    cipDefinition:
      'Any instructional program in alternative and complementary medicine and medical systems not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.34',
    cipTitle: 'Alternative and Complementary Medical Support Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3401 - 51.3499.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3401',
    cipTitle: 'Direct Entry Midwifery',
    cipDefinition:
      "A program that prepares individuals to provide pre-natal, natural birth, and immediate postpartum care to pregnant women in jurisdictions licensing direct (non-nursing) training for midwives.  Includes instruction in basic obstetrics, women's health, fetal development, childbirth education, maternal nutrition and health, labor support, natural childbirth, postpartum support, prebirth counseling and risk screening, management of obstetric emergencies, patient referral, applicable regulations, and professional standards and ethics.",
    Examples: 'Examples: - Direct Entry Midwifery, - Licensed Midwife (LM), - Certified Professional Midwife (CPM)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3499',
    cipTitle: 'Alternative and Complementary Medical Support Services, Other',
    cipDefinition:
      'Any instructional program in alternative and complementary medical support services not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.35',
    cipTitle: 'Somatic Bodywork and Related Therapeutic Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3501 - 51.3599.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3501',
    cipTitle: 'Massage Therapy/Therapeutic Massage',
    cipDefinition:
      'A program that prepares individuals to provide relief and improved health and well-being to clients through the application of manual techniques for manipulating skin, muscles, and connective tissues.  Includes instruction in Western (Swedish) massage, sports massage, myotherapy/trigger point massage, myofascial release, deep tissue massage, cranio-sacral therapy, reflexology, massage safety and emergency management, client counseling, practice management, applicable regulations, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3502',
    cipTitle: 'Asian Bodywork Therapy',
    cipDefinition:
      'A program that prepares individuals to provide relief and improved health and well-being based on Chinese medical principles through one or more of the traditional bodywork therapies of Asia.  Includes instruction in acupressure, AMMA(r) Therapy, Chi Nei Tsang, Five Element Shiatsu, Integrative Eclectic Shiatsu, Japanese Shiatsu, Jin Shin Do(r) Bodymind Acupressure(tm), Macrobiotic Shiatsu, Anma Therapy, Thai massage, Tui na, Zen Shiatsu, Qigong, Okazaki(r) Restorative Massage, principles of Western and Chinese medicine, patient counseling and observation, practice management, applicable regulations, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3503',
    cipTitle: 'Somatic Bodywork',
    cipDefinition:
      'A program that prepares individuals to promote physical and emotional balance and well-being through the application of skilled touch principles and techniques.  Includes instruction in therapeutic massage and other hands-on touch methods, anatomy and physiology, structural/functional integration and various holistic health systems (Rolfing/Structural Integration, colon hydrotherapy, Breema, Hellerwork, lymphatic drainage, Rosen Method, hydrotherapy, and others), client counseling, practice management, and applicable regulations and professional standards.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3599',
    cipTitle: 'Somatic Bodywork and Related Therapeutic Services, Other',
    cipDefinition: 'Any instructional program in somatic bodywork and related therapeutic services not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.36',
    cipTitle: 'Movement and Mind-Body Therapies and Education',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3601 - 51.3699.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3601',
    cipTitle: 'Movement Therapy and Movement Education',
    cipDefinition:
      'A program that prepares individuals to use functional and expressive integration strategies to help promote somatic awareness and optimal psychophysical functioning.  Includes instruction in skilled touch techniques, kinesthetic awareness processes, movement observation, patterning, client assessment and guidance, verbal and nonverbal communication, practice management, professional standards and ethics, and specific therapeutic and educational modalities',
    CrossReferences: '51.2302 - Dance Therapy/Therapist.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3602',
    cipTitle: 'Yoga Teacher Training/Yoga Therapy',
    cipDefinition:
      'A program that prepares individuals to function as professional instructors in the spiritual and physical therapy of Yoga in private, institutional, and clinical settings.  Includes instruction in the principles of Ayurveda, philosophy of Yoga, warm-up and progressive exercises, breathing exercises, meditation, Yamas and Niyamas, instructional techniques, posture adjustment, student/client counseling and assessment, advanced Yoga disciplines and styles, business practices, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3603',
    cipTitle: 'Hypnotherapy/Hypnotherapist',
    cipDefinition:
      'A program that prepares individuals to employ the controlled inducement of altered states of consciousness, or trance, to reduce pain, resolve emotional conflicts, and enhance communications as part of diagnostic and treatment programs.  Includes instruction in the principles of hypnosis and suggestion, induction and deepening, post-hypnotic suggestion, awakening techniques, regression, pacing and leading, resistance transformation, client testing and management, practice management and regulation, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3699',
    cipTitle: 'Movement and Mind-Body Therapies and Education, Other',
    cipDefinition: 'Any instructional program in movement and mind-body therapies not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.37',
    cipTitle: 'Energy and Biologically Based Therapies',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3701 - 51.3799.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3701',
    cipTitle: 'Aromatherapy',
    cipDefinition:
      'A program that prepares individuals to provide therapeutic care through the preparation and application of extracted essential oils from plants, including flowers (botanicals), roots, berries, and bark.  Includes instruction in aromatherapy history and theory, basic physiology of absorption, aromachemistry, organic chemistry, botanical taxonomy, carrier oils, essential oils, extraction methods, blending techniques, application methods, safety, client consultation and treatment planning, business management, professional standards and ethics, and applicable regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3702',
    cipTitle: 'Herbalism/Herbalist',
    cipDefinition:
      'A program that prepares individuals to utilize the materia medica and clinical practices of Western, Native American, African, Australasian, and other herbal medicine traditions to prevent and cure illness and temporary physical disorders.  Includes instruction in basic anatomy, physiology, pathology, and toxicology; nutrition; terminology and materia medica; therapeutic herbalism; herbal pharmacy; botanicals and ethnobotany; wildcrafting and harvesting; labeling and dispensing phytotherapy; counterindications; practice management; consultation skills; and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3703',
    cipTitle: 'Polarity Therapy',
    cipDefinition:
      'A program that prepares individuals to promote health and well-being through application of the integrative therapeutic system based on the Human Energy Field theory developed by Randolph Stone.  Includes instruction in polarity theory and principles, energy-based and orthodox anatomy and physiology, polarity processing, polarity bodywork, polarity yoga, energetic nutrition, energetic evaluation and assessment, client communication, practice management, and professional standards and ethics.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3704',
    cipTitle: 'Reiki',
    cipDefinition:
      "A program that prepares individuals to promote physical, emotional, and spiritual balance and healing through the manipulation of the body's energy field to increase healing energy.  Includes instruction in the history and philosophy of Usui Reiki, attunement, empowerment, self-treatment, direct and distance healing techniques, healing symbols, aura clearing, Reiki meditation, practice management, and related Reiki systems such as Lightarian, Urevia, and Magnified Healing.",
  },
  {
    cipFamily: '51',
    cipCode: '51.3799',
    cipTitle: 'Energy and Biologically Based Therapies, Other',
    cipDefinition:
      'Any instructional program in alternative, complementary, and somatic health and therapeutic services not listed above (bioenergetics, cranio-sacral therapy, Therapeutic Touch, Qi Gong, and others).',
  },
  {
    cipFamily: '51',
    cipCode: '51.38',
    cipTitle: 'Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3801 - 51.3899.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3801',
    cipTitle: 'Registered Nursing/Registered Nurse',
    cipDefinition:
      'A program that generally prepares individuals in the knowledge, techniques and procedures for promoting health, providing care for sick, disabled, infirmed, or other individuals or groups.  Includes instruction in the administration of medication and treatments, assisting a physician during treatments and examinations, Referring patients to physicians and other health care specialists, and planning education for health maintenance.',
    Examples: 'Examples: - Nursing (ASN), - Nursing (BSN, BScN), - Nursing (MSN, MScN, MN), - Registered Nurse (RN)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3802',
    cipTitle: 'Nursing Administration',
    cipDefinition:
      'A program that prepares registered nurses to manage nursing personnel and services in hospitals and other health care delivery agencies.  Includes instruction in principles of health care administration, resource and financial management, health care law and policy, medical personnel management, and managed care operations.',
    Examples: 'Examples: - Nursing Administration (PhD), - Nursing Administration (MSN, MScN, MS, MSc, MN)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3803',
    cipTitle: 'Adult Health Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide general care for adult patients.  Includes instruction in adult primary care, adult pathophysiology, clinical management of medication and treatments, patient assessment and education, patient referral, and planning adult health maintenance programs.',
    Examples: 'Examples: - Adult Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.3804',
    cipTitle: 'Nurse Anesthetist',
    cipDefinition:
      'A program that prepares registered nurses to administer anesthetics and provide care for patients before, during and after anesthesia.  Includes instruction in the biochemistry and physics of anesthesia; advanced anatomy and physiology; clinical pharmacology of anesthetics and adjunctive drugs; pain management; acute care and operating room practice; clinical technology and procedures; emergency intervention; patient assessment and education; and legal issues.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3805',
    cipTitle: 'Family Practice Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide independent general care for family groups and individuals in the context of family living.  Includes instruction in family theory and intervention, role synthesis, family primary care, nursing practice and health care policy, holistic practice, pediatric practice, gerontological practice, health assessment, clinical pharmacotherapeutics, clinical techniques, and pathopsychology.',
    Examples:
      'Examples: - Family Practice Nurse Practitioner, - Family Nurse Practitioner, - Primary Health Care Nurse Practitioner, - Primary Care Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.3806',
    cipTitle: 'Maternal/Child Health and Neonatal Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide prenatal care to pregnant women and to mothers and their newborn infants.  Includes instruction in perinatal and newborn health assessment, stabilization, and care; pathophysiology of pregnancy, fetuses, and the newborn; clinical management of high-risk pregnancies and newborns; perinatal and neonatal technology and clinical procedures; and patient education.',
    Examples: 'Examples: - Neonatal Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.3807',
    cipTitle: 'Nurse Midwife/Nursing Midwifery',
    cipDefinition:
      'A program that prepares registered nurses to independently deliver babies and treat mothers in the prenatal, delivery, and post-delivery periods.  Includes instruction in pre-delivery screening, physician referral, and the care of infants during the delivery and immediate post-delivery phases.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3808',
    cipTitle: 'Nursing Science',
    cipDefinition:
      'A research program that focuses on the study of advanced clinical practices, research methodologies, the administration of complex nursing services, and that prepares nurses to further the progress of nursing research through experimentation and clinical applications.',
    Examples: 'Examples: - Nursing Science (PhD), - Nursing Science (MS, MSc, MSN, MN)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3809',
    cipTitle: 'Pediatric Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide care for children from infancy through adolescence.  Includes instruction in the administration of medication and treatments, assisting physicians, patient examination and referral, and planning and delivering health maintenance and health education programs.',
    Examples: 'Examples: - Pediatric Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.381',
    cipTitle: 'Psychiatric/Mental Health Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to promote mental health and provide nursing care to patients with mental, emotional or behavioral disorders, in mental institutions or other settings.  Includes instruction in psychopathology, behavioral health, counseling and intervention strategies, psychoeducation, mental health assessment and dual diagnosis, stabilization and management of psychotic illness, psychiatric care and rehabilitation, substance abuse, and crisis intervention.',
    Examples: 'Examples: - Psychiatric Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.3811',
    cipTitle: 'Public Health/Community Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to promote health and provide preventive and curative nursing services for groups or communities under the supervision of a public health agency.  Includes instruction in community and rural health, family therapy and intervention, disease prevention, health education, and community health assessment.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3812',
    cipTitle: 'Perioperative/Operating Room and Surgical Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide care to patients before and during surgery, and provide tableside assistance to surgeons. Includes instruction in operating room safety and preparation, aseptic technique, anesthesia, patient preparation, surgical instruments and procedures, sterilization and disinfecting, surgical drugs and solutions, hemostasis, emergency procedures, and patient/family education.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3813',
    cipTitle: 'Clinical Nurse Specialist',
    cipDefinition:
      'A program that prepares registered nurses to deliver direct patient and client care in clinical settings.  Includes instruction in clinical pharmacotherapeutics, advanced clinical practice, holistic nursing, nursing practice and health care policy, administration and consultation services, health assessment, patient stabilization and care, and patient education.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3814',
    cipTitle: 'Critical Care Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide specialized care to patients with life-threatening problems, including those in intensive care facilities and on life support.  Includes instruction in adult, neonatal, and pediatric critical care; technical skills; patient monitoring and assessment; normal and abnormal readings; and troubleshooting.',
    Examples: 'Examples: - Acute Care Nursing, - Acute Care Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.3815',
    cipTitle: 'Occupational and Environmental Health Nursing',
    cipDefinition:
      'A program that prepares registered nurses to deliver nursing health care services to workers and worker populations in clinical settings and at job sites.  Includes instruction in public and community health, occupational safety and health, occupational health surveillance, case management, fitness for duty testing, medication, allergies and immunization, emergency and ambulatory care, and applicable laws and regulations.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3816',
    cipTitle: 'Emergency Room/Trauma Nursing',
    cipDefinition:
      'A program that prepares registered nurses to deliver advanced, direct patient care in emergency and trauma settings. Includes instruction in advanced health assessment, pharmacology, physiology, emergency management, trauma conditions, trauma assessments and acute care.',
    Examples:
      'Examples: - Emergency Nursing, - Emergency Nurse Practitioner, - Trauma Nursing, - Trauma, Critical Care and Emergency Nursing',
  },
  {
    cipFamily: '51',
    cipCode: '51.3817',
    cipTitle: 'Nursing Education',
    cipDefinition: 'Moved from 51.3817 to 51.3203',
  },
  {
    cipFamily: '51',
    cipCode: '51.3818',
    cipTitle: 'Nursing Practice',
    cipDefinition:
      'A practice-focused program that prepares registered nurses for increasingly complex evidence-based nursing practice, including translating research into practice, evaluating evidence, applying research in decision-making, and implementing viable clinical innovations to change practice. Includes instruction in healthcare delivery systems, health economics and finance, health policy, research methods, translating evidence into practice, concepts in population health, and nursing leadership.',
    Examples: 'Examples: - Nursing Practice (DNP)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3819',
    cipTitle: 'Palliative Care Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide a combination of active and compassionate therapies intended to comfort and support patients and their families who are living with life-threatening illness. Includes instruction in pathophysiology, palliative care processes, pain management, pharmacology, loss, grief, death, and bereavement.',
  },
  {
    cipFamily: '51',
    cipCode: '51.382',
    cipTitle: 'Clinical Nurse Leader',
    cipDefinition:
      'A program that prepares registered nurses for careers as clinical nurse leaders in which they work as part of an interdisciplinary team that provides direct medical care for patients based on evidence-based practice and research. Includes instruction in pharmacology, pathophysiology, epidemiology, research in nursing methods, advanced health assessment, statistics, and clinical practice management.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3821',
    cipTitle: 'Geriatric Nurse/Nursing',
    cipDefinition:
      'A program that prepares registered nurses to provide health care to aging and elderly patients. Includes instruction in geriatric primary care, geriatric diagnosis, mental health and aging, primary care management, geriatric acute and chronic illness, advanced nursing practices for geriatric patients, evidence-based nursing practice, advanced physiology, pathophysiology, pharmacology, and pharmacotherapeutics.',
    Examples: 'Examples: - Gerontological Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.3822',
    cipTitle: "Women's Health Nurse/Nursing.",
    cipDefinition:
      'A program that prepares registered nurses to provide health care specific to women. Includes instruction in the wellness and care of women, gynecology, obstetrics, advanced nursing practices for women, evidence-based nursing practice, advanced physiology and pathophysiology, advanced pharmacology, and pharmacotherapeutics.',
    Examples: "Examples: - Women's Health Nurse Practitioner",
  },
  {
    cipFamily: '51',
    cipCode: '51.3823',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3824',
    cipTitle: 'Forensic Nursing',
    cipDefinition:
      'A program that prepares individuals to apply the law and forensic science to nursing practice and collaborate with other professionals for the care of victims and the prosecution of criminals. Includes instruction in advanced physical assessment, evidence collection and preservation, forensic anthropology, legal testimony, medicolegal investigation, pathophysiology, pathohistology, pharmacology, and violence prevention.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3899',
    cipTitle: 'Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing, Other',
    cipDefinition:
      'Any instructional program in registered nursing, nursing administration, nursing research, and clinical nursing not listed above.',
    Examples: 'Examples: - Cardiology Nurse Practitioner, - Nephrology Nurse Practitioner',
  },
  {
    cipFamily: '51',
    cipCode: '51.39',
    cipTitle: 'Practical Nursing, Vocational Nursing and Nursing Assistants',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.3901 - 51.3999.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3901',
    cipTitle: 'Licensed Practical/Vocational Nurse Training',
    cipDefinition:
      'A program that prepares individuals to assist in providing general nursing care under the direction of a registered nurse, physician or dentist.  Includes instruction in taking patient vital signs, applying sterile dressings, patient health education, and assistance with examinations and treatment.',
    Examples:
      'Examples: - Licensed Practical Nurse (LPN), - Licensed Vocational Nurse (LVN), - Licensed Practical/Vocational Nurse Training (Cert.), - Licensed Practical/Vocational Nurse Training (Dipl), - Licensed Practical/Vocational Nurse Training (AAS)',
  },
  {
    cipFamily: '51',
    cipCode: '51.3902',
    cipTitle: 'Nursing Assistant/Aide and Patient Care Assistant/Aide',
    cipDefinition:
      'A program that prepares individuals to perform routine nursing-related services to patients in hospitals or long-term care facilities, under the training and supervision of a registered nurse or licensed practical nurse.',
  },
  {
    cipFamily: '51',
    cipCode: '51.3999',
    cipTitle: 'Practical Nursing, Vocational Nursing and Nursing Assistants, Other',
    cipDefinition:
      'Any instructional program in practical nursing, vocational nursing, and nursing assistants not listed above.',
  },
  {
    cipFamily: '51',
    cipCode: '51.99',
    cipTitle: 'Health Professions and Related Clinical Sciences, Other',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 51.9980 - 51.9999.',
  },
  {
    cipFamily: '51',
    cipCode: '51.9980',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '51',
    cipCode: '51.9999',
    cipTitle: 'Health Professions and Related Clinical Sciences, Other',
    cipDefinition:
      'Any instructional program in the health professions and related clinical sciences not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52',
    cipTitle: 'Business, Management, Marketing, and Related Support Services',
    cipDefinition:
      'Instructional programs that prepare individuals to perform managerial, technical support, and applied research functions related to the operation of commercial and non-profit enterprises and the buying and selling of goods and services.',
  },
  {
    cipFamily: '52',
    cipCode: '52.01',
    cipTitle: 'Business/Commerce, General',
    cipDefinition: 'Instructional content is defined in code 52.0101.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0101',
    cipTitle: 'Business/Commerce, General',
    cipDefinition:
      'A program that focuses on the general study of business, including the processes of interchanging goods and services (buying, selling and producing), business organization, and accounting as used in profit-making and nonprofit public and private institutions and agencies.  The programs may prepare individuals to apply business principles and techniques in various occupational settings.',
  },
  {
    cipFamily: '52',
    cipCode: '52.02',
    cipTitle: 'Business Administration, Management and Operations',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.0201 - 52.0299.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0201',
    cipTitle: 'Business Administration and Management, General',
    cipDefinition:
      'A program that generally prepares individuals to plan, organize, direct, and control the functions and processes of a firm or organization.  Includes instruction in management theory, human resources management and behavior, accounting and other quantitative methods, purchasing and logistics, organization and production, marketing, and business decision-making.',
    Examples: 'Examples: - General Management, - Business Administration',
  },
  {
    cipFamily: '52',
    cipCode: '52.0202',
    cipTitle: 'Purchasing, Procurement/Acquisitions and Contracts Management',
    cipDefinition:
      'A program that prepares individuals to manage and/or administer the processes by which a firm or organization contracts for goods and services to support its operations, as well as contracts it to sell to other firms or organizations.  Includes instruction in contract law, negotiations, buying procedures, government contracting, cost and price analysis, vendor relations, contract administration, auditing and inspection, relations with other firm departments, and applications to special areas such as high-technology systems, international purchasing, and construction.',
    Examples: 'Examples: - Purchasing Management, - Contracts Management, - Acquisition/Procurement Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.0203',
    cipTitle: 'Logistics, Materials, and Supply Chain Management',
    cipDefinition:
      'A program that prepares individuals to manage and coordinate all logistical functions in an enterprise, ranging from acquisitions to receiving and handling, through internal allocation of resources to operations units, to the handling and delivery of output.  Includes instruction in acquisitions and purchasing, inventory control, storage and handling, just-in-time manufacturing, logistics planning, shipping and delivery management, transportation, quality control, resource estimation and allocation, and budgeting.',
    Examples: 'Examples: - Logistics Management, - Materials Management, - Acquisition Logistics, - Supply Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.0204',
    cipTitle: 'Office Management and Supervision',
    cipDefinition:
      'A program that prepares individuals to supervise and manage the operations and personnel of business offices and management-level divisions.  Includes instruction in employee supervision, management, and labor relations; budgeting; scheduling and coordination; office systems operation and maintenance; office records management, organization, and security; office facilities design and space management; preparation and evaluation of business management data; and public relations.',
    CrossReferences: '51.0705 - Medical Office Management/Administration.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0205',
    cipTitle: 'Operations Management and Supervision',
    cipDefinition:
      'A program that prepares individuals to manage and direct the physical and/or technical functions of a firm or organization, particularly those relating to development, production, and manufacturing.  Includes instruction in principles of general management, manufacturing and production systems, plant management, equipment maintenance management, production control, industrial labor relations and skilled trades supervision, strategic manufacturing policy, systems analysis, productivity analysis and cost control, and materials planning.',
    Examples: 'Examples: - Maintenance Management, - Production Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.0206',
    cipTitle: 'Non-Profit/Public/Organizational Management',
    cipDefinition:
      'A program that prepares individuals to manage the business affairs of non-profit corporations, including foundations, educational institutions, associations, and other such organizations, and public agencies and governmental operations.  Includes instruction in business management, principles of public administration, principles of accounting and financial management, human resources management, taxation of non-profit organizations, and business law as applied to non-profit organizations.',
    CrossReferences: '39.0801 - Religious Institution Administration and Management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0207',
    cipTitle: 'Customer Service Management',
    cipDefinition:
      'A program that prepares individuals to supervise and monitor customer service performance and manage frontline customer support services, call centers/help desks, and customer relations.  Includes instruction in customer behavior, specialized information technology and systems management, developing and using customer service databases, user surveys and other feedback mechanisms, strategic and performance planning and analysis, operations management, personnel supervision, and communications and marketing skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0208',
    cipTitle: 'E-Commerce/Electronic Commerce',
    cipDefinition:
      'A program that prepares individuals to plan, manage, supervise, and market electronic business operations, products, and services provided online via the Internet.  Includes instruction in business administration, information technology, information resources management, web design, computer and Internet law and policy, computer privacy and security, e-trading, insurance, electronic marketing, investment capital planning, enterprise operations, personnel supervision, contracting, and product and service networking.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0209',
    cipTitle: 'Transportation/Mobility Management',
    cipDefinition:
      'A program that focuses on the theory, policy, law, and practices required to administrate and operate public transportation facilities, networks, services, and systems. Includes instruction in demand analysis and forecasting, environmental planning, facilities design and construction, geographic information systems (GIS), logistics, multi- and intermodal transportation systems, project management, public administration, public policy, transportation economics, transportation law, transportation operations, transportation systems, and transportation technologies.',
    CrossReferences: '44.0403 - Transportation and Infrastructure Planning/Studies.',
    Examples:
      'Examples: - Mobility Management, - Air Mobility, - Sea Mobility, - Freight Forwarding, - Customs Broker, - Transportation Administration and Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.021',
    cipTitle: 'Research and Development Management',
    cipDefinition:
      'A program that prepares individuals to manage projects, programs and organizations involving basic and applied research, or specialized technology applications, in the public or private sectors.  Includes instruction in all aspects of business management applied to the leadership and control of scientific, clinical, engineering and social scientific enterprises; the management of related operational, security and logistical issues; and technical competence in one or more research disciplines.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0211',
    cipTitle: 'Project Management',
    cipDefinition:
      'A program that prepares individuals to apply quantitative and qualitative knowledge, skills, tools, and techniques to manage projects in a wide range of fields and occupations.  Includes instruction in project planning, risk management, cost and time management, contracts and procurement, accounting, statistics, decision making, and human resources.',
    CrossReferences: '11.1005 - Information Technology Project Management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0212',
    cipTitle: 'Retail Management',
    cipDefinition:
      'A program that prepares individuals to manage the operations of a wide variety of retail businesses, including department stores; independent and chain stores; fashion, food and beverage, and pharmaceutical establishments; and online retail businesses. Includes instruction in retail management, merchandise management, customer service management, marketing, organizational behavior, accounting, finance, business law, and personnel management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0213',
    cipTitle: 'Organizational Leadership',
    cipDefinition:
      'A program that focuses on leadership skills that can be applied to a business, government, non-profit, or educational setting. Includes instruction in organizational planning, dynamics of leadership, finance, team building, conflict resolution and mediation, communication and other management skills.',
    Examples: 'Examples: - Leadership Studies, - Nonprofit Leadership Studies',
  },
  {
    cipFamily: '52',
    cipCode: '52.0214',
    cipTitle: 'Research Administration',
    cipDefinition:
      'A program designed to prepare formally trained, advanced-level personnel for research administration leadership positions at colleges and universities, government agencies, hospitals, nonprofit agencies, and in industry. Includes instruction in bioethics, biostatistics, ethical, legal and regulatory consideration in clinical investigation, financial analysis, grant writing, managing and monitoring clinical trials, project management, strategic planning, and team building.',
    CrossReferences: '51.0720 - Regulatory Science/Affairs.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0215',
    cipTitle: 'Risk Management',
    cipDefinition:
      'A program that focuses on the principles of business risk measurement, mitigation, and management. Includes instruction in business analytics, foundations of insurance and risk management, investments, life and health insurance, loss prevention, property and liability insurance risk assessment, risk control, risk financing, and security.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0216',
    cipTitle: 'Science/Technology Management',
    cipDefinition:
      'A program that prepares individuals to develop science, technical, and business skills required for management of people and systems in technology-based industries, government agencies, and non-profit organizations. Includes instruction in computer applications, general management principles, production and operations management, project management, quality control, safety and health issues, and statistics.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0299',
    cipTitle: 'Business Administration, Management and Operations, Other',
    cipDefinition: 'Any instructional program in business and administration not listed above.',
    CrossReferences:
      '51.0701 - Health/Health Care Administration/Management., 51.0702 - Hospital and Health Care Facilities Administration/Management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.03',
    cipTitle: 'Accounting and Related Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.0301 - 52.0399.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0301',
    cipTitle: 'Accounting',
    cipDefinition:
      'A program that prepares individuals to practice the profession of accounting and to perform related business functions.  Includes instruction in accounting principles and theory, financial accounting, managerial accounting, cost accounting, budget control, tax accounting, legal aspects of accounting, auditing, reporting procedures, statement analysis, planning and consulting, business information systems, accounting research methods, professional standards and ethics, and applications to specific for-profit, public, and non-profit organizations.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0302',
    cipTitle: 'Accounting Technology/Technician and Bookkeeping',
    cipDefinition:
      'A program that prepares individuals to provide technical administrative support to professional accountants and other financial management personnel.  Includes instruction in posting transactions to accounts, record-keeping systems, accounting software operation, and general accounting principles and practices.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0303',
    cipTitle: 'Auditing',
    cipDefinition:
      'A program that prepares individuals, including certified accountants, to perform independent internal and external appraisals to evaluate organizational financial and operational activities, ensure compliance with laws and policies, safeguard assets, and promote effective planning and resource allocation.  Includes instruction in advanced accounting, audit tools and techniques, sampling, risk and control, audit planning, audit function management, law and regulations, environmental auditing, information technology applications, professional standards and ethics, and specific industry and service sector problems.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0304',
    cipTitle: 'Accounting and Finance',
    cipDefinition:
      'An integrated or combined program in accounting and finance that prepares individuals to function as accountants and financial managers or analysts.',
    CrossReferences: '52.0301 - Accounting., 52.0801 - Finance, General.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0305',
    cipTitle: 'Accounting and Business/Management',
    cipDefinition:
      'An integrated or combined program in accounting and business administration/management that prepares individuals to function as accountants and business managers.',
    CrossReferences: '52.0201 - Business Administration and Management, General., 52.0301 - Accounting.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0399',
    cipTitle: 'Accounting and Related Services, Other',
    cipDefinition: 'Any instructional program in accounting not listed above.',
    Examples: 'Examples: - Controllership',
  },
  {
    cipFamily: '52',
    cipCode: '52.04',
    cipTitle: 'Business Operations Support and Assistant Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.0401 - 52.0499.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0401',
    cipTitle: 'Administrative Assistant and Secretarial Science, General',
    cipDefinition:
      'A program that generally prepares individuals to perform the duties of administrative assistants and/or secretaries and stenographers.  Includes instruction in business communications, principles of business law, word processing and data entry, office machines operation and maintenance, office procedures, public relations, secretarial accounting, filing systems and records management, and report preparation.',
    CrossReferences:
      '22.0301 - Legal Administrative Assistant/Secretary., 51.0716 - Medical Administrative/Executive Assistant and Medical Secretary.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0402',
    cipTitle: 'Executive Assistant/Executive Secretary',
    cipDefinition:
      'A program that prepares individuals to perform the duties of special assistants and/or personal secretaries for business executives and top management.  Includes instruction in business communications, principles of business law, public relations, scheduling and travel management, secretarial accounting, filing systems and records management, conference and meeting recording, report preparation, office equipment and procedures, office supervisory skills, and professional standards and legal requirements.',
    CrossReferences: '51.0716 - Medical Administrative/Executive Assistant and Medical Secretary.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0406',
    cipTitle: 'Receptionist',
    cipDefinition:
      'A program that prepares individuals to perform frontline public relations duties for a business, organization, or answering service.  Includes instruction in telephone answering techniques, responding to information requests, keeping caller and/or visitor records, placing business calls, operating telephone switchboards and/or other communications equipment, relaying incoming and interoffice calls, schedule maintenance, and public relations skills.',
    CrossReferences: '51.0712 - Medical Reception/Receptionist., 1.8203 - Veterinary Reception/Receptionist.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0407',
    cipTitle: 'Business/Office Automation/Technology/Data Entry',
    cipDefinition:
      'A program that prepares individuals to support business information operations by using computer equipment to enter, process, and retrieve data for a wide variety of administrative purposes.  Includes instruction in using basic business software and hardware, business computer networking, principles of desktop publishing, preparing mass mailings, compiling and editing spreadsheets, list maintenance, preparing tables and graphs, receipt control, and preparing business performance reports.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0408',
    cipTitle: 'General Office Occupations and Clerical Services',
    cipDefinition:
      'A program that prepares individuals to provide basic administrative support under the supervision of office managers, administrative assistants, secretaries, and other office personnel.  Includes instruction in typing, keyboarding, filing, general business correspondence, office equipment operation, and communications skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0409',
    cipTitle: 'Parts, Warehousing, and Inventory Management Operations',
    cipDefinition:
      'A program that prepares individuals to provide administrative, technical, and managerial support in the operation of warehouses, control of inventory, parts identification, and the performance of counter services for customers.  Includes instruction in record-keeping, equipment operation, database entry, supply logistics, shop operations and math, and customer and supplier relations.',
  },
  {
    cipFamily: '52',
    cipCode: '52.041',
    cipTitle: 'Traffic, Customs, and Transportation Clerk/Technician',
    cipDefinition:
      'A program that prepares individuals to perform duties associated with managing revenue-based customs, traffic, or transportation services, such as control of domestic and international traffic, toll roads and waterways, and to assist in the dispatch and control of fleet-based traffic for businesses and public services. Includes instruction in record-keeping; preparation of customs and transportation documentation; operation of communications equipment; basic transportation operations management; tariffs, rates and fares; revenue collection and change-making; and interpretation of customs or transportation regulations and related legislation, policies, and procedures.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0411',
    cipTitle: 'Customer Service Support/Call Center/Teleservice Operation',
    cipDefinition:
      'A program that prepares individuals to assist customers with inquiries and problems in frontline call centers, help desks, teleservice centers, and online.  Includes instruction in user interfaces and user behavior, principles of hardware and software systems operation, customer interaction skills, telephone and e-mailing skills, data entry, and database and Internet searching and retrieval.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0499',
    cipTitle: 'Business Operations Support and Secretarial Services, Other',
    cipDefinition: 'Any instructional program in administrative and secretarial services not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.05',
    cipTitle: 'Business/Corporate Communications',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.0501 - 52.0599.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0501',
    cipTitle: 'Business/Corporate Communications, General',
    cipDefinition:
      'A program that prepares individuals to function in an organization as a composer, editor, and proofreader of business or business-related communications.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0502',
    cipTitle: 'Grantsmanship',
    cipDefinition:
      'A program that prepares individuals to develop and write grant proposals to support an organization or cause. Includes instruction in identifying grant opportunities, developing an effective proposal, budget justifications, and developing a working relationship with the funder.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0599',
    cipTitle: 'Business/Corporate Communications, Other',
    cipDefinition: 'Any program in business or corporate communications not included above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.06',
    cipTitle: 'Business/Managerial Economics',
    cipDefinition: 'Instructional content is defined in code 52.0601.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0601',
    cipTitle: 'Business/Managerial Economics',
    cipDefinition:
      'A program that focuses on the application of economics principles to the analysis of the organization and operation of business enterprises.  Includes instruction in monetary theory, banking and financial systems, theory of competition, pricing theory, wage and salary/incentive theory, analysis of markets, and applications of econometrics and quantitative methods to the study of particular businesses and business problems.',
  },
  {
    cipFamily: '52',
    cipCode: '52.07',
    cipTitle: 'Entrepreneurial and Small Business Operations',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.0701 - 52.0799.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0701',
    cipTitle: 'Entrepreneurship/Entrepreneurial Studies',
    cipDefinition:
      'A program that generally prepares individuals to perform development, marketing and management functions associated with owning and operating a business.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0702',
    cipTitle: 'Franchising and Franchise Operations',
    cipDefinition:
      'A program that prepares individuals to manage and operate franchises.  Includes instruction in legal requirements, set-up costs and capitalization requirements, financing, and applications to specific franchise opportunities.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0703',
    cipTitle: 'Small Business Administration/Management',
    cipDefinition:
      'A program that prepares individuals to develop and manage independent small businesses.  Includes instruction in business administration; enterprise planning and entrepreneurship; start-up; small business operations and problems; personnel supervision; capitalization and investment; taxation; business law and regulations; e-commerce; home business operations; and applications to specific sectors, products, and services.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0704',
    cipTitle: 'Social Entrepreneurship',
    cipDefinition:
      'A program that focuses on blending entrepreneurial ideas and management skills for issue advocacy and social change. Includes instruction in philanthropy, business modeling, design, entrepreneurship, finance, investing, resource management, project management, prototype development, and marketing.',
    CrossReferences: '13.0413 - Education Entrepreneurship.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0799',
    cipTitle: 'Entrepreneurial and Small Business Operations, Other',
    cipDefinition: 'Any instructional program in enterprise management and entrepreneurship not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.08',
    cipTitle: 'Finance and Financial Management Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.0801 - 52.0899.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0801',
    cipTitle: 'Finance, General',
    cipDefinition:
      'A program that generally prepares individuals to plan, manage, and analyze the financial and monetary aspects and performance of business enterprises, banking institutions, or other organizations.  Includes instruction in principles of accounting, financial instruments, capital planning, funds acquisition, asset and debt management, budgeting, financial analysis, and investments and portfolio management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0803',
    cipTitle: 'Banking and Financial Support Services',
    cipDefinition:
      'A program that prepares individuals to perform a wide variety of customer services in banks, insurance agencies, savings and loan companies, and related enterprises.  Includes instruction in communications and public relations skills, business equipment operation, and technical skills applicable to the methods and operations of specific financial or insurance services.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0804',
    cipTitle: 'Financial Planning and Services',
    cipDefinition:
      'A program that prepares individuals to plan and manage the financial interests and growth of individuals and institutions.  Includes instruction in portfolio management, investment management, estate planning, insurance, tax planning, strategic investing and planning, financial consulting services, and client relations.',
    CrossReferences: '19.0401 - Family Resource Management Studies, General.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0806',
    cipTitle: 'International Finance',
    cipDefinition:
      'A program that prepares individuals to manage international financial operations and related currency transactions.  Includes instruction in international banking, international monetary and financial policy, money and capital markets, foreign exchange, risk analysis, and international cash flow operations.',
    CrossReferences: '45.0605 - International Economics.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0807',
    cipTitle: 'Investments and Securities',
    cipDefinition:
      'A program that prepares individuals to manage assets placed in capital markets, and related technical operations.  Includes instruction in security analysis, debt and equity analysis, investment strategies, securities markets, computer-assisted research, portfolio management, portfolio performance analysis, and applications to specific investment problems and business situations.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0808',
    cipTitle: 'Public Finance',
    cipDefinition:
      'A program that prepares individuals to manage the financial assets and budgets of public sector organizations.  Includes instruction in public trusts and investments; the laws and procedures used to plan, prepare and administer public agency budgets; and the preparation and analysis of public budget projections and policies.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0809',
    cipTitle: 'Credit Management',
    cipDefinition:
      'A program that prepares individuals to perform and/or manage operations concerning personal and corporate credit, collateral, loan processing, and related financial agency communications.  Includes instruction in general finance and banking principles, insurance, real estate, taxation, business law and regulations, quantitative methods, financial computer systems applications, database management, communications skills, business and office management, and professional standards and ethics.',
    Examples: 'Examples: - Mortgage Broker',
  },
  {
    cipFamily: '52',
    cipCode: '52.081',
    cipTitle: 'Financial Risk Management',
    cipDefinition:
      'A program that focuses on the strategies used to define and manage acceptable financial risk for companies. Includes instruction in banking and bank regulations, derivative securities, interest rate and credit markets, financial engineering of systematic risk, and equity, bond, futures, and options markets.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0899',
    cipTitle: 'Finance and Financial Management Services, Other',
    cipDefinition: 'Any instructional program in financial management and services not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.09',
    cipTitle: 'Hospitality Administration/Management',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.0901 - 52.0999.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0901',
    cipTitle: 'Hospitality Administration/Management, General',
    cipDefinition:
      'A program that prepares individuals to serve as general managers and directors of hospitality operations on a system-wide basis, including both travel arrangements and promotion and the provision of traveler facilities.  Includes instruction in principles of operations in the travel and tourism, hotel and lodging facilities, food services, and recreation facilities industries; hospitality marketing strategies; hospitality planning; management and coordination of franchise and unit operations; business management; accounting and financial management; hospitality transportation and logistics; and hospitality industry policies and regulations.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0903',
    cipTitle: 'Tourism and Travel Services Management',
    cipDefinition:
      'A program that prepares individuals to manage travel-related enterprises and related convention and/or tour services.  Includes instruction in travel agency management, tour arranging and planning, convention and event planning, travel industry operations and procedures, tourism marketing and promotion strategies, travel counseling, travel industry law, international and domestic operations, and travel and tourism policy.',
    CrossReferences: '3.0207 - Environmental/Natural Resource Recreation and Tourism.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0904',
    cipTitle: 'Hotel/Motel Administration/Management',
    cipDefinition:
      'A program that prepares individuals to manage operations and facilities that provide lodging services to the traveling public. Includes instruction in hospitality industry principles; supplies purchasing, storage and control; hotel facilities design and planning; hospitality industry law; personnel management and labor relations; financial management; marketing and sales promotion; convention and event management; front desk operations; and applications to specific types of hotels and motel operations.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0905',
    cipTitle: 'Restaurant/Food Services Management',
    cipDefinition:
      'A program that prepares individuals to plan, manage, and market restaurants, food services in hospitality establishments, food service chains and franchise networks, and restaurant supply operations.  Includes instruction in hospitality administration, food services management, wholesale logistics and distribution, franchise operations, business networking, personnel management, culinary arts, business planning and capitalization, food industry operations, marketing and retailing, business law and regulations, finance, and professional standards and ethics.',
    CrossReferences:
      '12.0504 - Restaurant, Culinary, and Catering Management/Manager., 19.0505 - Foodservice Systems Administration/Management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0906',
    cipTitle: 'Resort Management',
    cipDefinition:
      'A program that prepares individuals to plan, manage, and market comprehensive vacation facilities and services and related products.  Includes instruction in hospitality administration, hotel/motel management, restaurant and food services management, facilities planning, leisure studies, recreation administration, marketing, recreation equipment and grounds operations and maintenance, business finance, insurance and taxation, event management and guest services, personnel management, travel and logistics management, safety and health services, professional standards and ethics, and applications to specific vacation types and locations.',
    Examples: 'Examples: - Spa Management, - Resort and Attraction Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.0907',
    cipTitle: 'Meeting and Event Planning',
    cipDefinition:
      'A program that prepares individuals to plan, budget, and implement conferences, meetings, and other special events in the public or private sectors. Includes instruction in principles of meeting and event planning; special event management; budgets and finance; site selection; contracts, vendors, and negotiations; marketing and promotions; food and beverage management; audio-visual basics and meeting technology; and hospitality law.',
    CrossReferences: '19.0604 - Facilities Planning and Management.',
    Examples: 'Examples: - Conference Management, - Conference Planning, - Wedding Planning',
  },
  {
    cipFamily: '52',
    cipCode: '52.0908',
    cipTitle: 'Casino Management',
    cipDefinition:
      'A program that prepares individuals to manage casinos and gaming establishments. Includes instruction in business and financial management; casino operations; casino security and surveillance; hospitality, facilities, and personnel management; and principles of the gaming industry, ethics, and gaming law.',
    CrossReferences: '12.0601 - Casino Operations and Services, General.',
    Examples: 'Examples: - Casino Management, - Gaming and Casino Management, - Hospitality and Casino Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.0909',
    cipTitle: 'Hotel, Motel, and Restaurant Management',
    cipDefinition:
      'An instructional program that prepares individuals to manage operations and facilities that provide food and/or lodging services to the traveling public. Includes instruction in hospitality industry principles; supplies purchasing, storage, and control; hotel and restaurant facilities design and planning; hospitality industry law; personnel management and labor relations; financial management; facilities management; marketing and sale promotion strategies; convention and event management; front desk operations; and applications to specific types of hotel, motel, and/or restaurant operations. Note: this program was re-instated after being deleted from CIP 2000; previously, it was coded as 52.0902.',
    CrossReferences: '52.0904 - Hotel/Motel Administration/Management., 52.0905 - Restaurant/Food Services Management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.091',
    cipTitle: 'Brewery/Brewpub Operations/Management',
    cipDefinition:
      'A program that prepares individuals to plan, manage, and market breweries and brewpubs. Includes instruction in brewing science, food services management, logistics and distribution, business networking, personnel management, business planning and capitalization, brewing industry operations, marketing, alcohol and liquor law and regulations, finance, and ethics.',
    CrossReferences: '1.1003 - Brewing Science., 1.1005 - Zymology/Fermentation Science.',
  },
  {
    cipFamily: '52',
    cipCode: '52.0999',
    cipTitle: 'Hospitality Administration/Management, Other',
    cipDefinition: 'Any instructional program in hospitality service management not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.10',
    cipTitle: 'Human Resources Management and Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.1001 - 52.1099.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1001',
    cipTitle: 'Human Resources Management/Personnel Administration, General',
    cipDefinition:
      'A program that generally prepares individuals to manage the development of human capital in organizations, and to provide related services to individuals and groups.  Includes instruction in personnel and organization policy, human resource dynamics and flows, labor relations, sex roles, civil rights, human resources law and regulations, motivation and compensation systems, work systems, career management, employee testing and assessment, recruitment and selection, managing employee and job training programs, and the management of human resources programs and operations.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1002',
    cipTitle: 'Labor and Industrial Relations',
    cipDefinition:
      'A program that focuses on employee-management interactions and the management of issues and disputes regarding working conditions and worker benefit packages, and that may prepare individuals to function as labor or personnel relations specialists.  Includes instruction in labor history, policies and strategies of the labor movement, union organization, labor-management negotiation, labor law and contract interpretation, labor economics, welfare and benefit packages, grievance procedures, and labor policy studies.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1003',
    cipTitle: 'Organizational Behavior Studies',
    cipDefinition:
      'A program that focuses on the scientific study of the behavior and motivations of individuals functioning in organized groups, and its application to business and industrial settings.  Includes instruction in organization theory, industrial and organizational psychology, social psychology, sociology of organizations, reinforcement and incentive theory, employee relations strategies, organizational power and influence, organization stratification and hierarchy, leadership styles, and applications of operations research and other methodologies to organizational analysis.',
    CrossReferences:
      '42.2804 - Industrial and Organizational Psychology., 9.0901 - Organizational Communication, General.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1004',
    cipTitle: 'Labor Studies',
    cipDefinition:
      'A program that focuses on the study of various aspects of work, labor organization and policy, and labor movements from the perspective of the social sciences and history.  Includes instruction in labor history; political and ideological issues; worker movements and social organizations; civil rights; labor law and policy studies; labor economics; international and comparative labor studies; and applications to specific industries, groups, locations, and time periods.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1005',
    cipTitle: 'Human Resources Development',
    cipDefinition:
      'A program that prepares individuals to apply integrated training, organizational development, and career planning and counseling skills to the design, management, and evaluation of programs to improve individual productivity, employability, and job satisfaction and organizational effectiveness.  Includes instruction in psychology; organizational behavior; principles of adult education; occupational counseling; skill testing and evaluation; program design; consulting practice; organizational development; and applications to issues such as training, management development, customer service, and total quality management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1006',
    cipTitle: 'Executive/Career Coaching',
    cipDefinition:
      'A program of study that focuses on assisting individuals achieve career goals or assisting business leaders achieve organizational change. Includes instruction in executive coaching, group dynamics, leadership, management, organizational behavior, organizational communication, and team building.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1099',
    cipTitle: 'Human Resources Management and Services, Other',
    cipDefinition: 'Any instructional program in human resources management not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.11',
    cipTitle: 'International Business',
    cipDefinition: 'Instructional content is defined in code 52.1101.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1101',
    cipTitle: 'International Business/Trade/Commerce',
    cipDefinition:
      'A program that prepares individuals to manage international businesses and/or business operations.  Includes instruction in the principles and processes of export sales, trade controls, foreign operations and related problems, monetary issues, international business policy, and applications to doing business in specific countries and markets.',
    CrossReferences: '45.0605 - International Economics.',
    Examples: 'Examples: - Global Business, - Global Commerce, - Global Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.12',
    cipTitle: 'Management Information Systems and Services',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.1201 - 52.1299.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1201',
    cipTitle: 'Management Information Systems, General',
    cipDefinition:
      'A program that generally prepares individuals to provide and manage data systems and related facilities for processing and retrieving internal business information; select systems and train personnel; and respond to external data requests.  Includes instruction in cost and accounting information systems, management control systems, personnel information systems, data storage and security, business systems networking, report preparation, computer facilities and equipment operation and maintenance, operator supervision and training, and management information systems policy and planning.',
    CrossReferences: '11.0401 - Information Science/Studies.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1206',
    cipTitle: 'Information Resources Management',
    cipDefinition:
      'A program that prepares individuals to apply principles of information technology, computer systems management, and business operations to the planning, management, and evaluation of information services in organizations.  Includes instruction in telecommunications, systems planning and integration, information policy, information security, contracting and purchasing, budgeting, information technology, operations management, human resources, communications skills, and applicable law and regulations.',
    Examples: 'Examples: - Information Management, - Chief Information Officer Training',
  },
  {
    cipFamily: '52',
    cipCode: '52.1207',
    cipTitle: 'Knowledge Management',
    cipDefinition:
      'A program that focuses on the study of knowledge management in government agencies and corporations for the purpose of supporting stated organizational goals and objectives, and prepares individuals to function as information resource managers.  Includes instruction in information technology, principles of computer and information systems, management information systems, applicable policy and regulations, and operations and personnel management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1299',
    cipTitle: 'Management Information Systems and Services, Other',
    cipDefinition: 'Any  program in business information and data processing services not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.13',
    cipTitle: 'Management Sciences and Quantitative Methods',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.1301 - 52.1399.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1301',
    cipTitle: 'Management Science',
    cipDefinition:
      'A general program that focuses on the application of statistical modeling, data warehousing, data mining, programming, forecasting and operations research techniques to the analysis of problems of business organization and performance. Includes instruction in optimization theory and mathematical techniques, data mining, data warehousing, stochastic and dynamic modeling, operations analysis, and the design and testing of prototype systems and evaluation models.',
    Examples: 'Examples: - Business Intelligence, - Competitive Intelligence',
  },
  {
    cipFamily: '52',
    cipCode: '52.1302',
    cipTitle: 'Business Statistics',
    cipDefinition:
      'A program that focuses on the application of mathematical statistics to the description, analysis, and forecasting of business data.  Includes instruction in statistical theory and methods, computer applications, data analysis and display, long- and short-term forecasting methods, and market performance analysis.',
    CrossReferences: '27.0501 - Statistics, General., 30.7102 - Business Analytics.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1304',
    cipTitle: 'Actuarial Science',
    cipDefinition:
      'A program that focuses on the mathematical and statistical analysis of risk, and their applications to insurance and other business management problems.  Includes instruction in forecasting theory, quantitative and non-quantitative risk measurement methodologies, development of risk tables, secondary data analysis, and computer-assisted research methods.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1399',
    cipTitle: 'Management Sciences and Quantitative Methods, Other',
    cipDefinition:
      'Any instructional program in business quantitative methods and management science not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.14',
    cipTitle: 'Marketing',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.1401 - 52.1499.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1401',
    cipTitle: 'Marketing/Marketing Management, General',
    cipDefinition:
      'A program that generally prepares individuals to undertake and manage the process of developing consumer audiences and moving products from producers to consumers.  Includes instruction in buyer behavior and dynamics, principle of marketing research, demand analysis, cost-volume and profit relationships, pricing theory, marketing campaign and strategic planning, market segments, advertising methods, sales operations and management, consumer relations, retailing, and applications to specific products and markets.',
    Examples: 'Examples: - Social Media Marketing',
  },
  {
    cipFamily: '52',
    cipCode: '52.1402',
    cipTitle: 'Marketing Research',
    cipDefinition:
      'A program that prepares individuals to provide analytical descriptions of consumer behavior patterns and market environments to marketing managers and other business decision-makers.  Includes instruction in survey research methods, research design, new product test marketing, exploratory marketing, consumer needs and preferences analysis, geographic analysis, and applications to specific products and markets.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1403',
    cipTitle: 'International Marketing',
    cipDefinition:
      'A program that prepares individuals to perform marketing activities in enterprises primarily engaged in exporting or importing goods and services in world markets.  Includes instruction in international trade controls, foreign trade operations, locating markets, negotiation practices, monetary issues, and international public relations.',
    CrossReferences: '45.0605 - International Economics.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1404',
    cipTitle: 'Digital Marketing',
    cipDefinition:
      'A program that prepares individuals to develop a digital marketing plan and integrate marketing, advertising, sales, and logistics across physical and digital channels. Includes instruction in digital marketing, e-commerce, online consumer psychology, search engine optimization, social media marketing, and web analytics.',
    Examples: 'Examples: - Digital Innovation in Marketing',
  },
  {
    cipFamily: '52',
    cipCode: '52.1499',
    cipTitle: 'Marketing, Other',
    cipDefinition: 'Any instructional program in general marketing and marketing research not listed above.',
    CrossReferences: '51.2011 - Pharmaceutical Marketing and Management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.15',
    cipTitle: 'Real Estate',
    cipDefinition: 'Instructional content is defined in code 52.1501.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1501',
    cipTitle: 'Real Estate',
    cipDefinition:
      'A program that prepares individuals to develop, buy, sell, appraise, and manage real estate. Includes instruction in land use development policy, real estate law, real estate marketing procedures, agency management, brokerage, property inspection and appraisal, real estate investing, leased and rental properties, commercial real estate, and property management.',
    CrossReferences: '4.1001 - Real Estate Development.',
    Examples: 'Examples: - Property Management',
  },
  {
    cipFamily: '52',
    cipCode: '52.16',
    cipTitle: 'Taxation',
    cipDefinition: 'Instructional content is defined in code 52.1601.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1601',
    cipTitle: 'Taxation',
    cipDefinition:
      'A program that prepares individuals to provide tax advice and management services to individuals and corporations.  Includes instruction in tax law and regulations, tax record systems, individual and corporate income taxation, tax planning, partnerships and fiduciary relationships, estates and trusts, property depreciation, capital gains and losses, dispositions, transfers, liquidity, valuation, and applications to specific tax problems.',
  },
  {
    cipFamily: '52',
    cipCode: '52.17',
    cipTitle: 'Insurance',
    cipDefinition: 'Instructional content is defined in code 52.1701.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1701',
    cipTitle: 'Insurance',
    cipDefinition:
      'A program that prepares individuals to manage risk in organizational settings and provide insurance and risk-aversion services to businesses, individuals, and other organizations. Includes instruction in  casualty insurance and general liability, property insurance, employee benefits, social and health insurance, loss adjustment, underwriting, risk theory, and pension planning.',
  },
  {
    cipFamily: '52',
    cipCode: '52.18',
    cipTitle: 'General Sales, Merchandising and Related Marketing Operations',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.1801 - 52.1899.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1801',
    cipTitle: 'Sales, Distribution, and Marketing Operations, General',
    cipDefinition:
      'A program that focuses on the general process and techniques of direct wholesale and retail buying and selling operations and introduces individuals to related careers.  Includes instruction in the principles of entrepreneurial economics, basic sales skills, the distribution channels for goods and services, and supervised practical application experiences.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1802',
    cipTitle: 'Merchandising and Buying Operations',
    cipDefinition:
      'A program that prepares individuals to function as professional buyers of resale products and product lines for stores, chains, and other retail enterprises.  Includes instruction in product evaluation, merchandising, applicable aspects of brand and consumer research, principles of purchasing, and negotiation skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1803',
    cipTitle: 'Retailing and Retail Operations',
    cipDefinition:
      'A program that prepares individuals to perform operations associated with retail sales in a variety of settings.  Includes instruction in over-the-counter and other direct sales operations in business settings, basic bookkeeping principles, customer service, team/staff leadership and supervision, floor management, and applicable technical skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1804',
    cipTitle: 'Selling Skills and Sales Operations',
    cipDefinition:
      'A program that prepares individuals to possess the skills associated with direct promotion of products and services to potential customers and to function as independent sales representatives and managers.  Includes instruction in consumer psychology, image projection, public speaking and interpersonal communications, sales organization and operations, customer relations, professional standards and ethics, and applicable technical skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1880',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1899',
    cipTitle: 'General Merchandising, Sales, and Related Marketing Operations, Other',
    cipDefinition:
      'Any instructional program in merchandising, sales, and related marketing operations not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.19',
    cipTitle: 'Specialized Sales, Merchandising and  Marketing Operations',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.1901 - 52.1999.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1901',
    cipTitle: 'Auctioneering',
    cipDefinition:
      'A program that prepares individuals for professional careers as auctioneers and auction managers, and for meeting applicable state licensing requirements.  Includes instruction in bid calling, public speaking, ringworking, auction techniques, salesmanship skills, auction clerking and cashiering, auction advertising, working independently or with auction houses, contracts and agency, applicable sales law, and managing both general auctions and auctions specializing in commodities such as antiques, consignments, farm equipment, industrial equipment, real estate, livestock, and automobiles.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1902',
    cipTitle: 'Fashion Merchandising',
    cipDefinition:
      'A program that prepares individuals to promote product lines/brands, and organize promotional campaigns, at the wholesale level to attract retailer interest, wholesale purchasing, and supply contracts.  Includes instruction in wholesaling, wholesale advertising, selling, and customer relations.',
    CrossReferences: '19.0905 - Apparel and Textile Marketing Management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1903',
    cipTitle: 'Fashion Modeling',
    cipDefinition:
      'A program that prepares individuals to present and display fashion, apparel, and accessories products in wholesale and retail settings, either on the person or via props and natural or artificial settings.  Includes instruction in modeling skills, health and cosmetic principles, fashion show management, display design and production, and applicable aspects of advertising and fashion photography.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1904',
    cipTitle: 'Apparel and Accessories Marketing Operations',
    cipDefinition:
      'A program that prepares individuals to perform operations connected with the distribution and sale of apparel and accessories. Includes instruction in wholesaling and retailing, apparel technologies, distribution, customer sales and service skills, and principles of consumer research and sales forecasting.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1905',
    cipTitle: 'Tourism and Travel Services Marketing Operations',
    cipDefinition:
      'A program that prepares individuals to provide direct retail services to hotel and motel clients and customers in a variety of settings.  Includes instruction in the principles of hotel/motel operations, customer sales and assistance operations and techniques, telephone operations, and basic office management.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1906',
    cipTitle: 'Tourism Promotion Operations',
    cipDefinition:
      'A program that prepares individuals to perform marketing and sales operations connected with the promotion of tourism in public and private sector settings.  Includes instruction in principles of marketing research and advertising, promotional campaign organization, media relations, and applicable technical and administrative skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1907',
    cipTitle: 'Vehicle and Vehicle Parts and Accessories Marketing Operations',
    cipDefinition:
      'A program that prepares individuals to perform operations connected with the distribution and sale of replacement parts and other end-use supplies in the automotive, marine, and aviation industries.  Includes instruction in wholesaling and retailing, principles of vehicular technologies, storage and distribution, customer sales and service skills, and principles of consumer research and sales forecasting.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1908',
    cipTitle: 'Business and Personal/Financial Services Marketing Operations',
    cipDefinition:
      'A program that prepares individuals to perform marketing and operational tasks associated with the provision of personal and financial services.  Includes instruction in banking, marketing research, advertising, promotional campaign organization, insurance, media relations, and applicable technical and administrative skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1909',
    cipTitle: 'Special Products Marketing Operations',
    cipDefinition:
      'A program that prepares individuals to perform marketing and sales operations connected with the promotion of special products, including floristry, food, and home and office products. Includes instruction in principles of marketing research and advertising, promotional campaign organization, media relations, and applicable technical and administrative skills.',
  },
  {
    cipFamily: '52',
    cipCode: '52.191',
    cipTitle: 'Hospitality and Recreation Marketing Operations',
    cipDefinition:
      'A program that prepares individuals to provide marketing services in the hospitality and leisure fields. Includes instruction in hospitality operations, customer sales and assistance operations and techniques, telephone operations, basic office management, retail sports, recreation equipment, food and beverage.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1980',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '52',
    cipCode: '52.1999',
    cipTitle: 'Specialized Merchandising, Sales, and Marketing Operations, Other',
    cipDefinition: 'Any instructional program in sales, marketing, and distribution operations not listed above.',
    Examples: 'Examples: - Industrial Marketing, - Scientific and Medical Marketing',
  },
  {
    cipFamily: '52',
    cipCode: '52.20',
    cipTitle: 'Construction Management',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 52.2001 - 52.2099.',
  },
  {
    cipFamily: '52',
    cipCode: '52.2001',
    cipTitle: 'Construction Management, General',
    cipDefinition:
      'A program that prepares individuals to manage, coordinate, and supervise the construction process. Includes instruction in commercial, residential, mechanical, highway/heavy civil, electrical, environmental, industrial, and specialty construction; facilities management; budgeting and cost control; logistics and materials management; organization and scheduling; personnel management and labor relations; site safety; construction contracting; construction processes and techniques; and applicable codes and regulations.',
    CrossReferences: '46.0412 - Building/Construction Site Management/Manager.',
  },
  {
    cipFamily: '52',
    cipCode: '52.2002',
    cipTitle: 'Construction Project Management',
    cipDefinition:
      'A program that prepares individuals to apply project management knowledge, skills, tools, and techniques in the construction and facility management industries. Includes instruction in facilities operations and maintenance, construction estimating, OSHA standards, sustainability, drafting, construction plans, project planning, risk management, cost and time management, contracts and procurement, accounting, statistics, decision making, and human resources.',
  },
  {
    cipFamily: '52',
    cipCode: '52.2099',
    cipTitle: 'Construction Management, Other',
    cipDefinition: 'Any program in construction management not listed above.',
  },
  {
    cipFamily: '52',
    cipCode: '52.21',
    cipTitle: 'Telecommunications Management',
    cipDefinition: 'Instructional content is defined in code 52.2101.',
  },
  {
    cipFamily: '52',
    cipCode: '52.2101',
    cipTitle: 'Telecommunications Management',
    cipDefinition:
      'A program that prepares individuals to apply business skills to design, implement, and manage the voice, video, and data networking systems of organizations. Includes instruction in telecommunications concepts and technologies, network operations and management, wireless communications and mobile computing, cybersecurity, regulation and public policy, business practices and management, and written and oral communications.',
  },
  {
    cipFamily: '52',
    cipCode: '52.99',
    cipTitle: 'Business, Management, Marketing, and Related Support Services, Other',
    cipDefinition: 'Instructional content is defined in code 52.9999.',
  },
  {
    cipFamily: '52',
    cipCode: '52.9999',
    cipTitle: 'Business, Management, Marketing, and Related Support Services, Other',
    cipDefinition:
      'Any instructional program in business, management, marketing and related support services not listed above.',
  },
  {
    cipFamily: '53',
    cipCode: '53',
    cipTitle: 'High School/Secondary Diplomas and Certificates',
    cipDefinition:
      'Instructional programs that define the prescribed requirements for high school/secondary school graduation. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.01',
    cipTitle: 'High School/Secondary Diploma Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 53.0101 - 53.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0101',
    cipTitle: 'Regular/General High School/Secondary Diploma Program',
    cipDefinition:
      'An instructional program that defines the prescribed minimum requirements specified by a state, province, or other jurisdiction for completion of, and graduation from an undifferentiated regular secondary school program representing 12 academic years of schooling intended for school-age students. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0102',
    cipTitle: 'College/University Preparatory and Advanced High School/Secondary Diploma Program',
    cipDefinition:
      'An instructional program that defines the prescribed requirements, specified by a state, province, or other jurisdiction, for completion of, and graduation from, a secondary school program of academic subject matter designed to meet typical college and university entrance requirements. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0103',
    cipTitle: 'Vocational High School and Secondary Business/Vocational-Industrial/Occupational Diploma Program',
    cipDefinition:
      'An instructional program that defines the prescribed requirements, specified by a state, province, or other jurisdiction, for high school/secondary school graduation in a vocational program -- together with other required subject matter, and that may prepare individuals for specific occupations or direct labor market entry. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0104',
    cipTitle: 'Honors/Regents High School/Secondary Diploma Program',
    cipDefinition:
      'An instructional program that defines the prescribed requirements, specified by a state, province, or other jurisdiction for high school/secondary school graduation in an academic or vocational program at a prescribed level of outstanding scholastic performance, or via meeting special requirements beyond the minimum. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0105',
    cipTitle: 'Adult High School/Secondary Diploma Program',
    cipDefinition:
      'An instructional program that defines the prescribed requirements, specified by a state, province, or other jurisdiction for the completion of, and graduation from a secondary school program of academic subject matter offered for adult learners outside of the regular secondary school program.  This does not include adult compensatory education programs resulting in completion of a high school equivalency certificate or diploma. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0199',
    cipTitle: 'High School/Secondary Diploma Programs, Other',
    cipDefinition:
      'Any high school/secondary diploma program not listed above, such as diplomas awarded to special education students for completion of an individualized education plan (IEP). This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.02',
    cipTitle: 'High School/Secondary Certificate Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 53.0201 - 53.0299. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0201',
    cipTitle: 'High School Equivalence Certificate Program',
    cipDefinition:
      'An instructional program that defines the requirements for meeting the minimum high school/secondary completion requirements specified by a U.S. state or other jurisdiction, for adult learners who did not complete secondary school.  Includes undertaking a specified program of studies and obtaining a prescribed passing score on the General Educational Development Test (GED) or provincial examinations.  These awards represent the legally recognized equivalent of secondary school graduation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0202',
    cipTitle: 'High School Certificate of Competence Program',
    cipDefinition:
      'An instructional program that defines the content requirements for meeting specified performance standards in various secondary school studies that does not represent completion of and graduation from secondary school, as prescribed by a U.S. state or other jurisdiction.  Includes such requirements as passing a state- or province-mandated academic achievement test and completing other specified requirements for subject-matter competence.  These awards may be given for partial completion of secondary school or for achieving a specified high level of concentration and/or performance in certain subjects, and may be awarded independently or in conjunction with a high school/secondary diploma. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0203',
    cipTitle: 'Certificate of IEP Completion Program',
    cipDefinition:
      'An instructional program that defines the requirements for meeting specified goals pertaining to an Individual Educational Program established by a local school system under the authority of a state or other jurisdiction.  Specific content varies by student and may or may not equal a regular high school/secondary school program. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '53',
    cipCode: '53.0299',
    cipTitle: 'High School/Secondary Certificates, Other',
    cipDefinition:
      'Any certificate program at the high school/secondary instructional level not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '54',
    cipCode: '54',
    cipTitle: 'History',
    cipDefinition:
      'Instructional programs that focus on the study and interpretation of past events, institutions, issues, and cultures.',
  },
  {
    cipFamily: '54',
    cipCode: '54.01',
    cipTitle: 'History',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 54.0101 - 54.0199.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0101',
    cipTitle: 'History, General',
    cipDefinition:
      'A program that focuses on the general study and interpretation of the past, including the gathering, recording, synthesizing and criticizing of evidence and theories about past events.  Includes instruction in historiography; historical research methods; studies of specific periods, issues and cultures; and applications to areas such as historic preservation, public policy, and records administration.',
    CrossReferences: '30.4501 - History and Language/Literature., 30.4601 - History and Political Science.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0102',
    cipTitle: 'American  History (United States)',
    cipDefinition:
      'A program that focuses on the development of American society, culture, and institutions from the Pre-Columbian period to the present.  Includes instruction in American historiography, American history sources and materials, historical research methods, and applications to the study of specific themes, issues, periods, and institutions.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0103',
    cipTitle: 'European History',
    cipDefinition:
      'A program that focuses on the development of European society, culture, and institutions from the origins to the present.  Includes instruction in European historiography, European history sources and materials, historical research methods, and applications to the study of specific themes, issues, periods, and institutions.',
    CrossReferences: '30.1301 - Medieval and Renaissance Studies.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0104',
    cipTitle: 'History and Philosophy of Science and Technology',
    cipDefinition:
      'A program that focuses on the historical evolution of scientific theories, science applications and technologies, and the scientific, medical, and technological disciplines, as well as the philosophy of science and its historical socio-economic context. Includes instruction in the concepts and methods of philosophical inquiry; historiography of science; history of science, medicine, and technology; and research methods in the history of the scientific and engineering disciplines, including mathematics.',
    CrossReferences: '30.1501 - Science, Technology and Society., 51.3205 - History of Medicine.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0105',
    cipTitle: 'Public/Applied History',
    cipDefinition:
      'A program that focuses on the application of history and administrative skills to the recording of public events and the management of related historical resources. Includes instruction in historical research methods, the planning and administration of public history projects, and applications to specific problems in public organizations, government agencies, foundations, and other facilities.',
    CrossReferences:
      '30.1202 - Cultural Resource Management and Policy Analysis., 25.0103 - Archives/Archival Administration.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0106',
    cipTitle: 'Asian History',
    cipDefinition:
      'A program that focuses on the development of the societies, cultures, and institutions of the Asian Continent from their origins to the present.  Includes instruction in the historiography of specific cultures and periods; sources and materials; historical research methods; and applications to the study of specific themes, issues, periods, and institutions.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0107',
    cipTitle: 'Canadian History',
    cipDefinition:
      'A program that focuses on the study of the society, culture, and institutions of Canada from its origins to the present.  Includes instruction in Canadian historiography, sources and materials, historical research methods, and applications to the study of specific themes, issues, periods, and institutions.',
  },
  {
    cipFamily: '54',
    cipCode: '54.0108',
    cipTitle: 'Military History',
    cipDefinition:
      'A program that focuses on the study of the history of warfare and military institutions in their operational, politico-economic and socio-cultural contexts; including airpower history, naval history, and military history.  Includes instruction in military organization, military operations, the history of technology, leadership and biography, military psychology and behavior, international relations and politics, economics, aspects of general and regional history, cultural history and anthropology, historiography, and applicable quantitative methods.',
    Examples: 'Examples: - Air Force History, - Naval History, - Military History (Armies)',
  },
  {
    cipFamily: '54',
    cipCode: '54.0199',
    cipTitle: 'History, Other',
    cipDefinition: 'Any instructional program in history not listed above.',
    CrossReferences: '30.4501 - History and Language/Literature., 30.4601 - History and Political Science.',
  },
  {
    cipFamily: '55',
    cipCode: '55',
    cipTitle: 'RESERVED',
    cipDefinition: 'Reserved for use by Statistics Canada. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.01',
    cipTitle: 'Reserved',
    cipDefinition: 'Instructional content is defined in code 55.0101.',
  },
  {
    cipFamily: '55',
    cipCode: '55.0101',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.13',
    cipTitle: 'Reserved',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 55.1301 - 55.1399.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1301',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1302',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1303',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1304',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1399',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.14',
    cipTitle: 'Reserved',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 55.1401 - 55.1499.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1401',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1403',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1404',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1405',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.1499',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '55',
    cipCode: '55.99',
    cipTitle: 'Reserved',
    cipDefinition: 'Instructional content is defined in code 55.9999.',
  },
  {
    cipFamily: '55',
    cipCode: '55.9999',
    cipTitle: 'Reserved',
    cipDefinition: 'Reserved for use by Statistics Canada. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60',
    cipTitle: 'Health Professions Residency/Fellowship Programs',
    cipDefinition:
      'Programs that prepare dentists (DDS, DMD), nurse practitioners, pharmacists (PharmD), physician assistants, and veterinarians (DVM) for certification as practitioners of recognized specialties in their respective professions. These programs are approved and accredited by designated professional associations. Residency or fellowship programs that also result in the completion of an academic degree (e.g., MS, PhD), should be reported under the appropriate CIP code, rather than in a residency code located in Series 60. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.01',
    cipTitle: 'Dental Residency/Fellowship Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 60.0101 - 60.0199. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0101',
    cipTitle: 'Oral and Maxillofacial Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists in the diagnosis, and surgical and related treatment, of diseases, injuries, and defects involving both the functional and esthetic aspects of the hard and soft tissues of the head, mouth, teeth, gums, jaws, and neck. Includes treatment of problem wisdom teeth, facial pain, misaligned jaws, and reconstructive and dental implant surgery. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Dental/Oral Surgery Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0102',
    cipTitle: 'Dental Public Health Residency Program',
    cipDefinition:
      'A residency-training program that prepares dentists in the formulation and delivery of public preventive and curative dental health services. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0103',
    cipTitle: 'Endodontics Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists in the etiology, diagnosis, prevention, and treatment of conditions that affect the dental and other periodontal tissues, including pulp canal therapy and root canal therapy. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0104',
    cipTitle: 'Oral and Maxillofacial Pathology Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists in the diagnosis and management of diseases affecting the oral and maxillofacial regions, using clinical, radiographic, microscopic, biochemical, or other examinations. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Oral Pathology Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0105',
    cipTitle: 'Orthodontics Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists in the principles and techniques involved in the prevention and correction of dental malocclusions and oral cavity anomalies. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0106',
    cipTitle: 'Pediatric Dentistry Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists to provide primary oral health care to infants, children, and adolescents, including patients with medical, physical or mental disabilities, and to treat a wide variety of dental problems for these patients. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Pedodontics Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0107',
    cipTitle: 'Periodontology Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists in the prevention, diagnosis, and treatment of diseases affecting the gums and supporting structures of the teeth, and in the placement and maintenance of dental implants. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Periodontics Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0108',
    cipTitle: 'Prosthodontics Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists in the principles and techniques of constructing oral prostheses, and the restoration and maintenance of oral function by the replacement of missing teeth and other oral structures with such artificial devices. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0109',
    cipTitle: 'Oral and Maxillofacial Radiology Residency Program',
    cipDefinition:
      'A residency training program that prepares dentists to produce and interpret images of the jaws, teeth, bone, and other structures of the head and neck region; and to diagnose and manage diseases, disorders, and conditions that affect this area of the body. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.011',
    cipTitle: 'Implantology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares dentists in the surgical and restorative aspects of implant dentistry. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0199',
    cipTitle: 'Dental Residency/Fellowship Program, Other',
    cipDefinition:
      'Any dental residency or fellowship specialty program not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.03',
    cipTitle: 'Veterinary Residency/Fellowship Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 60.0301 - 60.0399. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0301',
    cipTitle: 'Veterinary Anesthesiology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the application of anesthesia for general and specialized surgery and obstetrics, critical animal care and the care of pain problems.  Includes instruction in surgical procedures, current monitoring procedures, fluid therapy, pain management, diagnostic and therapeutic procedures outside the operating room, and operating room safety. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0302',
    cipTitle: 'Veterinary Dentistry Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the application of dental care procedures to the teeth, eating surfaces, and oral cavities of animals.  Includes instruction in the prevention, diagnosis, and treatment of diseases and abnormalities of animal teeth and gums and related parts of the oral cavity; and related anatomical and physiological principles. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0303',
    cipTitle: 'Veterinary Dermatology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the delivery of specialized care to animals with diseases of the skin, scales, feathers, hair, nails and mucous membranes.  Includes instruction in dermatologic surgical procedures, cutaneous allergies, and diagnostic and therapeutic techniques. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0304',
    cipTitle: 'Veterinary Emergency and Critical Care Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the emergency treatment and management of care for animals with acutely life-threatening conditions which may include multiple organ failure.  Includes instruction in the management of critical care units, emergency procedures, and long-term care of critically diseased animals. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0305',
    cipTitle: 'Veterinary Internal Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the provision of general medical services to animals with a wide range of non-surgical clinical problems.  Includes instruction in behavioral aspects of diseases, animal diagnosis, animal aging, and referral procedures. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0306',
    cipTitle: 'Laboratory Animal Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the principles and practices of applied veterinary research and related techniques, equipment, data systems and research design.  Includes instruction in the management of veterinary and other animal research laboratories and health care facilities. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0307',
    cipTitle: 'Veterinary Microbiology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in clinical applications of research on harmful microorganisms, including viruses, and of the disease processes they induce in animals. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0308',
    cipTitle: 'Veterinary Nutrition Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians to apply research on the chemical nature of food substances; the processes by which animals ingest, digest, absorb, transport, utilize and excrete food and nutrients; and their relation to animal behavior and health. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0309',
    cipTitle: 'Veterinary Ophthalmology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the diagnosis, prevention, and treatment of ophthalmic diseases and disorders in animals, and related ocular pathology procedures.  Includes instruction in animal eye surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.031',
    cipTitle: 'Veterinary Pathology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the clinical laboratory analysis and diagnosis of diseased animal tissues and anatomic abnormalities.  Includes instruction in performing general autopsies, forensic medicine, laboratory management and quality control. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0311',
    cipTitle: 'Veterinary Practice Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the provision of regular and long-term care to animals, health services to owners, and the management of independent veterinary practices.  Includes instruction in comprehensive care and specialist referral, basic surgery, emergency medical procedures, and diagnostic imaging. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0312',
    cipTitle: 'Veterinary Preventive Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the investigation of and intervention in health and disease problems of animal and related human communities, comparative medicine, and animal public health.  Includes instruction in biostatistics, epidemiology, environmental control, toxicology and the planning and administration of animal health programs and services. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0313',
    cipTitle: 'Veterinary Radiology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the use of radiologic imaging and therapies to diagnose and treat animal diseases and health problems.  Includes instruction in the use and handling of equipment for radionuclides detection and application. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0314',
    cipTitle: 'Veterinary Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the care and treatment of animal diseases and disorders via invasive procedures and the etiology, pathogenesis, diagnosis and management of physical disorders.  Includes instruction in clinical and operative skills, pre-operative and post-operative care, trauma management, endoscopic techniques, and applications to large and small animal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0315',
    cipTitle: 'Theriogenology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the diagnosis, prevention and treatment of diseases and health problems affecting the reproductive systems of animals, and the comprehensive care and treatment of parent animals, animal fetuses and newborn or newly hatched animals throughout the gestation period. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0316',
    cipTitle: 'Veterinary Toxicology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the clinical application of research on the nature and extent of adverse effects of synthetic and naturally occurring chemical substances on animals.  Includes instruction in chemical mechanisms and the specialties of reproductive, developmental, genetic, forensic, inhalation and neurobehavioral toxicology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0317',
    cipTitle: 'Zoological Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians in the specialized treatment and care of zoo animals, performing animals, and animals living in the wild.  Includes instruction in the principles and procedures used in the observation, diagnosis, care and treatment of illness, disease, injury, deformity, or other anomalies in non-domesticated animals. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0318',
    cipTitle: 'Poultry Veterinarian Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians to work in the commercial poultry industry, providing services and technical support pertaining to prevention, diagnosis, and management of disease; epidemiology; genetics; and nutrition. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0319',
    cipTitle: 'Veterinary Behaviorist Residency Program',
    cipDefinition:
      "A residency training program that prepares veterinarians to understand and treat the behavioral problems of animals, primarily through the application of behavior modification techniques to companion animals; may also involve diagnosis of medical conditions that can affect a pet's behavior, and the use of medication in conjunction with behavior modification therapy. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '60',
    cipCode: '60.032',
    cipTitle: 'Veterinary Clinical Pharmacology Residency Program',
    cipDefinition:
      'A residency training program that prepares veterinarians to apply advanced knowledge of the chemical properties and biological effects of drugs to rational drug therapy in animal patients. Includes instruction in the comparative pharmacology of principal drug groups, pharmacokinetics, basic mechanisms and features of diseases occurring in domesticated animals, the role of drugs in modifying the disease process, and specific skills critical to the practice of clinical pharmacology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0399',
    cipTitle: 'Veterinary Residency/Fellowship Program, Other',
    cipDefinition:
      'Any residency or fellowship training program in veterinary medicine not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.04',
    cipTitle: 'Medical Residency Programs - General Certificates',
    cipDefinition: 'Deleted, Report under 61',
  },
  {
    cipFamily: '60',
    cipCode: '60.0401',
    cipTitle: 'Aerospace Medicine Residency Program',
    cipDefinition: 'Moved from 60.0401 to 61.2302',
  },
  {
    cipFamily: '60',
    cipCode: '60.0402',
    cipTitle: 'Allergy and Immunology Residency Program',
    cipDefinition: 'Moved from 60.0402 to 61.0301',
  },
  {
    cipFamily: '60',
    cipCode: '60.0403',
    cipTitle: 'Anesthesiology Residency Program',
    cipDefinition: 'Moved from 60.0403 to 61.0401',
  },
  {
    cipFamily: '60',
    cipCode: '60.0404',
    cipTitle: 'Child Neurology Residency Program',
    cipDefinition: 'Moved from 60.0404 to 61.1102',
  },
  {
    cipFamily: '60',
    cipCode: '60.0405',
    cipTitle: 'Clinical Biochemical Genetics Residency Program',
    cipDefinition: 'Moved from 60.0405 to 61.0901',
  },
  {
    cipFamily: '60',
    cipCode: '60.0406',
    cipTitle: 'Clinical Cytogenetics Residency Program',
    cipDefinition: 'Deleted, Report under 61.0903',
  },
  {
    cipFamily: '60',
    cipCode: '60.0407',
    cipTitle: 'Clinical Genetics Residency Program',
    cipDefinition: 'Moved from 60.0407 to 61.0902',
  },
  {
    cipFamily: '60',
    cipCode: '60.0408',
    cipTitle: 'Clinical Molecular Genetics Residency Program',
    cipDefinition: 'Deleted, Report under 61.0903',
  },
  {
    cipFamily: '60',
    cipCode: '60.0409',
    cipTitle: 'Colon and Rectal Surgery Residency Program',
    cipDefinition: 'Moved from 60.0409 to 61.2702',
  },
  {
    cipFamily: '60',
    cipCode: '60.041',
    cipTitle: 'Dermatology Residency Program',
    cipDefinition: 'Moved from 60.0410 to 61.0501',
  },
  {
    cipFamily: '60',
    cipCode: '60.0411',
    cipTitle: 'Diagnostic Radiology Residency Program',
    cipDefinition: 'Moved from 60.0411 to 61.2601',
  },
  {
    cipFamily: '60',
    cipCode: '60.0412',
    cipTitle: 'Emergency Medicine Residency Program',
    cipDefinition: 'Moved from 60.0412 to 61.0601',
  },
  {
    cipFamily: '60',
    cipCode: '60.0413',
    cipTitle: 'Family Medicine Residency Program',
    cipDefinition: 'Moved from 60.0413 to 61.0701',
  },
  {
    cipFamily: '60',
    cipCode: '60.0414',
    cipTitle: 'General Surgery Residency Program',
    cipDefinition: 'Moved from 60.0414 to 61.2701',
  },
  {
    cipFamily: '60',
    cipCode: '60.0415',
    cipTitle: 'Internal Medicine Residency Program',
    cipDefinition: 'Moved from 60.0415 to 61.0801',
  },
  {
    cipFamily: '60',
    cipCode: '60.0416',
    cipTitle: 'Neurological Surgery Residency Program',
    cipDefinition: 'Moved from 60.0416 to 61.1001',
  },
  {
    cipFamily: '60',
    cipCode: '60.0417',
    cipTitle: 'Neurology Residency Program',
    cipDefinition: 'Moved from 60.0417 to 61.1101',
  },
  {
    cipFamily: '60',
    cipCode: '60.0418',
    cipTitle: 'Nuclear Medicine Residency Program',
    cipDefinition: 'Moved from 60.0418 to 61.1201',
  },
  {
    cipFamily: '60',
    cipCode: '60.0419',
    cipTitle: 'Obstetrics and Gynecology Residency Program',
    cipDefinition: 'Moved from 60.0419 to 61.1301',
  },
  {
    cipFamily: '60',
    cipCode: '60.042',
    cipTitle: 'Occupational Medicine Residency Program',
    cipDefinition: 'Moved from 60.0420 to 61.2303',
  },
  {
    cipFamily: '60',
    cipCode: '60.0421',
    cipTitle: 'Ophthalmology Residency Program',
    cipDefinition: 'Moved from 60.0421 to 61.1401',
  },
  {
    cipFamily: '60',
    cipCode: '60.0422',
    cipTitle: 'Orthopedic Surgery Residency Program',
    cipDefinition: 'Moved from 60.0422 to 61.1501',
  },
  {
    cipFamily: '60',
    cipCode: '60.0423',
    cipTitle: 'Otolaryngology Residency Program',
    cipDefinition: 'Moved from 60.0423 to 61.1701',
  },
  {
    cipFamily: '60',
    cipCode: '60.0424',
    cipTitle: 'Pathology Residency Program',
    cipDefinition: 'Moved from 60.0424 to 61.1801',
  },
  {
    cipFamily: '60',
    cipCode: '60.0425',
    cipTitle: 'Pediatrics Residency Program',
    cipDefinition: 'Moved from 60.0425 to 61.1901',
  },
  {
    cipFamily: '60',
    cipCode: '60.0426',
    cipTitle: 'Physical Medicine and Rehabilitation Residency Program',
    cipDefinition: 'Moved from 60.0426 to 61.2001',
  },
  {
    cipFamily: '60',
    cipCode: '60.0427',
    cipTitle: 'Plastic Surgery Residency Program',
    cipDefinition: 'Moved from 60.0427 to 61.2101',
  },
  {
    cipFamily: '60',
    cipCode: '60.0428',
    cipTitle: 'Psychiatry Residency Program',
    cipDefinition: 'Moved from 60.0428 to 61.2401',
  },
  {
    cipFamily: '60',
    cipCode: '60.0429',
    cipTitle: 'Public Health and General Preventive Medicine Residency Program',
    cipDefinition: 'Moved from 60.0429 to 61.2301',
  },
  {
    cipFamily: '60',
    cipCode: '60.0430',
    cipTitle: 'Radiation Oncology Residency Program',
    cipDefinition: 'Moved from 60.0430 to 61.2501',
  },
  {
    cipFamily: '60',
    cipCode: '60.0431',
    cipTitle: 'Radiologic Physics Residency Program',
    cipDefinition: 'Moved from 60.0431 to 61.2610',
  },
  {
    cipFamily: '60',
    cipCode: '60.0432',
    cipTitle: 'Thoracic Surgery Residency Program',
    cipDefinition: 'Moved from 60.0432 to 61.2707',
  },
  {
    cipFamily: '60',
    cipCode: '60.0433',
    cipTitle: 'Urology Residency Program',
    cipDefinition: 'Moved from 60.0433 to 61.2801',
  },
  {
    cipFamily: '60',
    cipCode: '60.0434',
    cipTitle: 'Vascular Surgery Residency Program',
    cipDefinition: 'Moved from 60.0434 to 61.2708',
  },
  {
    cipFamily: '60',
    cipCode: '60.0499',
    cipTitle: 'Medical Residency Programs - General Certificates, Other',
    cipDefinition: 'Deleted, Report under 61',
  },
  {
    cipFamily: '60',
    cipCode: '60.05',
    cipTitle: 'Medical Residency Programs - Subspecialty Certificates',
    cipDefinition: 'Deleted, Report under 61',
  },
  {
    cipFamily: '60',
    cipCode: '60.0501',
    cipTitle: 'Addiction Psychiatry Residency Program',
    cipDefinition: 'Moved from 60.0501 to 61.2402',
  },
  {
    cipFamily: '60',
    cipCode: '60.0502',
    cipTitle: 'Adolescent Medicine Residency Program',
    cipDefinition: 'Moved from 60.0502 to 61.1902',
  },
  {
    cipFamily: '60',
    cipCode: '60.0503',
    cipTitle: 'Blood Banking/Transfusion Medicine Residency Program',
    cipDefinition: 'Moved from 60.0503 to 61.1804',
  },
  {
    cipFamily: '60',
    cipCode: '60.0504',
    cipTitle: 'Cardiovascular Disease Residency Program',
    cipDefinition: 'Moved from 60.0504 to 61.0804',
  },
  {
    cipFamily: '60',
    cipCode: '60.0505',
    cipTitle: 'Chemical Pathology Residency Program',
    cipDefinition: 'Moved from 60.0505 to 61.1805',
  },
  {
    cipFamily: '60',
    cipCode: '60.0506',
    cipTitle: 'Child Abuse Pediatrics Residency Program',
    cipDefinition: 'Moved from 60.0506 to 61.1903',
  },
  {
    cipFamily: '60',
    cipCode: '60.0507',
    cipTitle: 'Child and Adolescent Psychiatry Residency Program',
    cipDefinition: 'Moved from 60.0507 to 61.2403',
  },
  {
    cipFamily: '60',
    cipCode: '60.0508',
    cipTitle: 'Clinical Cardiac Electrophysiology Residency Program',
    cipDefinition: 'Moved from 60.0508 to 61.0805',
  },
  {
    cipFamily: '60',
    cipCode: '60.0509',
    cipTitle: 'Clinical Neurophysiology Residency Program',
    cipDefinition: 'Moved from 60.0509 to 61.1103',
  },
  {
    cipFamily: '60',
    cipCode: '60.051',
    cipTitle: 'Congenital Cardiac Surgery Residency Program',
    cipDefinition: 'Moved from 60.0510 to 61.2704',
  },
  {
    cipFamily: '60',
    cipCode: '60.0511',
    cipTitle: 'Critical Care Medicine Residency Program',
    cipDefinition: 'Moved from 60.0511 to 61.0202',
  },
  {
    cipFamily: '60',
    cipCode: '60.0512',
    cipTitle: 'Cytopathology Residency Program',
    cipDefinition: 'Moved from 60.0512 to 61.1806',
  },
  {
    cipFamily: '60',
    cipCode: '60.0513',
    cipTitle: 'Dermatopathology Residency Program',
    cipDefinition: 'Moved from 60.0513 to 61.0502',
  },
  {
    cipFamily: '60',
    cipCode: '60.0514',
    cipTitle: 'Developmental-Behavioral Pediatrics Residency Program',
    cipDefinition: 'Moved from 60.0514 to 61.1904',
  },
  {
    cipFamily: '60',
    cipCode: '60.0515',
    cipTitle: 'Diagnostic Radiologic Physics Residency Program',
    cipDefinition: 'Moved from 60.0515 to 61.2604',
  },
  {
    cipFamily: '60',
    cipCode: '60.0516',
    cipTitle: 'Endocrinology, Diabetes and Metabolism Residency Program',
    cipDefinition: 'Moved from 60.0516 to 61.0806',
  },
  {
    cipFamily: '60',
    cipCode: '60.0517',
    cipTitle: 'Forensic Pathology Residency Program',
    cipDefinition: 'Moved from 60.0517 to 61.1807',
  },
  {
    cipFamily: '60',
    cipCode: '60.0518',
    cipTitle: 'Forensic Psychiatry Residency Program',
    cipDefinition: 'Moved from 60.0518 to 61.2405',
  },
  {
    cipFamily: '60',
    cipCode: '60.0519',
    cipTitle: 'Gastroenterology Residency Program',
    cipDefinition: 'Moved from 60.0519 to 61.0807',
  },
  {
    cipFamily: '60',
    cipCode: '60.052',
    cipTitle: 'Geriatric Medicine Residency Program',
    cipDefinition: 'Moved from 60.0520 to 61.0203',
  },
  {
    cipFamily: '60',
    cipCode: '60.0521',
    cipTitle: 'Geriatric Psychiatry Residency Program',
    cipDefinition: 'Moved from 60.0521 to 61.2406',
  },
  {
    cipFamily: '60',
    cipCode: '60.0522',
    cipTitle: 'Gynecologic Oncology Residency Program',
    cipDefinition: 'Moved from 60.0522 to 61.1302',
  },
  {
    cipFamily: '60',
    cipCode: '60.0523',
    cipTitle: 'Hematological Pathology Residency Program',
    cipDefinition: 'Moved from 60.0523 to 61.1808',
  },
  {
    cipFamily: '60',
    cipCode: '60.0524',
    cipTitle: 'Hematology Residency Program',
    cipDefinition: 'Moved from 60.0524 to 61.0808',
  },
  {
    cipFamily: '60',
    cipCode: '60.0525',
    cipTitle: 'Hospice and Palliative Medicine Residency Program',
    cipDefinition: 'Moved from 60.0525 to 61.0205',
  },
  {
    cipFamily: '60',
    cipCode: '60.0526',
    cipTitle: 'Immunopathology Residency Program',
    cipDefinition: 'Moved from 60.0526 to 61.1809',
  },
  {
    cipFamily: '60',
    cipCode: '60.0527',
    cipTitle: 'Infectious Disease Residency Program',
    cipDefinition: 'Moved from 60.0527 to 61.0810',
  },
  {
    cipFamily: '60',
    cipCode: '60.0528',
    cipTitle: 'Interventional Cardiology Residency Program',
    cipDefinition: 'Moved from 60.0528 to 61.0811',
  },
  {
    cipFamily: '60',
    cipCode: '60.0529',
    cipTitle: 'Laboratory Medicine Residency Program',
    cipDefinition: 'Moved from 60.0529 to 61.1810',
  },
  {
    cipFamily: '60',
    cipCode: '60.0530',
    cipTitle: 'Maternal and Fetal Medicine Residency Program',
    cipDefinition: 'Moved from 60.0530 to 61.1303',
  },
  {
    cipFamily: '60',
    cipCode: '60.0531',
    cipTitle: 'Medical Biochemical Genetics Residency Program',
    cipDefinition: 'Moved from 60.0531 to 61.0904',
  },
  {
    cipFamily: '60',
    cipCode: '60.0532',
    cipTitle: 'Medical Microbiology Residency Program',
    cipDefinition: 'Moved from 60.0532 to 61.1811',
  },
  {
    cipFamily: '60',
    cipCode: '60.0533',
    cipTitle: 'Medical Nuclear Physics Residency Program',
    cipDefinition: 'Moved from 60.0533 to 61.2605',
  },
  {
    cipFamily: '60',
    cipCode: '60.0534',
    cipTitle: 'Medical Oncology Residency Program',
    cipDefinition: 'Moved from 60.0534 to 61.0813',
  },
  {
    cipFamily: '60',
    cipCode: '60.0535',
    cipTitle: 'Medical Toxicology Residency Program',
    cipDefinition: 'Moved from 60.0535 to 61.0208',
  },
  {
    cipFamily: '60',
    cipCode: '60.0536',
    cipTitle: 'Molecular Genetic Pathology Residency Program',
    cipDefinition: 'Moved from 60.0536 to 61.1812',
  },
  {
    cipFamily: '60',
    cipCode: '60.0537',
    cipTitle: 'Musculoskeletal Oncology Residency Program',
    cipDefinition: 'Moved from 60.0537 to 61.1502',
  },
  {
    cipFamily: '60',
    cipCode: '60.0538',
    cipTitle: 'Neonatal-Perinatal Medicine Residency Program',
    cipDefinition: 'Moved from 60.0538 to 61.1905',
  },
  {
    cipFamily: '60',
    cipCode: '60.0539',
    cipTitle: 'Nephrology Residency Program',
    cipDefinition: 'Moved from 60.0539 to 61.0812',
  },
  {
    cipFamily: '60',
    cipCode: '60.0540',
    cipTitle: 'Neurodevelopmental Disabilities Residency Program',
    cipDefinition: 'Moved from 60.0540 to 61.1106',
  },
  {
    cipFamily: '60',
    cipCode: '60.0541',
    cipTitle: 'Neuromuscular Medicine Residency Program',
    cipDefinition: 'Moved from 60.0541 to 61.0209',
  },
  {
    cipFamily: '60',
    cipCode: '60.0542',
    cipTitle: 'Neuropathology Residency Program',
    cipDefinition: 'Moved from 60.0542 to 61.1813',
  },
  {
    cipFamily: '60',
    cipCode: '60.0543',
    cipTitle: 'Neuroradiology Residency Program',
    cipDefinition: 'Moved from 60.0543 to 61.2607',
  },
  {
    cipFamily: '60',
    cipCode: '60.0544',
    cipTitle: 'Neurotology Residency Program',
    cipDefinition: 'Moved from 60.0544 to 61.1702',
  },
  {
    cipFamily: '60',
    cipCode: '60.0545',
    cipTitle: 'Nuclear Radiology Residency Program',
    cipDefinition: 'Moved from 60.0545 to 61.2608',
  },
  {
    cipFamily: '60',
    cipCode: '60.0546',
    cipTitle: 'Orthopedic Sports Medicine Residency Program',
    cipDefinition: 'Moved from 60.0546 to 61.1503',
  },
  {
    cipFamily: '60',
    cipCode: '60.0547',
    cipTitle: 'Orthopedic Surgery of the Spine Residency Program',
    cipDefinition: 'Moved from 60.0547 to 61.1504',
  },
  {
    cipFamily: '60',
    cipCode: '60.0548',
    cipTitle: 'Pain Medicine Residency Program',
    cipDefinition: 'Moved from 60.0548 to 61.0210',
  },
  {
    cipFamily: '60',
    cipCode: '60.0549',
    cipTitle: 'Pediatric Cardiology Residency Program',
    cipDefinition: 'Moved from 60.0549 to 61.1906',
  },
  {
    cipFamily: '60',
    cipCode: '60.0550',
    cipTitle: 'Pediatric Critical Care Medicine Residency Program',
    cipDefinition: 'Moved from 60.0550 to 61.1907',
  },
  {
    cipFamily: '60',
    cipCode: '60.0551',
    cipTitle: 'Pediatric Dermatology Residency Program',
    cipDefinition: 'Moved from 60.0551 to 61.0503',
  },
  {
    cipFamily: '60',
    cipCode: '60.0552',
    cipTitle: 'Pediatric Emergency Medicine Residency Program',
    cipDefinition: 'Moved from 60.0552 to 61.1908',
  },
  {
    cipFamily: '60',
    cipCode: '60.0553',
    cipTitle: 'Pediatric Endocrinology Residency Program',
    cipDefinition: 'Moved from 60.0553 to 61.1909',
  },
  {
    cipFamily: '60',
    cipCode: '60.0554',
    cipTitle: 'Pediatric Gastroenterology Residency Program',
    cipDefinition: 'Moved from 60.0554 to 61.1910',
  },
  {
    cipFamily: '60',
    cipCode: '60.0555',
    cipTitle: 'Pediatric Hematology-Oncology Residency Program',
    cipDefinition: 'Moved from 60.0555 to 61.1911',
  },
  {
    cipFamily: '60',
    cipCode: '60.0556',
    cipTitle: 'Pediatric Infectious Diseases Residency Program',
    cipDefinition: 'Moved from 60.0556 to 61.1912',
  },
  {
    cipFamily: '60',
    cipCode: '60.0557',
    cipTitle: 'Pediatric Nephrology Residency Program',
    cipDefinition: 'Moved from 60.0557 to 61.1913',
  },
  {
    cipFamily: '60',
    cipCode: '60.0558',
    cipTitle: 'Pediatric Orthopedics Residency Program',
    cipDefinition: 'Moved from 60.0558 to 61.1505',
  },
  {
    cipFamily: '60',
    cipCode: '60.0559',
    cipTitle: 'Pediatric Otolaryngology Residency Program',
    cipDefinition: 'Moved from 60.0559 to 61.1703',
  },
  {
    cipFamily: '60',
    cipCode: '60.0560',
    cipTitle: 'Pediatric Pathology Residency Program',
    cipDefinition: 'Moved from 60.0560 to 61.1814',
  },
  {
    cipFamily: '60',
    cipCode: '60.0561',
    cipTitle: 'Pediatric Pulmonology Residency Program',
    cipDefinition: 'Moved from 60.0561 to 61.1914',
  },
  {
    cipFamily: '60',
    cipCode: '60.0562',
    cipTitle: 'Pediatric Radiology Residency Program',
    cipDefinition: 'Moved from 60.0562 to 61.2609',
  },
  {
    cipFamily: '60',
    cipCode: '60.0563',
    cipTitle: 'Pediatric Rehabilitation Medicine Residency Program',
    cipDefinition: 'Moved from 60.0563 to 61.2003',
  },
  {
    cipFamily: '60',
    cipCode: '60.0564',
    cipTitle: 'Pediatric Rheumatology Residency Program',
    cipDefinition: 'Moved from 60.0564 to 61.1915',
  },
  {
    cipFamily: '60',
    cipCode: '60.0565',
    cipTitle: 'Pediatric Surgery Residency Program',
    cipDefinition: 'Moved from 60.0565 to 61.2705',
  },
  {
    cipFamily: '60',
    cipCode: '60.0566',
    cipTitle: 'Pediatric Transplant Hepatology Residency Program',
    cipDefinition: 'Moved from 60.0566 to 61.1917',
  },
  {
    cipFamily: '60',
    cipCode: '60.0567',
    cipTitle: 'Pediatric Urology Residency Program',
    cipDefinition: 'Moved from 60.0567 to 61.2802',
  },
  {
    cipFamily: '60',
    cipCode: '60.0568',
    cipTitle: 'Physical Medicine and Rehabilitation/Psychiatry Residency Program',
    cipDefinition: 'Deleted, Report under 61.0199',
  },
  {
    cipFamily: '60',
    cipCode: '60.0569',
    cipTitle: 'Plastic Surgery Within the Head and Neck Residency Program',
    cipDefinition: 'Moved from 60.0569 to 61.2103',
  },
  {
    cipFamily: '60',
    cipCode: '60.0570',
    cipTitle: 'Psychosomatic Medicine Residency Program',
    cipDefinition: 'Moved from 60.0570 to 61.2404',
  },
  {
    cipFamily: '60',
    cipCode: '60.0571',
    cipTitle: 'Pulmonary Disease Residency Program',
    cipDefinition: 'Moved from 60.0571 to 61.0814',
  },
  {
    cipFamily: '60',
    cipCode: '60.0572',
    cipTitle: 'Radioisotopic Pathology Residency Program',
    cipDefinition: 'Moved from 60.0572 to 61.1815',
  },
  {
    cipFamily: '60',
    cipCode: '60.0573',
    cipTitle: 'Reproductive Endocrinology/Infertility Residency Program',
    cipDefinition: 'Moved from 60.0573 to 61.1304',
  },
  {
    cipFamily: '60',
    cipCode: '60.0574',
    cipTitle: 'Rheumatology Residency Program',
    cipDefinition: 'Moved from 60.0574 to 61.0816',
  },
  {
    cipFamily: '60',
    cipCode: '60.0575',
    cipTitle: 'Sleep Medicine Residency Program',
    cipDefinition: 'Moved from 60.0575 to 61.0212',
  },
  {
    cipFamily: '60',
    cipCode: '60.0576',
    cipTitle: 'Spinal Cord Injury Medicine Residency Program',
    cipDefinition: 'Moved from 60.0576 to 61.2002',
  },
  {
    cipFamily: '60',
    cipCode: '60.0577',
    cipTitle: 'Sports Medicine Residency Program',
    cipDefinition: 'Moved from 60.0577 to 61.0213',
  },
  {
    cipFamily: '60',
    cipCode: '60.0578',
    cipTitle: 'Surgery of the Hand Residency Program',
    cipDefinition: 'Moved from 60.0578 to 61.0214',
  },
  {
    cipFamily: '60',
    cipCode: '60.0579',
    cipTitle: 'Surgical Critical Care Residency Program',
    cipDefinition: 'Moved from 60.0579 to 61.2706',
  },
  {
    cipFamily: '60',
    cipCode: '60.058',
    cipTitle: 'Therapeutic Radiologic Physics Residency Program',
    cipDefinition: 'Moved from 60.0580 to 61.2611',
  },
  {
    cipFamily: '60',
    cipCode: '60.0581',
    cipTitle: 'Transplant Hepatology Residency Program',
    cipDefinition: 'Moved from 60.0581 to 61.0818',
  },
  {
    cipFamily: '60',
    cipCode: '60.0582',
    cipTitle: 'Undersea and Hyperbaric Medicine Residency Program',
    cipDefinition: 'Moved from 60.0582 to 61.0216',
  },
  {
    cipFamily: '60',
    cipCode: '60.0583',
    cipTitle: 'Vascular and Interventional Radiology Residency Program',
    cipDefinition: 'Moved from 60.0583 to 61.2612',
  },
  {
    cipFamily: '60',
    cipCode: '60.0584',
    cipTitle: 'Vascular Neurology Residency Program',
    cipDefinition: 'Moved from 60.0584 to 61.1107',
  },
  {
    cipFamily: '60',
    cipCode: '60.0599',
    cipTitle: 'Medical Residency Programs - Subspecialty Certificates, Other',
    cipDefinition: 'Deleted, Report under 61',
  },
  {
    cipFamily: '60',
    cipCode: '60.06',
    cipTitle: 'Podiatric Medicine Residency Programs',
    cipDefinition: 'Moved from 60.06 to 61.22',
  },
  {
    cipFamily: '60',
    cipCode: '60.0601',
    cipTitle: 'Podiatric Medicine and Surgery - 24 Residency Program',
    cipDefinition: 'Deleted, Report under 61.2201',
  },
  {
    cipFamily: '60',
    cipCode: '60.0602',
    cipTitle: 'Podiatric Medicine and Surgery - 36 Residency Program',
    cipDefinition: 'Deleted, Report under 61.2201',
  },
  {
    cipFamily: '60',
    cipCode: '60.07',
    cipTitle: 'Nurse Practitioner Residency/Fellowship Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 60.0701 - 60.0799. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0701',
    cipTitle: 'Nurse Practitioner Residency/Fellowship Program, General',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in nursing. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0702',
    cipTitle: 'Combined Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in two or more nursing specialties. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0703',
    cipTitle: 'Acute Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in acute care medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0704',
    cipTitle: 'Adult/Gerontology Acute Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in adult/gerontology acute care medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0705',
    cipTitle: 'Adult/Gerontology Critical Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in adult/gerontology critical care medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0706',
    cipTitle: 'Cardiology/Cardiovascular Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in cardiology and cardiovascular medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0707',
    cipTitle: 'Clinical Informatics Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in clinical informatics. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0708',
    cipTitle: 'Dermatology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in dermatology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0709',
    cipTitle: 'Developmental and Behavioral Pediatrics Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in developmental and behavioral pediatrics. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.071',
    cipTitle: 'Diabetes Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in diabetology and metabolic disorders. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0711',
    cipTitle: 'Emergency Medicine Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in emergency medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0712',
    cipTitle: 'Endocrinology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in endocrinology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0713',
    cipTitle: 'Family Medicine Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in family medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0714',
    cipTitle: 'Gastroenterology and Hepatology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in gastroenterology and hepatology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0715',
    cipTitle: 'Gastroenterology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in gastroenterology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0716',
    cipTitle: 'Genetics Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in genetic counseling and disorders. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0717',
    cipTitle: 'Gerontology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in gerontology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0718',
    cipTitle: 'Global Health Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in global health. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0719',
    cipTitle: 'Hematology-Oncology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in hematology and oncology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.072',
    cipTitle: 'Hepatology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in hepatology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0721',
    cipTitle: 'Home-Based Primary Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in home-based primary care. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0722',
    cipTitle: 'Hospice and Palliative Medicine Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in hospice and palliative medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0723',
    cipTitle: 'Hospital Medicine Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in hospital medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0724',
    cipTitle: 'Infectious Diseases Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in infectious diseases. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0725',
    cipTitle: 'Neonatal Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in neonatology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0726',
    cipTitle: 'Nephrology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in nephrology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0727',
    cipTitle: 'Neurology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in neurology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0728',
    cipTitle: 'Neuroscience Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in neuroscience. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0729',
    cipTitle: 'Obstetrics and Gynecology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in obstetrics and gynecology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0730',
    cipTitle: 'Occupational Health Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in occupational health. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0731',
    cipTitle: 'Orthopedic Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in orthopedics. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0732',
    cipTitle: 'Orthopedic Surgery Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in orthopedic surgery. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0733',
    cipTitle: 'Pain Management Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in pain management. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0734',
    cipTitle: 'Palliative Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in palliative care. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0735',
    cipTitle: 'Pediatric Hematology-Oncology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in pediatric hematology and oncology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0736',
    cipTitle: 'Pediatric Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in pediatrics. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0737',
    cipTitle: 'Pediatric Rehabilitation Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in pediatric rehabilitation. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0738',
    cipTitle: 'Psychiatric/Mental Health Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in psychiatric and mental health. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0739',
    cipTitle: 'Public/Community Health Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in public or community health. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0740',
    cipTitle: 'Pulmonary Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in pulmonology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0741',
    cipTitle: 'Rheumatology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in rheumatology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0742',
    cipTitle: 'Rural Health Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in rural health. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0743',
    cipTitle: 'Sleep Medicine Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in sleep medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0744',
    cipTitle: 'Surgical and Critical Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in surgical and critical care medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0745',
    cipTitle: 'Surgical Wound and Reconstruction Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in surgical wound care and reconstruction. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0746',
    cipTitle: 'Transplantation Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in transplantology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0747',
    cipTitle: 'Trauma and Critical Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in trauma and critical care medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0748',
    cipTitle: 'Urgent Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in urgent care medicine. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0749',
    cipTitle: 'Urology Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in urology. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0750',
    cipTitle: "Women's Health Nurse Practitioner Residency/Fellowship Program.",
    cipDefinition:
      "A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in women's health. This CIP Code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '60',
    cipCode: '60.0751',
    cipTitle: 'Wound Care Nurse Practitioner Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed nurse practitioners that provides advanced didactic and clinical training in wound care. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0799',
    cipTitle: 'Nurse Practitioner Residency/Fellowship Program, Other',
    cipDefinition:
      'Any residency or fellowship program for nurse practitioners not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.08',
    cipTitle: 'Pharmacy Residency/Fellowship Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 60.0801 - 60.0899. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0801',
    cipTitle: 'Pharmacy Residency/Fellowship Program, General',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0802',
    cipTitle: 'Combined Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in two or more pharmacy specialties. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0803',
    cipTitle: 'Ambulatory Care Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in ambulatory care pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0804',
    cipTitle: 'Cardiology Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in cardiology pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0805',
    cipTitle: 'Clinical Pharmacogenomics Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in clinical pharmacogenomics. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Biologics/Pharmacogenomics Pharmacy Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0806',
    cipTitle: 'Community/Community-Based Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in community or community-based pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0807',
    cipTitle: 'Corporate Pharmacy Leadership Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in corporate pharmacy leadership. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0808',
    cipTitle: 'Critical Care Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in critical care pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0809',
    cipTitle: 'Drug Information Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in drug information. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.081',
    cipTitle: 'Emergency Medicine Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in emergency medicine pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0811',
    cipTitle: 'Family Medicine Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in family medicine pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0812',
    cipTitle: 'Geriatric Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in geriatric pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0813',
    cipTitle: 'Health System Medication Management Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in health system medication management. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0814',
    cipTitle: 'Health System Pharmacy Administration and Leadership Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in health system pharmacy administration and leadership. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0815',
    cipTitle: 'Infectious Diseases Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in infectious diseases pharmacy. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - HIV Pharmacy Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0816',
    cipTitle: 'Internal Medicine Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in internal medicine pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0817',
    cipTitle: 'Investigational Drugs and Research Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in investigational drugs and research. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0818',
    cipTitle: 'Managed Care Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in managed care pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0819',
    cipTitle: 'Medication Systems and Operations Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in medication systems and operations. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.082',
    cipTitle: 'Medication-Use Safety Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in medication-use safety. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0821',
    cipTitle: 'Neonatal Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in neonatal pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0822',
    cipTitle: 'Nephrology Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in nephrology pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0823',
    cipTitle: 'Neurology Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in neurology pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0824',
    cipTitle: 'Nuclear Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in nuclear pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0825',
    cipTitle: 'Nutrition Support Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in nutrition support. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0826',
    cipTitle: 'Oncology Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in oncology pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0827',
    cipTitle: 'Palliative Care/Pain Management Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in palliative care and pain management. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0828',
    cipTitle: 'Pediatric Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in pediatric pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0829',
    cipTitle: 'Pharmacotherapy Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in pharmacotherapy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0830',
    cipTitle: 'Pharmacy Informatics Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in pharmacy informatics. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Pharmacoeconomics and Outcomes Research Pharmacy Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0831',
    cipTitle: 'Psychiatric Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in psychiatric pharmacy. This CIP Code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0832',
    cipTitle: 'Transplantation Pharmacy Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed pharmacists that provides advanced training in transplantation pharmacy. This CIP Code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Solid Organ Transplant Pharmacy Residency Program, - Transplantional/Immunology Pharmacy Residency Program',
  },
  {
    cipFamily: '60',
    cipCode: '60.0899',
    cipTitle: 'Pharmacy Residency Programs, Other',
    cipDefinition:
      'Any residency or fellowship program in pharmacy not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.09',
    cipTitle: 'Physician Assistant Residency/Fellowship Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 60.0901 - 60.0999. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0901',
    cipTitle: 'Physician Assistant Residency/Fellowship Program, General',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in medicine. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Physician Associate Residency/Fellowship Program, General.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0902',
    cipTitle: 'Combined Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in two or more medical or surgical specialties. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Combined Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0903',
    cipTitle: 'Acute Care Medicine Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in acute care medicine. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Acute Care Medicine Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0904',
    cipTitle: 'Acute Care Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in acute care surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Acute Care Surgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0905',
    cipTitle: 'Cardiology Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in cardiology. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Cardiology Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0906',
    cipTitle: 'Cardiothoracic Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in cardiothoracic surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Cardiothoracic Surgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0907',
    cipTitle: 'Critical Care Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in critical care medicine. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Critical Care Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0908',
    cipTitle: 'Critical Care and Trauma Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in critical care and trauma surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Critical Care and Trauma Surgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0909',
    cipTitle: 'Emergency Medicine Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in emergency medicine. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Emergency Medicine Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.091',
    cipTitle: 'ENT Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in ENT (ears, nose, and throat) surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - ENT Surgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0911',
    cipTitle: 'Family Medicine Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in family medicine. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Family Medicine Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0912',
    cipTitle: 'Geriatrics Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in geriatrics. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Geriatrics Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0913',
    cipTitle: 'Hematology-Oncology Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in hematology and oncology. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Hematology-Oncology Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0914',
    cipTitle: 'Hepatobiliary Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in hepatobiliary surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Hepatobiliary Surgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0915',
    cipTitle: 'Hospitalist Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in hospital medicine. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Hospitalist Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0916',
    cipTitle: 'Neurosurgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in neurosurgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Neurosurgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0917',
    cipTitle: 'Orthopedic Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in orthopedic surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Orthopedic Surgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0918',
    cipTitle: 'Pediatric Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in pediatric surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Pediatric Surgery Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0919',
    cipTitle: 'Transplant Surgery Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in transplant surgery. This CIP Code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Transplant Surgery Physician Associate Residency/Fellowship Program., - Abdominal Organ Transplantation Physician Associate Residency Program., - Abdominal Organ Transplantation Physician Assistant Residency Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.092',
    cipTitle: 'Urology Physician Assistant Residency/Fellowship Program',
    cipDefinition:
      'A residency or fellowship program for licensed physician associates/assistants that provides advanced training in urology. This CIP Code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Urology Physician Associate Residency/Fellowship Program.',
  },
  {
    cipFamily: '60',
    cipCode: '60.0999',
    cipTitle: 'Physician Assistant Residency/Fellowship Program, Other',
    cipDefinition:
      'Any residency or fellowship program for physician associates/assistants not listed above. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Physician Associate Residency/Fellowship Program, Other.',
  },
  {
    cipFamily: '60',
    cipCode: '60.99',
    cipTitle: 'Health Professions Residency/Fellowship Programs, Other',
    cipDefinition:
      'Instructional content is defined in code 60.9999. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '60',
    cipCode: '60.9999',
    cipTitle: 'Health Professions Residency/Fellowship Programs, Other',
    cipDefinition:
      'Any health professions residency or fellowship program not listed above. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61',
    cipTitle: 'Medical Residency/Fellowship Programs',
    cipDefinition:
      'Programs that prepare allopathic physicians (MD), osteopathic physicians (DO), and podiatrists (DPM) for certification as practitioners of recognized medical specialties. These programs are approved and accredited by the Accreditation Council for Graduate Medical Education (ACGME) or the Council on Podiatric Medical Education (CPME) and require from one to seven years to complete, depending on the program. Residency programs that also result in the completion of an academic degree (e.g., MS, PhD), should be reported under one of the clinical sciences codes located in Series 26, 51.05, or 51.14, rather than in a residency code located in Series 61. These CIP Codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.01',
    cipTitle: 'Combined Medical Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0101 - 61.0199.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0101',
    cipTitle: 'Combined Medical Residency/Fellowship Program, General',
    cipDefinition:
      'A combined educational program in two or more closely related medical specialty or subspecialty programs which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0102',
    cipTitle: 'Diagnostic Radiology/Nuclear Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in diagnostic radiology and nuclear medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0103',
    cipTitle: 'Emergency Medicine/Anesthesiology Combined Specialty Program',
    cipDefinition:
      'A combined educational program in emergency medicine and anesthesiology which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0104',
    cipTitle: 'Family Medicine/Emergency Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in family medicine and emergency medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0105',
    cipTitle: 'Family Medicine/Osteopathic Neuromusculoskeletal Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in family medicine and osteopathic neuromusculoskeletal medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0106',
    cipTitle: 'Family Medicine/Preventive Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in family medicine and preventative medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0107',
    cipTitle: 'Family Medicine/Psychiatry Combined Specialty Program',
    cipDefinition:
      'A combined educational program in family medicine and psychiatry which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0108',
    cipTitle: 'Internal Medicine/Anesthesiology Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and anesthesiology which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0109',
    cipTitle: 'Internal Medicine/Dermatology Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and dermatology which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.011',
    cipTitle: 'Internal Medicine/Emergency Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and emergency medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0111',
    cipTitle: 'Internal Medicine/Emergency Medicine/Critical Care Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine, emergency medicine, and critical care medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0112',
    cipTitle: 'Internal Medicine/Family Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and family medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0113',
    cipTitle: 'Internal Medicine/Medical Genetics and Genomics Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and medical genetics and genomics which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0114',
    cipTitle: 'Internal Medicine/Neurology Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and neurology which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0115',
    cipTitle: 'Internal Medicine/Pediatrics Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and pediatrics which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0116',
    cipTitle: 'Internal Medicine/Preventive Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and preventative medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0117',
    cipTitle: 'Internal Medicine/Psychiatry Combined Specialty Program',
    cipDefinition:
      'A combined educational program in internal medicine and psychiatry which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0118',
    cipTitle: 'Medical Genetics and Genomics/Maternal-Fetal Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in medical genetics and genomics and maternal-fetal medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0119',
    cipTitle: 'Pediatrics/Anesthesiology Combined Specialty Program',
    cipDefinition:
      'A combined educational program in pediatrics and anesthesiology which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.012',
    cipTitle: 'Pediatrics/Emergency Medicine Combined Specialty Program',
    cipDefinition:
      'A combined educational program in pediatrics and emergency medicine which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0121',
    cipTitle: 'Pediatrics/Medical Genetics and Genomics Combined Specialty Program',
    cipDefinition:
      'A combined educational program in pediatrics and medical genetics and genomics which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0122',
    cipTitle: 'Pediatrics/Physical Medicine & Rehabilitation Combined Specialty Program',
    cipDefinition:
      'A combined educational program in pediatrics and physical medicine and rehabilitation which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0123',
    cipTitle: 'Pediatrics/Psychology/Child-Adolescent Psychology Combined Specialty Program',
    cipDefinition:
      'A combined educational program in pediatrics, psychology, and child-adolescent psychology which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0124',
    cipTitle: 'Psychiatry/Neurology Combined Specialty Program',
    cipDefinition:
      'A combined educational program in psychiatry and neurology which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0125',
    cipTitle: 'Reproductive Endocrinology and Infertility/Medical Genetics and Genomics Combined Specialty Program',
    cipDefinition:
      'A combined educational program in reproductive endocrinology and infertility and medical genetics and genomics which has been approved by the specialty board of each of the specialties to ensure that resident physicians completing combined training are eligible for board certification in each of the component specialties. The duration of combined training is longer than any one of its component specialty programs standing alone, and shorter than all of its component specialty programs together. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0199',
    cipTitle: 'Combined Medical Residency/Fellowship Programs, Other',
    cipDefinition: 'Any combined medical residency or fellowship program not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.02',
    cipTitle: 'Multiple-Pathway Medical Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0202 - 61.0299.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0202',
    cipTitle: 'Critical Care Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the management of care for patients with acutely life-threatening conditions which may include multiple organ failure. Includes instruction in the management of critical care units, emergency procedures and post-discharge care of former critical care patients. Requires prior completion of a residency program in anesthesiology, internal medicine, or obstetrics and gynecology. Note: critical care anesthesiology is no longer a medical subspecialty, but anesthesiologists may complete a subspecialty in critical care medicine. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Critical Care Anesthesiology Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.0203',
    cipTitle: 'Geriatric Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the care and management of elderly patients in acute, ambulatory, community and long-term care settings, and the treatment of diseases and conditions associated with the aging process. Includes instruction in ethical and legal issues, behavioral aspects of illness, socioeconomic factors in care and rehabilitation therapies. Requires prior completion of a residency program in family medicine or internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0204',
    cipTitle: 'Health Policy Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to effect change within the health care system and advocate for the practice of medicine to ultimately shape the future health care landscape on both local and national levels. Requires prior completion of an accredited medical residency program. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0205',
    cipTitle: 'Hospice and Palliative Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to prevent and relieve the suffering experienced by patients with life-limiting illnesses and to maximize the quality of life while addressing the physical, psychological, social, and spiritual needs of both patient and family throughout the course of the disease, including through the dying process and subsequent family grieving. Requires prior completion of a residency program in anesthesiology, emergency medicine, family medicine, internal medicine, neurology, child neurology, obstetrics and gynecology, pediatrics, physical medicine and rehabilitation, psychiatry, radiation oncology, or general surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0206',
    cipTitle: 'Integrative Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to practice medicine in a way that reaffirms the importance of the relationship between practitioner and patient; focuses on the whole person; is informed by evidence; and makes use of all appropriate therapeutic approaches, healthcare professionals, and disciplines to achieve optimal health and healing. Requires prior completion of an accredited medical residency program. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Integrative Geriatric Fellowship Program, - Academic Integrative Health Fellowship Program, - Integrative Health and Medicine Fellowship Program, - Lifestyle Medicine Fellowship Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.0207',
    cipTitle: 'Medical Education Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to teach deliver, design, and evaluate courses and curricula for medical students, residents, and faculty. Requires prior completion of an accredited medical residency program. This CIP code is not valid for IPEDS reporting.',
    CrossReferences: '51.3202 - Health Professions Education.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0208',
    cipTitle: 'Medical Toxicology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians who specialize in the prevention, evaluation, treatment, and monitoring of injury and illness from exposures to drugs and chemicals, as well as exposures to biological and radiological agents. Important areas include acute drug poisoning; adverse drug events; drug abuse, addiction, and withdrawal; chemicals and hazardous materials; terrorism preparedness; venomous bites and stings; and environmental and workplace exposures. Requires prior completion of a residency program in emergency medicine, pediatrics, or preventive medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0209',
    cipTitle: 'Neuromuscular Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and management of disorders of nerve, muscle, or neuromuscular junction, including amyotrophic lateral sclerosis, peripheral neuropathies, various muscular dystrophies, congenital and acquired myopathies, inflammatory myopathies, and neuromuscular transmission disorders. Requires prior completion of a residency program in neurology, child neurology, or physical medicine and rehabilitation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.021',
    cipTitle: 'Pain Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of the entire range of painful disorders, either as a primary physician or consultant, for patients experiencing problems with acute, chronic, and/or cancer pain in both hospital and ambulatory settings. Requires prior completion of a residency program in anesthesiology, neurology, child neurology, physical medicine and rehabilitation, or psychiatry. This CIP code is not valid for IPEDS reporting.',
    CrossReferences: '51.1403 - Pain Management.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0211',
    cipTitle: 'Simulation Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to develop, implement, manage, and assess healthcare simulation-based activities and programs. Requires prior completion of an accredited medical residency program. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0212',
    cipTitle: 'Sleep Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and management of clinical conditions that occur during sleep, that disturb sleep, or that are affected by disturbances in the wake-sleep cycle; in the analysis and interpretation of comprehensive polysomnography; and in the management of a sleep laboratory. Requires prior completion of a residency program in family medicine, internal medicine, neurology, child neurology, otolaryngology, pediatrics, or psychiatry. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0213',
    cipTitle: 'Sports Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the pathology and biomechanics of athletic injuries and the effects of injury on the athlete, including both physical and psychological manifestations. Includes instruction in acute and chronic patient supervision, therapy and rehabilitation, and diagnostic procedures. Requires prior completion of a residency program in emergency medicine, family medicine, internal medicine, orthopedic surgery, pediatrics, or physical medicine and rehabilitation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0214',
    cipTitle: 'Surgery of the Hand Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the investigation, preservation, and restoration by medical, surgical or physical methods of all structures of the upper extremity which directly affect the form and function of the limb, wrist, and hand. Requires prior completion of a residency program in orthopedic surgery, plastic surgery, or general surgery. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Hand Surgery Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.0215',
    cipTitle: 'Telemedicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to bring together technology and clinical medicine to enhance the overall delivery of medical care. Requires prior completion of an accredited medical residency program. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Telehealth Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.0216',
    cipTitle: 'Undersea and Hyperbaric Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to treat decompression illness and diving accident cases; to use hyperbaric oxygen therapy treatment for conditions such as carbon monoxide poisoning, gas gangrene, non-healing wounds, tissue damage from radiation and burns, and bone infections; to serve as consultant in all aspects of hyperbaric chamber operations; and to assess risks and apply appropriate standards to prevent disease and disability in divers and other persons working in altered atmospheric conditions. Requires prior completion of a residency program in emergency medicine or preventive medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0217',
    cipTitle: 'Wilderness Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to practice medicine with limited resources in austere environments or to provide healthcare anywhere in which environmental conditions have physiologic insult to a patient. Requires prior completion of a medical residency program. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0218',
    cipTitle: "Women's Health Fellowship Program.",
    cipDefinition:
      'A fellowship training program that prepares physicians to provide high-quality and culturally competent reproductive and gynecologic care to women, and to understand the interactions of biological, societal, behavioral, political, and environmental issues on the overall health of women. Requires prior completion of a residency program in emergency medicine, internal medicine, obstetrics-gynecology, or psychiatry. This CIP code is not valid for IPEDS reporting.',
    Examples: "Examples: - Global Women's Health Fellowship, - Sex and Gender Fellowship, - Women's Health Fellowship",
  },
  {
    cipFamily: '61',
    cipCode: '61.0299',
    cipTitle: 'Multiple-Pathway Medical Fellowship Programs, Other',
    cipDefinition: 'Any multiple-pathway medical fellowship program not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.03',
    cipTitle: 'Allergy and Immunology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0301 - 61.0399.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0301',
    cipTitle: 'Allergy and Immunology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the delivery of skilled medical care to patients suffering from allergic, asthmatic, and immunologic diseases. Requires completion of a prior residency program in internal medicine or pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0399',
    cipTitle: 'Allergy and Immunology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship in allergy or immunology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.04',
    cipTitle: 'Anesthesiology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0401 - 61.0499.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0401',
    cipTitle: 'Anesthesiology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the application of anesthesia for general and specialized surgery and obstetrics, critical patient care, and the care of pain problems. Includes instruction in surgical procedures, current monitoring procedures, fluid therapy, pain management, diagnostic and therapeutic procedures outside the operating room, and operating room safety. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0499',
    cipTitle: 'Anesthesiology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in anesthesiology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.05',
    cipTitle: 'Dermatology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0501 - 61.0599.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0501',
    cipTitle: 'Dermatology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the delivery of specialized care to patients with diseases of the skin, hair, nails and mucous membranes.  Includes instruction in dermatologic surgical procedures, cutaneous allergies, sexually transmitted diseases, and diagnostic and therapeutic techniques. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0502',
    cipTitle: 'Dermatopathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the clinical and microscopic diagnosis and analysis of skin diseases and disorders.  Includes instruction in laboratory administration and the supervision and training of support personnel.  Requires prior completion of a residency program in dermatology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0503',
    cipTitle: 'Pediatric Dermatology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the treatment of specific skin disease categories, with emphasis on those diseases which predominate in infants, children, and adolescents. Requires prior completion of a residency program in dermatology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0599',
    cipTitle: 'Dermatology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in dermatology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.06',
    cipTitle: 'Emergency Medicine Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0601 - 61.0699.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0601',
    cipTitle: 'Emergency Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the methods, procedures, and techniques of providing and managing immediate health care services. Includes instruction in the initial recognition, stabilization, evaluation and care of the acutely ill or injured patient; patient follow-up and referral; management of prehospital care; training and supervision of emergency medical personnel; emergency department management; medicolegal and ethical issues; and disaster planning. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0602',
    cipTitle: 'Disaster Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to provide care for the victims of natural and man-made disasters with specific consideration given to timeliness and availability of resources. Includes instruction in emergency medicine and its associated acute care specialties, public health, emergency management, humanitarian services, and prehospital emergency medical services (EMS). Requires prior completion of a residency program in emergency medicine. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Disaster Medicine and Emergency Management Fellowship',
  },
  {
    cipFamily: '61',
    cipCode: '61.0603',
    cipTitle: 'Emergency Medical Services Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the leadership and medical oversight of pre- and out-of-hospital emergency care systems with advanced competencies in Emergency Medical System (EMS) system design, administration, and clinical care. Includes instruction in clinical aspects of EMS, medical oversight of EMS, mass casualty management,  disaster planning and operations, wilderness EMS systems, and quality management and research. Requires prior completion of a residency program in emergency medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0699',
    cipTitle: 'Emergency Medicine Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in emergency medicine not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.07',
    cipTitle: 'Family Medicine Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0701 - 61.0799.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0701',
    cipTitle: 'Family Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the provisions of regular and long-term care to individuals and family members.  Includes instruction in comprehensive care and specialist referral, basic surgery, emergency medical procedures, and diagnostic imaging and practice management. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0799',
    cipTitle: 'Family Medicine Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in family medicine not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.08',
    cipTitle: 'Internal Medicine Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0801 - 61.0899.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0801',
    cipTitle: 'Internal Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the provision of general medical services to adult patients with a wide range of non-surgical clinical problems.  Includes instruction in behavioral aspects of diseases, patient and family counseling and practice management. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0804',
    cipTitle: 'Cardiovascular Disease Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to specialize in diseases of the heart and blood vessels and to manage complex cardiac conditions such as heart attacks and life-threatening, abnormal heartbeat rhythms. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Cardiology Fellowship Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.0805',
    cipTitle: 'Clinical Cardiac Electrophysiology Fellowship Program',
    cipDefinition:
      'A fellowship training program, within the subspecialty of cardiovascular disease, which prepares physicians in intricate technical procedures to evaluate heart rhythms and determine appropriate treatment. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0806',
    cipTitle: 'Endocrinology, Diabetes, and Metabolism Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to specialize in disorders of the internal (endocrine) glands such as the thyroid and adrenal glands, and to deal with disorders such as diabetes, metabolic and nutritional disorders, obesity, pituitary diseases, and menstrual and sexual problems. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Endocrinology and Metabolism Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.0807',
    cipTitle: 'Gastroenterology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of patients with digestive and other gastrointestinal disorders. Includes instruction in surgical procedures, cancer of the digestive system, nutrition and malnutrition, and counseling the behavioral adjustment of patients with chronic problems. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0808',
    cipTitle: 'Hematology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the mechanisms and therapy of diseases of the blood, bone marrow, spleen, and lymph, including patient management, diagnostic tests, biopsies, and other procedures. Requires prior completion of a residency program in internal medicine or pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0809',
    cipTitle: 'Hematology-Oncology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of cancer, benign and malignant tumors, and diseases of the blood, bone marrow, spleen, and lymph. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.081',
    cipTitle: 'Infectious Disease Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the natural history, prevention and treatment of major infectious diseases, including sexually transmitted diseases, and the long-term management of patients. Includes instruction in epidemiology, identification and specimen collection techniques, and quality assurance and cost containment. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0811',
    cipTitle: 'Interventional Cardiology Fellowship Program',
    cipDefinition:
      'A fellowship training program, within the subspecialty of cardiology, which trains physicians in the use of specialized imaging and other diagnostic techniques to evaluate blood flow and pressure in the coronary arteries and chambers of the heart, and in the use of technical procedures and medications to treat abnormalities that impair the function of the cardiovascular system. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0812',
    cipTitle: 'Nephrology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the pathogenesis, natural history, and management of congenital and acquired diseases of the kidney and urinary tract and renal diseases associated with systemic disorders. Includes instruction in organ transplantation and dialysis therapy. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0813',
    cipTitle: 'Medical Oncology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of all types of cancer and other benign and malignant tumors; this includes deciding on and administering therapy for these malignancies, as well as consulting with surgeons and radiotherapists on other treatments for cancer. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0814',
    cipTitle: 'Pulmonary Disease Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in pulmonary physiology and the treatment of lung diseases, pulmonary malignancies, related vascular diseases, respiratory disorders, diagnostic and therapeutic procedures, and pulmonary pathology. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0816',
    cipTitle: 'Rheumatology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of patients with acute and chronic rheumatic diseases, diffuse connective tissue diseases, systemic and metabolic diseases and infections and complications following surgery. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0818',
    cipTitle: 'Transplant Hepatology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians with special knowledge and the skill required of a gastroenterologist to care for patients prior to and following hepatic transplantation that spans all phases of liver transplantation. Requires prior completion of a residency program in internal medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0899',
    cipTitle: 'Internal Medicine Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in internal medicine not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.09',
    cipTitle: 'Medical Genetics and Genomics Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.0901 - 61.0999.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0901',
    cipTitle: 'Clinical Biochemical Genetics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to perform and interpret biochemical analyses relevant to the diagnosis and management of human genetic diseases, and to serve as a consultant regarding laboratory diagnosis of a broad range of inherited disorders. One of three general certificates in the area of medical genetics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0902',
    cipTitle: 'Clinical Genetics and Genomics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to provide comprehensive diagnostic, management, and counseling services for individuals and families at risk for clinical disorders with a genetic basis. One of three general certificates in the area of medical genetics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0903',
    cipTitle: 'Laboratory Genetics and Genomics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to direct and interpret both clinical cytogenetic and molecular genetic analyses relevant to the diagnosis and management of human genetic diseases. One of three general certificates in the area of medical genetics. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Clinical Cytogenetics and Genomics Residency Program, - Clinical Molecular Genetics and Genomics Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.0904',
    cipTitle: 'Medical Biochemical Genetics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis and medical management of individuals with inborn errors of metabolism, including the provision of direct care and consultative care for individuals of all ages. Requires prior completion of a residency program in medical genetics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.0999',
    cipTitle: 'Medical Genetics and Genomics Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in medical genetics and genomics not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.10',
    cipTitle: 'Neurological Surgery Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1001 - 61.1099.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1001',
    cipTitle: 'Neurological Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the prevention, diagnosis, evaluation, treatment, critical care, and rehabilitation of disorders of the central, peripheral, and autonomic nervous systems, using operative and non-operative means; the evaluation and treatment of pathological processes that modify the function or activity of the nervous system; and the management of pain. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Neurological Surgery/Neurosurgery Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.1099',
    cipTitle: 'Neurological Surgery Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in neurological surgery not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.11',
    cipTitle: 'Neurology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1101 - 61.1199.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1101',
    cipTitle: 'Neurology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis and treatment of all types of disease or impaired function of the brain, spinal cord, peripheral nerves, muscles and autonomic nervous system, as well as the blood vessels that relate to these structures. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1102',
    cipTitle: 'Child Neurology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis and management of neurologic disorders of the neonatal period, infancy, early childhood, and adolescence. Requires training in neurology with additional training in child neurology. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Neurology with Special Qualifications in Child Neurology Residency Program, - Pediatric Neurology Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.1103',
    cipTitle: 'Clinical Neurophysiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and management of central, peripheral, and autonomic nervous system disorders using a combination of clinical evaluation and electrophysiologic testing such as electroencephalography (EEG), electromyography (EMG), and nerve conduction studies (NCS). Requires prior completion of a residency program in neurology, child neurology, or psychiatry. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1104',
    cipTitle: 'Epilepsy Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of patients with epileptic seizures or with symptoms and signs whose differential diagnosis includes epileptic seizures. Requires prior completion of a residency program in neurology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1105',
    cipTitle: 'Headache Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis, treatment, and research of headache and related disorders. Requires prior completion of a residency program in neurology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1106',
    cipTitle: 'Neurodevelopmental Disabilities Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to treat children having developmental delays or learning disorders, including those associated with visual and hearing impairment, mental retardation, cerebral palsy, spina bifida, autism, and other chronic neurologic conditions. Requires prior completion of a residency program in neurology, child neurology, or pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1107',
    cipTitle: 'Vascular Neurology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the evaluation, prevention, treatment, and recovery from vascular diseases of the nervous system resulting from a large number of causes, such as ischemic stroke, intracranial hemorrhage, spinal cord ischemia, and spinal cord hemorrhage. Requires prior completion of a residency program in neurology or child neurology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1199',
    cipTitle: 'Neurology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in neurology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.12',
    cipTitle: 'Nuclear Medicine Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1201 - 61.1299.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1201',
    cipTitle: 'Nuclear Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to use the tracer principle, most often with radiopharmaceuticals, to evaluate molecular, metabolic, physiologic and pathologic conditions of the body for the purposes of diagnosis, therapy and research. Includes instruction in tracer kinetics, the biologic effects of radiation exposure, the fundamentals of the physical sciences, and the principles and operation of radiation detection and imaging systems. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1299',
    cipTitle: 'Nuclear Medicine Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in nuclear medicine not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.13',
    cipTitle: 'Obstetrics and Gynecology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1301 - 61.1399.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1301',
    cipTitle: 'Obstetrics and Gynecology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis, prevention and treatment of diseases of women, especially those affecting the reproductive system, and the comprehensive care and treatment of women before, during and after childbirth. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1302',
    cipTitle: 'Gynecologic Oncology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians for consultation and comprehensive management of patients with gynecologic cancer, including those diagnostic and therapeutic procedures necessary for the total care of the patient with gynecologic cancer and resulting complications. Requires prior completion of a residency program in obstetrics and gynecology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1303',
    cipTitle: 'Maternal and Fetal Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to care for, or provide consultation on, patients with complications of pregnancy, based on advanced knowledge of the obstetrical, medical, and surgical complications of pregnancy, and their effect on both the mother and the fetus, and expertise in the most current diagnostic and treatment modalities used in the care of patients with complicated pregnancies. Requires prior completion of a residency program in obstetrics and gynecology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1304',
    cipTitle: 'Reproductive Endocrinology/Infertility Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to manage complex problems relating to reproductive endocrinology and infertility. Requires prior completion of a residency program in obstetrics and gynecology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1399',
    cipTitle: 'Obstetrics and Gynecology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in obstetrics and gynecology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.14',
    cipTitle: 'Ophthalmology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1401 - 61.1499.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1401',
    cipTitle: 'Ophthalmology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis, prevention treatment of ophthalmic diseases and disorders, and ocular pathology procedures.  Includes instruction in eye surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1499',
    cipTitle: 'Ophthalmology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in ophthalmology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.15',
    cipTitle: 'Orthopedic Surgery Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1501 - 61.1599.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1501',
    cipTitle: 'Orthopedic Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the investigation, preservation, and restoration of the form and function of the extremities, spine and associated structures by medical, surgical and physical methods. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Orthopedics Residency Program, - Adult Reconstructive Orthopedics Residency Program, - Orthopaedic Surgery Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.1502',
    cipTitle: 'Musculoskeletal Oncology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of musculoskeletal neoplasm, and the application of cancer therapy regimes. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1503',
    cipTitle: 'Orthopedic Sports Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to prevent and manage athletic injuries and to provide appropriate care for all structures of the musculoskeletal system directly affected by participation in sporting activity. Requires prior completion of a residency program in orthopedic surgery. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Orthopaedic Sports Medicine Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.1504',
    cipTitle: 'Orthopedic Surgery of the Spine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares individuals for the study, prevention and treatment of spinal column disease, disorders, and injuries by medical, physical and surgical methods. Includes instruction in pathogenesis and diagnosis of clinical disorders of the spine; non-operative treatment modalities;  invasive modalities; basic sciences related to the musculoskeletal system; spinal degeneration; spinal neurologic function; pre- and post-operative management; and patient evaluation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1505',
    cipTitle: 'Pediatric Orthopedics Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis, surgical and non-surgical treatment, and management of musculoskeletal diseases, abnormalities and trauma in infants, children, and adolescents.  Requires prior completion of a residency program in orthopedic surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1599',
    cipTitle: 'Orthopedic Surgery Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in orthopedic surgery not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.16',
    cipTitle: 'Osteopathic Medicine Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1601 - 61.1699.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1601',
    cipTitle: 'Osteopathic Neuromusculoskeletal Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares osteopathic (DO) physicians to perform osteopathic neuromusculoskeletal diagnosis and perform Osteopathic Manipulative Treatment (OMT). This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1699',
    cipTitle: 'Osteopathic Medicine Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in osteopathic medicine not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.17',
    cipTitle: 'Otolaryngology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1701 - 61.1799.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1701',
    cipTitle: 'Otolaryngology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to diagnose and provide medical and/or surgical therapy or prevention of diseases, allergies, neoplasms, deformities, disorders, and injuries of the ears, nose, sinuses, throat, respiratory and upper alimentary systems, face, jaws, and the other head and neck systems. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1702',
    cipTitle: 'Neurotology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to treat diseases of the ear and temporal bone, including disorders of hearing and balance. Requires prior completion of a residency program in otolaryngology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1703',
    cipTitle: 'Pediatric Otolaryngology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to diagnose, treat and manage disorders of infants and children that include congenital and acquired conditions involving the aerodigestive tract, nose and paranasal sinuses, the ear, and other areas of the head and neck; this includes special skills in the diagnosis, treatment, and management of childhood disorders of voice, speech, language, and hearing. Requires prior completion of a residency program in otolaryngology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1799',
    cipTitle: 'Otolaryngology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in otolaryngology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.18',
    cipTitle: 'Pathology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1801 - 61.1899.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1801',
    cipTitle: 'Combined Anatomic and Clinical Pathology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the clinical laboratory analysis and diagnosis of disease and anatomic abnormalities. Includes instruction in performing general autopsies, forensic medicine, laboratory management, and quality control. This CIP code is not valid for IPEDS reporting.',
    Examples:
      'Examples: - Anatomic Pathology Residency Program, - Clinical Pathology Residency Program, - Anatomic Pathology and Clinical Pathology Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.1802',
    cipTitle: 'Anatomic Pathology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis of disease and anatomic abnormalities. Includes instruction in autopsy pathology, cytology, diagnostic surgical pathology, forensic medicine, laboratory management, and quality control. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1803',
    cipTitle: 'Clinical Pathology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the clinical laboratory analysis and diagnosis of disease. Includes instruction in performing clinical chemistry, cytogenetics, immunology, laboratory management, microbiology, molecular biology, quality control, serology, toxicology, and virology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1804',
    cipTitle: 'Blood Banking/Transfusion Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians for the maintenance of an adequate blood supply, blood donor and patient-recipient safety, and appropriate blood utilization.  Includes directing the preparation and safe use of specially prepared blood components, including red blood cells, white blood cells, platelets and plasma constituents, and marrow or stem cells for transplantation. Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Blood Banking Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.1805',
    cipTitle: 'Chemical Pathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the use and interpretation of chemical analyses, chemical diagnoses and management, and the supervision of chemical pathology laboratories.  Includes instruction in basic and applied analytical chemical pathology; instrumentation data processing and chemical laboratory management. Requires prior completion of a residency program in medical pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1806',
    cipTitle: 'Cytopathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares individuals to study, prevent, and manage human diseases manifested in cells. Includes instruction in laboratory procedures, laboratory management, quality assurance, screening, specimen collection, cytopreparation, cytometric and image analysis, and related immunological and molecular techniques. Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1807',
    cipTitle: 'Forensic Pathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the performance of medical autopsies, the analysis of human remains and crime scenes, and the legal follow-up and responsibilities of public pathologists.  Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1808',
    cipTitle: 'Hematological Pathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the laboratory and analytical procedures for studying all facets of hematologic and coagulation disorders.  Includes instruction in the management of hematology laboratories. Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Hematopathology',
  },
  {
    cipFamily: '61',
    cipCode: '61.1809',
    cipTitle: 'Immunopathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis, treatment and laboratory management of immunologic diseases. Includes instruction in diagnostic surgical pathology, management of organ transplantation and immunotherapy.  Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.181',
    cipTitle: 'Laboratory Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the principles and practices of applied medical research and related techniques, equipment, data systems, and research design. Includes instruction in the management of medical laboratories in research and healthcare facilities. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1811',
    cipTitle: 'Medical Microbiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the isolation and identification of microbial agents that cause infectious disease, including viruses, bacteria, fungi, and parasites. Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1812',
    cipTitle: 'Molecular Genetic Pathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to make or confirm diagnoses of Mendelian genetic disorders, disorders of human development, infectious diseases and malignancies, and to assess the natural history of those disorders, using the principles, theory, and technologies of molecular biology and molecular genetics. Requires prior completion of a residency program in medical genetics or pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1813',
    cipTitle: 'Neuropathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the laboratory analysis of nerve tissues and the clinical diagnosis of neurological and neuromuscular diseases.  Includes instruction in nerve biopsies and necropsies.  Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1814',
    cipTitle: 'Pediatric Pathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the laboratory diagnosis of diseases that occur during fetal growth, infancy, and child development, based on knowledge of general pathology, normal growth and development, and pediatric medicine. Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1815',
    cipTitle: 'Radioisotopic Pathology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the use of radionuclides in the study of body fluids, excreta, or tissues quantified outside the body.  Includes instruction in laboratory management and safety, quality control, instrumentation, isotope storage and in vitro analyses. Requires prior completion of a residency program in pathology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1899',
    cipTitle: 'Pathology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in pathology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.19',
    cipTitle: 'Pediatrics Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.1901 - 61.1999.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1901',
    cipTitle: 'Pediatrics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the comprehensive care and treatment of all aspects of human growth and development from conception through fetal life, infancy, childhood and adolescence, including preventive and therapeutic regimes.  Includes instruction in behavioral aspects of illness, related family and community medicine issues, and emergency and critical care procedures. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1902',
    cipTitle: 'Adolescent Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the unique physical, psychological, and social characteristics of adolescents and their healthcare problems and needs. Requires prior completion of a residency program in family medicine, internal medicine, or pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1903',
    cipTitle: 'Child Abuse Pediatrics Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to accurately diagnose abuse, consult with community agencies on child safety, provide expertise in courts of law, treat consequences of abuse and neglect, direct child abuse and neglect prevention programs and participate on multidisciplinary investigative teams, and manage child abuse cases. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1904',
    cipTitle: 'Developmental-Behavioral Pediatrics Fellowship Program',
    cipDefinition:
      "A fellowship training program that prepares physicians to assist in the prevention, diagnosis, and management of developmental difficulties and problematic behaviors in children, and in the family dysfunctions that compromise children's development; and to foster understanding and promotion of optimal development of children and families through research, education, clinical care, and advocacy efforts. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.",
  },
  {
    cipFamily: '61',
    cipCode: '61.1905',
    cipTitle: 'Neonatal-Perinatal Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the physiology of the normal neonate, the pathophysiology of the sick infant, and the diagnosis and management of problems of the newborn infant, including consulting with obstetricians to plan for the care of women with high-risk pregnancies. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1906',
    cipTitle: 'Pediatric Cardiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and management of diseases and disorders of the cardiovascular and cardiopulmonary systems of infants, children, and adolescents.  Includes instruction in related public health and community medicine issues.  Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1907',
    cipTitle: 'Pediatric Critical Care Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in advanced life support for children, from the term or near-term neonate to the adolescent. This includes critical care management of life-threatening organ system failure in both medical and surgical patients and support of vital physiological functions. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1908',
    cipTitle: 'Pediatric Emergency Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to manage emergencies in infants and children. Requires prior completion of a residency program in emergency medicine or pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1909',
    cipTitle: 'Pediatric Endocrinology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and management of endocrine diseases and the regulation of hormone balance in childhood and adolescence.  Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.191',
    cipTitle: 'Pediatric Gastroenterology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of diseases of the digestive systems of infants, children, and adolescents; includes treatment of conditions such as abdominal pain, ulcers, diarrhea, cancer, and jaundice, and performance of complex diagnostic and therapeutic procedures using lighted scopes to see internal organs. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1911',
    cipTitle: 'Pediatric Hematology-Oncology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and management of hematologic disorders and malignant diseases, including blood and bone marrow function, in infancy, childhood, and adolescence. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Pediatric Hemato-Oncology Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.1912',
    cipTitle: 'Pediatric Infectious Diseases Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis, treatment, and prevention of infectious diseases in children; and to effect a better outcome for pediatric infections with complicated courses, underlying diseases that predispose to unusual or severe infections, unclear diagnoses, uncommon diseases, and complex or investigational treatments. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1913',
    cipTitle: 'Pediatric Nephrology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and management of infants, children, and adolescents with renal and genito-urinary problems, hypertension and disorders of body fluid physiology.  Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1914',
    cipTitle: 'Pediatric Pulmonology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the prevention and treatment of all respiratory diseases affecting infants, children, and young adults, based on knowledge of the growth and development of the lung, assessment of respiratory function in infants and children, and experience in a variety of invasive and noninvasive diagnostic techniques. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1915',
    cipTitle: 'Pediatric Rheumatology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to diagnose and treat diseases of joints, muscle, bones, and tendons in children and young adults, including arthritis, back pain, muscle strains, common athletic injuries, and collagen diseases. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1917',
    cipTitle: 'Pediatric Transplant Hepatology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians with special knowledge, skill, and expertise required of pediatric gastroenterologists to care for children and young adults prior to and following hepatic transplantation. Requires prior completion of a residency program in pediatrics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.1999',
    cipTitle: 'Pediatrics Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in pediatrics not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.20',
    cipTitle: 'Physical Medicine and Rehabilitation Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2001 - 61.2099.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2001',
    cipTitle: 'Physical Medicine and Rehabilitation Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis, etiology, treatment, prevention and rehabilitation of neuromusculo-skeletal, cardiovascular, pulmonary and other system disorders common to patients of both sexes and all ages.  Includes instruction in physical examinations, design and prescription of rehabilitation strategies and the supervision of rehabilitation teams. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Physical and Rehabilitation Medicine Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.2002',
    cipTitle: 'Spinal Cord Injury Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to address the prevention, diagnosis, treatment, and management of traumatic spinal cord injury and non-traumatic etiologies of spinal cord dysfunction, including related medical, physical, psychological, and vocational disabilities and complications. Requires prior completion of a residency program in physical medicine and rehabilitation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2003',
    cipTitle: 'Pediatric Rehabilitation Medicine Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to use an interdisciplinary approach to prevent, diagnose, treat, and manage congenital and childhood-onset physical impairments in children and young adults, including related medical, physical, functional, psychosocial, and vocational limitations or conditions. Requires prior completion of a residency program in physical medicine and rehabilitation. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2099',
    cipTitle: 'Physical Medicine and Rehabilitation Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in physical medicine and rehabilitation not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.21',
    cipTitle: 'Plastic Surgery Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2101 - 61.2199.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2101',
    cipTitle: 'Plastic Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the repair, replacement, and reconstruction of defects of form and function of the integument and its underlying musculoskeletal system, with emphasis on the craniofacial structures, the oropharynx, the hand, the breast, and the external genitalia. Includes instruction in aesthetic as well as reconstructive surgery. Requires prior completion of a program in general surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2102',
    cipTitle: 'Integrated Plastic Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the repair, replacement, and reconstruction of defects of form and function of the integument and its underlying musculoskeletal system, with emphasis on the craniofacial structures, the oropharynx, the hand, the breast, and the external genitalia. Includes instruction in aesthetic as well as reconstructive surgery. Does NOT require prior completion of a program in general surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2103',
    cipTitle: 'Plastic Surgery Within the Head and Neck Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in plastic and reconstructive procedures within the head, face, neck, and associated structures, including cutaneous head and neck oncology and reconstruction, management of maxillofacial trauma, soft tissue repair, and neural surgery. Requires prior completion of a residency program in otolaryngology or plastic surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2199',
    cipTitle: 'Plastic Surgery Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in plastic surgery not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.22',
    cipTitle: 'Podiatric Medicine Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2201 - 61.2299.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2201',
    cipTitle: 'Podiatric Medicine and Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares podiatrists for primary practice in podiatric healthcare and foot surgery. This includes diagnosis and treatment of conditions affecting the human foot, ankle, and their governing and related structures, including the leg, and the local manifestations of systemic conditions, by all appropriate systems and means. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2299',
    cipTitle: 'Podiatric Medicine Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in podiatric medicine not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.23',
    cipTitle: 'Preventive Medicine Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2301 - 61.2399.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2301',
    cipTitle: 'Public Health and General Preventive Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to promote health, prevent disease, and manage the health of communities and defined populations; combines population-based public health skills with knowledge of primary, secondary, and tertiary prevention-oriented clinical practice in a wide variety of settings. One of three general certificates in the area of preventive medicine. Note: this medical specialty merges the previous subspecialties of preventive medicine and public health medicine. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Public Health Medicine Residency Program, - Preventive Medicine Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.2302',
    cipTitle: 'Aerospace Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the health care of operating crews, support personnel, and passengers of air and space vehicles. Includes instruction in special conditions of physical and psychological stress, emergency medical procedures, adaptive systems and artificial environments. One of three general certificates in the area of preventive medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2303',
    cipTitle: 'Occupational Medicine Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the maintenance of the health of workers, the ability to perform work, the arrangements of work, and the physical and chemical environments of the workplace. Includes instruction in data collection and management and the administration and regulation of occupational medical services.  One of three general certificates in the area of preventive medicine. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2399',
    cipTitle: 'Preventive Medicine Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in preventive medicine not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.24',
    cipTitle: 'Psychiatry Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2401 - 61.2499.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2401',
    cipTitle: 'Psychiatry Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnosis, treatment, and prevention of mental, emotional, behavioral and neurological disorders.  Includes instruction in psychotherapy, family counseling, referral, clinical diagnosis, and practice management. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2402',
    cipTitle: 'Addiction Psychiatry Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the evaluation and treatment of individuals with alcohol, drug, or other substance-related disorders, and of individuals with the dual diagnosis of substance-related and other psychiatric disorders. Requires prior completion of a residency program in psychiatry. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2403',
    cipTitle: 'Child and Adolescent Psychiatry Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of developmental, behavioral, emotional, and mental disorders of childhood and adolescence. Requires prior completion of a residency program in psychiatry. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Child Psychiatry Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.2404',
    cipTitle: 'Consultation-Liaison Psychiatry Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis and treatment of psychiatric disorders and symptoms in complex medically ill patients. This includes treatment of patients with acute or chronic medical, neurological, obstetrical, or surgical illness in which psychiatric illness is affecting their medical care and/or quality of life, and patients who have a psychiatric disorder that is the direct consequence of a primary medical condition, a somatoform disorder, or psychological factors affecting a general medical condition. Requires prior completion of a residency program in psychiatry. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Psychosomatic Medicine',
  },
  {
    cipFamily: '61',
    cipCode: '61.2405',
    cipTitle: 'Forensic Psychiatry Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians to evaluate individuals involved with the legal system and provide specialized treatment to those incarcerated in jails, prisons, and forensic psychiatry hospitals; and who focus on the interrelationships between psychiatry and civil, criminal, and administrative law. Requires prior completion of a residency program in psychiatry. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2406',
    cipTitle: 'Geriatric Psychiatry Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the prevention, evaluation, diagnosis, and treatment of mental and emotional disorders in the elderly, and to improve the psychiatric care of the elderly both in health and in disease. Requires prior completion of a residency program in psychiatry. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2499',
    cipTitle: 'Psychiatry Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in psychiatry not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.25',
    cipTitle: 'Radiation Oncology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2501 - 61.2599.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2501',
    cipTitle: 'Radiation Oncology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the use of ionizing radiation to treat patients with cancer and other diseases. Includes instruction in treatment planning, instrument design and operation, radiation physics and radiobiology. One of two general certificates in the area of radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2599',
    cipTitle: 'Radiation Oncology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in radiation oncology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.26',
    cipTitle: 'Radiology Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2601 - 61.2699.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2601',
    cipTitle: 'Diagnostic Radiology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to utilize x-ray, ionizing radiation, radionuclides, ultrasound, electromagnetic radiation, and image-guided intervention to diagnose and treat disease. One of two general certificates in the area of radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2602',
    cipTitle: 'Integrated Interventional Radiology Residency Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in image-guided therapies, using fluoroscopy, digital radiography, computed tomography, sonography, and magnetic resonance imaging, to guide small devices inserted into the body through very small openings. These therapies include angioplasty, stent placement, thrombolysis, embolization, biliary and genitourinary drainages, and abscess drainages. Does NOT require prior completion of a residency program in diagnostic radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2603',
    cipTitle: 'Abdominal Radiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares diagnostic radiologists in the diagnosis and treatment of disorders of the abdomen and pelvis in adults and children. Requires prior completion of a residency program in diagnostic or interventional radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2604',
    cipTitle: 'Diagnostic Radiologic Physics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the diagnostic applications of x-rays, gamma rays from sealed sources, ultrasonic radiation, and magnetic resonance; and in the use of equipment associated with their production and use, including radiation safety. One of the three subspecialties available to residents in radiologic physics who must select one or more subspecialty areas. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2605',
    cipTitle: 'Medical Nuclear Physics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the therapeutic and diagnostic applications of radionuclides (except those used in sealed sources for therapeutic purposes); and in the use of equipment associated with their production and use, including radiation safety. One of the three subspecialties available to residents in radiologic physics who must select one or more subspecialty areas. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2606',
    cipTitle: 'Musculoskeletal Radiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares diagnostic radiologists in the diagnosis and treatment of disorders or injuries of the joints, bones, muscles, and spine in adults and children. Requires prior completion of a residency program in diagnostic or interventional radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2607',
    cipTitle: 'Neuroradiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares diagnostic radiologists in the diagnosis and treatment of disorders of the brain, spine, spinal cord, peripheral nerves, and head and neck in adults and children. Requires prior completion of a residency program in diagnostic radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2608',
    cipTitle: 'Nuclear Radiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the imaging by external detection of radionuclides and/or biodistribution by external detection of radionuclides for diagnosis of disease.  Requires prior partial completion of a residency program in diagnostic radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2609',
    cipTitle: 'Pediatric Radiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in all forms of diagnostic imaging as it pertains to the diagnosis and treatment of diseases in the newborn, infant, child, and adolescent. Requires prior completion of a residency program in diagnostic radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.261',
    cipTitle: 'Radiologic Physics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in that branch of physics which includes the physical aspects of radiation therapy, radiologic diagnosis, and/or nuclear medicine. Radiologic physicists must specialize in at least one of the three subspecialty areas: therapeutic radiological physics, diagnostic radiological physics, and/or medical nuclear physics. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2611',
    cipTitle: 'Therapeutic Radiologic Physics Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to deal with the physical aspects of the therapeutic applications of x-rays, gamma rays, electron, and other charged particle beams, neutrons, and radiations from sealed radionuclide sources; and with the equipment associated with their production and use, including radiation safety. One of the three subspecialties available to residents in radiologic physics who must select one or more subspecialty areas. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2612',
    cipTitle: 'Vascular and Interventional Radiology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares diagnostic radiologists with additional training in image-guided therapies, using fluoroscopy, digital radiography, computed tomography, sonography, and magnetic resonance imaging, to guide small devices inserted into the body through very small openings. These therapies include angioplasty, stent placement, thrombolysis, embolization, biliary and genitourinary drainages, and abscess drainages. Requires prior completion of a residency program in diagnostic radiology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2699',
    cipTitle: 'Radiology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any medical residency or fellowship in radiology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.27',
    cipTitle: 'Surgery Residency/Fellowship Programs',
    cipDefinition: 'Instructional content for this group of programs is defined in codes 61.2701 - 61.2799.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2701',
    cipTitle: 'General Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the care and treatment of diseases and disorders via invasive procedures and the etiology, pathogenesis, diagnosis and management of physical disorders.  Includes instruction in clinical and operative skills, pre-operative and post-operative care, trauma management and endoscopic techniques. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Surgery Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.2702',
    cipTitle: 'Colon and Rectal Surgery Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians in the surgical care of patients with anorectal and colonic diseases.  Also includes instruction in diagnostic and therapeutic colonoscopy.  Requires prior or concurrent completion of a residency program in general surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2703',
    cipTitle: 'Complex General Surgical Oncology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares surgeons in the management of patients with difficult, rare or complex cancer presentations, including clinical evaluation, surgical management, and adjuvant therapy. Requires prior completion of a residency program in general surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2704',
    cipTitle: 'Congenital Cardiac Surgery Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the operative treatment of structural abnormalities involving the heart and major blood vessels, including defects resulting from congenital malformations as well as defects acquired in infants and children; includes care of patients from the fetus to the adult. Requires prior completion of a residency or fellowship program in thoracic surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2705',
    cipTitle: 'Pediatric Surgery Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the diagnosis, evaluation and surgical treatment of diseases, disorders, and trauma in infants and children.  Requires prior completion of a residency program in general surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2706',
    cipTitle: 'Surgical Critical Care Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares surgeons in the diagnosis, treatment, and support of critically ill and injured patients, particularly trauma victims and patients with multiple organ dysfunction. Requires prior completion of a residency program in general surgery. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Critical Care Surgery Residency Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.2707',
    cipTitle: 'Thoracic Surgery Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the evaluation and surgical treatment of pulmonary, esophageal, mediastinal, chest wall, diaphragmatic and cardiovascular disorders.  Includes instruction in bronchoscopy and esophagoscopy. Requires prior or concurrent completion of a residency program in general surgery. This CIP code is not valid for IPEDS reporting.',
    Examples: 'Examples: - Integrated Thoracic Surgery Fellowship Program',
  },
  {
    cipFamily: '61',
    cipCode: '61.2708',
    cipTitle: 'Vascular Surgery Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares physicians in the surgical treatment of diseases and disorders of the arterial, venous and lymphatic circulatory systems and of the heart and thoracic aorta. Requires prior or concurrent completion of a residency program in general surgery. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2799',
    cipTitle: 'Surgery Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in surgery not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.28',
    cipTitle: 'Urology Residency/Fellowship Programs',
    cipDefinition:
      'Instructional content for this group of programs is defined in codes 61.2801 - 61.2899. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2801',
    cipTitle: 'Urology Residency Program',
    cipDefinition:
      'A residency training program that prepares physicians to manage congenital and acquired conditions of the genitourinary system, the adrenal gland, and other contiguous structures throughout the lifespan and which may be benign or malignant. This specialist is skilled in medical and open surgical therapy of these conditions, as well as endoscopic, percutaneous, and other minimally invasive methods. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2802',
    cipTitle: 'Pediatric Urology Fellowship Program',
    cipDefinition:
      'A fellowship training program that prepares individuals in the study, prevention, and management of congenital, childhood-acquired, and overlapping adolescent anomalies of the genitourinary tract, including problems, diseases, tumors, and traumas. Requires prior completion of a residency program in urology. This CIP code is not valid for IPEDS reporting.',
  },
  {
    cipFamily: '61',
    cipCode: '61.2899',
    cipTitle: 'Urology Residency/Fellowship Programs, Other',
    cipDefinition: 'Any residency or fellowship program in urology not listed above.',
  },
  {
    cipFamily: '61',
    cipCode: '61.99',
    cipTitle: 'Medical Residency/Fellowship Programs, Other',
    cipDefinition: 'Instructional content is defined in code 61.9999.',
  },
  {
    cipFamily: '61',
    cipCode: '61.9999',
    cipTitle: 'Medical Residency/Fellowship Programs, Other',
    cipDefinition:
      'Any medical residency or fellowship program not listed above. This CIP code is not valid for IPEDS reporting.',
  },
];
