import { SurveyResult } from './model';

export enum TenantSharingType {
  ENROLLMENT = 'Enrollment',
  MARKETING = 'Marketing',
}

// Get FE to stop using these, the BE doesn't use them. Use FavoriteSurvey from model.ts instead
export interface FavoriteMajorSurvey {
  surveyId: number;
  majorId: string;
  questions: SurveyResult[];
}

export interface FavoriteCollegeSurvey {
  surveyId: number;
  collegeId?: number;
  questions: SurveyResult[];
}
