import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const SHOW_ERRORS_PARAM = 'showErrors';

const removeShowErrorsParam = (searchParams: URLSearchParams) => {
  if (searchParams.has(SHOW_ERRORS_PARAM)) {
    searchParams.delete(SHOW_ERRORS_PARAM);
  }
};

export const useRemoveShowErrorOnUnmount = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    return () => {
      removeShowErrorsParam(searchParams);
      setSearchParams(searchParams);
    };
  }, [searchParams, setSearchParams]);
};

export const removeShowErrorsFromUrl = () => {
  const url = new URL(window.location.href);
  removeShowErrorsParam(url.searchParams);
  window.history.replaceState({}, '', url.toString());
};

/**
 * Check localStorage for debug flag and redirect if not present
 * @param url The URL to redirect to
 * @param method The redirect method to use ('replace' or 'assign')
 * @param localStorageKey The localStorage key to check (defaults to 'debug_noredirect')
 * @returns true if redirect occurred, false if prevented by debug flag
 * @description to set the debug flag, run `localStorage.setItem('debug_noredirect', 'true')`
 */
export const conditionalRedirect = (
  url: string,
  method: 'replace' | 'assign' = 'replace',
  localStorageKey = 'debug_noredirect',
): boolean => {
  // Check if the debug flag is set in localStorage
  const debugNoRedirect = localStorage.getItem(localStorageKey);

  // If debug flag is set, don't redirect
  if (debugNoRedirect) {
    // Log that redirect was prevented
    console.log(`Navigation prevented by debug flag: ${localStorageKey}. Would have redirected to: ${url}`);
    return false;
  }

  // Otherwise, perform the redirect
  if (method === 'replace') {
    window.location.replace(url);
  } else {
    window.location.assign(url);
  }

  return true;
};
