import { z } from 'zod';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import {
  AdmissionStatus,
  AwardTypeEnum,
  ApplicationStatus,
  ApplicationType,
  DeadlineType,
  Decision,
  MajorListSort,
  ScholarshipSort,
  ScholarshipStatus,
  Season,
  SortDirection,
  TaskTags,
} from '../enums';

extendZodWithOpenApi(z);

const minimumYear = 2020;
const maximumYear = new Date().getFullYear();
const longitudeLimit = 180;
const latitudeLimit = 90;

export const TYPE_MONGO_ID = 'mongoIdValidator';
export const TYPE_DATE_OR_STRING = 'dateOrStringValidator';

export const mongoIdValidator = z.string().openapi({ type: 'string' }).describe(TYPE_MONGO_ID);
export const dateOrStringValidator = z.coerce.date().or(z.string()).describe(TYPE_DATE_OR_STRING);

export const positiveIntegerValidator = z.number().int().min(0);

export const commaDelimitedIntegersRegExp = /^[0-9]+(,[0-9]+)*$/;
export const commaDelimitedDecimalsRegExp = /^(\d+(\.\d+)?)(,\d+(\.\d+)?)*$/;
export const commaDelimitedCapitalLettersRegExp = /^[A-Z]+(,[A-Z]+)*$/;
export const zipcodeRegExp = /^\d{5}(-\d{4})?$/;

export const commaDelimitedDecimalValidator = z.string().regex(commaDelimitedDecimalsRegExp);
export const commaDelimitedIntegersValidator = z.string().regex(commaDelimitedIntegersRegExp);
export const commaDelimitedCapitalLettersValidator = z.string().regex(commaDelimitedCapitalLettersRegExp);
export const keywordValidator = z.string().min(3);
export const limitValidator = z.number().int().min(1).optional();
export const skipValidator = z.number().int().min(0).default(0).optional();

export const beaRegionValidator = z.number().int().min(0).max(9);
export const classesOfferedValidator = z.number().int().min(1).max(4);
export const gradeValidator = z.number().int().min(9).max(12);
export const latitudeValidator = z.number().min(-latitudeLimit).max(latitudeLimit);
export const longitudeValidator = z.number().min(-longitudeLimit).max(longitudeLimit);
export const statesValidator = z.string().regex(commaDelimitedCapitalLettersRegExp).optional();
export const yearValidator = z.number().int().min(minimumYear).max(maximumYear);
export const zipCodeValidator = z.string().regex(zipcodeRegExp);

export const admissionStatusValidator = z.nativeEnum(AdmissionStatus);
export const applicationStatusValidator = z.nativeEnum(ApplicationStatus);
export const applicationTypeValidator = z.nativeEnum(ApplicationType);
export const awardLevelValidator = z.enum(['Certificate', 'Associate', 'Bachelor', 'Master', 'Doctorate']);
export const awardTypeValidator = z.nativeEnum(AwardTypeEnum);
export const carnegieSizeSettingValidator = z.enum(['Vocational/Technical', '2-year', '4-year', 'Graduate']);
export const deadlineTypeValidator = z.nativeEnum(DeadlineType);
export const decisionValidator = z.nativeEnum(Decision);
export const institutionTypeValidator = z.enum(['Public', 'Private (non-profit)', 'Private (for-profit)']);
export const localesValidator = z.enum(['City', 'Suburb', 'Town', 'Rural']);
export const majorSortValidator = z.nativeEnum(MajorListSort);
export const scholarshipStatusValidator = z.nativeEnum(ScholarshipStatus);
export const scholarshipSortValidator = z.nativeEnum(ScholarshipSort);
export const seasonValidator = z.nativeEnum(Season);
export const sortValidator = z.nativeEnum(SortDirection);
export const specialtyValidator = z.enum(['isHistoricallyBlack', 'isTribal', 'isMenOnly', 'isWomenOnly']);
export const tagsValidator = z.nativeEnum(TaskTags);
export const tenantShareTypeValidator = z.enum(['Enrollment', 'Marketing']);
export const totalStudentsEnrolledValidator = z.enum(['small', 'medium', 'large']);

export const CreatedResultSchema = z.object({
  _id: z.string(),
  message: z.string(),
});

export const DeletedResultSchema = z.object({
  deleted: z.boolean(),
  message: z.string(),
});

export const SuccessResultSchema = z.object({
  message: z.string(),
});
export type SuccessResult = z.infer<typeof SuccessResultSchema>;

export const UpdatedResultSchema = z.object({
  message: z.string(),
  updated: z.boolean(),
});

export const AuditFieldSchema = z.object({
  createdDate: dateOrStringValidator,
  createdBy: z.string(),
  modifiedDate: dateOrStringValidator,
  modifiedBy: z.string(),
});

export const AuditFieldOptionalSchema = z.object({
  createdDate: dateOrStringValidator.optional(),
  createdBy: z.string().optional(),
  modifiedDate: dateOrStringValidator.optional(),
  modifiedBy: z.string().optional(),
});

export const StringArraySchema = z.array(z.string());

export const CertificateProductSchema = z.object({
  productNumber: z.number().int(),
  productName: z.string(),
  hasCertification: z.boolean(),
});
