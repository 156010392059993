export const classifications = [
  {
    cipCode: 1,
    cipAreaofStudy: 'Agriculture and Animal Science',
    cipTitle: 'Agricultural/Animal/Plant/Veterinary Science And Related Fields',
    cipDescription:
      'Instructional programs that focus on agriculture, animal, plant, veterinary, and related sciences and that prepares individuals to apply specific knowledge, methods, and techniques to the management and performance of agricultural and veterinary operations.',
  },
  {
    cipCode: 3,
    cipAreaofStudy: 'Natural Resources And Conservation',
    cipTitle: 'Natural Resources And Conservation',
    cipDescription:
      'Instructional programs that focus on the various natural resources and conservation fields and prepare individuals for related occupations.',
  },
  {
    cipCode: 4,
    cipAreaofStudy: 'Architecture',
    cipTitle: 'Architecture And Related Services',
    cipDescription:
      'Instructional programs that prepare individuals for professional practice in the various architecture-related fields and focus on the study of related aesthetic and socioeconomic aspects of the built environment.',
  },
  {
    cipCode: 5,
    cipAreaofStudy: 'Area, Ethnic, Multidisciplinary Studies',
    cipTitle: 'Area, Ethnic, Cultural, Gender, and Group Studies',
    cipDescription:
      'Instructional programs that focus on the defined areas, regions, and countries of the world; defined minority groups within and across societies; and issues relevant to collective gender and group experience.',
  },
  {
    cipCode: 9,
    cipAreaofStudy: 'Communication and Journalism',
    cipTitle: 'Communication, Journalism, and Related Programs',
    cipDescription:
      'Instructional programs that focus on how messages in various media are produced, used, and interpreted within and across different contexts, channels, and cultures, and that prepare individuals to apply communication knowledge and skills professionally.',
  },
  {
    cipCode: 10,
    cipAreaofStudy: 'Communications Technology',
    cipTitle: 'Communications Technologies/Technicians and Support Services',
    cipDescription:
      'Instructional programs that prepare individuals to function as equipment operators, support technicians, and operations managers in the film/video, recording, and graphic communications industries.',
  },
  {
    cipCode: 11,
    cipAreaofStudy: 'Computer and Information Sciences',
    cipTitle: 'Computer and Information Sciences and Support Services',
    cipDescription:
      'Instructional programs that focus on the computer and information sciences and prepare individuals for various occupations in information technology and computer operations fields.',
  },
  {
    cipCode: 12,
    cipAreaofStudy: 'Culinary, Entertainment, Cosmetology, and Funeral Services',
    cipTitle: 'Culinary, Entertainment, and Personal Services',
    cipDescription:
      'Instructional programs that prepare individuals to provide professional services related to cosmetology, funeral services, entertainment, and food preparation and service.',
  },
  {
    cipCode: 13,
    cipAreaofStudy: 'Education',
    cipTitle: 'Education',
    cipDescription:
      'Instructional programs that focus on the theory and practice of learning and teaching, and related research, administrative and support services.',
  },
  {
    cipCode: 14,
    cipAreaofStudy: 'Engineering',
    cipTitle: 'Engineering',
    cipDescription:
      'Instructional programs that prepare individuals to apply mathematical and scientific principles to the solution of practical problems.',
  },
  {
    cipCode: 15,
    cipAreaofStudy: 'Engineering Technology',
    cipTitle: 'Engineering/Engineering-related Technologies/Technicians',
    cipDescription:
      'Instructional programs that prepare individuals to apply basic engineering principles and technical skills in support of engineering and related projects or to prepare for engineering-related fields.',
  },
  {
    cipCode: 16,
    cipAreaofStudy: 'Foreign Languages, Literatures, and Linguistics',
    cipTitle: 'Foreign Languages, Literatures, and Linguistics',
    cipDescription:
      'Instructional programs that focus on foreign languages and literatures, the humanistic and scientific study of linguistics, and the provision of professional interpretation and translation services.',
  },
  {
    cipCode: 19,
    cipAreaofStudy: 'Family and Consumer Sciences/Human Sciences',
    cipTitle: 'Family and Consumer Sciences/Human Sciences',
    cipDescription:
      'Instructional programs that focus on the human interface with the physical, social, emotional, and intellectual environments and the developmental stages and needs of individuals in the interrelated spheres of family, workplace, and community.',
  },
  {
    cipCode: 22,
    cipAreaofStudy: 'Legal Professions and Studies',
    cipTitle: 'Legal Professions and Studies',
    cipDescription:
      'Instructional programs that prepare individuals for the legal profession, for related support professions and professional legal research, and focus on the study of legal issues in non-professional programs.',
  },
  {
    cipCode: 23,
    cipAreaofStudy: 'English Language and Literature',
    cipTitle: 'English Language and Literature/letters',
    cipDescription:
      'Instructional programs that focus on the structure and use of the English language and dialects, speech, writing, and various aspects of the literatures and cultures of the English-speaking peoples.',
  },
  {
    cipCode: 24,
    cipAreaofStudy: 'Liberal Arts and Sciences',
    cipTitle: 'Liberal Arts and Sciences, General Studies and Humanities',
    cipDescription:
      'General instructional programs and independent or individualized studies in the liberal arts subjects, the humanities disciplines and the general curriculum.',
  },
  {
    cipCode: 25,
    cipAreaofStudy: 'Library Science',
    cipTitle: 'Library Science',
    cipDescription:
      'Instructional programs that focus on the knowledge and skills required for managing and/or maintaining libraries and related information and record systems,  collections and facilities for research and general use.',
  },
  {
    cipCode: 26,
    cipAreaofStudy: 'Biological and Biomedical Sciences',
    cipTitle: 'Biological and Biomedical Sciences',
    cipDescription:
      'Instructional programs that focus on the biological sciences and the non-clinical biomedical sciences, and that prepare individuals for research and professional careers as biologists and biomedical scientists.',
  },
  {
    cipCode: 27,
    cipAreaofStudy: 'Mathematics and Statistics',
    cipTitle: 'Mathematics and Statistics',
    cipDescription:
      'Instructional programs that focus on the systematic study of logical symbolic language and its applications.',
  },
  {
    cipCode: 28,
    cipAreaofStudy: 'Military Science, Leadership and Operational Art',
    cipTitle: 'Military Science, Leadership and Operational Art',
    cipDescription:
      'Instructional programs that provide professional education and training of military officers in leadership, military science and operational studies, security policy and strategy, military economics and management, and pre-officer training. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 29,
    cipAreaofStudy: 'Military Technologies and Applied Sciences',
    cipTitle: 'Military Technologies and Applied Sciences',
    cipDescription:
      'Instructional programs that prepare individuals in specialized and advanced subject matter for the armed services and related national security organizations, including intelligence operations, military applied sciences, and military technologies.',
  },
  {
    cipCode: 30,
    cipAreaofStudy: 'Multi/interdisciplinary Studies',
    cipTitle: 'Multi/interdisciplinary Studies',
    cipDescription:
      'Instructional programs that derive from two or more distinct programs to provide a cross-cutting focus on a subject concentration that is not subsumed under a single discipline or occupational field.',
  },
  {
    cipCode: 31,
    cipAreaofStudy: 'Parks, Recreation, Leisure, Fitness, and Kinesiology',
    cipTitle: 'Parks, Recreation, Leisure, Fitness, and Kinesiology',
    cipDescription:
      'Instructional programs that focus on the principles and practices of managing parks and other recreational and fitness facilities; providing recreational, leisure and fitness services; and the study of human kinesiology and fitness.',
  },
  {
    cipCode: 32,
    cipAreaofStudy: 'Basic Skills and Developmental/Remedial Education',
    cipTitle: 'Basic Skills and Developmental/Remedial Education',
    cipDescription:
      'Instructional programs that focus on the fundamental knowledge and skills that individuals need to function productively in society. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 33,
    cipAreaofStudy: 'Citizenship Activities',
    cipTitle: 'Citizenship Activities',
    cipDescription:
      'Instructional programs that prepare individuals for citizenship, and provide instruction in how citizens may engage in civic activities. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 34,
    cipAreaofStudy: 'Health-related Knowledge and Skills',
    cipTitle: 'Health-related Knowledge and Skills',
    cipDescription:
      'Instructional programs that focus on the promotion of personal and family health. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 35,
    cipAreaofStudy: 'Interpersonal and Social Skills',
    cipTitle: 'Interpersonal and Social Skills',
    cipDescription:
      'Programs that focus on the development of methods and skills for effective interactions with others in a variety of settings (private, social, and business settings). These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 36,
    cipAreaofStudy: 'Leisure and Recreational Activities',
    cipTitle: 'Leisure and Recreational Activities',
    cipDescription:
      'Programs that focus on the development of an appreciation for and competency in recreational and leisure-related activities. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 37,
    cipAreaofStudy: 'Personal Awareness and Self-improvement',
    cipTitle: 'Personal Awareness and Self-improvement',
    cipDescription:
      'Programs that focus on the development of improved self-awareness, avoidance of stressful behavior, and improved decision-making skills. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 38,
    cipAreaofStudy: 'Philosophy and Religious Studies',
    cipTitle: 'Philosophy and Religious Studies',
    cipDescription:
      'Instructional programs that focus on logical inquiry, philosophical analysis, and the academic study of organized systems of belief and religious practices.',
  },
  {
    cipCode: 39,
    cipAreaofStudy: 'Theology and Religious Vocations',
    cipTitle: 'Theology and Religious Vocations',
    cipDescription:
      'Instructional programs that focus on the intramural study of theology and that prepare individuals for the professional practice of religious vocations.',
  },
  {
    cipCode: 40,
    cipAreaofStudy: 'Physical Sciences',
    cipTitle: 'Physical Sciences',
    cipDescription:
      'Instructional programs that focus on the scientific study of inanimate objects, processes of matter and energy, and associated phenomena.',
  },
  {
    cipCode: 41,
    cipAreaofStudy: 'Science Technology',
    cipTitle: 'Science Technologies/Technicians',
    cipDescription:
      'Instructional programs that prepare individuals to apply scientific principles and technical skills in support of scientific research and development.',
  },
  {
    cipCode: 42,
    cipAreaofStudy: 'Psychology',
    cipTitle: 'Psychology',
    cipDescription:
      'Instructional programs that focus on the scientific study of the behavior of individuals, independently or collectively, and the physical and environmental bases of mental, emotional, and neurological activity.',
  },
  {
    cipCode: 43,
    cipAreaofStudy: 'Homeland Security, Law Enforcement, Firefighting and Related Protective Services',
    cipTitle: 'Homeland Security, Law Enforcement, Firefighting and Related Protective Services',
    cipDescription:
      'Instructional programs that focus on the principles and procedures for providing homeland security, police, fire, and other safety services and managing penal institutions. Note: this series is titled Security and Protective Services in the Canadian CIP.',
  },
  {
    cipCode: 44,
    cipAreaofStudy: 'Public Administration and Social Service Professions',
    cipTitle: 'Public Administration and Social Service Professions',
    cipDescription:
      'Instructional programs that prepare individuals to analyze, manage, and deliver public programs and services.',
  },
  {
    cipCode: 45,
    cipAreaofStudy: 'Social Sciences',
    cipTitle: 'Social Sciences',
    cipDescription:
      'Instructional programs that focus on the systematic study of social systems, social institutions, and social behavior.',
  },
  {
    cipCode: 46,
    cipAreaofStudy: 'Construction Trades',
    cipTitle: 'Construction Trades',
    cipDescription:
      'Instructional programs that prepare individuals to apply technical knowledge and skills in the building, inspecting, and maintaining of structures and related properties.',
  },
  {
    cipCode: 47,
    cipAreaofStudy: 'Mechanic and Repair Technology',
    cipTitle: 'Mechanic and Repair Technologies/technicians',
    cipDescription:
      'Instructional programs that prepare individuals to apply technical knowledge and skills in the adjustment, maintenance, part replacement, and repair of tools, equipment, and machines.',
  },
  {
    cipCode: 48,
    cipAreaofStudy: 'Precision Production',
    cipTitle: 'Precision Production',
    cipDescription:
      'Instructional programs that prepare individuals to apply technical knowledge and skills to create products using techniques of precision craftsmanship or technical illustration.',
  },
  {
    cipCode: 49,
    cipAreaofStudy: 'Transportation and Logistics',
    cipTitle: 'Transportation and Materials Moving',
    cipDescription:
      'Instructional programs that prepare individuals to apply technical knowledge and skills to perform tasks and services that facilitate the movement of people or materials.',
  },
  {
    cipCode: 50,
    cipAreaofStudy: 'Visual and Performing Arts',
    cipTitle: 'Visual and Performing Arts',
    cipDescription:
      'Instructional programs that focus on the creation and interpretation of works and performances that use auditory, kinesthetic, and visual phenomena to express ideas and emotions in various forms, subject to aesthetic criteria.',
  },
  {
    cipCode: 51,
    cipAreaofStudy: 'Health Professions',
    cipTitle: 'Health Professions and Related Programs',
    cipDescription:
      'Instructional programs that prepare individuals to practice as licensed professionals and assistants in the health care professions and related clinical sciences and administrative and support services.',
  },
  {
    cipCode: 52,
    cipAreaofStudy: 'Business',
    cipTitle: 'Business, Management, Marketing, and Related Support Services',
    cipDescription:
      'Instructional programs that prepare individuals to perform managerial, technical support, and applied research functions related to the operation of commercial and non-profit enterprises and the buying and selling of goods and services.',
  },
  {
    cipCode: 53,
    cipAreaofStudy: 'High School/Secondary Diplomas and Certificates',
    cipTitle: 'High School/Secondary Diplomas and Certificates',
    cipDescription:
      'Instructional programs that define the prescribed requirements for high school/secondary school graduation. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 54,
    cipAreaofStudy: 'History',
    cipTitle: 'History',
    cipDescription:
      'Instructional programs that focus on the study and interpretation of past events, institutions, issues, and cultures.',
  },
  {
    cipCode: 60,
    cipAreaofStudy: 'Health Professions Residency',
    cipTitle: 'Health Professions Residency/Fellowship Programs',
    cipDescription:
      'Programs that prepare dentists (DDS, DMD), nurse practitioners, pharmacists (PharmD), physician assistants, and veterinarians (DVM) for certification as practitioners of recognized specialties in their respective professions. These programs are approved and accredited by designated professional associations. Residency or fellowship programs that also result in the completion of an academic degree (e.g., MS, PhD), should be reported under the appropriate CIP code, rather than in a residency code located in Series 60. These CIP codes are not valid for IPEDS reporting.',
  },
  {
    cipCode: 61,
    cipAreaofStudy: 'Medical Residency',
    cipTitle: 'Medical Residency/Fellowship Programs',
    cipDescription:
      'Programs that prepare allopathic physicians (MD), osteopathic physicians (DO), and podiatrists (DPM) for certification as practitioners of recognized medical specialties. These programs are approved and accredited by the Accreditation Council for Graduate Medical Education (ACGME) or the Council on Podiatric Medical Education (CPME) and require from one to seven years to complete, depending on the program. Residency programs that also result in the completion of an academic degree (e.g., MS, PhD), should be reported under one of the clinical sciences codes located in Series 26, 51.05, or 51.14, rather than in a residency code located in Series 61. These CIP Codes are not valid for IPEDS reporting.',
  },
];
