import { omit } from 'lodash-es';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  DeadlineType,
  Season,
  TaskStatus,
  TaskTags,
  Task,
  IpedsCardInfo,
  UserPlanning,
  ApplicationType,
  UpdateApplyingCollege,
} from '@common-types';

import {
  ALL_TYPES_FILTER_VALUE,
  FILTER_TAGS_COLORS,
  INITIAL_FILTERS,
  TASK_STATUS_NOTIFICATION_DATA,
  ALL_GRADES_VALUE,
} from '@constants/educationPlanner';

import {
  STEPS,
  SchoolSelectionData as baseSchoolSelectionData,
  SchoolByState,
  SchoolSelectionStore as baseSchoolSelectionStore,
} from '@interfaces/selectSchool';

import { baseService } from '@services/base.service';
import useNotificationStore from '@store/NotificationStore';

interface TaskFilters {
  status: TaskStatus | string;
  tag: TaskTags | string;
}

export interface EducationPlannerData {
  currentGrade: number;
  currentSeason: Season | null;
  currentTask?: Task;
  pageTasks: Task[];
  pageFilters: TaskFilters;
  tagColor: string;
  isShowConfetti: boolean;
  isTaskArchivePage: boolean;
}

export interface SchoolSelectionData extends baseSchoolSelectionData {
  priority?: number;
  deadlineType?: DeadlineType;
}

interface EducationPlannerStore extends baseSchoolSelectionStore {
  educationPlannerData: EducationPlannerData;
  setEducationPlannerData: (data: Partial<EducationPlannerData>) => void;
  getSchoolInfo: () => Promise<void | IpedsCardInfo>;
  saveSchoolSelection: () => Promise<void>;

  userPlanning: UserPlanning;
  setUserPlanning: (data: UserPlanning) => void;

  addCustomTask: (data: Partial<Task>) => void;
  editCustomTask: (data: Partial<Task>) => void;
  deleteTask: (taskId: string, isDefaultTask: boolean) => void;
  changeTaskStatus: (taskId: string, status: TaskStatus) => void;
  restoreTask: (taskId: string) => void;

  schoolSelectionData: SchoolSelectionData;
  setSchoolSelectionData: (data: Partial<SchoolSelectionData>) => void;
  resetSchoolSelectionData: () => void;
}

const initialState = {
  userPlanning: {} as UserPlanning,

  educationPlannerData: {
    currentGrade: ALL_GRADES_VALUE,
    currentSeason: Season.FALL,
    pageTasks: [],
    pageFilters: INITIAL_FILTERS,
    tagColor: FILTER_TAGS_COLORS[ALL_TYPES_FILTER_VALUE],
    isShowConfetti: false,
    isTaskArchivePage: false,
  },

  schoolSelectionData: {
    activeStep: STEPS.step1,
    priority: 1,
    state: null,
    school: null,
    schoolListOptions: [],
    isConfirmToSelection: true,
    isGrantAccessToYSProfile: false,
    isCollegeOriginatingTenant: false,
    schoolPreviewInfo: {} as IpedsCardInfo,
  },

  isPreviewCardDataLoading: false,
};

export const useEducationPlannerStore = createWithEqualityFn<EducationPlannerStore>()(
  (set, get) => ({
    ...initialState,

    setUserPlanning: (userPlanning: UserPlanning) => {
      set({
        userPlanning,
      });
    },

    setEducationPlannerData: (data: Partial<EducationPlannerData>) => {
      set({
        educationPlannerData: { ...get().educationPlannerData, ...data },
      });
    },

    restoreTask: async (taskId: string) => {
      const userPlanning = await baseService.patchAsync<UserPlanning, { isDeleted: boolean }>(`/user/tasks/${taskId}`, {
        isDeleted: false,
      });

      set({
        userPlanning,
      });

      useNotificationStore.getState().notify({
        title: 'Task was successfully restored',
        message: '',
        severity: 'success',
      });
    },

    changeTaskStatus: async (taskId: string, status: TaskStatus) => {
      const userPlanning = await baseService.patchAsync<UserPlanning, { status: TaskStatus }>(`/user/tasks/${taskId}`, {
        status,
      });

      set({
        userPlanning,
      });

      useNotificationStore.getState().notify({
        title: TASK_STATUS_NOTIFICATION_DATA[status].title,
        message: TASK_STATUS_NOTIFICATION_DATA[status].message,
        severity: TASK_STATUS_NOTIFICATION_DATA[status].severity,
      });
    },

    addCustomTask: async (data: Partial<Task>) => {
      const newTask = {
        status: TaskStatus.ACTIVE,
        linkUrls: data.linkUrls,
        grades: data.grades,
        season: data.season,
        tags: data.tags,
        title: data.title,
        description: data.description,
        dueDate: data.dueDate,
        isDeleted: false,
      } as Task;

      const userPlanning = await baseService.postAsync<UserPlanning, Task>(`/user/tasks`, newTask);

      set({
        userPlanning,
      });

      useNotificationStore.getState().notify({
        message: `Saved to Tasks`,
        severity: 'info',
      });
    },

    editCustomTask: async (customTask: Partial<Task>) => {
      if (!customTask._id) {
        throw new Error('Task id is required');
      }

      const data = { ...omit(customTask, ['_id']) };

      const userPlanning = await baseService.patchAsync<UserPlanning, typeof data>(
        `/user/tasks/${customTask._id}`,
        data,
      );

      set({
        userPlanning,
      });

      useNotificationStore.getState().notify({
        message: `Task Updated`,
        severity: 'info',
      });
    },

    deleteTask: async (taskId: string, isDefaultTask: boolean) => {
      const userPlanning = await baseService.deleteAsync<UserPlanning>(`/user/tasks/${taskId}`);

      set({
        userPlanning,
      });

      const notificationTitle = isDefaultTask
        ? TASK_STATUS_NOTIFICATION_DATA.Deleted.title
        : 'Task was successfully deleted';
      const notificationMessage = isDefaultTask ? TASK_STATUS_NOTIFICATION_DATA.Deleted.message : '';

      useNotificationStore.getState().notify({
        title: notificationTitle,
        message: notificationMessage,
        severity: TASK_STATUS_NOTIFICATION_DATA.Deleted.severity,
      });
    },

    setSchoolSelectionData: (data: Partial<SchoolSelectionData>) => {
      set({
        schoolSelectionData: { ...get().schoolSelectionData, ...data },
      });
    },

    resetSchoolSelectionData: () => {
      set({
        schoolSelectionData: { ...initialState.schoolSelectionData },
      });
    },

    getSchoolListByState: async () => {
      const {
        schoolSelectionData: { state },
      } = get();

      if (state) {
        const schoolList = await baseService.getAsync<SchoolByState[]>(`/college-search?state=${state.value}`);

        const schoolListOptions = schoolList.map(({ id, name, city, zip }) => {
          return {
            label: `${name} (${zip}, ${city})`,
            value: id.toString(),
          };
        });

        set({
          schoolSelectionData: { ...get().schoolSelectionData, schoolListOptions },
        });
      }
    },

    getSchoolInfo: async () => {
      const {
        schoolSelectionData: { school, schoolPreviewInfo },
      } = get();

      if (school?.value && schoolPreviewInfo.id !== +school.value) {
        set({ isPreviewCardDataLoading: true });

        const [schoolPreviewInfo] = await baseService.getAsync<IpedsCardInfo[]>(`/card-info?id=${school.value}`);

        set({
          schoolSelectionData: {
            ...get().schoolSelectionData,
            schoolPreviewInfo,
          },
          isPreviewCardDataLoading: false,
        });

        return schoolPreviewInfo;
      }

      return Promise.resolve(schoolPreviewInfo);
    },

    saveSchoolSelection: async () => {
      const data = get().schoolSelectionData;

      const payload: UpdateApplyingCollege = {
        id: data.schoolPreviewInfo.id,
        priority: data.priority as number,
      };

      if (data.deadlineType) {
        payload.application = { deadlineType: data.deadlineType, type: ApplicationType.INSTITUTIONAL };
      }

      const userPlanning = await baseService.postAsync<UserPlanning, UpdateApplyingCollege>(
        `/user/applying-college`,
        payload,
      );

      useNotificationStore.getState().notify({
        message: `${data.school?.label} added to your list`,
        severity: 'success',
      });

      set({
        userPlanning,
      });
    },
  }),
  Object.is,
);
