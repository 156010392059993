import { useState } from 'react';
import { CoreBox, CoreTypography, CoreRadioGroup, CoreRadio, CoreFormControlLabel } from '@youscience/khaleesi';

import useSchoolSelectionStore from '@store/SchoolSelectionStore';
import useHomePageStore from '@store/HomePageStore';
import useNotificationStore from '@store/NotificationStore';

import useUserPreferencesStore from '@store/UserPreferencesStore';

import ArrowsIcon from '@images/arrows.svg?react';

import { sxStyles, StyledChangeEnrollment } from './LPUserMenu.styles';
import useEcpStore from '@store/EcpStore';

enum Severity {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export const AccessNotificationMessages = {
  SUCCESS: {
    title: 'Access has been granted.',
    message:
      'You can remove access by clicking on your profile name and marking the corresponding checkbox in the dropdown.',
    severity: Severity.success,
  },
  INFO: {
    title: 'Access has been withdrawn.',
    message:
      'You can grant access by clicking on your profile name and marking the corresponding checkbox in the dropdown.',
    severity: Severity.info,
  },
};

interface LPUserMenuProps {
  onHandleShowMessage: () => void;
}

export const LPUserMenu = (props: LPUserMenuProps) => {
  const { onHandleShowMessage } = props;

  const { cardInfo } = useHomePageStore((state) => state.landingPageSchoolData);
  const { grantAccessToYSProfile } = useSchoolSelectionStore((state) => state);
  const { notify } = useNotificationStore((state) => state);

  const { isCollegeOriginatingTenant, enrolledSchoolId, tenantSharing } = useUserPreferencesStore(
    (state) => state.userPreferences,
  );

  const { schoolExplorationEnabled } = useEcpStore((state) => state.ecpSettings);

  const schoolHasAccessToProfile = tenantSharing?.find((item) => item.collegeId === enrolledSchoolId)?.currentlySharing;

  const [isProvideAccess, setIsProvideAccess] = useState<boolean>(schoolHasAccessToProfile ?? false);

  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value === 'true';

    setIsProvideAccess(currentValue);

    await grantAccessToYSProfile(cardInfo, currentValue);

    if (currentValue) {
      notify({
        title: AccessNotificationMessages.SUCCESS.title,
        message: AccessNotificationMessages.SUCCESS.message,
        severity: AccessNotificationMessages.SUCCESS.severity,
      });
    } else {
      notify({
        title: AccessNotificationMessages.INFO.title,
        message: AccessNotificationMessages.INFO.message,
        severity: AccessNotificationMessages.INFO.severity,
      });
    }
  };

  return cardInfo && (!isCollegeOriginatingTenant || schoolExplorationEnabled) ? (
    <>
      <CoreBox sx={sxStyles.header}>
        <StyledChangeEnrollment onClick={onHandleShowMessage}>
          <ArrowsIcon />

          <CoreTypography>Update Enrollment</CoreTypography>
        </StyledChangeEnrollment>
      </CoreBox>

      <CoreBox sx={sxStyles.body}>
        {/* eslint-disable max-len */}
        <CoreTypography>{`I want to provide ${cardInfo.name} access to my personal YouScience profile.`}</CoreTypography>

        <CoreRadioGroup value={isProvideAccess} onChange={handleRadioChange}>
          <CoreFormControlLabel control={<CoreRadio value />} label="Yes" />

          <CoreFormControlLabel control={<CoreRadio value={false} />} label="No" />
        </CoreRadioGroup>
      </CoreBox>
    </>
  ) : null;
};
