import { createWithEqualityFn } from 'zustand/traditional';
import { isAxiosError } from 'axios';
import type { UserProfile } from '@common-types';

import { baseService } from '@services/base.service';
import { BRIGHTPATH_HOME_URL } from '@constants/externalRoutes';
import { conditionalRedirect } from '@utils/navigation';

import useHomePageStore from '@store/HomePageStore';
import useUserInfoStore from '@store/UserInfoStore';
import useUserPreferencesStore from '@store/UserPreferencesStore';
import useEducationPlannerStore from '@store/EducationPlannerStore';
import useApplyingSchoolsPlannerStore from '@store/ApplyingSchoolsPlannerStore';
import useAuthStore from '@store/AuthStore';
import useEcpStore from '@store/EcpStore';

import { TenantAdminAssignment } from '@store/HomePageStore';
import { ecpService } from '@services/ecp.service';

interface AppInitStore {
  isInitialized: boolean;
  isLoading: boolean;
  error: Error | null;
  initialize: () => Promise<void>;
}

interface UserProfileData extends UserProfile {
  tenantAdminAssignments: TenantAdminAssignment[];
}

export const useAppInitStore = createWithEqualityFn<AppInitStore>()(
  (set) => ({
    isInitialized: false,
    isLoading: true,
    error: null,

    initialize: async () => {
      try {
        set({ isLoading: true });

        const { getMe } = useAuthStore.getState();
        const { setAdminHomePageData, setLandingPageData } = useHomePageStore.getState();
        const { setUserInfo } = useUserInfoStore.getState();
        const { setUserPreferences } = useUserPreferencesStore.getState();
        const { setUserPlanning, setEducationPlannerData } = useEducationPlannerStore.getState();
        const { getAppliedSchool } = useApplyingSchoolsPlannerStore.getState();
        const { setEcpSettings } = useEcpStore.getState();

        const me = await getMe();

        const discoveryMe = (await baseService.get('/user/me')) as unknown as {
          data: Record<string, unknown>;
        };

        const userProfile = await baseService.getAsync<UserProfileData>('/user/profile');

        const promises = [
          setUserInfo({
            phone: me?.profile?.phones?.[0]?.number ?? '',
            email: me?.profile?.emails?.[0]?.address ?? '',
            userId: discoveryMe.data.id as number,
            notifyTenantAvailable: userProfile.notifyTenantAvailable ?? false,
          }),
          setUserPlanning(userProfile.userPlanning),
          setEducationPlannerData({
            currentGrade: (discoveryMe.data.grade as number) ?? 0,
          }),
          setUserPreferences(userProfile.userPreferences),
          setLandingPageData(userProfile.enrolledSchool),
          setAdminHomePageData(userProfile.tenantAdminAssignments[0]),
          getAppliedSchool(),
        ];

        if (
          userProfile?.userPreferences?.isCollegeOriginatingTenant &&
          userProfile?.userPreferences?.collegeOriginatingTenantId
        ) {
          const ecpSettings = await ecpService.getEcpSettings(
            `${userProfile.userPreferences.collegeOriginatingTenantId}`,
          );

          if (ecpSettings) {
            promises.push(setEcpSettings(ecpSettings));
          }
        }

        await Promise.all(promises);

        set({
          isInitialized: true,
          isLoading: false,
          error: null,
        });
      } catch (error) {
        if (isAxiosError(error)) {
          conditionalRedirect(BRIGHTPATH_HOME_URL, 'replace');
        }
        set({
          error: error as Error,
          isLoading: false,
        });
      }
    },
  }),
  Object.is,
);
