import { z } from 'zod';
import { mongoIdValidator } from '../common';

export const SocCipCrosswalkDocumentSchema = z.object({
  _id: mongoIdValidator,
  socCode: z.string(),
  socTitle: z.string(),
  cipCode: z.string(),
  cipTitle: z.string(),
});
export type SocCipCrosswalkDocument = z.infer<typeof SocCipCrosswalkDocumentSchema>;
