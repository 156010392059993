import { styled, Theme, SxProps } from '@mui/material';
import { CoreDialog } from '@youscience/khaleesi';

const helperTextColor = '#FF4949';

export const sxStyles: Record<string, SxProps<Theme>> = {
  title: (theme: Theme) => ({
    ...theme.typography.h4,
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
  }),
  content: {
    padding: '1.5rem 1rem',
  },
  subtitle: {
    marginTop: '1.5rem',
  },
  contactInfo: {
    marginTop: '1rem',

    '& > div:last-of-type': {
      marginTop: '1rem',
    },
  },
  street: {
    width: '100%',
    marginTop: '1rem',
  },
  textField: {
    maxWidth: '176px',
    width: '100%',
    marginTop: '1rem',
  },
  zip: {
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  address: {
    display: 'flex',
    gap: '0.5rem',
  },
  helperText: {
    marginLeft: '0.875rem',
    color: helperTextColor,
  },
  checkboxGroup: {
    gap: '0.75rem',
  },
  planEnrollment: {
    marginTop: '1rem',
  },
};

export const StyledDialog = styled(CoreDialog)(() => ({
  '.MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '576px',
    width: '100%',
  },
}));
