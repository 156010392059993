import { QueryClient } from '@tanstack/react-query';
import { getSchools } from '@api/schools';
import { DESKTOP_PER_PAGE_OPTIONS } from '@constants';

export const schoolListLoader = (queryClient: QueryClient) => () => {
  const filters = { limit: DESKTOP_PER_PAGE_OPTIONS[0] };

  void queryClient.prefetchQuery({
    queryKey: ['schools', filters, false],
    queryFn: () => getSchools({ filters, filterByMajor: false }),
  });

  return null;
};
