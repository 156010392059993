import { z } from 'zod';

export const MoneyRangeSchema = z.object({
  minLimit: z.number().int(),
  maxLimit: z.number().int(),
});
export type MoneyRange = z.infer<typeof MoneyRangeSchema>;

export const ScholarshipLimitSchema = z.object({
  minLowerLimit: z.number().int(),
  maxLowerLimit: z.number().int(),
  minUpperLimit: z.number().int(),
  maxUpperLimit: z.number().int(),
});
export type ScholarshipLimit = z.infer<typeof ScholarshipLimitSchema>;
