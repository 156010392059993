import { z } from 'zod';

export const MajorDescriptionSchema = z.object({
  cipTitle: z.string(),
  cipFamily: z.string(),
  cipCode: z.string(),
  cipDefinition: z.string(),
  CrossReferences: z.string().optional(),
  Examples: z.string().optional(),
});
export type MajorDescription = z.infer<typeof MajorDescriptionSchema>;

export const RecommendationChipDataSchema = z.object({
  type: z.string(),
  icon: z.any(),
  label: z.string(),
  color: z.string(),
});
export type RecommendationChipData = z.infer<typeof RecommendationChipDataSchema>;

export const MajorCollegeCountSchema = z.object({
  cipCode: z.string(),
  collegesCount: z.number().int(),
});
export type MajorCollegeCount = z.infer<typeof MajorCollegeCountSchema>;
