export const AUTHENTICATION_BASE_URL: string = import.meta.env.VITE_AUTHENTICATION_BASE_URL;

export const API_BASE_URL: string = `${import.meta.env.VITE_API_BASE_URL}/${import.meta.env.VITE_API_MAPPING_PATH}`;

export const DISCOVERY_URL: string = import.meta.env.VITE_DISCOVERY_URL;

export const DISCOVERY_APPS_URL: string = import.meta.env.VITE_DISCOVERY_APPS_URL;

export const COMMON_APP_URL: string = import.meta.env.VITE_COMMON_APP_URL;

export const IMAGES_URL: string = import.meta.env.VITE_IMAGES_URL;

export const COLLEGES_IMAGES_URL: string = `${import.meta.env.VITE_IMAGES_URL}/colleges`;

export const MAJORS_IMAGES_URL: string = `${import.meta.env.VITE_IMAGES_URL_DEV}/images`;

export const BRIGHTPATH_HOME_URL: string = import.meta.env.VITE_HOME_URL;

export const GUS_BASE_URL: string = import.meta.env.VITE_GUS_BASE_URL;

export const OPPORTUNITIES_URL: string = import.meta.env.VITE_OPPORTUNITIES_URL;

export const ECP_APPS_URL: string = import.meta.env.VITE_ECP_URL;

export const ECP_API_URL: string = import.meta.env.VITE_ECP_API_URL;
