import { MembersDocumentSchema } from '@youscience/commonapp-service-common';
import { z } from 'zod';
import { dateOrStringValidator, mongoIdValidator } from '../common';

export const MemberDocumentSchema = MembersDocumentSchema.omit({
  collegeName: true,
  address1: true,
  website: true,
  ipedsCode: true,
}).extend({
  _id: mongoIdValidator.optional(),
  name: z.string(),
  address: z.string(),
  url: z.string(),
  ipedsCode: z.number().int().optional(),
  modifiedDate: dateOrStringValidator,
});
export type MemberDocument = z.infer<typeof MemberDocumentSchema>;
