import { AxiosError } from 'axios';

import { PreferredAccess } from '@interfaces/user';

import { authService } from '@services/auth.api.service';

import { resetAuthSession } from '@store/AuthStore';
import { notify } from '@store/NotificationStore';
import { conditionalRedirect } from '@utils/navigation';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleRefreshToken = () => {
  authService
    .refreshToken()
    .then(() => {
      notify({ message: 'Your access has been updated', severity: 'success' });

      setTimeout(() => {
        resetAuthSession();
      }, 1000);
    })
    .catch((error: AxiosError) => {
      if (error.status === 401 || error.status === 403) return;
      notify({ message: 'There was an error updating your access', severity: 'error' });
      conditionalRedirect(`${window.location.protocol}//${window.location.host}/`, 'assign');
    });
};

export const handleTenantSelectCallback = (preferredAccessId: string) => {
  const preferences = {
    currentAccess: preferredAccessId,
  } as unknown as PreferredAccess;

  authService
    .updateUserPreferences(preferences)
    .then((preferredAccess) => {
      if (preferredAccess) {
        handleRefreshToken();
      }
    })
    .catch((err) => {
      notify({ message: 'There was an error updating your access', severity: 'error' });
      //conditionalRedirect(`${window.location.protocol}//${window.location.host}/`, 'replace');
      // eslint-disable-next-line no-console
      console.log(err);
    });
};
