import { z } from 'zod';
import { AuditFieldSchema, mongoIdValidator } from '../common';
import { ScholarshipCardSchema } from '../scholarships/responses';
import { ApplyingCollegeSchema, BeginCollegeSchema, TaskSchema } from './model';

const ApplyingCollegeSchoolInfoSchema = z.object({
  name: z.string(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  url: z.string().optional(),
});
export type ApplyingCollegeSchoolInfo = z.infer<typeof ApplyingCollegeSchoolInfoSchema>;

export const ApplyingCollegeWithSchoolSchema = ApplyingCollegeSchema.merge(
  z.object({
    school: ApplyingCollegeSchoolInfoSchema,
  }),
);
export type ApplyingCollegeWithSchool = z.infer<typeof ApplyingCollegeWithSchoolSchema>;

export const UserPlanningSchema = z
  .object({
    _id: mongoIdValidator.optional(),
    userId: z.string().or(z.number().int()),
    legacyId: z.number().int().optional(),
    currentGrade: z.number().int().optional(),
    overallGPA: z.number().min(0).max(5).optional(),
    beginCollege: BeginCollegeSchema.optional(),
    applyingColleges: ApplyingCollegeSchema.array(),
    applyingScholarships: ScholarshipCardSchema.array(),
    tasks: TaskSchema.array(),
  })
  .merge(AuditFieldSchema);
export type UserPlanning = z.infer<typeof UserPlanningSchema>;
