import { UserCertificationProductSchema } from '../../certification';
import { EmployerCardSchema } from '../../employerCard';
import { z } from 'zod';
import { SocCodeInfoSchema } from '../cip-soc-crosswalk/responses';
import { IpedsDocumentSchema } from '../ipeds/model';

export const MajorSchema = z.object({
  cipCode: z.string(),
  cipTitle: z.string(),
  areaOfStudy: z.number().int(),
  areaOfStudyTitle: z.string(),
  collegesCount: z.number().int(),
  isFavorite: z.boolean(),
  isFavoriteSoc: z.boolean(),
  isBestFitSoc: z.boolean(),
  hasCertification: z.boolean(),
});
export type Major = z.infer<typeof MajorSchema>;

export const MajorsResultSchema = z.object({
  majors: z.array(MajorSchema),
  totalCount: z.number().int(),
  skip: z.number().int(),
  limit: z.number().int(),
});
export type MajorsResult = z.infer<typeof MajorsResultSchema>;

export const MajorCardDataSchema = MajorSchema.extend({
  moreDetailsUrl: z.string(),
  favoriteUrl: z.string(),
  imageUrl: z.string(),
});
export type MajorCardData = z.infer<typeof MajorCardDataSchema>;

export const MajorDetailSchema = z.object({
  cipTitle: z.string(),
  isFavoriteSoc: z.boolean(),
  isBestFitSoc: z.boolean(),
  hasCertification: z.boolean(),
  relatedCareers: SocCodeInfoSchema.array(),
  schools: IpedsDocumentSchema.array(),
  userCertifications: UserCertificationProductSchema.array(),
  employers: EmployerCardSchema.array(),
});
export type MajorDetail = z.infer<typeof MajorDetailSchema>;
