import { z } from 'zod';
import { AuditFieldSchema, mongoIdValidator } from '../common';

export const ProgramCategorySchema = z.object({
  needBased: z.boolean(),
  meritBased: z.boolean(),
  adult: z.boolean(),
  athletic: z.boolean(),
  disability: z.boolean(),
  firstGeneration: z.boolean(),
  immigrant: z.boolean(),
  lgbtq: z.boolean(),
  military: z.boolean(),
  minorities: z.boolean(),
  religious: z.boolean(),
  residency: z.boolean(),
  rotc: z.boolean(),
  singleParent: z.boolean(),
});

const InstitutionSchema = z.object({
  id: z.number().int(),
  name: z.string(),
});

export const AreaOfStudySchema = z.object({
  code: z.string(),
  description: z.string(),
  areaOfStudy: z.string().nullable(),
  areaOfStudyDescription: z.string().nullable(),
  cipCode: z.string().nullable(),
  cipCodeDescription: z.string().nullable(),
});
export type AreaOfStudy = z.infer<typeof AreaOfStudySchema>;

export const CodeDescriptionSchema = z.object({
  code: z.string(),
  description: z.string(),
});

export const ClassificationSchema = z.object({
  isInstitutionalAid: z.boolean(),
  isPrivateAid: z.boolean(),
  isGovernmentAid: z.boolean(),
});

export const AwardTypeSchema = z.object({
  isFellowship: z.boolean(),
  isGrant: z.boolean(),
  isForgivableLoan: z.boolean(),
  isPrize: z.boolean(),
  isScholarship: z.boolean(),
});
export type AwardType = z.infer<typeof AwardTypeSchema>;

export const LimitRangeSchema = z.object({
  lowerLimit: z.number().nullable(),
  upperLimit: z.number().nullable(),
});

export const AwardTotalsSchema = z.object({
  totalNumber: z.number(),
  totalAmount: z.number(),
  totalApplicants: z.number(),
});

export const AwardInclusionsSchema = z.object({
  tuitionPayment: z.boolean(),
  schoolFees: z.boolean(),
  roomAndBoard: z.boolean(),
  booksAndSupplies: z.boolean(),
  researchFunding: z.boolean(),
  cashPrize: z.boolean(),
});

export const WorkStatusSchema = z.object({
  fullTimeOnly: z.boolean(),
  partTimeOnly: z.boolean(),
  fullOrPartTime: z.boolean(),
});

export const SectorSchema = z.object({
  publicInstitution: z.boolean(),
  privateForProfitInstitution: z.boolean(),
  privateNotForProfitInstitution: z.boolean(),
});
export type Sector = z.infer<typeof SectorSchema>;

export const LevelOfStudySchema = z.object({
  freshman: z.boolean(),
  sophomore: z.boolean(),
  junior: z.boolean(),
  senior: z.boolean(),
  graduate: z.boolean(),
  postGraduate: z.boolean(),
});

export const InstitutionTypeSchema = z.object({
  tradeSchool: z.boolean(),
  twoYearCollege: z.boolean(),
  fourYearCollege: z.boolean(),
  university: z.boolean(),
});
export type InstitutionType = z.infer<typeof InstitutionTypeSchema>;

export const CitizenshipSchema = z.object({
  isAvailableToUS: z.boolean(),
  isAvailableToCA: z.boolean(),
  isAvailableToOther: z.boolean(),
});

export const ScoreSchema = z.object({
  gpa: z.boolean(),
  satOrAct: z.boolean(),
  gpaOrSatOrAct: z.boolean(),
});

export const ScoreRequirementSchema = z.object({
  minimumGpa: z.number(),
  minimumSat: z.number(),
  minimumAct: z.number(),
});

export const AgeRangeSchema = z.object({
  fromAge: z.number().int(),
  toAge: z.number().int(),
});

export const GenderSchema = z.object({
  menOnly: z.boolean(),
  womenOnly: z.boolean(),
});

export const MaritalStatusSchema = z.object({
  married: z.boolean(),
  single: z.boolean(),
});

export const RaceSchema = z.object({
  americanIndianAlaskaNative: z.boolean(),
  asianPacificIslander: z.boolean(),
  blackNonHispanic: z.boolean(),
  hispanic: z.boolean(),
});

export const DisabilityTypeSchema = z.object({
  hearing: z.boolean(),
  learning: z.boolean(),
  physical: z.boolean(),
  visual: z.boolean(),
});

export const MilitaryServiceSchema = z.object({
  anyService: z.boolean(),
  airForce: z.boolean(),
  army: z.boolean(),
  marines: z.boolean(),
  navy: z.boolean(),
  coastGuard: z.boolean(),
  airNationalGuard: z.boolean(),
  armyNationalGuard: z.boolean(),
  specialRequirements: z.boolean(),
});

export const InstitutionalAidSchema = z.object({
  applyDirectly: z.boolean(),
  automaticallyConsidered: z.boolean(),
});

export const ApplicationSubmissionSchema = z.object({
  submitOnline: z.boolean(),
  submitEmail: z.boolean(),
  submitMail: z.boolean(),
  formUrl: z.string(),
  email: z.string(),
});

export const ApplicationRequirementSchema = z.object({
  applicationForm: z.boolean(),
  autoBiography: z.boolean(),
  communityService: z.boolean(),
  driversLicense: z.boolean(),
  essay: z.boolean(),
  financialAnalysis: z.boolean(),
  interview: z.boolean(),
  photograph: z.boolean(),
  portfolio: z.boolean(),
  recommendations: z.boolean(),
  resume: z.boolean(),
  addressedEnvelope: z.boolean(),
  testScores: z.boolean(),
  transcript: z.boolean(),
  form214: z.boolean(),
  special: z.boolean(),
  other: z.string(),
  essayPrompt: z.string(),
});

export const ApplicationFeeSchema = z.object({
  feeRequired: z.boolean(),
  amount: z.number(),
});

const ApplicationDeadlineSchema = z.object({
  deadlineMonth: z.number().int().nullable(),
  deadlineDay: z.number().int().nullable(),
  deadlineYear: z.number().int().nullable(),
});
export type ApplicationDeadline = z.infer<typeof ApplicationDeadlineSchema>;

export const ApplicationDateSchema = ApplicationDeadlineSchema.extend({
  startMonth: z.number().int().nullable(),
  startDay: z.number().int().nullable(),
  startYear: z.number().int().nullable(),
  priorityMonth: z.number().int().nullable(),
  priorityDay: z.number().int().nullable(),
  priorityYear: z.number().int().nullable(),
});
export type ApplicationDate = z.infer<typeof ApplicationDateSchema>;

export const WebAddressSchema = z.object({
  programUrl: z.string(),
  sponsorUrl: z.string(),
});

export const ScholarshipDocumentSchema = z
  .object({
    _id: mongoIdValidator.optional(),
    id: z.number().int(),
    yearLastSurveyed: z.number().int(),
    programName: z.string(),
    description: z.string(),
    donorName: z.string(),
    donorDescription: z.string(),
    programNameAbbr: z.string(),
    renewalRequirements: z.string(),
    programCategories: ProgramCategorySchema,
    institutions: z.array(InstitutionSchema),
    areasOfStudy: z.array(AreaOfStudySchema),
    employment: z.array(CodeDescriptionSchema),
    ethnicity: z.array(CodeDescriptionSchema),
    essayCategories: z.array(CodeDescriptionSchema),
    stateResidence: z.array(z.string()),
    stateRestrictions: z.array(z.string()),
    isStateFunded: z.boolean(),
    isRenewable: z.boolean(),
    applicantRequiredInHighSchool: z.boolean().nullable(),
    applicantNotRequiredInHighSchool: z.boolean().nullable(),
    areHighSchoolEligible: z.boolean().nullable(),
    areHighSchoolNotEligible: z.boolean().nullable(),
    is911Related: z.boolean(),
    classification: ClassificationSchema,
    awardType: AwardTypeSchema,
    awardRange: LimitRangeSchema,
    moneyRange: LimitRangeSchema,
    awardTotals: AwardTotalsSchema,
    awardInclusions: AwardInclusionsSchema,
    workStatus: WorkStatusSchema,
    sector: SectorSchema,
    levelOfStudy: LevelOfStudySchema,
    institutionType: InstitutionTypeSchema,
    citizenship: CitizenshipSchema,
    score: ScoreSchema,
    scoreRequirements: ScoreRequirementSchema,
    ageRange: AgeRangeSchema,
    gender: GenderSchema,
    maritalStatus: MaritalStatusSchema,
    race: RaceSchema,
    disabilityType: DisabilityTypeSchema,
    militaryService: MilitaryServiceSchema,
    institutionalAid: InstitutionalAidSchema,
    applicationSubmission: ApplicationSubmissionSchema,
    applicationRequirements: ApplicationRequirementSchema,
    applicationFee: ApplicationFeeSchema,
    applicationDates: ApplicationDateSchema,
    webAddress: WebAddressSchema,
  })
  .merge(AuditFieldSchema);
export type ScholarshipDocument = z.infer<typeof ScholarshipDocumentSchema>;
