import { z } from 'zod';
import {
  commaDelimitedDecimalValidator,
  commaDelimitedIntegersValidator,
  limitValidator,
  majorSortValidator,
  skipValidator,
  sortValidator,
  yearValidator,
  zipCodeValidator,
} from '../common';

export const GetMajorListSchema = z.object({
  year: yearValidator.optional(),
  cipCodes: commaDelimitedIntegersValidator.optional(),
  exactCipCodes: commaDelimitedDecimalValidator.optional(),
  awardLevels: z.string().optional(),
  careerFit: z.boolean().optional(),
  careerFavorite: z.boolean().optional(),
  certificationsEarned: z.boolean().optional(),
  limit: limitValidator,
  skip: skipValidator,
  sort: majorSortValidator.optional(),
  sortDirection: sortValidator.optional(),
});
export type GetMajorList = z.infer<typeof GetMajorListSchema>;

const GetMajorDetailSchema = z.object({
  carnegieSizeSettings: z.string().optional(),
  classesOffered: z.number().int().optional(),
  institutionTypes: z.string().optional(),
  isVocationalSchool: z.boolean().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  limit: z.number().int().optional(),
  zipCode: zipCodeValidator.optional(),
});
export type GetMajorDetail = z.infer<typeof GetMajorDetailSchema>;

const GetStudyAreasListSchema = z.object({
  year: yearValidator.optional(),
  cipCodes: commaDelimitedIntegersValidator.optional(),
});
export type GetStudyAreasList = z.infer<typeof GetStudyAreasListSchema>;
