import { z } from 'zod';
import { mongoIdValidator } from '../common';

export const CompletionsSchema = z.object({
  cipCode: z.string(),
  originalAwLevel: z.array(z.number().int()).nullable(),
  updatedAwLevel: z.array(z.number().int()).nullable(),
});
export type Completions = z.infer<typeof CompletionsSchema>;

const CompletionsConflictSchema = z.object({
  added: z.string().array(),
  removed: z.string().array(),
  updated: z
    .object({
      cipCode: z.string(),
      addedAwLevels: z.number().array(),
      removedAwLevels: z.number().array(),
    })
    .array(),
});
export type CompletionsConflict = z.infer<typeof CompletionsConflictSchema>;

export const IpedsAdminChangesDocumentSchema = z.object({
  _id: mongoIdValidator.optional(),
  id: z.number().int(),
  completions: z.array(CompletionsSchema),
  conflicts: z
    .object({
      completions: CompletionsConflictSchema.optional(),
    })
    .optional(),
});
export type IpedsAdminChangesDocument = z.infer<typeof IpedsAdminChangesDocumentSchema>;
