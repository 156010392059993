import { z } from 'zod';
import {
  awardLevelValidator,
  carnegieSizeSettingValidator,
  commaDelimitedDecimalValidator,
  institutionTypeValidator,
  keywordValidator,
  localesValidator,
  positiveIntegerValidator,
  specialtyValidator,
  tenantShareTypeValidator,
  totalStudentsEnrolledValidator,
  zipCodeValidator,
} from '../common';
import { PersonalInfoSchema, SurveyResultSchema } from './model';

export const GetMajorCardSchema = z.object({
  cipCodes: commaDelimitedDecimalValidator,
});
export type GetMajorCard = z.infer<typeof GetMajorCardSchema>;

export const AddCollegeFilterBodySchema = z.object({
  awardLevels: z.array(awardLevelValidator).optional(),
  carnegieSizeSettings: z.array(carnegieSizeSettingValidator).optional(),
  cipCodes: z.array(z.string()).optional(),
  cost: z.number().int().optional(),
  hasScholarship: z.boolean().optional(),
  institutionTypes: z.array(institutionTypeValidator).optional(),
  keywords: keywordValidator.optional(),
  locales: z.array(localesValidator).optional(),
  specialty: z.array(specialtyValidator).optional(),
  states: z.array(z.string()).optional(),
  totalStudentsEnrolled: z.array(totalStudentsEnrolledValidator).optional(),
});
export type AddCollegeFilter = z.infer<typeof AddCollegeFilterBodySchema>;

export const AddCollegeSurveyBodySchema = z.object({
  collegeId: positiveIntegerValidator,
  surveyId: positiveIntegerValidator,
  questions: z.array(SurveyResultSchema),
});
export type AddCollegeSurvey = z.infer<typeof AddCollegeSurveyBodySchema>;

export const AddMajorFavoriteBodySchema = z.object({ cipCode: z.string() });
export type AddMajorFavoriteBody = z.infer<typeof AddMajorFavoriteBodySchema>;

export const AddMajorFilterSchema = z.object({ cipCodes: commaDelimitedDecimalValidator });
export type AddMajorFilter = z.infer<typeof AddMajorFilterSchema>;

export const AddMajorSurveyBodySchema = z.object({
  majorId: z.string(),
  surveyId: positiveIntegerValidator,
  questions: z.array(SurveyResultSchema),
});
export type AddMajorSurvey = z.infer<typeof AddMajorSurveyBodySchema>;

export const AddScholarshipFavoriteBodySchema = z.object({ scholarshipId: positiveIntegerValidator });
export type AddScholarshipFavoriteBody = z.infer<typeof AddScholarshipFavoriteBodySchema>;

export const UpdateBatchTenantSharingBodySchema = z.object({
  optInText: z.string(),
  optOutText: z.string(),
  tenantSharing: z.array(
    z.object({
      collegeId: positiveIntegerValidator,
      currentlySharing: z.boolean(),
    }),
  ),
});
export type UpdateBatchTenantSharingBody = z.infer<typeof UpdateBatchTenantSharingBodySchema>;

export const UpdateUserPreferenceBodySchema = z.object({
  notifyWhenTenantAssigned: z.boolean().optional(),
  isNotToBeAskedToShare: z.boolean().optional(),
  personalInfo: PersonalInfoSchema.optional(),
});
export type UpdateUserPreferenceBody = z.infer<typeof UpdateUserPreferenceBodySchema>;

export const GetUserProfileSchema = z.object({ zipCode: zipCodeValidator.optional() });
export type GetUserProfile = z.infer<typeof GetUserProfileSchema>;

export const UpdateTenantSharingBodySchema = z.object({
  question: z.string(),
  share: z.boolean(),
  shareType: tenantShareTypeValidator,
});
export type UpdateTenantSharingBody = z.infer<typeof UpdateTenantSharingBodySchema>;

export const CollegeIdQuerySchema = z.object({ collegeId: z.number().int() });
