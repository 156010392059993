export enum Season {
  FALL = 'Fall',
  WINTER = 'Winter',
  SPRING = 'Spring',
  SUMMER = 'Summer',
}

export enum TaskStatus {
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
}

export enum TaskTags {
  COLLEGE = 'College',
  EXTRACURRICULAR = 'Extracurricular',
  PREPARATION = 'Preparation',
  FINANCIAL_AID = 'Financial Aid',
  TESTING = 'Testing',
}

export enum AdmissionStatus {
  ADMITTED = 'Admitted',
  DENIED = 'Denied',
  DEFERRED = 'Deferred',
  WAITLISTED = 'Waitlisted',
}

export enum DeadlineType {
  EARLY_ACTION = 'Early action',
  EARLY_ACTION2 = 'Early action II',
  EARLY_DECISION = 'Early decision',
  EARLY_DECISION2 = 'Early decision II',
  REGULAR_DECISION = 'Regular decision (Fall Freshman)',
  REGULAR_DECISION_OUT_OF_STATE = 'Regular decision for out of state applicant (Fall Freshman)',
  ROLLING_ADMISSION = 'Rolling admission',
  NOT_SURE = 'Not sure',
}

export enum ApplicationType {
  COMMON = 'Common app',
  INSTITUTIONAL = 'Institutional app',
}

export enum ApplicationStatus {
  NOT_STARTED = 'Not Started',
  STARTED = 'Started',
  SUBMITTED = 'Submitted/Applied',
  CANCELLED = 'Cancelled',
}

export enum Decision {
  PLAN_ON = 'Plan on attending',
  DEPOSITED = 'Deposited/Enrolled',
  MAYBE = 'Maybe attending',
  NOT_ATTENDING = 'Not attending',
}

export enum ScholarshipStatus {
  IN_PROGRESS = 'In progress',
  APPLIED = 'Applied',
  WINNER = "I'm the winner!",
  CHANGED = 'I have changed my mind',
}
