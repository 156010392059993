import { z } from 'zod';
import { gradeValidator, mongoIdValidator, seasonValidator, tagsValidator } from '../common';

export const TaskDocumentSchema = z.object({
  _id: mongoIdValidator.optional(),
  taskId: z.number().int(),
  required: z.boolean(),
  linkUrls: z.array(z.string()).optional(),
  grades: z.array(gradeValidator),
  season: seasonValidator,
  tags: z.array(tagsValidator),
  title: z.string(),
  description: z.string(),
});
export type TaskDocument = z.infer<typeof TaskDocumentSchema>;
