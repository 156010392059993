import { AdmissionStatus, ApplicationStatus } from '@common-types';

import { ALL_TYPES_FILTER_VALUE } from '@constants/educationPlanner';

import MarkIcon from '@images/AdmissionStatusIcons/mark.svg?react';
import CrossedCircleIcon from '@images/AdmissionStatusIcons/crossed-out-circle.svg?react';
import ClockIcon from '@images/AdmissionStatusIcons/clock.svg?react';
import SandglassIcon from '@images/AdmissionStatusIcons/sandglass.svg?react';

export const MAX_SCHOOL_COUNT = 35;
export const MAX_CHECKPOINT_LABEL_LENGTH = 50;
export const MAX_CUSTOM_CHECKLIST_LENGTH = 15;

export const INITIAL_PAGE_FILTERS = {
  applicationType: ALL_TYPES_FILTER_VALUE,
  applicationStatus: ALL_TYPES_FILTER_VALUE,
};

export const SCHOOL_RANKING_DATA = {
  1: 'First-Choice',
  2: 'Second-Choice',
  3: 'Back-Up',
};

export const ADMISSION_STATUS_TAGS_DATA = {
  [AdmissionStatus.ADMITTED]: {
    color: '#1C5F20',
    icon: <MarkIcon />,
  },
  [AdmissionStatus.DENIED]: {
    color: '#C72929',
    icon: <CrossedCircleIcon />,
  },
  [AdmissionStatus.DEFERRED]: {
    color: '#E55000',
    icon: <ClockIcon />,
  },
  [AdmissionStatus.WAITLISTED]: {
    color: '#E55000',
    icon: <SandglassIcon />,
  },
};

export const APPLICATION_STATUS_TAGS_COLORS = {
  [ApplicationStatus.NOT_STARTED]: '#E55000',
  [ApplicationStatus.STARTED]: '#01599D',
  [ApplicationStatus.SUBMITTED]: '#1C5F20',
  [ApplicationStatus.CANCELLED]: '#C72929',
};

export const DEFAULT_CHECKPOINTS_LABELS = {
  sentTranscription: 'Send your transcripts',
  sentRecommendation: 'Send recommendation letters',
  sentTestScores: 'Send test scores',
  submitFafsa: 'Submit FAFSA',
  receiveFinancialAid: 'Receive financial aid',
  submitEssay: 'Submit essay',
};

export const CUSTOM_CHECKPOINT_ERRORS: { [key: string]: string } = {
  short: 'Looks like your task is empty. Please add a brief description and try again.',
  duplicate: 'Looks like you’ve already added this checkpoint. Please make some edits and try again.',
};
