import { z } from 'zod';
import { AuditFieldSchema, dateOrStringValidator, mongoIdValidator } from '../common';

const AddressSchema = z.object({
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  location: z.object({
    type: z.string(),
    coordinates: z.array(z.number()).length(2),
  }),
});
export type Address = z.infer<typeof AddressSchema>;

const LinksSchema = z.object({
  url: z.string(),
  adminUrl: z.string(),
  financialAidUrl: z.string(),
  applicationUrl: z.string(),
  priceCalculatorUrl: z.string(),
});
export type Links = z.infer<typeof LinksSchema>;

export const DirectorySchema = z.object({
  opeId: z.string(),
  name: z.string(),
  alias: z.string(),
  address: AddressSchema,
  links: LinksSchema,
  demographics: z.object({
    institutionType: z.number(),
    fipsStateCode: z.number(),
    beaRegion: z.number(),
    carnegieSizeSetting: z.number(),
    locale: z.number(),
    isHistoricallyBlack: z.boolean(),
    isTribal: z.boolean(),
  }),
  multiInstitution: z
    .object({
      isMulti: z.number().optional(),
      name: z.string().optional(),
      id: z.number().optional(),
    })
    .optional(),
});
export type Directory = z.infer<typeof DirectorySchema>;

export const CharacteristicsSchema = z.object({
  religiousAffiliation: z.number().optional(),
  isDistanceLearning: z.number().optional(),
  isOpenPolicy: z.boolean().optional(),
  isMemberOfAthleticAssoc: z.boolean().optional(),
  isMemberOfNCAA: z.boolean().optional(),
  isMemberOfNAIA: z.boolean().optional(),
  isMemberOfNJCAA: z.boolean().optional(),
  isMemberOfNSCAA: z.boolean().optional(),
  isMemberOfNCCAA: z.boolean().optional(),
  isMemberOfOther: z.boolean().optional(),
  hasOnCampusHousing: z.boolean().optional(),
  hasNoDistanceEducation: z.number().optional(),
  acceptsAPCredit: z.boolean().optional(),
  acceptsCreditForLifeExperience: z.boolean().optional(),
  acceptsDualEnrollment: z.boolean().optional(),
  acceptsNoCredit: z.boolean().optional(),
  hasAirForceROTC: z.boolean().optional(),
  hasArmyROTC: z.boolean().optional(),
  hasCounselingService: z.boolean().optional(),
  hasDayCareService: z.boolean().optional(),
  hasEmploymentService: z.boolean().optional(),
  hasNavyROTC: z.boolean().optional(),
  hasNoStudentServices: z.boolean().optional(),
  hasPlacementService: z.boolean().optional(),
  hasROTC: z.boolean().optional(),
  hasRemedialService: z.boolean().optional(),
  hasStudyAbroad: z.boolean().optional(),
});
export type Characteristics = z.infer<typeof CharacteristicsSchema>;

export const CostsSchema = z.object({
  isVocationalSchool: z.boolean(),
  inStateTuition: z.number().optional(),
  outOfStateTuition: z.number().optional(),
  booksAndSupplies: z.number().optional(),
  onCampusHousing: z.number().optional(),
  onCampusOtherExpenses: z.number().optional(),
  offCampusHousing: z.number().optional(),
  offCampusOtherExpenses: z.number().optional(),
});
export type Costs = z.infer<typeof CostsSchema>;

export const AdmissionsSchema = z.object({
  hasTestScoreRequirements: z.boolean().optional(),
  hasHSGPAAdmissionRequirements: z.boolean().optional(),
  hasHSRankAdmissionRequirements: z.boolean().optional(),
  hasRecommendationAdmissionRequirements: z.boolean().optional(),
  hasTranscriptAdmissionRequirements: z.boolean().optional(),
  actComposite25Score: z.number().optional(),
  actComposite75Score: z.number().optional(),
  actEnglish25Score: z.number().optional(),
  actEnglish75Score: z.number().optional(),
  actMath25Score: z.number().optional(),
  actMath75Score: z.number().optional(),
  satMath25Score: z.number().optional(),
  satMath75Score: z.number().optional(),
  satReadingAndWriting25Score: z.number().optional(),
  satReadingAndWriting75Score: z.number().optional(),
});
export type Admissions = z.infer<typeof AdmissionsSchema>;

export const EnrollmentSchema = z.object({
  totalStudentsEnrolled: z.number().optional(),
  totalStudentsMen: z.number().optional(),
  totalStudentsWomen: z.number().optional(),
  totalStudentsNativeAmerican: z.number().optional(),
  totalStudentsAsian: z.number().optional(),
  totalStudentsAfricanAmerican: z.number().optional(),
  totalStudentsLatino: z.number().optional(),
  totalStudentsPacificIslander: z.number().optional(),
  totalStudentsWhite: z.number().optional(),
  totalStudentsTwoOrMoreRaces: z.number().optional(),
});
export type Enrollment = z.infer<typeof EnrollmentSchema>;

export const PictureSchema = z.object({
  name: z.string(),
  pictureWidth: z.number().nullable().optional(),
  pictureHeight: z.number().nullable().optional(),
  pictureSource: z.string().optional(),
  pictureUrl: z.string().optional(),
  updated: dateOrStringValidator.optional(),
});
export type Picture = z.infer<typeof PictureSchema>;

export const CompletionSchema = z.object({
  cipCode: z.string(),
  awLevel: z.number().array(),
});
export type Completion = z.infer<typeof CompletionSchema>;

export const TopTenMajorSchema = z.object({
  cipCode: z.string().optional(),
  major: z.string().optional(),
  order: z.number().optional(),
  percentage: z.number().optional(),
});
export type TopTenMajor = z.infer<typeof TopTenMajorSchema>;

export const CollegeScorecardSchema = z.object({
  id: z.number(),
  modifiedDate: dateOrStringValidator,
  latest: z
    .object({
      academics: z
        .object({
          program_percentage: z
            .object({
              agriculture: z.number().nullable().optional(),
              architecture: z.number().nullable().optional(),
              biological: z.number().nullable().optional(),
              business_marketing: z.number().nullable().optional(),
              communication: z.number().nullable().optional(),
              communications_technology: z.number().nullable().optional(),
              computer: z.number().nullable().optional(),
              construction: z.number().nullable().optional(),
              education: z.number().nullable().optional(),
              engineering: z.number().nullable().optional(),
              engineering_technology: z.number().nullable().optional(),
              english: z.number().nullable().optional(),
              ethnic_cultural_gender: z.number().nullable().optional(),
              family_consumer_science: z.number().nullable().optional(),
              health: z.number().nullable().optional(),
              history: z.number().nullable().optional(),
              humanities: z.number().nullable().optional(),
              language: z.number().nullable().optional(),
              library: z.number().nullable().optional(),
              legal: z.number().nullable().optional(),
              mathematics: z.number().nullable().optional(),
              mechanic_repair_technology: z.number().nullable().optional(),
              military: z.number().nullable().optional(),
              multidiscipline: z.number().nullable().optional(),
              parks_recreation_fitness: z.number().nullable().optional(),
              personal_culinary: z.number().nullable().optional(),
              philosophy_religious: z.number().nullable().optional(),
              physical_science: z.number().nullable().optional(),
              precision_production: z.number().nullable().optional(),
              psychology: z.number().nullable().optional(),
              public_administration_social_service: z.number().nullable().optional(),
              resources: z.number().nullable().optional(),
              science_technology: z.number().nullable().optional(),
              security_law_enforcement: z.number().nullable().optional(),
              social_science: z.number().nullable().optional(),
              theology_religious_vocation: z.number().nullable().optional(),
              transportation: z.number().nullable().optional(),
              visual_performing: z.number().nullable().optional(),
            })
            .optional(),
        })
        .optional(),
      admissions: z
        .object({
          act_scores: z
            .object({
              midpoint: z
                .object({
                  cumulative: z.number().nullable().optional(),
                })
                .optional(),
            })
            .optional(),
          admission_rate: z
            .object({
              overall: z.number().nullable().optional(),
            })
            .optional(),
          sat_scores: z
            .object({
              average: z
                .object({
                  overall: z.number().nullable().optional(),
                })
                .optional(),
            })
            .optional(),
        })
        .optional(),
      completion: z
        .object({
          completion_rate_4yr_150nt_pooled: z.number().nullable().optional(),
          completion_rate_less_than_4yr_150nt_pooled: z.number().nullable().optional(),
        })
        .optional(),
      cost: z
        .object({
          attendance: z
            .object({
              academic_year: z.number().nullable().optional(),
              program_year: z.number().nullable().optional(),
            })
            .optional(),
        })
        .optional(),
      earnings: z
        .object({
          '6_yrs_after_entry': z
            .object({
              median: z.number().nullable().optional(),
            })
            .optional(),
        })
        .optional(),
      school: z
        .object({
          men_only: z.boolean().optional(),
          women_only: z.boolean().optional(),
          degrees_awarded: z
            .object({
              predominant: z.number().optional(),
            })
            .optional(),
        })
        .optional(),
      student: z
        .object({
          retention_rate: z
            .object({
              four_year: z
                .object({
                  full_time: z.number().nullable().optional(),
                })
                .optional(),
              lt_four_year: z
                .object({
                  full_time: z.number().nullable().optional(),
                })
                .optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .optional(),
  topTenMajors: z.array(TopTenMajorSchema).optional(),
});
export type CollegeScorecard = z.infer<typeof CollegeScorecardSchema>;

export const PetersonSchema = z.object({
  datasetYear: z.number().int(),
  inunId: z.number().int(),
  fallFreshmenApplicationDeadlineMonth: z.number().int().nullable(),
  fallFreshmenApplicationDeadlineDay: z.number().int().nullable(),
  fallFreshmenOutOfStateApplicationDeadlineMonth: z.number().int().nullable(),
  fallFreshmenOutOfStateApplicationDeadlineDay: z.number().int().nullable(),
  otherEarlyDecisionApplicationDeadlineMonth: z.number().int().nullable(),
  otherEarlyDecisionApplicationDeadlineDay: z.number().int().nullable(),
  outOfStateFallFreshmenNotificationDeadlineMonth: z.number().int().nullable(),
  outOfStateFallFreshmenNotificationDeadlineDay: z.number().int().nullable(),
  earlyDecisionApplicationDeadlineMonth: z.number().int().nullable(),
  earlyDecisionApplicationDeadlineDay: z.number().int().nullable(),
  fallTransferApplicationDeadlineMonth: z.number().int().nullable(),
  fallTransferApplicationDeadlineDay: z.number().int().nullable(),
  earlyActionApplicationDeadlineMonth: z.number().int().nullable(),
  earlyActionApplicationDeadlineDay: z.number().int().nullable(),
  applicationFee: z.number().nullable(),
  latestDateSatOrActReceivedFallAdmissionMonth: z.number().int().nullable(),
  latestDateSatOrActReceivedFallAdmissionDay: z.number().int().nullable(),
  latestDateSat2ReceivedFallAdmissionMonth: z.number().int().nullable(),
  latestDateSat2ReceivedFallAdmissionDay: z.number().int().nullable(),
  fallFreshmenPriorityApplicationDeadlineMonth: z.number().int().nullable(),
  fallFreshmenPriorityApplicationDeadlineDay: z.number().int().nullable(),
  isOpenAdmissionPolicy: z.boolean().nullable(),
  hasEarlyActionPlan: z.boolean().nullable(),
  hasEarlyDecisionPlan: z.boolean().nullable(),
  isAcceptingElectronicApplications: z.boolean().nullable(),
  isEnrollingTransferStudents: z.boolean().nullable(),
  entranceDifficultyLevelFreshmen: z.string().nullable(),
  entranceDifficultyLevelFreshmenOutOfState: z.string().nullable(),
  entranceDifficultyLevelTransferStudents: z.string().nullable(),
});
export type Peterson = z.infer<typeof PetersonSchema>;

export const IpedsDocumentSchema = z
  .object({
    _id: mongoIdValidator.optional(),
    id: z.number(),
    directory: DirectorySchema,
    characteristics: CharacteristicsSchema,
    costs: CostsSchema,
    admissions: AdmissionsSchema,
    enrollment: EnrollmentSchema,
    picture: PictureSchema.optional().nullable(),
    completions: z.array(CompletionSchema),
    description: z.string().optional(),
    collegeScorecard: CollegeScorecardSchema.optional(),
    peterson: PetersonSchema.optional(),
    distance: z.number().optional(),
    tenantId: z.string().optional(),
    memberId: z.number().optional(),
    isActive: z.boolean(),
  })
  .merge(AuditFieldSchema);

export type IpedsDocument = z.infer<typeof IpedsDocumentSchema>;
